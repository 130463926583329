import { AuthContextProps } from 'react-oidc-context'
import { OpenAPI } from './openapi'
import { ApiRequestOptions } from './openapi/core/ApiRequestOptions'

// Set the base URL to use for API calls.
OpenAPI.BASE = process.env.REACT_APP_API_URL as string

export const setApiToken = (auth: AuthContextProps) => {
  OpenAPI.TOKEN = (options: ApiRequestOptions) =>
    new Promise((resolveOuter) => {
      resolveOuter(auth.user?.access_token || '')
    })
}

// Allow the headers to be set with the tenant ID.
export const setApiTenantId = (tenantId: string) => {
  OpenAPI.HEADERS = {
    'Content-Type': 'application/json',
  }
}
