import './ClientRecordDetails.scss'

import { lazy, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import MISTabbedContentContainer from 'common/components/contentpane/MISTabbedContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import Loader from 'common/components/Loader'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import Documents from 'modules/shared/Documents'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import { checkPrivacyClientProfileState } from 'recoil/recentClients'
import {
  PrivacyDirectiveControllerService,
  PrivacyDirectiveOverrideControllerService,
  PrivacyDirectiveOverrideDTO,
} from 'services/openapi'
import { setClientPrivacyDirectives } from 'store/reducers/client'
import OverridePrivacyDialog from './ClientPrivacyDirectives/OverridePrivacyDialog'
import ClientRecordHeader from './ClientRecordHeader'
import ClientStartup from './ClientStartup'

const EmergencyContactSection = lazy(() => import('./EmergencyContact/EmergencyContactSection'))
const FamilyRelationships = lazy(() => import('./FamilyRelationships/FamilyRelationships'))
const NonClinicalNotesSection = lazy(() => import('./GeneralNotes/NonClinicalNotesSection'))
const PersonSection = lazy(() => import('./Person/PersonSection'))
const ContactsSection = lazy(() => import('./Contacts/ContactsSection'))
const NamesSection = lazy(() => import('./Names/NamesSection'))
const ReportingOrganizationsSection = lazy(
  () => import('./ReportingOrganizations/ReportingOrganizationsSection')
)
const IdentifiersSection = lazy(() => import('./Identifiers/IdentifiersSection'))
const AddressesSection = lazy(() => import('./Addresses/AddressesSection'))
const GenderInfoSection = lazy(() => import('./GenderInfo/GenderInfo'))
const BirthDetailsSection = lazy(() => import('./BirthDetails/BirthDetails'))
const DeathDetailsSection = lazy(() => import('./DeathDetails/DeathDetails'))
const ClientAlertsSection = lazy(() => import('./Alerts/ClientAlerts'))
const EducationSection = lazy(() => import('./Education/EducationDetails'))

const ClientDetails = () => {
  const dispatch = useDispatch()
  const { clientId } = useParams()
  const { t } = useTranslation('common')
  const [forceDetailReload, setForceDetailReload] = useState(false)
  const { clientDetails } = useClientDetails(clientId, forceDetailReload)
  const { handleApiError } = useErrorHandler()

  const [openOverridePrivacyDialog, setOpenOverridePrivacyDialog] = useState(false)
  const [checkSelectClientId, setCheckSelectClientId] = useRecoilState(
    checkPrivacyClientProfileState
  )

  const tabConfig = useCallback(
    (clientId: string) => [
      {
        active: false,
        component: <PersonSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.person',
        label: t('navigation.client-profile-menu.person'),
        visibile: true,
      },
      {
        active: false,
        component: <ContactsSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.contact-information',
        label: t('navigation.client-profile-menu.contact-information'),
        visibile: true,
      },
      {
        active: false,
        component: <EmergencyContactSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.emergency-contacts',
        label: t('navigation.client-profile-menu.emergency-contacts'),
        visibile: true,
      },
      {
        active: false,
        component: <NamesSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.names',
        label: t('navigation.client-profile-menu.names'),
        visibile: true,
      },
      {
        active: false,
        component: <AddressesSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.addresses',
        label: t('navigation.client-profile-menu.addresses'),
        visibile: true,
      },
      {
        active: false,
        component: <IdentifiersSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.identifiers',
        label: t('navigation.client-profile-menu.identifiers'),
        visibile: true,
      },
      {
        active: false,
        component: <FamilyRelationships />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.family-relationships',
        label: t('navigation.client-profile-menu.family-relationships'),
        visibile: true,
      },
      {
        active: false,
        component: <EducationSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.schools-education',
        label: t('navigation.client-profile-menu.schools-education'),
        visibile: true,
      },
      {
        active: false,
        component: <BirthDetailsSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.birth-details',
        label: t('navigation.client-profile-menu.birth-details'),
        visibile: true,
      },
      {
        active: false,
        component: <GenderInfoSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.gender-sex-at-birth-details',
        label: t('navigation.client-profile-menu.gender-sex-at-birth-details'),
        visibile: true,
      },
      {
        active: false,
        component: <DeathDetailsSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.cause-of-death-details',
        label: t('navigation.client-profile-menu.cause-of-death-details'),
        visibile: true,
      },
      {
        active: false,
        component: <ClientAlertsSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.alerts',
        label: t('navigation.client-profile-menu.alerts'),
        visibile: true,
      },
      {
        active: false,
        component: <Documents breadcrumb="" entityId={clientId || ''} entityType="CLIENT_FILE" />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.documents',
        label: 'Documents and Images',
        visibile: true,
      },
      {
        active: false,
        component: <NonClinicalNotesSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.client-profile-menu.general-notes',
        label: t('navigation.client-profile-menu.general-notes'),
        visibile: true,
      },
      {
        active: false,
        component: <ReportingOrganizationsSection />,
        dirty: false,
        disabled: false,
        id: 'reportingorganizations',
        label: t('client-reporting-org.title'),
        visibile: true,
      },
    ],
    [t]
  )

  const handleCheckOverridePrivacy = useCallback(
    async (reason: string) => {
      if (clientId) {
        const overrideDto: PrivacyDirectiveOverrideDTO = {
          entityId: clientId,
          entityType: 'CLIENT',
          reason: reason,
        }
        PrivacyDirectiveOverrideControllerService.createDirectiveOverride(clientId, overrideDto)
          .then(() => {
            setOpenOverridePrivacyDialog(false)
            setForceDetailReload(true)
          })
          .catch((error) => {
            setOpenOverridePrivacyDialog(false)
            handleApiError(error)
          })
      }
    },
    [clientId, handleApiError]
  )

  const handleOverrideDialogClose = useCallback(() => {
    handleApiError(checkSelectClientId?.error)
    setOpenOverridePrivacyDialog(false)
    setCheckSelectClientId({ checkFlag: false, error: undefined })
  }, [checkSelectClientId?.error, handleApiError, setCheckSelectClientId])

  useEffect(() => {
    const getClientDirective = async (id: string) => {
      try {
        const directives = await PrivacyDirectiveControllerService.getDirectives(
          id,
          true,
          undefined,
          undefined,
          ['auditInfo']
        )
        if (!directives?.content?.length) {
          dispatch(setClientPrivacyDirectives(undefined))
          return
        }
        const directive = directives.content[0]
        dispatch(setClientPrivacyDirectives(directive))
      } catch (error) {
        handleApiError(error)
      }
    }

    if (clientId) {
      getClientDirective(clientId)
    }
  }, [clientId, dispatch, handleApiError])

  useEffect(() => {
    if (checkSelectClientId && checkSelectClientId.checkFlag) {
      setOpenOverridePrivacyDialog(true)
    }
  }, [checkSelectClientId])

  useEffect(() => {
    if (clientDetails && forceDetailReload) {
      setForceDetailReload(false)
    }
  }, [clientDetails, forceDetailReload])

  return (
    <MISBaseContainer>
      <div className="client-record-details">
        {clientDetails?.id ? (
          <>
            <ClientStartup clientId={clientDetails?.id} />
            <ClientRecordHeader />
            <MISTabbedContentContainer tabConfig={tabConfig(clientDetails.id)} />
          </>
        ) : (
          <Loader fullScreen />
        )}
      </div>
      <OverridePrivacyDialog
        onCancel={handleOverrideDialogClose}
        onSave={handleCheckOverridePrivacy}
        open={openOverridePrivacyDialog}
      />
    </MISBaseContainer>
  )
}

export default ClientDetails
