/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientNetworkDTO } from '../models/ClientNetworkDTO'
import type { PageClientNetworkDTO } from '../models/PageClientNetworkDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientNetworkControllerService {
  /**
   * Returns a single network by networkId
   * Returns a single network by networkId
   * @param clientId
   * @param networkId
   * @returns ClientNetworkDTO OK
   * @throws ApiError
   */
  public static getNetwork(
    clientId: string,
    networkId: string
  ): CancelablePromise<ClientNetworkDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/network/{networkId}',
      path: {
        clientId: clientId,
        networkId: networkId,
      },
    })
  }

  /**
   * Update client network
   * update client network by networkId
   * @param clientId
   * @param networkId
   * @param requestBody
   * @returns ClientNetworkDTO OK
   * @throws ApiError
   */
  public static updateNetwork(
    clientId: string,
    networkId: string,
    requestBody: ClientNetworkDTO
  ): CancelablePromise<ClientNetworkDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/network/{networkId}',
      path: {
        clientId: clientId,
        networkId: networkId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete network by networkId
   * Soft deletes the network by setting deleted flag to true
   * @param clientId
   * @param networkId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteNetwork(clientId: string, networkId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/network/{networkId}',
      path: {
        clientId: clientId,
        networkId: networkId,
      },
    })
  }

  /**
   * Returns a paged list of networks for client
   * List is returned as Paged collection
   * @param clientId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageClientNetworkDTO OK
   * @throws ApiError
   */
  public static getClientNetworks(
    clientId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageClientNetworkDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/network',
      path: {
        clientId: clientId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create client network
   * Create client network
   * @param clientId
   * @param requestBody
   * @returns ClientNetworkDTO OK
   * @throws ApiError
   */
  public static createNetwork(
    clientId: string,
    requestBody: ClientNetworkDTO
  ): CancelablePromise<ClientNetworkDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/network',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
