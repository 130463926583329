import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISDurationField, { toHHMM } from 'common/components/form/MISDurationField'
import MISRadio from 'common/components/form/MISRadio'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import Loader from 'common/components/Loader'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import useLocationOfService from 'modules/shared/hooks/useLocationOfService'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { getError, getFormattedOptions, hasError } from 'modules/shared/utils'
import { esdrtCategoriesAtom, programsAtom, purposesAtom, typesAtom } from 'recoil/atoms'
import { encountersState } from 'recoil/encounters'
import { isDirtyState } from 'recoil/isDirty'
import { encounterIdState } from 'recoil/lastupdated'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientDTO,
  CodedConceptDto,
  CodedRef,
  EncounterControllerService,
  EncounterDTO,
  EncounterServiceControllerService,
  EncounterServiceDTO,
  EncounterServiceStateControllerService,
} from 'services/openapi'
import {
  MIS_ENCOUNTER_SERVICE_STATE,
  MIS_NOT_PROVIDED_ESDRT_REASON_TYPE,
  MIS_NOT_PROVIDED_REASON_TYPE,
  MIS_VOIDED_REASON_TYPE,
} from 'services/terminologyConstants'
import EncounterServiceForms from './EncounterServiceForms'
import EncounterServiceNotes from './EncounterServiceNotes'
import EncounterServicesStaff from './EncounterServicesStaff'
import AddEncounterDialog from '../AddEncounterDialog'
import {
  EncounterEntityRefType,
  EncounterServiceRowDataType,
  EncounterServiceRowType,
} from '../Encounters'

const ENTITY = 'Encounter Service'

type EncounterServicesSectionProps = {
  client: ClientDTO
  encounter: EncounterDTO
  services: EncounterServiceRowType
  setEncounter: (encounter: EncounterDTO | undefined) => void
  setServices: (services: EncounterServiceRowType) => void
}

const EncounterServicesSection = forwardRef(
  (
    { client, encounter, services, setEncounter, setServices }: EncounterServicesSectionProps,
    ref
  ) => {
    const { t } = useTranslation('common')
    const { showSnackSuccess } = useSnack()
    const { handleApiError } = useErrorHandler()
    const { locationOfService } = useLocationOfService()
    const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
    const [encounters, setEncounters] = useRecoilState(encountersState)
    const esdrtCategories = useRecoilValue(esdrtCategoriesAtom)
    const purposeOptions = useRecoilValue(purposesAtom)
    const typeOptions = useRecoilValue(typesAtom)
    const programOptions = useRecoilValue(programsAtom)
    const serviceStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
    const voidedReasonType = useRecoilValue(terminologySelector(MIS_VOIDED_REASON_TYPE))
    const notProvidedEsdrtReasonOptions = useRecoilValue(
      terminologySelector(MIS_NOT_PROVIDED_ESDRT_REASON_TYPE)
    )
    const notProvidedReasonOptions = useRecoilValue(
      terminologySelector(MIS_NOT_PROVIDED_REASON_TYPE)
    )
    const setLastUpdatedEncounterId = useSetRecoilState(encounterIdState)

    const [openAddEditForm, setOpenAddEditForm] = useState(false)
    const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
      index: -1,
      open: false,
    })
    const [preferredWarningDialog, setPreferredWarningDialog] = useState<{
      open: boolean
      data: {
        rowIndex?: number
        field?: string
        value?: boolean | string | number | CodedConceptDto
      }
    }>({ data: {}, open: false })

    const formsRef = useRef<EncounterEntityRefType>()
    const notesRef = useRef<EncounterEntityRefType>()

    const isEncounterServiceEsdrt = useCallback(
      (service: EncounterServiceDTO) =>
        service.esdrtCategoryId !== undefined &&
        service.esdrtServiceId !== undefined &&
        service.duration !== undefined,
      []
    )

    const getEncounterServiceStateValues = useCallback(
      (service: EncounterServiceDTO) =>
        serviceStateOptions.map((opt) => {
          switch (opt.code) {
            case 'NOT_PROVIDED':
              return {
                reasonCodeRequired: true,
                reasonCodeTerminology: isEncounterServiceEsdrt(service)
                  ? notProvidedEsdrtReasonOptions
                  : notProvidedReasonOptions,
                stateValue: opt,
              }
            case 'VOIDED':
              return {
                reasonCodeRequired: true,
                reasonCodeTerminology: voidedReasonType,
                stateValue: opt,
              }
            case 'REQUIRES_UPDATE':
            case 'REVISED_DRAFT':
              return { commentRequired: true, stateValue: opt }
            default:
              return { stateValue: opt }
          }
        }),
      [
        isEncounterServiceEsdrt,
        notProvidedEsdrtReasonOptions,
        notProvidedReasonOptions,
        serviceStateOptions,
        voidedReasonType,
      ]
    )

    const getEncounterServiceStateReasonOptions = useCallback(
      (service: EncounterServiceDTO) => {
        if (service.state?.code === 'NOT_PROVIDED') {
          if (isEncounterServiceEsdrt(service)) return notProvidedEsdrtReasonOptions
          return notProvidedReasonOptions
        } else if (service.state?.code === 'VOIDED') return voidedReasonType
        return []
      },
      [
        isEncounterServiceEsdrt,
        notProvidedEsdrtReasonOptions,
        notProvidedReasonOptions,
        voidedReasonType,
      ]
    )

    const getEsdrtInfo = useCallback(
      (esdrtCategoryId, esdrtServiceId) => {
        let info = ''
        const esdrtCategory = esdrtCategories.find((category) => category.id === esdrtCategoryId)
        if (esdrtCategory) {
          info = `${esdrtCategory.eSDRTCategoryDesc} (${esdrtCategory.eSDRTCategoryCode})`
          const esdrtService = esdrtCategory.eSDRTServices?.find(
            (service) => service.id === esdrtServiceId
          )
          if (esdrtService) {
            info = `${info} | ${esdrtService.eSDRTServiceDesc} (${esdrtService.eSDRTServiceCode})`
            if (esdrtService.durationMinutes)
              info = `${info} | ${toHHMM(esdrtService.durationMinutes)}`
          }
        }
        return info
      },
      [esdrtCategories]
    )

    const handleSaveCallBack = useCallback(
      (encounter: EncounterDTO) => {
        setIsDirty(false)

        setEncounters(
          encounters?.map((el: EncounterDTO) =>
            el.id === encounter.id ? Object.assign({}, encounter) : el
          )
        )
        setEncounter(encounter)
        setOpenAddEditForm(false)
      },
      [encounters, setEncounter, setEncounters, setIsDirty]
    )

    const handleChangeServiceState = useCallback(
      async (serviceId: string, state: CodedRef, reason?: CodedRef, comment?: string) => {
        try {
          const serviceUpdate =
            await EncounterServiceStateControllerService.createEncounterServiceState(
              encounter?.id as string,
              serviceId,
              { comment, reason, state }
            )
          const updatedService = {
            ...services?.rows?.find((item: EncounterServiceDTO) => item?.id === serviceId),
            ...serviceUpdate,
            id: serviceId,
          }
          const updatedServiceRows = [
            ...services.rows.filter((item: EncounterServiceDTO) => item?.id !== serviceId),
            updatedService,
          ]
          setServices({
            ...services,
            rows: updatedServiceRows.sort((a, b) =>
              a?.isPrimaryService ? -1 : b?.isPrimaryService ? 1 : 0
            ),
          })

          const response = await EncounterControllerService.encountersgetPagedList(
            client?.id as string,
            'CLIENT_FILES',
            '',
            '',
            false,
            false,
            'ALL'
          )
          setEncounters(response?.content)
        } catch (error) {
          handleApiError(error)
        }
      },
      [client?.id, encounter?.id, handleApiError, services, setEncounters, setServices]
    )

    const handleGetNextServiceStateOptions = useCallback(
      async (serviceId: string) => {
        const nextStateOptions: StateWithInstructionsType[] = []
        const response = await EncounterServiceStateControllerService.getNextStatesByServiceId(
          encounter?.id as string,
          serviceId
        )
        response.content?.forEach((item) => {
          if (item.state) nextStateOptions.push(item)
        })
        return nextStateOptions
      },
      [encounter?.id]
    )

    const onCollapseRow = useCallback(
      (rowIndex: number) => {
        services.rows[rowIndex].isCollapsed = !services.rows[rowIndex].isCollapsed
        setServices({ ...services })
      },
      [services, setServices]
    )

    const onChangeRow = useCallback(
      (rowIndex: number, field: string, value: boolean | number | string | CodedConceptDto) => {
        if (!isDirty) setIsDirty(true)

        const updatedRows = services.rows.map((row: EncounterServiceRowDataType, index: number) => {
          if (field === 'isPrimaryService' && rowIndex === index) {
            setPreferredWarningDialog({
              data: { field: field, rowIndex: rowIndex, value: value },
              open: true,
            })
            return { ...row }
          }

          if (rowIndex === index) {
            return { ...row, [field]: value }
          }
          return row
        })
        setServices({ ...services, rows: updatedRows })
      },
      [services, setServices, isDirty, setIsDirty]
    )

    const disableServiceDelete = useCallback(
      (rowIndex: number) => {
        const service = services.rows[rowIndex]
        if (service.state?.code !== 'DRAFT') {
          return true
        }
        const rows = services.rows.length
        if (service.isPrimaryService) {
          if (rows > 1) return true
          if (rows === 1) return false
        }
        return !service.isDeletable
      },
      [services]
    )

    const onPreferredWarningClose = useCallback(() => {
      setPreferredWarningDialog({ data: {}, open: false })
    }, [])

    const onPreferredWarningSave = useCallback(
      async ({
        field,
        rowIndex,
        value,
      }: {
        rowIndex?: number
        field?: string
        value?: boolean | number | string | CodedConceptDto
      }) => {
        setPreferredWarningDialog({ ...preferredWarningDialog, open: false })

        const updatedRows = services.rows.map((row: EncounterServiceRowDataType, index: number) => {
          const newRow = { ...row }
          if (field === 'isPrimaryService') {
            Object.assign(newRow, { isPrimaryService: false })
          }
          if (rowIndex === index) {
            const attr = Object.keys(row).filter((r) => r === field)
            const fieldName: string = attr[0]
            Object.assign(newRow, { [fieldName]: value })
          }
          return newRow
        })
        if (!encounter?.id) return
        const saveMe = {
          ...encounter,
          encounterServices: updatedRows,
        }
        try {
          const response: EncounterDTO = await EncounterControllerService.updateEncounter(
            encounter?.id,
            saveMe
          )
          if (!response.encounterServices) return
          setServices({ ...services, rows: response.encounterServices })
          const nextEncounter = { ...encounter, encounterServices: response.encounterServices }
          setEncounters(
            encounters?.map((el: EncounterDTO) =>
              el.id === encounter.id ? Object.assign({}, nextEncounter) : el
            )
          )
          setEncounter(nextEncounter)
          setLastUpdatedEncounterId(encounter?.id)
        } catch (error) {
          handleApiError(error)
        }
      },
      [
        services,
        setServices,
        encounter,
        encounters,
        setEncounter,
        setEncounters,
        setLastUpdatedEncounterId,
        handleApiError,
        preferredWarningDialog,
      ]
    )

    const onDeleteWarningClose = useCallback(() => {
      setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
    }, [deleteConfirmationDialog])

    const onDeleteWarningSave = useCallback(
      async (rowIndex: number) => {
        setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })

        const serviceToDelete = services.rows.find(
          (row: EncounterServiceRowDataType, index: number) => rowIndex === index
        )
        await EncounterServiceControllerService.deleteEncounterService(
          serviceToDelete?.encounterId as string,
          serviceToDelete?.id as string
        )
          .then(() => {
            showSnackSuccess(t('api.save-success'))
            const updatedRows = services.rows.filter(
              (row: EncounterServiceRowDataType, index: number) => {
                return rowIndex !== index
              }
            )
            setServices({ ...services, rows: updatedRows })
            if (updatedRows.length === 0) {
              const remainingEncounters = encounters?.filter(
                (encounter) => encounter.id !== serviceToDelete?.encounterId
              )
              setEncounters(remainingEncounters)
              if (remainingEncounters?.length) {
                setEncounter(remainingEncounters[0])
                setLastUpdatedEncounterId(remainingEncounters[0].id as string)
              } else setEncounter(undefined)
            } else {
              const nextEncounter = { ...encounter, encounterServices: updatedRows }
              setEncounters(
                encounters?.map((el: EncounterDTO) =>
                  el.id === encounter.id ? Object.assign({}, nextEncounter) : el
                )
              )
              setEncounter(nextEncounter)
            }
          })
          .catch((error) => {
            handleApiError(error)
          })
      },
      [
        deleteConfirmationDialog,
        encounter,
        encounters,
        handleApiError,
        services,
        setEncounter,
        setEncounters,
        setLastUpdatedEncounterId,
        setServices,
        showSnackSuccess,
        t,
      ]
    )

    useImperativeHandle(
      ref,
      () => ({
        save() {
          formsRef.current?.save()
          notesRef.current?.save()
        },
      }),
      []
    )

    const rowHeader = useCallback(
      (
        row: EncounterServiceRowDataType,
        rowIndex: number,
        onChangeRow: (
          rowIndex: number,
          field: string,
          value: boolean | number | string | CodedConceptDto
        ) => void
      ) => {
        return (
          <>
            <MISRadio
              checked={row.isPrimaryService}
              name="services"
              onChange={() => onChangeRow(rowIndex, 'isPrimaryService', !row.isPrimaryService)}
              onClick={(event) => event.stopPropagation()}
              sx={{ padding: GLOBAL.MARGIN_XS, pointerEvents: 'auto' }}
            />
            {row.synopsis && <span>{row.synopsis}</span>}
            {row.isPrimaryService && (
              <span>
                {' | '}
                <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{t('common.primary')}</span>
              </span>
            )}
            {row.state?.code && serviceStateOptions?.find((o) => o.code === row.state?.code) && (
              <span>
                {' | '}
                <StateChip
                  comment={row.comment}
                  label={serviceStateOptions.find((o) => o.code === row.state?.code)?.name || ''}
                  reason={
                    getEncounterServiceStateReasonOptions(row).find(
                      (o) => o.code === row.reason?.code
                    )?.name
                  }
                />
              </span>
            )}
            <StateSelector
              entityId={row.id as string}
              onGetNextStates={handleGetNextServiceStateOptions}
              onSelect={handleChangeServiceState}
              stateValues={getEncounterServiceStateValues(row)}
              warningText={t('common.state-selector.warning')}
            />
          </>
        )
      },
      [
        getEncounterServiceStateReasonOptions,
        getEncounterServiceStateValues,
        handleChangeServiceState,
        handleGetNextServiceStateOptions,
        serviceStateOptions,
        t,
      ]
    )

    const rowDataForm = useCallback(
      (
        row: EncounterServiceRowDataType,
        rowIndex: number,
        onChangeRow: (
          rowIndex: number,
          field: string,
          value: boolean | number | string | CodedConceptDto
        ) => void
      ) => {
        return (
          <>
            <Grid container spacing={2}>
              {programOptions && (
                <Grid item xs={3}>
                  <MISSelectDropdown
                    error={hasError(services?.errors, `encounter-service.${rowIndex}.programId`)}
                    helperText={getError(
                      services?.errors,
                      `encounter-service.${rowIndex}.programId`
                    )}
                    id="program"
                    label={t('encounter-service.program')}
                    onChange={(event) =>
                      onChangeRow(rowIndex, 'programId', event.target.value as CodedConceptDto)
                    }
                    options={getFormattedOptions(programOptions, true)}
                    required
                    value={programOptions.find((o) => o.id === row.programId)?.id}
                  />
                </Grid>
              )}
              {purposeOptions && (
                <Grid item xs={3}>
                  <MISSelectDropdown
                    error={hasError(services?.errors, `encounter-service.${rowIndex}.purposeId`)}
                    helperText={getError(
                      services?.errors,
                      `encounter-service.${rowIndex}.purposeId`
                    )}
                    id="purpose"
                    label={t('encounter-service.purpose')}
                    onChange={(event) =>
                      onChangeRow(rowIndex, 'purposeId', event.target.value as CodedConceptDto)
                    }
                    options={getFormattedOptions(purposeOptions, true)}
                    required
                    value={purposeOptions.find((o) => o.id === row.purposeId)?.id}
                  />
                </Grid>
              )}
              {typeOptions && (
                <Grid item xs={3}>
                  <MISSelectDropdown
                    error={hasError(services?.errors, `encounter-service.${rowIndex}.typeId`)}
                    helperText={getError(services?.errors, `encounter-service.${rowIndex}.typeId`)}
                    id="type"
                    label={t('encounter-service.type')}
                    onChange={(event) =>
                      onChangeRow(rowIndex, 'typeId', event.target.value as CodedConceptDto)
                    }
                    options={getFormattedOptions(typeOptions, true)}
                    required
                    value={typeOptions.find((o) => o.id === row.typeId)?.id}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <MISAutocomplete
                  error={hasError(services?.errors, `encounter-service.${rowIndex}.location`)}
                  helperText={getError(services?.errors, `encounter-service.${rowIndex}.location`)}
                  label={t('encounter-service.location')}
                  onChange={(location) => onChangeRow(rowIndex, 'locationOfService', location?.id)}
                  options={locationOfService}
                  required
                  value={locationOfService?.find((o) => o.id === row?.locationOfService) || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <MISTextField
                  error={hasError(services?.errors, `encounter-service.${rowIndex}.synopsis`)}
                  helperText={getError(services?.errors, `encounter-service.${rowIndex}.synopsis`)}
                  label={t('encounter-service.synopsis')}
                  onChange={(event) => onChangeRow(rowIndex, 'synopsis', event.target.value)}
                  required
                  value={row?.synopsis}
                />
              </Grid>
              <Grid item xs={3}>
                <MISDurationField
                  error={hasError(services?.errors, `encounter-service.${rowIndex}.duration`)}
                  helperText={getError(services?.errors, `encounter-service.${rowIndex}.duration`)}
                  label={t('encounter-service.duration')}
                  onChange={(value) => onChangeRow(rowIndex, 'duration', value as number)}
                  required={!!row?.esdrtCategoryId && !!row?.esdrtServiceId}
                  value={row?.duration}
                />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={3} />
              {row?.esdrtCategoryId && row?.esdrtServiceId && (
                <Grid item xs={4}>
                  <Box sx={{ color: '#333', fontSize: '12px', textAlign: 'right' }}>
                    <span>{getEsdrtInfo(row.esdrtCategoryId, row.esdrtServiceId)}</span>
                  </Box>
                </Grid>
              )}
            </Grid>
            {encounter?.id && row?.id && (
              <EncounterServiceForms
                encounterId={encounter.id}
                ref={formsRef}
                serviceId={row?.id}
              />
            )}
            {encounter?.id && row?.id && (
              <EncounterServiceNotes
                encounterId={encounter.id}
                ref={notesRef}
                serviceId={row?.id}
              />
            )}
            {encounter?.id && row?.id && (
              <EncounterServicesStaff encounterId={encounter.id} encounterServiceId={row?.id} />
            )}
          </>
        )
      },
      [
        encounter.id,
        getEsdrtInfo,
        locationOfService,
        programOptions,
        purposeOptions,
        services?.errors,
        t,
        typeOptions,
      ]
    )

    return (
      <>
        <MISButton
          color="primary"
          onClick={() => setOpenAddEditForm(true)}
          size="large"
          startIcon={<AddCircleOutline />}
          sx={{ ml: 1, mt: 2, p: 1 }}
          variant="text"
        >
          {`${t('common.button.add')} Service`}
        </MISButton>
        {locationOfService ? (
          <MISAccordionContainer
            disableDelete={(rowIndex: number) => disableServiceDelete(rowIndex)}
            emptyDataMessage={t('encounter-service.empty-message')}
            errors={services.errors.length > 0 ? [t('common.validation.errors')] : []}
            onChangeRow={onChangeRow}
            onCollapseRow={onCollapseRow}
            onRemoveRow={(rowIndex: number) => {
              setDeleteConfirmationDialog({ index: rowIndex, open: true })
            }}
            rowForm={rowDataForm}
            rowHeader={rowHeader}
            rows={services.rows}
          />
        ) : (
          <Loader height={100} />
        )}
        {client.id && openAddEditForm && (
          <AddEncounterDialog
            clientId={client.id}
            encounterDate={encounter?.encounterDateTime}
            encounterId={encounter?.id}
            entityName="Encounter Service"
            onCloseCallback={() => setOpenAddEditForm(false)}
            onSaveCallback={handleSaveCallBack}
            openDialog={openAddEditForm}
          />
        )}
        <WarningDialog
          entity={ENTITY}
          onCancel={() => onDeleteWarningClose()}
          onSave={() => onDeleteWarningSave(deleteConfirmationDialog.index)}
          open={deleteConfirmationDialog.open}
          type={MODALS.DELETE_WARNING}
        />
        <WarningDialog
          entity={ENTITY}
          onCancel={() => onPreferredWarningClose()}
          onSave={() => onPreferredWarningSave(preferredWarningDialog.data)}
          open={preferredWarningDialog.open}
          type={MODALS.PREFERRED_WARNING}
        />
      </>
    )
  }
)
EncounterServicesSection.displayName = 'EncounterServicesSection'

export default EncounterServicesSection
