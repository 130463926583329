import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISChip from 'common/components/form/MISChip'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISDrawer from 'common/components/form/MISDrawer'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getPersonnelFullName } from 'modules/shared/clientUtils'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import {
  displayChipsUtil,
  evaluateChipsUtil,
  evaluateLabelUtil,
} from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { ErrorType, getError, getFormattedOptions, hasError } from 'modules/shared/utils'
import { functionalAreasAtom, programsAtom } from 'recoil/atoms'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  EncounterServiceTemplateControllerService,
  EncounterServiceTemplateDTO,
  ListFunctionalAreasDTO,
  PersonnelDTO,
  Program,
} from 'services/openapi'
import { MIS_ENCOUNTER_NOTE_STATE } from 'services/terminologyConstants'

type RunningNotesFiltersProps = {
  applyFilters: boolean
  serviceTemplateIdsInUse: string[]
  filters: FilterProps[]
  providers: PersonnelDTO[]
  setApplyFilters: Dispatch<SetStateAction<boolean>>
  setFilters: Dispatch<SetStateAction<FilterProps[]>>
}

type DisplayFiltersProps = {
  label?: string
  filterIndex: number
  childFilterIndex?: number
  key?: string
}

const RunningNotesFilters = ({
  applyFilters,
  filters = [],
  providers,
  serviceTemplateIdsInUse = [],
  setApplyFilters,
  setFilters,
}: RunningNotesFiltersProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const [isOpen, setIsOpen] = useState(false)
  const [errors, setErrors] = useState<ErrorType[]>([])
  const programs = useRecoilValue(programsAtom)
  const noteStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_NOTE_STATE))
  const functionalAreas = useRecoilValue(functionalAreasAtom)
  const [serviceTemplatesInUse, setServiceTemplatesInUse] = useState<EncounterServiceTemplateDTO[]>(
    []
  )
  const [displayFilters, setDisplayFilters] = useState<DisplayFiltersProps[]>([])

  useEffect(() => {
    const fetchEncounterTemplates = async () => {
      try {
        const result = await EncounterServiceTemplateControllerService.organizationgetPagedList()
        if (result?.content) {
          setServiceTemplatesInUse(
            result.content.filter(
              (template) => template.id && serviceTemplateIdsInUse.includes(template.id)
            )
          )
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    fetchEncounterTemplates()
  }, [handleApiError, serviceTemplateIdsInUse])

  const updateFilter = (key: string, value: any) => {
    setApplyFilters(false)
    const filterObj = {
      key: key,
      value: value,
    }
    const foundIndex = filters?.findIndex((filter) => filter.key === key)
    if (foundIndex !== -1) {
      filters[foundIndex] = filterObj
    } else {
      filters.push(filterObj)
    }
    setFilters([...filters])
  }

  const getDefaultFromDate = () => {
    const oneYearPriorDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    oneYearPriorDate.setHours(0, 0, 0, 0)
    return DateTime.fromJSDate(oneYearPriorDate).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
  }

  const renderFormComponent = () => {
    return (
      <Box
        sx={{
          '& .MuiFormControl-root': {
            width: '100%',
          },
          m: 4,
          width: 400,
        }}
      >
        <MISAutocomplete
          label={t('running-notes.author')}
          onChange={(value) => updateFilter('author', value || null)}
          options={providers.map((provider: PersonnelDTO) => {
            return {
              ...provider,
              label: evaluateLabelUtil(provider?.names),
            }
          })}
          renderOption={(props, provider) => {
            const label = evaluateLabelUtil(provider?.names)
            const chips = evaluateChipsUtil(provider?.jobFunctions)
            return (
              <Stack
                {...props}
                direction="row"
                key={label}
                sx={{
                  '&.MuiAutocomplete-option': {
                    justifyContent: 'space-between',
                  },
                  cursor: 'pointer',
                  p: 1,
                }}
              >
                <span>{label}</span>
                <span>{displayChipsUtil(chips)}</span>
              </Stack>
            )
          }}
          value={filters.find(({ key }) => key === 'author')?.value}
        />
        <br />
        <MISSelectMultiDropdown
          error={hasError(errors, 'sources')}
          errorMessage={getError(errors, 'sources')}
          label={t('running-notes.sources')}
          onChange={(event) => updateFilter('sources', event.target.value || null)}
          options={functionalAreas.map((functionalArea: ListFunctionalAreasDTO) => {
            return {
              label: functionalArea?.displayName,
              value: functionalArea,
            }
          })}
          placeholder={t('running-notes.all-sources')}
          required
          value={filters.find(({ key }) => key === 'sources')?.value || []}
        />
        <br />
        <MISSelectMultiDropdown
          label={t('running-notes.note-statuses')}
          onChange={(event) => updateFilter('noteStatuses', event.target.value || null)}
          options={getFormattedOptions(noteStateOptions)}
          placeholder={t('running-notes.all-note-statuses')}
          value={filters.find(({ key }) => key === 'noteStatuses')?.value || []}
        />
        <br />
        <MISSelectMultiDropdown
          label={t('running-notes.programs')}
          onChange={(event) => updateFilter('programs', event.target.value || null)}
          options={getFormattedOptions(programs)}
          placeholder={t('running-notes.all-programs')}
          value={filters.find(({ key }) => key === 'programs')?.value || []}
        />
        <br />
        <MISSelectMultiDropdown
          label={t('running-notes.service-templates')}
          onChange={(event) => updateFilter('serviceTemplates', event.target.value || null)}
          options={getFormattedOptions(serviceTemplatesInUse)}
          placeholder={t('running-notes.all-service-templates')}
          value={filters.find(({ key }) => key === 'serviceTemplates')?.value || []}
        />
        <br />
        <Box sx={{ display: 'inline-flex', verticalAlign: 'bottom', width: '45% !important' }}>
          <MISDatePicker
            error={hasError(errors, 'fromDate')}
            helperText={getError(errors, 'fromDate')}
            label={t('common.labels.start-date')}
            onChange={(value) => updateFilter('fromDate', value || null)}
            placeholder="yyyy-month-dd"
            required
            value={filters.find(({ key }) => key === 'fromDate')?.value || ''}
          />
        </Box>
        <Typography
          sx={{
            display: 'inline-flex',
            margin: '2.5%',
            padding: '4px',
            verticalAlign: 'bottom',
            width: '5%',
          }}
        >
          {t('running-notes.to')}
        </Typography>
        <Box sx={{ display: 'inline-flex', verticalAlign: 'bottom', width: '45% !important' }}>
          <MISDatePicker
            error={hasError(errors, 'toDate')}
            helperText={getError(errors, 'toDate')}
            label={t('common.labels.end-date')}
            onChange={(value) => updateFilter('toDate', value || null)}
            placeholder="yyyy-month-dd"
            required
            value={filters.find(({ key }) => key === 'toDate')?.value || ''}
          />
        </Box>
        <MISButton
          color="primary"
          onClick={handleSearch}
          size="large"
          sx={{
            alignItems: 'center',
            bottom: 0,
            left: 0,
            m: '5%',
            position: 'absolute',
            width: '90%',
          }}
        >
          {t('running-notes.apply')}
        </MISButton>
      </Box>
    )
  }

  const handleSearch = useCallback(() => {
    const validate = () => {
      const errors: ErrorType[] = []
      if (!filters.find(({ key }) => key === 'sources')?.value) {
        errors.push({
          field: 'sources',
          message: t('running-notes.filters.sources-required'),
        })
      }
      if (!filters.find(({ key }) => key === 'fromDate')?.value) {
        errors.push({
          field: 'fromDate',
          message: t('running-notes.filters.from-date-required'),
        })
      }
      if (!filters.find(({ key }) => key === 'toDate')?.value) {
        errors.push({
          field: 'toDate',
          message: t('running-notes.filters.to-date-required'),
        })
      }
      setErrors(errors)
      return errors
    }

    if (isOpen) {
      const errors = validate()
      if (errors.length === 0) {
        setIsOpen(false)
        setApplyFilters(true)
      }
    } else {
      setApplyFilters(true)
    }
  }, [filters, isOpen, setApplyFilters, t])

  useEffect(() => {
    const displayFilters: DisplayFiltersProps[] = []
    filters.forEach(({ key, value }, index) => {
      switch (key) {
        case 'author':
          displayFilters.push({
            filterIndex: index,
            label: getPersonnelFullName(value),
          })
          break
        case 'sources':
          value.forEach((source: ListFunctionalAreasDTO, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: source.displayName,
            })
          })
          break
        case 'noteStatuses':
          value.forEach((status: CodedConceptDto, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: status.name,
            })
          })
          break
        case 'programs':
          value.forEach((program: Program, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: program.name,
            })
          })
          break
        case 'serviceTemplates':
          value.forEach((template: EncounterServiceTemplateDTO, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: template.name,
            })
          })
          break
      }
    })
    const from = filters.find(({ key }) => key === 'fromDate')?.value || ''
    const to = filters.find(({ key }) => key === 'toDate')?.value || ''
    if (from || to) {
      displayFilters.push({
        filterIndex: -1, // not used
        key: 'date',
        label: `${isoDateToDisplayFormat(from)}${to ? ' to ' + isoDateToDisplayFormat(to) : ''}`,
      })
    }
    setDisplayFilters([...displayFilters])
  }, [filters])

  return (
    <>
      <Box>
        <Box
          sx={{
            '& .MuiFormControl-root': {
              '& .MuiTextField-root': {
                marginRight: '1%',
                width: '99%',
              },
              width: '50%',
            },
          }}
        >
          <MISTextField
            label={t('running-notes.search-notes')}
            onChange={(event) => updateFilter('searchText', event.target.value || null)}
            value={filters.find((filter) => filter?.key === 'searchText')?.value}
          />
          <MISButton
            color="primary"
            onClick={handleSearch}
            size="large"
            sx={{
              height: '44px',
              margin: '8px',
              verticalAlign: 'bottom',
            }}
          >
            {t('common.button.search')}
          </MISButton>
        </Box>
        <Box>
          <MISButton
            onClick={() => {
              setIsOpen(true)
              const fromDateFilterIdx = filters.findIndex(({ key }) => key === 'fromDate')
              if (fromDateFilterIdx === -1) {
                updateFilter('fromDate', getDefaultFromDate())
              }
            }}
            size="large"
            startIcon={<TuneIcon />}
            sx={{ color: 'black', px: 0.5 }}
            variant="text"
          >
            {t('running-notes.open-filters')}
          </MISButton>
          {applyFilters &&
            displayFilters.map(
              ({ childFilterIndex, filterIndex, key, label }: DisplayFiltersProps) => {
                return (
                  <MISChip
                    key={key}
                    label={label}
                    onDelete={() => {
                      if (key === 'date') {
                        const fromDateFilterIdx = filters.findIndex(({ key }) => key === 'fromDate')
                        if (fromDateFilterIdx !== -1) {
                          filters.splice(fromDateFilterIdx, 1)
                        }
                        const toDateFilterIdx = filters.findIndex(({ key }) => key === 'toDate')
                        if (toDateFilterIdx !== -1) {
                          filters.splice(toDateFilterIdx, 1)
                        }
                      } else {
                        if (childFilterIndex !== undefined) {
                          filters[filterIndex]?.value.splice(childFilterIndex, 1)
                        } else {
                          filters.splice(filterIndex, 1)
                        }
                      }
                      setFilters([...filters])
                      setApplyFilters(true)
                    }}
                    sx={{ mx: 0.5 }}
                    variant="outlined"
                  />
                )
              }
            )}
        </Box>
      </Box>
      <MISDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderFormComponent()}
      </MISDrawer>
    </>
  )
}

export default RunningNotesFilters
