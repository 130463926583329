/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentResponseDTO } from '../models/ContentResponseDTO'
import type { PagePersonnelTrainingDTO } from '../models/PagePersonnelTrainingDTO'
import type { PersonnelTrainingDTO } from '../models/PersonnelTrainingDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelTrainingControllerService {
  /**
   * Returns a paged list of Training for Personnel
   * List is returned as Paged collection
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelTrainingDTO OK
   * @throws ApiError
   */
  public static getPersonnelTrainings(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelTrainingDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/training',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Updates the list of training for personnel
   * updates the list and then returns as UnPaged collection
   * @param personnelId
   * @param requestBody
   * @returns PersonnelTrainingDTO OK
   * @throws ApiError
   */
  public static savePersonnelTraining(
    personnelId: string,
    requestBody: Array<PersonnelTrainingDTO>
  ): CancelablePromise<Array<PersonnelTrainingDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/training',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * search all courses
   * returns the courses by matching with course, if course is not proviced then returns all the courses
   * @param course
   * @returns ContentResponseDTO OK
   * @throws ApiError
   */
  public static searchCourses(course?: string): CancelablePromise<ContentResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/training/courses',
      query: {
        course: course,
      },
    })
  }

  /**
   * search all course providers
   * returns the course providers by matching with courseProvider, if courseProvider is not proviced then returns all the course providers
   * @param courseProvider
   * @returns ContentResponseDTO OK
   * @throws ApiError
   */
  public static searchCourseProviders(
    courseProvider?: string
  ): CancelablePromise<ContentResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/training/course-providers',
      query: {
        courseProvider: courseProvider,
      },
    })
  }

  /**
   * Delete training by trainingId
   * Soft deletes the training by setting deleted flag to true
   * @param personnelId
   * @param trainingId
   * @returns any OK
   * @throws ApiError
   */
  public static deletePersonnelTraining(
    personnelId: string,
    trainingId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/training/{trainingId}',
      path: {
        personnelId: personnelId,
        trainingId: trainingId,
      },
    })
  }
}
