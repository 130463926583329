/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SecuredObject } from './SecuredObject'

export type Permission = {
  permissionType?: Permission.permissionType
  entity?: SecuredObject
  actions?: Array<'read' | 'create' | 'update' | 'delete' | 'denied' | 'all'>
  comments?: Array<string>
}

export namespace Permission {
  export enum permissionType {
    ENTITY = 'entity',
    FIELD = 'field',
  }
}
