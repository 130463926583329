/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelJobFunctionDTO } from '../models/PagePersonnelJobFunctionDTO'
import type { PersonnelJobFunctionDTO } from '../models/PersonnelJobFunctionDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelJobFunctionControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelJobFunctionDTO OK
   * @throws ApiError
   */
  public static getPersonnelJobFunctionList(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelJobFunctionDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelJobFunctionDTO OK
   * @throws ApiError
   */
  public static saveJobFunctions(
    personnelId: string,
    requestBody: Array<PersonnelJobFunctionDTO>
  ): CancelablePromise<Array<PersonnelJobFunctionDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelJobFunctionDTO OK
   * @throws ApiError
   */
  public static createPersonnelJobFunction(
    personnelId: string,
    requestBody: PersonnelJobFunctionDTO
  ): CancelablePromise<PersonnelJobFunctionDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param personnelId
   * @param jobFunctionId
   * @returns PersonnelJobFunctionDTO OK
   * @throws ApiError
   */
  public static getPersonnelJobFunction(
    personnelId: string,
    jobFunctionId: string
  ): CancelablePromise<PersonnelJobFunctionDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions/{jobFunctionId}',
      path: {
        personnelId: personnelId,
        jobFunctionId: jobFunctionId,
      },
    })
  }

  /**
   * @param personnelId
   * @param jobFunctionId
   * @param requestBody
   * @returns PersonnelJobFunctionDTO OK
   * @throws ApiError
   */
  public static updatePersonnelJobFunction(
    personnelId: string,
    jobFunctionId: string,
    requestBody: PersonnelJobFunctionDTO
  ): CancelablePromise<PersonnelJobFunctionDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions/{jobFunctionId}',
      path: {
        personnelId: personnelId,
        jobFunctionId: jobFunctionId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param personnelId
   * @param jobFunctionId
   * @returns any OK
   * @throws ApiError
   */
  public static deletePersonnelJobFunction(
    personnelId: string,
    jobFunctionId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions/{jobFunctionId}',
      path: {
        personnelId: personnelId,
        jobFunctionId: jobFunctionId,
      },
    })
  }

  /**
   * @param personnelId
   * @param jobFunctionId
   * @param fteId
   * @returns any OK
   * @throws ApiError
   */
  public static deletePersonnelJobFunctionFte(
    personnelId: string,
    jobFunctionId: string,
    fteId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/job-functions/{jobFunctionId}/fullTimeEquivalency/{fteId}',
      path: {
        personnelId: personnelId,
        jobFunctionId: jobFunctionId,
        fteId: fteId,
      },
    })
  }
}
