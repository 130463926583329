import { ReactNode } from 'react'
import { Grid, Paper } from '@mui/material'

type InfoCardContainerProps = {
  children: ReactNode
  xs: number
}

const InfoCardContainer = ({ children, xs }: InfoCardContainerProps) => {
  return (
    <Grid item minHeight="152px" xs={xs}>
      <Paper sx={{ backgroundColor: '#fff', height: '100%', p: 2, width: '100%' }}>
        {children}
      </Paper>
    </Grid>
  )
}

export default InfoCardContainer
