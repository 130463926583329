import {
  createContext,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { CircularProgress, Dialog, DialogContent, Fade } from '@mui/material'
import axios, { AxiosRequestConfig } from 'axios'

const DEBOUNCE_TIME = 500

const EXCLUDED_API_PATH = [
  '/v1/addresses?addressText',
  '/v1/documents',
  '/v1/encounter-service-templates?fullText',
  '/v1/form-schemas/search',
  '/v1/governance-agencies',
  '/v1/location?addressText',
  '/v1/personnel/addresses',
  '/birth-details',
  '/coded-concepts/search?searchText',
  '/encounter-services',
  '/forms',
  '/health-concerns',
  '/next-states',
  '/notes?page',
  '/relationships?relationshipTypeCodes',
  '/roles?name',
  '/staff?',
  '/training/courses',
  '/training/course-providers',
  '/users?searchText',
  '/users/find-by?username',
]

export const ProgressContext = createContext({
  hideProgress: () => {
    return
  },
  showProgress: (_: AxiosRequestConfig) => {
    return
  },
})

type ProgressProviderProps = {
  children: ReactNode
}

type TransitionProps = {
  children: ReactElement
}

const Transition = forwardRef((props: TransitionProps, ref) => {
  return <Fade in ref={ref} {...props} />
})
Transition.displayName = 'Transition'

export const ProgressProvider = ({ children }: ProgressProviderProps) => {
  const [open, setOpen] = useState(false)
  const hideTimerRef = useRef<NodeJS.Timeout | null>(null)

  const showProgress = useCallback((config: AxiosRequestConfig) => {
    const isCharting = config.method === 'get' && window.location.pathname.includes('/charting')
    const isClientDetails =
      config.method === 'get' &&
      window.location.pathname.includes('/clients/client-record/') &&
      window.location.pathname.split('/').length === 5
    const isClientEncounterRequestingPersonnel =
      config.method === 'get' &&
      (config.url?.includes('/v1/clients') || config.url?.includes('/v1/personnel')) &&
      window.location.pathname.includes('/clients/client-record/') &&
      window.location.pathname.endsWith('/encounters')
    const filteredApiPaths = EXCLUDED_API_PATH.filter((path) => {
      return config.method === 'get' && config.url?.includes(path)
    })
    setOpen(
      filteredApiPaths.length === 0 &&
        !isCharting &&
        !isClientDetails &&
        !isClientEncounterRequestingPersonnel
    )
  }, [])

  const hideProgress = useCallback(() => {
    if (hideTimerRef.current !== null) {
      clearTimeout(hideTimerRef.current)
    }
    hideTimerRef.current = setTimeout(() => {
      setOpen(false)
    }, DEBOUNCE_TIME)
  }, [])

  // Add axios interceptors to show the progress bar when API calls are made
  // and hide it when they are finished.
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        showProgress(config)
        return config
      },
      function (error) {
        hideProgress()
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => {
        hideProgress()
        return response
      },
      (error) => {
        hideProgress()
        return Promise.reject(error)
      }
    )
  }, [showProgress, hideProgress])

  return (
    <ProgressContext.Provider value={{ hideProgress, showProgress }}>
      <Dialog TransitionComponent={Transition} open={open}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      {children}
    </ProgressContext.Provider>
  )
}
