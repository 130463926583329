import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import useProviders from 'modules/shared/hooks/useProviders'
import { ChartingEntryControllerService } from 'services/openapi'
import { selectUserId } from 'store/selectors/user'
import UserDashboardEntry from './UserDashboardEntry'
import UserDashboardTable from './UserDashboardTable'
import { isDateBeforeOrEqualDate } from '../../../common/utils/DateUtils'

export type TDashboardEntry = {
  id: string
  clientId?: string
  template: TTemplateBlot
}

const UserDashboard = () => {
  const providers = useProviders()
  const userId = useSelector(selectUserId)

  const [followUp, setFollowUp] = useState<TDashboardEntry>()
  const [followUps, setFollowUps] = useState<TDashboardEntry[]>([])

  const provider = useMemo(
    () => providers?.find((provider) => provider.userId === userId),
    [providers, userId]
  )

  const handleSelectEntry = useCallback(
    (id: string) => setFollowUp(followUps?.find((each) => each.template.templateRecordId === id)),
    [followUps]
  )

  useEffect(() => {
    const getFollowUps = async (providerId: string) => {
      const followUpsResp = await ChartingEntryControllerService.getFilteredChartingEntries1(
        'FollowUpTemplate',
        undefined,
        [`assignedTo.id|eq|${providerId}`],
        true
      )
      if (followUpsResp?.length) {
        const followUps: TDashboardEntry[] = []
        followUpsResp.forEach((each) => {
          const clientId = each.clientId?.length > 0 ? each.clientId[0] : undefined
          const id = each.id
          each.chartingTemplateBlot.forEach((template) => {
            followUps.push({ clientId, id, template })
          })
        })
        const followUpsWithDueDate = followUps.filter((each) => each.template.templateData.dueDate)
        followUpsWithDueDate.sort((a, b) =>
          isDateBeforeOrEqualDate(
            a.template.templateData.dueDate as string,
            b.template.templateData.dueDate as string
          )
            ? -1
            : 1
        )
        const followUpsWithoutDueDate = followUps.filter(
          (each) => !each.template.templateData.dueDate
        )
        const sortedFollowUps = [...followUpsWithDueDate, ...followUpsWithoutDueDate]
        setFollowUps(sortedFollowUps)
      } else setFollowUps([])
    }

    if (provider?.id) getFollowUps(provider.id)
  }, [provider])

  return (
    <MISBaseContainer>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UserDashboardTable entries={followUps} onSelect={handleSelectEntry} />
      </LocalizationProvider>
      {followUp && (
        <UserDashboardEntry
          clientId={followUp.clientId}
          entry={followUp.template}
          id={followUp.id}
          onClose={() => setFollowUp(undefined)}
        />
      )}
    </MISBaseContainer>
  )
}

export default UserDashboard
