import {
  AbilityBuilder,
  AbilityTuple,
  createMongoAbility,
  defineAbility,
  MongoAbility,
  MongoQuery,
} from '@casl/ability'
import { PermissionType } from './permissions'

const ability = defineAbility((can) => {
  can('read', 'Home')
})

export const updateAbility = (
  ability: MongoAbility<AbilityTuple, MongoQuery>,
  permissions: PermissionType[]
) => {
  const { can, rules } = new AbilityBuilder(createMongoAbility)

  permissions.forEach((element) => {
    element.actions.forEach((op) => {
      can(op, element.entity)
    })
  })

  ability.update(rules)
}

export default ability
