/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncounterDTO } from '../models/EncounterDTO'
import type { PageEncounterDTO } from '../models/PageEncounterDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterControllerService {
  /**
   * Get an Encounter by id
   * @param id
   * @returns EncounterDTO OK
   * @throws ApiError
   */
  public static encountersgetById(id: string): CancelablePromise<EncounterDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * Update an Encounter
   * The properties of the Encounter are specified in the JSON request body.
   * @param id
   * @param requestBody
   * @returns EncounterDTO OK
   * @throws ApiError
   */
  public static updateEncounter(
    id: string,
    requestBody: EncounterDTO
  ): CancelablePromise<EncounterDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-encounter-service/v1/encounters/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get paged list of Encounters with optional search params
   * The name param allows searching for items with a name containing name. Use the  param to return only items where effectiveAt is within the effective date rang.eAll items are returned if effectiveAt is not specified, including terminated items.
   * @param parentEntityId
   * @param parentEntityType
   * @param synopsis
   * @param name
   * @param terminated
   * @param matchPrimaryServiceOnly
   * @param encounterServicesToInclude
   * @param encounterServiceDateFrom
   * @param encounterServiceDateTo
   * @param encounterServiceStatus
   * @param durationFrom
   * @param durationTo
   * @param mostRecent
   * @param programs
   * @param serviceTemplates
   * @param includeNotes
   * @param noteText
   * @param noteCreatedBy
   * @param noteStatus
   * @param noteUpdateDateFrom
   * @param noteUpdateDateTo
   * @param effectiveAt
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterDTO OK
   * @throws ApiError
   */
  public static encountersgetPagedList(
    parentEntityId: string,
    parentEntityType: string,
    synopsis?: string,
    name?: string,
    terminated?: boolean,
    matchPrimaryServiceOnly: boolean = false,
    encounterServicesToInclude: string = 'PRIMARY_ONLY',
    encounterServiceDateFrom?: string,
    encounterServiceDateTo?: string,
    encounterServiceStatus?: Array<string>,
    durationFrom?: number,
    durationTo?: number,
    mostRecent: boolean = false,
    programs?: Array<string>,
    serviceTemplates?: Array<string>,
    includeNotes: boolean = false,
    noteText?: string,
    noteCreatedBy?: string,
    noteStatus?: Array<string>,
    noteUpdateDateFrom?: string,
    noteUpdateDateTo?: string,
    effectiveAt?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters',
      query: {
        parentEntityId: parentEntityId,
        parentEntityType: parentEntityType,
        synopsis: synopsis,
        name: name,
        terminated: terminated,
        matchPrimaryServiceOnly: matchPrimaryServiceOnly,
        encounterServicesToInclude: encounterServicesToInclude,
        encounterServiceDateFrom: encounterServiceDateFrom,
        encounterServiceDateTo: encounterServiceDateTo,
        encounterServiceStatus: encounterServiceStatus,
        durationFrom: durationFrom,
        durationTo: durationTo,
        mostRecent: mostRecent,
        programs: programs,
        serviceTemplates: serviceTemplates,
        includeNotes: includeNotes,
        noteText: noteText,
        noteCreatedBy: noteCreatedBy,
        noteStatus: noteStatus,
        noteUpdateDateFrom: noteUpdateDateFrom,
        noteUpdateDateTo: noteUpdateDateTo,
        effectiveAt: effectiveAt,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create an Encounter
   * @param requestBody
   * @returns EncounterDTO OK
   * @throws ApiError
   */
  public static createEncounter(requestBody: EncounterDTO): CancelablePromise<EncounterDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-encounter-service/v1/encounters',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
