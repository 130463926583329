import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ValueSetType } from 'core/Types'

export interface TerminologyState {
  terminology: ValueSetType[]
}

const initialState: TerminologyState = {
  terminology: [],
}

export const terminologySlice = createSlice({
  initialState,
  name: 'terminology',
  reducers: {
    setTerminology: (state, action: PayloadAction<ValueSetType[]>) => {
      state.terminology = action.payload
    },
  },
})

export const { setTerminology } = terminologySlice.actions

export default terminologySlice.reducer
