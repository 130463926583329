import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { PersonnelControllerService } from 'services/openapi'
import { setProviders } from 'store/reducers/provider'
import { selectProviders } from 'store/selectors/provider'
import { evaluateLabelUtil } from '../StaffAssociation/StaffAssociationUtils'

const useProviders = () => {
  const dispatch = useDispatch()
  const providers = useSelector(selectProviders)
  const { handleApiError } = useErrorHandler()

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const result = await PersonnelControllerService.searchPersonnel(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          1000000
        )
        const sortedProviders =
          result?.content?.sort((a, b) =>
            evaluateLabelUtil(a.names) < evaluateLabelUtil(b.names) ? -1 : 1
          ) || []
        dispatch(setProviders(sortedProviders))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (providers === undefined) fetchProviders()
  }, [dispatch, handleApiError, providers])

  return providers
}

export default useProviders
