import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { programGroupAtom, programsAtom } from 'recoil/atoms'
import {
  BusinessLineControllerService,
  ProgramGroupAssociationControllerService,
} from 'services/openapi'

export default function BusinessLines() {
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState({})

  const [associations, setAssociations] = useState(new Map())
  const [otherEntities, setOtherEntities] = useState([])
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [refetchTrigger, setRefetchTrigger] = useState(0)

  const programs = useRecoilValue(programsAtom)

  const getEntitiesServiceCall = BusinessLineControllerService.listProgramGroups
  const createEntityServiceCall = BusinessLineControllerService.createProgramGroup
  const updateEntityServiceCall = BusinessLineControllerService.updateProgramGroup
  const updateAtomCall = useSetRecoilState(programGroupAtom)

  const openLinkModalCallBack = () => {
    setOpenLinkModal(true)
  }

  const closeLinkModalCallBack = () => {
    setOpenLinkModal(false)
  }

  const savePGAssociations = (associations) => {
    saveAssociations(
      associations,
      ProgramGroupAssociationControllerService.updateProgramGroupAssociation,
      BusinessLineControllerService.addProgramToProgramGroup,
      setOpenLinkModal,
      setRefetchTriggerPlusOne,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const setRefetchTriggerPlusOne = () => {
    setRefetchTrigger(refetchTrigger + 1)
  }

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      BusinessLineControllerService.getProgramGroupAssociationsForProgramGroup(selectedRow.id).then(
        (data) => {
          setOtherEntities(programs)
          buildAssociationMapUtil(
            data,
            programs,
            'programId',
            setAssociations,
            setOtherEntities,
            handleApiError,
            selectedRow
          )
        },
        (error) => {
          handleApiError(error)
        }
      )
    }
  }, [selectedRow, refetchTrigger, handleApiError, programs])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('program-groups.title.program-groups')}</h1>
        {t('program-groups.title.program-groups-description')}

        <EntityTemplate
          createEntityServiceCall={createEntityServiceCall}
          entityName={t('program-groups.title.program-groups')}
          getEntitiesServiceCall={getEntitiesServiceCall}
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={updateEntityServiceCall}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box sx={{ p: 2 }}>
          <h3>
            {t('program-groups.title.association-header')} {selectedRow.name}
          </h3>
          <AssociationTable
            associations={associations}
            closeLinkModalCallBack={closeLinkModalCallBack}
            entity={selectedRow}
            headerName={t('programs.title.programs')}
            openLinkModal={openLinkModal}
            openLinkModalCallBack={openLinkModalCallBack}
            otherEntities={otherEntities}
            saveCallBack={savePGAssociations}
          />
        </Box>
      )}
    </>
  )
}
