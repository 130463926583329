import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Typography } from '@mui/material'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { dateNowIsoString, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { CodedConceptDto, CodeSystemControllerService } from 'services/openapi'
import {
  ALLERGY_INTOLERANCE_SEVERITY_CODES,
  ALLERGY_INTOLERANCE_TYPE_CODES,
  LIFE_STAGE_CODES,
  REQUESTED_BY_CODES,
} from 'services/terminologyConstants'
import { selectChartingValidationActive } from 'store/selectors/charting'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import { AllergyTemplateState, TAllergyTemplateValue } from './AllergyTemplate'

const ALLERGEN_CODE_SYSTEM_OID = '2.16.840.1.113883.3.1019.1.14'

type AllergyTemplateComponentProps = AllergyTemplateState & {
  onChange?: (key: string, value: TAllergyTemplateValue) => void
}

const AllergyTemplateComponent = ({
  allergen,
  allergenOther,
  allergenSpecified,
  createdBy,
  createdOn,
  lastUpdated,
  lifeStage,
  note,
  onChange,
  reaction,
  reportedBy,
  severity,
  type,
}: AllergyTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const userId = useSelector(selectUserId)
  const validationActive = useSelector(selectChartingValidationActive)

  const providers = useProviders()

  const [initialized, setInitialized] = useState(false)
  const [loadingTypeAhead, setLoadingTypeAhead] = useState(false)
  const [searchStr, setSearchStr] = useState('')
  const [allergenOpts, setAllergenOpts] = useState<CodedConceptDto[]>([])

  const allergyType = useMemo(
    () => terminology.find((term) => term.setName === ALLERGY_INTOLERANCE_TYPE_CODES)?.value || [],
    [terminology]
  )

  const allergySeverity = useMemo(
    () =>
      terminology.find((term) => term.setName === ALLERGY_INTOLERANCE_SEVERITY_CODES)?.value || [],
    [terminology]
  )

  const lifeStageOpts = useMemo(
    () => terminology.find((term) => term.setName === LIFE_STAGE_CODES)?.value || [],
    [terminology]
  )

  const reportedByOpts = useMemo(
    () => terminology.find((term) => term.setName === REQUESTED_BY_CODES)?.value || [],
    [terminology]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const handleChange = useCallback(
    (key: string, value: TAllergyTemplateValue) => {
      onChange?.(key, value)
    },
    [onChange]
  )

  const handleSearchAllergenOpts = useCallback(
    async (text?: string) => {
      if (text && text.length >= 3) {
        setLoadingTypeAhead(true)
        try {
          const resp = await CodeSystemControllerService.searchCodeSystem(
            ALLERGEN_CODE_SYSTEM_OID,
            text
          )
          setAllergenOpts(resp.concepts ? resp.concepts : [])
          const newOpts: CodedConceptDto[] = []
          resp.concepts?.forEach((each) => {
            if (allergenOpts.find((opt) => opt.code === each.code) === undefined) newOpts.push(each)
          })
          if (newOpts.length > 0) setAllergenOpts([...new Set([...allergenOpts, ...newOpts])])
          setLoadingTypeAhead(false)
        } catch (error) {
          setAllergenOpts([])
          setLoadingTypeAhead(false)
        }
      } else setAllergenOpts([])
    },
    [allergenOpts]
  )

  const handleAllergenInputChange = useCallback(
    (value: string) => {
      setSearchStr(value)
      handleSearchAllergenOpts(value)
    },
    [handleSearchAllergenOpts]
  )

  useEffect(() => {
    if (!isTemplateEditor) {
      if (createdBy === undefined && loggedInProvider && loggedInProvider.id)
        handleChange('createdBy', loggedInProvider.id)
      if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
      if (lastUpdated === undefined) handleChange('lastUpdated', dateNowIsoString())
    }
    setInitialized(true)
  }, [
    createdBy,
    createdOn,
    handleChange,
    lastUpdated,
    loggedInProvider,
    isTemplateEditor,
    providers,
    userId,
  ])

  if (!initialized) return null

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Typography component="h1">{t('charting.templates.allergy.label')}</Typography>
      </Grid>
      <Grid item md={6} sm={12}>
        <MISAutocomplete
          clearOnBlur={false}
          disabled={!!allergenOther}
          error={validationActive && !allergenOther && !allergen}
          helperText={
            validationActive && !allergenOther && !allergen
              ? t('charting.templates.allergy.fields.allergen-required')
              : undefined
          }
          inputValue={allergen?.name || searchStr}
          label={t('charting.templates.allergy.fields.allergen')}
          loading={loadingTypeAhead}
          onChange={(value) =>
            onChange?.(
              'allergen',
              allergenOpts.find((each) => each.name === value)
            )
          }
          onFocus={() => {
            if (searchStr === '') handleSearchAllergenOpts()
          }}
          onInputChange={handleAllergenInputChange}
          options={allergenOpts.map((each) => each.name as string)}
          required
          value={allergen?.name || searchStr}
        />
      </Grid>
      <Grid item lg={1} md={2} sm={12}>
        <FormControlLabel
          control={
            <MISCheckbox
              checked={!!allergenOther}
              onChange={(_, checked) => handleChange('allergenOther', checked)}
            />
          }
          label={t('charting.templates.allergy.fields.allergen-other')}
        />
      </Grid>
      <Grid item lg={5} md={4} sm={12} sx={{ display: allergenOther ? 'block' : 'none' }}>
        <MISTextField
          error={validationActive && allergenOther && !allergenSpecified}
          helperText={
            validationActive && allergenOther && !allergenSpecified
              ? t('charting.templates.allergy.fields.allergen-required')
              : undefined
          }
          label={t('charting.templates.allergy.fields.allergen-specified')}
          onChange={(e) => handleChange('allergenSpecified', e.target.value)}
          value={allergenSpecified || ''}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          error={validationActive && !type}
          helperText={
            validationActive && !type
              ? t('charting.templates.allergy.fields.type-required')
              : undefined
          }
          label={t('charting.templates.allergy.fields.type')}
          onChange={(e) => handleChange('type', e.target.value)}
          options={allergyType.map((each) => ({ label: each.name as string, value: each }))}
          required
          value={type}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.allergy.fields.severity')}
          onChange={(e) => handleChange('severity', e.target.value)}
          options={allergySeverity.map((each) => ({ label: each.name as string, value: each }))}
          value={severity}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.allergy.fields.life-stage')}
          onChange={(e) => handleChange('lifeStage', e.target.value)}
          options={lifeStageOpts.map((each) => ({ label: each.name as string, value: each }))}
          value={lifeStage}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISTextField
          label={t('charting.templates.allergy.fields.reaction')}
          onChange={(e) => handleChange('reaction', e.target.value)}
          value={reaction || ''}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.allergy.fields.reported-by')}
          onChange={(e) => handleChange('reportedBy', e.target.value)}
          options={reportedByOpts.map((each) => ({ label: each.name as string, value: each }))}
          value={reportedBy}
        />
      </Grid>
      <Grid item md={12} sm={12}>
        <MISTextField
          label={t('charting.templates.allergy.fields.note')}
          multiline
          onChange={(e) => handleChange('note', e.target.value)}
          value={note || ''}
        />
      </Grid>
      {lastUpdated && (
        <Grid item sm={12}>
          {lastUpdated && (
            <Typography>{`${t(
              'charting.templates.allergy.fields.last-updated'
            )}${isoDateToDisplayFormat(lastUpdated)}`}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default AllergyTemplateComponent
