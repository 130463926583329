import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { utils } from 'xlsx'
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISMultiValueAutocomplete from 'common/components/form/MISMultiValueAutocomplete'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { dateNowIsoString } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getClientFullName } from 'modules/shared/clientUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { ChartingEntryControllerService, FormSchemaDTO } from 'services/openapi'
import { selectChartingMyTemplates } from 'store/selectors/charting'
import { selectClientDetails } from 'store/selectors/client'
import { selectUserId } from 'store/selectors/user'
import HistoricalItemFilter from './HistoricalItemFilter'
import {
  getMetadataForTemplate,
  getTemplateDataByMetadataType,
  getTemplateDisplayName,
  getTemplateVersion,
} from './utils'
import { TTemplateData } from '../blots/TemplateBlot'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'

const EXPORT_WORKSHEET_DATA = 'Data'
const EXPORT_WORKSHEET_METADATA = 'Metadata'

type StringArrayMap = {
  [key: string]: string[]
}

type HistoricalItemProps = {
  onSelectTemplate: (value: FormSchemaDTO | null) => void
  preTemplateId?: string
}

export const HistoricalItem = ({ onSelectTemplate, preTemplateId }: HistoricalItemProps) => {
  const { handleApiError } = useErrorHandler()
  const providers = useProviders()
  const clientDetails = useSelector(selectClientDetails)
  const myTemplates = useSelector(selectChartingMyTemplates)
  const userId = useSelector(selectUserId)
  const { t } = useTranslation('common')

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [tableValues, setTableValues] = useState<StringArrayMap | null | undefined>(undefined)
  const [tableColumns, setTableColumns] = useState<string[]>([])

  const tbl = useRef<HTMLTableElement>(null)

  const fetchHistoricalData = useCallback(
    async (templateId: string, filters?: string[]) => {
      if (clientDetails?.id && templateId) {
        let templateData: TTemplateData[] | undefined = undefined
        try {
          const historicalData = await ChartingEntryControllerService.getFilteredChartingEntries(
            clientDetails.id,
            templateId,
            filters ? filters : undefined,
            Boolean(!myTemplates?.find((template) => template.id === templateId))
          )
          if (historicalData && historicalData.length > 0)
            templateData = historicalData
              .flatMap((each) => each.chartingTemplateBlot)
              .map((blot) => blot.templateData)
        } catch (error) {
          handleApiError(error)
        }
        if (templateData) {
          if (!templateData[0].components) {
            const resultMap: StringArrayMap = {}
            const metadata = getMetadataForTemplate(templateId)
            if (!metadata) return // TODO define type for metadata to always have value
            Object.keys(metadata).forEach((key) => (resultMap[metadata[key].label] = []))
            templateData.forEach((item) => {
              Object.keys(resultMap).forEach((label) => {
                const keyForLabel = Object.keys(metadata).find(
                  (key) => metadata[key].label === label
                )
                if (keyForLabel && (item[keyForLabel] || item[keyForLabel] === false)) {
                  const value = getTemplateDataByMetadataType(
                    keyForLabel,
                    item,
                    metadata,
                    providers
                  )
                  resultMap[label].push(value)
                } else resultMap[label].push('')
              })
            })
            setTableValues(resultMap)
            setTableColumns(Object.keys(resultMap))
          } else {
            // TODO set table data based on metadata automatically generated for custom template
            const components = templateData
              .filter((data) => data)
              .flatMap((data) => data.components)

            const resultMap: StringArrayMap = {}

            components.forEach((item) => {
              const { label, value } = item.data
              if (!resultMap[label as string] && label !== 'name') {
                resultMap[label as string] = []
              }
              resultMap[label as string].push(value as string)
            })
            setTableValues(resultMap)
            setTableColumns(Object.keys(resultMap))
          }
        } else {
          setTableValues(null)
          setTableColumns([])
        }
      }
    },
    [clientDetails?.id, handleApiError, myTemplates, providers]
  )

  const getFormattedTemplateOptions = useCallback(() => {
    const formatOption = (option) => ({
      label: getTemplateDisplayName(option?.name) || option?.name,
      value: option,
    })

    return Object.values(TEMPLATES)
      .filter((each) => each.historical === 'table')
      .map(formatOption)
  }, [])

  const handleSelectTemplate = useCallback(
    (value) => {
      fetchHistoricalData(value?.id)
      setSelectedTemplateId(value?.id)
      if (onSelectTemplate) onSelectTemplate(value?.name)
    },
    [fetchHistoricalData, onSelectTemplate]
  )

  const xport = useCallback(
    (fileName: string, password: string) => {
      /* the .current field will be a TABLE element */
      const table_elt = tbl.current
      /* generate SheetJS workbook */
      const wb = utils.table_to_book(table_elt, { sheet: EXPORT_WORKSHEET_DATA })
      const metadata = [
        [t('charting.historical.item.template-name'), getTemplateDisplayName(selectedTemplateId)],
        [t('charting.historical.item.template-version'), getTemplateVersion(selectedTemplateId)],
        [t('charting.historical.item.export-date-time'), dateNowIsoString()],
      ]
      const provider = providers?.find((provider) => provider.userId === userId)
      if (provider)
        metadata.push([
          t('charting.historical.item.exported-by'),
          evaluateLabelUtil(provider?.names),
        ])
      if (clientDetails) {
        metadata.push([])
        metadata.push([t('charting.historical.item.client-name'), getClientFullName(clientDetails)])
        metadata.push([
          t('charting.historical.item.client-file-number'),
          (clientDetails.fileNumber as number).toString(),
        ])
      }
      metadata.push([])
      metadata.push([t('charting.historical.item.confidential')])
      const ws = utils.aoa_to_sheet(metadata)
      utils.book_append_sheet(wb, ws, EXPORT_WORKSHEET_METADATA)
      /* export to XLSX */
      XlsxPopulate.fromBlankAsync().then((workbook) => {
        // Modify the workbook.
        Object.keys(wb.Sheets).forEach((sheetName) => {
          const sheet = wb.Sheets[sheetName]
          workbook.addSheet(sheetName)
          Object.keys(sheet).forEach((cell) => {
            const value = wb.Sheets[sheetName][cell]?.v
            if (value) workbook.sheet(sheetName).cell(cell).value(value)
          })
        })
        workbook.deleteSheet('Sheet1')

        // Write to file.
        return workbook.outputAsync({ password }).then(function (blob) {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        })
      })
    },
    [clientDetails, providers, selectedTemplateId, t, userId]
  )

  const handleFiltering = useCallback(
    (filters) => {
      if (selectedTemplateId) {
        fetchHistoricalData(selectedTemplateId, filters)
      }
    },
    [fetchHistoricalData, selectedTemplateId]
  )

  useEffect(() => {
    if (preTemplateId) {
      const template = Object.values(TEMPLATES).find((template) => template.id === preTemplateId)
      if (template) {
        handleSelectTemplate(template)
      }
    }
  }, [handleSelectTemplate, preTemplateId])

  return (
    <Box
      component={Paper}
      sx={{
        borderRadius: GLOBAL.BASE_UNIT,
        margin: GLOBAL.MARGIN_MD,
        marginLeft: GLOBAL.MARGIN_XXL,
      }}
    >
      <Box sx={{ padding: GLOBAL.PADDING_MD }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISAutocomplete
              label={t('charting.historical.item.template')}
              onChange={(value) => handleSelectTemplate(value?.value || null)}
              options={getFormattedTemplateOptions()}
            />
          </Grid>
          <Grid item xs={4}>
            {selectedTemplateId && (
              <HistoricalItemFilter
                components={getMetadataForTemplate(selectedTemplateId)}
                handleFiltering={handleFiltering}
              />
            )}
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <MISMultiValueAutocomplete
              allowFreeText={false}
              label={t('charting.historical.item.columns')}
              onChange={(_event: SyntheticEvent, newValue: string[]) => setTableColumns(newValue)}
              options={Object.keys(tableValues ?? [])}
              value={tableColumns || []}
            />
          </Grid>
        </Grid>
        {tableValues != null && (
          <Box sx={{ pt: 3 }}>
            {Object.keys(tableValues).length > 0 && (
              <MISButton
                label={t('charting.historical.item.export')}
                onClick={() => setExportDialogOpen(true)}
                startIcon={<FileDownloadIcon />}
              />
            )}
            <Dialog
              PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault()
                  const formData = new FormData(event.currentTarget)
                  const formJson = Object.fromEntries(formData.entries())
                  const { name, password } = formJson
                  if (name && password) {
                    setExportDialogOpen(false)
                    xport(name as string, password as string)
                  }
                },
              }}
              onClose={() => setExportDialogOpen(false)}
              open={exportDialogOpen}
            >
              <DialogTitle>{t('charting.historical.item.export')}</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                  {t('charting.historical.item.export-text')}
                </DialogContentText>
                <MISTextField
                  fullWidth
                  id="name"
                  label={t('charting.historical.item.file-name')}
                  name="name"
                  required
                  sx={{ mb: 2 }}
                />
                <MISTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          onMouseUp={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  id="password"
                  label={t('charting.historical.item.file-password')}
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                />
              </DialogContent>
              <DialogActions>
                <MISButton color="secondary" onClick={() => setExportDialogOpen(false)}>
                  {t('common.button.cancel')}
                </MISButton>
                <MISButton color="primary" type="submit">
                  {t('common.button.confirm')}
                </MISButton>
              </DialogActions>
            </Dialog>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table ref={tbl} size="small" sx={{ minWidth: 550 }}>
                <TableHead>
                  <TableRow>
                    {clientDetails?.fileNumber && (
                      <TableCell sx={{ display: 'none' }}>
                        {t('charting.historical.item.client-file-number')}
                      </TableCell>
                    )}
                    {Object.keys(tableValues)
                      .filter((key) => tableColumns.includes(key))
                      .map((key) => (
                        <TableCell key={key} sx={{ fontWeight: 600 }}>
                          {key}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({
                    length: Math.max(
                      ...Object.entries(tableValues)
                        .filter(([key, _]) => tableColumns.includes(key))
                        .map(([_, arr]) => arr.length)
                    ),
                  }).map((_, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {clientDetails?.fileNumber && (
                        <TableCell sx={{ display: 'none' }}>{clientDetails?.fileNumber}</TableCell>
                      )}
                      {Object.keys(tableValues)
                        .filter((key) => tableColumns.includes(key))
                        .map((key) => (
                          <TableCell key={`${key}-${rowIndex}`}>
                            {tableValues[key][rowIndex] ?? ''}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {tableValues === null && (
          <Box sx={{ marginTop: 3 }}>{t('charting.historical.item.no-data-found')}</Box>
        )}
      </Box>
    </Box>
  )
}
