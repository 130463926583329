/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PurposeGroupAssociation } from '../models/PurposeGroupAssociation'
import type { PurposeGroupAssociationTerse } from '../models/PurposeGroupAssociationTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PurposeGroupAssociationControllerService {
  /**
   * @param id
   * @param requestBody
   * @returns PurposeGroupAssociationTerse OK
   * @throws ApiError
   */
  public static updatePurposeGroupAssociation(
    id: string,
    requestBody: PurposeGroupAssociation
  ): CancelablePromise<PurposeGroupAssociationTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/purpose-group-associations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
