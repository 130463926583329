/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEmergencyContact } from '../models/ClientEmergencyContact'
import type { PageClientEmergencyContact } from '../models/PageClientEmergencyContact'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientEmergencyContactControllerService {
  /**
   * @param clientId
   * @returns PageClientEmergencyContact OK
   * @throws ApiError
   */
  public static getClientEmergencyContacts(
    clientId: string
  ): CancelablePromise<PageClientEmergencyContact> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/emergency-contacts',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientEmergencyContact OK
   * @throws ApiError
   */
  public static putClientEmergencyContacts(
    clientId: string,
    requestBody: Array<ClientEmergencyContact>
  ): CancelablePromise<Array<ClientEmergencyContact>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/emergency-contacts',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param contactId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEmergencyContact(
    clientId: string,
    contactId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/emergency-contacts/{contactId}',
      path: {
        clientId: clientId,
        contactId: contactId,
      },
    })
  }

  /**
   * @param clientId
   * @param contactId
   * @param methodId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEmergencyContactMethod(
    clientId: string,
    contactId: string,
    methodId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/emergency-contacts/{contactId}/contact-method/{methodId}',
      path: {
        clientId: clientId,
        contactId: contactId,
        methodId: methodId,
      },
    })
  }
}
