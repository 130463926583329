/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contact } from '../models/Contact'
import type { PageContact } from '../models/PageContact'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ContactsControllerService {
  /**
   * Returns a list of contact information for specified Agency
   * List is returned as Paged collection
   * @param agencyId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageContact OK
   * @throws ApiError
   */
  public static listContacts(
    agencyId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageContact> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/contact-information',
      path: {
        agencyId: agencyId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Insert or Update the contact information for a specified Agency
   * If id present then information will be updated, otherwise will be created
   * @param agencyId
   * @param requestBody
   * @returns Contact OK
   * @throws ApiError
   */
  public static upsertContacts(
    agencyId: string,
    requestBody: Array<Contact>
  ): CancelablePromise<Array<Contact>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/contact-information',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete contact information record for a governance agency
   * Agency and contact information ids are specified in the URL.
   * @param agencyId
   * @param contactId
   * @returns void
   * @throws ApiError
   */
  public static deleteContact(agencyId: string, contactId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/contact-information/{contactId}',
      path: {
        agencyId: agencyId,
        contactId: contactId,
      },
      errors: {
        400: `Failed to delete the specified domain agency association`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }
}
