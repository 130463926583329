/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageUser } from '../models/PageUser'
import type { User } from '../models/User'
import type { UserDTO } from '../models/UserDTO'
import type { UserProfile } from '../models/UserProfile'
import type { UserProvisionDTO } from '../models/UserProvisionDTO'
import type { UserProvisionLightDTO } from '../models/UserProvisionLightDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class UserControllerService {
  /**
   * Get a User by ID
   * @param userId
   * @returns User OK
   * @throws ApiError
   */
  public static getById(userId: string): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * @param userId
   * @param requestBody
   * @returns User OK
   * @throws ApiError
   */
  public static updateUsers(userId: string, requestBody: User): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param userId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static updateUserPassword(userId: string, requestBody: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}/reset-password',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Update roles and business lines for provisioned user
   * @param userId
   * @param requestBody
   * @returns UserProvisionLightDTO OK
   * @throws ApiError
   */
  public static updateProvisionUser(
    userId: string,
    requestBody: UserProvisionLightDTO
  ): CancelablePromise<UserProvisionLightDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}/provision',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get a List of Users by the search params
   * @param searchText
   * @param firstName
   * @param lastName
   * @param userIds
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageUser OK
   * @throws ApiError
   */
  public static getPagedList(
    searchText?: string,
    firstName?: string,
    lastName?: string,
    userIds?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users',
      query: {
        searchText: searchText,
        firstName: firstName,
        lastName: lastName,
        userIds: userIds,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create a user by adding a record to the database.
   * @param requestBody
   * @returns User OK
   * @throws ApiError
   */
  public static createUser(requestBody: User): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Provision a user locally and in IAMDefault is provision=ALL and the user will be fully provisioned.
   * @param requestBody
   * @param provision
   * @returns UserProfile OK
   * @throws ApiError
   */
  public static provisionUser(
    requestBody: UserProvisionDTO,
    provision?: Array<'ALL' | 'IAM' | 'IDHE' | 'PREFERENCES' | 'ROLES' | 'NONE'>
  ): CancelablePromise<UserProfile> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/provision',
      query: {
        provision: provision,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get a UserProfile by userID.  This is a convenience endpoint, so far not part of official API.
   * @param userId
   * @returns UserProfile OK
   * @throws ApiError
   */
  public static getUserProfile(userId: string): CancelablePromise<UserProfile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/profile',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * Get a User by IAM UserID, aka oauthSubjectId in DB, which is usually a UUID but persisted as a string
   * @param iamUserId
   * @param username
   * @param _with
   * @returns UserDTO OK
   * @throws ApiError
   */
  public static findUserBy(
    iamUserId?: string,
    username?: string,
    _with?: Array<string>
  ): CancelablePromise<UserDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/find-by',
      query: {
        'iam-user-id': iamUserId,
        username: username,
        with: _with,
      },
    })
  }

  /**
   * Get a tenandId by username
   * @param username
   * @returns string OK
   * @throws ApiError
   */
  public static findTenantByUsername(username: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/find-by-username/{username}',
      path: {
        username: username,
      },
    })
  }
}
