import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Stack } from '@mui/material'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import { ChartingEntryControllerService } from 'services/openapi'
import ToDoEntry from './ToDoEntry'
import ToDoFilters from './ToDoFilters'
import { isDateBeforeOrEqualDate } from '../../../common/utils/DateUtils'
import ClientRecordHeader from '../ClientDetails/ClientRecordHeader'

const ToDo = () => {
  const { clientId } = useParams()

  const [followUps, setFollowUps] = useState<TTemplateBlot[]>([])
  const [followUp, setFollowUp] = useState<TTemplateBlot>()

  const handleSelectEntry = useCallback(
    (id: string) => {
      setFollowUp(followUps?.find((each) => each.templateRecordId === id))
    },
    [followUps]
  )

  useEffect(() => {
    const getFollowUps = async (clientId: string) => {
      const followUpsResp = await ChartingEntryControllerService.getFilteredChartingEntries(
        clientId,
        'FollowUpTemplate',
        undefined,
        true
      )
      if (followUpsResp?.length) {
        const followUps: TTemplateBlot[] = followUpsResp.flatMap(
          (each) => each.chartingTemplateBlot
        )
        const followUpsWithDueDate = followUps.filter((each) => each.templateData.dueDate)
        followUpsWithDueDate.sort((a, b) =>
          isDateBeforeOrEqualDate(
            a.templateData.dueDate as string,
            b.templateData.dueDate as string
          )
            ? -1
            : 1
        )
        const followUpsWithoutDueDate = followUps.filter((each) => !each.templateData.dueDate)
        const sortedFollowUps = [...followUpsWithDueDate, ...followUpsWithoutDueDate]
        setFollowUp(sortedFollowUps[0])
        setFollowUps(sortedFollowUps)
      }
    }

    if (clientId) getFollowUps(clientId)
  }, [clientId])

  return (
    <MISBaseContainer>
      <ClientRecordHeader />
      <Stack direction="row" spacing={3}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ToDoFilters
            entries={followUps}
            entryId={followUp?.templateRecordId || ''}
            onSelect={handleSelectEntry}
          />
        </LocalizationProvider>
        {followUp && <ToDoEntry entry={followUp} />}
      </Stack>
    </MISBaseContainer>
  )
}

export default ToDo
