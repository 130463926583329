import { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import MISButton from 'common/components/MISButton'

type MISConfirmationDialogProps = {
  open: boolean
  headerText?: string
  bodyText?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  onClose?: () => void
  onCancel?: () => void
  onSave?: () => void
}

const MISConfirmationDialog = (props: MISConfirmationDialogProps) => {
  const {
    bodyText = 'Dialog body',
    headerText = 'Dialog header',
    onCancel,
    onSave,
    open = false,
    primaryButtonText = 'Ok',
    secondaryButtonText = 'Cancel',
  } = props

  const [isOpen, setIsOpen] = useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleCancel = () => {
    setIsOpen(false)
    onCancel && onCancel()
  }

  const handleSave = () => {
    setIsOpen(false)
    onSave && onSave()
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-line' }} variant="body2">
          {bodyText}
        </Typography>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <MISButton color="secondary" onClick={handleCancel}>
            {secondaryButtonText}
          </MISButton>
        )}
        {onSave && (
          <MISButton color="primary" onClick={handleSave}>
            {primaryButtonText}
          </MISButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MISConfirmationDialog
