/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityAssociationCreationDTO } from '../models/EntityAssociationCreationDTO'
import type { PageTypeTerse } from '../models/PageTypeTerse'
import type { Type } from '../models/Type'
import type { TypeGroupAssociation } from '../models/TypeGroupAssociation'
import type { TypeGroupAssociationTerse } from '../models/TypeGroupAssociationTerse'
import type { TypeTerse } from '../models/TypeTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class TypeControllerService {
  /**
   * @param id
   * @returns TypeTerse OK
   * @throws ApiError
   */
  public static getTypeById(id: string): CancelablePromise<TypeTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/types/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns TypeTerse OK
   * @throws ApiError
   */
  public static updateType(id: string, requestBody: Type): CancelablePromise<TypeTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/types/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageTypeTerse OK
   * @throws ApiError
   */
  public static listTypes(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageTypeTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/types',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns TypeTerse OK
   * @throws ApiError
   */
  public static createType(requestBody: Type): CancelablePromise<TypeTerse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/types',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns TypeGroupAssociationTerse OK
   * @throws ApiError
   */
  public static getTypeGroupAssociationsForType(
    id: string
  ): CancelablePromise<Array<TypeGroupAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/types/{id}/groups',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns TypeGroupAssociation OK
   * @throws ApiError
   */
  public static addTypeGroupToType(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<TypeGroupAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/types/{id}/groups',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param activeOnly
   * @param name
   * @param programId
   * @param purposeId
   * @returns TypeTerse OK
   * @throws ApiError
   */
  public static searchTypes(
    activeOnly: boolean = false,
    name?: string,
    programId?: string,
    purposeId?: string
  ): CancelablePromise<Array<TypeTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/types/search',
      query: {
        activeOnly: activeOnly,
        name: name,
        programId: programId,
        purposeId: purposeId,
      },
    })
  }
}
