import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '@mui/icons-material/History'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'
import MISHideIcon from 'common/components/icons/MISHideIcon'
import MISShowIcon from 'common/components/icons/MISShowIcon'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { StateChip } from 'modules/shared/State'
import { programsAtom } from 'recoil/atoms'
import { encounterServicesNotesHistoryState, encounterServicesNotesState } from 'recoil/encounters'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef, EncounterDTO, EncounterNoteDTO } from 'services/openapi'
import { MIS_ENCOUNTER_NOTE_STATE } from 'services/terminologyConstants'
import EncounterNoteHistoryDialog from './EncounterNoteHistoryDialog'

export interface NoteHistoryToShow {
  serviceId: string
  note: EncounterNoteDTO
}

type EncounterNotesSectionProps = {
  encounter: EncounterDTO
}

const EncounterNotesSection = ({ encounter }: EncounterNotesSectionProps) => {
  const { t } = useTranslation('common')
  const notes = useRecoilValue(encounterServicesNotesState)
  const notesHistory = useRecoilValue(encounterServicesNotesHistoryState)
  const programOptions = useRecoilValue(programsAtom)
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_NOTE_STATE))

  const [isRevisionHidden, setIsRevisionHidden] = useState(false)
  const [noteHistoryToShow, setNoteHistoryToShow] = useState<NoteHistoryToShow | null>(null)

  const serviceHeader = useCallback(
    (synopsis: string, serviceProgramId: string, isPrimary: boolean) => (
      <Box sx={{ lineHeight: 5 }}>
        <span>
          <b>{synopsis}</b>
        </span>
        {programOptions.find((opt) => opt.id === serviceProgramId) && (
          <span>{` | ${programOptions.find((opt) => opt.id === serviceProgramId)?.name}`}</span>
        )}
        {isPrimary && (
          <span>
            {' | '}
            <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{t('common.primary')}</span>
          </span>
        )}
      </Box>
    ),
    [programOptions, t]
  )

  const serviceNote = useCallback(
    (serviceId: string, note: EncounterNoteDTO) => (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', lineHeight: 3 }}>
          <Box>
            <span>{`${t('encounter-service-notes.labels.note-created-by')}: ${
              note.auditInfo?.lastUpdated?.user?.firstName
            } ${note.auditInfo?.lastUpdated?.user?.lastName}`}</span>
            <span>{` | ${isoDateToDisplayFormatWithTime(note.auditInfo?.lastUpdated?.date)}`}</span>
            {note.state && (
              <span>
                {' | '}
                <StateChip
                  comment={note.stateComment}
                  defaultCursor
                  label={
                    stateOptions?.find((o: CodedRef) => o.code === (note.state as CodedRef)?.code)
                      ?.name as string
                  }
                />
              </span>
            )}
          </Box>
          <MISButton
            color="primary"
            onClick={() => setNoteHistoryToShow({ note, serviceId })}
            size="large"
            startIcon={<HistoryIcon />}
            variant="text"
          >
            {t('encounter-service-notes.labels.view-history')}
          </MISButton>
        </Box>
        <Box sx={{ lineHeight: 2, mt: -1 }}>{note.content && parse(note.content)}</Box>
        {!isRevisionHidden &&
          notesHistory[serviceId] &&
          notesHistory[serviceId]
            .filter((noteHistory) => noteHistory.id === note.id && noteHistory.isLatest === false)
            .map((noteHistory, index) => (
              <Box key={index} sx={{ lineHeight: 3 }}>
                <Divider sx={{ m: 0 }} />
                <span>{`${t('encounter-service-notes.labels.note-created-by')}: ${
                  note.auditInfo?.lastUpdated?.user?.firstName
                } ${note.auditInfo?.lastUpdated?.user?.lastName}`}</span>
                <span>{` | ${isoDateToDisplayFormatWithTime(noteHistory.revisedAt)}`}</span>
                {noteHistory.state && (
                  <span>
                    {' | '}
                    <StateChip
                      comment={noteHistory.stateComment}
                      defaultCursor
                      label={
                        stateOptions?.find(
                          (o: CodedRef) => o.code === (noteHistory.state as CodedRef)?.code
                        )?.name as string
                      }
                    />
                  </span>
                )}
                <Box sx={{ lineHeight: 2, mt: -1, textDecoration: 'line-through' }}>
                  {noteHistory.content && parse(noteHistory.content)}
                </Box>
              </Box>
            ))}
      </>
    ),
    [isRevisionHidden, notesHistory, stateOptions, t]
  )

  return (
    <>
      <Box sx={{ pb: 2, pt: 4 }}>
        <IconButton
          disableRipple
          onClick={() => setIsRevisionHidden(!isRevisionHidden)}
          sx={{ p: 0 }}
        >
          {isRevisionHidden ? <MISShowIcon /> : <MISHideIcon />}
          <Typography
            component="span"
            sx={{ color: '#cc5500', fontSize: '14px', lineHeight: 2, pl: 1 }}
          >
            {isRevisionHidden
              ? t('encounter-service-notes.labels.show-revisions')
              : t('encounter-service-notes.labels.hide-revisions')}
          </Typography>
        </IconButton>
      </Box>
      {encounter?.id &&
        encounter?.encounterServices &&
        [...encounter.encounterServices]
          .sort((a, b) => (a?.isPrimaryService ? -1 : b?.isPrimaryService ? 1 : 0))
          .map((service) => (
            <Box key={service.id} sx={{ pb: 3 }}>
              <Divider sx={{ m: 0 }} />
              {serviceHeader(
                service.synopsis as string,
                service.programId as string,
                service.isPrimaryService as boolean
              )}
              {service.id &&
                notes[service.id] &&
                [...notes[service.id]]
                  .filter(
                    (note) =>
                      (stateOptions?.find(
                        (o: CodedRef) => o.code === (note.state as CodedRef)?.code
                      )?.name as string) !== 'Voided'
                  )
                  .sort(
                    (a, b) =>
                      new Date(b?.auditInfo?.lastUpdated?.date as string).getTime() -
                      new Date(a?.auditInfo?.lastUpdated?.date as string).getTime()
                  )
                  .map((note) => (
                    <div key={note.id}>
                      <Divider sx={{ m: 0 }} />
                      {serviceNote(service.id as string, note)}
                    </div>
                  ))}
            </Box>
          ))}
      {noteHistoryToShow && (
        <EncounterNoteHistoryDialog
          encounterServiceId={noteHistoryToShow.serviceId}
          note={noteHistoryToShow.note}
          onClose={() => setNoteHistoryToShow(null)}
          openDialog={!!noteHistoryToShow}
        />
      )}
    </>
  )
}

export default EncounterNotesSection
