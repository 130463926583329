export const getCodedConceptId = (valueset, codedConcept) => {
  if (codedConcept && codedConcept.code && codedConcept.codeSystemOid) {
    let selectedConcept = valueset.find(
      (x) => x.codeSystemOid === codedConcept.codeSystemOid && x.code === codedConcept.code
    )
    if (selectedConcept) {
      return selectedConcept.code
    }
  }
  return ''
}

//This works for Programs, Purposes and Types only.
export const getNameFromId = (valueset, id) => {
  if (id && valueset) {
    let selectedConcept = valueset.find((x) => x.id === id)
    if (selectedConcept) {
      return selectedConcept.name
    }
  }
  return ''
}

export const getNameFromCodedConcept = (valueset, codedConcept) => {
  if (!valueset || !codedConcept) return ''
  return valueset.find(
    (x) => x.codeSystemOid === codedConcept.codeSystemOid && x.code === codedConcept.code
  )?.name
}
