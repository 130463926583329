import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AddCircleOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTabTable from 'common/components/associations/AssociationListTabTemplate'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import { Breadcrumb } from 'common/components/breadcrumb/Breadcrumb'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISDraggableList from 'common/components/form/MISDraggableList'
import MISDurationField, { getMinutes } from 'common/components/form/MISDurationField'
import FormViewer from 'common/components/form/MISFormViewer'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { BOOLEAN_CONTENT } from 'common/constants'
import { convertMinsToHourMins, nowToApi } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { DomainType } from 'core/Types'
import {
  domainsAtom,
  esdrtCategoriesAtom,
  functionalAreasAtom,
  programsAtom,
  purposesAtom,
  templateGroupAtom,
  typesAtom,
} from 'recoil/atoms'
import { isDirtyState } from 'recoil/isDirty'
import { serviceTemplateState } from 'recoil/lastupdated'
import { terminologyState } from 'recoil/terminology'
import {
  FormSchemaControllerService,
  FormSchemaDTO,
  ListEsdrtServicesDTO,
  ListFunctionalAreasDTO,
  TemplateFormAssociationEntity,
} from 'services/openapi'
import { EncounterServiceTemplateControllerService } from 'services/openapi/services/EncounterServiceTemplateControllerService'
import { EncounterServiceTemplateGroupControllerService } from 'services/openapi/services/EncounterServiceTemplateGroupControllerService'
import {
  MILEAGE_TYPES,
  PROV_SERV_TYPES,
  REFERRAL_TYPES,
  SIGN_REQ_TYPES,
} from 'services/terminologyConstants'
import AddFormToSTDialog from './AddFormToSTDialog'
import Roles from './Roles'

const associationTablesHeight = 500
const attributionRequiredOptions = BOOLEAN_CONTENT
const automatedOptions = BOOLEAN_CONTENT

function AddEditEncounterServiceTemplate({ breadcrumb }: any) {
  const { selectedTemplateId } = useParams()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { showSnackError, showSnackSuccess } = useSnack()
  const { buildError, handleApiError, setErrorList } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const valueSets = useRecoilValue(terminologyState)
  const purposeOptions = useRecoilValue(purposesAtom)
  const typeOptions = useRecoilValue(typesAtom)
  const programOptions = useRecoilValue(programsAtom)
  const domainsSet = useRecoilValue(domainsAtom)
  const functionalGroupsSet = useRecoilValue(functionalAreasAtom)
  const templateGroupSet = useRecoilValue(templateGroupAtom)
  const esdrtCategoryOptions = useRecoilValue(esdrtCategoriesAtom)
  const setLastUpdatedServiceTemplate = useSetRecoilState(serviceTemplateState)

  const [serviceTemplateFetched, setServiceTemplateFetched] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [synopsis, setSynopsis] = useState('')
  const [templateCode, setTemplateCode] = useState('')
  const [duration, setDuration] = useState('')
  const [updateDuration, setUpdateDuration] = useState(false)
  const [mileage, setMileage] = useState('')
  const [program, setProgram] = useState('')
  const [type, setType] = useState('')
  const [purpose, setPurpose] = useState('')
  const [funder, setFunder] = useState('')
  const [signatureRequirement, setSignatureRequirement] = useState('')
  const [attributionRequired, setAttributionRequired] = useState(false)
  const [automated, setAutomated] = useState(false)
  const [esdrtCategory, setEsdrtCategory] = useState('')
  const [esdrtService, setEsdrtService] = useState('')
  const [esdrtDuration, setEsdrtDuration] = useState('')
  const [provincialType, setProvincialType] = useState('')
  const [referralType, setReferralType] = useState('')
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [isTerminated, setIsTerminated] = useState(false)
  const [openDomainsLinkModal, setOpenDomainsLinkModal] = useState(false)
  const [openFunctionalGroupsLinkModal, setOpenFunctionalGroupsLinkModal] = useState(false)
  const [openTemplateGroupsLinkModal, setOpenTemplateGroupsLinkModal] = useState(false)
  const [domainAssociations, setDomainAssociations] = useState(new Map())
  const [domains, setDomains] = useState<DomainType[]>([])
  const [functionalGroupAssociations, setFunctionalGroupsAssociations] = useState(new Map())
  const [functionalGroups, setFunctionalGroups] = useState<ListFunctionalAreasDTO[]>([])
  const [templateGroups, setTemplateGroups] = useState([])
  const [templateGroupAssociations, setTemplateGroupAssociations] = useState(new Map())
  const [templateRoles, setTemplateRoles] = useState<string[]>([])

  const [esdrtServiceOptions, setEsdrtServiceOptions] = useState<
    ListEsdrtServicesDTO[] | undefined
  >([])
  const [funderOptions] = useState([])
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [esdrtEnabled, setEsdrtEnabled] = useState(true)

  const [selectedForm, setSelectedForm] = useState<FormSchemaDTO>()
  const [openFormModal, setOpenFormModal] = useState(false)
  const [formAssociations, setFormAssociations] = useState<TemplateFormAssociationEntity[]>([])
  const [formListUpdated, setFormListUpdated] = useState(false)

  //#endregion

  const signatureRequirementOptions = useMemo(() => {
    if (valueSets) return valueSets.find((x) => x.setName === SIGN_REQ_TYPES)?.value
    else return []
  }, [valueSets])

  const provincialTypeOptions = useMemo(() => {
    if (valueSets) return valueSets.find((x) => x.setName === PROV_SERV_TYPES)?.value
    else return []
  }, [valueSets])

  const referralTypeOptions = useMemo(() => {
    if (valueSets) return valueSets.find((x) => x.setName === REFERRAL_TYPES)?.value
    else return []
  }, [valueSets])

  const mileageOptions = useMemo(() => {
    if (valueSets) return valueSets.find((x) => x.setName === MILEAGE_TYPES)?.value
    else return []
  }, [valueSets])

  const initialDomainEntities = useMemo(() => {
    const otherEntities: { id: string; name: string }[] = []
    domainsSet.forEach((domain) => {
      const otherEntity = {
        id: domain.id,
        name: domain.domainName,
      }
      otherEntities.push(otherEntity)
    })
    return otherEntities
  }, [domainsSet])

  const initialFunctionalAreasEntities = useMemo(() => {
    const otherEntities: { id: string | undefined; name: string | undefined }[] = []
    functionalGroupsSet.forEach((fg) => {
      const otherEntity = {
        id: fg.id,
        name: fg.displayName,
      }
      otherEntities.push(otherEntity)
    })
    return otherEntities
  }, [functionalGroupsSet])

  const makeDomainAssociationObj = useMemo(() => {
    const associationList = [...domainAssociations.values()]
    const result: any = []
    associationList.forEach((element) => {
      result.push(element[0])
    })
    return result
  }, [domainAssociations])

  const makeFuncAssociationObj = useMemo(() => {
    const associationList = [...functionalGroupAssociations.values()]
    const result: any = []
    associationList.forEach((element) => {
      result.push(element[0])
    })
    return result
  }, [functionalGroupAssociations])

  const rejigResponse = (response: any) => {
    const rejiggedResponse: any = []
    response.forEach((element: any) => {
      const newElement = {
        ...element,
        endDate: element.effective.endDate ? element.effective.endDate : null,
        startDate: element.effective.startDate,
      }
      rejiggedResponse.push(newElement)
    })
    return rejiggedResponse
  }

  const buildDomainAssociationsMap = useCallback(
    (data) => {
      const currentAssociations = data
        ? rejigResponse(data)
        : selectedTemplateId
        ? domainAssociations
          ? makeDomainAssociationObj
          : []
        : []
      setDomains(domainsSet)

      buildAssociationMapUtil(
        currentAssociations,
        initialDomainEntities,
        'domainId',
        setDomainAssociations,
        setDomains
      )
    },
    [
      domainsSet,
      domainAssociations,
      initialDomainEntities,
      makeDomainAssociationObj,
      selectedTemplateId,
    ]
  )

  const buildFunctionalGroupAssociationsMap = useCallback(
    (data) => {
      const currentAssociations = data
        ? rejigResponse(data)
        : selectedTemplateId
        ? functionalGroupAssociations
          ? makeFuncAssociationObj
          : []
        : []
      setFunctionalGroups(functionalGroupsSet)

      buildAssociationMapUtil(
        currentAssociations,
        initialFunctionalAreasEntities,
        'functionalAreaId',
        setFunctionalGroupsAssociations,
        setFunctionalGroups
      )
    },
    [
      functionalGroupsSet,
      functionalGroupAssociations,
      initialFunctionalAreasEntities,
      makeFuncAssociationObj,
      selectedTemplateId,
    ]
  )

  const saveDomainAssociations = (associationsToSave: any) => {
    let toSave: any = []
    associationsToSave.forEach((association: any) => {
      const newAssociation = {
        domainId: association.otherEntityId,
        effective: {
          endDate: association.endDate ? association.endDate : null,
          startDate: association.startDate,
        },
      }
      toSave = [...toSave, newAssociation]
    })

    if (selectedTemplateId) {
      EncounterServiceTemplateControllerService.addDomainAssociations(
        selectedTemplateId,
        toSave
      ).then((response) => {
        showSnackSuccess(t('api.save-success'))
        buildDomainAssociationsMap(response)
      })
    } else {
      buildDomainAssociationsMap(toSave)
    }
    setOpenDomainsLinkModal(false)
  }

  const saveFunctionalGroupsAssociations = (associationsToSave: any) => {
    let toSave: any = []
    associationsToSave.forEach((association: any) => {
      const newAssociation = {
        effective: {
          endDate: association.endDate ? association.endDate : null,
          startDate: association.startDate,
        },
        functionalAreaId: association.otherEntityId,
      }
      toSave = [...toSave, newAssociation]
    })

    if (selectedTemplateId) {
      EncounterServiceTemplateControllerService.addFunctionalAreaAssociations(
        selectedTemplateId,
        toSave
      ).then((response) => {
        showSnackSuccess(t('api.save-success'))
        buildFunctionalGroupAssociationsMap(response)
      })
    } else {
      buildFunctionalGroupAssociationsMap(toSave)
    }
    setOpenFunctionalGroupsLinkModal(false)
  }

  const saveFormAssociations = (associationsToSave: any) => {
    let toSave: any[] = []
    associationsToSave.forEach((association: any, index: number) => {
      const newAssociation = {
        formName: association.formName,
        formOrder: index,
        formSemanticVersion: association.formSemanticVersion,
      }
      toSave = [...toSave, newAssociation]
    })

    toSave = [...formAssociations, ...toSave]

    toSave.forEach((entry, index) => (entry.formOrder = index))

    if (selectedTemplateId) {
      EncounterServiceTemplateControllerService.addFormAssociations(
        selectedTemplateId,
        toSave
      ).then((response) => {
        showSnackSuccess(t('api.save-success'))
        setFormAssociations(response)
      })
    } else {
      toSave = toSave.map((entry) => {
        return {
          ...entry,
          id: entry.formName + entry.formSemanticVersion,
        }
      })
      setFormAssociations(toSave)
    }
    setOpenFormModal(false)
  }

  //TODO: doesnt even use domain associations map?
  const saveTemplateGroupAssociations = (associationsToSave: any) => {
    saveAssociations(
      associationsToSave,
      EncounterServiceTemplateGroupControllerService.addEncounterServiceTemplateAssociation,
      EncounterServiceTemplateGroupControllerService.addEncounterServiceTemplateAssociation,
      setOpenTemplateGroupsLinkModal,
      buildDomainAssociationsMap,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      { id: selectedTemplateId }
    )
  }

  const setStatesForEsdrt = useCallback(
    (categoryId, serviceId) => {
      setEsdrtCategory(categoryId)
      const serviceOptions = esdrtCategoryOptions?.find((x) => x.id === categoryId)?.eSDRTServices
      setEsdrtServiceOptions(serviceOptions)
      setEsdrtService(serviceId)
    },
    [esdrtCategoryOptions]
  )

  const fetchFromOrgApi = useCallback(
    (templateId) => {
      return EncounterServiceTemplateControllerService.organizationgetById(templateId)
        .then((response) => {
          setServiceTemplateFetched(true)
          setTemplateName(response.name || '')
          setSynopsis(response.synopsis || '')
          setTemplateCode(response.code || '')
          setProgram(response.associations?.program?.programId || '')
          setType(response.associations?.type?.typeId || '')
          setPurpose(response.associations?.purpose?.purposeId || '')
          if (response.associations?.eSDRTCategory) {
            setStatesForEsdrt(
              response.associations.eSDRTCategory.eSDRTCategoryId,
              response.associations?.eSDRTService?.eSDRTServiceId
            )
            setEsdrtEnabled(false)
          } else {
            setEsdrtEnabled(true)
          }
          setMileage('123124')
          setStartDate(response.effective?.startDate || null)
          setEndDate(response.effective?.endDate || null)
          setIsTerminated(response.effective?.isTerminated || false)
          setAttributionRequired(response.attributionToIndividual || false)
          setAutomated(response.automatedEncounter || false)
          setDuration(response.duration?.toString() || '')
          setSignatureRequirement(
            signatureRequirementOptions?.find((x) => x.code === response.signatureRequirement?.code)
              ?.id || ''
          )
          setTemplateRoles(response.templateRoles || [])
          EncounterServiceTemplateControllerService.getDomainAssociationsForEncounterTemplate(
            templateId
          )
            .then((response) => {
              buildAssociationMapUtil(
                rejigResponse(response),
                initialDomainEntities,
                'domainId',
                setDomainAssociations,
                setDomains
              )
            })
            .catch((error) => {
              handleApiError(error)
            })
          EncounterServiceTemplateControllerService.getFunctionalAreaAssociationsForEncounterTemplate(
            templateId
          )
            .then((response) => {
              buildAssociationMapUtil(
                rejigResponse(response),
                initialFunctionalAreasEntities,
                'functionalAreaId',
                setFunctionalGroupsAssociations,
                setFunctionalGroups
              )
            })
            .catch((error) => {
              handleApiError(error)
            })
          EncounterServiceTemplateControllerService.getFormAssociationsForEncounterTemplate(
            templateId
          )
            .then((response) => {
              response.sort((a, b) => (a.formOrder && b.formOrder ? a.formOrder - b.formOrder : 0))
              setFormAssociations(response)
            })
            .catch((error) => {
              handleApiError(error)
            })
        })
        .catch((error) => {
          handleApiError(error)
        })
    },
    [
      handleApiError,
      initialDomainEntities,
      initialFunctionalAreasEntities,
      setStatesForEsdrt,
      signatureRequirementOptions,
    ]
  )

  const setupEmptyAssociations = useCallback(() => {
    buildAssociationMapUtil(
      [],
      initialDomainEntities,
      'domainId',
      setDomainAssociations,
      setDomains
    )
    buildAssociationMapUtil(
      [],
      initialFunctionalAreasEntities,
      'functionalAreaId',
      setFunctionalGroupsAssociations,
      setFunctionalGroups
    )
    buildAssociationMapUtil(
      [],
      templateGroupSet,
      'encounterServiceTemplateId',
      setTemplateGroups,
      setTemplateGroupAssociations
    )
  }, [initialDomainEntities, initialFunctionalAreasEntities, templateGroupSet])

  useEffect(() => {
    // If we are editing an existing template, go fetch the template. Otherwise,
    // Generate a new file number for the client.
    if (selectedTemplateId) {
      fetchFromOrgApi(selectedTemplateId)
    } else {
      setupEmptyAssociations()
    }
  }, [selectedTemplateId, fetchFromOrgApi, setupEmptyAssociations])

  //#region Handlers

  const handleChange = useCallback(
    (value, setValue) => {
      if (!isDirty) setIsDirty(true)
      setValue(value)
    },
    [isDirty, setIsDirty]
  )

  const handleEsdrtCategoryChange = useCallback(
    (event) => {
      if (!isDirty) setIsDirty(true)

      setEsdrtCategory(event.target.value)
      const serviceOptions = esdrtCategoryOptions?.find(
        (x) => x.id === event.target.value
      )?.eSDRTServices
      setEsdrtServiceOptions(serviceOptions)
      setEsdrtDuration('')
    },
    [esdrtCategoryOptions, isDirty, setIsDirty]
  )

  const handleEsdrtServiceChange = (event: any) => {
    if (!isDirty) setIsDirty(true)

    setEsdrtService(event.target.value)
    const mins = esdrtServiceOptions?.find((x) => x.id === event.target.value)?.durationMinutes
    setEsdrtDuration(convertMinsToHourMins(mins))
  }

  const handleMakeTemplateDuration = useCallback(() => {
    setUpdateDuration(true)
    setDuration(getMinutes(esdrtDuration).toString())
  }, [esdrtDuration])

  useEffect(() => {
    if (updateDuration) {
      setUpdateDuration(false)
      document.getElementById('mis-text-field-mis-duration-input-duration')?.focus()
      setTimeout(() => {
        document.getElementById('mis-text-field-mis-duration-input-duration')?.blur()
      }, 100)
    }
  }, [updateDuration])

  const handleDeleteForm = (itemId: string) => {
    const newAssociations = formAssociations.filter((form) => form.id !== itemId)

    if (selectedTemplateId) {
      EncounterServiceTemplateControllerService.deleteFormAssociationForEncounterTemplate(
        selectedTemplateId,
        itemId
      ).then(() => {
        showSnackSuccess(t('api.save-success'))
        setFormAssociations(newAssociations)
      })
    } else {
      setFormAssociations(newAssociations)
    }
    setSelectedForm({})
  }

  const handleSelectFormRow = (index: any) => {
    FormSchemaControllerService.searchFormSchema(
      formAssociations?.at(index)?.formName as string
    ).then((response) => {
      setSelectedForm(response?.content?.at(0))
    })
  }

  const handleAddForm = () => {
    setOpenFormModal(true)
  }

  const handleFormModalClose = () => {
    setOpenFormModal(false)
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ destination, source }: any) => {
    // dropped outside the list
    if (!destination) return
    const newItems = reorder(formAssociations, source.index, destination.index)

    newItems.forEach((entry, index) => (entry.formOrder = index))
    setFormAssociations(newItems)
    setFormListUpdated(true)
  }

  const handleSaveForm = () => {
    setIsDirty(false)

    EncounterServiceTemplateControllerService.addFormAssociations(
      selectedTemplateId as string,
      formAssociations
    )
    setFormListUpdated(false)
    showSnackSuccess('Form Order Saved')
  }

  //#endregion

  const handleTerminate = async () => {
    if (validate()) {
      let template = createTemplateForApi()

      const effective = {
        endDate: nowToApi(),
        isTerminated: true,
        startDate: startDate,
      }
      template = { ...template, effective: effective }

      // Call UpdateClient or CreateNewClient to update or create a new client.
      const saveOperation =
        EncounterServiceTemplateControllerService.updateEncounterServiceTemplate(
          selectedTemplateId as string,
          template
        )
      await saveOperation
        .then(() => {
          showSnackSuccess(t('encounter-template-page.snack-messages.terminate-success'))
          navigate('/encounter/service-templates')
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
  }

  const handleUnterminate = async () => {
    if (validate()) {
      let template = createTemplateForApi()

      const effective = {
        endDate: null,
        isTerminated: false,
        startDate: startDate,
      }

      template = { ...template, effective: effective }

      // Call UpdateClient or CreateNewClient to update or create a new client.
      const saveOperation =
        EncounterServiceTemplateControllerService.updateEncounterServiceTemplate(
          selectedTemplateId as string,
          template
        )

      await saveOperation
        .then(() => {
          showSnackSuccess(t('encounter-template-page.snack-messages.unterminate-success'))
          navigate('/encounter/service-templates')
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
  }

  const createTemplateForApi = useCallback(
    (roles?: string[]) => {
      let referralCodedConcept
      if (referralType) {
        const seelctedReferral = referralTypeOptions?.find((x) => x.id === referralType)
        referralCodedConcept = {
          code: seelctedReferral?.code,
          codeSystemOid: seelctedReferral?.codeSystemOid,
          name: seelctedReferral?.name,
        }
      }

      let signatureRequirementCodedConcept
      if (signatureRequirement) {
        const selectedSignatureRequirement = signatureRequirementOptions?.find(
          (x) => x.id === signatureRequirement
        )
        signatureRequirementCodedConcept = {
          code: selectedSignatureRequirement?.code,
          codeSystemOid: selectedSignatureRequirement?.codeSystemOid,
          name: selectedSignatureRequirement?.name,
        }
      }

      let provincialServiceConcept
      if (provincialType) {
        const selectedProvType = provincialTypeOptions?.find((x) => x.id === provincialType)
        provincialServiceConcept = {
          code: selectedProvType?.code,
          codeSystemOid: selectedProvType?.codeSystemOid,
          name: selectedProvType?.name,
        }
      }

      const selectedMileageType = mileageOptions?.find((x) => 'KMS' === x.code)
      const unitOfMeasureConcept = {
        code: selectedMileageType?.code,
        codeSystemOid: selectedMileageType?.codeSystemOid,
        name: selectedMileageType?.name,
      }

      const mileageDTO = {
        mileage: parseInt(mileage),
        unitOfMeasure: unitOfMeasureConcept,
      }

      const template: any = {
        associations: {
          eSDRTCategory: { eSDRTCategoryId: esdrtCategory },
          eSDRTService: { eSDRTServiceId: esdrtService },
          program: { programId: program },
          purpose: { purposeId: purpose },
          type: { typeId: type },
        },
        attributionToIndividual: attributionRequired,
        automatedEncounter: automated,
        code: templateCode,
        duration: parseInt(duration),
        id: selectedTemplateId,
        mileage: mileageDTO,
        name: templateName,
        provincialServiceType: provincialServiceConcept,
        referralType: referralCodedConcept,
        signatureRequirement: signatureRequirementCodedConcept,
        synopsis: synopsis,
        templateRoles: roles || templateRoles,
      }

      return template
    },
    [
      attributionRequired,
      automated,
      duration,
      esdrtCategory,
      esdrtService,
      mileage,
      mileageOptions,
      program,
      provincialType,
      provincialTypeOptions,
      purpose,
      referralType,
      referralTypeOptions,
      selectedTemplateId,
      signatureRequirement,
      signatureRequirementOptions,
      synopsis,
      templateCode,
      templateName,
      templateRoles,
      type,
    ]
  )

  const validate = useCallback(() => {
    const errors: any[] = []

    const validationList = [
      [templateName, 'templateName', 'api.error.required-field', { fieldName: 'Template Name' }],
      [synopsis, 'synopsis', 'api.error.required-field', { fieldName: 'Synopsis' }],
      [templateCode, 'templateCode', 'api.error.required-field', { fieldName: 'Template Code' }],
      [startDate, 'startDate', 'api.error.required-field', { fieldName: 'Start Date' }],
      [
        signatureRequirement,
        'signatureRequirement',
        'api.error.required-field',
        { fieldName: 'Signature Requirement' },
      ],
    ]

    if (esdrtCategory && esdrtService) {
      validationList.push([
        duration,
        'duration',
        'api.error.required-field',
        { fieldName: 'Duration' },
      ])
    }

    validationList.forEach((validation) => {
      if (!validation[0]) {
        errors.push(buildError(validation[1], validation[2], validation[3]))
      }
    })

    setErrorList(errors)
    if (errors.length > 0)
      showSnackError('There are missing fields. Please fill them and try again')
    return errors.length === 0
  }, [
    buildError,
    duration,
    esdrtCategory,
    esdrtService,
    setErrorList,
    showSnackError,
    signatureRequirement,
    startDate,
    synopsis,
    templateCode,
    templateName,
  ])

  const handleSaveTemplate = useCallback(
    async (roles?: string[]) => {
      setIsDirty(false)

      setShowValidationErrors(true)
      if (validate()) {
        setShowValidationErrors(false)
        let template = createTemplateForApi(roles)

        const effective = {
          endDate: endDate,
          isTerminated: isTerminated,
          startDate: startDate,
        }

        template = { ...template, effective: effective }
        // Call UpdateClient or CreateNewClient to update or create a new client.
        let saveOperation
        let successMessage = ''
        if (selectedTemplateId) {
          saveOperation = EncounterServiceTemplateControllerService.updateEncounterServiceTemplate(
            selectedTemplateId,
            template
          )
          successMessage = t('encounter-template-page.snack-messages.update-success')
        } else {
          saveOperation =
            EncounterServiceTemplateControllerService.createEncounterServiceTemplate(template)
          successMessage = t('encounter-template-page.snack-messages.create-success')
        }
        await saveOperation
          .then((result) => {
            setLastUpdatedServiceTemplate(result)
            if (!selectedTemplateId) {
              saveAssociations(
                domainAssociations,
                null,
                EncounterServiceTemplateControllerService.addDomainAssociations,
                setOpenDomainsLinkModal,
                buildDomainAssociationsMap,
                handleApiError,
                () => showSnackSuccess(t('api.save-success')),
                { id: result.id }
              )

              saveAssociations(
                functionalGroupAssociations,
                null,
                EncounterServiceTemplateControllerService.addFunctionalAreaAssociations,
                setOpenFunctionalGroupsLinkModal,
                buildFunctionalGroupAssociationsMap,
                handleApiError,
                () => showSnackSuccess(t('api.save-success')),
                { id: result.id }
              )

              EncounterServiceTemplateControllerService.addFormAssociations(
                result.id as string,
                formAssociations
              )
              navigate('/encounter/service-templates')
            }
            showSnackSuccess(successMessage)
          })
          .catch((error) => {
            handleApiError(error)
          })
      }
    },
    [
      buildDomainAssociationsMap,
      buildFunctionalGroupAssociationsMap,
      createTemplateForApi,
      domainAssociations,
      endDate,
      formAssociations,
      functionalGroupAssociations,
      handleApiError,
      isTerminated,
      navigate,
      selectedTemplateId,
      setIsDirty,
      setLastUpdatedServiceTemplate,
      showSnackSuccess,
      startDate,
      t,
      validate,
    ]
  )

  const handleRoleChange = useCallback(
    async (roles: string[]) => {
      setTemplateRoles(roles)
      if (selectedTemplateId) await handleSaveTemplate(roles)
    },
    [handleSaveTemplate, selectedTemplateId]
  )

  return (
    <>
      <Breadcrumb breadcrumb={breadcrumb} />

      <Paper elevation={1} sx={{ p: 2 }}>
        <Box justifyContent="center">
          <Box sx={{ flexGrow: 1, mb: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography component="div" sx={{ flex: '1 1 100%' }} variant="h4">
                  {selectedTemplateId
                    ? templateName
                    : t('encounter-template-page.header-new-template')}
                </Typography>
              </Grid>

              <Box sx={{ marginLeft: '1rem', marginTop: '1rem', textAlign: 'end' }}>
                <MISButton onClick={() => handleSaveTemplate()}>
                  {t('encounter-template-page.buttons.save')}
                </MISButton>

                {selectedTemplateId && !isTerminated && (
                  <MISButton
                    onClick={handleTerminate}
                    sx={{
                      marginLeft: '1rem',
                    }}
                  >
                    {t('encounter-template-page.buttons.terminate')}
                  </MISButton>
                )}
                {selectedTemplateId && isTerminated && (
                  <MISButton
                    onClick={handleUnterminate}
                    sx={{
                      marginLeft: '1rem',
                    }}
                  >
                    {t('encounter-template-page.buttons.reactivate')}
                  </MISButton>
                )}
              </Box>
            </Grid>
          </Box>
          <Stack spacing={1}>
            <Paper elevation={6} sx={{ p: 5 }}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                {/* Row 1 */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <MISTextField
                    error={showValidationErrors && templateName === ''}
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      templateName === '' &&
                      t('encounter-template-page.validation.missing-name')
                    }
                    id="templateName"
                    inputProps={{ maxLength: 50 }}
                    label={t('encounter-template-page.search-label.template-name')}
                    onChange={(event) => handleChange(event.target.value, setTemplateName)}
                    required
                    value={templateName}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <MISTextField
                    error={showValidationErrors && synopsis === ''}
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      synopsis === '' &&
                      t('encounter-template-page.validation.missing-synopsis')
                    }
                    id="synopsis"
                    inputProps={{ maxLength: 50 }}
                    label={t('encounter-template-page.search-label.synopsis')}
                    onChange={(event) => handleChange(event.target.value, setSynopsis)}
                    required
                    value={synopsis}
                  />
                </Grid>
                {/* Row 2 */}
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    SelectProps={{
                      multiple: false,
                      onChange: (event) => handleChange(event.target.value, setProgram),
                      value: program,
                    }}
                    fullWidth
                    id="program"
                    label={t('encounter-template-page.search-label.program')}
                    onChange={(event) => handleChange(event.target.value, setProgram)}
                    select
                    value={program}
                  >
                    {programOptions?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    SelectProps={{
                      multiple: false,
                      onChange: (event) => handleChange(event.target.value, setType),
                      value: type,
                    }}
                    fullWidth
                    id="type"
                    label={t('encounter-template-page.search-label.type')}
                    onChange={(event) => handleChange(event.target.value, setType)}
                    select
                    value={type}
                  >
                    {typeOptions?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    SelectProps={{
                      multiple: false,
                      onChange: (event) => handleChange(event.target.value, setPurpose),
                      value: purpose,
                    }}
                    fullWidth
                    id="purpose"
                    label={t('encounter-template-page.search-label.purpose')}
                    onChange={(event) => handleChange(event.target.value, setPurpose)}
                    select
                    value={purpose}
                  >
                    {purposeOptions?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    disabled={!!selectedTemplateId}
                    error={showValidationErrors && templateCode === ''}
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      templateCode === '' &&
                      t('encounter-template-page.validation.missing-template-code')
                    }
                    id="templateCode"
                    inputProps={{ maxLength: 50 }}
                    label={t('encounter-template-page.search-label.template-code')}
                    onChange={(event) => handleChange(event.target.value, setTemplateCode)}
                    value={templateCode}
                  />
                </Grid>

                {/*Row 3 */}
                <Grid item md={3} sm={6} xs={12}>
                  {!selectedTemplateId || serviceTemplateFetched ? (
                    <MISDurationField
                      error={
                        showValidationErrors && !!esdrtCategory && !!esdrtService && duration === ''
                      }
                      helperText={
                        showValidationErrors &&
                        !!esdrtCategory &&
                        !!esdrtService &&
                        duration === '' &&
                        t('encounter-template-page.validation.missing-duration')
                      }
                      id="duration"
                      label={t('encounter-template-page.search-label.duration')}
                      onChange={(value) => handleChange(value, setDuration)}
                      required={!!esdrtCategory && !!esdrtService}
                      value={duration || ''}
                    />
                  ) : (
                    <MISTextField
                      error={
                        showValidationErrors && !!esdrtCategory && !!esdrtService && duration === ''
                      }
                      fullWidth
                      helperText={
                        showValidationErrors &&
                        !!esdrtCategory &&
                        !!esdrtService &&
                        duration === '' &&
                        t('encounter-template-page.validation.missing-duration')
                      }
                      id="duration"
                      label={t('encounter-template-page.search-label.duration')}
                      required={!!esdrtCategory && !!esdrtService}
                      value={duration || ''}
                    />
                  )}
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    fullWidth
                    id="mileage"
                    inputProps={{ maxLength: 50 }}
                    label={t('encounter-template-page.search-label.mileage')}
                    onChange={(event) => handleChange(event.target.value, setMileage)}
                    value={mileage}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MISTextField
                    SelectProps={{
                      multiple: false,
                      onChange: (event) => handleChange(event.target.value, setFunder),
                      value: funder,
                    }}
                    fullWidth
                    id="funder"
                    label={t('encounter-template-page.search-label.funder')}
                    onChange={(event) => handleChange(event.target.value, setFunder)}
                    select
                    size="small"
                    value={funder}
                    variant="outlined"
                  >
                    {funderOptions?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MISTextField
                    error={showValidationErrors && signatureRequirement === ''}
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      signatureRequirement === '' &&
                      t('encounter-template-page.validation.missing-signature-requirement')
                    }
                    id="signatureRequirement"
                    label={t('encounter-template-page.search-label.signature-requirement')}
                    onChange={(event) => handleChange(event.target.value, setSignatureRequirement)}
                    required
                    select
                    value={signatureRequirement}
                  >
                    {signatureRequirementOptions?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.code}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>

                {/*Row 4*/}
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MISDatePicker
                    error={showValidationErrors && !startDate}
                    helperText={
                      showValidationErrors &&
                      !startDate &&
                      t('encounter-template-page.validation.missing-start-date')
                    }
                    isDefaultToday
                    label={t('encounter-template-page.search-label.start-date')}
                    onChange={(value) => handleChange(value, setStartDate)}
                    readOnly={false}
                    required
                    value={startDate || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MISDatePicker
                    label={t('encounter-template-page.search-label.end-date')}
                    onChange={(value) => handleChange(value, setEndDate)}
                    readOnly={false}
                    value={endDate || ''}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MISTextField
                    fullWidth
                    id="attributionRequired"
                    label={t('encounter-template-page.search-label.attribution')}
                    onChange={(event) => handleChange(event.target.value, setAttributionRequired)}
                    required
                    select
                    value={attributionRequired}
                  >
                    {attributionRequiredOptions?.map((option, index) => (
                      <MenuItem key={index} value={option.value ? 'true' : 'false'}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <MISTextField
                    fullWidth
                    id="automated"
                    label={t('encounter-template-page.search-label.automated')}
                    onChange={(event) => handleChange(event.target.value, setAutomated)}
                    required
                    select
                    value={automated}
                  >
                    {automatedOptions?.map((option, index) => (
                      <MenuItem key={index} value={option.value ? 'true' : 'false'}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </Box>
        <Roles onChange={(roles) => handleRoleChange(roles)} templateRoles={templateRoles} />
        {/* Form section start*/}
        <Box component={Paper} sx={{ height: associationTablesHeight, my: 4, p: 2 }}>
          <Grid container height="100%">
            <Grid item xs={2}>
              <Box component={Paper} height="100%">
                <Typography
                  align="center"
                  fontSize="h6.fontSize"
                  fontWeight="bold"
                  sx={{
                    margin: 'auto',
                  }}
                >
                  {t('encounter-template-page.form-section.form-list')}
                </Typography>

                <MISButton
                  color="primary"
                  onClick={handleAddForm}
                  size="large"
                  startIcon={<AddCircleOutline />}
                  variant="text"
                >
                  {t('encounter-template-page.form-section.add-form')}
                </MISButton>
                <MISDraggableList
                  handleDelete={handleDeleteForm}
                  handleOnClick={handleSelectFormRow}
                  items={formAssociations}
                  onDragEnd={onDragEnd}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box component={Paper} height="100%">
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        align="center"
                        fontSize="h6.fontSize"
                        fontWeight="bold"
                        sx={{
                          margin: 'auto',
                        }}
                      >
                        {t('encounter-template-page.form-section.form-preview')}
                      </Typography>
                      <Box height="400px" overflow="auto">
                        {selectedForm?.schema && (
                          <FormViewer formJson={selectedForm.schema} readOnly />
                        )}
                        {!selectedForm?.schema && (
                          <Typography
                            align="center"
                            fontSize="h6.fontSize"
                            fontStyle="italic"
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              height: '100%',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                            {t('encounter-template-page.form-section.empty-form-list')}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {formListUpdated && selectedTemplateId && (
              <MISButton onClick={handleSaveForm} sx={{ marginTop: '10px' }}>
                {t('encounter-template-page.buttons.save')}
              </MISButton>
            )}
          </Grid>
        </Box>
        {/* Form section end*/}
        <Box>
          <Grid container direction="column">
            <Grid container direction="row" item spacing={2}>
              <Grid item md={6}>
                <Box sx={{ p: 2 }}>
                  <h3>{t('encounter-template-page.permissions.header')}</h3>
                  <Box
                    component={Paper}
                    sx={{ height: associationTablesHeight, overflowY: 'none' }}
                  >
                    <Box sx={{ mx: 1 }}>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        {/* Row 1 */}
                        <Grid item lg={6} md={6} sm={6} sx={{ my: 3 }} xs={12}>
                          <MISTextField
                            disabled={!esdrtEnabled}
                            fullWidth
                            id="esdrtCategory"
                            label={t('encounter-template-page.permissions.category')}
                            onChange={handleEsdrtCategoryChange}
                            select
                            value={esdrtCategory}
                          >
                            {esdrtCategoryOptions?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.eSDRTCategoryDesc}
                              </MenuItem>
                            ))}
                          </MISTextField>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} sx={{ my: 3 }} xs={12}>
                          <MISTextField
                            disabled={!esdrtEnabled}
                            fullWidth
                            id="esdrtService"
                            label={t('encounter-template-page.permissions.service')}
                            onChange={handleEsdrtServiceChange}
                            select
                            value={esdrtService}
                          >
                            {esdrtServiceOptions?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.eSDRTServiceDesc}
                              </MenuItem>
                            ))}
                          </MISTextField>
                        </Grid>
                        {/* Row 2 */}
                        <Grid item lg={6} md={6} sm={6} sx={{ my: 3 }} xs={12}>
                          <MISTextField
                            disabled
                            fullWidth
                            id="esdrtDuration"
                            label={t('encounter-template-page.permissions.duration')}
                            value={esdrtDuration}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} sx={{ my: 8 }} xs={12}>
                          <MISButton
                            disabled={esdrtDuration === ''}
                            onClick={handleMakeTemplateDuration}
                          >
                            {t('encounter-template-page.buttons.make-template-duration')}
                          </MISButton>
                        </Grid>
                        {/* Row 3 */}
                        <Grid item lg={6} md={6} sm={6} sx={{ mt: 3 }} xs={12}>
                          <MISTextField
                            fullWidth
                            id="provincialServiceType"
                            label={t('encounter-template-page.permissions.service-type')}
                            onChange={(event) =>
                              handleChange(event.target.value, setProvincialType)
                            }
                            select
                            value={provincialType}
                          >
                            {provincialTypeOptions?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </MISTextField>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} sx={{ mt: 3 }} xs={12}>
                          <MISTextField
                            fullWidth
                            id="referralType"
                            label={t('encounter-template-page.permissions.referral-type')}
                            onChange={(event) => handleChange(event.target.value, setReferralType)}
                            select
                            value={referralType}
                          >
                            {referralTypeOptions?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </MISTextField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ mt: 8, p: 2 }}>
                  <AssociationTabTable
                    associations={domainAssociations}
                    closeLinkModalCallBack={() => setOpenDomainsLinkModal(false)}
                    entity={{ name: templateName }}
                    fixedHeightInPixels={associationTablesHeight}
                    openLinkModal={openDomainsLinkModal}
                    openLinkModalCallBack={() => setOpenDomainsLinkModal(true)}
                    otherEntities={domains}
                    saveCallBack={saveDomainAssociations}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={2}>
              <Grid item md={6}>
                <Box sx={{ p: 2 }}>
                  <h3>{t('encounter-template-page.domains')}</h3>
                  <AssociationTable
                    associations={domainAssociations}
                    closeLinkModalCallBack={() => setOpenDomainsLinkModal(false)}
                    entity={{ name: templateName }}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('encounter-template-page.domain-table-name')}
                    openLinkModal={openDomainsLinkModal}
                    openLinkModalCallBack={() => setOpenDomainsLinkModal(true)}
                    otherEntities={domains}
                    saveCallBack={saveDomainAssociations}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ p: 2 }}>
                  <h3>{t('encounter-template-page.functional-groups')}</h3>
                  <AssociationTable
                    associations={functionalGroupAssociations}
                    closeLinkModalCallBack={() => setOpenFunctionalGroupsLinkModal(false)}
                    entity={{ name: templateName }}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('encounter-template-page.functional-groups-table-name')}
                    openLinkModal={openFunctionalGroupsLinkModal}
                    openLinkModalCallBack={() => setOpenFunctionalGroupsLinkModal(true)}
                    otherEntities={functionalGroups}
                    saveCallBack={saveFunctionalGroupsAssociations}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <h3>{t('encounter-template-page.encounter-template-groups')}</h3>
              <AssociationTable
                associations={templateGroupAssociations}
                closeLinkModalCallBack={() => setOpenTemplateGroupsLinkModal(false)}
                entity={{ name: templateName }}
                fixedHeightInPixels={associationTablesHeight}
                headerName={t('encounter-template-page.encounter-template-groups-header')}
                openLinkModal={openTemplateGroupsLinkModal}
                openLinkModalCallBack={() => setOpenTemplateGroupsLinkModal(true)}
                otherEntities={templateGroups}
                saveCallBack={saveTemplateGroupAssociations}
              />
            </Grid>
            <Grid item md={12}>
              <h3>Status History</h3>
              TABLE GOES IN HERE
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {openFormModal && (
        <AddFormToSTDialog
          currentAssociations={formAssociations}
          entityName="Template"
          onCloseCallback={handleFormModalClose}
          onSaveCallback={saveFormAssociations}
          openDialog={openFormModal}
        />
      )}
    </>
  )
}

export default AddEditEncounterServiceTemplate
