import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Check } from '@mui/icons-material'
import { Box, Chip, Stack, Typography } from '@mui/material'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import MISLink from 'common/components/MISLink'
import GLOBAL from 'common/styles/global.scss'
import { isDateAfterToday, isDateBeforeOrEqualToday } from 'common/utils/DateUtils'
import './GovAgencyRecordHeader.scss'
import { domainsAtom, govAgenciesWithDomainsAtom } from 'recoil/atoms'
import {
  govAgencyDepartmentState,
  govAgencyState,
  govAssociatedDomainsState,
} from 'recoil/govAgency'
import { terminologySelector } from 'recoil/terminology'
import { CodedConceptDto, GovernanceAgencyControllerService } from 'services/openapi'
import { GOVERNANCE_AGENCY_TYPES } from 'services/terminologyConstants'

type GovAgencyRecordHeaderProps = {
  isDepartment?: boolean
  internalExternal?: boolean | null
}

const GovAgencyRecordHeader = ({ internalExternal, isDepartment }: GovAgencyRecordHeaderProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { agencyId } = useParams()
  const agencyDetails = useRecoilValue(govAgencyState)
  const departmentDetails = useRecoilValue(govAgencyDepartmentState)

  const resetAgencyDetails = useResetRecoilState(govAgencyState)
  const resetDepartmentDetails = useResetRecoilState(govAgencyDepartmentState)
  const agencyTypeOptions = useRecoilValue(terminologySelector(GOVERNANCE_AGENCY_TYPES))

  const domains = useRecoilValue(domainsAtom)
  const govAgencies = useRecoilValue(govAgenciesWithDomainsAtom)

  const associatedDomains = useRecoilValue(govAssociatedDomainsState)
  const [reorderedDomains, setReorderedDomains] = useState<JSX.Element[]>([])
  const [parentGovAgencyName, setParentGovAgencyName] = useState('')

  useEffect(() => {
    const getParentAgencyName = async (agencyId: string) => {
      const response = await GovernanceAgencyControllerService.getAgencyById(agencyId)
      if (response) {
        setParentGovAgencyName(response.name)
      }
    }
    const parentGovAgencyName = govAgencies?.find((obj) => obj.id === agencyId)?.name
    if (parentGovAgencyName) {
      setParentGovAgencyName(parentGovAgencyName)
    } else {
      agencyId && getParentAgencyName(agencyId)
    }
  }, [govAgencies, agencyId])

  const reset = useCallback(() => {
    resetAgencyDetails()
    resetDepartmentDetails()
  }, [resetAgencyDetails, resetDepartmentDetails])

  const gotoParentAgency = useCallback(() => {
    if (agencyId) {
      reset()
      navigate('/admin/governance-agencies/edit/' + agencyId)
    }
  }, [agencyId, reset, navigate])

  const isActive = useCallback(() => {
    const currentDetails = isDepartment ? departmentDetails : agencyDetails
    return (
      currentDetails?.effective &&
      !currentDetails?.effective.isTerminated &&
      currentDetails?.effective.startDate &&
      isDateBeforeOrEqualToday(currentDetails?.effective.startDate) &&
      (!currentDetails?.effective.endDate || isDateAfterToday(currentDetails?.effective.endDate))
    )
  }, [isDepartment, departmentDetails, agencyDetails])

  useEffect(() => {
    if (associatedDomains) {
      const sortedDomains = [...associatedDomains].sort((a, b) =>
        a.governanceAgencyDefault === true ? -1 : 1
      )

      const content = sortedDomains.reduce<JSX.Element[]>((acc, domain, index) => {
        if (index !== 0) {
          acc.push(<span key={'separator' + index}>{' | '}</span>)
        }

        if (domain.governanceAgencyDefault) {
          acc.push(
            <Check
              key={'check' + index}
              style={{
                color: GLOBAL.BUTTON_PRIMARY_BG_COLOR,
                float: 'left',
                margin: `0 ${GLOBAL.MARGIN_XXS}`,
              }}
            />
          )
        }

        const domainName = domains.find((o) => o.id === domain.domainId)?.domainName
        acc.push(<span key={'domainName' + index}>{domainName}</span>)

        return acc
      }, [])

      setReorderedDomains(content)
    }
  }, [associatedDomains, domains])

  return (
    <Box className="gov-agency-record-header">
      <Box className="panel right">
        <Stack spacing={0}>
          <Typography className="title">
            {isDepartment ? departmentDetails?.name : agencyDetails?.name}
          </Typography>
          <Box>
            {isDepartment
              ? departmentDetails?.governanceAgencyType?.map((type) => (
                  <Chip key={type.code} label={type.code} sx={{ m: 0.5 }} />
                ))
              : agencyDetails?.governanceAgencyType?.map((type) => (
                  <Chip
                    key={type.code}
                    label={
                      agencyTypeOptions.find((opt: CodedConceptDto) => opt.code === type.code)?.name
                    }
                    sx={{ m: 0.5 }}
                  />
                ))}
            {agencyDetails?.governanceAgencyType?.some((x) => x.code === 'Organization') &&
              internalExternal !== null && (
                <Chip
                  className="external-chip"
                  label={
                    internalExternal
                      ? t('gov-agency-header.internal-label')
                      : t('gov-agency-header.external-label')
                  }
                  sx={{ m: 0.5 }}
                />
              )}
            {isActive() && (
              <Chip
                className="active-chip"
                label={t('gov-agency-header.active-label')}
                sx={{ m: 0.5 }}
              />
            )}
          </Box>
        </Stack>

        <Box className="identifier-container">
          {isDepartment && (
            <Typography component="span" sx={{ marginRight: '20px' }} variant="subtitle1">
              {t('gov-agency-header.parent-gov-agency-label')}
              <MISLink
                aria-current="page"
                color="primary"
                key="link-parent-agency"
                onClick={(event) => {
                  event.preventDefault()
                  gotoParentAgency()
                }}
                underline="hover"
                variant="inherit"
              >
                {parentGovAgencyName}
              </MISLink>
            </Typography>
          )}
          <Typography component="span" variant="subtitle1">
            {t('gov-agency-header.domains-label')}
          </Typography>
          <Typography className="domain-typography" sx={{ mx: 0.5 }}>
            {reorderedDomains}
          </Typography>
        </Box>
        <Box className="identifier-container">
          {isDepartment && (
            <Typography component="span" sx={{ marginRight: '20px' }} variant="subtitle1">
              {t('gov-agency-header.parent-gov-agency-type-label')}
              <span>{agencyDetails?.governanceAgencyType?.map((type) => ' ' + type.code)}</span>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default GovAgencyRecordHeader
