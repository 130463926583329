import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, DatePickerProps } from '@mui/lab'
import { Box, Stack, TextFieldProps } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import { IDHE_DATE_DISPLAY_FORMAT } from 'common/utils/DateUtils'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBDatePickerProps = {
  data?: DatePickerProps & TextFieldProps
  onChange: (date: unknown, keyboardInputValue?: string | undefined) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBDatePicker = ({ data, onChange, onDelete, onSave, preview }: FBDatePickerProps) => {
  const { t } = useTranslation('common')

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(data || {})
  const [configMode, setConfigMode] = useState(false)

  return (
    <>
      {preview ? (
        <DatePicker
          {...data}
          inputFormat={IDHE_DATE_DISPLAY_FORMAT}
          onChange={onChange}
          renderInput={(props) => <MISTextField {...props} />}
          value={data?.value || null}
        />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <DatePicker
              inputFormat={IDHE_DATE_DISPLAY_FORMAT}
              onChange={onChange}
              renderInput={(props) => <MISTextField {...props} />}
              value={data?.value || null}
            />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.date-picker.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.name}
                label={t('charting.form-builder.name')}
                onChange={(e) =>
                  setConfigData(
                    configData ? { ...configData, name: e.target.value } : { name: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.helperText}
                label={t('charting.form-builder.helperText')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, helperText: e.target.value }
                      : { helperText: e.target.value }
                  )
                }
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBDatePicker
