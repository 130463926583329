import { useCallback, useMemo, useState } from 'react'
import { Box, IconButton, styled, Typography } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import MISCircleExclaimRed from 'common/components/icons/MISCircleExclaimRed'
import GLOBAL from 'common/styles/global.scss'

type WarningTooltipProps = {
  reason?: string
  header?: string
}

const WarningTooltip = ({ header, reason }: WarningTooltipProps) => {
  const [show, setShow] = useState(false)

  const StyledTooltip = useMemo(
    () =>
      styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
          [`& .${tooltipClasses.arrow}`]: {
            color: '#6f6f6f',
            left: '-125px !important',
          },
          backgroundColor: '#6f6f6f',
          marginLeft: 250,
          minWidth: 300,
          paddingBottom: GLOBAL.PADDING_SM,
          paddingLeft: GLOBAL.PADDING_SM,
          paddingRight: GLOBAL.PADDING_SM,

          paddingTop: GLOBAL.PADDING_MD,
        },
      })),
    []
  )

  const getTooltipContent = useCallback(
    (header?: string, body?: string) => (
      <>
        <Typography sx={{ color: GLOBAL.WHITE, fontSize: 12, fontWeight: 650 }}>
          {header}
        </Typography>
        <Typography sx={{ color: GLOBAL.WHITE, fontSize: 14, paddingBottom: GLOBAL.PADDING_SM }}>
          {body}
        </Typography>
      </>
    ),
    []
  )

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation()
      setShow(!show)
    },
    [show]
  )

  if (reason || header)
    return (
      <StyledTooltip
        arrow
        open={show}
        title={
          <Box onClick={(event) => event.stopPropagation()}>
            {reason && getTooltipContent(header, reason)}
          </Box>
        }
      >
        <IconButton onBlur={() => setShow(false)} onClick={(event) => handleClick(event)}>
          <MISCircleExclaimRed />
        </IconButton>
      </StyledTooltip>
    )

  return <MISCircleExclaimRed />
}

export default WarningTooltip
