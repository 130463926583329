import { Switch, SwitchProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

const MISSwitch = (props: SwitchProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <Switch {...props} size={props.size || 'small'} />
    </ThemeProvider>
  )
}

export default MISSwitch
