import { createTheme } from '@mui/material/styles'
import global from 'common/styles/global.scss'

const misTheme = createTheme({
  components: {
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiAutocomplete-inputRoot': {
    //         '& input': {
    //           height: global.FIELD_BASE_HEIGHT,
    //           padding: global.PADDING_XS,
    //         },
    //         backgroundColor: global.WHITE,
    //         color: global.BlACK,
    //         padding: global.PADDING_XXXS,
    //       },
    //     },
    //   },
    // },
    // MuiBadge: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiBadge-badge': {
    //         backgroundColor: global.BLACK,
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: global.BUTTON_REGULAR_FONT_FAMILY,
          fontWeight: global.BUTTON_REGULAR_FONT_FAMILY,
          height: global.BUTTON_REGULAR_HEIGHT,
          minWidth: 'auto',
          padding: `${global.MARGIN_XXXS} ${global.MARGIN_MD} ${global.MARGIN_XXXS}  ${global.MARGIN_MD}`,
          textAlign: global.TEXT_ALIGN_CENTER,
          ...(ownerState.size === 'small' && {
            height: global.BUTTON_SMALL_HEIGHT,
            padding: `${global.MARGIN_XXXS} ${global.MARGIN_SM} ${global.MARGIN_XXXS} ${global.MARGIN_SM}`,
          }),
          '&.Mui-disabled': {
            backgroundColor: global.BUTTON_DISABLED_BG_COLOR,
            border: `1px solid ${global.BUTTON_DISABLED_BG_COLOR}`,
            color: global.BUTTON_DISABLED_COLOR,
            ...(ownerState.variant === 'outlined' && {
              backgroundColor: 'transparent',
            }),
            ...(ownerState.variant === 'text' && {
              backgroundColor: 'transparent',
              border: 'none',
              textDecoration: 'underline',
            }),
          },
          border: 'none',
          borderRadius: global.MARGIN_XXS,
          boxShadow: 'none',
          cursor: 'pointer',
          lineHeight: 'normal',
          textTransform: 'capitalize',
          ...(ownerState.color === 'primary' && {
            backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
            color: global.BUTTON_PRIMARY_COLOR,
          }),
          ...(ownerState.variant === 'text' && {
            '&:active, &:focus': {
              backgroundColor: 'transparent',
              color: global.BUTTON_PRIMARY_PRESSED_BG_COLOR,
              textDecoration: 'underline',
            },
            '&:hover': {
              backgroundColor: 'transparent',
              color: global.BUTTON_PRIMARY_BG_COLOR,
              opacity: '0.8',
              textDecoration: 'underline',
            },
            backgroundColor: 'transparent',
            color: global.BUTTON_PRIMARY_BG_COLOR,
          }),
          ...(ownerState.variant === 'contained' && {
            '&:active, &:focus': {
              backgroundColor: global.BUTTON_PRIMARY_PRESSED_BG_COLOR,
              color: global.BUTTON_PRIMARY_COLOR,
            },
            '&:hover': {
              backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
              color: global.BUTTON_PRIMARY_COLOR,
              opacity: '0.8',
            },
            backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
            color: global.BUTTON_PRIMARY_COLOR,
          }),
          ...((ownerState.color === 'secondary' || ownerState.variant === 'outlined') && {
            '&:active, &:focus': {
              backgroundColor: global.BUTTON_PRIMARY_PRESSED_BG_COLOR,
              border: `${global.MARGIN_XXXS} solid ${global.BUTTON_PRIMARY_PRESSED_BG_COLOR}`,
              color: global.BUTTON_PRIMARY_COLOR,
            },
            '&:hover': {
              backgroundColor: global.BUTTON_SECONDARY_BG_COLOR,
              border: `1px solid ${global.BUTTON_SECONDARY_COLOR}`,
              borderOpacity: '0.8',
              color: global.BUTTON_SECONDARY_COLOR,
              opacity: '0.8',
            },
            backgroundColor: global.BUTTON_SECONDARY_BG_COLOR,
            border: `1px solid ${global.BUTTON_SECONDARY_COLOR}`,
            color: global.BUTTON_SECONDARY_COLOR,
          }),
          ...(ownerState.color === 'success' && {
            '&:hover': {
              backgroundColor: global.BUTTON_SUCCESS_BG_COLOR,
              color: global.WHITE,
              opacity: '0.8',
            },
            backgroundColor: global.BUTTON_SUCCESS_BG_COLOR,
            color: global.WHITE,
          }),
        }),
      },
    },
    // MuiCheckbox: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-checked': {
    //         color: global.BUTTON_PRIMARY_BG_COLOR,
    //       },
    //     },
    //   },
    // },
    // MuiChip: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       backgroundColor: global.BUTTON_DISABLED_BG_COLOR,
    //       padding: `0 ${global.MARGIN_XS}`,
    //       ...(ownerState.color === 'primary' && {
    //         backgroundColor: global.CHIP_ACTIVE_BG_COLOR,
    //       }),
    //       ...(ownerState.variant === 'outlined' && {
    //         backgroundColor: global.CHIP_BG_COLOR,
    //       }),
    //     }),
    //   },
    // },
    // MuiDivider: {
    //   styleOverrides: {
    //     root: {
    //       marginBottom: global.MARGIN_XXS,
    //     },
    //   },
    // },
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiFormHelperText-root.Mui-error': {
    //         color: global.FIELD_ERROR_TEXT,
    //       },
    //       '& .MuiFormLabel-root.MuiInputLabel-root': {
    //         color: global.BUTTON_DISABLED_BG_COLOR,
    //         fontSize: global.FONT_SIZE_H4,
    //         padding: `${global.MARGIN_XXXS} ${global.MARGIN_XXXS} ${global.MARGIN_XXXS} ${global.MARGIN_XXXS}`,
    //       },
    //       '& .MuiFormLabel-root.select-dropdown.Mui-focused': {
    //         color: global.FIELD_LABEL_COLOR,
    //       },
    //       '& .MuiTextField-root': {
    //         marginBottom: global.MARGIN_XXS,
    //         marginTop: global.MARGIN_XXS,
    //       },
    //     },
    //   },
    // },
    // MuiFormControlLabel: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiFormControlLabel-label': {
    //         '& .MuiFormLabel-asterisk': {
    //           display: 'none',
    //         },
    //         color: global.FIELD_LABEL_COLOR,
    //         display: 'block',
    //         fontSize: global.FIELD_LABEL_FONT_SIZE,
    //       },
    //     },
    //   },
    // },
    // MuiFormHelperText: {
    //   styleOverrides: {
    //     root: {
    //       margin: 0,
    //     },
    //   },
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiFormLabel-asterisk': {
    //         display: 'none',
    //       },
    //       color: global.FIELD_LABEL_COLOR,
    //       display: 'block',
    //       fontSize: global.FIELD_LABEL_FONT_SIZE,
    //     },
    //   },
    // },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root': {
            display: 'flex',
          },
        },
      },
    },
    // MuiLink: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiSvgIcon-root': {
    //         display: 'inline-flex',
    //         margin: global.MARGIN_XXXS,
    //         position: 'absolute',
    //       },
    //       '&:hover': {
    //         textDecoration: 'underline',
    //       },
    //       color: global.LINK_PRIMARY_COLOR,
    //       textDecoration: 'none',
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         border: `1px solid ${global.FIELD_BORDER_COLOR}`,
    //       },
    //       '& button': {
    //         marginRight: 0,
    //       },
    //       '& fieldset': {
    //         border: `1px solid ${global.FIELD_BORDER_COLOR}`,
    //         borderRadius: global.FIELD_BORDER_RADIUS,
    //       },
    //       '&.Mui-disabled': {
    //         '&:hover .MuiOutlinedInput-notchedOutline': {
    //           border: `1px solid ${global.FIELD_BORDER_COLOR}`,
    //         },
    //         backgroundColor: global.FIELD_DISABLED_BG_COLOR,
    //       },
    //       '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
    //         {
    //           border: `2px solid ${global.BUTTON_PRIMARY_PRESSED_BG_COLOR}`,
    //         },
    //       '&.Mui-readOnly': {
    //         '&:hover .MuiOutlinedInput-notchedOutline': {
    //           border: `1px solid ${global.FIELD_BORDER_COLOR}`,
    //         },
    //         backgroundColor: global.FIELD_DISABLED_BG_COLOR,
    //       },
    //       backgroundColor: global.WHITE,
    //       color: global.BlACK,
    //       padding: global.MARGIN_XXXS,
    //     },
    //   },
    // },
    // MuiRadio: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-checked': {
    //         color: global.BUTTON_PRIMARY_BG_COLOR,
    //       },
    //       padding: `${global.MARGIN_XXXS} ${global.MARGIN_XXXS} ${global.MARGIN_XXXS} ${global.MARGIN_XS}`,
    //     },
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       '& .placeholder-value': {
    //         color: global.FIELD_PLACEHOLDER_FONT_COLOR,
    //       },
    //     },
    //   },
    // },
    // MuiSlider: {
    //   styleOverrides: {
    //     root: {
    //       color: global.BUTTON_PRIMARY_BG_COLOR,
    //     },
    //   },
    // },
    // MuiSwitch: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiSwitch-switchBase.Mui-checked': {
    //         color: global.BUTTON_PRIMARY_BG_COLOR,
    //       },
    //       '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    //         backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
    //       },
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       padding: global.MARGIN_XS,
    //     },
    //   },
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiInputLabel-root': {
    //         color: global.FIELD_DISABLED_BG_COLOR,
    //         padding: `${global.MARGIN_XXXS} ${global.MARGIN_XXXS} ${global.MARGIN_XXXS}  ${global.MARGIN_SM}`,
    //       },
    //     },
    //   },
    // },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: global.FONT_BODY_LARGE,
          fontWeight: global.FONT_WEIGHT_REGULAR,
          lineHeight: 1.5,
        },
        body2: {
          fontSize: global.FONT_BODY_REGULAR,
          fontWeight: global.FONT_WEIGHT_REGULAR,
          lineHeight: 1.5,
        },
        h1: {
          fontSize: global.FONT_SIZE_H1,
          fontWeight: global.FONT_WEIGHT_SEMIBOLD,
          lineHeight: 1.2,
        },
        h2: {
          fontSize: global.FONT_SIZE_H2,
          fontWeight: global.FONT_WEIGHT_SEMIBOLD,
          lineHeight: 1.2,
        },
        h3: {
          fontSize: global.FONT_SIZE_H3,
          fontWeight: global.FONT_WEIGHT_MEDIUM,
          lineHeight: 1.2,
        },
        h4: {
          fontSize: global.FONT_SIZE_H4,
          fontWeight: global.FONT_WEIGHT_SEMIBOLD,
          lineHeight: 1.2,
        },
        h5: {
          fontSize: global.FONT_SIZE_H5,
          fontWeight: global.FONT_WEIGHT_SEMIBOLD,
          lineHeight: 1.2,
        },
        h6: {
          fontSize: global.FONT_SIZE_H6,
          fontWeight: global.FONT_WEIGHT_SEMIBOLD,
          lineHeight: 1.2,
        },
        root: {
          color: global.BASE_FONT_COLOR,
          fontFamily: global.BASE_FONT_FAMILY,
          fontSize: global.BASE_FONT_SIZE,
          fontWeightRegular: global.BASE_FONT_WEIGHT,
          lineHeight: 1.5,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#c50',
    },
    secondary: {
      main: '#fff',
    },
  },
})

export default misTheme
