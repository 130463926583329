import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Container } from '@mui/system'
import 'survey-core/defaultV2.min.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { themeJson } from './MISFormTheme.js'

type FormViewerProps = {
  formJson: string
  formData?: string
  readOnly?: boolean
}

const FormViewer = forwardRef(({ formData, formJson, readOnly }: FormViewerProps, ref) => {
  const survey = useMemo(() => new Model(formJson), [formJson])

  useImperativeHandle(
    ref,
    () => ({
      getData() {
        if (survey && survey.validate()) return JSON.stringify(survey.data)
        else return ''
      },
    }),
    [survey]
  )

  useEffect(() => {
    survey.applyTheme(themeJson)
    survey.showNavigationButtons = false
    if (formData) survey.data = JSON.parse(formData)
    survey.mode = readOnly ? 'display' : 'edit'
  }, [formData, readOnly, survey])

  return (
    <div className="FormViewer">
      <Container maxWidth={false} sx={{ maxWidth: '100%' }}>
        <Survey model={survey} />
      </Container>
    </div>
  )
})
FormViewer.displayName = 'FormViewer'

export default FormViewer
