/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomainAssociationDTO } from '../models/DomainAssociationDTO'
import type { EncounterServiceTemplateAssociationDTO } from '../models/EncounterServiceTemplateAssociationDTO'
import type { EncounterServiceTemplateDTO } from '../models/EncounterServiceTemplateDTO'
import type { FunctionalAreaAssociationDTO } from '../models/FunctionalAreaAssociationDTO'
import type { PageEncounterServiceTemplateDTO } from '../models/PageEncounterServiceTemplateDTO'
import type { TemplateFormAssociationEntity } from '../models/TemplateFormAssociationEntity'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterServiceTemplateControllerService {
  /**
   * Get paged list of EncounterServiceTemplate with optional search params
   * The name param allows searching for items with a name containing name. Use the  param to return only items where effectiveAt is within the effective date rang.eAll items are returned if effectiveAt is not specified, including terminated items.
   * @param fullText
   * @param name
   * @param synopsis
   * @param programName
   * @param purposeName
   * @param typeName
   * @param signatureRequirement
   * @param automated
   * @param durationSpecified
   * @param associatedDomains
   * @param associatedFunctionalAreas
   * @param terminated
   * @param effectiveAt
   * @returns PageEncounterServiceTemplateDTO OK
   * @throws ApiError
   */
  public static organizationgetPagedList(
    fullText?: string,
    name?: string,
    synopsis?: string,
    programName?: string,
    purposeName?: string,
    typeName?: string,
    signatureRequirement?: string,
    automated?: boolean,
    durationSpecified?: string,
    associatedDomains?: Array<string>,
    associatedFunctionalAreas?: Array<string>,
    terminated?: boolean,
    effectiveAt?: string
  ): CancelablePromise<PageEncounterServiceTemplateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates',
      query: {
        fullText: fullText,
        name: name,
        synopsis: synopsis,
        programName: programName,
        purposeName: purposeName,
        typeName: typeName,
        signatureRequirement: signatureRequirement,
        automated: automated,
        durationSpecified: durationSpecified,
        associatedDomains: associatedDomains,
        associatedFunctionalAreas: associatedFunctionalAreas,
        terminated: terminated,
        effectiveAt: effectiveAt,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string OK
   * @throws ApiError
   */
  public static createEncounterServiceTemplates(
    requestBody: Array<EncounterServiceTemplateDTO>
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create an EncounterServiceTemplate
   * @param requestBody
   * @returns EncounterServiceTemplateDTO OK
   * @throws ApiError
   */
  public static createEncounterServiceTemplate(
    requestBody: EncounterServiceTemplateDTO
  ): CancelablePromise<EncounterServiceTemplateDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * get all Functional Area Associations to existing EncounterServiceTemplate
   * @param templateId
   * @returns FunctionalAreaAssociationDTO OK
   * @throws ApiError
   */
  public static getFunctionalAreaAssociationsForEncounterTemplate(
    templateId: string
  ): CancelablePromise<Array<FunctionalAreaAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/functional-areas',
      path: {
        templateId: templateId,
      },
    })
  }

  /**
   * Add FunctionalArea Associations to existing EncounterServiceTemplate
   * An array of functionalArea associations is provided the JSON request body.
   * @param templateId
   * @param requestBody
   * @returns FunctionalAreaAssociationDTO OK
   * @throws ApiError
   */
  public static addFunctionalAreaAssociations(
    templateId: string,
    requestBody: Array<FunctionalAreaAssociationDTO>
  ): CancelablePromise<Array<FunctionalAreaAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/functional-areas',
      path: {
        templateId: templateId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * get all Form Associations to existing EncounterServiceTemplate
   * @param templateId
   * @returns TemplateFormAssociationEntity OK
   * @throws ApiError
   */
  public static getFormAssociationsForEncounterTemplate(
    templateId: string
  ): CancelablePromise<Array<TemplateFormAssociationEntity>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/forms',
      path: {
        templateId: templateId,
      },
    })
  }

  /**
   * Add Form Associations to existing EncounterServiceTemplate
   * An array of form associations is provided the JSON request body.
   * @param templateId
   * @param requestBody
   * @returns TemplateFormAssociationEntity OK
   * @throws ApiError
   */
  public static addFormAssociations(
    templateId: string,
    requestBody: Array<TemplateFormAssociationEntity>
  ): CancelablePromise<Array<TemplateFormAssociationEntity>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/forms',
      path: {
        templateId: templateId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * get all Domain Associations to existing EncounterServiceTemplate
   * @param templateId
   * @returns DomainAssociationDTO OK
   * @throws ApiError
   */
  public static getDomainAssociationsForEncounterTemplate(
    templateId: string
  ): CancelablePromise<Array<DomainAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/domains',
      path: {
        templateId: templateId,
      },
    })
  }

  /**
   * Add Domain Associations to existing EncounterServiceTemplate
   * An array of domain associations is provided the JSON request body.
   * @param templateId
   * @param requestBody
   * @returns DomainAssociationDTO OK
   * @throws ApiError
   */
  public static addDomainAssociations(
    templateId: string,
    requestBody: Array<DomainAssociationDTO>
  ): CancelablePromise<Array<DomainAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/domains',
      path: {
        templateId: templateId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get an EncounterServiceTemplate by id
   * @param id
   * @returns EncounterServiceTemplateDTO OK
   * @throws ApiError
   */
  public static organizationgetById(id: string): CancelablePromise<EncounterServiceTemplateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * Update an EncounterServiceTemplate
   * The properties of the EncounterServiceTemplate are specified in the JSON request body.
   * @param id
   * @param requestBody
   * @returns EncounterServiceTemplateDTO OK
   * @throws ApiError
   */
  public static updateEncounterServiceTemplate(
    id: string,
    requestBody: EncounterServiceTemplateDTO
  ): CancelablePromise<EncounterServiceTemplateDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * get Form Association to existing EncounterServiceTemplate by Id
   * @param templateId
   * @param formId
   * @returns TemplateFormAssociationEntity OK
   * @throws ApiError
   */
  public static getFormAssociationForEncounterTemplate(
    templateId: string,
    formId: string
  ): CancelablePromise<TemplateFormAssociationEntity> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/forms/{formId}',
      path: {
        templateId: templateId,
        formId: formId,
      },
    })
  }

  /**
   * delete Form Association to existing EncounterServiceTemplate by Id
   * @param templateId
   * @param formId
   * @returns TemplateFormAssociationEntity OK
   * @throws ApiError
   */
  public static deleteFormAssociationForEncounterTemplate(
    templateId: string,
    formId: string
  ): CancelablePromise<TemplateFormAssociationEntity> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/forms/{formId}',
      path: {
        templateId: templateId,
        formId: formId,
      },
    })
  }

  /**
   * get EncounterServiceTemplateAssociations by templateId
   * @param templateId
   * @returns EncounterServiceTemplateAssociationDTO OK
   * @throws ApiError
   */
  public static getEncounterServiceTemplateAssociations(
    templateId: string
  ): CancelablePromise<Array<EncounterServiceTemplateAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-templates/{templateId}/encounter-service-template-groups',
      path: {
        templateId: templateId,
      },
    })
  }
}
