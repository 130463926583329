/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomainAssociationDTO } from '../models/DomainAssociationDTO'
import type { DomainEntity } from '../models/DomainEntity'
import type { PageObject } from '../models/PageObject'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AgencyDomainControllerService {
  /**
   * Returns a list of Domains for Agency
   * List is returned as UnPaged collection, assumes this list will normally be small.
   * @param nameList
   * @param verbose
   * @returns any OK
   * @throws ApiError
   */
  public static listDomains(
    nameList: boolean = false,
    verbose: boolean = false
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/setup/domains',
      query: {
        nameList: nameList,
        verbose: verbose,
      },
    })
  }

  /**
   * Create list of domains
   * These are standalone domains with no association to a governance agency
   * @param requestBody
   * @returns DomainEntity OK
   * @throws ApiError
   */
  public static putDomains(
    requestBody: Array<DomainEntity>
  ): CancelablePromise<Array<DomainEntity>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/setup/domains',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create one domain
   * These are standalone domains with no association to a governance agency
   * @param requestBody
   * @returns DomainEntity OK
   * @throws ApiError
   */
  public static postDomain(requestBody: DomainEntity): CancelablePromise<DomainEntity> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/setup/domains',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Returns a list of agency domain associations for Agency
   * List is returned as Paged collection
   * @param agencyId
   * @param includeInactive
   * @param activeOnDate
   * @param verbose
   * @returns PageObject OK
   * @throws ApiError
   */
  public static listAgencyDomains(
    agencyId: string,
    includeInactive: boolean = false,
    activeOnDate?: string,
    verbose: boolean = false
  ): CancelablePromise<PageObject> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/domains',
      path: {
        agencyId: agencyId,
      },
      query: {
        includeInactive: includeInactive,
        activeOnDate: activeOnDate,
        verbose: verbose,
      },
    })
  }

  /**
   * Create a new association of domain to specified Agency
   * Validation will be performed to check for date period overlaps with existing
   * @param agencyId
   * @param requestBody
   * @returns DomainAssociationDTO OK
   * @throws ApiError
   */
  public static addAgencyDomainAssociation(
    agencyId: string,
    requestBody: Array<DomainAssociationDTO>
  ): CancelablePromise<Array<DomainAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/domains',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param agencyId
   * @param associationId
   * @returns DomainEntity OK
   * @throws ApiError
   */
  public static getAgencyDomainById(
    agencyId: string,
    associationId: string
  ): CancelablePromise<DomainEntity> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/domains/{associationId}',
      path: {
        agencyId: agencyId,
        associationId: associationId,
      },
    })
  }

  /**
   * Update the association of domain to specified Agency
   * Validation will be performed to check for date period overlaps with existing
   * @param agencyId
   * @param associationId
   * @param requestBody
   * @returns DomainAssociationDTO OK
   * @throws ApiError
   */
  public static updateAgencyDomainAssociation(
    agencyId: string,
    associationId: string,
    requestBody: DomainAssociationDTO
  ): CancelablePromise<DomainAssociationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/domains/{associationId}',
      path: {
        agencyId: agencyId,
        associationId: associationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param domainId
   * @returns DomainEntity OK
   * @throws ApiError
   */
  public static getDomainById(domainId: string): CancelablePromise<DomainEntity> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/setup/domains/{domainId}',
      path: {
        domainId: domainId,
      },
    })
  }

  /**
   * Delete a domain association with a governance agency
   * Agency and domain ids are specified in the URL.
   * @param agencyId
   * @param domainId
   * @returns void
   * @throws ApiError
   */
  public static deleteAgencyDomainAssociation(
    agencyId: string,
    domainId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/domains/{domainId}',
      path: {
        agencyId: agencyId,
        domainId: domainId,
      },
      errors: {
        400: `Failed to delete the specified domain agency association`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }
}
