import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { useRecoilState } from 'recoil'
import Loader from 'common/components/Loader'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import StaffAssociation, {
  CustomStaffMemberProps,
} from 'modules/shared/StaffAssociation/StaffAssociation'
import {
  encounterServicesStaffPersonnelState,
  encounterServicesStaffState,
} from 'recoil/encounters'
import {
  CancelablePromise,
  EncounterStaffControllerService,
  PersonnelControllerService,
  PersonnelDTO,
  StaffUserDTO,
} from 'services/openapi'

type EncounterServiceStaffProps = {
  encounterId: string
  encounterServiceId: string
}

const EncounterServicesStaff = ({
  encounterId,
  encounterServiceId,
}: EncounterServiceStaffProps) => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [staff, setStaff] = useRecoilState(encounterServicesStaffState)
  const [staffPersonnel, setStaffPersonnel] = useRecoilState(encounterServicesStaffPersonnelState)

  const [associatedStaff, setAssociatedStaff] = useState<StaffUserDTO[]>([])
  const [staffPersonnelList, setStaffPersonnelList] = useState<PersonnelDTO[]>([])
  const [selectedPersonnels, setSelectedPersonnels] = useState<CustomStaffMemberProps[]>([])

  useEffect(() => {
    const fetchEncounterServiceStaff = async (encounterId: string, encounterServiceId: string) => {
      try {
        const response = await EncounterStaffControllerService.getPagedList2(
          encounterId,
          encounterServiceId
        )
        setAssociatedStaff(response.content as StaffUserDTO[])
      } catch (error) {
        handleApiError(error)
      }
    }

    fetchEncounterServiceStaff(encounterId, encounterServiceId)
  }, [encounterId, encounterServiceId, handleApiError])

  useEffect(() => {
    const fetchPersonnelList = async () => {
      try {
        const personnelIds: string[] = []
        associatedStaff?.forEach((staff: StaffUserDTO) => {
          if (staff.providerId) {
            personnelIds.push(staff.providerId)
          }
        })
        const promises: CancelablePromise<PersonnelDTO>[] = []
        personnelIds.forEach((personnelId) => {
          promises.push(PersonnelControllerService.getPersonnel(personnelId))
        })
        const response = await Promise.all(promises)
        setStaffPersonnelList(response)
      } catch (error) {
        handleApiError(error)
      }
    }
    fetchPersonnelList()
  }, [associatedStaff, handleApiError])

  useEffect(() => {
    const selectedStaffProviders: CustomStaffMemberProps[] = []
    associatedStaff.forEach((staff: StaffUserDTO) => {
      // Set Staff List
      if (staffPersonnelList.length > 0) {
        selectedStaffProviders.push({
          associationId: staff?.associationId || '',
          isPrimary: staff?.attributes?.isPrimaryProvider || false,
          personnel: staffPersonnelList.find(
            (provider: PersonnelDTO) => provider.id === staff.providerId
          ) as PersonnelDTO,
        })
      }
    })
    setSelectedPersonnels(selectedStaffProviders)
  }, [associatedStaff, staffPersonnelList])

  const handleAddStaff = useCallback(
    async (provider: PersonnelDTO) => {
      const isPrimaryProvider = associatedStaff.length > 0 ? false : true
      const attributes = { isPrimaryProvider }
      const staffUser = {
        associationId: '',
        attributes: attributes,
        providerId: provider.id,
      }

      const staffAssociations = {
        users: [staffUser],
      }
      const createEncounterStaffPayload = {
        associations: staffAssociations,
        id: provider.id,
      }

      try {
        const response = await EncounterStaffControllerService.addStaffToEncounterService(
          encounterId,
          encounterServiceId,
          createEncounterStaffPayload
        )
        setAssociatedStaff(response?.associations?.users as StaffUserDTO[])
      } catch (error) {
        handleApiError(error)
      }
    },
    [encounterId, encounterServiceId, associatedStaff, handleApiError]
  )

  useEffect(() => {
    if (!isEqual(associatedStaff, staff[encounterServiceId]))
      setStaff({ ...staff, [encounterServiceId]: associatedStaff })
  }, [associatedStaff, staff, setStaff, encounterServiceId])

  useEffect(() => {
    if (!isEqual(staffPersonnelList, staffPersonnel[encounterServiceId]))
      setStaffPersonnel({ ...staffPersonnel, [encounterServiceId]: staffPersonnelList })
  }, [staffPersonnelList, staffPersonnel, setStaffPersonnel, encounterServiceId])

  const handleUpdateStaff = useCallback(
    async (providerId: string) => {
      const staffUsers = selectedPersonnels.map(({ personnel }: CustomStaffMemberProps) => {
        let primaryProvider = false
        if (personnel?.id === providerId) {
          primaryProvider = true
        }
        const staff = associatedStaff.find(
          (staff: StaffUserDTO) => staff?.providerId === personnel?.id
        ) as StaffUserDTO
        return {
          associationId: staff.associationId,
          attributes: {
            isPrimaryProvider: primaryProvider,
          },
          providerId: personnel?.id,
        }
      })
      const staffAssociations = {
        users: staffUsers,
      }
      const updateEncounterStaffPayload = {
        associations: staffAssociations,
        id: providerId,
      }
      try {
        const response = await EncounterStaffControllerService.updateStaffAttributes(
          encounterId,
          encounterServiceId,
          updateEncounterStaffPayload
        )
        setAssociatedStaff(response?.associations?.users as StaffUserDTO[])
      } catch (error) {
        handleApiError(error)
      }
    },
    [encounterId, encounterServiceId, associatedStaff, selectedPersonnels, handleApiError]
  )

  const handleDeleteStaff = useCallback(
    async (associationId: string, index: number) => {
      try {
        await EncounterStaffControllerService.deleteEncounterServiceStaff(
          encounterId,
          encounterServiceId,
          associationId
        )
        const staffIdToDelete = selectedPersonnels[index].personnel?.id
        selectedPersonnels.splice(index as number, 1)
        setSelectedPersonnels([...selectedPersonnels])
        setAssociatedStaff(associatedStaff.filter((staff) => staff.providerId !== staffIdToDelete))
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
    },
    [
      encounterId,
      encounterServiceId,
      selectedPersonnels,
      associatedStaff,
      showSnackSuccess,
      t,
      handleApiError,
    ]
  )

  return (
    <>
      {selectedPersonnels.length > 0 ? (
        <StaffAssociation
          allowUpdate
          displayPrimaryIndicator
          members={selectedPersonnels}
          onAdd={(member: PersonnelDTO) => handleAddStaff(member)}
          onRemove={(associationId: string, memberIndex: number) =>
            handleDeleteStaff(associationId, memberIndex)
          }
          onUpdate={(memberId: string) => handleUpdateStaff(memberId)}
        />
      ) : (
        <>
          <SectionHeader isSubsection title="Add Provider/Staff" />
          <Loader height={100} />
        </>
      )}
    </>
  )
}

export default EncounterServicesStaff
