import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { TValue } from '../Charting'

type MentionItemProps = {
  item: TValue
}

const MentionItem = ({ item }: MentionItemProps) => {
  return <span>{item.value}</span>
}

const MentionNode = (item: TValue) => {
  const div = document.createElement('div')
  const root = createRoot(div)
  flushSync(() => {
    root.render(<MentionItem item={item} />)
  })
  return div
}

export default MentionNode
