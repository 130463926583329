import { AddCircleOutline, Delete } from '@mui/icons-material'
import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { ErrorType } from 'modules/shared/utils'

const MISListContainer = ({
  addLabel,
  emptyDataMessage = '',
  errors = [],
  hideDividers = false,
  isDisabled,
  onAddRow,
  onChangeRow,
  onRemoveRow,
  rowForm,
  rows = [],
}: any) => {
  return (
    <Box>
      {errors &&
        errors.map((error: ErrorType, index: number) => (
          <Alert
            className="error"
            key={index}
            severity="error"
            style={{ marginBottom: GLOBAL.MARGIN_XS }}
          >
            {error.message}
          </Alert>
        ))}
      {rows.length === 0 && (
        <Typography style={{ textAlign: 'center' }}>
          <em>{emptyDataMessage}</em>
        </Typography>
      )}
      {rows.map((row: any, rowIndex: number) => (
        <>
          {rowIndex === 0 && !hideDividers && <Divider sx={{ mt: GLOBAL.MARGIN_XS }} />}
          <List key={rowIndex} sx={{ '& hr': { mx: 0.5 }, boxShadow: 'none' }}>
            <ListItem
              secondaryAction={
                !isDisabled && (
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation()
                        onRemoveRow(rowIndex)
                      }}
                      size="small"
                      sx={{ pointerEvents: 'auto' }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                )
              }
              sx={{
                borderBottom: !hideDividers ? '1px solid rgba(0, 0, 0, 0.12)' : '',
                flexDirection: 'row-reverse',
                padding: `${GLOBAL.MARGIN_XS} ${GLOBAL.MARGIN_XS}`,
              }}
            >
              <Typography sx={{ alignSelf: 'center', width: '100%' }} variant="body2">
                {rowForm(row, rowIndex, onChangeRow)}
              </Typography>
            </ListItem>
          </List>
        </>
      ))}
      {addLabel && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MISButton
              color="primary"
              onClick={onAddRow}
              size="large"
              startIcon={<AddCircleOutline />}
              variant="text"
            >
              {addLabel}
            </MISButton>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default MISListContainer
