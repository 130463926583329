import { ReactNode } from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsIcon from '@mui/icons-material/Notifications'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'

export interface TopNavigationMenuChildConfigType {
  id: number
  key: string
  icon: ReactNode
  label: string
}

export interface TopNavigationMenuConfigType {
  id: number
  key: string
  name: string
  badgeCount: number
  icon: ReactNode
  children: TopNavigationMenuChildConfigType[]
}

const TOP_NAVIGATION_MENU_CONFIG: TopNavigationMenuConfigType[] = [
  {
    badgeCount: 0,
    children: [],
    icon: <PushPinOutlinedIcon />,
    id: 1,
    key: 'pinned-clients',
    name: 'Pinned Clients',
  },
  {
    badgeCount: 2,
    children: [
      {
        icon: <EmailIcon />,
        id: 1,
        key: 'message1',
        label: '2 new messages',
      },
      {
        icon: <EmailIcon />,
        id: 2,
        key: 'message2',
        label: '1 new message',
      },
    ],
    icon: <EmailIcon />,
    id: 2,
    key: 'messages',
    name: 'Messages',
  },
  {
    badgeCount: 1,
    children: [
      {
        icon: <AccountCircle />,
        id: 1,
        key: 'notification1',
        label: 'user logged in',
      },
    ],
    icon: <NotificationsIcon />,
    id: 3,
    key: 'notifications',
    name: 'Notifications',
  },
  {
    badgeCount: 5,
    children: [
      {
        icon: <OpenInNewIcon />,
        id: 1,
        key: '/user/preferences',
        label: 'Preferences',
      },
      {
        icon: <OpenInNewIcon />,
        id: 2,
        key: 'link2',
        label: 'Link 2',
      },
      {
        icon: <OpenInNewIcon />,
        id: 3,
        key: 'link3',
        label: 'Link 3',
      },
      {
        icon: <OpenInNewIcon />,
        id: 4,
        key: 'link4',
        label: 'Link 4',
      },
      {
        icon: <OpenInNewIcon />,
        id: 5,
        key: 'link5',
        label: 'Link 5',
      },
      {
        icon: <LogoutIcon />,
        id: 6,
        key: 'logout',
        label: 'Logout',
      },
    ],
    icon: <AccountCircle />,
    id: 4,
    key: 'account',
    name: 'My account',
  },
]

export default TOP_NAVIGATION_MENU_CONFIG
