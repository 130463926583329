/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncounterServiceDTO } from '../models/EncounterServiceDTO'
import type { PageEncounterServiceDTO } from '../models/PageEncounterServiceDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterServiceControllerService {
  /**
   * Get an EncounterService by id
   * @param encounterId
   * @param serviceId
   * @returns EncounterServiceDTO OK
   * @throws ApiError
   */
  public static encountersgetById1(
    encounterId: string,
    serviceId: string
  ): CancelablePromise<EncounterServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{serviceId}',
      path: {
        encounterId: encounterId,
        serviceId: serviceId,
      },
    })
  }

  /**
   * Update an EncounterService
   * The properties of the EncounterService are specified in the JSON request body.
   * @param encounterId
   * @param serviceId
   * @param requestBody
   * @returns EncounterServiceDTO OK
   * @throws ApiError
   */
  public static updateEncounterService(
    encounterId: string,
    serviceId: string,
    requestBody: EncounterServiceDTO
  ): CancelablePromise<EncounterServiceDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{serviceId}',
      path: {
        encounterId: encounterId,
        serviceId: serviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete an EncounterService
   * @param encounterId
   * @param serviceId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEncounterService(
    encounterId: string,
    serviceId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{serviceId}',
      path: {
        encounterId: encounterId,
        serviceId: serviceId,
      },
    })
  }

  /**
   * Get paged list of EncounterServices associated with Encounter with optional search params
   * @param encounterId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterServiceDTO OK
   * @throws ApiError
   */
  public static encountersgetPagedList1(
    encounterId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services',
      path: {
        encounterId: encounterId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create an EncounterService
   * @param encounterId
   * @param requestBody
   * @returns EncounterServiceDTO OK
   * @throws ApiError
   */
  public static createEncounterService(
    encounterId: string,
    requestBody: EncounterServiceDTO
  ): CancelablePromise<EncounterServiceDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services',
      path: {
        encounterId: encounterId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
