/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientRelationshipDTO } from '../models/ClientRelationshipDTO'
import type { PageClientRelationshipDTO } from '../models/PageClientRelationshipDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientRelationshipControllerService {
  /**
   * Returns a single relationsip for client
   * Returns a single relationsip for client
   * @param clientId
   * @param relationshipId
   * @returns ClientRelationshipDTO OK
   * @throws ApiError
   */
  public static getClientRelationship(
    clientId: string,
    relationshipId: string
  ): CancelablePromise<ClientRelationshipDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/relationships/{relationshipId}',
      path: {
        clientId: clientId,
        relationshipId: relationshipId,
      },
    })
  }

  /**
   * Update client relationship
   * update client relationship
   * @param clientId
   * @param relationshipId
   * @param requestBody
   * @returns ClientRelationshipDTO OK
   * @throws ApiError
   */
  public static updateRelationship(
    clientId: string,
    relationshipId: string,
    requestBody: ClientRelationshipDTO
  ): CancelablePromise<ClientRelationshipDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/relationships/{relationshipId}',
      path: {
        clientId: clientId,
        relationshipId: relationshipId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete relationship by relationshipId
   * Soft deletes the relationship by setting deleted flag to true
   * @param clientId
   * @param relationshipId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteRelationship(
    clientId: string,
    relationshipId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/relationships/{relationshipId}',
      path: {
        clientId: clientId,
        relationshipId: relationshipId,
      },
    })
  }

  /**
   * Returns a paged list of relationsips for client
   * List is returned as Paged collection
   * @param clientId
   * @param relationshipTypeCodes
   * @param relationshipAttributeCodes
   * @param searchOperator
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageClientRelationshipDTO OK
   * @throws ApiError
   */
  public static getClientRelationships(
    clientId: string,
    relationshipTypeCodes?: Array<string>,
    relationshipAttributeCodes?: Array<string>,
    searchOperator?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageClientRelationshipDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/relationships',
      path: {
        clientId: clientId,
      },
      query: {
        relationshipTypeCodes: relationshipTypeCodes,
        relationshipAttributeCodes: relationshipAttributeCodes,
        searchOperator: searchOperator,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create client relationship
   * Create client relationship
   * @param clientId
   * @param requestBody
   * @returns ClientRelationshipDTO OK
   * @throws ApiError
   */
  public static createRelationship(
    clientId: string,
    requestBody: ClientRelationshipDTO
  ): CancelablePromise<ClientRelationshipDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/relationships',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
