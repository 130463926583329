import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { typeGroupAtom, typesAtom } from 'recoil/atoms'
import { TypeGroupAssociationControllerService, TypeGroupControllerService } from 'services/openapi'

export default function TypeGroups() {
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState({})

  const [associations, setAssociations] = useState(new Map())
  const [otherEntities, setOtherEntities] = useState([])
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [refetchTrigger, setRefetchTrigger] = useState(0)

  const types = useRecoilValue(typesAtom)

  const getEntitiesServiceCall = TypeGroupControllerService.listTypeGroups
  const createEntityServiceCall = TypeGroupControllerService.createTypeGroup
  const updateEntityServiceCall = TypeGroupControllerService.updateTypeGroup
  const updateAtomCall = useSetRecoilState(typeGroupAtom)

  const openLinkModalCallBack = () => {
    setOpenLinkModal(true)
  }

  const closeLinkModalCallBack = () => {
    setOpenLinkModal(false)
  }

  const saveTGAssociations = (associations) => {
    saveAssociations(
      associations,
      TypeGroupAssociationControllerService.updateTypeGroupAssociation,
      TypeGroupControllerService.addTypeToTypeGroup,
      setOpenLinkModal,
      setRefetchTriggerPlusOne,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const setRefetchTriggerPlusOne = () => {
    setRefetchTrigger(refetchTrigger + 1)
  }

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      TypeGroupControllerService.getTypeGroupAssociationsForTypeGroup(selectedRow.id).then(
        (data) => {
          setOtherEntities(types)
          buildAssociationMapUtil(
            data,
            types,
            'typeId',
            setAssociations,
            setOtherEntities,
            handleApiError,
            selectedRow
          )
        },
        (error) => {
          handleApiError(error)
        }
      )
    }
  }, [selectedRow, refetchTrigger, handleApiError, types])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('type-groups.title.type-groups')}</h1>
        {t('type-groups.title.type-groups-description')}

        <EntityTemplate
          createEntityServiceCall={createEntityServiceCall}
          entityName={t('type-groups.title.type-groups')}
          getEntitiesServiceCall={getEntitiesServiceCall}
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={updateEntityServiceCall}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box sx={{ p: 2 }}>
          <h3>
            {t('type-groups.title.association-header')} {selectedRow.name}
          </h3>
          <AssociationTable
            associations={associations}
            closeLinkModalCallBack={closeLinkModalCallBack}
            entity={selectedRow}
            headerName={t('types.title.types')}
            openLinkModal={openLinkModal}
            openLinkModalCallBack={openLinkModalCallBack}
            otherEntities={otherEntities}
            saveCallBack={saveTGAssociations}
          />
        </Box>
      )}
    </>
  )
}
