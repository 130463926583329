import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, Stack } from '@mui/material'
import MISSwitch from 'common/components/form/MISSwitch'
import MISTextField from 'common/components/form/MISTextField'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBTextFieldProps = {
  data?: TFormBuilderComponentData
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBTextField = ({ data, onChange, onDelete, onSave, preview }: FBTextFieldProps) => {
  const { t } = useTranslation('common')

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(data || {})
  const [configMode, setConfigMode] = useState(false)

  return (
    <>
      {preview ? (
        <MISTextField {...data} onChange={onChange} />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <MISTextField {...data} onChange={onChange} />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.text-field.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.name}
                label={t('charting.form-builder.name')}
                onChange={(e) =>
                  setConfigData(
                    configData ? { ...configData, name: e.target.value } : { name: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.helperText}
                label={t('charting.form-builder.helperText')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, helperText: e.target.value }
                      : { helperText: e.target.value }
                  )
                }
              />
              <FormControlLabel
                control={
                  <MISSwitch
                    defaultChecked={data?.multiline}
                    onChange={(_, checked) =>
                      setConfigData(
                        configData ? { ...configData, multiline: checked } : { multiline: checked }
                      )
                    }
                  />
                }
                label={t('charting.form-builder.multiline')}
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBTextField
