import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'

type Anchor = 'top' | 'left' | 'bottom' | 'right'
const anchor: Anchor = 'right'

const EncountersFilters = () => {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer =
    (anchor: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpen(open)
    }

  const list = (anchor: string) => (
    <Box
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      role="presentation"
      sx={{ m: 2, width: 250 }}
    >
      Filter fields
    </Box>
  )

  return (
    <div key={anchor}>
      <Button onClick={toggleDrawer(anchor, true)}>Filters</Button>
      <Drawer anchor={anchor} onClose={toggleDrawer(anchor, false)} open={open}>
        {list(anchor)}
      </Drawer>
    </div>
  )
}

export default EncountersFilters
