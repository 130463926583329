import { Alert, Snackbar } from '@mui/material'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'
import { snackbarState } from 'recoil/snackbar'

const MISSnackbar = () => {
  const snackbar = useRecoilValue(snackbarState)

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={snackbar.autoHideDuration}
      key={snackbar.message || ''}
      onClose={snackbar.onClose}
      open={snackbar.visible}
    >
      <Alert
        elevation={6}
        onClose={snackbar.onClose}
        severity={snackbar.severity}
        sx={{ width: '100%' }}
        variant="filled"
      >
        {parse(snackbar.message || '')}
      </Alert>
    </Snackbar>
  )
}

export default MISSnackbar
