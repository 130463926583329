/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataDomainAndEntityPermissionsDTO } from '../models/DataDomainAndEntityPermissionsDTO'
import type { UnPagedUserRoleDTO } from '../models/UnPagedUserRoleDTO'
import type { UserPermissionsProfile } from '../models/UserPermissionsProfile'
import type { UserRoleDTO } from '../models/UserRoleDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class UserRoleControllerService {
  /**
   * Get a Role by ID
   * @param userId
   * @param userRoleId
   * @returns UserRoleDTO OK
   * @throws ApiError
   */
  public static getById1(userId: string, userRoleId: string): CancelablePromise<UserRoleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/roles/{userRoleId}',
      path: {
        userId: userId,
        userRoleId: userRoleId,
      },
    })
  }

  /**
   * @param userId
   * @param userRoleId
   * @param requestBody
   * @returns UserRoleDTO OK
   * @throws ApiError
   */
  public static updateUserRole(
    userId: string,
    userRoleId: string,
    requestBody: UserRoleDTO
  ): CancelablePromise<UserRoleDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}/roles/{userRoleId}',
      path: {
        userId: userId,
        userRoleId: userRoleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param userId
   * @param userRoleId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUserRole(userId: string, userRoleId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-user-service/v1/users/{userId}/roles/{userRoleId}',
      path: {
        userId: userId,
        userRoleId: userRoleId,
      },
    })
  }

  /**
   * Get a unpaged list of user roles.
   * @param userId
   * @param includeInactive
   * @param activeOnDate
   * @returns UnPagedUserRoleDTO OK
   * @throws ApiError
   */
  public static listUserRoles(
    userId: string,
    includeInactive: boolean = false,
    activeOnDate?: string
  ): CancelablePromise<UnPagedUserRoleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/roles',
      path: {
        userId: userId,
      },
      query: {
        includeInactive: includeInactive,
        activeOnDate: activeOnDate,
      },
    })
  }

  /**
   * Create a role by adding a record to the database.
   * @param userId
   * @param requestBody
   * @returns UserRoleDTO OK
   * @throws ApiError
   */
  public static createUserRole(
    userId: string,
    requestBody: UserRoleDTO
  ): CancelablePromise<UserRoleDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/{userId}/roles',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get user permission profile using V2 Permissions with DataDomains
   * @param userId
   * @returns UserPermissionsProfile OK
   * @throws ApiError
   */
  public static getUserPermissionProfileV2(
    userId: string
  ): CancelablePromise<UserPermissionsProfile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/permission-profile-v2',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * remove user from user permission profile cache
   * @param userId
   * @returns any OK
   * @throws ApiError
   */
  public static invalidateUserIdCache(userId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/{userId}/permission-profile-v2',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * reset user permission profile cache
   * @returns any OK
   * @throws ApiError
   */
  public static invalidateFullUserCache(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/permission-profile-v2/reset-cache',
    })
  }

  /**
   * Get user permission profile.
   * @param userId
   * @returns UserPermissionsProfile OK
   * @throws ApiError
   */
  public static getUserPermissionProfile(
    userId: string
  ): CancelablePromise<UserPermissionsProfile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/permission-profile',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * Get preview of user permissions according to listed roles
   * @param roles
   * @returns DataDomainAndEntityPermissionsDTO OK
   * @throws ApiError
   */
  public static getDataDomainPermissionsPreview(
    roles: Array<string>
  ): CancelablePromise<Array<DataDomainAndEntityPermissionsDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/permission-preview',
      query: {
        roles: roles,
      },
    })
  }
}
