import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import global from 'common/styles/global.scss'

type MISTab = {
  content: React.ReactNode
  id: string
  title: React.ReactNode
}

export interface MISTabsRef {
  setById: (id: string) => void
}

type TabPanelProps = {
  children: React.ReactNode
  index: number
  value: number
  mountAllTabs?: boolean
}

type MISTabsProps = {
  tabs: MISTab[]
  mountAllTabs?: boolean
  onActiveTabChange?: (id: string) => void
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, mountAllTabs, value, ...other } = props

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {(value === index || mountAllTabs) && <>{children}</>}
    </div>
  )
}

const MISTabs = forwardRef(
  ({ mountAllTabs = false, onActiveTabChange, tabs }: MISTabsProps, ref) => {
    const [value, setValue] = useState(0)

    const a11yProps = useCallback((index: number) => {
      return {
        'aria-controls': `simple-tabpanel-${index}`,
        id: `simple-tab-${index}`,
      }
    }, [])

    const handleChange = useCallback(
      (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        if (onActiveTabChange) {
          onActiveTabChange(tabs[newValue].id)
        }
      },
      [onActiveTabChange, tabs]
    )

    const setById = useCallback(
      (id: string) => {
        const index = tabs.findIndex((tab) => tab.id === id)
        setValue(index)
      },
      [tabs]
    )

    useImperativeHandle(ref, () => ({
      setById,
    }))

    return (
      <Box sx={{ m: 2, width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            aria-label="basic tabs example"
            onChange={handleChange}
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
                display: 'block',
              },
              'button.MuiTab-root': {
                '&.Mui-selected': {
                  color: global.FIELD_LABEL_COLOR,
                },
                textTransform: 'capitalize',
              },
            }}
            value={value}
          >
            {tabs.map((tab, index: number) => {
              return <Tab key={tab.id} label={tab.title} {...a11yProps(index)} />
            })}
          </Tabs>
        </Box>
        {tabs.map((tab, index: number) => {
          return (
            <TabPanel index={index} key={tab.id} mountAllTabs={mountAllTabs} value={value}>
              {tab.content}
            </TabPanel>
          )
        })}
      </Box>
    )
  }
)
MISTabs.displayName = 'MISTabs'

export default MISTabs
