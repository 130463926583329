import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import FollowUpTemplateComponent from 'modules/charting/components/templates/follow-up-template/FollowUpTemplateComponent'
import ClientRecordHeaderDetails from 'modules/client/ClientDetails/ClientRecordHeaderDetails'
import { ClientControllerService, ClientDTO } from 'services/openapi'

type UserToDoEntryProps = {
  clientId?: string
  entry: TTemplateBlot
}

const UserToDoEntry = ({ clientId, entry }: UserToDoEntryProps) => {
  const { handleApiError } = useErrorHandler()

  const [client, setClient] = useState<ClientDTO | undefined>()
  const [templateData, setTemplateData] = useState(entry.templateData)

  useEffect(() => {
    if (clientId)
      ClientControllerService.getClient(clientId)
        .then((resp) => setClient(resp))
        .catch((error) => handleApiError(error))
  }, [clientId, handleApiError])

  useEffect(() => setTemplateData(entry.templateData), [entry])

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: '6px',
        p: 2,
        pt: 4,
        width: '100%',
      }}
    >
      {client && <ClientRecordHeaderDetails client={client} readOnly withNavigation />}
      <FollowUpTemplateComponent
        {...templateData}
        onChange={(key, value) => setTemplateData({ ...templateData, [key]: value })}
        readOnly
      />
    </Box>
  )
}

export default UserToDoEntry
