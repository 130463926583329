import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Grid, InputAdornment } from '@mui/material'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISListContainer from 'common/components/MISListContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import { multiCriteriaSort } from 'common/utils/utils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { getFormattedOptions, hasDuplicateObjects } from 'modules/shared/utils'
import {
  CodedRef,
  GovernanceAgencyControllerService,
  GovernanceAgencyWithDomainsDTO,
  PersonnelJobFunctionControllerService,
  PersonnelJobFunctionFteDTO,
} from 'services/openapi'

const ENTITY = 'FTE/Funder'

type FTEFunderProps = {
  personnelId: string
  jobFunctionId: string
  fullTimeEquivalencies: PersonnelJobFunctionFteDTO[] | undefined
  setFullTimeEquivalencies: (value: PersonnelJobFunctionFteDTO[]) => void
  jobFunctionIndex: number
  getError: (field: string) => string
}

type FundersDataRowType = { data: PersonnelJobFunctionFteDTO }
type FundersDataType = { rows: FundersDataRowType[] }

const FTEFunders = ({
  fullTimeEquivalencies,
  getError,
  jobFunctionId,
  jobFunctionIndex,
  personnelId,
  setFullTimeEquivalencies,
}: FTEFunderProps) => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [currData, setCurrData] = useState<FundersDataType>({ rows: [] })
  const [funderTypes, setFunderTypes] = useState<GovernanceAgencyWithDomainsDTO[]>([])

  useEffect(() => {
    const fetchFunderTypes = async () => {
      const response = await GovernanceAgencyControllerService.searchAgencyListForSpecifiedType(
        'Funder',
        '',
        '',
        true,
        undefined,
        10000 // to get the whole list
      )
      setFunderTypes(response.content as GovernanceAgencyWithDomainsDTO[])
    }
    fetchFunderTypes()
  }, [])

  const sortCriteria = useMemo(
    () => [
      {
        key: 'data.effective.startDate',
        order: 'desc' as const,
        type: 'date' as const,
      },
    ],
    []
  )
  const handleCreateRows = useCallback(() => {
    const parsedData: FundersDataType = {
      rows: fullTimeEquivalencies
        ? multiCriteriaSort(
            [...fullTimeEquivalencies].map((obj) => {
              return {
                data: {
                  effective: obj.effective,
                  fullTimeEquivalency: obj.fullTimeEquivalency,
                  funder: obj.funder,
                  hourlyRate: obj.hourlyRate,
                  id: obj.id,
                },
              }
            }),
            sortCriteria
          )
        : [],
    }

    setCurrData(parsedData)
  }, [fullTimeEquivalencies, setCurrData, sortCriteria])

  useEffect(() => {
    handleCreateRows()
  }, [handleCreateRows])

  const onAddRow = useCallback(() => {
    setCurrData({
      rows: [
        ...currData.rows,
        {
          data: {
            effective: undefined,
            fullTimeEquivalency: undefined,
            funder: '',
            hourlyRate: undefined,
          },
        },
      ],
    })
  }, [currData])

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, value?: any | string | CodedRef) => {
      const rows = currData.rows?.map((row: FundersDataRowType, index: number) => {
        if (index === rowIndex) {
          let data
          switch (field) {
            case 'fullTimeEquivalency':
              data = {
                ...row.data,
                fullTimeEquivalency: value,
              }
              break
            case 'hourlyRate':
              data = {
                ...row.data,
                hourlyRate: value,
              }
              break
            case 'funder':
              data = {
                ...row.data,
                funder: value?.id,
              }
              break
            case 'startDate':
              data = {
                ...row.data,
                effective: {
                  ...row.data.effective,
                  startDate: value,
                },
              }
              break
            case 'endDate':
              data = {
                ...row.data,
                effective: {
                  ...row.data.effective,
                  endDate: value,
                },
              }
              break
            default:
              return row
          }
          return { ...row, data }
        }
        return row
      })
      setCurrData({ rows })
      setFullTimeEquivalencies(
        rows.map((o: FundersDataRowType) => {
          return { ...o.data }
        })
      )
    },
    [currData.rows, setFullTimeEquivalencies]
  )

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      setDeleteIndex(-1)

      const deleteJobFunctionFte = async (
        personnelId: string,
        jobFunctionId: string,
        fteId: string
      ) => {
        await PersonnelJobFunctionControllerService.deletePersonnelJobFunctionFte(
          personnelId,
          jobFunctionId,
          fteId
        )
          .then(() => {
            showSnackSuccess(t('api.save-success'))
          })
          .catch((error) => {
            handleApiError(error)
          })
      }
      const fteId = currData.rows[rowIndex].data?.id
      currData.rows.splice(rowIndex, 1)
      if (fteId) deleteJobFunctionFte(personnelId, jobFunctionId, fteId)
      else setCurrData({ rows: currData.rows })
    },
    [currData, personnelId, jobFunctionId, showSnackSuccess, handleApiError, t]
  )

  const rowDataForm = useCallback(
    (
      row: FundersDataRowType,
      rowIndex: number,
      onChangeRow: (rowIndex: number, field: string, value?: any) => void
    ) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <MISTextField
              error={
                !!getError(
                  `fullTimeEquivalencies-${jobFunctionIndex}-fullTimeEquivalency-${rowIndex}`
                )
              }
              helperText={getError(
                `fullTimeEquivalencies-${jobFunctionIndex}-fullTimeEquivalency-${rowIndex}`
              )}
              id="full-time-equivalency"
              label="FTE"
              onChange={(event) => onChangeRow(rowIndex, 'fullTimeEquivalency', event.target.value)}
              required={!row.data.hourlyRate}
              value={row.data.fullTimeEquivalency}
            />
          </Grid>
          <Grid item xs={2}>
            <MISTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 1 }}>
                    $
                  </InputAdornment>
                ),
              }}
              error={!!getError(`fullTimeEquivalencies-${jobFunctionIndex}-hourlyRate-${rowIndex}`)}
              helperText={getError(
                `fullTimeEquivalencies-${jobFunctionIndex}-hourlyRate-${rowIndex}`
              )}
              id="hourly-rate"
              label="Hourly Rate"
              onChange={(event) => onChangeRow(rowIndex, 'hourlyRate', event.target.value)}
              required={!row.data.fullTimeEquivalency}
              value={row.data.hourlyRate}
            />
          </Grid>
          <Grid item xs={3}>
            <MISSelectDropdown
              error={!!getError(`fullTimeEquivalencies-${jobFunctionIndex}-funder-${rowIndex}`)}
              helperText={getError(`fullTimeEquivalencies-${jobFunctionIndex}-funder-${rowIndex}`)}
              label="Funder"
              onChange={(event) => onChangeRow(rowIndex, 'funder', event.target.value as string)}
              options={getFormattedOptions(funderTypes)}
              required={row.data.fullTimeEquivalency || row.data.hourlyRate ? true : false}
              value={funderTypes?.find((o: PersonnelJobFunctionFteDTO) => {
                return o.id === row.data.funder
              })}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              error={!!getError(`fullTimeEquivalencies-${jobFunctionIndex}-startDate-${rowIndex}`)}
              helperText={getError(
                `fullTimeEquivalencies-${jobFunctionIndex}-startDate-${rowIndex}`
              )}
              label="Start Date"
              onChange={(value) => onChangeRow(rowIndex, 'startDate', value)}
              required
              value={row?.data?.effective?.startDate}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              label="End Date"
              onChange={(value) => onChangeRow(rowIndex, 'endDate', value)}
              value={row?.data?.effective?.endDate}
            />
          </Grid>
        </Grid>
      )
    },
    [funderTypes, jobFunctionIndex, getError]
  )

  const checkDuplicate = useCallback((funders: FundersDataRowType[]) => {
    return funders.length > 0
      ? hasDuplicateObjects(
          funders.map((a: FundersDataRowType) => {
            return {
              endDate: a.data.effective?.endDate ? a.data.effective?.endDate.substr(0, 10) : '',
              fullTimeEquivalency: a.data.fullTimeEquivalency
                ? a.data.fullTimeEquivalency.toString()
                : '',
              funder: a.data.funder ? a.data.funder.toString() : '',
              hourlyRate: a.data.hourlyRate ? a.data.hourlyRate.toString() : '',
              startDate: a.data.effective?.startDate
                ? a.data.effective?.startDate.substr(0, 10)
                : '',
            }
          })
        )
      : false
  }, [])

  return (
    <Box sx={{ mt: 2 }}>
      <SectionHeader isSubsection title="FTE" />
      {checkDuplicate(currData.rows) && (
        <Alert className="info" severity="info">
          There seems to be duplicate funder/fte row, please verify
        </Alert>
      )}
      <MISListContainer
        addLabel="Add FTE"
        onAddRow={onAddRow}
        onChangeRow={onChangeRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowDataForm}
        rows={currData.rows}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </Box>
  )
}

export default FTEFunders
