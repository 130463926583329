import { useCallback, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import { useRecoilState } from 'recoil'
import { multiCriteriaSort } from 'common/utils/utils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { chartingEntriesState } from 'recoil/charting'
import {
  CancelablePromise,
  ChartingEntryControllerService,
  ChartingEntryDTO,
  CodedConceptDto,
  FormSchemaControllerService,
  FormSchemaDTO,
  PersonnelDTO,
} from 'services/openapi'
import useProviders from './useProviders'
import { FilterProps } from './useRunningNotes'

const useChartingEntries = (clientId: string, filters: FilterProps[], applyFilters = false) => {
  const { handleApiError } = useErrorHandler()
  const providers = useProviders()

  const [chartingEntries, setChartingEntriesInRecoil] = useRecoilState(chartingEntriesState)

  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<string[]>([])
  const [primaryProvider, setPrimaryProvider] = useState<string[]>([])
  const [durationFrom, setDurationFrom] = useState<number | undefined>(undefined)
  const [durationTo, setDurationTo] = useState<number | undefined>(undefined)
  const [serviceDateTimeFrom, setServiceDateTimeFrom] = useState('')
  const [serviceDateTimeTo, setServiceDateTimeTo] = useState('')
  const [templateNames, setTemplateNames] = useState<string[]>([])

  const getFilterValue = useCallback(
    (filterKey: string) => {
      const filterObj = filters.filter((o) => o.key === filterKey)?.[0]
      if (filterObj) {
        const { key, value } = filterObj
        switch (key) {
          case 'status': {
            const statusCodes: string[] = []
            value?.forEach((status: CodedConceptDto) => {
              if (status?.code) {
                statusCodes.push(status.code)
              }
            })
            return [...statusCodes]
          }
          case 'primaryProvider': {
            const personnelIds: string[] = []
            value?.forEach((provider: PersonnelDTO) => {
              if (provider?.id) {
                personnelIds.push(provider?.id)
              }
            })
            return [...personnelIds]
          }
          case 'templateName': {
            const templateNamesThings: string[] = []
            value?.forEach((template) => {
              if (template?.value?.name) {
                templateNamesThings.push(template.value.name)
              } else if (template?.name) {
                templateNamesThings.push(template.name)
              }
            })
            return [...templateNamesThings]
          }
          default:
            return value
        }
      }
    },
    [filters]
  )

  useEffect(() => {
    if (applyFilters) {
      setDurationFrom(undefined)
      setDurationTo(undefined)
      setServiceDateTimeFrom('')
      setServiceDateTimeTo('')
      setSearchText('')
      setTemplateNames([])
      filters.forEach(({ key }: FilterProps) => {
        switch (key) {
          case 'status':
            setStatus(getFilterValue(key))
            break
          case 'primaryProvider':
            setPrimaryProvider(getFilterValue(key))
            break

          case 'durationFrom':
            setDurationFrom(getFilterValue(key))
            break
          case 'durationTo':
            setDurationTo(getFilterValue(key))
            break
          case 'searchText':
            setSearchText(getFilterValue(key))
            break
          case 'serviceDateTimeFrom':
            setServiceDateTimeFrom(getFilterValue(key))
            break
          case 'serviceDateTimeTo':
            setServiceDateTimeTo(getFilterValue(key))
            break
          case 'templateName':
            setTemplateNames(getFilterValue(key))
            break
        }
      })
    }
  }, [applyFilters, filters, getFilterValue])

  const sortData = useCallback((arrData: ChartingEntryDTO[]) => {
    const sortedData = multiCriteriaSort(arrData, [
      {
        key: 'serviceDateTime',
        order: 'desc' as const,
        type: 'date' as const,
      },
    ])

    const map = new Map()
    for (let i = 0; i < sortedData.length; i++) {
      const data = sortedData[i] as ChartingEntryDTO
      if (map.has(data.serviceDateTime))
        map.set(data.serviceDateTime, map.get(data.serviceDateTime).concat(data))
      else map.set(data.serviceDateTime, [data])
    }

    let retVal = []
    for (const key of map.keys()) {
      const sorted = multiCriteriaSort(map.get(key), [
        {
          key: 'auditInfo.created.date',
          order: 'desc' as const,
          type: 'date' as const,
        },
      ])
      retVal = retVal.concat(...sorted)
    }
    return retVal
  }, [])

  const setChartingEntries = useCallback(
    async (entries: ChartingEntryDTO[] | undefined): Promise<void> => {
      if (entries) {
        const entriesToSet = cloneDeep(entries)
        // find all the form build template ids
        const templateIds = new Set<string>()
        entriesToSet.forEach((entry) => {
          if (entry.chartingEntryData?.delta?.ops) {
            entry.chartingEntryData.delta.ops.forEach((op) => {
              const templateBlot = op.insert?.['template-blot']
              if (templateBlot?.isFormBuilder && templateBlot?.templateId)
                templateIds.add(templateBlot.templateId)
            })
          }
        })

        // find all schema by id
        const templateSchemaByName: { [key: string]: string } = {}
        const promisesForSchema: CancelablePromise<FormSchemaDTO>[] = []
        templateIds.forEach((id) =>
          promisesForSchema.push(FormSchemaControllerService.getFormSchema(id))
        )
        await Promise.all(promisesForSchema).then((resp) => {
          resp.forEach((each) => {
            if (each.name && each.schema) templateSchemaByName[each.name] = each.schema
          })
        })

        // merge schema with entry data
        entriesToSet.forEach((entry) => {
          entry.chartingEntryData?.delta?.ops?.forEach((op) => {
            const templateBlot = op.insert?.['template-blot']
            if (
              templateBlot?.formBuilderId &&
              templateBlot?.isFormBuilder &&
              templateBlot?.templateName
            ) {
              const templateSchema = JSON.parse(templateSchemaByName[templateBlot.templateName])
              const templateDataForId = templateSchema.ops.find(
                (op) =>
                  op.insert?.['template-blot']?.formBuilderId &&
                  op.insert['template-blot'].formBuilderId === templateBlot.formBuilderId
              ).insert['template-blot'].templateData
              templateBlot.templateData = { ...templateDataForId, ...templateBlot.templateData }
              templateBlot.templateData.components = templateBlot.templateData.components.concat(
                templateDataForId.components.slice(templateBlot.templateData.components.length)
              )
            }
          })
        })
        setChartingEntriesInRecoil(entriesToSet)
      } else setChartingEntriesInRecoil(entries)
    },
    [setChartingEntriesInRecoil]
  )

  useEffect(() => {
    const getChartingEntries = async (clientId: string) => {
      try {
        const response = await ChartingEntryControllerService.searchChartingEntries(
          clientId,
          getFilterValue('status') || undefined,
          getFilterValue('templateName') || undefined,
          getFilterValue('primaryProvider') || undefined,
          getFilterValue('durationFrom') || undefined,
          getFilterValue('durationTo') || undefined,
          getFilterValue('serviceDateTimeFrom') || undefined,
          getFilterValue('serviceDateTimeTo') || undefined,
          getFilterValue('searchText') || undefined,
          undefined,
          9999
        )
        if (response.content) setChartingEntries(sortData(response.content))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (clientId) {
      setChartingEntries(undefined)
      getChartingEntries(clientId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientId,
    status,
    primaryProvider,
    durationFrom,
    durationTo,
    serviceDateTimeFrom,
    serviceDateTimeTo,
    templateNames,
    searchText,
  ])
  return { chartingEntries, providers, setChartingEntries, sortData }
}
export default useChartingEntries
