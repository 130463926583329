/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceReferencesDTO } from '../models/ResourceReferencesDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class UserBusinessLineControllerService {
  /**
   * Get a unpaged list of user businessLine.
   * @param userId
   * @returns ResourceReferencesDTO OK
   * @throws ApiError
   */
  public static listUserBusinessLines(userId: string): CancelablePromise<ResourceReferencesDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/business-lines',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * @param userId
   * @param requestBody
   * @returns ResourceReferencesDTO OK
   * @throws ApiError
   */
  public static upsertUserBusinessLines(
    userId: string,
    requestBody: ResourceReferencesDTO
  ): CancelablePromise<ResourceReferencesDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}/business-lines',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
