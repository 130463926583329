import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Chip, styled, Typography } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import GLOBAL from 'common/styles/global.scss'

type StateChipProps = {
  label: string
  reason?: string
  comment?: string
  defaultCursor?: boolean
}

const StateChip = ({ comment, defaultCursor, label, reason }: StateChipProps) => {
  const { t } = useTranslation('common')

  const [show, setShow] = useState(false)

  const StyledTooltip = useMemo(
    () =>
      styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
          [`& .${tooltipClasses.arrow}`]: {
            color: '#6f6f6f',
            left: '125px !important',
          },
          backgroundColor: '#6f6f6f',
          marginLeft: -250,
          minWidth: 300,
          paddingBottom: GLOBAL.PADDING_SM,
          paddingLeft: GLOBAL.PADDING_SM,
          paddingRight: GLOBAL.PADDING_SM,
          paddingTop: GLOBAL.PADDING_MD,
        },
      })),
    []
  )

  const getTooltipContent = useCallback(
    (header: string, body: string) => (
      <>
        <Typography sx={{ color: GLOBAL.WHITE, fontSize: 12, fontWeight: 650 }}>
          {header}
        </Typography>
        <Typography sx={{ color: GLOBAL.WHITE, fontSize: 14, paddingBottom: GLOBAL.PADDING_SM }}>
          {body}
        </Typography>
      </>
    ),
    []
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setShow(!show)
    },
    [show]
  )

  if (reason || comment)
    return (
      <StyledTooltip
        arrow
        onBlur={() => setShow(false)}
        onClick={handleClick}
        open={show}
        title={
          <Box onClick={(event) => event.stopPropagation()}>
            {reason && getTooltipContent(t('common.state-selector.reason-code'), reason)}
            {comment && getTooltipContent(t('common.state-selector.comment'), comment)}
          </Box>
        }
      >
        <Chip label={label} sx={{ cursor: 'pointer' }} />
      </StyledTooltip>
    )

  return <Chip label={label} sx={{ cursor: defaultCursor ? 'default' : 'pointer' }} />
}

export default StateChip
