import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

// selector for permission related stuff
export const selectBusinessLines = createSelector(
  [(state: RootState) => state.permission],
  (permission) => permission.businessLines
)

export const selectDataDomains = createSelector(
  [(state: RootState) => state.permission],
  (permission) => permission.dataDomains
)
