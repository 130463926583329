import {
  createRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Divider } from '@mui/material'
import { useRecoilValue } from 'recoil'
import FormViewer from 'common/components/form/MISFormViewer'
import Loader from 'common/components/Loader'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { encounterServicesFormsAssociationsState } from 'recoil/encounters'
import {
  CancelablePromise,
  EncounterServiceFormControllerService,
  FormDataDTO,
  FormSchemaControllerService,
  FormSchemaDTO,
  PageFormSchemaLightDTO,
  TemplateFormAssociationEntity,
} from 'services/openapi'

type EncounterServiceFormsProps = {
  encounterId: string
  serviceId: string
}

const EncounterServiceForms = forwardRef(
  ({ encounterId, serviceId }: EncounterServiceFormsProps, ref) => {
    const { handleApiError } = useErrorHandler()
    const formsAssociation = useRecoilValue(encounterServicesFormsAssociationsState)

    const [formData, setFormData] = useState<FormDataDTO[] | null>(null)
    const [formSchemas, setFormSchemas] = useState<FormSchemaDTO[] | null>(null)
    const [formViewerRefs, setFormViewerRef] = useState<RefObject<{ getData: () => string }>[]>([])

    const getExistingData = useCallback(
      (formSchema: FormSchemaDTO) =>
        formData?.find(
          (data) =>
            data.name === formSchema.name &&
            data.tags === formSchema.tags &&
            data.version === formSchema.version
        ),
      [formData]
    )

    const handleSaveForms = useCallback(async () => {
      if (!formData || !formSchemas) return
      const newFormData: string[] = []
      if (formViewerRefs.length > 0)
        formViewerRefs.forEach((ref) => newFormData.push(ref.current?.getData() || ''))

      for (let i = 0; i < newFormData.length; i += 1) {
        try {
          if (newFormData[i]) {
            const existingFormData = getExistingData(formSchemas[i])
            if (existingFormData && existingFormData.id) {
              if (newFormData[i] !== existingFormData.data)
                await EncounterServiceFormControllerService.updateFormData(
                  encounterId,
                  serviceId,
                  existingFormData.id,
                  {
                    data: newFormData[i],
                    entityRecordId: serviceId,
                    entityType: 'ENCOUNTER_SERVICE',
                    name: formSchemas[i].name,
                    tags: formSchemas[i].tags,
                    version: formSchemas[i].version,
                  }
                )
            } else
              await EncounterServiceFormControllerService.createFormData(encounterId, serviceId, {
                data: newFormData[i],
                entityRecordId: serviceId,
                entityType: 'ENCOUNTER_SERVICE',
                name: formSchemas[i].name,
                tags: formSchemas[i].tags,
                version: formSchemas[i].version,
              })
          }
        } catch (err) {
          handleApiError(err)
        }
      }
    }, [
      encounterId,
      formData,
      formSchemas,
      formViewerRefs,
      getExistingData,
      handleApiError,
      serviceId,
    ])

    useEffect(() => {
      const fetchFormSchemas = async (schemas: TemplateFormAssociationEntity[]) => {
        const retVal: FormSchemaDTO[] = []
        const promises: CancelablePromise<PageFormSchemaLightDTO>[] = []
        schemas.forEach((schema) => {
          if (schema.id && schema.formName)
            promises.push(
              FormSchemaControllerService.searchFormSchema(
                schema.formName,
                schema.formSemanticVersion || undefined
              )
            )
        })
        try {
          const responses = await Promise.all(promises)
          responses.forEach((resp) => {
            if (resp.content && resp.content.length > 0) retVal.push(resp.content[0])
          })
          setFormSchemas(retVal)
          const { content } = await EncounterServiceFormControllerService.searchFormData(
            encounterId,
            serviceId,
            [serviceId]
          )
          setFormData(content || [])
        } catch (error) {
          handleApiError(error)
        }
      }

      if (formsAssociation?.[serviceId] && formsAssociation?.[serviceId].length > 0)
        fetchFormSchemas(formsAssociation[serviceId])
      else {
        setFormData([])
        setFormSchemas([])
      }
    }, [encounterId, formsAssociation, handleApiError, serviceId])

    useImperativeHandle(
      ref,
      () => ({
        save() {
          handleSaveForms()
        },
      }),
      [handleSaveForms]
    )

    useEffect(() => {
      if (!formSchemas) return
      setFormViewerRef((formViewerRef) =>
        Array(formSchemas.length)
          .fill(false)
          .map((_, i) => formViewerRef[i] || createRef())
      )
    }, [formSchemas])

    return (
      <>
        {formData && formSchemas ? (
          <>
            {formSchemas.map((formSchema, i) => (
              <div key={formSchema.id}>
                <Divider sx={{ ml: 0, mr: 0, mt: GLOBAL.MARGIN_XL }} />
                <FormViewer
                  formData={getExistingData(formSchema)?.data}
                  formJson={formSchema.schema as string}
                  readOnly={false}
                  ref={formViewerRefs[i]}
                />
              </div>
            ))}
          </>
        ) : (
          <Loader height={100} />
        )}
      </>
    )
  }
)
EncounterServiceForms.displayName = 'EncounterServiceForms'

export default EncounterServiceForms
