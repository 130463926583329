import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import MISButton from 'common/components/MISButton'
import MISTabs, { MISTabsRef } from 'common/components/tabs/MISTabs'
import MISTabTitle from 'common/components/tabs/MISTabTitle'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  CancelablePromise,
  ClientControllerService,
  ClientDTO,
  ClientRelationshipControllerService,
} from 'services/openapi'
import { setClientRelationships } from 'store/reducers/client'
import ImmunizationConsentRecorded from './ImmunizationConsentRecorded'
import ImmunizationRecordConsent from './ImmunizationRecordConsent'

type ImmunizationRecordedConsentsRef = {
  updateList: () => void
}

type ImmunizationConsentRecordRef = {
  save: () => void
  cancel: () => void
}

const ImmunizationsConsentContentPanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const { handleApiError } = useErrorHandler()
  const tabsRef = useRef<MISTabsRef>()
  const [activeTab, setActiveTab] = useState('RECORD')

  const [showSaveButton, setShowSaveButton] = useState(false)
  const immunizationRecordedConsentRef = useRef<ImmunizationRecordedConsentsRef>()
  const immunizationConsentRecordRef = useRef<ImmunizationConsentRecordRef>()

  const toggleSaveButton = useCallback((flag: boolean) => {
    setShowSaveButton(flag)
  }, [])

  const setTabById = useCallback((id: string) => {
    tabsRef.current?.setById(id)
  }, [])

  const tabs = useMemo(
    () => [
      {
        content: (
          <ImmunizationRecordConsent
            ref={immunizationConsentRecordRef}
            setTabById={setTabById}
            toggleSaveButton={toggleSaveButton}
            toggleUpdateRecords={() => immunizationRecordedConsentRef.current?.updateList()}
          />
        ),
        id: 'RECORD',
        title: <MISTabTitle title={t('immunizations.record-tab-title')} />,
      },
      {
        content: <ImmunizationConsentRecorded ref={immunizationRecordedConsentRef} />,
        id: 'CONSENTS',
        title: <MISTabTitle title={t('immunizations.consents-recorded-tab-title')} />,
      },
    ],
    [setTabById, toggleSaveButton, t]
  )

  const updateActiveTab = useCallback((id: string) => {
    setActiveTab(id)
  }, [])

  useEffect(() => {
    const getClientRelationships = async (id: string) => {
      try {
        const clientRelationships =
          await ClientRelationshipControllerService.getClientRelationships(
            id,
            undefined,
            undefined,
            undefined,
            0,
            100
          )
        const promises: CancelablePromise<ClientDTO>[] = []
        clientRelationships.content?.forEach((relationship) => {
          if (relationship.relatedClientId)
            promises.push(
              ClientControllerService.getClient(relationship.relatedClientId, undefined, true)
            )
        })
        const clientDetails = await Promise.all(promises)
        const relationships = clientRelationships.content
          ? clientRelationships.content.map((clientRelationship, index) => {
              return { client: clientDetails[index], clientRelationship }
            })
          : []

        dispatch(setClientRelationships({ clientId: id, relationships }))
      } catch (err) {
        handleApiError(err)
      }
    }
    if (clientId) getClientRelationships(clientId)
  }, [clientId, dispatch, handleApiError])

  return (
    <Box sx={{ ml: 1, mr: 5 }}>
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={6}>
          <Typography component="span" sx={{ marginLeft: GLOBAL.MARGIN_SM }} variant="h1">
            {t('immunizations.consent-header')}
          </Typography>
        </Grid>
        {showSaveButton && (
          <Grid item textAlign="right" xs={6}>
            <Stack direction="row" spacing={2} sx={{ display: 'block' }}>
              {activeTab === 'RECORD' && (
                <MISButton
                  color="secondary"
                  onClick={() => immunizationConsentRecordRef.current?.cancel()}
                >
                  {t('common.button.cancel')}
                </MISButton>
              )}
              <MISButton onClick={() => immunizationConsentRecordRef.current?.save()}>
                {t('common.button.save')}
              </MISButton>
            </Stack>
          </Grid>
        )}
      </Grid>
      <MISTabs mountAllTabs onActiveTabChange={updateActiveTab} ref={tabsRef} tabs={tabs} />
    </Box>
  )
}

export default ImmunizationsConsentContentPanel
