import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import logo from 'assets/images/mis-logo.png'

const ConfidentialityModal = () => {
  const { t } = useTranslation('common')
  const [open, setOpen] = useState(true)

  const handleClose = useCallback(() => {
    localStorage.setItem('agreementClosed', 'true')
    setOpen(false)
  }, [])

  useEffect(() => {
    if (localStorage.getItem('agreementClosed')) {
      setOpen(false)
    }
  }, [])

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box sx={{ margin: '8px' }}>
        <Box
          component="img"
          src={logo}
          sx={{
            height: '100px',
            marginLeft: '18px',
            width: '100px',
          }}
        />
        <DialogTitle sx={{ paddingBottom: '4px' }}>
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {' '}
            {t('confidentiality-popup.title')}
          </Typography>
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            color: 'black',
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            gutterBottom
            sx={{ color: '#00000099', fontWeight: 'bold', paddingBottom: '10px' }}
            variant="body2"
          >
            {' '}
            {t('confidentiality-popup.grey-text-message')}
          </Typography>
          <Typography gutterBottom sx={{ paddingBottom: '10px' }} variant="body2">
            {' '}
            {t('confidentiality-popup.paragraph-text1')}
          </Typography>
          <Typography gutterBottom sx={{ paddingBottom: '10px' }} variant="body2">
            {' '}
            {t('confidentiality-popup.paragraph-text2')}
          </Typography>
          <Typography variant="body2"> {t('confidentiality-popup.paragraph-text3')}</Typography>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default ConfidentialityModal
