import Breadcrumbs from '@mui/material/Breadcrumbs'
import MISLink from 'common/components/MISLink'
import './Breadcrumb.scss'

const handleClick = (event) => {
  event.preventDefault()
}

export const Breadcrumb = (breadcrumb) => {
  return (
    <div className="breadcrumb" onClick={handleClick} role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumb.breadcrumb.map((item, index) => {
          return (
            <MISLink
              aria-current={index < breadcrumb.breadcrumb.length - 1 ? '' : 'page'}
              color={index < breadcrumb.breadcrumb.length - 1 ? 'inherit' : 'primary'}
              href={item.link}
              key={'link-' + index}
              underline="hover"
            >
              {item.value}
            </MISLink>
          )
        })}
      </Breadcrumbs>
    </div>
  )
}
