import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AddCircleOutline } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateValueType, StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { ErrorType, getFormattedOptions } from 'modules/shared/utils'
import { immunizationToAvoidState } from 'recoil/immunzations'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  CancelablePromise,
  CodedConceptDto,
  CodedRef,
  ImmunizationShotControllerService,
  ImmunizationShotDTO,
  ImmunizationToAvoidControllerService,
  ImmunizationToAvoidDTO,
} from 'services/openapi'
import { MIS_BC_IMMUNIZATION_AGENT, MIS_VOID_REASON_TYPE } from 'services/terminologyConstants'

const ImmunizationToAvoidStates = [
  {
    code: 'ACTIVE',
    codeSystemOid: '1.1.123.567.1',
    name: 'Active',
  },
  {
    code: 'INACTIVE',
    codeSystemOid: '1.1.123.567.2',
    name: 'No longer a problem/ Resolved',
  },
  {
    code: 'VOID',
    codeSystemOid: '1.1.123.567.3',
    name: 'Voided',
  },
]

const ImmunizationsToAvoid = () => {
  const agentOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_BC_IMMUNIZATION_AGENT)
  )
  const { clientId } = useParams()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [immunzationsToAvoid, setImmunizationsToAvoid] = useRecoilState(immunizationToAvoidState)
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  const [currentAvoidList, setCurrentAvoidList] = useState<ImmunizationToAvoidDTO[]>([])

  const [shotMap, setShotMap] = useState<Map<string, ImmunizationShotDTO[]>>(
    new Map<string, ImmunizationShotDTO[]>()
  )
  const [errors, setErrors] = useState<ErrorType[]>([])

  const avoidStateValues: StateValueType[] = useMemo(
    () =>
      ImmunizationToAvoidStates.map((opt) => {
        switch (opt.code) {
          case 'VOID':
            return {
              reasonCodeRequired: true,
              reasonCodeTerminology: voidReasonOptions,
              stateValue: opt,
            }
          default:
            return { stateValue: opt }
        }
      }),
    [voidReasonOptions]
  )

  const handleChangeConsentState = useCallback(
    async (immunzationId: string, state: CodedRef, reason?: CodedRef, comment?: string) => {
      try {
        const item = currentAvoidList.find((x) => x.id === immunzationId)
        if (clientId && item) {
          const serviceUpdate =
            await ImmunizationToAvoidControllerService.createImmunizationToAvoidState(
              clientId,
              item.id as string,
              { comment, reason, state }
            )

          const newItem = { ...item, currentState: serviceUpdate }

          const indexToUpdate = currentAvoidList.findIndex((x) => x.id === immunzationId)
          const updatedRows = [...currentAvoidList]
          updatedRows[indexToUpdate] = newItem
          setCurrentAvoidList(updatedRows)
          setImmunizationsToAvoid(updatedRows)
        }
      } catch (error) {
        handleApiError(error)
      }
    },
    [clientId, currentAvoidList, handleApiError, setImmunizationsToAvoid]
  )

  const handleGetNextServiceStateOptions = useCallback(
    async (avoidId: string) => {
      const nextStateOptions: StateWithInstructionsType[] = []
      if (avoidId && clientId) {
        const response =
          await ImmunizationToAvoidControllerService.getNextStatesByImmunizationToAvoidId(
            clientId,
            avoidId
          )
        response.content?.forEach((item) => {
          if (item.state) nextStateOptions.push(item)
        })
        return nextStateOptions
      }
      return []
    },
    [clientId]
  )

  const updateShopMap = useCallback(
    (agentCode?: string) => {
      const populateShotsForAgent = async (agentCode: string) => {
        const shots = await ImmunizationShotControllerService.searchAllImmunizationShots(
          undefined,
          agentCode
        )
        setShotMap((prev) => {
          const newMap = new Map(prev)
          newMap.set(agentCode, shots.content || [])
          return newMap
        })
      }

      if (agentCode) {
        if (!shotMap.has(agentCode)) {
          populateShotsForAgent(agentCode)
        }
      }
    },
    [shotMap]
  )

  const updateCurrentAvoidList = useCallback((prev, index, update) => {
    const newList = [...prev]
    newList[index] = {
      ...newList[index],
      ...update,
    }
    return newList
  }, [])

  const handlePropertyChange = useCallback(
    (property: string, value: string | CodedRef | CodedConceptDto, index: number) => {
      if (!isDirty) setIsDirty(true)
      switch (property) {
        case 'agent':
          {
            const newValue = value as CodedRef
            if (newValue?.code) updateShopMap(newValue.code)
            setCurrentAvoidList((prev) => {
              return updateCurrentAvoidList(prev, index, { agent: newValue || undefined })
            })
          }
          break
        case 'shot':
          {
            const newValue = value as CodedConceptDto
            setCurrentAvoidList((prev) => {
              return updateCurrentAvoidList(prev, index, {
                immunizationShotId: newValue?.id || undefined,
              })
            })
          }
          break
        case 'reason':
          {
            const newValue = value as string
            setCurrentAvoidList((prev) => {
              return updateCurrentAvoidList(prev, index, { reason: newValue })
            })
          }
          break
      }
    },
    [isDirty, setIsDirty, updateCurrentAvoidList, updateShopMap]
  )

  const onAddRow = useCallback(() => {
    if (!isDirty) setIsDirty(true)

    setCurrentAvoidList((prev) => [
      ...prev,
      {
        agent: { code: undefined, codeSystemOid: undefined },
        id: '',
        immunizationShotId: '',
      },
    ])
  }, [isDirty, setIsDirty])

  const deleteRow = useCallback((index: number) => {
    setCurrentAvoidList((prev) => {
      const newList = [...prev]
      newList.splice(index, 1)
      return newList
    })
    setErrors([])
  }, [])

  const handleSave = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      currentAvoidList?.forEach((row: ImmunizationToAvoidDTO, i: number) => {
        if (!row.agent || !row.agent.code) {
          isValid = false
          errors.push({
            field: `agent-${i}`,
            message: t('immunizations.agent-required-text'),
          })
        }
        if (!row.reason?.trim()) {
          isValid = false
          errors.push({
            field: `reason-${i}`,
            message: t('immunizations.reason-required-text'),
          })
        }
      })
      return { errors, isValid }
    }

    const saveImmunzationsToAvoid = async (updatedToAvoids: ImmunizationToAvoidDTO[]) => {
      const promises: CancelablePromise<ImmunizationToAvoidDTO>[] = []
      if (clientId) {
        updatedToAvoids.forEach((avoid) => {
          if (avoid?.id) {
            promises.push(
              ImmunizationToAvoidControllerService.putImmunizationToAvoid(
                clientId,
                avoid?.id,
                avoid
              )
            )
          } else {
            promises.push(
              ImmunizationToAvoidControllerService.postImmunizationToAvoid(clientId, avoid)
            )
          }
        })
      }

      try {
        const responses = await Promise.all(promises)

        setCurrentAvoidList(responses)
        setImmunizationsToAvoid(responses)
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
    }
    const { errors, isValid } = validate()
    if (isValid) {
      const updatedAlerts = currentAvoidList
      saveImmunzationsToAvoid(updatedAlerts)
      setErrors([])
    } else {
      setErrors(errors)
    }
  }, [
    clientId,
    currentAvoidList,
    handleApiError,
    setImmunizationsToAvoid,
    setIsDirty,
    showSnackSuccess,
    t,
  ])

  const getError = useCallback(
    (field: string) => {
      const errorObj = errors.find((error) => error.field === field)
      return errorObj ? errorObj.message : ''
    },
    [errors]
  )

  useEffect(() => {
    const getAvoids = async () => {
      setCurrentAvoidList(immunzationsToAvoid || [])
      immunzationsToAvoid?.forEach((avoid) => {
        updateShopMap(avoid.agent?.code)
      })
    }
    if (immunzationsToAvoid && shotMap.size === 0) {
      getAvoids()
    }
  }, [clientId, handleApiError, immunzationsToAvoid, shotMap.size, updateShopMap])

  return (
    <Box sx={{ ml: 1, mr: 5 }}>
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={6}>
          <Typography component="span" sx={{ marginLeft: GLOBAL.MARGIN_SM }} variant="h1">
            {t('immunizations.immunization-avoid-header')}
          </Typography>
        </Grid>

        <Grid item textAlign="right" xs={6}>
          <Stack direction="row" sx={{ display: 'block' }}>
            <MISButton
              color="primary"
              onClick={onAddRow}
              size="large"
              startIcon={<AddCircleOutline />}
              variant="text"
            >
              {t('immunizations.add-immu-avoid')}
            </MISButton>
            <MISButton onClick={handleSave}>{t('common.button.save')}</MISButton>
          </Stack>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sx={{ marginTop: GLOBAL.MARGIN_XL }} xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ height: GLOBAL.MARGIN_XXL }}>
                  <TableCell width="30%">
                    {t('immunizations.avoid-agent-text')}
                    <span> * </span>
                  </TableCell>
                  <TableCell width="30%">{t('immunizations.avoid-shot-text')}</TableCell>
                  <TableCell width="40%">
                    {t('immunizations.avoid-reason-text')}
                    <span> * </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentAvoidList?.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      '& > th, & > td': {
                        borderBottom: 'unset',
                      },
                      '&:last-child td, &:last-child th': { border: 0 },
                      alignContent: 'start',
                      height: GLOBAL.MARGIN_XXL,
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <MISAutocomplete
                        disabled={item.id !== '' && item.currentState?.state?.code !== 'ACTIVE'}
                        error={!!getError(`agent-${index}`)}
                        helperText={getError(`agent-${index}`)}
                        label=""
                        onChange={(value) =>
                          handlePropertyChange('agent', value?.value as CodedRef, index)
                        }
                        options={getFormattedOptions(agentOptions)}
                        placeholder="Select..."
                        value={agentOptions?.find((x) => x.code === item?.agent?.code)?.name || ''}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ alignContent: 'start' }}>
                      <MISAutocomplete
                        disabled={item.id !== '' && item.currentState?.state?.code !== 'ACTIVE'}
                        label=""
                        onChange={(value) =>
                          handlePropertyChange('shot', value?.value as CodedConceptDto, index)
                        }
                        options={
                          item?.agent?.code
                            ? getFormattedOptions(shotMap.get(item?.agent?.code) || [])
                            : []
                        }
                        placeholder="Select..."
                        value={
                          item?.agent?.code
                            ? shotMap
                                .get(item?.agent?.code)
                                ?.find((x) => x.id === item.immunizationShotId)?.name || ''
                            : ''
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: 'center', width: '100%' }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <MISTextField
                            disabled={item.id !== '' && item.currentState?.state?.code !== 'ACTIVE'}
                            error={!!getError(`reason-${index}`)}
                            helperText={getError(`reason-${index}`)}
                            id="reason"
                            inputProps={{ maxLength: 200 }}
                            label=""
                            onChange={(event) =>
                              handlePropertyChange('reason', event.target.value, index)
                            }
                            value={item?.reason || ''}
                          />
                        </Box>
                        {item.currentState &&
                          ImmunizationToAvoidStates?.find(
                            (o) => o.code === item.currentState?.state?.code
                          ) && (
                            <Box sx={{ ml: `${GLOBAL.MARGIN_SM}` }}>
                              <StateChip
                                comment={item.currentState?.comment || ''}
                                label={
                                  ImmunizationToAvoidStates.find(
                                    (o) => o.code === item.currentState?.state?.code
                                  )?.name || ''
                                }
                                reason={
                                  item.currentState?.reason
                                    ? voidReasonOptions.find(
                                        (x) => x.code === item.currentState?.reason?.code
                                      )?.name
                                    : undefined
                                }
                              />
                            </Box>
                          )}
                        {item.id !== '' && (
                          <StateSelector
                            entityId={item.id as string}
                            onGetNextStates={handleGetNextServiceStateOptions}
                            onSelect={handleChangeConsentState}
                            popToLeft
                            stateValues={avoidStateValues}
                            warningText={t('immunizations.avoid-void-warning-text')}
                          />
                        )}
                        {!item.id && (
                          <IconButton onClick={() => deleteRow(index)} sx={{ mt: 2 }}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ImmunizationsToAvoid
