import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { CancelablePromise, RoleControllerService, RoleView } from 'services/openapi'

const ENTITY = 'Role'

type RolesProps = {
  templateRoles: string[]
  onChange: (templateRoles: string[]) => void
}

const Roles = ({ onChange, templateRoles }: RolesProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()

  const [currRoles, setCurrRoles] = useState<RoleView[]>([])
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [searchText, setSearchText] = useState('')
  const [searchResults, setSearchResults] = useState<RoleView[]>([])

  const handleSearch = useCallback(
    async (searchText: string) => {
      if (searchText && searchText.length >= 3) {
        try {
          const response = await RoleControllerService.searchRolesPaged(searchText)
          setSearchResults(response?.content || [])
        } catch (err) {
          handleApiError(err)
          setSearchResults([])
        }
      } else setSearchResults([])
    },
    [handleApiError]
  )

  const handleDelete = useCallback(
    (index: number) => {
      setDeleteIndex(-1)
      const nextRoles = [...currRoles]
      nextRoles.splice(index, 1)
      setCurrRoles(nextRoles)
      onChange(nextRoles.map((role) => role.id as string))
    },
    [currRoles, onChange]
  )

  useEffect(() => {
    const getRoles = async (templateRoles: string[]) => {
      try {
        const promises: CancelablePromise<RoleView>[] = []
        templateRoles.forEach((role) => {
          promises.push(RoleControllerService.getById3(role))
        })
        const responses = await Promise.all(promises)
        setCurrRoles(responses)
      } catch (err) {
        handleApiError(err)
        setCurrRoles([])
      }
    }
    if (templateRoles.length > 0) getRoles(templateRoles)
    else setCurrRoles([])
  }, [templateRoles, handleApiError])

  return (
    <Box
      sx={{
        borderRadius: 1.5,
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.09)',
        boxSizing: 'border-box',
        mt: 2,
        p: 4,
      }}
    >
      <SectionHeader isSubsection title={t('encounter-template-page.roles.title')} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MISAutocomplete
              inputValue={searchText}
              label={t('encounter-template-page.roles.search-roles')}
              onChange={(role) => {
                const selectedRole = searchResults.find((each) => each.id === role.id)
                if (selectedRole) {
                  setCurrRoles([...currRoles, selectedRole])
                  onChange([...currRoles, selectedRole].map((role) => role.id as string))
                }
                setSearchText('')
              }}
              onInputChange={(value) => {
                setSearchText(value)
                handleSearch(value)
              }}
              options={searchResults
                .filter((role) => !currRoles.find((currRole) => currRole.id === role.id))
                .map((role) => {
                  return { id: role.id, label: role.name }
                })}
            />
          </Grid>
          <Divider sx={{ mb: GLOBAL.MARGIN_S, ml: 0, mr: 0 }} />
          {currRoles.length > 0 && (
            <Grid item xs={12}>
              <List sx={{ '& hr': { mx: 0.5, my: 2 }, boxShadow: 'none', p: 0 }}>
                {currRoles.map((role, index) => (
                  <ListItem
                    key={role.id}
                    secondaryAction={
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation()
                            setDeleteIndex(index)
                          }}
                          size="small"
                          sx={{ pointerEvents: 'auto' }}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    }
                    sx={{
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                      flexDirection: 'row-reverse',
                      p: 0,
                    }}
                  >
                    <Typography sx={{ alignSelf: 'center', m: 1, width: '100%' }} variant="body2">
                      <span>{role.name}</span>
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
        <WarningDialog
          entity={ENTITY}
          onCancel={() => setDeleteIndex(-1)}
          onSave={() => handleDelete(deleteIndex)}
          open={deleteIndex > -1}
          type={MODALS.DELETE_WARNING}
        />
      </Box>
    </Box>
  )
}

export default Roles
