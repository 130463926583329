/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataDomain } from '../models/DataDomain'
import type { DataEntityType } from '../models/DataEntityType'
import type { OrganizationStatusResponse } from '../models/OrganizationStatusResponse'
import type { UnPagedDataDomain } from '../models/UnPagedDataDomain'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DataDomainControllerService {
  /**
   * @returns UnPagedDataDomain OK
   * @throws ApiError
   */
  public static listDataDomains(): CancelablePromise<UnPagedDataDomain> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/data-domains',
    })
  }

  /**
   * @param requestBody
   * @returns DataDomain OK
   * @throws ApiError
   */
  public static createDataDomain(requestBody: DataDomain): CancelablePromise<DataDomain> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/data-domains',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns DataEntityType OK
   * @throws ApiError
   */
  public static insertDataDomainEntity(
    id: string,
    requestBody: DataEntityType
  ): CancelablePromise<DataEntityType> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/data-domains/{id}/entity',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns DataDomain OK
   * @throws ApiError
   */
  public static createDataDomainEntities(
    id: string,
    requestBody: Array<DataEntityType>
  ): CancelablePromise<DataDomain> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/data-domains/{id}/entities',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param pattern
   * @returns OrganizationStatusResponse OK
   * @throws ApiError
   */
  public static loadDataDomains(pattern?: string): CancelablePromise<OrganizationStatusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/data-domains/bulk',
      query: {
        pattern: pattern,
      },
    })
  }

  /**
   * @param id
   * @returns DataDomain OK
   * @throws ApiError
   */
  public static getDataDomainById(id: string): CancelablePromise<DataDomain> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/data-domains/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns DataEntityType OK
   * @throws ApiError
   */
  public static getDataEntityById(id: string): CancelablePromise<DataEntityType> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/data-domains-entity/{id}',
      path: {
        id: id,
      },
    })
  }
}
