import { Typography } from '@mui/material'

type TemplatesToolbarHeaderProps = { header: string }

const TemplatesToolbarHeader = ({ header }: TemplatesToolbarHeaderProps) => {
  return (
    <Typography component="h1" sx={{ color: '#333', fontSize: '12px', fontWeight: 700, pt: 2 }}>
      {header}
    </Typography>
  )
}

export default TemplatesToolbarHeader
