import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import GLOBAL from 'common/styles/global.scss'
import { getTimeFromDate, isoDateToDisplayFormatInUTC } from 'common/utils/DateUtils'
import Timeline from 'modules/shared/Timeline'
import {
  PersonnelControllerService,
  PersonnelSuperLightDTO,
  PrivacyDirectiveControllerService,
  PrivacyDirectiveDTO,
  PrivacyDirectiveOverrideControllerService,
  PrivacyDirectiveOverrideDTO,
} from 'services/openapi'

type DirectiveHistoryItem = {
  date?: string
  reason?: string
  doneBy?: string
  providerNames?: string[]
  directiveType: string
  isDenied?: boolean
}

export type UserMap = {
  id?: string
  name?: string
}

type TimelineProps = {
  date?: string
}

function FileUnrestricted(changeReason: string) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: 'bold' }}>Reason for Changes: </span>
      {changeReason}
    </Typography>
  )
}

function FileAccessedBy(changeReason: string) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: 'bold' }}>Reason for accessing restricted file: </span>
      {changeReason}
    </Typography>
  )
}

function FileRestricted(changeReason: string, providerList: string[], isProviderDenied: boolean) {
  return (
    <Box>
      <Typography variant="body2">
        <span style={{ fontWeight: 'bold' }}>Reason for Changes: </span>
        {changeReason}
      </Typography>
      {isProviderDenied && providerList.length > 0 && (
        <Typography sx={{ marginTop: 2 }} variant="body2">
          <span style={{ fontWeight: 'bold' }}>
            Providers/Staff denied by the client for direct access to restricted file:{' '}
          </span>
          {providerList.join(', ')}
        </Typography>
      )}
      {!isProviderDenied && providerList.length > 0 && (
        <Typography sx={{ marginTop: 2 }} variant="body2">
          <span style={{ fontWeight: 'bold' }}>
            Providers/Staff authorized by the client for direct access to restricted file:{' '}
          </span>
          {providerList.join(', ')}
        </Typography>
      )}
    </Box>
  )
}

function TimelineTitle(props: TimelineProps) {
  if (props.date) {
    return (
      <Stack direction="column" spacing={2} style={{ marginBottom: '10px' }}>
        <Typography
          sx={{
            color: `${GLOBAL.LINK_PRIMARY_COLOR}`,
            fontWeight: 'bold',
            left: '-21px',
            position: 'absolute',
            top: '65px',
          }}
          variant="h3"
        >
          {isoDateToDisplayFormatInUTC(props.date)}
        </Typography>
        <Typography sx={{ left: '3px', position: 'absolute', top: '75px' }} variant="body1">
          {getTimeFromDate(props.date)}
        </Typography>
      </Stack>
    )
  } else {
    return null
  }
}

const PrivacyTimeline = () => {
  const { clientId } = useParams()
  const [items, setItems] = useState<any>([])
  const { t } = useTranslation('common')

  useEffect(() => {
    const getPrivacyDirectives = async (id: string) => {
      const directives = await PrivacyDirectiveControllerService.getDirectives(
        id,
        false,
        undefined,
        undefined,
        ['auditInfo']
      )
      const overrideDirectives =
        await PrivacyDirectiveOverrideControllerService.getDirectiveOverrides(
          id,
          false,
          undefined,
          undefined,
          ['auditInfo']
        )
      if (directives && overrideDirectives && directives.content && overrideDirectives.content) {
        const providerList = directives.content
          .map((directive: PrivacyDirectiveDTO) =>
            directive.personnel?.map((personnel) => personnel.personnelId)
          )
          .flat()
          .filter((personnelId): personnelId is string => !!personnelId)

        let userData: UserMap[] = []
        if (providerList.length > 0) {
          const userList = await PersonnelControllerService.getPersonnelLightList(providerList)
          if (userList) {
            userData = userList.map((user: PersonnelSuperLightDTO) => {
              const userId = user.id
              const userName = user.firstName + ' ' + user.lastName
              return { id: userId, name: userName }
            })
          }
        }

        const mergedItems: DirectiveHistoryItem[] = directives.content.map(
          (directive: PrivacyDirectiveDTO) => {
            return {
              date: directive.auditInfo?.created?.date,
              directiveType: directive.directive?.code || '',
              doneBy:
                directive.auditInfo?.created?.user?.firstName +
                ' ' +
                directive.auditInfo?.created?.user?.lastName,
              isDenied: directive.personnel
                ? directive.personnel[0]?.accessType?.code === 'DENIED'
                : true,
              providerNames: directive.personnel?.map((personnel) => {
                const provider = userData.find((user) => user.id === personnel.personnelId)
                return provider?.name || ''
              }),
              reason: directive.reason,
            }
          }
        )

        overrideDirectives.content.forEach((override: PrivacyDirectiveOverrideDTO) => {
          const overrideItem = {
            date: override.auditInfo?.created?.date,
            directiveType: 'ACCESSED',
            doneBy:
              override.auditInfo?.created?.user?.firstName +
              ' ' +
              override.auditInfo?.created?.user?.lastName,
            reason: override.reason,
          }
          mergedItems.push(overrideItem)
        })

        mergedItems.sort((a, b) => {
          return new Date(b.date || '').getTime() - new Date(a.date || '').getTime()
        })

        const newItems = mergedItems.map((directive: DirectiveHistoryItem) => {
          switch (directive.directiveType) {
            case 'RESTRICTED': {
              return {
                cardSubtitle: `${t('privacy-directives.changes-by')}` + directive.doneBy,
                cardTitle: `${t('privacy-directives.file-restricted')}`,
                content: FileRestricted(
                  directive.reason || '',
                  directive.providerNames || [],
                  directive.isDenied || false
                ),
                title: <TimelineTitle date={directive.date} />,
              }
            }
            case 'UNRESTRICTED': {
              return {
                cardSubtitle: `${t('privacy-directives.changes-by')}` + directive.doneBy,
                cardTitle: `${t('privacy-directives.file-unrestricted')}`,
                content: FileUnrestricted(directive.reason || ''),
                title: <TimelineTitle date={directive.date} />,
              }
            }
            case 'ACCESSED': {
              return {
                cardSubtitle: '' + directive.doneBy,
                cardTitle: `${t('privacy-directives.file-accessed-by')}`,
                content: FileAccessedBy(directive.reason || ''),
                title: <TimelineTitle date={directive.date} />,
              }
            }
          }
          return ''
        })
        setItems(newItems)
      }
    }

    if (clientId) getPrivacyDirectives(clientId)
  }, [clientId, t])

  return <Timeline cardHeight={250} items={items} mode="VERTICAL" />
}

export default PrivacyTimeline
