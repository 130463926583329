import { useEffect, useState } from 'react'
import { GovernanceAgencyControllerService } from 'services/openapi'

export interface LocationType {
  id: string
  label: string
}

const useLocationOfService = () => {
  const [locationOfService, setLocationOfService] = useState<LocationType[] | null>(null)

  useEffect(() => {
    const getLocationOfService = () => {
      GovernanceAgencyControllerService.searchAgencyListForSpecifiedType('LocationOfService').then(
        (result) => {
          if (result.content) {
            const governanceAgencyOptions = result.content.map((agency) => {
              return { id: agency.id as string, label: agency.name as string }
            })
            setLocationOfService(governanceAgencyOptions)
          }
        }
      )
    }
    getLocationOfService()
  }, [])

  return { locationOfService }
}

export default useLocationOfService
