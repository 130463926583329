import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CheckboxProps, FormControlLabel, Stack, TextFieldProps } from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISTextField from 'common/components/form/MISTextField'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBCheckboxProps = {
  data?: CheckboxProps & TextFieldProps & { terminologySet?: string }
  onChange: (checked: boolean) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBCheckbox = ({ data, onChange, onDelete, onSave, preview }: FBCheckboxProps) => {
  const { t } = useTranslation('common')

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(data || {})
  const [configMode, setConfigMode] = useState(false)

  return (
    <>
      {preview ? (
        <FormControlLabel
          control={<MISCheckbox {...data} onChange={(_, checked) => onChange(checked)} />}
          label={data?.label}
        />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <FormControlLabel control={<MISCheckbox {...data} />} label={data?.label} />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.checkbox.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.name}
                label={t('charting.form-builder.name')}
                onChange={(e) =>
                  setConfigData(
                    configData ? { ...configData, name: e.target.value } : { name: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBCheckbox
