import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { isEqual } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import Loader from 'common/components/Loader'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import useHealthConcerns from 'modules/shared/hooks/useHealthConcerns'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateValueType, StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { ErrorType, getError, hasError } from 'modules/shared/utils'
import {
  HEALTH_CONCERN_CODE_SYSTEM_OID,
  healthConcernTerminology,
  HealthConcernType,
} from 'recoil/healthConcerns'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  CancelablePromise,
  CodedConceptDto,
  CodedRef,
  CodeSystemControllerService,
  HealthConcernControllerService,
  HealthConcernDTO,
} from 'services/openapi'
import {
  HEALTH_CONCERN_CERTAINTY_TYPES,
  HEALTH_CONCERN_TYPES,
  MIS_VOID_REASON_TYPE,
  YES_NO_UNKNOWN,
} from 'services/terminologyConstants'
import './HealthConcerns.scss'
import HealthConcernTimeline from './HealthConcernTimeline'
type HealthConcernsDataRowType = {
  data: HealthConcernType
  isCollapsed: boolean
  searchStr: string
}

type HealthConcernsDataType = {
  rows: HealthConcernsDataRowType[]
}

const HealthConcerns = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const { clientId } = useParams()
  const { clientDetails } = useClientDetails(clientId)
  const { healthConcerns, setHealthConcerns } = useHealthConcerns(clientId)
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [healthConcernOptions, setHealthConcernOptions] = useRecoilState(healthConcernTerminology)
  const concernTypeOptions = useRecoilValue(terminologySelector(HEALTH_CONCERN_TYPES))
  const yesNoUnknownOptions = useRecoilValue(terminologySelector(YES_NO_UNKNOWN))
  const stateOptions = useRecoilValue(terminologySelector(HEALTH_CONCERN_CERTAINTY_TYPES))
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  const [currData, setCurrData] = useState<HealthConcernsDataType | undefined>(undefined)
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [loadingTypeAhead, setLoadingTypeAhead] = useState(false)
  const [typeAheadConcerns, setTypeAheadConcerns] = useState<string[]>([])

  const currDataRef = useRef(currData)

  const healthConcernStateValues: StateValueType[] = useMemo(
    () =>
      stateOptions.map((opt) => {
        switch (opt.code) {
          case 'VOIDED':
            return {
              reasonCodeRequired: true,
              reasonCodeTerminology: voidReasonOptions,
              stateValue: opt,
            }
          default:
            return { stateValue: opt }
        }
      }),
    [stateOptions, voidReasonOptions]
  )

  const getHealthConcernName = useCallback(
    (code: string | undefined) => healthConcernOptions.find((opt) => opt.code === code)?.name || '',
    [healthConcernOptions]
  )

  const handleAddRow = useCallback(() => {
    setCurrData({
      rows: [
        ...(currData ? currData.rows : []),
        {
          data: { healthConcern: {}, states: [] },
          isCollapsed: false,
          searchStr: '',
        },
      ],
    })
  }, [currData])

  const handleChangeRow = useCallback(
    (index: number, field: string, value: string) => {
      if (!currData) return
      if (!isDirty) setIsDirty(true)
      const rows = currData.rows.map((row, i) => {
        let data, healthConcern, nextHealthConcern, searchStr
        if (index === i) {
          switch (field) {
            case 'healthConcern':
              nextHealthConcern = healthConcernOptions.find((opt) => opt.name === value)
              healthConcern = {
                ...row.data.healthConcern,
                healthConcern: nextHealthConcern
                  ? { code: nextHealthConcern.code, codeSystemOid: nextHealthConcern.codeSystemOid }
                  : undefined,
              }
              data = { ...row.data, healthConcern, searchStr: '' }
              break
            case 'searchStr':
              healthConcern = { ...row.data.healthConcern, healthConcern: undefined }
              searchStr = value
              data = { ...row.data, healthConcern, searchStr }
              break
            case 'onsetDatetime':
              healthConcern = { ...row.data.healthConcern, onsetDatetime: value }
              data = { ...row.data, healthConcern }
              break
            case 'type':
              healthConcern = {
                ...row.data.healthConcern,
                type: concernTypeOptions.find((o: CodedRef) => o.code === value),
              }
              data = { ...row.data, healthConcern }
              break
            case 'patientAwareOfHealthConcern':
              healthConcern = {
                ...row.data.healthConcern,
                patientAwareOfHealthConcern: yesNoUnknownOptions.find(
                  (o: CodedRef) => o.code === value
                ),
              }
              data = { ...row.data, healthConcern }
              break
            default:
              return row
          }
          return { ...row, data, searchStr: searchStr || '' }
        }
        return row
      })
      setCurrData({ rows })
    },
    [concernTypeOptions, currData, healthConcernOptions, isDirty, setIsDirty, yesNoUnknownOptions]
  )

  const handleChangeState = useCallback(
    async (healthConcernId: string, state: CodedRef, reason?: CodedRef, comment?: string) => {
      if (!healthConcerns) return
      try {
        const newState = await HealthConcernControllerService.postHealthConcernState(
          clientDetails?.id as string,
          healthConcernId,
          { comment, reason, state }
        )
        const updatedHealthConcern = {
          ...healthConcerns[healthConcernId],
          states: [newState, ...healthConcerns[healthConcernId].states],
        }
        const updatedHealthConcerns = { ...healthConcerns, [healthConcernId]: updatedHealthConcern }
        setHealthConcerns(updatedHealthConcerns)
      } catch (error) {
        handleApiError(error)
      }
    },
    [clientDetails?.id, handleApiError, healthConcerns, setHealthConcerns]
  )

  const handleCollapseRow = useCallback(
    (index: number) => {
      if (currData) {
        currData.rows[index].isCollapsed = !currData.rows[index].isCollapsed
        setCurrData({ ...currData })
      }
    },
    [currData]
  )

  const handleGetNextStateOptions = useCallback(async (healthConcernId: string) => {
    const nextStateOptions: StateWithInstructionsType[] = []
    const response = await HealthConcernControllerService.getNextStates(healthConcernId)
    response.content?.forEach((item) => {
      if (item.state) nextStateOptions.push(item)
    })
    return nextStateOptions
  }, [])

  const handleSaveRows = useCallback(() => {
    if (!currData) return
    setIsDirty(false)

    const validate = () => {
      const errors: ErrorType[] = []
      currData.rows?.forEach((row, i) => {
        if (!row.data.healthConcern.healthConcern) {
          errors.push({
            field: `healthConcern-${i}`,
            message: t('health-concerns.health-concern-required'),
          })
        }
        if (!row.data.healthConcern.type) {
          errors.push({
            field: `type-${i}`,
            message: t('health-concerns.type-required'),
          })
        }
        if (!row.data.healthConcern.patientAwareOfHealthConcern) {
          errors.push({
            field: `patientAwareOfHealthConcern-${i}`,
            message: t('health-concerns.patient-aware-of-health-concern-required'),
          })
        }
      })
      setErrors(errors)
      return errors
    }

    const save = async (clientId: string) => {
      const promises: CancelablePromise<HealthConcernDTO>[] = []
      const newData = currData.rows.map((row) => row.data)
      try {
        newData.forEach((each) => {
          if (each.healthConcern.id) {
            const oldHealthConcern = healthConcerns?.[each.healthConcern.id].healthConcern
            if (!isEqual(each.healthConcern, oldHealthConcern))
              promises.push(
                HealthConcernControllerService.putHealthConcern(
                  clientId,
                  each.healthConcern.id,
                  each.healthConcern
                )
              )
          } else {
            promises.push(
              HealthConcernControllerService.postHealthConcern(clientId, each.healthConcern)
            )
          }
        })
        const next = { ...healthConcerns }
        const responses = await Promise.all(promises)
        responses.forEach((healthConcern) => {
          if (!healthConcern.id) return
          if (Object.keys(next).indexOf(healthConcern.id) > -1) {
            next[healthConcern.id] = { ...next[healthConcern.id], healthConcern }
          } else next[healthConcern.id] = { healthConcern, states: [] }
        })
        setHealthConcerns(next)
        showSnackSuccess(t('api.save-success'))
      } catch (err) {
        handleApiError(err)
      }
    }

    setErrors([])
    const errors = validate()
    if (clientDetails?.id && errors.length === 0) save(clientDetails.id)
  }, [
    clientDetails?.id,
    currData,
    handleApiError,
    healthConcerns,
    setHealthConcerns,
    setIsDirty,
    showSnackSuccess,
    t,
  ])

  const handleRemoveRow = useCallback(
    (index: number) => {
      const deleteRow = async (clientId: string, healthConcernId: string) => {
        try {
          await HealthConcernControllerService.deleteHealthConcern(clientId, healthConcernId)
          showSnackSuccess(t('api.save-success'))
        } catch (error) {
          handleApiError(error)
        }
        if (healthConcerns) {
          const { [healthConcernId]: removedProperty, ...nextHealthConcerns } = healthConcerns
          setHealthConcerns({ ...nextHealthConcerns })
          currData?.rows.splice(index, 1)
          setCurrData(currData)
        }
      }
      setDeleteIndex(-1)
      const healthConcernId = currData?.rows[index].data.healthConcern.id
      if (clientDetails?.id && healthConcernId) deleteRow(clientDetails.id, healthConcernId)
      else {
        currData?.rows.splice(index, 1)
        setCurrData({ rows: currData?.rows || [] })
      }
    },
    [
      clientDetails?.id,
      currData,
      handleApiError,
      healthConcerns,
      setHealthConcerns,
      showSnackSuccess,
      t,
    ]
  )

  const handleSearchConcerns = useCallback(
    async (text) => {
      if (text && text.length >= 5) {
        setLoadingTypeAhead(true)
        try {
          const resp = await CodeSystemControllerService.searchCodeSystem(
            HEALTH_CONCERN_CODE_SYSTEM_OID,
            text
          )
          setTypeAheadConcerns(
            resp.concepts ? resp.concepts.map((item) => item.name as string) : []
          )
          const newHealthConcernOpts: CodedConceptDto[] = []
          resp.concepts?.forEach((each) => {
            if (healthConcernOptions.find((opt) => opt.code === each.code) === undefined)
              newHealthConcernOpts.push(each)
          })
          if (newHealthConcernOpts.length > 0)
            setHealthConcernOptions([
              ...new Set([...healthConcernOptions, ...newHealthConcernOpts]),
            ])
          setLoadingTypeAhead(false)
        } catch (error) {
          setTypeAheadConcerns([])
          setLoadingTypeAhead(false)
        }
      } else setTypeAheadConcerns([])
    },
    [healthConcernOptions, setHealthConcernOptions]
  )

  const rowHeader = useCallback(
    (row: HealthConcernsDataRowType) => {
      return (
        <>
          {row.data.healthConcern.healthConcern && (
            <span>{`${getHealthConcernName(row.data.healthConcern.healthConcern?.code)}`}</span>
          )}
          {row.data.healthConcern.onsetDatetime && (
            <span>{` | Onset: ${isoDateToDisplayFormat(
              row.data.healthConcern.onsetDatetime
            )}`}</span>
          )}
          {row.data.healthConcern.type && (
            <span>{` | ${
              concernTypeOptions.find((opt) => opt.code === row.data.healthConcern.type?.code)?.name
            }`}</span>
          )}
          {row.data.healthConcern.patientAwareOfHealthConcern && (
            <span>{` | Patient Aware: ${
              yesNoUnknownOptions.find(
                (opt) => opt.code === row.data.healthConcern.patientAwareOfHealthConcern?.code
              )?.name
            }`}</span>
          )}
          {row.data.states.length > 0 && row.data.states[0].state && (
            <span>
              {' | '}
              <StateChip
                comment={row.data.states[0].comment}
                label={
                  stateOptions?.find(
                    (o: CodedRef) => o.code === (row.data.states[0].state as CodedRef)?.code
                  )?.name as string
                }
                reason={
                  voidReasonOptions.find((o) => o.code === row.data.states[0].reason?.code)?.name
                }
              />
            </span>
          )}
          {row.data.healthConcern.id && (
            <>
              {row.data.states.length === 0 && <span> | </span>}
              <StateSelector
                entityId={row.data.healthConcern.id}
                onGetNextStates={() =>
                  handleGetNextStateOptions(row.data.healthConcern.id as string)
                }
                onSelect={handleChangeState}
                stateValues={healthConcernStateValues}
                warningText={t('common.state-selector.warning')}
              />
            </>
          )}
        </>
      )
    },
    [
      concernTypeOptions,
      getHealthConcernName,
      handleChangeState,
      handleGetNextStateOptions,
      healthConcernStateValues,
      stateOptions,
      t,
      voidReasonOptions,
      yesNoUnknownOptions,
    ]
  )

  const rowForm = useCallback(
    (row: HealthConcernsDataRowType, index: number) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISAutocomplete
              clearOnBlur={false}
              error={hasError(errors, `healthConcern-${index}`)}
              helperText={getError(errors, `healthConcern-${index}`)}
              inputValue={
                getHealthConcernName(row.data.healthConcern.healthConcern?.code) || row.searchStr
              }
              label={t('health-concerns.health-concern')}
              loading={loadingTypeAhead}
              onChange={(value) => handleChangeRow(index, 'healthConcern', value)}
              onFocus={() => {
                if (row.searchStr === '')
                  handleSearchConcerns(
                    getHealthConcernName(row.data.healthConcern.healthConcern?.code)
                  )
              }}
              onInputChange={(value) => {
                handleChangeRow(index, 'searchStr', value)
                handleSearchConcerns(value)
              }}
              options={typeAheadConcerns}
              required
              value={
                getHealthConcernName(row.data.healthConcern.healthConcern?.code) || row.searchStr
              }
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              label={t('health-concerns.onset-date')}
              onChange={(value: unknown) =>
                handleChangeRow(
                  index,
                  'onsetDatetime',
                  value ? `${(value as string).split('T')[0]}T00:00:00` : ''
                )
              }
              value={row.data.healthConcern.onsetDatetime || ''}
            />
          </Grid>
          <Grid item xs={4}>
            <MISSelectDropdown
              error={hasError(errors, `type-${index}`)}
              helperText={getError(errors, `type-${index}`)}
              id={`type-${index}`}
              label={t('health-concerns.type')}
              onChange={(event) => handleChangeRow(index, 'type', event.target.value as string)}
              options={concernTypeOptions.map((opt) => ({
                label: opt.name as string,
                value: opt.code,
              }))}
              required
              value={
                concernTypeOptions.find((opt) => opt.code === row.data.healthConcern.type?.code)
                  ?.code
              }
            />
          </Grid>
          <Grid item xs={2}>
            <MISSelectDropdown
              error={hasError(errors, `patientAwareOfHealthConcern-${index}`)}
              helperText={getError(errors, `patientAwareOfHealthConcern-${index}`)}
              id={`patient-aware-of-health-concern-${index}`}
              label={t('health-concerns.patient-aware-of-health-concern')}
              onChange={(event) =>
                handleChangeRow(index, 'patientAwareOfHealthConcern', event.target.value as string)
              }
              options={yesNoUnknownOptions.map((opt) => ({
                label: opt.name as string,
                value: opt.code,
              }))}
              required
              value={
                yesNoUnknownOptions.find(
                  (opt) => opt.code === row.data.healthConcern.patientAwareOfHealthConcern?.code
                )?.code
              }
            />
          </Grid>
          <Grid item xs={12}>
            {row.data.healthConcern.id && healthConcerns && (
              <div style={{ height: '400px', width: '100%' }}>
                <HealthConcernTimeline healthConcern={healthConcerns[row.data.healthConcern.id]} />
              </div>
            )}
          </Grid>
        </Grid>
      )
    },
    [
      concernTypeOptions,
      errors,
      getHealthConcernName,
      handleChangeRow,
      healthConcerns,
      handleSearchConcerns,
      loadingTypeAhead,
      t,
      typeAheadConcerns,
      yesNoUnknownOptions,
    ]
  )

  useEffect(() => {
    currDataRef.current = currData
  }, [currData])

  useEffect(() => {
    const rows = healthConcerns
      ? Object.values(healthConcerns).map((data) => {
          const existingHealthConcern = currDataRef.current?.rows.find(
            (row) => row.data.healthConcern.id === data.healthConcern.id
          )
          const isCollapsed = existingHealthConcern ? existingHealthConcern.isCollapsed : true
          return { data, isCollapsed, searchStr: '' }
        })
      : []
    const rowsWithStates = rows.filter((row) => row.data.states.length > 0)
    if (rowsWithStates.length > 1) {
      rowsWithStates.sort((a, b) => {
        const aLastUpdatedDate = Date.parse(a.data.states[0].auditInfo?.lastUpdated?.date as string)
        const bLastUpdatedDate = Date.parse(b.data.states[0].auditInfo?.lastUpdated?.date as string)
        return bLastUpdatedDate - aLastUpdatedDate
      })
    }
    const rowsWithoutStates = rows.filter((row) => row.data.states.length === 0)
    setCurrData({ rows: [...rowsWithStates, ...rowsWithoutStates] })
  }, [healthConcerns])

  return (
    <MISBaseContainer>
      <ClientRecordHeader />
      <Box className="health-concerns-container">
        <Box className="health-concerns-content">
          <SectionHeader
            addLabel={healthConcerns ? t('health-concerns.add') : undefined}
            onAdd={handleAddRow}
            onSave={handleSaveRows}
            saveLabel={healthConcerns ? t('common.button.save') : undefined}
            title={t('health-concerns.title')}
          />
          <Box sx={{ px: GLOBAL.MARGIN_MD }}>
            {healthConcerns && currData ? (
              <MISAccordionContainer
                emptyDataMessage={t('health-concerns.empty-message')}
                errors={errors.length > 0 ? [t('common.validation.errors')] : []}
                onChangeRow={handleChangeRow}
                onCollapseRow={handleCollapseRow}
                onRemoveRow={(index: number) => setDeleteIndex(index)}
                rowForm={rowForm}
                rowHeader={rowHeader}
                rows={currData.rows}
              />
            ) : (
              <Loader />
            )}
          </Box>
        </Box>
        <WarningDialog
          entity="Health Concern"
          onCancel={() => setDeleteIndex(-1)}
          onSave={() => handleRemoveRow(deleteIndex)}
          open={deleteIndex > -1}
          type={MODALS.DELETE_WARNING}
        />
      </Box>
    </MISBaseContainer>
  )
}

export default HealthConcerns
