import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Divider, Grid, MenuItem } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import ADD_CLIENT_IMG_SRC from 'assets/images/add-client.jpg'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { dateNowIsoString, formatToLocalDateTime } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { isDirtyState } from 'recoil/isDirty'
import { terminologyDefaultStatusSelector, terminologySelector } from 'recoil/terminology'
import { ClientControllerService, CodedConceptDto } from 'services/openapi'
import {
  MEMBER_STATUS_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
  PERSON_NAME_USE_VOCAB_NAME,
} from 'services/terminologyConstants'

const AddEditClient = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { showSnackSuccess } = useSnack()
  const { buildError, getError, handleApiError, hasError, isError, setErrorList } =
    useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)

  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const nameTypeOptions = useRecoilValue(terminologySelector(PERSON_NAME_USE_VOCAB_NAME))
  const statusOptions = useRecoilValue(terminologySelector(MEMBER_STATUS_VOCAB_NAME))
  const defaultStatus = useRecoilValue(terminologyDefaultStatusSelector)

  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [namePrefix, setNamePrefix] = useState('')
  const [nameSuffix, setNameSuffix] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [pronouns, setPronouns] = useState('')
  const [status, setStatus] = useState(defaultStatus)
  const [birthdate, setBirthdate] = useState('')
  const [estimated, setEstimated] = useState<boolean>(false)

  const handleChange = useCallback(
    (value: any, setValue: (value: any) => void) => {
      if (!isDirty) setIsDirty(true)
      setValue(value)
    },
    [isDirty, setIsDirty]
  )

  const getCodedConcept = (selectedValue: CodedConceptDto) => {
    return {
      code: selectedValue?.code,
      codeSystemOid: selectedValue?.codeSystemOid,
      name: selectedValue?.name,
    }
  }

  const handleSaveClient = async () => {
    setIsDirty(false)

    if (validate()) {
      let nameTypeCodedConcept
      const legalNameType = nameTypeOptions.find((x) => x.code === 'L')
      if (legalNameType) nameTypeCodedConcept = getCodedConcept(legalNameType)

      let genderCodedConcept
      if (gender) {
        const selectedGender = genderOptions.find((x) => x.id === gender)
        if (selectedGender) {
          genderCodedConcept = getCodedConcept(selectedGender)
        }
      }

      let statusCodedConcept
      if (status) {
        const selectedStatus = statusOptions.find((x) => x.id === status)
        if (selectedStatus) {
          statusCodedConcept = getCodedConcept(selectedStatus)
        }
      }

      if (!genderCodedConcept || !statusCodedConcept) {
        return
      }

      let pronounsCodedConcept
      if (pronouns) {
        const selectedPronouns = pronounsOptions.find((x) => x.id === pronouns)
        if (selectedPronouns) {
          pronounsCodedConcept = getCodedConcept(selectedPronouns)
        }
      }

      const client = {
        birthdate: birthdate ? formatToLocalDateTime(birthdate) : '',
        firstName: firstName,
        gender: genderCodedConcept,
        isBirthEstimated: estimated,
        lastName: lastName,
        middleName: middleName,
        preferredName: true,
        prefix: namePrefix,
        pronouns: pronounsCodedConcept || undefined,
        startDate: dateNowIsoString(),
        status: statusCodedConcept,
        suffix: nameSuffix,
        type: nameTypeCodedConcept,
      }

      ClientControllerService.createClient(client)
        .then((result) => {
          const successMessage = t('client-edit.create-success')
          showSnackSuccess(successMessage)
          navigate(`/clients/client-record/${result.id}`)
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
  }

  const validate = () => {
    const errors: any = []
    if (!firstName) {
      errors.push(
        buildError('names[0].firstName', 'api.error.required-field', { fieldName: 'First Name' })
      )
    }

    if (!gender) {
      errors.push(buildError('gender', 'api.error.required-field', { fieldName: 'Gender' }))
    }
    if (!status) {
      errors.push(buildError('status', 'api.error.required-field', { fieldName: 'Status' }))
    }
    setErrorList(errors)
    return errors.length === 0
  }

  const getAddClientContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MISTextField
              error={isError() && hasError('status')}
              fullWidth
              helperText={getError('status')}
              id="status"
              label={t('client-edit.status-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setStatus)
              }
              required
              select
              value={status}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </MISTextField>
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              error={isError() && hasError('names[0].firstName')}
              helperText={getError('names[0].firstName')}
              id="firstName"
              inputProps={{ maxLength: 50 }}
              label={t('client-edit.first-name-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setFirstName)
              }
              required
              value={firstName || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              fullWidth
              id="middleName"
              inputProps={{ maxLength: 50 }}
              label={t('client-edit.middle-name-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setMiddleName)
              }
              value={middleName}
            />
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              id="lastName"
              inputProps={{ maxLength: 50 }}
              label={t('client-edit.last-name-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setLastName)
              }
              value={lastName}
            />
          </Grid>

          <Grid item xs={1.5}>
            <MISTextField
              id="namePrefix"
              inputProps={{ maxLength: 20 }}
              label={t('client-edit.prefix-name-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setNamePrefix)
              }
              value={namePrefix}
            />
          </Grid>
          <Grid item xs={1.5}>
            <MISTextField
              fullWidth
              id="nameSuffix"
              inputProps={{ maxLength: 20 }}
              label={t('client-edit.suffix-name-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setNameSuffix)
              }
              value={nameSuffix}
            />
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              error={isError() && hasError('gender')}
              fullWidth
              helperText={getError('gender')}
              id="gender"
              label={t('client-edit.gender-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setGender)
              }
              required
              select
              value={gender}
            >
              {genderOptions.map((gender) => (
                <MenuItem key={gender.id} value={gender.id}>
                  {gender.name}
                </MenuItem>
              ))}
            </MISTextField>
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              fullWidth
              id="pronouns"
              label={t('client-edit.pronouns-label')}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(event?.target.value, setPronouns)
              }
              select
              value={pronouns}
            >
              {pronounsOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </MISTextField>
          </Grid>

          <Grid item xs={3}>
            <MISDatePicker
              disableFuture
              displayAge
              displayEstimated
              estimated={estimated}
              label={t('client-edit.birthdate-label')}
              onChange={(value) => handleChange(value, setBirthdate)}
              onChangeEstimated={() => handleChange(!estimated, setEstimated)}
              value={birthdate}
            />
          </Grid>
        </Grid>
        <Divider className="divider" />
        <Box className="actions" sx={{ textAlign: 'end' }}>
          <MISButton onClick={handleSaveClient} variant="contained">
            {t('client-edit.add-client-text')}
          </MISButton>
        </Box>
      </>
    )
  }

  return (
    <MISBaseContainer>
      <div className="add-edit-client">
        <MISImageContentContainer imgSrc={ADD_CLIENT_IMG_SRC}>
          <Content
            content={getAddClientContent()}
            heading={t('client-edit.add-client-text')}
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
      </div>
    </MISBaseContainer>
  )
}

export default AddEditClient
