import React from 'react'
import Agreements from './Agreements'

const AgreementsConfidential = ({ breadcrumb }) => {
  return (
    <Agreements
      breadcrumb={breadcrumb}
      title="Confidentiality Reminders"
      type="confidentiality-agreements"
    />
  )
}

export default AgreementsConfidential
