/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodedConceptDto } from '../models/CodedConceptDto'
import type { UnPagedCodedConceptDto } from '../models/UnPagedCodedConceptDto'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ValueSetControllerService {
  /**
   * @param vocabularyNames
   * @param includeRelatedConcepts
   * @returns CodedConceptDto OK
   * @throws ApiError
   */
  public static getValueSets(
    vocabularyNames: Array<string>,
    includeRelatedConcepts: boolean = false
  ): CancelablePromise<Record<string, Array<CodedConceptDto>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-terminology-service/v1/value-sets',
      query: {
        vocabularyNames: vocabularyNames,
        includeRelatedConcepts: includeRelatedConcepts,
      },
    })
  }

  /**
   * @param vocabularyName
   * @returns UnPagedCodedConceptDto OK
   * @throws ApiError
   */
  public static getValueSet(vocabularyName: string): CancelablePromise<UnPagedCodedConceptDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-terminology-service/v1/value-sets/{vocabularyName}',
      path: {
        vocabularyName: vocabularyName,
      },
    })
  }
}
