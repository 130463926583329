import './Editor.scss'

import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import 'quill-mention'
import { Box, FormControlLabel, Grid, Stack, Switch } from '@mui/material'
import debounce from 'lodash/debounce'
import { DeltaStatic } from 'quill'
import { useRecoilValue } from 'recoil'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import {
  getClientAgeString,
  getClientPreferredName,
  getIdentifierValue,
} from 'modules/shared/clientUtils'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientControllerService,
  ClientDTO,
  EncounterServiceTemplateDTO,
  IdheServiceControllerService,
  IdheServiceDTO,
  ProgramControllerService,
  ProgramTerse,
} from 'services/openapi'
import {
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
} from 'services/terminologyConstants'
import { setPreview } from 'store/reducers/charting'
import { selectChartingPreviewState } from 'store/selectors/charting'
import { TValue } from '../../Charting'
import MentionNode from '../MentionItem'
import '../modules/registration'

export enum EMentionTypes {
  Client = 'Client',
  Program = 'Program',
  Service = 'Service',
}

export interface NavigationDetail {
  activeMenuItem?: string
  fromMenuItem?: string
}

const mentionValues: TValue[] = [
  { id: 1, value: 'Client' },
  { id: 2, value: 'Program' },
  { id: 3, value: 'Service' },
]

interface EditorProps extends ReactQuillProps {
  disableMentions?: boolean
  onMentionResultSelect?: (
    item: ClientDTO | ProgramTerse | EncounterServiceTemplateDTO,
    type: EMentionTypes
  ) => void
}
const Editor = forwardRef((props: EditorProps, ref) => {
  const dispatch = useDispatch()
  const preview = useSelector(selectChartingPreviewState)
  const { t } = useTranslation('common')

  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(false)

  const editorRef = useRef<ReactQuill | null>(null)
  const mentionTypeRef = useRef<EMentionTypes | null>(null)
  const lastMentionTypeRef = useRef<EMentionTypes | null>(null)
  const mentionResultsRef = useRef<ClientDTO[] | ProgramTerse[] | EncounterServiceTemplateDTO[]>([])
  const serviceDateTimeRef = useRef<string | undefined>()
  const navigationMenuDetailsRef = useRef<NavigationDetail | undefined>()
  const clientMentionsRef = useRef<string[] | undefined>()
  const clientIdContextRef = useRef<string | undefined>()
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const identifierTypeOptions = useRecoilValue(
    terminologySelector(MEMBER_IDENTIFIER_TYPE_VOCAB_NAME)
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const isBlankOrInprogress = useMemo(
    () =>
      (window.location.pathname.endsWith('/charting') &&
        !window.location.pathname.includes('client-record')) ||
      window.location.pathname.endsWith('/in-progress'),
    []
  )

  const handleMenuItemOnClose = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) mentionTypeRef.current = null
  }, [])

  const handleMentionItemOnSelect = useCallback(
    (item: TValue, insertItem: (item: TValue) => void) => {
      const editor = editorRef.current?.getEditor()
      if (editor) {
        if (mentionTypeRef.current) {
          item.type = lastMentionTypeRef.current || ''
          insertItem(item)
          switch (lastMentionTypeRef.current) {
            case EMentionTypes.Client:
              props.onMentionResultSelect?.(
                (mentionResultsRef.current as ClientDTO[]).find(
                  (each) => each.id === item.id
                ) as ClientDTO,
                EMentionTypes.Client
              )
              break
            case EMentionTypes.Program:
              props.onMentionResultSelect?.(
                (mentionResultsRef.current as ProgramTerse[]).find(
                  (each) => each.id === item.id
                ) as ProgramTerse,
                EMentionTypes.Program
              )
              break
            case EMentionTypes.Service:
            default:
              props.onMentionResultSelect?.(
                (mentionResultsRef.current as IdheServiceDTO[]).find(
                  (each) => each.id === item.id
                ) as IdheServiceDTO,
                EMentionTypes.Service
              )
              break
          }
          mentionTypeRef.current = null
        } else {
          const mentionModule = editor.getModule('mention')
          editor.deleteText(
            mentionModule.mentionCharPos,
            mentionModule.cursorPos - mentionModule.mentionCharPos,
            'user'
          )
          mentionTypeRef.current = item.value as EMentionTypes
          setTimeout(() => mentionModule.openMenu('@'), 100)
        }
      }
    },
    [props]
  )

  const getClientDetail = useCallback(
    (client: ClientDTO) => {
      const preferredName = getClientPreferredName(client)
      const gender =
        client.gender && genderOptions?.find((option) => option.code === client.gender?.code)?.name
      const birthDate = client.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''
      const age = client.birthdate ? getClientAgeString(client.birthdate) : ''
      const statusCardNumber =
        getIdentifierValue('Indian Registry Status Number', client, identifierTypeOptions) || ''
      return `${preferredName}|${t('client-header.gender-label')}: ${gender}| ${t(
        'client-header.birthdate-label'
      )}:${birthDate}|${t('client-header.age-label')}: ${age}|${t(
        'client-header.status-card-label'
      )}:${statusCardNumber} `
    },
    [genderOptions, identifierTypeOptions, t]
  )

  const handleSource = useCallback(
    async (
      searchTerm: string,
      renderList: (values: TValue[], searchTerm: string) => ReactNode,
      mentionChar: string
    ) => {
      let values: TValue[] = []
      if (mentionChar === '\\') {
        values = mentionValues
        if (searchTerm.length === 0) {
          renderList(values, searchTerm)
        } else {
          const matches: TValue[] = []
          for (let i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i])
          renderList(matches, searchTerm)
        }
      } else if (mentionTypeRef.current && mentionChar === '@') {
        if (searchTerm.length < 4) {
          values.push({
            disabled: true,
            id: 1,
            value: `Search ${mentionTypeRef.current.toLocaleLowerCase()} by name`,
          })
        } else {
          let resp
          lastMentionTypeRef.current = mentionTypeRef.current
          switch (mentionTypeRef.current) {
            case EMentionTypes.Client:
              resp = await ClientControllerService.searchClient(searchTerm, '', '', '', -1)
              mentionResultsRef.current = resp.content || []
              values =
                resp.content && resp.content.length > 0
                  ? resp.content.map((each) => {
                      return { id: each.id as string, value: getClientDetail(each) }
                    })
                  : [
                      {
                        disabled: true,
                        id: '1',
                        value: 'No client found',
                      },
                    ]
              break
            case EMentionTypes.Program:
              resp = resp = await ProgramControllerService.searchPrograms(
                true,
                new Date(serviceDateTimeRef.current as string).toISOString(),
                searchTerm
              )
              mentionResultsRef.current = resp || []
              values =
                resp && resp.length > 0
                  ? resp.map((each) => {
                      return { id: each.id as string, type: 'program', value: each.name as string }
                    })
                  : [
                      {
                        disabled: true,
                        id: '2',
                        value: 'No program found',
                      },
                    ]
              break
            case EMentionTypes.Service:
            default:
              resp = await IdheServiceControllerService.searchServices(true, searchTerm)
              mentionResultsRef.current = resp.content || []
              values =
                resp.content && resp.content.length > 0
                  ? resp.content.map((each) => {
                      return { id: each.id as string, type: 'service', value: each.name as string }
                    })
                  : [
                      {
                        disabled: true,
                        id: '3',
                        value: 'No service found',
                      },
                    ]
              break
          }
        }
        renderList(values, searchTerm)
      } else {
        renderList([], searchTerm)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleRedo = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) (editor as unknown as { history: { redo: () => void } }).history.redo()
  }, [])

  const handleUndo = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) (editor as unknown as { history: { undo: () => void } }).history.undo()
  }, [])

  const modules = useMemo(() => {
    const mention = props.disableMentions
      ? undefined
      : {
          allowedChars: /^[a-zA-Z0-9\s:]*$/,
          mentionDenotationChars: ['\\', '@'],
          onClose: handleMenuItemOnClose,
          onSelect: handleMentionItemOnSelect,
          renderItem: (item: TValue) => MentionNode(item),
          renderLoading: () => MentionNode({ id: 1, value: 'Loading...' }),
          showDenotationChar: false,
          source: debounce(handleSource, 500),
        }

    return {
      mention,
      templatesToolbar: {
        container: '#templates-toolbar',
      },
      toolbar: {
        container: [
          ['undo', 'redo'],
          [{ font: [] }, { size: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          redo: handleRedo,
          undo: handleUndo,
        },
      },
    }
  }, [
    props.disableMentions,
    handleMentionItemOnSelect,
    handleMenuItemOnClose,
    handleRedo,
    handleSource,
    handleUndo,
  ])

  useImperativeHandle(
    ref,
    () => ({
      getClientIdContext() {
        return clientIdContextRef.current
      },
      getClientMentions() {
        return clientMentionsRef.current
      },
      getContents() {
        const editor = editorRef.current?.getEditor()
        if (editor) return editor.getContents()
      },
      getNavigationDetails() {
        return navigationMenuDetailsRef.current
      },
      setClientIdContext(clientId: string | undefined) {
        clientIdContextRef.current = clientId
      },
      setClientMentions(clientMentions: string[] | undefined) {
        clientMentionsRef.current = clientMentions
      },
      setContents(contents: string | DeltaStatic) {
        const editor = editorRef.current?.getEditor()
        if (editor) {
          if (typeof contents === 'string') editor.setText(contents, 'user')
          else setTimeout(() => editor.setContents(contents, 'user'), 10)
        }
      },
      setNavigationDetails(navigationDetail: NavigationDetail | undefined) {
        navigationMenuDetailsRef.current = navigationDetail
      },
      setServiceDateTime(serviceDateTime: string | undefined) {
        serviceDateTimeRef.current = serviceDateTime
      },
    }),
    []
  )

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    dispatch(setPreview(!isTemplateEditor))
  }, [dispatch, isTemplateEditor])

  useEffect(() => {
    if (
      (editorRef?.current?.editor?.getContents() &&
        editorRef?.current?.editor?.getContents().length() > 1) ||
      !isBlankOrInprogress
    ) {
      setHasContent(true)
    } else {
      setHasContent(false)
    }
  }, [isBlankOrInprogress, isFocused, isTemplateEditor])

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        {isTemplateEditor && (
          <Box
            alignContent="end"
            sx={{
              border: '1px solid #ccc',
              borderBottom: 'none',
              borderRadius: '4px 4px 0 0',
              height: '38px',
            }}
          >
            <Stack direction="row-reverse" spacing={3} sx={{ pb: 1, pt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={preview}
                    name="template-preview-mode"
                    onChange={(_, checked) => dispatch(setPreview(checked))}
                    size="small"
                  />
                }
                label={t('charting.canvas.template-preview-mode')}
                sx={{ display: 'flex', pr: 1 }}
              />
            </Stack>
          </Box>
        )}
        <ReactQuill
          className={
            !isFocused && !hasContent && !isTemplateEditor ? 'quill-editor-placeholder' : 'canvas'
          }
          modules={modules}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={editorRef}
          {...props}
        />
      </Grid>
      <Grid item xs={2}>
        <Box id="templates-toolbar" sx={{ pb: 2, width: '100%' }} />
      </Grid>
    </Grid>
  )
})
Editor.displayName = 'Editor'

export default Editor
