import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { isEqual } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import IDHEDatePicker from 'common/components/fields/DatePicker'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { isDate1BeforeDate2, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { ErrorType, getError } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import { personnelTrainingState } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedRef,
  PersonnelTrainingControllerService,
  PersonnelTrainingDTO,
} from 'services/openapi'
import { MIS_PROVIDER_QUALIFIED_ROLE_TYPE } from 'services/terminologyConstants'
import { MODALS } from '../../../../shared/constants'
import WarningDialog from '../../../../shared/Dialogs/WarningDialog'

type TrainingDataRowType = {
  data: PersonnelTrainingDTO
  isCollapsed: boolean
}

type TrainingDataType = {
  rows: TrainingDataRowType[]
}

const sortTrainingsDataRow = (training: TrainingDataRowType[]): TrainingDataRowType[] => {
  const noStartDate = training.filter(
    (each) => each.data.effective === undefined || each.data.effective.startDate === undefined
  )
  if (noStartDate.length > 1)
    noStartDate.sort((a, b) =>
      a.data.courseTitle && b.data.courseTitle
        ? a.data.courseTitle.localeCompare(b.data.courseTitle)
        : 0
    )
  const withStartDate = training.filter((each) => !!each.data.effective?.startDate)
  if (withStartDate.length > 1)
    withStartDate.sort((a, b) => {
      if (a.data.effective?.startDate && b.data.effective?.startDate) {
        return isDate1BeforeDate2(a.data.effective.startDate, b.data.effective.startDate) ? 1 : -1
      }
      return 0
    })
  return [...withStartDate, ...noStartDate]
}

const Training = forwardRef((_, ref) => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [personnelTraining, setPersonnelTraining] = useRecoilState(personnelTrainingState)
  const qualifiedRole = useRecoilValue(terminologySelector(MIS_PROVIDER_QUALIFIED_ROLE_TYPE))

  const [clearCurrDataForSorting, setClearCurrDataForSorting] = useState(false)
  const [currData, setCurrData] = useState<TrainingDataType>({
    rows: personnelTraining.training
      ? sortTrainingsDataRow(
          personnelTraining.training.map((each) => {
            return {
              data: each,
              isCollapsed: true,
            }
          })
        )
      : [],
  })
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [typeAheadCourses, setTypeAheadCourses] = useState<string[]>([])
  const [typeAheadProviders, setTypeAheadProviders] = useState<string[]>([])

  const handleAddRow = useCallback(
    () =>
      setCurrData({
        rows: [
          ...currData.rows,
          {
            data: {
              completedHours: undefined,
              courseProvider: undefined,
              courseTitle: undefined,
              credentialType: undefined,
              effective: undefined,
              hoursTotal: undefined,
              isComplete: undefined,
              note: undefined,
            },
            isCollapsed: false,
          },
        ],
      }),
    [currData]
  )

  const handleChangeRow = useCallback(
    (index: number, field: string, value: number | string) => {
      if (!isDirty) setIsDirty(true)

      const rows = currData.rows.map((row, i) => {
        let data
        if (index === i) {
          switch (field) {
            case 'courseTitle':
              data = { ...row.data, courseTitle: value as string }
              break
            case 'courseProvider':
              data = { ...row.data, courseProvider: value as string }
              break
            case 'hoursTotal':
              data = { ...row.data, hoursTotal: value as number }
              break
            case 'completedHours':
              data = { ...row.data, completedHours: value as number }
              break
            case 'isComplete':
              data = {
                ...row.data,
                isComplete: value === t('provider-staff.credentials-training.training.yes'),
              }
              break
            case 'credentialType':
              data = {
                ...row.data,
                credentialType: qualifiedRole?.find((o: CodedRef) => o.code === (value as string)),
              }
              break
            case 'note':
              data = { ...row.data, note: value as string }
              break
            case 'startDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, startDate: value as string },
              }
              break
            case 'endDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, endDate: value as string },
              }
              break
            default:
              return row
          }
          return { ...row, data }
        }
        return row
      })
      setCurrData({ rows })
    },
    [currData.rows, qualifiedRole, isDirty, setIsDirty, t]
  )

  const handleCollapseRow = useCallback(
    (index: number) => {
      currData.rows[index].isCollapsed = !currData.rows[index].isCollapsed
      setCurrData({ ...currData })
    },
    [currData]
  )

  const handleRemoveRow = useCallback(
    (index: number) => {
      const deleteRow = async (personnelId: string, id: string) => {
        setClearCurrDataForSorting(true)
        try {
          await PersonnelTrainingControllerService.deletePersonnelTraining(personnelId, id)
          setPersonnelTraining({
            id: personnelId,
            training: personnelTraining.training.filter((each) => each.id !== id),
          })
          currData.rows.splice(index, 1)
          setCurrData(currData)
          showSnackSuccess(t('api.save-success'))
        } catch (error) {
          handleApiError(error)
        }
        setClearCurrDataForSorting(false)
      }
      setDeleteIndex(-1)
      const id = currData.rows[index].data.id
      if (personnelTraining.id && id) deleteRow(personnelTraining.id, id)
      else {
        currData.rows.splice(index, 1)
        setCurrData({ rows: currData.rows })
      }
    },
    [
      currData,
      handleApiError,
      personnelTraining.id,
      personnelTraining.training,
      setPersonnelTraining,
      showSnackSuccess,
      t,
    ]
  )

  const handleSaveRows = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      const errors: ErrorType[] = []
      currData.rows?.forEach((row, i) => {
        if (!row.data.courseTitle) {
          errors.push({
            field: `training-counrse-title-${i}`,
            message: t(
              'provider-staff.credentials-training.training.training-counrse-title-required'
            ),
          })
        }
      })
      setErrors(errors)
      return errors
    }
    const save = async (personnelId: string) => {
      const newData = currData.rows.map((row) => row.data)
      if (isEqual(newData, personnelTraining.training)) return
      setClearCurrDataForSorting(true)
      try {
        const training = await PersonnelTrainingControllerService.savePersonnelTraining(
          personnelId,
          currData.rows.map((row) => row.data)
        )
        setPersonnelTraining({
          id: personnelId,
          training,
        })
        setCurrData({
          rows: sortTrainingsDataRow(
            currData.rows.map((row, index) => {
              return {
                data: training[index],
                isCollapsed: row.isCollapsed,
              }
            })
          ),
        })
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
      setClearCurrDataForSorting(false)
    }
    setErrors([])
    const errors = validate()
    if (personnelTraining.id && errors.length === 0) save(personnelTraining.id)
  }, [
    currData.rows,
    handleApiError,
    personnelTraining.id,
    personnelTraining.training,
    setPersonnelTraining,
    showSnackSuccess,
    setIsDirty,
    t,
  ])

  const handleSearchCourses = useCallback(async (text) => {
    if (text) {
      try {
        const response = await PersonnelTrainingControllerService.searchCourses(text)
        setTypeAheadCourses(response.content || [])
      } catch (error) {
        setTypeAheadCourses([])
      }
    } else setTypeAheadCourses([])
  }, [])

  const handleSearchProviders = useCallback(async (text) => {
    if (text) {
      try {
        const response = await PersonnelTrainingControllerService.searchCourseProviders(text)
        setTypeAheadProviders(response.content || [])
      } catch (error) {
        setTypeAheadProviders([])
      }
    } else setTypeAheadProviders([])
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      add() {
        handleAddRow()
      },
      save() {
        handleSaveRows()
      },
    }),
    [handleAddRow, handleSaveRows]
  )

  const rowHeader = useCallback(
    (row: TrainingDataRowType) => {
      const cred = qualifiedRole?.find((o: CodedRef) => o.code === row.data.credentialType?.code)
      return (
        <>
          {row.data.courseTitle && <span>{row.data.courseTitle}</span>}
          {cred?.name && <span>{` | ${cred?.name}`}</span>}
          {row.data.effective?.startDate && (
            <span>{` | ${isoDateToDisplayFormat(row.data.effective.startDate)}`}</span>
          )}
          {row.data.effective?.endDate && (
            <span>{` to ${isoDateToDisplayFormat(row.data.effective.endDate)}`}</span>
          )}
        </>
      )
    },
    [qualifiedRole]
  )

  const rowForm = useCallback(
    (row: TrainingDataRowType, index: number) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MISAutocomplete
              clearOnBlur={false}
              error={!!getError(errors, `training-counrse-title-${index}`)}
              helperText={getError(errors, `training-counrse-title-${index}`)}
              inputValue={row.data.courseTitle || ''}
              label={t('provider-staff.credentials-training.training.training-counrse-title')}
              onChange={(value) => {
                handleChangeRow(index, 'courseTitle', value)
              }}
              onInputChange={(value) => {
                handleChangeRow(index, 'courseTitle', value)
                handleSearchCourses(value)
              }}
              options={typeAheadCourses}
              required
              value={row.data.courseTitle || ''}
            />
          </Grid>
          <Grid item xs={2}>
            <MISAutocomplete
              clearOnBlur={false}
              inputValue={row.data.courseProvider || ''}
              label={t('provider-staff.credentials-training.training.training-provided-by')}
              onChange={(value) => {
                handleChangeRow(index, 'courseProvider', value)
              }}
              onInputChange={(value) => {
                handleChangeRow(index, 'courseProvider', value)
                handleSearchProviders(value)
              }}
              options={typeAheadProviders}
              value={row.data.courseProvider || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              id={`training-course-duration-${index}`}
              label={t('provider-staff.credentials-training.training.training-course-duration')}
              onChange={(event) => handleChangeRow(index, 'hoursTotal', event.target.value)}
              type="number"
              value={row.data.hoursTotal}
            />
          </Grid>
          <Grid item xs={2}>
            <MISTextField
              id={`hours-completed-${index}`}
              label={t('provider-staff.credentials-training.training.hours-completed')}
              onChange={(event) => handleChangeRow(index, 'completedHours', event.target.value)}
              type="number"
              value={row.data.completedHours}
            />
          </Grid>
          <Grid item xs={2}>
            <MISRadioGroup
              id={`training-completed-${index}`}
              label={t('provider-staff.credentials-training.training.training-completed')}
              name={`training-completed-${index}`}
              onChange={(event) => handleChangeRow(index, 'isComplete', event.target.value)}
              options={[
                {
                  label: t('provider-staff.credentials-training.training.yes'),
                  value: t('provider-staff.credentials-training.training.yes'),
                },
                {
                  label: t('provider-staff.credentials-training.training.no'),
                  value: t('provider-staff.credentials-training.training.no'),
                },
              ]}
              value={
                row.data.isComplete === undefined
                  ? undefined
                  : row.data.isComplete
                  ? t('provider-staff.credentials-training.training.yes')
                  : t('provider-staff.credentials-training.training.no')
              }
            />
          </Grid>
          <Grid item xs={3}>
            <MISSelectDropdown
              id={`contributes-to-credential-${index}`}
              label={t('provider-staff.credentials-training.training.contributes-to-credential')}
              onChange={(event) =>
                handleChangeRow(index, 'credentialType', event.target.value as string)
              }
              options={qualifiedRole.map((opt) => ({
                label: opt.name as string,
                value: opt.code,
              }))}
              value={qualifiedRole.find((opt) => opt.code === row.data.credentialType?.code)?.code}
            />
          </Grid>
          <Grid item xs={5}>
            <MISTextField
              id={`note-${index}`}
              label={t('provider-staff.credentials-training.note')}
              onChange={(event) => handleChangeRow(index, 'note', event.target.value)}
              value={row.data.note}
            />
          </Grid>
          <Grid item xs={2}>
            <IDHEDatePicker
              date={row.data.effective?.startDate}
              error={undefined}
              helperText={undefined}
              label={t('provider-staff.credentials-training.start-date')}
              onDateChange={(value: string) => handleChangeRow(index, 'startDate', value)}
              readOnly={false}
              required={false}
            />
          </Grid>
          <Grid item xs={2}>
            <IDHEDatePicker
              date={row.data.effective?.endDate}
              error={undefined}
              helperText={undefined}
              label={t('provider-staff.credentials-training.end-date')}
              onDateChange={(value: string) => handleChangeRow(index, 'endDate', value)}
              readOnly={false}
              required={false}
            />
          </Grid>
        </Grid>
      )
    },
    [
      errors,
      handleChangeRow,
      handleSearchCourses,
      handleSearchProviders,
      qualifiedRole,
      typeAheadCourses,
      typeAheadProviders,
      t,
    ]
  )

  return (
    <Box sx={{ pt: GLOBAL.PADDING_XXL }}>
      <SectionHeader
        isSubsection
        title={t('provider-staff.credentials-training.training.training-counrse')}
      />
      <MISAccordionContainer
        emptyDataMessage={
          clearCurrDataForSorting
            ? ''
            : t('provider-staff.credentials-training.training.empty-data')
        }
        errors={errors.length === 0 ? [] : [t('common.validation.errors')]}
        onChangeRow={handleChangeRow}
        onCollapseRow={handleCollapseRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowForm}
        rowHeader={rowHeader}
        rows={clearCurrDataForSorting ? [] : currData.rows}
      />
      <WarningDialog
        entity="Training"
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => handleRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </Box>
  )
})

Training.displayName = 'Training'
export default Training
