/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncounterServiceStateDTO } from '../models/EncounterServiceStateDTO'
import type { UnPagedEncounterServiceStateDTO } from '../models/UnPagedEncounterServiceStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterServiceStateControllerService {
  /**
   * Get history of state changes by serviceId
   * @param encounterId
   * @param encounterServiceId
   * @returns UnPagedEncounterServiceStateDTO OK
   * @throws ApiError
   */
  public static getStatesByServiceId(
    encounterId: string,
    encounterServiceId: string
  ): CancelablePromise<UnPagedEncounterServiceStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/states',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
    })
  }

  /**
   * Create a new State for serviceId
   * @param encounterId
   * @param encounterServiceId
   * @param requestBody
   * @returns EncounterServiceStateDTO OK
   * @throws ApiError
   */
  public static createEncounterServiceState(
    encounterId: string,
    encounterServiceId: string,
    requestBody: EncounterServiceStateDTO
  ): CancelablePromise<EncounterServiceStateDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/states',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get state by encounterId
   * @param encounterId
   * @returns UnPagedEncounterServiceStateDTO OK
   * @throws ApiError
   */
  public static getStatesByEncounterId(
    encounterId: string
  ): CancelablePromise<UnPagedEncounterServiceStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/states',
      path: {
        encounterId: encounterId,
      },
    })
  }

  /**
   * Get list of possible next states
   * @param encounterId
   * @param encounterServiceId
   * @returns UnPagedEncounterServiceStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByServiceId(
    encounterId: string,
    encounterServiceId: string
  ): CancelablePromise<UnPagedEncounterServiceStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/next-states',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
    })
  }
}
