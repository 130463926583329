import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import GLOBAL from 'common/styles/global.scss'
import { getTimeFromDate, isoDateToDisplayFormatInUTC } from 'common/utils/DateUtils'
import Timeline from 'modules/shared/Timeline'
import { HealthConcernType } from 'recoil/healthConcerns'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef, HealthConcernControllerService, HealthConcernStateDTO } from 'services/openapi'
import { HEALTH_CONCERN_CERTAINTY_TYPES, MIS_VOID_REASON_TYPE } from 'services/terminologyConstants'

type TimelineProps = {
  healthConcern?: HealthConcernType
  date?: string
}

function Content(reason: string, comment: string) {
  return (
    <Box>
      {reason && (
        <Typography variant="body2">
          <span style={{ fontWeight: 'bold' }}>Reason: </span>
          {reason}
        </Typography>
      )}
      <Typography variant="body2">
        <span style={{ fontWeight: 'bold' }}>Comment: </span>
        {comment}
      </Typography>
    </Box>
  )
}

function TimelineTitle(props: TimelineProps) {
  if (props.date) {
    return (
      <div style={{ display: 'flex' }}>
        <Stack direction="column" style={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: `${GLOBAL.LINK_PRIMARY_COLOR}`,
              fontWeight: 'bold',
            }}
            variant="h3"
          >
            {isoDateToDisplayFormatInUTC(props.date)}
          </Typography>
          <Typography variant="body1">{getTimeFromDate(props.date)}</Typography>
        </Stack>
        <div> </div>
      </div>
    )
  } else {
    return null
  }
}

const HealthConcernTimeline = ({ healthConcern = undefined }: TimelineProps) => {
  const { clientId } = useParams()
  const [items, setItems] = useState<any>([])
  const { t } = useTranslation('common')
  const stateOptions = useRecoilValue(terminologySelector(HEALTH_CONCERN_CERTAINTY_TYPES))
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  useEffect(() => {
    const getStateHistory = async (clientId: string, healthConcernId: string) => {
      const response = await HealthConcernControllerService.getStateHistory(
        clientId,
        healthConcernId
      )
      const newItems = response.content?.map((history: HealthConcernStateDTO) => {
        const userName =
          history.auditInfo?.created?.user?.firstName +
          ' ' +
          history.auditInfo?.created?.user?.lastName
        const state = stateOptions?.find(
          (o: CodedRef) => o.code === (history.state as CodedRef)?.code
        )?.name as string
        const reason = voidReasonOptions?.find(
          (o: CodedRef) => o.code === (history.reason as CodedRef)?.code
        )?.name as string
        return {
          cardSubtitle: `${t('privacy-directives.changes-by')}` + userName,
          cardTitle: state,
          content: Content(reason || '', history.comment || ''),
          title: <TimelineTitle date={history.auditInfo?.created?.date} />,
        }
      })
      setItems(newItems)
    }
    if (clientId && healthConcern?.healthConcern.id) {
      setItems([])
      getStateHistory(clientId, healthConcern.healthConcern.id)
    }
  }, [clientId, stateOptions, t, voidReasonOptions, healthConcern])

  return (
    <div style={{ width: '100%' }}>
      {items.length > 0 ? (
        <Timeline
          activeItemIndex={0}
          itemWidth={500}
          items={items}
          mode="HORIZONTAL"
          showAllCardsHorizontal
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default HealthConcernTimeline
