import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { isEqual } from 'lodash'
import { useRecoilState } from 'recoil'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import { encounterServicesNotesState } from 'recoil/encounters'
import { isDirtyState } from 'recoil/isDirty'
import {
  CancelablePromise,
  EncounterNoteControllerService,
  EncounterNoteDTO,
} from 'services/openapi'
import EncounterServiceNote from './EncounterServiceNote'
import EncounterServiceNotesHeader from './EncounterServiceNotesHeader'
import EncounterNoteHistoryDialog from '../EncounterNotes/EncounterNoteHistoryDialog'
import { NoteHistoryToShow } from '../EncounterNotes/EncounterNotesSection'

export interface SortOptionType {
  id: 'asc' | 'desc'
  description: string
}

type EncounterServiceNotesProps = {
  encounterId: string
  serviceId: string
}

const ENTITY = 'Note'

const EncounterServiceNotes = forwardRef(
  ({ encounterId, serviceId }: EncounterServiceNotesProps, ref) => {
    const { handleApiError } = useErrorHandler()
    const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
    const [notes, setNotes] = useRecoilState(encounterServicesNotesState)
    const [currNotes, setCurrNotes] = useState<EncounterNoteDTO[]>(notes[serviceId] || [])
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const [noteHistoryToShow, setNoteHistoryToShow] = useState<NoteHistoryToShow | null>(null)
    const [sortOption, setSortOption] = useState<'asc' | 'desc'>('desc')

    const handleAddNote = useCallback(() => {
      setCurrNotes([{}, ...currNotes])
    }, [currNotes])

    const handleChangeNote = useCallback(
      (note: EncounterNoteDTO, index: number) => {
        if (!isDirty) setIsDirty(true)

        const nextNotes = [...currNotes]
        nextNotes[index] = note
        setCurrNotes(nextNotes)
      },
      [currNotes, isDirty, setIsDirty]
    )

    const handleChangeSortOption = useCallback(
      (option: 'asc' | 'desc') => {
        const notesWithoutId = currNotes.filter((note) => note.id === undefined)
        const notesWithId = currNotes
          .filter((note) => note.id)
          .sort((a, b) => {
            if (sortOption === 'desc')
              return (
                new Date(a?.auditInfo?.lastUpdated?.date as string).getTime() -
                new Date(b?.auditInfo?.lastUpdated?.date as string).getTime()
              )
            return (
              new Date(b?.auditInfo?.lastUpdated?.date as string).getTime() -
              new Date(a?.auditInfo?.lastUpdated?.date as string).getTime()
            )
          })
        setCurrNotes([...notesWithoutId, ...notesWithId])
        setSortOption(option)
      },
      [currNotes, sortOption]
    )

    const handleDeleteNote = useCallback(
      async (index: number) => {
        setDeleteIndex(-1)
        const noteId = currNotes[index]?.id
        if (noteId) {
          try {
            await EncounterNoteControllerService.deleteEncounterNote(encounterId, serviceId, noteId)
            currNotes.splice(index, 1)
            setNotes({ ...notes, [serviceId]: [...currNotes] })
          } catch (err) {
            handleApiError(err)
          }
        } else {
          currNotes.splice(index, 1)
          setCurrNotes([...currNotes])
        }
      },
      [currNotes, encounterId, handleApiError, notes, serviceId, setNotes]
    )

    const handleSaveNotes = useCallback(() => {
      const updateEncountersNotes = async (encounterId: string, serviceId: string) => {
        const promises: CancelablePromise<EncounterNoteDTO>[] = []
        currNotes.forEach((currNote) => {
          if (currNote.id) {
            const originalNote = notes[serviceId].find((note) => note.id === currNote.id)
            if (!isEqual(originalNote, currNote))
              promises.push(
                EncounterNoteControllerService.updateEncounterNote(
                  encounterId,
                  serviceId,
                  currNote.id,
                  currNote
                )
              )
          } else
            promises.push(
              EncounterNoteControllerService.createEncounterService1(
                encounterId,
                serviceId,
                currNote
              )
            )
        })
        try {
          const responses = await Promise.all(promises)
          if (responses.length > 0) {
            let newServiceNotes: EncounterNoteDTO[] = []
            if (notes[serviceId]) newServiceNotes = [...notes[serviceId]]
            responses.forEach((resp) => {
              const indexOfUpdatedNote = newServiceNotes.findIndex((note) => note.id === resp.id)
              if (indexOfUpdatedNote > -1) newServiceNotes[indexOfUpdatedNote] = resp
              else newServiceNotes.push(resp)
            })
            setNotes({ ...notes, [serviceId]: newServiceNotes })
          }
        } catch (error) {
          handleApiError(error)
        }
      }
      if (encounterId && serviceId) {
        setIsDirty(false)
        updateEncountersNotes(encounterId, serviceId)
      }
    }, [currNotes, encounterId, handleApiError, notes, serviceId, setNotes, setIsDirty])

    useImperativeHandle(
      ref,
      () => ({
        save() {
          handleSaveNotes()
        },
      }),
      [handleSaveNotes]
    )

    useEffect(() => {
      if (notes[serviceId] && notes[serviceId].length > 0)
        setCurrNotes(
          [...notes[serviceId]].sort(
            (a, b) =>
              new Date(b?.auditInfo?.lastUpdated?.date as string).getTime() -
              new Date(a?.auditInfo?.lastUpdated?.date as string).getTime()
          )
        )
      else setCurrNotes([])
    }, [notes, serviceId])

    return (
      <>
        <EncounterServiceNotesHeader
          onAddNote={handleAddNote}
          onChangeSortOption={handleChangeSortOption}
          sortOption={sortOption}
        />
        {currNotes?.length > 0 &&
          currNotes.map((note, index) => (
            <div key={`${note.id || 'new-note'}-${index}`}>
              <EncounterServiceNote
                encounterId={encounterId}
                index={index}
                note={note}
                onChangeNote={handleChangeNote}
                onDeleteNote={() => setDeleteIndex(index)}
                onViewHistory={() => setNoteHistoryToShow({ note, serviceId })}
                serviceId={serviceId}
              />
            </div>
          ))}
        <WarningDialog
          entity={ENTITY}
          onCancel={() => setDeleteIndex(-1)}
          onSave={() => handleDeleteNote(deleteIndex)}
          open={deleteIndex > -1}
          type={MODALS.DELETE_WARNING}
        />
        {noteHistoryToShow && (
          <EncounterNoteHistoryDialog
            encounterServiceId={noteHistoryToShow.serviceId}
            note={noteHistoryToShow.note}
            onClose={() => setNoteHistoryToShow(null)}
            openDialog={!!noteHistoryToShow}
          />
        )}
      </>
    )
  }
)
EncounterServiceNotes.displayName = 'EncounterServiceNotes'

export default EncounterServiceNotes
