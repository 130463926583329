import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISCheckbox from 'common/components/form/MISCheckbox'
import Loader from 'common/components/Loader'
import MISListContainer from 'common/components/MISListContainer'
import GLOBAL from 'common/styles/global.scss'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import { getPersonnelFullName } from 'modules/shared/clientUtils'
import useRunningNotes, { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { RunningNoteType } from 'recoil/runningNotes'
import RunningNote from './RunningNote'
import RunningNotesFilters from './RunningNotesFilter'
import './RunningNotes.scss'

const RunningNotes = () => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const [applyFilters, setApplyFilters] = useState(false)
  const [filters, setFilters] = useState<FilterProps[]>([])
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const { associatedStaff, providers, runningNotes, serviceTemplateIdsInUse, setRunningNotes } =
    useRunningNotes(clientId || '', filters, applyFilters)

  const handleSingleSelect = useCallback(
    (selected: boolean, noteId: string) => {
      const notes: RunningNoteType[] | undefined = runningNotes?.map((obj) => {
        return {
          ...obj,
          runningNote: {
            ...obj.runningNote,
            checked: noteId === obj.runningNote.id ? selected : obj.runningNote.checked,
          },
        }
      })
      if (notes) {
        setRunningNotes([...notes])
      }
      if (!selected) {
        setAllSelected(false)
      }
    },
    [runningNotes, setRunningNotes]
  )

  const handleSelectAll = (selected: boolean) => {
    const notes: RunningNoteType[] | undefined = runningNotes?.map((obj) => {
      return {
        ...obj,
        runningNote: {
          ...obj.runningNote,
          checked: selected,
        },
      }
    })
    if (notes) {
      setRunningNotes([...notes])
    }
    if (selected) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }

  const rowForm = useCallback(
    ({ entity, runningNote }: RunningNoteType) => {
      let primaryProvider = ''
      if (runningNote.encounterServiceId) {
        const providerId = associatedStaff.find(
          (o) => o.encounterServiceId === runningNote.encounterServiceId
        )?.providerId
        primaryProvider = getPersonnelFullName(providers.find((o) => o.id === providerId) || null)
      }
      return (
        <RunningNote
          entity={entity}
          handleSingleSelect={handleSingleSelect}
          primaryProvider={primaryProvider}
          runningNote={runningNote}
        />
      )
    },
    [associatedStaff, handleSingleSelect, providers]
  )

  return (
    <MISBaseContainer>
      <ClientRecordHeader />
      <Box className="running-notes-container">
        <Box className="running-notes-content">
          <SectionHeader title={t('running-notes.title')} />
          <Box sx={{ px: GLOBAL.MARGIN_MD }}>
            <Box sx={{ mb: 2, ml: 0 }}>
              <RunningNotesFilters
                applyFilters={applyFilters}
                filters={filters}
                providers={providers}
                serviceTemplateIdsInUse={serviceTemplateIdsInUse}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
              />
            </Box>
            <Box>
              {runningNotes ? (
                <>
                  <MISCheckbox
                    checked={allSelected}
                    onChange={(e) => handleSelectAll(e.target.checked as boolean)}
                    sx={{ display: 'inline', m: 0, p: 0 }}
                  />
                  <span>Select all</span>
                  <MISListContainer
                    emptyDataMessage="Results not found"
                    hideDividers
                    isDisabled
                    rowForm={rowForm}
                    rows={runningNotes
                      .filter((obj) => obj?.runningNote?.notes?.length > 0)
                      .sort((a, b) => {
                        // Use first note as latest, as child notes are already sorted
                        const aLatestDate = a?.runningNote.notes?.[0]?.lastUpdated as string
                        const bLatestDate = b?.runningNote.notes?.[0]?.lastUpdated as string
                        return +new Date(bLatestDate) - +new Date(aLatestDate)
                      })}
                  />
                </>
              ) : (
                <Loader />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </MISBaseContainer>
  )
}

export default RunningNotes
