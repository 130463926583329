/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessLine } from '../models/BusinessLine'
import type { BusinessLineTerse } from '../models/BusinessLineTerse'
import type { EntityAssociationCreationDTO } from '../models/EntityAssociationCreationDTO'
import type { PageBusinessLineTerse } from '../models/PageBusinessLineTerse'
import type { ProgramGroupAssociation } from '../models/ProgramGroupAssociation'
import type { ProgramGroupAssociationTerse } from '../models/ProgramGroupAssociationTerse'
import type { ProgramGroupDomainAssociation } from '../models/ProgramGroupDomainAssociation'
import type { ProgramGroupDomainAssociationTerse } from '../models/ProgramGroupDomainAssociationTerse'
import type { UnPagedBusinessLineWithPrograms } from '../models/UnPagedBusinessLineWithPrograms'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class BusinessLineControllerService {
  /**
   * @param id
   * @returns BusinessLineTerse OK
   * @throws ApiError
   */
  public static getProgramGroupById(id: string): CancelablePromise<BusinessLineTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @param terminateProgramDomainRelationships
   * @returns BusinessLineTerse OK
   * @throws ApiError
   */
  public static updateProgramGroup(
    id: string,
    requestBody: BusinessLine,
    terminateProgramDomainRelationships: boolean = false
  ): CancelablePromise<BusinessLineTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}',
      path: {
        id: id,
      },
      query: {
        terminateProgramDomainRelationships: terminateProgramDomainRelationships,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageBusinessLineTerse OK
   * @throws ApiError
   */
  public static listProgramGroups(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageBusinessLineTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns BusinessLineTerse OK
   * @throws ApiError
   */
  public static createProgramGroup(
    requestBody: BusinessLine
  ): CancelablePromise<BusinessLineTerse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/business-lines',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns ProgramGroupAssociationTerse OK
   * @throws ApiError
   */
  public static getProgramGroupAssociationsForProgramGroup(
    id: string
  ): CancelablePromise<Array<ProgramGroupAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}/programs',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns ProgramGroupAssociation OK
   * @throws ApiError
   */
  public static addProgramToProgramGroup(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<ProgramGroupAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}/programs',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns ProgramGroupDomainAssociationTerse OK
   * @throws ApiError
   */
  public static getProgramGroupDomainAssociationsForProgramGroup(
    id: string
  ): CancelablePromise<Array<ProgramGroupDomainAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}/domains',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns ProgramGroupDomainAssociation OK
   * @throws ApiError
   */
  public static addDomainToProgramGroup(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<ProgramGroupDomainAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/business-lines/{id}/domains',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param name
   * @param activeOnly
   * @returns BusinessLineTerse OK
   * @throws ApiError
   */
  public static searchProgramGroups(
    name: string,
    activeOnly: boolean = false
  ): CancelablePromise<Array<BusinessLineTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines/search',
      query: {
        activeOnly: activeOnly,
        name: name,
      },
    })
  }

  /**
   * @returns UnPagedBusinessLineWithPrograms OK
   * @throws ApiError
   */
  public static listAllBusinessLinesWithPrograms(): CancelablePromise<UnPagedBusinessLineWithPrograms> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/business-lines/all/programs',
    })
  }
}
