import { configureStore } from '@reduxjs/toolkit'
import appReducer from './reducers/app'
import businessLineReducer from './reducers/businessLine'
import chartingReducer from './reducers/charting'
import clientReducer from './reducers/client'
import permissionReducer from './reducers/permission'
import programReducer from './reducers/program'
import providerReducer from './reducers/provider'
import terminologyReducer from './reducers/terminology'
import userReducer from './reducers/user'

export const store = configureStore({
  reducer: {
    app: appReducer,
    businessLine: businessLineReducer,
    charting: chartingReducer,
    client: clientReducer,
    permission: permissionReducer,
    program: programReducer,
    provider: providerReducer,
    terminology: terminologyReducer,
    user: userReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
