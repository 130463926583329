import { Box, Skeleton } from '@mui/material'

const StatusCardLoader = () => {
  return (
    <Box sx={{ height: '160px', width: '100%' }}>
      {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
        <Skeleton animation="wave" height="20px" key={index} />
      ))}
    </Box>
  )
}
export default StatusCardLoader
