/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomainAssociationDTO } from '../models/DomainAssociationDTO'
import type { EncounterServiceTemplateAssociationDTO } from '../models/EncounterServiceTemplateAssociationDTO'
import type { EncounterServiceTemplateAssociationEntity } from '../models/EncounterServiceTemplateAssociationEntity'
import type { EncounterServiceTemplateGroupDTO } from '../models/EncounterServiceTemplateGroupDTO'
import type { FunctionalAreaAssociationDTO } from '../models/FunctionalAreaAssociationDTO'
import type { PageEncounterServiceTemplateGroupDTO } from '../models/PageEncounterServiceTemplateGroupDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterServiceTemplateGroupControllerService {
  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterServiceTemplateGroupDTO OK
   * @throws ApiError
   */
  public static listEncounterServiceTemplateGroups(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterServiceTemplateGroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string OK
   * @throws ApiError
   */
  public static createEncounterServiceTemplateGroups(
    requestBody: Array<EncounterServiceTemplateGroupDTO>
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create an EncounterServiceTemplate
   * @param requestBody
   * @returns EncounterServiceTemplateGroupDTO OK
   * @throws ApiError
   */
  public static createEncounterServiceTemplateGroup(
    requestBody: EncounterServiceTemplateGroupDTO
  ): CancelablePromise<EncounterServiceTemplateGroupDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get an EncounterServiceTemplateGroup by id
   * @param id
   * @returns EncounterServiceTemplateGroupDTO OK
   * @throws ApiError
   */
  public static organizationgetById1(
    id: string
  ): CancelablePromise<EncounterServiceTemplateGroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * Update an EncounterServiceTemplateGroup
   * The properties of the EncounterServiceTemplate are specified in the JSON request body.
   * @param id
   * @param requestBody
   * @returns EncounterServiceTemplateGroupDTO OK
   * @throws ApiError
   */
  public static updateEncounterServiceTemplateGroup(
    id: string,
    requestBody: EncounterServiceTemplateGroupDTO
  ): CancelablePromise<EncounterServiceTemplateGroupDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns EncounterServiceTemplateAssociationDTO OK
   * @throws ApiError
   */
  public static getTemplateAssociationsForTemplateGroup(
    id: string
  ): CancelablePromise<Array<EncounterServiceTemplateAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{id}/encounter-service-templates',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns EncounterServiceTemplateAssociationDTO OK
   * @throws ApiError
   */
  public static updateTemplateGroupAssociation(
    id: string,
    requestBody: EncounterServiceTemplateAssociationEntity
  ): CancelablePromise<EncounterServiceTemplateAssociationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{id}/encounter-service-templates',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create an EncounterServiceTemplateAssociation
   * @param id
   * @param requestBody
   * @returns EncounterServiceTemplateAssociationDTO OK
   * @throws ApiError
   */
  public static addEncounterServiceTemplateAssociation(
    id: string,
    requestBody: EncounterServiceTemplateAssociationDTO
  ): CancelablePromise<EncounterServiceTemplateAssociationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{id}/encounter-service-templates',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Add FunctionalArea Associations to existing EncounterServiceTemplateGroup
   * An array of functionalArea associations is provided the JSON request body.
   * @param groupId
   * @param requestBody
   * @returns FunctionalAreaAssociationDTO OK
   * @throws ApiError
   */
  public static addFunctionalAreaAssociations1(
    groupId: string,
    requestBody: Array<FunctionalAreaAssociationDTO>
  ): CancelablePromise<Array<FunctionalAreaAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{groupId}/functional-areas',
      path: {
        groupId: groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Add Domain Associations to existing EncounterServiceTemplateGroup
   * An array of domain associations is provided the JSON request body.
   * @param groupId
   * @param requestBody
   * @returns DomainAssociationDTO OK
   * @throws ApiError
   */
  public static addDomainAssociations1(
    groupId: string,
    requestBody: Array<DomainAssociationDTO>
  ): CancelablePromise<Array<DomainAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/{groupId}/domains',
      path: {
        groupId: groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get paged list of EncounterServiceTemplate with optional search params
   * The name param allows searching for items with a name containing name. Use the  param to return only items where effectiveAt is within the effective date rang.eAll items are returned if effectiveAt is not specified, including terminated items.
   * @param name
   * @param description
   * @param templateName
   * @returns PageEncounterServiceTemplateGroupDTO OK
   * @throws ApiError
   */
  public static organizationgetPagedList1(
    name?: string,
    description?: string,
    templateName?: string
  ): CancelablePromise<PageEncounterServiceTemplateGroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/encounter-service-template-groups/search',
      query: {
        name: name,
        description: description,
        templateName: templateName,
      },
    })
  }
}
