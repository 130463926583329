import { SelectTreeViewDataType } from 'common/components/form/MISSelectTreeView'
import { ClientLanguage, CodedConceptDto, PersonnelLanguageDTO } from 'services/openapi'
import { ErrorType } from '../utils'

export interface LanguageDataType {
  data: ClientLanguage | PersonnelLanguageDTO
  isCollapsed: boolean
}

export interface LanguageDataRowType {
  rows: LanguageDataType[]
}

export const getLanguageDataForTreeView = (
  languageNames: CodedConceptDto[]
): SelectTreeViewDataType[] => {
  const parentLanguages: SelectTreeViewDataType[] = []
  const childLanguages: SelectTreeViewDataType[] = []
  languageNames.forEach(({ attributes, code, id, name }) => {
    if (attributes?.[0]?.refCodedConcept?.id) {
      childLanguages.push({
        children: [],
        code: code as string,
        id: id as string,
        name: name as string,
        parentId: attributes?.[0]?.refCodedConcept?.id,
      })
    } else {
      parentLanguages.push({
        children: [],
        code: code as string,
        id: id as string,
        name: name as string,
        parentId: undefined,
      })
    }
  })

  parentLanguages.sort((a, b) => a.name.localeCompare(b.name))
  childLanguages.sort((a, b) => a.name.localeCompare(b.name))
  const result: SelectTreeViewDataType[] = []
  parentLanguages.forEach((parentLanguage) => {
    const map = new Map<string, SelectTreeViewDataType>()
    const currArr = [parentLanguage, ...childLanguages]
    for (const language of currArr) {
      language.children = []
      map.set(language.id, language)
    }
    for (const language of currArr) {
      if (language?.parentId && map.get(language.parentId)) {
        map.get(language.parentId)?.children?.push(language)
      }
    }
    result.push(parentLanguage)
  })
  return result
}

export const validateLanguages = (
  languageData: LanguageDataRowType,
  requiredError: string,
  uniqueError: string
) => {
  const errors: ErrorType[] = []
  if (languageData.rows.length > 0) {
    const languageMap = new Map<string, number>()
    for (let i = 0; i < languageData.rows.length; i += 1) {
      if (!languageData.rows[i].data.language) {
        if (!languageData.rows[i].data.language)
          errors.push({
            field: `language-${i}`,
            message: requiredError,
          })
      } else {
        const code = languageData.rows[i].data.language?.code
        if (code) {
          if (languageMap.get(code)) {
            errors.push({
              field: `language-${i}`,
              message: uniqueError,
            })
          } else languageMap.set(code, 1)
        }
      }
    }
  }
  return errors
}
