export type PermissionType = {
  actions: string[]
  entity: string
  id: string
  name: string
}

const TEMP_PERMISSIONS: PermissionType[] = [
  {
    actions: ['read', 'create', 'update'],
    entity: 'PROGRAM',
    id: '00000000-0000-0000-0000-00000000001',
    name: 'COMMUNITY_HEALTH',
  },
  {
    actions: ['read', 'create', 'update'],
    entity: 'CLIENT',
    id: '00000000-0000-0000-0000-00000000002',
    name: 'CLIENT',
  },
  {
    actions: ['read', 'create', 'update'],
    entity: 'ENCOUNTER',
    id: '00000000-0000-0000-0000-00000000003',
    name: 'ENCOUNTER',
  },
]

export default TEMP_PERMISSIONS
