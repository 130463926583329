import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

export const selectClientId = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientDetails?.id
)

export const selectClientAlerts = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientAlerts
)

export const selectClientDetails = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientDetails
)

export const selectClientLanguages = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientLanguages
)

export const selectClientRelationships = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientRelationships
)

export const selectClientParentRelationships = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientParentRelationships
)

export const selectClientBirthDetails = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientBirthDetails
)

export const selectClientDeathDetails = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientDeathDetails
)

export const selectClientGenderDetails = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientGenderDetails
)

export const selectClientEducation = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientEducation
)

export const selectClientPrivacyDirectives = createSelector(
  [(state: RootState) => state.client],
  (client) => client.clientPrivacyDirectives
)

export const selectGenderIdentityTags = createSelector(
  [(state: RootState) => state.client],
  (client) => client.genderIdentityTags
)
