import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import type { Blocker, History, Transition } from 'history'

/*
blocker hook was removed from react-router-dom v6, so creating custom navigation blocker.
when this hook is called if invokes the blocker function when isDirty=true.
and in the blocker function passed as parameter, which controls the display warning modal.
*/
const useNavigationBlocker = (blocker: Blocker, isDirty = false) => {
  const navigator = useContext(NavigationContext).navigator as History

  useEffect(() => {
    if (!isDirty) return

    // here we block the navigation. If user closes the browser or
    // types other root then we invoke the browser's prompt to save
    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, isDirty])
}

export default useNavigationBlocker
