import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProgramTerse } from 'services/openapi'

export interface ProgramState {
  programs?: ProgramTerse[]
}

const initialState: ProgramState = {}

export const programSlice = createSlice({
  initialState,
  name: 'program',
  reducers: {
    setPrograms: (state, action: PayloadAction<ProgramTerse[] | undefined>) => {
      state.programs = action.payload
    },
  },
})

export const { setPrograms } = programSlice.actions

export default programSlice.reducer
