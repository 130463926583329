/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientGenderDetailDTO } from '../models/ClientGenderDetailDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientGenderDetailControllerService {
  /**
   * Returns gender detail for client
   * Returns a gender detail for client
   * @param clientId
   * @returns ClientGenderDetailDTO OK
   * @throws ApiError
   */
  public static getGenderDetail(clientId: string): CancelablePromise<ClientGenderDetailDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/gender-details',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Update client gender details
   * update client gender details
   * @param clientId
   * @param requestBody
   * @returns ClientGenderDetailDTO OK
   * @throws ApiError
   */
  public static updateGenderDetail(
    clientId: string,
    requestBody: ClientGenderDetailDTO
  ): CancelablePromise<ClientGenderDetailDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/gender-details',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Returns gender identity tags
   * Returns all gender identity tags already used in the database
   * @param tagText
   * @returns string OK
   * @throws ApiError
   */
  public static getGenderIdentityTags(tagText?: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/gender-identity-tags',
      query: {
        tagText: tagText,
      },
    })
  }
}
