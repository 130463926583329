/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationStatusResponse = {
  status?: OrganizationStatusResponse.status
  message?: string
  errorCode?: string
  timestamp?: string
  exception?: string
  messages?: Array<string>
}

export namespace OrganizationStatusResponse {
  export enum status {
    OK = 'OK',
    FAIL = 'FAIL',
  }
}
