/**
 * Returns the realm, client id and tenant id of the tenant corresponding to the specific customer url
 */
export const getCurrentTenant = () => {
  const urlPathname = window.location.pathname
  const urlParts = urlPathname.split('/')

  // Get the tenant id from the url
  const clientInformation = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: urlParts[1] || 'tenant2',
  }

  return clientInformation
}
