import { DateTime } from 'luxon'

export const buildAssociationMapUtil = (
  existingAssociaitons,
  entities,
  entityIdFieldName,
  associationsSetter,
  entitiesSetter
) => {
  let associationMap = new Map()
  let otherEntityIndices = []

  existingAssociaitons.forEach((association) => {
    //First, we find the index of the associated entity within the entity array, and then push that index into a list
    let newAssociation = { ...association }
    var otherEntityIndex = entities.findIndex((obj) => {
      return obj.id === association[entityIdFieldName]
    })

    if (!otherEntityIndices.includes(otherEntityIndex)) {
      otherEntityIndices.push(otherEntityIndex)
    }

    //We then build out the new association from the current association
    newAssociation.otherEntity = { ...entities[otherEntityIndex] }
    newAssociation.startDate = association.startDate
      ? DateTime.fromISO(association.startDate)
      : null
    newAssociation.endDate = association.endDate ? DateTime.fromISO(association.endDate) : null

    //then we either add the new association to an existing map, or create a new map with the new association as the first entry in the list
    if (associationMap.has(association[entityIdFieldName])) {
      let associationList = [...associationMap.get(association[entityIdFieldName])]
      associationList.push(newAssociation)
      associationMap.set(association[entityIdFieldName], associationList)
    } else {
      associationMap.set(association[entityIdFieldName], [newAssociation])
    }
  })
  // we remove the existing associations from the entities tab since we dont want them to show up again in selection
  //Need to remove in reverse order, else indices will change as you iterate and remove
  const entityArr = [...entities]
  otherEntityIndices
    .sort((a, b) => (a < b ? 1 : -1))
    .forEach((i) => {
      entityArr.splice(i, 1)
    })
  entitiesSetter(entityArr)
  associationsSetter(associationMap)
}

export const saveAssociations = (
  associationsToSave,
  updateCallback,
  addCallback,
  setOpenLinkModal,
  buildAssociationsMap,
  handleApiError,
  handleApiSuccess,
  selectedRow
) => {
  let saveAssociationPromises = []
  let isError = false
  associationsToSave.forEach((association, index) => {
    if (association.id) {
      let associationToUpdate = {
        endDate: association.endDate,
        id: association.id,
        // TODO: IDHE-724 rename id1 and id2 when IDHE-719 is done
        id1: selectedRow.id,
        id2: association.otherEntityId,
        startDate: association.startDate,
      }
      saveAssociationPromises[index] = updateCallback(association.id, associationToUpdate)
    } else {
      saveAssociationPromises[index] = addCallback(selectedRow.id, association)
    }
    saveAssociationPromises[index].then(
      () => {
        // don't need to update anything here, will be processed by refetch.
      },
      (error) => {
        isError = true
        handleApiError(error)
      }
    )

    Promise.allSettled(saveAssociationPromises).then(() => {
      if (!isError) {
        handleApiSuccess()
        setOpenLinkModal(false)
      }
      buildAssociationsMap()
    })
  })
}
