import { useCallback, useEffect, useState } from 'react'
import { AuthProvider, AuthProviderProps } from 'react-oidc-context'
import axios from 'axios'
import { WebStorageStateStore } from 'oidc-client-ts'
import Loader from 'common/components/Loader'
import ability from 'core/helpers/security/ability'
import { AbilityContext } from 'core/helpers/security/can'
import RouterApp from 'core/routing/RouterApp'
import 'common/styles/reset.scss'
import InitialUsernamePage from './routing/InitialUsernamePage'

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

const oidcConfig: AuthProviderProps = {
  authority: '',
  client_id: '',
  onSigninCallback,
  redirect_uri: '',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const App = () => {
  const [oiconfig, setOiconfig] = useState(oidcConfig)
  const [isLoading, setIsLoading] = useState(true)
  const [username, setUsername] = useState('')
  const [flip, setFlip] = useState(false)

  const setupOiConfig = useCallback((tenantId: string, username: string | null) => {
    axios
      .get(process.env.REACT_APP_API_URL + '/public/tenant-issuer/' + tenantId)
      .then((data) => {
        setOiconfig((prevState) => {
          return {
            ...prevState,
            authority: data.data?.issuerUrl,
            client_id: process.env.REACT_APP_CLIENT_ID as string,
            redirect_uri: `${process.env.REACT_APP_DEPLOYMENT_HOST}/${tenantId}/admin/dashboard/`,
          }
        })
        setIsLoading(false)
        if (username !== null) {
          localStorage.setItem('tenantId', tenantId)
          setUsername(username)
        } else {
          setTimeout(() => {
            setFlip(true)
          }, 10)
        }
      })
      .catch(() => {
        // TODO error handling for auth fetching is not implemented
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (oiconfig.authority && localStorage.getItem('login_initiated') === null) {
      localStorage.setItem('login_initiated', 'true')
      setFlip(true)
    }
  }, [oiconfig.authority])

  useEffect(() => {
    if (localStorage.getItem('login_initiated') !== null) {
      setupOiConfig(localStorage.getItem('tenantId') as string, null)
    }
  }, [setupOiConfig])

  useEffect(() => {
    if (localStorage.getItem('logged_out') !== null) {
      setUsername(localStorage.getItem('logged_out') as string)
      localStorage.removeItem('logged_out')
      setupOiConfig(localStorage.getItem('tenantId') as string, null)
    }
  }, [setupOiConfig])

  if (localStorage.getItem('login_initiated') === null && !flip) {
    return <InitialUsernamePage onLogin={setupOiConfig} />
  }

  if (isLoading && localStorage.getItem('login_initiated') === null) {
    return <Loader fullScreen />
  }

  return (
    <>
      {localStorage.getItem('login_initiated') !== null && flip && (
        <AuthProvider {...oiconfig}>
          <AbilityContext.Provider value={ability}>
            <RouterApp username={username} />
          </AbilityContext.Provider>
        </AuthProvider>
      )}
    </>
  )
}

export default App
