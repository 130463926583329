import { useEffect, useState } from 'react'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { chartingEntryIdState } from 'recoil/lastupdated'
import { terminologySelector } from 'recoil/terminology'
import { ChartingEntryDTO, PersonnelDTO } from 'services/openapi'
import { MIS_ENCOUNTER_SERVICE_STATE } from 'services/terminologyConstants'

type ChartingEntryListItemProps = {
  activeChartingEntryId: string
  chartingEntry: ChartingEntryDTO
  providers: PersonnelDTO[]
  onClick: (event: unknown) => void
}

type ChartingEntriesPanelProps = {
  charts: ChartingEntryDTO[]
  providers: PersonnelDTO[]
  onSelect: (entry: ChartingEntryDTO, duplicate?: boolean) => void
}

const ChartingEntryListItem = ({
  activeChartingEntryId,
  chartingEntry,
  onClick,
  providers,
}: ChartingEntryListItemProps) => {
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
  const provider = providers.find((provider) => provider.id === chartingEntry.primaryProvider)
  const providerName = provider ? evaluateLabelUtil(provider?.names) : ''
  return (
    <ListItem
      onClick={onClick}
      sx={{
        backgroundColor:
          chartingEntry.id === activeChartingEntryId
            ? GLOBAL.INNER_LEFT_SIDEBAR_BACKGROUND
            : GLOBAL.WHITE,
        borderBottom: `1px solid ${GLOBAL.BUTTON_DISABLED_BG_COLOR}`,
        cursor: 'pointer',
        px: 2,
        py: 1,
        width: '100%',
      }}
    >
      <Typography sx={{ ml: 1 }} variant="body2">
        <strong>
          {stateOptions.find((opt) => chartingEntry?.status?.code === opt.code)?.name}
        </strong>
        <br />
        {chartingEntry?.serviceDateTime
          ? isoDateToDisplayFormat(new Date(chartingEntry?.serviceDateTime).toISOString())
          : ''}
        , {providerName}
      </Typography>
    </ListItem>
  )
}

const ChartingEntriesPanel = ({ charts, onSelect, providers }: ChartingEntriesPanelProps) => {
  const lastUpdatedChartingEntryId = useRecoilValue(chartingEntryIdState)
  const [activeChartingEntryId, setActiveChartingEntryId] = useState<string>('')

  useEffect(() => {
    const activeId = lastUpdatedChartingEntryId || charts?.[0]?.id
    if (activeId) {
      setActiveChartingEntryId(activeId)
    }
  }, [lastUpdatedChartingEntryId, charts])

  return (
    <Grid container sx={{ height: '85%', overflowY: 'auto' }}>
      <Grid item xs={12}>
        <List dense sx={{ p: 0 }}>
          {charts?.map((chart: ChartingEntryDTO) => {
            return (
              <Box key={chart.id}>
                <ChartingEntryListItem
                  activeChartingEntryId={activeChartingEntryId}
                  chartingEntry={chart}
                  onClick={(event: any) => {
                    event.preventDefault()
                    if (chart.id) {
                      setActiveChartingEntryId(chart.id)
                    }
                    onSelect(chart)
                  }}
                  providers={providers}
                />
              </Box>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default ChartingEntriesPanel
