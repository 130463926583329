import { ElementType, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, TypographyOwnProps } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBTypographyProps = {
  data?: TFormBuilderComponentData
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBTypography = ({ data, onDelete, onSave, preview }: FBTypographyProps) => {
  const { t } = useTranslation('common')

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(
    data || { children: 'Typography' }
  )
  const [configMode, setConfigMode] = useState(false)

  return (
    <>
      {preview ? (
        <Typography {...(data as TypographyOwnProps)} />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <Typography {...(data as TypographyOwnProps)} />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.typography.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.children}
                label={t('charting.form-builder.text')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, children: e.target.value }
                      : { children: e.target.value }
                  )
                }
              />
              <MISSelectDropdown
                helperText={data?.helperText as string}
                id="form-builder-typography-component"
                label={t('charting.form-builder.component')}
                onChange={(e) =>
                  setConfigData({ ...configData, component: e.target.value as ElementType })
                }
                options={['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'root'].map(
                  (each) => ({
                    label: each,
                    value: each,
                  })
                )}
                value={configData?.component}
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBTypography
