/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdheServiceDTO } from '../models/IdheServiceDTO'
import type { PageIdheServiceDTO } from '../models/PageIdheServiceDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class IdheServiceControllerService {
  /**
   * @param id
   * @returns IdheServiceDTO OK
   * @throws ApiError
   */
  public static getServiceById(id: string): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns IdheServiceDTO OK
   * @throws ApiError
   */
  public static updateService(
    id: string,
    requestBody: IdheServiceDTO
  ): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/services/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageIdheServiceDTO OK
   * @throws ApiError
   */
  public static getAllServices(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageIdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns IdheServiceDTO OK
   * @throws ApiError
   */
  public static createService(requestBody: IdheServiceDTO): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/services',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param activeOnly
   * @param name
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageIdheServiceDTO OK
   * @throws ApiError
   */
  public static searchServices(
    activeOnly: boolean = true,
    name?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageIdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services/search',
      query: {
        activeOnly: activeOnly,
        name: name,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
