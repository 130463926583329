/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePrivacyDirectiveOverrideDTO } from '../models/PagePrivacyDirectiveOverrideDTO'
import type { PrivacyDirectiveOverrideDTO } from '../models/PrivacyDirectiveOverrideDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PrivacyDirectiveOverrideControllerService {
  /**
   * Returns a paged list of PrivacyDirectiveOverride resources
   * Returns page list as specified by clientId and filters
   * @param clientId
   * @param activeOnly
   * @param entityId
   * @param entityDirectivesToInclude
   * @param _with
   * @returns PagePrivacyDirectiveOverrideDTO OK
   * @throws ApiError
   */
  public static getDirectiveOverrides(
    clientId: string,
    activeOnly: boolean = true,
    entityId?: Array<string>,
    entityDirectivesToInclude: string = 'CLIENT',
    _with?: Array<string>
  ): CancelablePromise<PagePrivacyDirectiveOverrideDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/privacy-directive-overrides',
      path: {
        clientId: clientId,
      },
      query: {
        activeOnly: activeOnly,
        entityId: entityId,
        entityDirectivesToInclude: entityDirectivesToInclude,
        with: _with,
      },
    })
  }

  /**
   * Update client directive overrides
   * update client directive overrides
   * @param clientId
   * @param requestBody
   * @returns PrivacyDirectiveOverrideDTO OK
   * @throws ApiError
   */
  public static createDirectiveOverride(
    clientId: string,
    requestBody: PrivacyDirectiveOverrideDTO
  ): CancelablePromise<PrivacyDirectiveOverrideDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/privacy-directive-overrides',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
