import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISChip from 'common/components/form/MISChip'
import GLOBAL from 'common/styles/global.scss'
import { getNameFromId } from 'common/utils/CodedConceptUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { programsAtom } from 'recoil/atoms'
import { encounterIdState } from 'recoil/lastupdated'
import { terminologySelector } from 'recoil/terminology'
import { EncounterControllerService, EncounterDTO, EncounterServiceDTO } from 'services/openapi'
import { MIS_ENCOUNTER_STATE } from 'services/terminologyConstants'

type EncountersAccordionListItemProps = {
  activeEncounterId: string
  encounter: EncounterDTO
  onClick: () => void
}

type EncountersListPanelProps = {
  encounters: EncounterDTO[] | undefined
  onClick: (encounter: EncounterDTO) => void
}

const EncountersAccordionListItem = ({
  activeEncounterId,
  encounter,
  onClick,
}: EncountersAccordionListItemProps) => {
  const programOptions = useRecoilValue(programsAtom)
  const encounterStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_STATE))

  const primaryServiceIndex = useMemo(
    () =>
      encounter?.encounterServices?.findIndex(
        (service: EncounterServiceDTO) => service.isPrimaryService
      ) || 0,
    [encounter?.encounterServices]
  )
  const primaryService = useMemo(
    () => encounter?.encounterServices?.[primaryServiceIndex],
    [encounter?.encounterServices, primaryServiceIndex]
  )

  return (
    <ListItem
      onClick={onClick}
      sx={{
        backgroundColor:
          encounter.id === activeEncounterId ? GLOBAL.INNER_LEFT_SIDEBAR_BACKGROUND : '',
        borderBottom: `1px solid ${GLOBAL.BUTTON_DISABLED_BG_COLOR}`,
        cursor: 'pointer',
        px: 2,
        py: 1,
        width: '100%',
      }}
    >
      <Typography variant="body2">
        {primaryService?.encounterServiceDate}
        {encounter.associations?.state && (
          <MISChip
            color="primary"
            label={
              encounterStateOptions.find((opt) => encounter.associations?.state?.code === opt.code)
                ?.name
            }
            sx={{
              height: `20px !important`,
              mb: GLOBAL.MARGIN_XXS,
              ml: GLOBAL.MARGIN_XS,
              p: 0,
            }}
          />
        )}
        <br />
        <strong>{primaryService?.synopsis}</strong>
        <br />
        {getNameFromId(programOptions, primaryService?.programId)}
      </Typography>
    </ListItem>
  )
}

const EncountersListPanel = ({ encounters, onClick }: EncountersListPanelProps) => {
  const { handleApiError } = useErrorHandler()
  const lastUpdatedEncounterId = useRecoilValue(encounterIdState)

  const [activeEncounterId, setActiveEncounterId] = useState<string>('')

  const findEncounterById = useCallback(
    (itemId: string) => {
      EncounterControllerService.encountersgetById(itemId)
        .then((result: EncounterDTO) => {
          onClick(result)
        })
        .catch((error) => {
          handleApiError(error)
        })
    },
    [handleApiError, onClick]
  )

  useEffect(() => {
    const activeId = lastUpdatedEncounterId || encounters?.[0]?.id
    if (activeId) {
      setActiveEncounterId(activeId)
    }
  }, [lastUpdatedEncounterId, encounters])

  return (
    <Grid container>
      <Grid item xs={12}>
        <List dense sx={{ p: 0 }}>
          {encounters?.map((encounter: EncounterDTO) => (
            <Box key={encounter.id}>
              <EncountersAccordionListItem
                activeEncounterId={activeEncounterId}
                encounter={encounter}
                onClick={() => {
                  if (encounter?.id) {
                    setActiveEncounterId(encounter.id)
                    if (encounter.id !== activeEncounterId) {
                      findEncounterById(encounter?.id)
                    }
                  }
                }}
              />
            </Box>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default EncountersListPanel
