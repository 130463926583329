import { ROUTE_KEYS } from '../constants'

export interface BreadbrumbType {
  id: number
  key: string
  link: string
  value: string
}

export const getAdditionalBreadcrumbs = (key: string): BreadbrumbType[] => {
  switch (key) {
    case ROUTE_KEYS.AGREEMENTS_CONFIDENTIAL:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 15,
          key: 'AGREEMENTSCONFIDENTIAL',
          link: '/admin/agreements/confidential',
          value: 'Confidentiality Reminder',
        },
      ]
    case ROUTE_KEYS.AGREEMENTS_ACCEPTABLE_USE:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 16,
          key: 'ACCEPTABLEUSE',
          link: '/admin/governance-agencies/edit/:id',
          value: 'Acceptable Use',
        },
      ]
    case ROUTE_KEYS.CLIENTS:
      return [
        {
          id: 4,
          key: 'CLIENT',
          link: '/clients',
          value: 'Search',
        },
      ]
    case ROUTE_KEYS.CLIENT_CREATE:
      return [
        {
          id: 2,
          key: 'SEARCHRESULTS',
          link: '/clients',
          value: 'Search Results',
        },
        {
          id: 5,
          key: 'CREATECLIENT',
          link: '/clients/create',
          value: 'Add Client',
        },
      ]
    case ROUTE_KEYS.CLIENT_RECORD:
      return [
        {
          id: 2,
          key: 'SEARCHRESULTS',
          link: '/clients',
          value: 'Search Results',
        },
        {
          id: 7,
          key: 'CLIENTRECORD',
          link: '/clients/client-record/:clientId',
          value: 'Client Record',
        },
      ]
    case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATES:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 9,
          key: 'ENCOUNTERTEMPLATES',
          link: '/encounter/service-templates',
          value: 'Service Templates',
        },
      ]
    case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_CREATE:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 10,
          key: 'ADDENCOUNTERTEMPLATES',
          link: '/encounter/service-templates/create',
          value: 'Add Service Templates',
        },
      ]
    case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_EDIT:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 11,
          key: 'EDITENCOUNTERTEMPLATES',
          link: '/encounter/service-templates',
          value: 'Edit Service Templates',
        },
      ]
    case ROUTE_KEYS.GOVERNANCE_AGENCIES:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 12,
          key: 'GOVERNANCEAGENCIES',
          link: '/admin/governance-agencies',
          value: 'Governance Agency List',
        },
      ]
    case ROUTE_KEYS.GOVERNANCE_AGENCY_CREATE:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 13,
          key: 'ADDGOVERNANCEAGENCY',
          link: '/admin/governance-agencies',
          value: 'Add Governance Agency',
        },
      ]
    case ROUTE_KEYS.GOVERNANCE_AGENCY_EDIT:
      return [
        {
          id: 8,
          key: 'ADMINISTRATION',
          link: '/admin',
          value: 'Administration',
        },
        {
          id: 14,
          key: 'EDITGOVERNANCEAGENCY',
          link: '/admin/governance-agencies/edit/:id',
          value: 'Edit Governance Agency',
        },
      ]
    default:
      return []
  }
}
