import { atom } from 'recoil'
import { Client } from 'services/openapi'

export const clientSearchFiltersState = atom<{ [key: string]: any } | null>({
  default: null,
  key: 'clientSearchFiltersState',
})

export const clientSearchResultsState = atom<Client[] | null>({
  default: null,
  key: 'clientSearchResultsState',
})
