import { useCallback, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import loginBgImage from 'assets/images/login-artwork1.jpg'
import logoImg from 'assets/images/login-logo.png'
import MISTextField from 'common/components/form/MISTextField'
import { UserControllerService } from 'services/openapi'

const InitialUsernamePage = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()
      if (!username) {
        setError('Username is required')
        return
      }

      try {
        const res = await UserControllerService.findTenantByUsername(username.trim())
        if (res) {
          onLogin(res, username)
        }
      } catch (err) {
        setError('Username not found')
      }
    },
    [onLogin, username]
  )

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleLogin(e)
      }
    },
    [handleLogin]
  )

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={6} xs={12}>
        <Box
          style={{
            backgroundImage: ` url(${logoImg}),url(${loginBgImage})`,
            backgroundPosition: '30px 30px, center',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: '158px 50px, cover',
            height: '100%',
            position: 'relative',
          }}
        />
      </Grid>

      <Grid alignItems="center" display="flex" item justifyContent="center" md={6} xs={12}>
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            paddingBottom: '130px',
            width: '100%',
          }}
        >
          <Typography
            color="black"
            component="h1"
            fontFamily="'Inter-SemiBold', 'Inter Semi Bold', 'Inter', sans-serif"
            fontWeight="700"
          >
            Sign in
          </Typography>
          <Box
            style={{
              alignItems: 'center',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: '32px',
              width: '300px',
            }}
          >
            <MISTextField
              autoComplete="username"
              autoFocus
              color="primary"
              fullWidth
              id="username"
              label="User ID"
              margin="normal"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              required
              value={username}
              variant="outlined"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                onClick={handleLogin}
                sx={{
                  '&:hover': {
                    backgroundColor: '#b64f04',
                  },
                  backgroundColor: '#c50',
                  color: 'white',
                  margin: '24px 0 16px',
                  textTransform: 'none',
                  width: '25%',
                }}
                type="submit"
                variant="contained"
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default InitialUsernamePage
