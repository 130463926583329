import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import MISTable from 'common/components/table/MISTable'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { FormSchemaControllerService, TemplateFormAssociationEntity } from 'services/openapi'

const AddFormToSTDialog = ({
  currentAssociations,
  entityName,
  onCloseCallback,
  onSaveCallback,
  openDialog,
}: any) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()

  const [searchText, setSearchText] = useState('')

  const [order] = useState('asc')
  const [orderBy] = useState('name')
  const [forms, setForms] = useState([])
  const [formSearchResults, setFormSearchResults] = useState<any>([])
  const [searchFormText, setSearchFormText] = useState('')
  const [selectedFormNames, setSelectedFormNames] = useState<any>([])

  const handleClose = () => {
    onCloseCallback()
  }

  const onFormSelectChange = useCallback(
    (selected: any) => {
      if (!selected) {
        setSelectedFormNames([])
        return
      }

      const selectedFiltered = formSearchResults.filter((item: any, itemIndex: number) => {
        return selected.includes(item.id)
      })
      setSelectedFormNames(selectedFiltered)
    },
    [formSearchResults]
  )

  const handleSaveClicked = () => {
    const saveForms = selectedFormNames.map((form: any) => {
      return {
        formName: form.name,
        formOrder: null,
        formSemanticVersion: form.version,
      }
    })

    onSaveCallback(saveForms)
  }

  // Encounter search
  const OnSearchClick = () => {
    setSearchText(searchFormText)
  }

  const handleSearchForms = useCallback(() => {
    FormSchemaControllerService.searchFormSchema(
      '%' + searchText + '%',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      'OR'
    )
      .then((result) => {
        const remainingForms = result.content?.filter(
          (form) =>
            !currentAssociations?.some(
              (current: TemplateFormAssociationEntity) => current.formName === form.name
            )
        )
        setFormSearchResults(remainingForms)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [handleApiError, searchText, currentAssociations])

  const handleInitSearchForms = useCallback(() => {
    FormSchemaControllerService.searchFormSchema('%%')
      .then((result) => {
        const remainingForms = result.content?.filter(
          (form) =>
            !currentAssociations?.some(
              (current: TemplateFormAssociationEntity) => current.formName === form.name
            )
        )
        setFormSearchResults(remainingForms)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [handleApiError, currentAssociations])

  const handleSearchTextChange = (event: any) => {
    setSearchFormText(event.target.value)
  }

  const encounterTableHeaders = useMemo(
    () => [
      {
        disablePadding: true,
        id: 'name',
        label: 'Template Name',
        numeric: false,
        width: '70%',
      },
      {
        disablePadding: false,
        id: 'version',
        label: 'Version',
        numeric: false,
        width: '30%',
      },
    ],
    []
  )

  useEffect(() => {
    setForms(formSearchResults ? formSearchResults : [])
  }, [formSearchResults, onFormSelectChange])

  useEffect(() => {
    handleInitSearchForms()
  }, [handleInitSearchForms])

  useEffect(() => {
    if (searchText?.length >= 3) {
      handleSearchForms()
    }
  }, [orderBy, order, searchText, handleSearchForms])

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={openDialog}>
        <DialogTitle>{t('common.button.add') + ' ' + entityName}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <MISTextField
                id="search-text"
                label="Search Template"
                onChange={handleSearchTextChange}
                placeholder="Search Template by name"
                value={searchFormText}
              />
            </Grid>
            <Grid item xs={4}>
              <MISButton onClick={OnSearchClick} sx={{ marginTop: '32px' }}>
                {t('common.button.search')}
              </MISButton>
            </Grid>

            {forms?.length > 0 && (
              <MISTable
                formDialog
                headers={encounterTableHeaders}
                onSelect={onFormSelectChange}
                rows={forms}
                title=""
              />
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <MISButton onClick={handleClose}>{t('common.button.cancel')}</MISButton>
          <MISButton onClick={handleSaveClicked}>Add</MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddFormToSTDialog
