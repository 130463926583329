/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEducationDTO } from '../models/ClientEducationDTO'
import type { PageClientEducationDTO } from '../models/PageClientEducationDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientEducationControllerService {
  /**
   * Returns a single education by educationId
   * Returns a single education by educationId
   * @param clientId
   * @param educationId
   * @returns ClientEducationDTO OK
   * @throws ApiError
   */
  public static getEducation(
    clientId: string,
    educationId: string
  ): CancelablePromise<ClientEducationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/education/{educationId}',
      path: {
        clientId: clientId,
        educationId: educationId,
      },
    })
  }

  /**
   * Update client education
   * update client education by educationId
   * @param clientId
   * @param educationId
   * @param requestBody
   * @returns ClientEducationDTO OK
   * @throws ApiError
   */
  public static updateEducation(
    clientId: string,
    educationId: string,
    requestBody: ClientEducationDTO
  ): CancelablePromise<ClientEducationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/education/{educationId}',
      path: {
        clientId: clientId,
        educationId: educationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete education by educationId
   * Soft deletes the education by setting deleted flag to true
   * @param clientId
   * @param educationId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEducation(clientId: string, educationId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/education/{educationId}',
      path: {
        clientId: clientId,
        educationId: educationId,
      },
    })
  }

  /**
   * Returns a paged list of educations for client
   * List is returned as Paged collection
   * @param clientId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageClientEducationDTO OK
   * @throws ApiError
   */
  public static getClientEducations(
    clientId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageClientEducationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/education',
      path: {
        clientId: clientId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create client education
   * Create client education
   * @param clientId
   * @param requestBody
   * @returns ClientEducationDTO OK
   * @throws ApiError
   */
  public static createEducation(
    clientId: string,
    requestBody: ClientEducationDTO
  ): CancelablePromise<ClientEducationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/education',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
