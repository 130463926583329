import React, { useCallback, useEffect, useState } from 'react'
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css' // ES6
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import { useRecoilState } from 'recoil'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT, LOCAL_DATE_FORMAT } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { isDirtyState } from 'recoil/isDirty'
import {
  AcceptableUseAgreementControllerService,
  ConfidentialityAgreementControllerService,
} from 'services/openapi'

const AgreementEdit = ({ agreementId, onUpdate, title, type }) => {
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [richText, setRichText] = useState('')
  const [id, setId] = useState(agreementId)
  const [loading, setLoading] = useState(false)
  const [agreementResponse, setAgreementResponse] = useState(null)

  useEffect(() => {
    if (agreementResponse) {
      setName(agreementResponse.name)
      setRichText(agreementResponse.text)
      setStartDate(
        agreementResponse.startDate
          ? DateTime.fromFormat(agreementResponse.startDate, LOCAL_DATE_FORMAT)
          : null
      )
      setEndDate(
        agreementResponse.endDate
          ? DateTime.fromFormat(agreementResponse.endDate, LOCAL_DATE_FORMAT)
          : null
      )
      setId(agreementResponse.id)
    } else {
      // log.warn('useEffect(agreementResponse): unexpected missing response')
    }
  }, [agreementResponse])

  useEffect(() => {
    if (agreementId) {
      let getOperation
      if (type === 'acceptable-uses') {
        getOperation =
          AcceptableUseAgreementControllerService.getAcceptableUseAgreementById(agreementId)
      } else if (type === 'confidentiality-agreements') {
        getOperation =
          ConfidentialityAgreementControllerService.getConfidentialityAgreementById(agreementId)
      }
      if (getOperation) {
        setLoading(true)
        getOperation
          .then((response) => {
            setAgreementResponse(response)
          })
          .catch((error) => {
            handleApiError(error)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [agreementId, type, handleApiError])

  const copyButtonClicked = () => {
    setId(undefined)
    setName(name + ' -- COPY')
    setEndDate(null)
    setStartDate(null)
  }

  const saveButtonClicked = () => {
    setIsDirty(false)

    const agreementToSave = {
      endDate: endDate ? endDate.toFormat(LOCAL_DATE_FORMAT) : '',
      name: name,
      startDate: startDate ? startDate.toFormat(LOCAL_DATE_FORMAT) : '',
      text: richText,
    }

    // Set up the appropriate save operation based on whether we are editing or
    // creating an agreement and the type of the agreement.
    let saveOperation
    if (id) {
      agreementToSave.id = id
      if (type === 'acceptable-uses') {
        saveOperation = AcceptableUseAgreementControllerService.updateAcceptableUseAgreement(
          agreementId,
          agreementToSave
        )
      } else if (type === 'confidentiality-agreements') {
        saveOperation = ConfidentialityAgreementControllerService.updateConfidentialityAgreement(
          agreementId,
          agreementToSave
        )
      }
    } else {
      if (type === 'acceptable-uses') {
        saveOperation =
          AcceptableUseAgreementControllerService.createAcceptableUseAgreement(agreementToSave)
      } else if (type === 'confidentiality-agreements') {
        saveOperation =
          AcceptableUseAgreementControllerService.createAcceptableUseAgreement(agreementToSave)
      }
    }

    // Execute the save operation.
    if (saveOperation) {
      setLoading(true)
      saveOperation
        .then((response) => {
          if (!id) {
            setId(response.id)
          }
          // let agreementType;
          // if (type === 'acceptable-uses') {
          //   agreementType = 'Acceptable Use Agreement';
          // } else if (type === 'confidentiality-agreements') {
          //   agreementType = 'Confidentiality Agreement';
          // }
          // TODO: While this snowSnackSuccess does show a success message, for
          //  some reason when the snack is dismissed the data in the page is
          //  refreshed, including a call back to the server to get both the list
          //  of agreements and the agreement being edited. Not sure why this is
          //  happening. When this is discovered and addressed, uncomment the next
          //  line, the previous lines from "let agreementType" down, and the
          //  imports of t and showSnackSuccess at the top of the component.
          // showSnackSuccess(t('agreement.alert.save-success', {agreementType: agreementType }));
          onUpdate(response.id, response.name)
        })
        .catch((error) => {
          handleApiError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const cancelButtonClicked = () => {
    setId(undefined)
    setName('')
    setRichText('')
    setStartDate(null)
    setEndDate(null)
  }

  const handleChange = useCallback(
    (value, setValue) => {
      if (!isDirty) setIsDirty(true)

      setValue(value)
    },
    [isDirty, setIsDirty]
  )

  const changeStartDate = (value) => {
    if (!isDirty) setIsDirty(true)

    if (value && value.isValid) {
      setStartDate(value)
    }
    validate()
  }

  /**
   * EndDate cannot be changed in frontend
   * @param value
   */
  const changedEndDate = () => null

  const validate = () => null

  return (
    <Paper elevation={6} sx={{ p: 5 }}>
      <Typography gutterBottom style={{ marginBottom: '1rem' }} variant="h4">
        {title}
      </Typography>

      {!loading && (
        <Grid container justifyContent="center" spacing={4}>
          <Grid item sm={6} xs={12}>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: { enter: 1600, exit: 500 } }}
              arrow
              placement="top-end"
              title="Name of the Agreement, should be unique"
            >
              <MISTextField
                fullWidth
                inputProps={{ maxLength: 50 }}
                label="Name"
                name="AgreementName"
                onChange={(event) => handleChange(event.target.value, setName)}
                value={name}
              />
            </Tooltip>
          </Grid>

          <Grid item sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                label="Start Date"
                mask="____-__-__"
                onChange={changeStartDate}
                renderInput={(params) => <MISTextField {...params} />}
                value={startDate}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                disabled
                format={IDHE_DATE_DISPLAY_FORMAT}
                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                label="End Date"
                mask="____-__-__"
                onChange={changedEndDate}
                renderInput={(params) => <MISTextField {...params} />}
                value={endDate}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <ReactQuill
              onChange={(newText) => handleChange(newText, setRichText)}
              placeholder="Write something appropriate..."
              theme="snow"
              value={richText || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', mt: 2 }}>
              <MISButton onClick={cancelButtonClicked}>Cancel</MISButton>
              <Tooltip
                placement="right"
                title="Create a copy of this item (must click 'Save' to save the copy)"
              >
                <MISButton onClick={copyButtonClicked}>Copy</MISButton>
              </Tooltip>
              <MISButton onClick={saveButtonClicked}>Save</MISButton>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}

export default AgreementEdit
