import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import UserProvisioning from 'modules/user/components/provisioning/UserProvisioning'
import { personnelDetailsState } from 'recoil/personnelDetails'
import { UserControllerService, UserDTO } from 'services/openapi'

type PasswordProps = {
  newPassword: string
  rePassword: string
}

type UserProps = {
  isAdmin?: boolean
}

const User = ({ isAdmin }: UserProps) => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const personnelDetails = useRecoilValue(personnelDetailsState)

  const [currUser, setCurrUser] = useState<UserDTO | undefined>()
  const [newPassword, setNewPassword] = useState<PasswordProps>({ newPassword: '', rePassword: '' })
  const [isEditing, setIsEditing] = useState(false)
  const userProvisionRef = useRef<{ setProviderEditing: (isEditing) => void }>()

  const handleEditPermissions = useCallback(() => {
    if (userProvisionRef.current) {
      userProvisionRef.current.setProviderEditing(true)
      setIsEditing(true)
    }
  }, [])

  const handleSave = useCallback(async () => {
    if (currUser?.id) {
      try {
        await UserControllerService.updateUserPassword(currUser?.id, newPassword.newPassword)
        setNewPassword({ newPassword: '', rePassword: '' })
        showSnackSuccess(t('api.save-success'))
      } catch (err) {
        handleApiError(err)
      }
    }
  }, [currUser, handleApiError, newPassword.newPassword, showSnackSuccess, t])

  useEffect(() => {
    const getUser = async (userId: string) => {
      try {
        const resp = await UserControllerService.getById(userId)
        setCurrUser(resp)
      } catch (err) {
        handleApiError(err)
        setCurrUser(undefined)
      }
    }
    if (personnelDetails?.userId) getUser(personnelDetails.userId)
    else setCurrUser(undefined)
  }, [personnelDetails?.userId, handleApiError])

  return (
    <>
      <SectionHeader
        onSave={handleSave}
        saveLabel={t('common.button.save')}
        title={t('provider-staff.user.header.title')}
      />
      <Box className="provider-staff-section-container">
        <SectionHeader isSubsection title="Change Password" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISTextField
              label="New Password"
              onChange={(e) => setNewPassword({ ...newPassword, newPassword: e.target.value })}
              required
              value={newPassword.newPassword || ''}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              error={newPassword.newPassword !== newPassword.rePassword}
              helperText={
                newPassword.newPassword === newPassword.rePassword ? '' : 'Passwords do not match'
              }
              label="Re-type New Password"
              onChange={(e) => setNewPassword({ ...newPassword, rePassword: e.target.value })}
              required
              value={newPassword.rePassword || ''}
            />
          </Grid>
        </Grid>
        <UserProvisioning
          flipPermissions={setIsEditing}
          provUserFirstName={personnelDetails?.names?.find((x) => x.preferredName)?.firstName}
          provUserLastName={personnelDetails?.names?.find((x) => x.preferredName)?.lastName}
          ref={userProvisionRef}
          userId={personnelDetails?.userId}
        />
        {!isEditing && isAdmin && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
            <MISButton onClick={handleEditPermissions}>Edit Permissions</MISButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default User
