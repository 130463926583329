import { useCallback } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  ThemeProvider,
} from '@mui/material'
import { isEqual } from 'lodash'
import misTheme from 'core/styles/theme'
import MISMarkdownRenderer from '../markdown/MISMarkdownRenderer'

export type MISSelectDropdownInfo = { content?: string; filePath?: string }
export type MISSelectDropdownOption = { label: string; value: any }

type MISSelectDropdownProps = {
  label: string
  onChange: (value) => void
  options: MISSelectDropdownOption[]
  helperText?: string
  info?: MISSelectDropdownInfo
  noClearIcon?: boolean
} & SelectProps

const MISSelectDropdown = (props: MISSelectDropdownProps) => {
  const findLabelByValue = useCallback(
    (value) =>
      props.options.find(
        (opt: MISSelectDropdownOption) => opt.value === value || isEqual(opt.value, value)
      )?.label,
    [props.options]
  )

  return (
    <ThemeProvider theme={misTheme}>
      <FormControl size={props.size || 'small'}>
        <InputLabel required={props.required}>{props.label}</InputLabel>
        <Select
          {...props}
          defaultValue=""
          endAdornment={
            props.value && !props.readOnly && !props.noClearIcon ? (
              <IconButton onClick={props.onChange} sx={{ mr: 1 }}>
                <ClearIcon />
              </IconButton>
            ) : (
              false
            )
          }
          onChange={props.onChange}
          renderValue={() => (props.value ? findLabelByValue(props.value) : null)} // label is always shrinked with startAdornment https://github.com/mui/material-ui/issues/13898
          startAdornment={props.info ? <MISMarkdownRenderer {...props.info} /> : false}
          value={props.value || ''}
        >
          {props.options.map((option: MISSelectDropdownOption) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {props.helperText && (
          <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  )
}

export default MISSelectDropdown
