import { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useRecoilState, useSetRecoilState } from 'recoil'
import MISTabs from 'common/components/tabs/MISTabs'
import MISTabTitle from 'common/components/tabs/MISTabTitle'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  encounterServicesFormsAssociationsState,
  EncounterServicesFormsAssociationsType,
  EncounterServicesNoteHistoryType,
  encounterServicesNotesHistoryState,
  encounterServicesNotesState,
  EncounterServicesNotesType,
  encounterServicesStaffState,
  EncounterServicesStaffType,
} from 'recoil/encounters'
import {
  CancelablePromise,
  ClientDTO,
  EncounterDTO,
  EncounterNoteControllerService,
  EncounterServiceDTO,
  EncounterServiceTemplateControllerService,
  EncounterStaffControllerService,
  PageEncounterNoteDTO,
  PageEncounterNoteHistoryDTO,
  PageStaffUserDTO,
  TemplateFormAssociationEntity,
} from 'services/openapi'
import EncounterNotesSection from './EncounterNotes/EncounterNotesSection'
import { EncounterServiceRowType } from './Encounters'
import EncounterServicesSection from './EncounterService/EncounterServicesSection'
import EncounterStaffSection from './EncounterStaff/EncounterStaffSection'

const NOTE_VOIDED_CODE = 'VOIDED'

type EncountersContentPanelProps = {
  client: ClientDTO
  encounter: EncounterDTO
  services: EncounterServiceRowType
  setEncounter: (encounter: EncounterDTO | undefined) => void
  setServices: (services: EncounterServiceRowType) => void
}

const EncountersContentPanel = forwardRef(
  (
    { client, encounter, services, setEncounter, setServices }: EncountersContentPanelProps,
    ref
  ) => {
    const { t } = useTranslation('common')
    const { handleApiError } = useErrorHandler()
    const setForms = useSetRecoilState(encounterServicesFormsAssociationsState)
    const [notes, setNotes] = useRecoilState(encounterServicesNotesState)
    const [staff, setStaff] = useRecoilState(encounterServicesStaffState)
    const setNotesHistory = useSetRecoilState(encounterServicesNotesHistoryState)

    const notesCount = useCallback(() => {
      let count = 0
      if (notes)
        Object.keys(notes).forEach(
          (key) =>
            (count += notes[key].filter((note) => note.state?.code !== NOTE_VOIDED_CODE).length)
        )
      return count === 0 ? undefined : count
    }, [notes])

    const staffCount = useCallback(() => {
      let staffArr: string[] = []
      const uniqueStaff: string[] = []
      if (staff) {
        Object.keys(staff).forEach(
          (key) => (staffArr = staffArr.concat(staff[key].map((staff) => staff.providerId || '')))
        )
        staffArr.forEach((staff) => {
          if (uniqueStaff.indexOf(staff) === -1) uniqueStaff.push(staff)
        })
      }
      return uniqueStaff.length === 0 ? undefined : uniqueStaff.length
    }, [staff])

    useEffect(() => {
      const fetchEncountersForms = async (services: EncounterServiceDTO[]) => {
        const retVal: EncounterServicesFormsAssociationsType = {}
        const serviceIds: string[] = []
        const promises: CancelablePromise<TemplateFormAssociationEntity[]>[] = []
        services.forEach((service) => {
          if (service.id && service.encounterServiceTemplateId) {
            serviceIds.push(service.id)
            promises.push(
              EncounterServiceTemplateControllerService.getFormAssociationsForEncounterTemplate(
                service.encounterServiceTemplateId
              )
            )
          }
        })
        try {
          const responses = await Promise.all(promises)
          responses.forEach((resp, index) => (retVal[serviceIds[index]] = resp))
          setForms(retVal)
        } catch (error) {
          handleApiError(error)
        }
      }
      if (
        encounter.id &&
        encounter.encounterServices?.length &&
        encounter.encounterServices.length > 0
      )
        fetchEncountersForms(encounter.encounterServices)
    }, [encounter, handleApiError, setForms])

    useEffect(() => {
      const fetchEncountersNotes = async (encounterId: string, services: EncounterServiceDTO[]) => {
        const retVal: EncounterServicesNotesType = {}
        const retValHistory: EncounterServicesNoteHistoryType = {}
        const promises: CancelablePromise<PageEncounterNoteDTO>[] = []
        const promisesHistory: CancelablePromise<PageEncounterNoteHistoryDTO>[] = []
        services.forEach((service) => {
          const serviceId = service.id
          if (serviceId) {
            promises.push(
              EncounterNoteControllerService.getPagedList3(encounterId, serviceId, 0, 100)
            )
            promisesHistory.push(
              EncounterNoteControllerService.getPagedListForEncounterNoteHistory(
                encounterId,
                serviceId,
                0,
                100
              )
            )
          }
        })
        try {
          const responses = await Promise.all(promises)
          const responsesHistory = await Promise.all(promisesHistory)
          responses.forEach((resp) => {
            if (resp.content && resp.content.length > 0)
              retVal[resp.content[0].encounterServiceId as string] = resp.content
          })
          responsesHistory.forEach((resp) => {
            if (resp.content && resp.content.length > 0)
              retValHistory[resp.content[0].encounterServiceId as string] = resp.content
          })
          setNotes(retVal)
          setNotesHistory(retValHistory)
        } catch (error) {
          handleApiError(error)
        }
      }
      if (
        encounter.id &&
        encounter.encounterServices?.length &&
        encounter.encounterServices.length > 0
      )
        fetchEncountersNotes(encounter.id, encounter.encounterServices)
    }, [encounter, handleApiError, setNotes, setNotesHistory])

    useEffect(() => {
      const fetchEncounterServiceStaff = async (
        encounterId: string,
        services: EncounterServiceDTO[]
      ) => {
        const retVal: EncounterServicesStaffType = {}
        const promises: CancelablePromise<PageStaffUserDTO>[] = []
        const serviceIds: string[] = []
        services.forEach((service) => {
          const serviceId = service.id
          if (serviceId) {
            serviceIds.push(serviceId)
            promises.push(
              EncounterStaffControllerService.getPagedList2(encounterId, serviceId, 0, 100)
            )
          }
        })
        try {
          const responses = await Promise.all(promises)
          responses.forEach((resp, index) => {
            if (resp.content && resp.content.length > 0) retVal[serviceIds[index]] = resp.content
          })
          setStaff(retVal)
        } catch (error) {
          handleApiError(error)
        }
      }
      if (
        encounter.id &&
        encounter.encounterServices?.length &&
        encounter.encounterServices.length > 0
      )
        fetchEncounterServiceStaff(encounter.id, encounter.encounterServices)
    }, [encounter, handleApiError, setStaff])

    const tabs = useMemo(() => {
      const tabs = [
        {
          content: (
            <EncounterServicesSection
              client={client}
              encounter={encounter}
              ref={ref}
              services={services}
              setEncounter={setEncounter}
              setServices={setServices}
            />
          ),
          id: 'SERVICES',
          title: (
            <MISTabTitle
              count={services?.rows?.length > 0 ? services.rows.length : undefined}
              title={t('encounter.title.services')}
            />
          ),
        },
        {
          content: <EncounterNotesSection encounter={encounter} />,
          id: 'NOTES',
          title: <MISTabTitle count={notesCount()} title={t('encounter.title.notes')} />,
        },
        {
          content: <EncounterStaffSection encounter={encounter} />,
          id: 'STAFF',
          title: <MISTabTitle count={staffCount()} title={t('encounter.title.staff')} />,
        },
        {
          content: <Box sx={{ m: 2 }}>History</Box>,
          id: 'HISTORY',
          title: 'History',
        },
      ]
      if (notesCount() === undefined || notesCount() === 0)
        return tabs.filter((tab) => tab.id !== 'NOTES')
      return tabs
    }, [client, encounter, notesCount, ref, services, setEncounter, setServices, staffCount, t])

    return (
      <Box sx={{ ml: 1, mr: 5 }}>
        <MISTabs mountAllTabs tabs={tabs} />
      </Box>
    )
  }
)
EncountersContentPanel.displayName = 'EncountersContentPanel'

export default EncountersContentPanel
