import { Container as MUIContainer } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import GLOBAL from 'common/styles/global.scss'
import misTheme from 'core/styles/theme'

type MISBaseContainerProps = {
  children: React.ReactNode
  height?: string | number
  margin?: string | number
  maxHeight?: string | number
  maxWidth?: string | number
  padding?: string | number
  width?: string | number
}

const MISBaseContainer = ({
  children,
  height = 'auto',
  margin = GLOBAL.MARGIN_XXS,
  maxHeight = 'auto',
  maxWidth = '100% !important',
  padding = GLOBAL.PADDING_XXS,
  width = '100%',
}: MISBaseContainerProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <MUIContainer
        sx={{
          '&': {
            margin: 0,
            padding: `0 ${padding}`,
          },
          height: height,
          margin: margin,
          maxHeight: maxHeight,
          maxWidth: maxWidth,
          padding: padding,
          width: width,
        }}
      >
        {children}
      </MUIContainer>
    </ThemeProvider>
  )
}

export default MISBaseContainer
