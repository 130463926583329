/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UnPagedCodedConceptDto } from '../models/UnPagedCodedConceptDto'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class CodedConceptControllerService {
  /**
   * @param codeSystemOid
   * @param conceptCode
   * @returns UnPagedCodedConceptDto OK
   * @throws ApiError
   */
  public static getFilteredConceptCodes(
    codeSystemOid: string,
    conceptCode: string
  ): CancelablePromise<UnPagedCodedConceptDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-terminology-service/v1/coded-concepts',
      query: {
        codeSystemOid: codeSystemOid,
        conceptCode: conceptCode,
      },
    })
  }
}
