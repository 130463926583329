import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TrendingUpRounded } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT } from 'common/utils/DateUtils'

const columns = [
  {
    disablePadding: true,
    id: 'name',
    isSortable: true,
    label: 'types.table-header.name',
  },
  {
    id: 'startDate',
    isSortable: true,
    label: 'types.table-header.start-date',
  },
  {
    id: 'endDate',
    isSortable: true,
    label: 'types.table-header.end-date',
  },
  {
    id: 'select',
    isSortable: false,
    label: 'types.table-header.link',
  },
]

function EnhancedTableHead(props) {
  const { t } = useTranslation('common')
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) =>
          column.isSortable ? (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}
              style={{ maxWidth: column.maxWidth }}
              sx={{ fontWeight: 'bold' }}
            >
              {t(column.label)}
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
              style={{ maxWidth: column.maxWidth }}
              sx={{ fontWeight: 'bold' }}
            >
              {t(column.label)}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default function AddAssociationDialog(props) {
  const { t } = useTranslation('common')

  const { entityName, handleCloseCallback, onSaveClickedCallback, openDialog, otherEntities } =
    props

  const [selected] = useState([])

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [associations, setAssociations] = useState([])
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClose = () => {
    handleCloseCallback()
  }

  const handleStartDateChange = (value, index) => {
    let updatedAssociation = {
      ...associations[index],
      isSelected: TrendingUpRounded,
      startDate: value,
    }
    let associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleEndDateChange = (value, index) => {
    let updatedAssociation = { ...associations[index], endDate: value, isSelected: true }
    let associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleCheckboxChange = (event, index) => {
    let updatedAssociation = { ...associations[index], isSelected: event.target.checked }
    let associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const getEndDateHelperText = (startDate, endDate) => {
    if (endDate && !endDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'End date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const getStartDateHelperText = (startDate, endDate) => {
    if (!startDate) {
      return t('common.helper-text.required-field', { fieldName: 'Start date' })
    } else if (startDate && !startDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'Start date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const isStartDateValid = (startDate, endDate) => {
    if (!startDate || !startDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const isEndDateValid = (startDate, endDate) => {
    if (endDate && !endDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const validateAssociations = () => {
    let isValid = true
    associations.forEach((association) => {
      if (association.isSelected) {
        if (
          !isStartDateValid(association.startDate, association.endDate) ||
          !isEndDateValid(association.startDate, association.endDate)
        ) {
          isValid = false
        }
      }
    })
    return isValid
  }

  const handleSaveClicked = () => {
    setShowValidationErrors(true)
    if (!validateAssociations()) {
      return
    } else {
      setShowValidationErrors(false)
    }
    let associationsToSave = []
    associations.forEach((association) => {
      if (association.isSelected) {
        let associationToSave = {
          endDate: association.endDate ? association.endDate.toISO() : null,
          otherEntityId: association.otherEntityId,
          startDate: association.startDate ? association.startDate.toISO() : null,
        }
        associationsToSave.push(associationToSave)
      }
    })
    onSaveClickedCallback(associationsToSave)
  }
  useEffect(() => {
    let emptyAssociations = []
    otherEntities.forEach((e) => {
      let association = {
        endDate: null,
        isSelected: false,
        otherEntityId: e.id,
        otherEntityName: e.name,
        startDate: null,
      }
      emptyAssociations.push(association)
    })
    setAssociations(emptyAssociations)
  }, [otherEntities])

  return (
    <div>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} open={openDialog}>
        <DialogTitle>Link to {entityName}</DialogTitle>
        <DialogContent>
          <TableContainer sx={{ p: 2 }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                rowCount={associations.length}
              />

              {associations && associations.length > 0 && (
                <TableBody>
                  {associations.map((association, index) => {
                    if (association) {
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow hover key={association.otherEntityId} tabIndex={-1}>
                          <TableCell
                            component="th"
                            id={labelId}
                            padding="none"
                            scope="row"
                            style={{ maxWidth: 300 }}
                          >
                            {association.otherEntityName}
                          </TableCell>

                          <TableCell align="left">
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DatePicker
                                format={IDHE_DATE_DISPLAY_FORMAT}
                                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                                label={t('types.table-header.start-date')}
                                mask="____-__-__"
                                onChange={(value) => handleStartDateChange(value, index)}
                                renderInput={(params) => (
                                  <MISTextField
                                    {...params}
                                    error={
                                      showValidationErrors &&
                                      association.isSelected &&
                                      !isStartDateValid(association.startDate, association.endDate)
                                    }
                                    fullWidth
                                    helperText={
                                      showValidationErrors &&
                                      association.isSelected &&
                                      getStartDateHelperText(
                                        association.startDate,
                                        association.endDate
                                      )
                                    }
                                  />
                                )}
                                value={association.startDate}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell align="left">
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DatePicker
                                format={IDHE_DATE_DISPLAY_FORMAT}
                                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                                label={t('types.table-header.end-date')}
                                mask="____-__-__"
                                onChange={(value) => handleEndDateChange(value, index)}
                                renderInput={(params) => (
                                  <MISTextField
                                    {...params}
                                    error={
                                      showValidationErrors &&
                                      association.isSelected &&
                                      !isEndDateValid(association.startDate, association.endDate)
                                    }
                                    fullWidth
                                    helperText={
                                      showValidationErrors &&
                                      association.isSelected &&
                                      getEndDateHelperText(
                                        association.startDate,
                                        association.endDate
                                      )
                                    }
                                  />
                                )}
                                value={association.endDate}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell align="left">
                            <MISCheckbox
                              checked={association.isSelected}
                              onChange={(event) => handleCheckboxChange(event, index)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    } else {
                      return null
                    }
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <MISButton onClick={handleClose}>{t('common.button.cancel')}</MISButton>
          <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
