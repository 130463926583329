import { lazy, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { personnelDetailsState } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientIdentifier,
  PersonnelIdentifierControllerService,
  PersonnelIdentifierDTO,
} from 'services/openapi'
import { MIS_PROVIDER_IDENTIFIER_TYPES } from 'services/terminologyConstants'
import '../ProviderStaffDetails.scss'

const Identifers = lazy(() => import('modules/shared/Identifiers'))

const IdentifiersSection = () => {
  const { handleApiError } = useErrorHandler()
  const [personnelDetails, setPersonnelDetails] = useRecoilState(personnelDetailsState)
  const personIdentifierTypes = useRecoilValue(terminologySelector(MIS_PROVIDER_IDENTIFIER_TYPES))
  const { showSnackError, showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const transformIdentifiersPayload = useCallback((identifiers) => {
    // As identifiers component is shared between client & personnel, the type could be either, hence transformation
    return identifiers.map((obj: ClientIdentifier & PersonnelIdentifierDTO) => {
      return {
        ...obj,
        effective: {
          endDate: obj.endDate || undefined,
          startDate: obj.startDate || undefined,
        },
      }
    })
  }, [])

  const updatePersonnelIdentifiers = useCallback(
    (identifiers) => {
      if (personnelDetails?.id) {
        const transformedIdentifiers = transformIdentifiersPayload(identifiers)
        PersonnelIdentifierControllerService.savePersonnelIdentifiers(
          personnelDetails.id,
          transformedIdentifiers
        )
          .then((response) => {
            showSnackSuccess(t('api.save-success'))
            setPersonnelDetails({ ...personnelDetails, identifiers: response })
          })
          .catch((error) => {
            showSnackError(t('api.error.save-error'))
            handleApiError(error)
          })
      }
    },
    [
      personnelDetails,
      transformIdentifiersPayload,
      showSnackSuccess,
      t,
      setPersonnelDetails,
      showSnackError,
      handleApiError,
    ]
  )

  const deletePersonnelIdentifier = useCallback(
    (identifierId) => {
      if (personnelDetails?.id)
        PersonnelIdentifierControllerService.deletePersonnelIdentifier(
          personnelDetails.id,
          identifierId
        )
          .then(() => {
            showSnackSuccess(t('provider-staff.identifiers.messages.delete-identifier-success'))
            setPersonnelDetails({
              ...personnelDetails,
              identifiers: personnelDetails?.identifiers
                ? personnelDetails.identifiers.filter(
                    (identifier) => identifier.id !== identifierId
                  )
                : [],
            })
          })
          .catch((error) => {
            showSnackError(t('provider-staff.identifiers.messages.delete-identifier-error'))
            handleApiError(error)
          })
    },
    [personnelDetails, handleApiError, setPersonnelDetails, showSnackError, showSnackSuccess, t]
  )
  return (
    <Identifers
      deleteIdentifier={deletePersonnelIdentifier}
      identifiers={personnelDetails?.identifiers}
      personIdentifierTypes={personIdentifierTypes}
      updateIdentifiers={updatePersonnelIdentifiers}
    />
  )
}

export default IdentifiersSection
