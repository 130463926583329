/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImmunizationConsentDTO } from '../models/ImmunizationConsentDTO'
import type { ImmunizationConsentStateDTO } from '../models/ImmunizationConsentStateDTO'
import type { PageImmunizationConsentDTO } from '../models/PageImmunizationConsentDTO'
import type { UnPagedImmunizationConsentStateDTO } from '../models/UnPagedImmunizationConsentStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ImmunizationConsentControllerService {
  /**
   * Search Immunization Consents by agentCode
   * @param clientId
   * @param agentCode
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageImmunizationConsentDTO OK
   * @throws ApiError
   */
  public static searchAllImmunizationConsent(
    clientId: string,
    agentCode?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageImmunizationConsentDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents',
      path: {
        clientId: clientId,
      },
      query: {
        agentCode: agentCode,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ImmunizationConsentDTO OK
   * @throws ApiError
   */
  public static postImmunizationConsent(
    clientId: string,
    requestBody: ImmunizationConsentDTO
  ): CancelablePromise<ImmunizationConsentDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get history of state changes for immunization consent
   * @param clientId
   * @param immunizationConsentId
   * @returns UnPagedImmunizationConsentStateDTO OK
   * @throws ApiError
   */
  public static getStatesByImmunizationConsentId(
    clientId: string,
    immunizationConsentId: string
  ): CancelablePromise<UnPagedImmunizationConsentStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents/{immunizationConsentId}/states',
      path: {
        clientId: clientId,
        immunizationConsentId: immunizationConsentId,
      },
    })
  }

  /**
   * Create a new State for immunizationConsentId
   * @param clientId
   * @param immunizationConsentId
   * @param requestBody
   * @returns ImmunizationConsentStateDTO OK
   * @throws ApiError
   */
  public static createImmunizationConsentState(
    clientId: string,
    immunizationConsentId: string,
    requestBody: ImmunizationConsentStateDTO
  ): CancelablePromise<ImmunizationConsentStateDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents/{immunizationConsentId}/states',
      path: {
        clientId: clientId,
        immunizationConsentId: immunizationConsentId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param immunizationConsentId
   * @returns ImmunizationConsentDTO OK
   * @throws ApiError
   */
  public static getImmunizationConsentById(
    clientId: string,
    immunizationConsentId: string
  ): CancelablePromise<ImmunizationConsentDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents/{immunizationConsentId}',
      path: {
        clientId: clientId,
        immunizationConsentId: immunizationConsentId,
      },
    })
  }

  /**
   * Get list of possible next states for immunization consent
   * @param clientId
   * @param immunizationConsentId
   * @returns UnPagedImmunizationConsentStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByImmunizationConsentId(
    clientId: string,
    immunizationConsentId: string
  ): CancelablePromise<UnPagedImmunizationConsentStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunization-consents/{immunizationConsentId}/next-states',
      path: {
        clientId: clientId,
        immunizationConsentId: immunizationConsentId,
      },
    })
  }
}
