import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISReactQuill from 'common/components/form/MISReactQuill'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISCircledCheckIcon from 'common/components/icons/MISCircledCheckIcon'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { dateNowDisplayFormat, isDateAfterToday, isDateBeforeToday } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getClientFullName } from 'modules/shared/clientUtils'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import {
  ErrorType,
  getFormattedOptions,
  getFormattedSortedOptionsWithDesc,
} from 'modules/shared/utils'
import WarningTooltip from 'modules/shared/WarningTooltip'
import { immunizationToAvoidState } from 'recoil/immunzations'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  CancelablePromise,
  CodedConceptDto,
  CodedRef,
  ImmunizationConsentControllerService,
  ImmunizationConsentDTO,
  ImmunizationScheduleControllerService,
  ImmunizationScheduleDTO,
  PageImmunizationScheduleDTO,
} from 'services/openapi'
import {
  MIS_BC_IMMUNIZATION_AGENT,
  MIS_CONSENT_BY_OPTIONS,
  MIS_CONSENT_OPTIONS,
  MIS_PERSON_RELATIONSHIP_ATTRIBUTE,
  MIS_PERSON_RELATIONSHIP_TYPE,
} from 'services/terminologyConstants'
import { selectClientRelationships } from 'store/selectors/client'

type ImmunizationRecordConsentProps = {
  toggleSaveButton: (consenting: boolean) => void
  toggleUpdateRecords?: () => void
  setTabById: (id: string) => void
}

type ScheduleConsentData = {
  name?: string
  agents?: CodedRef[]
}

const ImmunizationRecordConsent = forwardRef(
  ({ setTabById, toggleSaveButton, toggleUpdateRecords }: ImmunizationRecordConsentProps, ref) => {
    const { t } = useTranslation('common')
    const { showSnackSuccess } = useSnack()
    const { handleApiError } = useErrorHandler()
    const { clientId } = useParams()
    const { clientDetails } = useClientDetails(clientId)
    const clientRelationships = useSelector(selectClientRelationships)
    const agentOptions: CodedConceptDto[] = useRecoilValue(
      terminologySelector(MIS_BC_IMMUNIZATION_AGENT)
    )
    const consentOptions: CodedConceptDto[] = useRecoilValue(
      terminologySelector(MIS_CONSENT_OPTIONS)
    )
    const relationshipTypes = useRecoilValue(terminologySelector(MIS_PERSON_RELATIONSHIP_TYPE))
    const relationshipAttributeTypes = useRecoilValue(
      terminologySelector(MIS_PERSON_RELATIONSHIP_ATTRIBUTE)
    )

    const immunzationsToAvoid = useRecoilValue(immunizationToAvoidState)
    const consentByTypes = useRecoilValue(terminologySelector(MIS_CONSENT_BY_OPTIONS))
    const [isDirty, setIsDirty] = useRecoilState(isDirtyState)

    const [searchText, setSearchText] = useState('')
    const [selectedAgent, setSelectedAgent] = useState<CodedConceptDto | null>(null)
    const [schedules, setSchedules] = useState<PageImmunizationScheduleDTO>({})
    const [selectedScheduleData, setSelectedScheduleData] = useState<ScheduleConsentData | null>(
      null
    )
    const [givingConsent, setGivingConsent] = useState<boolean>(false)
    const [currentConsent, setCurrentConsent] = useState<ImmunizationConsentDTO[]>([])
    const [isDefaultSet, setIsDefaultSet] = useState<boolean>(false)
    const [errors, setErrors] = useState<ErrorType[]>([])

    const getFormattedRelationsOptions = useMemo(() => {
      const formattedOptions =
        clientRelationships?.relationships
          ?.filter(
            (x) =>
              !x.clientRelationship.effective?.endDate ||
              isDateAfterToday(x.clientRelationship.effective?.endDate)
          )
          .sort((a, b) => {
            const aImmunizationAttribute = !!a.clientRelationship.relationshipAttributes?.find(
              (x) => x.attribute?.code === 'RESPONSIBLE_PARTY_IMM'
            )
            const bImmunizationAttribute = !!b.clientRelationship.relationshipAttributes?.find(
              (x) => x.attribute?.code === 'RESPONSIBLE_PARTY_IMM'
            )
            return (bImmunizationAttribute ? 1 : 0) - (aImmunizationAttribute ? 1 : 0)
          })
          .map((option) => {
            const relationshipTypeString = relationshipTypes.find(
              (x) => x.code === option.clientRelationship.relationshipType?.code
            )?.name
            const immunizationAttribute = option.clientRelationship.relationshipAttributes?.find(
              (x) => x.attribute?.code === 'RESPONSIBLE_PARTY_IMM'
            )
              ? ' | ' +
                relationshipAttributeTypes.find((x) => x.code === 'RESPONSIBLE_PARTY_IMM')?.name
              : ''

            return {
              label:
                getClientFullName(option.client) +
                ' | ' +
                relationshipTypeString +
                immunizationAttribute,
              value: option,
            }
          }) || []

      // if (
      //   clientDetails &&
      //   clientDetails.birthdate
      //   //DateTime.fromISO(clientDetails.birthdate).plus({ years: 14 }) <= DateTime.now() .. removing this for now because the requirements need to be fleshed out but this is where the code change will happen
      // ) {
      formattedOptions.unshift({
        label: 'Self',
        value: { client: { id: clientId }, clientRelationship: {} },
      })
      //}

      return formattedOptions
    }, [
      clientId,
      clientRelationships?.relationships,
      relationshipTypes,
      relationshipAttributeTypes,
    ])

    const fetchSchedules = useCallback(async (text, agentCode) => {
      const response = await ImmunizationScheduleControllerService.searchAllImmunizationSchedules(
        text,
        agentCode
      )
      if (response) {
        setSchedules(response)
      }
    }, [])

    const handleSearch = useCallback(
      (text) => {
        let hideTimer: NodeJS.Timeout | null = null
        if (hideTimer !== null) {
          clearTimeout(hideTimer)
        }
        hideTimer = setTimeout(() => {
          if (searchText) {
            fetchSchedules(text, null)
          }
        }, 1500)
      },
      [fetchSchedules, searchText]
    )

    const handleSearchTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedAgent(null)
      setSearchText(event.target.value)
    }, [])

    const handleAgentUpdate = useCallback((value: CodedConceptDto | null) => {
      setSelectedAgent(value)
      setSearchText('')
    }, [])

    const onSelectSchedule = useCallback(
      (schedule: ImmunizationScheduleDTO) => {
        const uniqueMap = new Map<string | undefined, CodedRef | undefined>(
          schedule?.sequences?.map((item) => [item?.agent?.code, item.agent])
        )

        // Convert the Map values back to an array
        const groupedAgents = Array.from(uniqueMap.values()).filter(
          (item): item is CodedRef => item !== undefined
        )
        const scheduleData: ScheduleConsentData = {
          agents: [...groupedAgents],
          name: schedule.name,
        }
        setSelectedScheduleData(scheduleData)
        setGivingConsent(true)
        toggleSaveButton(true)
        const newConsent: ImmunizationConsentDTO[] = []
        groupedAgents?.forEach((agent: CodedRef) => {
          newConsent.push({ agent: agent, immunizationScheduleId: schedule.id })
        })
        setIsDefaultSet(false)
        setCurrentConsent(newConsent)
      },
      [toggleSaveButton]
    )

    const handlePropertyChange = useCallback(
      (property, value) => {
        if (!isDirty) setIsDirty(true)
        const newConsent = currentConsent.map((item) => {
          const newItem = { ...item }
          if (property === 'consentDate') {
            newItem.consentDate = value ? (value as string) : ''
          } else if (property === 'consentBy') {
            if (!value) {
              newItem.consentBy = undefined
              newItem.consentingClientId = undefined
            } else if (value.label === 'Self') {
              newItem.consentBy = consentByTypes.find((x) => x.code === 'SELF')
              newItem.consentingClientId = undefined
            } else {
              newItem.consentBy = consentByTypes.find((x) => x.code === 'CLIENT_RELATION')
              newItem.consentingClientId = value?.value?.client?.id || ''
            }
          } else if (property === 'note') {
            newItem.note = value ? value : ''
          }
          return newItem
        })
        setCurrentConsent(newConsent)
      },
      [consentByTypes, currentConsent, isDirty, setIsDirty]
    )

    const getError = useCallback(
      (field: string) => {
        const errorObj = errors.find((error) => error.field === field)
        return errorObj ? errorObj.message : ''
      },
      [errors]
    )

    const determineDueDate = useCallback(
      (usualAge: number, usualTimeInterval: string) => {
        if (clientDetails?.birthdate) {
          switch (usualTimeInterval) {
            case 'd': {
              return isDateBeforeToday(
                DateTime.fromISO(clientDetails.birthdate).plus({ days: usualAge }).toISODate()
              )
                ? DateTime.fromISO(clientDetails.birthdate).plus({ days: usualAge }).toISODate()
                : DateTime.now().toISODate()
            }
            case 'wk': {
              return isDateBeforeToday(
                DateTime.fromISO(clientDetails.birthdate).plus({ weeks: usualAge }).toISODate()
              )
                ? DateTime.fromISO(clientDetails.birthdate).plus({ weeks: usualAge }).toISODate()
                : DateTime.now().toISODate()
            }
            case 'mo': {
              return isDateBeforeToday(
                DateTime.fromISO(clientDetails.birthdate).plus({ months: usualAge }).toISODate()
              )
                ? DateTime.fromISO(clientDetails.birthdate).plus({ months: usualAge }).toISODate()
                : DateTime.now().toISODate()
            }
            case 'a': {
              return isDateBeforeToday(
                DateTime.fromISO(clientDetails.birthdate).plus({ years: usualAge }).toISODate()
              )
                ? DateTime.fromISO(clientDetails.birthdate).plus({ years: usualAge }).toISODate()
                : DateTime.now().toISODate()
            }
          }
        }
      },
      [clientDetails?.birthdate]
    )

    const validate = useCallback(() => {
      let isValid = true
      const errors: ErrorType[] = []
      currentConsent?.forEach((row: ImmunizationConsentDTO, i: number) => {
        if (!row?.consent?.code) {
          isValid = false
          errors.push({
            field: `agent-consent-${i}`,
            message: `${t('immunizations.error.consent-required')}`,
          })
        }
        if (!row?.consentBy) {
          isValid = false
          errors.push({
            field: `consentBy`,
            message: `${t('immunizations.error.consent-person-required')}`,
          })
        }
        if (!row?.consentDate) {
          isValid = false
          errors.push({
            field: `consentDate`,
            message: `${t('immunizations.error.consent-date-required')}`,
          })
        }
      })

      return { errors, isValid }
    }, [currentConsent, t])

    const saveConsents = useCallback(async () => {
      setIsDirty(false)
      const { errors, isValid } = validate()
      if (isValid) {
        const promises: CancelablePromise<ImmunizationConsentDTO>[] = []
        if (clientId) {
          currentConsent.forEach((consent) => {
            promises.push(
              ImmunizationConsentControllerService.postImmunizationConsent(clientId, consent)
            )
          })
        }

        try {
          await Promise.all(promises)
          showSnackSuccess(`${t('api.save-success')}`)
        } catch (error) {
          handleApiError(error)
        }
        setGivingConsent(false)
        setTabById('CONSENTS')
        toggleSaveButton(false)
        if (toggleUpdateRecords) toggleUpdateRecords()
        setErrors([])
      } else {
        setErrors(errors)
      }
    }, [
      clientId,
      currentConsent,
      handleApiError,
      setIsDirty,
      setTabById,
      showSnackSuccess,
      t,
      toggleSaveButton,
      toggleUpdateRecords,
      validate,
    ])

    useImperativeHandle(ref, () => ({
      cancel: () => {
        setGivingConsent(false)
        setSearchText('')
        setSelectedAgent(null)
        setSchedules({})
        setErrors([])
        toggleSaveButton(false)
      },
      save: () => {
        saveConsents()
      },
    }))

    useEffect(() => {
      if (selectedAgent) {
        fetchSchedules(null, selectedAgent.code)
      }
    }, [fetchSchedules, selectedAgent])

    useEffect(() => {
      if (searchText && searchText.length > 2) {
        handleSearch(searchText)
      }
    }, [handleSearch, searchText])

    useEffect(() => {
      if (
        !isDefaultSet &&
        getFormattedRelationsOptions &&
        getFormattedRelationsOptions.length > 0 &&
        currentConsent.length > 0
      ) {
        const relation =
          getFormattedRelationsOptions[0]?.label?.indexOf('Self') > -1 ||
          getFormattedRelationsOptions[0]?.label?.indexOf(
            relationshipAttributeTypes.find((x) => x.code === 'RESPONSIBLE_PARTY_IMM')
              ?.name as string
          ) > -1
            ? getFormattedRelationsOptions[0]
            : undefined

        const newConsent = currentConsent.map((item) => {
          const newItem = { ...item }
          newItem.consentBy = relation
            ? relation.label === 'Self'
              ? consentByTypes.find((x) => x.code === 'SELF')
              : consentByTypes.find((x) => x.code === 'CLIENT_RELATION')
            : undefined
          newItem.consentingClientId = relation
            ? relation.label === 'Self'
              ? undefined
              : relation.value.client.id
            : undefined
          return newItem
        })

        setCurrentConsent(newConsent)
        setIsDefaultSet(true)
      }
    }, [
      consentByTypes,
      currentConsent,
      fetchSchedules,
      getFormattedRelationsOptions,
      isDefaultSet,
      relationshipAttributeTypes,
      selectedAgent,
    ])

    return (
      <>
        {!givingConsent && (
          <>
            <Grid alignItems="center" container justifyContent="center" spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <MISAutocomplete
                  label={t('immunizations.search-by-agent-label')}
                  onChange={(value) => handleAgentUpdate(value?.value || null)}
                  options={getFormattedSortedOptionsWithDesc(agentOptions)}
                  value={selectedAgent?.description}
                />
              </Grid>
              <Grid item sx={{ marginTop: GLOBAL.MARGIN_SM, textAlign: 'center' }} xs={1}>
                <Typography>OR</Typography>
              </Grid>
              <Grid item xs={5}>
                <Tooltip arrow placement="top" title={t('documents.search-text-tooltip')}>
                  <MISTextField
                    id="search-text"
                    label={t('immunizations.search-schedules-label')}
                    onChange={handleSearchTextChange}
                    placeholder={t('immunizations.search-text-placeholder')}
                    value={searchText}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container>
              {schedules?.content?.map((schedule, index) => (
                <Grid item key={index} sx={{ marginTop: GLOBAL.MARGIN_XL }} xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow sx={{ height: GLOBAL.MARGIN_XXL }}>
                          <TableCell sx={{ fontWeight: 'bold' }} width="25%">
                            {schedule.name}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: 'bold' }} width="10%">
                            {t('immunizations.dose-number')}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: 'bold' }} width="10%">
                            {t('immunizations.due-date')}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: 'bold' }} width="55%">
                            <MISButton
                              color="primary"
                              onClick={() => onSelectSchedule(schedule)}
                              size="large"
                              startIcon={<MISCircledCheckIcon />}
                              variant="text"
                            >
                              {t('immunizations.select-schedule-label')}
                            </MISButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {schedule?.sequences?.map((sequence) => (
                          <TableRow
                            key={sequence.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              height: GLOBAL.MARGIN_XXL,
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center', width: '100%' }}
                              >
                                {agentOptions.find((x) => sequence.agent?.code === x.code)?.name}
                                {immunzationsToAvoid?.find(
                                  (x) =>
                                    x.agent?.code === sequence.agent?.code &&
                                    x.currentState?.state?.code === 'ACTIVE'
                                ) && (
                                  <WarningTooltip
                                    header="Warning!"
                                    reason="This agent is listed on the Immunizations to Avoid for this client."
                                  />
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell align="right">{sequence.doseNumber}</TableCell>
                            <TableCell align="right">
                              {determineDueDate(
                                sequence.usualAge || 0,
                                sequence.usualAgeUnit?.code || 'a'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {givingConsent && selectedScheduleData && (
          <Grid container spacing={3}>
            <Grid item sx={{ marginTop: GLOBAL.MARGIN_XL }} xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }} width="75%">
                        {selectedScheduleData.name}
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                        {t('immunizations.consent-label')}*
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedScheduleData.agents?.map((agent, index) => (
                      <TableRow
                        key={agent.code + '' + selectedScheduleData.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {agentOptions.find((x) => agent.code === x.code)?.name}
                        </TableCell>
                        <TableCell align="right">
                          <MISSelectDropdown
                            error={!!getError(`agent-consent-${index}`)}
                            helperText={getError(`agent-consent-${index}`)}
                            label="Select..."
                            onChange={(event) => {
                              const consent = consentOptions.find((x) => x === event.target.value)
                              if (consent) {
                                const newCurrentConsent = [...currentConsent]
                                newCurrentConsent[index].consent = {
                                  code: consent.code,
                                  codeSystemOid: consent.codeSystemOid,
                                }
                                setCurrentConsent(newCurrentConsent)
                              }
                            }}
                            options={getFormattedOptions(consentOptions)}
                            value={
                              consentOptions.find(
                                (x) => x.code === currentConsent[index]?.consent?.code
                              ) || ''
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                error={!!getError(`consentDate`)}
                helperText={getError(`consentDate`)}
                isDefaultToday
                label={t('immunizations.consent-date-label')}
                onChange={(value) => handlePropertyChange('consentDate', value)}
                required
                value={currentConsent[0]?.consentDate || dateNowDisplayFormat()}
              />
            </Grid>
            <Grid item xs={8}>
              <MISAutocomplete
                error={!!getError('consentBy')}
                helperText={getError('consentBy')}
                label={t('immunizations.by-whom-label')}
                onChange={(value) => handlePropertyChange('consentBy', value)}
                options={getFormattedRelationsOptions}
                required
                value={
                  currentConsent[0]?.consentingClientId
                    ? getFormattedRelationsOptions.find(
                        (x) => x.value.client.id === currentConsent[0]?.consentingClientId
                      )
                    : getFormattedRelationsOptions[0]?.label?.indexOf('Self') > -1 ||
                      getFormattedRelationsOptions[0]?.label?.indexOf(
                        relationshipAttributeTypes.find((x) => x.code === 'RESPONSIBLE_PARTY_IMM')
                          ?.name as string
                      ) > -1
                    ? getFormattedRelationsOptions[0]
                    : ''
                }
              />
            </Grid>
            <Grid item xs={8}>
              <MISReactQuill
                MAX_NOTE_TEXT_LENGTH={128}
                noteText={currentConsent[0]?.note || ''}
                onContentChange={(value: string) => handlePropertyChange('note', value)}
                style={{
                  height: '80%',
                  marginBottom: `${GLOBAL.MARGIN_XS}`,
                  marginTop: `${GLOBAL.MARGIN_XS}`,
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    )
  }
)
ImmunizationRecordConsent.displayName = 'ImmunizationRecordConsent'

export default ImmunizationRecordConsent
