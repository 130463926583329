import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PushPinIcon from '@mui/icons-material/PushPin'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import { TableSortLabel } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import { DateTime } from 'luxon'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISChip from 'common/components/form/MISChip'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISHideIcon from 'common/components/icons/MISHideIcon'
import MISButton from 'common/components/MISButton'
import { getNameFromCodedConcept } from 'common/utils/CodedConceptUtils'
import { isoDateToDisplayFormat, isoDateToLocalDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import {
  pinnedClientsState,
  userIdState,
  userPinnedClientsPreferenceIdState,
} from 'recoil/recentClients'
import { clientSearchFiltersState, clientSearchResultsState } from 'recoil/searchResults'
import { terminologyState } from 'recoil/terminology'
import {
  Client,
  ClientControllerService,
  ClientDTO,
  CodedConceptDto,
  Preference,
  PrivacyDirectiveOverrideControllerService,
  PrivacyDirectiveOverrideDTO,
  UserPreferenceControllerService,
} from 'services/openapi'
import {
  ADDRESS_PROVINCES,
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MEMBER_STATUS_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
  VALUESET,
} from 'services/terminologyConstants'
import OverridePrivacyDialog from '../ClientDetails/ClientPrivacyDirectives/OverridePrivacyDialog'
import './SearchClient.scss'

const DEFAULT_PAGE_NUMBER = 0
const DEFAULT_PAGE_SIZE = 5
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_ORDER = 'asc'

const defaultFiltersObj = {
  age: '',
  birthdate: '',
  fileNumber: '',
  gender: '',
  identifierNumber: '',
  identifierType: '',
  name: '',
  page: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  sortBy: DEFAULT_SORT_BY,
  sortOrder: DEFAULT_SORT_ORDER,
  stateProvince: [],
  status: [],
  totalElements: 0,
  voucherNumber: '',
}

type EnhancedTableHeadProps = {
  onRequestSort: (property: string) => void
  order: 'asc' | 'desc' | undefined
  orderBy: string
}

const EnhancedTableHead = ({ onRequestSort, order, orderBy }: EnhancedTableHeadProps) => {
  const { t } = useTranslation('common')
  const headCells = useMemo(
    () => [
      {
        id: 'name',
        key: 'name',
        label: t('client-search.content.client-name'),
      },
      {
        id: 'gender',
        key: 'gender',
        label: t('client-search.content.gender'),
      },
      {
        id: 'birthdate',
        key: 'birthdate',
        label: t('client-search.content.birthdate'),
      },
      {
        id: 'age',
        key: 'age',
        label: t('client-search.content.age'),
      },
      {
        id: 'fileNumber',
        key: 'fileNumber',
        label: t('client-search.content.file-number'),
      },
      {
        id: 'identifier',
        key: 'identifier',
        label: t('client-search.content.identifier-number'),
      },
      {
        id: 'status',
        key: 'status',
        label: t('client-search.content.status'),
      },
    ],
    [t]
  )

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            className="head-cell"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== 'identifier' && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {headCell.id === 'identifier' && (
              <Typography className="identifier">{headCell.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type SearchClientProps = {
  clientId?: string
  onAddClient?: (client: ClientDTO) => void
}

const SearchClient = ({ clientId, onAddClient }: SearchClientProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { handleApiError } = useErrorHandler()
  const [pinnedClients, setPinnedClients] = useRecoilState(pinnedClientsState)
  const [userPinnedClientsPreferenceId, setUserPinnedClientsPreferenceId] = useRecoilState(
    userPinnedClientsPreferenceIdState
  )
  const setNavItemSelected = useSetRecoilState(MISNavigationState)
  const userId = useRecoilValue(userIdState)
  const valueSets = useRecoilValue(terminologyState)
  const [clientSearchFilters, setClientSearchFilters] = useRecoilState(clientSearchFiltersState)
  const [clientSearchResults, setClientSearchResults] = useRecoilState(clientSearchResultsState)
  const [validSearchInput, setValidSearchInput] = useState(false)
  const [showSearchError, setShowSearchError] = useState(false)
  const [totalElements, setTotalElements] = useState(0)
  const [birthdateError, setBirthdateError] = useState<string | null>(null)
  const [genderOptions, setGenderOptions] = useState<CodedConceptDto[]>([])
  const [identifierTypeOptions, setIdentifierTypeOptions] = useState<CodedConceptDto[]>([])
  const [statusOptions, setStatusOptions] = useState<CodedConceptDto[]>([])
  const [provinceOptions, setProvinceOptions] = useState<CodedConceptDto[]>([])
  const [filters, setFilters] = useState<{ [key: string]: any } | null>(defaultFiltersObj)
  const [searchResults, setSearchResults] = useState<Client[] | null>([])
  const [displayResultSection, setDisplayResultSection] = useState(false)
  const [openOverridePrivacyDialog, setOpenOverridePrivacyDialog] = useState(false)
  const [scrutinyId, setScrutinyId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (onAddClient === undefined) setSearchResults(clientSearchResults)
  }, [clientSearchResults, onAddClient])

  useEffect(() => {
    setFilters(clientSearchFilters)
  }, [clientSearchFilters])

  const updateFilters = useCallback(
    (key, value) => {
      setClientSearchFilters({ ...filters, [key]: value })
    },
    [filters, setClientSearchFilters]
  )

  useEffect(() => {
    if (statusOptions?.length > 0) {
      if (
        filters?.['status']?.[0] !==
        statusOptions.find((status) => status?.code === VALUESET.MEMBER_STATUS.ACTIVE.code)?.id
      ) {
        setClientSearchFilters({
          ...filters,
          sortBy: DEFAULT_SORT_BY,
          sortOrder: DEFAULT_SORT_ORDER,
          status: [
            statusOptions.find((status) => status?.code === VALUESET.MEMBER_STATUS.ACTIVE.code)?.id,
          ],
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusOptions, updateFilters])

  useEffect(() => {
    if (filters?.['totalElements'] !== totalElements) {
      updateFilters('totalElements', totalElements)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalElements])

  // Check if the user has specified at least one search parameter in
  // addition to the default active status criteria
  const validateSearchInput = useCallback(() => {
    if (
      (filters?.['name'] ||
        filters?.['voucherNumber'] ||
        filters?.['fileNumber'] ||
        filters?.['identifierType'] ||
        filters?.['identifierNumber'] ||
        filters?.['gender'] ||
        filters?.['age'] ||
        (filters?.['status'] &&
          filters?.['status'].length > 0 &&
          !filters?.['status'].includes(
            [
              statusOptions.find((status) => status?.code === VALUESET.MEMBER_STATUS.ACTIVE.code)
                ?.id,
            ][0]
          )) ||
        (filters?.['stateProvince'] && filters?.['stateProvince'].length > 0) ||
        filters?.['birthdate']) &&
      !birthdateError
    ) {
      setValidSearchInput(true)
      setShowSearchError(false)
      return true
    } else {
      setValidSearchInput(false)
      setShowSearchError(true)
      return false
    }
  }, [filters, statusOptions, birthdateError])

  const handleSearchClient = useCallback(
    (page, passedOrder = DEFAULT_SORT_ORDER, passedOrderBy = DEFAULT_SORT_BY) => {
      if (validateSearchInput()) {
        // The identifier type and gender contain a global ID, and the status
        // contains a list of status global IDs. However, the server doesn't
        // store global IDs (anymore), but code system OIDs and codes. So for
        // identifier type and gender, we need to pass the code system OID and
        // code instead of just the global ID, and for status, we need to pass
        // a list of code system OIDs and codes instead of a list of global IDs.
        let identifierCode
        let identifierCodeSystemOid
        if (filters?.['identifierType']) {
          identifierCode = identifierTypeOptions.find(
            (x) => x.id === filters?.['identifierType']?.id
          )?.code
          identifierCodeSystemOid = identifierTypeOptions.find(
            (x) => x.id === filters?.['identifierType']?.id
          )?.codeSystemOid
        }

        let genderCode
        let genderCodeSystemOid
        if (filters?.['gender']) {
          genderCode = genderOptions.find((x) => x.id === filters?.['gender'])?.code
          genderCodeSystemOid = genderOptions.find((x) => x.id === filters['gender'])?.codeSystemOid
        }

        const statusCodes: string[] = []
        const statusCodeSystemOids: string[] = []
        if (filters?.['status']) {
          filters?.['status'].forEach((statusId: string) => {
            const statusOption = statusOptions.find((x) => x.id === statusId)
            if (statusOption) {
              statusCodes.push(statusOption.code as string)
              statusCodeSystemOids.push(statusOption.codeSystemOid as string)
            }
          })
        }

        const stateProvinceCodes: string[] = []
        const stateProvinceSystemOids: string[] = []
        if (filters?.['stateProvince']) {
          filters?.['stateProvince'].forEach((stateId: string) => {
            const stateOption = provinceOptions.find((x) => x.id === stateId)
            if (stateOption) {
              stateProvinceCodes.push(stateOption.code as string)
              stateProvinceSystemOids.push(stateOption.codeSystemOid as string)
            }
          })
        }

        ClientControllerService.searchClient(
          filters?.['name'] || '',
          filters?.['fileNumber'] || '',
          filters?.['identifierNumber'] || '',
          filters?.['birthdate'] ? isoDateToLocalDisplayFormat(filters?.['birthdate']) : '',
          filters?.['age'] ? filters?.['age'] : -1,
          identifierCodeSystemOid || '',
          identifierCode || '',
          genderCodeSystemOid || '',
          genderCode || '',
          statusCodeSystemOids || [],
          statusCodes || [],
          stateProvinceSystemOids || [],
          stateProvinceCodes || [],
          clientId ? [clientId] : [],
          true,
          page,
          DEFAULT_PAGE_SIZE,
          [passedOrderBy, passedOrder]
        )
          .then((result) => {
            const clientsWithPreferredName = result.content?.filter((client) =>
              client.names?.find((name) => name.preferredName)
            )
            setClientSearchResults(clientsWithPreferredName || [])
            setDisplayResultSection(true)
            setTotalElements(result.totalElements || 0)
          })
          .catch((error) => {
            setDisplayResultSection(false)
            handleApiError(error)
          })
      }
    },
    [
      clientId,
      filters,
      genderOptions,
      handleApiError,
      identifierTypeOptions,
      provinceOptions,
      setClientSearchResults,
      statusOptions,
      validateSearchInput,
    ]
  )

  const handleChangePage = useCallback(
    (event, newPage) => {
      updateFilters('page', newPage)
      handleSearchClient(newPage, filters?.['sortOrder'], filters?.['sortBy'])
    },
    [filters, handleSearchClient, updateFilters]
  )

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () =>
      filters?.['page'] > 0
        ? Math.max(
            0,
            filters?.['pageSize'] - (clientSearchResults ? clientSearchResults.length : 0)
          )
        : 0,
    [clientSearchResults, filters]
  )

  useEffect(() => {
    const genderOptions = valueSets.find((obj) => obj.setName === MIS_GENDER_VOCAB_NAME)?.value
    const identifierTypeOptions = valueSets.find(
      (obj) => obj.setName === MEMBER_IDENTIFIER_TYPE_VOCAB_NAME
    )?.value
    const statusOptions = valueSets.find((obj) => obj.setName === MEMBER_STATUS_VOCAB_NAME)?.value
    const provinceOptions = valueSets.find((obj) => obj.setName === ADDRESS_PROVINCES)?.value

    setGenderOptions(genderOptions || [])
    setIdentifierTypeOptions(identifierTypeOptions || [])
    setStatusOptions(statusOptions || [])
    setProvinceOptions(provinceOptions || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSets])

  const handleBirthdateChange = useCallback(
    (date) => {
      const parsedDate = DateTime.fromISO(date)
      if (!parsedDate.isValid) {
        setBirthdateError(t('client-search.error.date'))
      } else {
        setBirthdateError(null)
      }
      updateFilters('birthdate', date)
    },
    [t, updateFilters]
  )

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = filters?.['sortBy'] === property && filters?.['sortOrder'] === 'asc'
      setClientSearchFilters({
        ...filters,
        sortBy: property,
        sortOrder: isAsc ? 'desc' : 'asc',
      })
      handleSearchClient(filters?.['page'], isAsc ? 'desc' : 'asc', property)
    },
    [filters, handleSearchClient, setClientSearchFilters]
  )

  const updatePinState = useCallback(
    async (event, index) => {
      event.stopPropagation()

      let nextPinnedClients = pinnedClients || []
      if (nextPinnedClients.find((client) => client.id === clientSearchResults?.[index].id))
        nextPinnedClients = nextPinnedClients.filter(
          (client) => client.id !== clientSearchResults?.[index].id
        )
      else if (clientSearchResults?.[index])
        nextPinnedClients = [...nextPinnedClients, clientSearchResults?.[index]]
      setPinnedClients(nextPinnedClients)
      try {
        if (userId) {
          const preference = {
            id: userPinnedClientsPreferenceId,
            name: 'pinned-clients',
            type: Preference.type.USER,
            value: nextPinnedClients.map((client) => client.id).join('|') || '',
          }
          if (userPinnedClientsPreferenceId) {
            await UserPreferenceControllerService.updateUserPreference(
              userId,
              userPinnedClientsPreferenceId,
              preference
            )
          } else {
            const preferenceResp = await UserPreferenceControllerService.createUserPreference(
              userId,
              preference
            )
            setUserPinnedClientsPreferenceId(preferenceResp.id)
          }
        }
      } catch (err) {
        handleApiError(err)
      }
    },
    [
      clientSearchResults,
      handleApiError,
      pinnedClients,
      setPinnedClients,
      setUserPinnedClientsPreferenceId,
      userId,
      userPinnedClientsPreferenceId,
    ]
  )

  const handleCheckOverridePrivacy = useCallback(
    async (reason: string) => {
      if (scrutinyId) {
        const overrideDto: PrivacyDirectiveOverrideDTO = {
          entityId: scrutinyId,
          entityType: 'CLIENT',
          reason: reason,
        }
        PrivacyDirectiveOverrideControllerService.createDirectiveOverride(scrutinyId, overrideDto)
          .then(() => {
            navigate(`/clients/client-record/${scrutinyId}`)
            setOpenOverridePrivacyDialog(false)
            setScrutinyId(undefined)
          })
          .catch((error) => {
            setOpenOverridePrivacyDialog(false)
            setScrutinyId(undefined)
            handleApiError(error)
          })
      }
    },
    [handleApiError, navigate, scrutinyId]
  )

  const getClientSearchResults = useCallback(() => {
    return (
      <div className="client-search-results">
        <Table aria-label="simple table" className="table">
          <EnhancedTableHead
            onRequestSort={handleRequestSort}
            order={filters?.['sortOrder']}
            orderBy={filters?.['sortBy']}
          />
          {clientSearchResults && clientSearchResults.length <= 0 && (
            <TableBody>
              <TableRow className="row">
                <TableCell className="cell" colSpan={10}>
                  <Alert severity="info">{t('client-search.alert.no-result')}</Alert>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {clientSearchResults && clientSearchResults.length > 0 && (
            <TableBody>
              {clientSearchResults.map((row, index) => {
                const preferredNameIndex =
                  clientSearchResults[index].names?.findIndex((name) => name.preferredName) || 0
                const preferredName = row.names?.[preferredNameIndex]
                return (
                  <TableRow className="special-row" hover key={row.id}>
                    <TableCell>
                      {onAddClient === undefined && (
                        <IconButton
                          className={`pin-icon ${
                            pinnedClients?.find((client) => client.id === row.id)
                              ? ''
                              : 'not-pinned'
                          }`}
                          onClick={(event) => updatePinState(event, index)}
                          size="small"
                        >
                          {pinnedClients?.find((client) => client.id === row.id) ? (
                            <PushPinIcon color="primary" />
                          ) : (
                            <PushPinOutlinedIcon />
                          )}
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <MISButton
                        onClick={() => {
                          if (onAddClient) {
                            onAddClient(row)
                          } else {
                            if (row.gender?.code) {
                              setNavItemSelected('navigation.left-nav-menu.client.dashboard')
                              navigate(`/clients/client-record/${row.id}/dashboard`)
                            } else if (row.id) {
                              setScrutinyId(row.id)
                              setOpenOverridePrivacyDialog(true)
                            }
                          }
                        }}
                        sx={{ p: 0, textAlign: 'left' }}
                        variant="text"
                      >
                        {[
                          preferredName?.firstName,
                          preferredName?.middleName,
                          preferredName?.lastName,
                        ]
                          .filter(Boolean)
                          .join(' ')
                          .trim()}
                        {row.masked && (
                          <span style={{ marginLeft: '1rem' }}>
                            <MISHideIcon />
                          </span>
                        )}
                      </MISButton>
                    </TableCell>
                    <TableCell>
                      {genderOptions &&
                        genderOptions.find(
                          (x) =>
                            x.code === row.gender?.code &&
                            x.codeSystemOid === row.gender?.codeSystemOid
                        )?.name}
                    </TableCell>
                    <TableCell>
                      {row.birthdate ? isoDateToDisplayFormat(row.birthdate) : ''}
                    </TableCell>
                    <TableCell>
                      {row.birthdate
                        ? Math.floor(
                            DateTime.now()
                              .diff(DateTime.fromJSDate(new Date(row.birthdate as string)), 'years')
                              .toObject().years as number
                          )
                        : ''}
                    </TableCell>
                    <TableCell>{row.fileNumber}</TableCell>
                    <TableCell>
                      {row.clientIdentifiers?.map((identifier, index) => (
                        <Stack direction="row" key={index} spacing={1}>
                          <Typography className="typography" fontWeight="600">
                            {getNameFromCodedConcept(identifierTypeOptions, identifier.type)}:
                          </Typography>
                          <Typography className="typography">{identifier.value}</Typography>
                        </Stack>
                      ))}
                    </TableCell>
                    <TableCell>
                      <MISChip
                        color={
                          getNameFromCodedConcept(statusOptions, row.status) === 'Active'
                            ? 'primary'
                            : 'default'
                        }
                        label={getNameFromCodedConcept(statusOptions, row.status)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          )}
          {(!clientSearchResults || (clientSearchResults && clientSearchResults.length === 0)) && (
            <TableBody>
              <TableRow style={{ height: 40 * filters?.['pageSize'] }}>
                <TableCell colSpan={8} />
              </TableRow>
            </TableBody>
          )}
          <TableFooter className="footer">
            <TableRow className="row">
              <TableCell className="cell" />
              <TableCell className="cell" />
              <TableCell className="cell" />
              <TableCell className="cell" />
              <TableCell className="cell" />
              <TableCell className="cell" />
              <TableCell className="cell" />
              {onAddClient === undefined && (
                <TableCell className="cell">
                  <Box className="add-client">
                    <Box className="container">
                      <MISButton
                        className={
                          !(
                            displayResultSection ||
                            (clientSearchResults &&
                              searchResults?.length &&
                              searchResults.length > 0)
                          )
                            ? 'button primary disabled'
                            : 'button primary'
                        }
                        disabled={
                          !(
                            displayResultSection ||
                            (clientSearchResults &&
                              searchResults?.length &&
                              searchResults.length > 0)
                          )
                        }
                        onClick={() => navigate('/clients/create')}
                      >
                        Add Client
                      </MISButton>
                    </Box>
                  </Box>
                </TableCell>
              )}
            </TableRow>
            {filters?.['totalElements'] !== undefined && filters?.['page'] !== undefined && (
              <TableRow className="row">
                <TablePagination
                  className="pagination"
                  colSpan={10}
                  count={filters?.['totalElements']}
                  onPageChange={handleChangePage}
                  page={filters?.['page']}
                  rowsPerPage={DEFAULT_PAGE_SIZE}
                  rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </div>
    )
  }, [
    clientSearchResults,
    displayResultSection,
    emptyRows,
    filters,
    genderOptions,
    handleChangePage,
    handleRequestSort,
    identifierTypeOptions,
    navigate,
    onAddClient,
    pinnedClients,
    searchResults?.length,
    setNavItemSelected,
    statusOptions,
    t,
    updatePinState,
  ])

  const getClientSearchContent = useCallback(() => {
    return (
      <form className="search-content" onSubmit={(event) => event.preventDefault()}>
        {showSearchError && !validSearchInput && (
          <Alert className="error" severity="error">
            {t('client-search.error.client-search-parameter')}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.client-name')}>
              <MISTextField
                label={t('client-search.content.client-name')}
                onChange={(event) => updateFilters('name', event.target.value)}
                value={filters?.['name']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              disabled
              id="voucher-number"
              label={t('client-search.content.voucher-number')}
              onChange={(event) => updateFilters('voucherNumber', event.target.value)}
              value={filters?.['voucherNumber']}
            />
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.file-number')}>
              <MISTextField
                id="file-number"
                inputProps={{ min: 0 }}
                label={t('client-search.content.file-number')}
                onChange={(event) => updateFilters('fileNumber', event.target.value)}
                onKeyDown={(event) => {
                  if (event?.key === '-' || event?.key === 'e' || event?.key === '+')
                    event.preventDefault()
                }}
                type="number"
                value={filters?.['fileNumber']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.identifier-type')}>
              <MISSelectDropdown
                id="identifier-type"
                label={t('client-search.content.identifier-type')}
                onChange={(event) => updateFilters('identifierType', event.target.value)}
                options={identifierTypeOptions.map((option) => ({
                  label: option.name as string,
                  value: option,
                }))}
                value={filters?.['identifierType']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.identifier-number')}>
              <MISTextField
                id="identifier-number"
                label={t('client-search.content.identifier-number')}
                onChange={(event) => updateFilters('identifierNumber', event.target.value)}
                value={filters?.['identifierNumber']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.gender')}>
              <MISSelectDropdown
                id="gender"
                label={t('client-search.content.gender')}
                onChange={(event) => updateFilters('gender', event.target.value)}
                options={genderOptions.map((option) => ({
                  label: option.name as string,
                  value: option.id,
                }))}
                value={filters?.['gender']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              error={!!birthdateError}
              helperText={birthdateError}
              label={t('client-search.content.birthdate-exact')}
              onChange={(date) => {
                if (date) {
                  handleBirthdateChange(date)
                } else {
                  updateFilters('birthdate', '')
                  setBirthdateError(null)
                }
              }}
              readOnly={false}
              tooltipArrow
              tooltipText={t('client-search.tooltip.birthdate')}
              value={filters?.['birthdate']}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.age')}>
              <MISTextField
                id="age"
                label={t('client-search.content.age')}
                onChange={(event) => updateFilters('age', event.target.value)}
                onKeyDown={(event) => {
                  if (event?.key === '-' || event?.key === 'e' || event?.key === '+')
                    event.preventDefault()
                }}
                type="number"
                value={filters?.['age']}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.state-province')}>
              <MISSelectMultiDropdown
                label={t('client-search.content.state-province')}
                onChange={(event) => updateFilters('stateProvince', event.target.value)}
                options={provinceOptions.map((option) => {
                  return {
                    label: option.name,
                    value: option.id,
                  }
                })}
                value={filters?.['stateProvince'] || []}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip arrow placement="top" title={t('client-search.tooltip.status')}>
              <MISSelectMultiDropdown
                label={t('client-search.content.status')}
                onChange={(event) => updateFilters('status', event.target.value)}
                options={statusOptions.map((option) => {
                  return {
                    label: option.name,
                    value: option.id,
                  }
                })}
                value={filters?.['status'] || []}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Divider className="divider" />
        <Box className="actions">
          <MISButton
            onClick={() => {
              updateFilters('page', DEFAULT_PAGE_NUMBER)
              handleSearchClient(DEFAULT_PAGE_NUMBER)
            }}
            sx={{ marginLeft: '1rem' }}
            type="submit"
          >
            {t('common.button.search')}
          </MISButton>
        </Box>
      </form>
    )
  }, [
    birthdateError,
    filters,
    genderOptions,
    handleBirthdateChange,
    handleSearchClient,
    identifierTypeOptions,
    provinceOptions,
    showSearchError,
    statusOptions,
    t,
    updateFilters,
    validSearchInput,
  ])

  return (
    <MISBaseContainer>
      <div className="search-client">
        <MISImageContentContainer>
          <Content
            content={getClientSearchContent()}
            heading={t('client-search.title.client-list')}
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
        {!!(
          displayResultSection ||
          (clientSearchResults && searchResults?.length && searchResults.length > 0)
        ) && (
          <MISImageContentContainer hideImage>
            <Content
              content={getClientSearchResults()}
              heading={t('client-search.title.client-search-results')}
              isCollapsible={false}
              isDivider
            />
          </MISImageContentContainer>
        )}
      </div>
      <OverridePrivacyDialog
        onCancel={() => setOpenOverridePrivacyDialog(false)}
        onSave={handleCheckOverridePrivacy}
        open={openOverridePrivacyDialog}
      />
    </MISBaseContainer>
  )
}

export default SearchClient
