import { atom } from 'recoil'
import { EncounterServiceTemplateDTO } from 'services/openapi'

export const serviceTemplateState = atom<EncounterServiceTemplateDTO | null>({
  default: null,
  key: 'serviceTemplate',
})

export const encounterIdState = atom<string>({
  default: '',
  key: 'encounterIdState',
})

export const encounterServiceIdState = atom<string>({
  default: '',
  key: 'encounterServiceIdState',
})

export const chartingEntryIdState = atom<string>({
  default: '',
  key: 'chartingEntryIdState',
})
