import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown, {
  ChipValueType,
} from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { multiCriteriaSort } from 'common/utils/utils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { ErrorType, getFormattedOptions } from 'modules/shared/utils'
import { alertsAtom, programsAtom } from 'recoil/atoms'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  AlertControllerService,
  AlertDTO,
  CancelablePromise,
  CodedRef,
  ProgramTerse,
} from 'services/openapi'
import { MIS_TIME_UNITS } from 'services/terminologyConstants'
import './Alerts.scss'

type AlertsDataRowType = { data: AlertDTO; isCollapsed?: boolean }
type AlertsDataType = { rows: AlertsDataRowType[] }

const ENTITY = 'Alert'

const Alerts = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const programsOptions = useRecoilValue(programsAtom)
  const timeUnitOptions = useRecoilValue(terminologySelector(MIS_TIME_UNITS))
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [alerts, setAlerts] = useRecoilState(alertsAtom)
  const [alertsData, setAlertsData] = useState<AlertsDataType>({ rows: [] })
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [deleteIndex, setDeleteIndex] = useState(-1)

  useEffect(() => {
    const getAlerts = async () => {
      try {
        const response = await AlertControllerService.getAlerts()
        if (response?.content) {
          setAlerts(response.content)
        }
      } catch (err) {
        handleApiError(err)
      }
    }
    getAlerts()
  }, [handleApiError, programsOptions, setAlerts])

  const sortCriteria = useMemo(
    () => [
      {
        key: 'data.code',
        order: 'asc' as const,
        type: 'string' as const,
      },
    ],
    []
  )

  const sortAlertsData = useCallback(
    (arrData: AlertsDataRowType[]) => {
      // Last parameter is keypath of field that moves deactivated at the bottom
      return multiCriteriaSort(arrData, sortCriteria, 'data.effective.endDate')
    },
    [sortCriteria]
  )

  const handleCreateAlertsData = useCallback(() => {
    const parsedData = alerts
      ? [...alerts].map((obj: AlertDTO) => {
          return {
            data: {
              ...obj,
            },
            isCollapsed: true,
          }
        })
      : []

    setAlertsData({
      rows: sortAlertsData(parsedData),
    })
  }, [alerts, setAlertsData, sortAlertsData])

  useEffect(() => {
    handleCreateAlertsData()
  }, [alerts, handleCreateAlertsData])

  const getError = useCallback(
    (field: string) => {
      const errorObj = errors.find((error) => error.field === field)
      return errorObj ? errorObj.message : ''
    },
    [errors]
  )

  const onAddRow = useCallback(
    () =>
      setAlertsData({
        rows: [
          ...alertsData.rows,
          {
            data: {
              code: undefined,
              description: undefined,
              effective: undefined,
              id: undefined,
              name: undefined,
              programs: [],
              reminderInterval: undefined,
              reminderIntervalUnit: undefined,
            },
            isCollapsed: false,
          },
        ],
      }),
    [alertsData]
  )

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, value: string | string[] | number | CodedRef) => {
      if (!isDirty) setIsDirty(true)

      const rows = alertsData.rows?.map((row: AlertsDataRowType, index: number) => {
        let data: AlertDTO
        if (index === rowIndex) {
          switch (field) {
            case 'code':
              data = { ...row.data, code: value as string }
              break
            case 'name':
              data = { ...row.data, name: value as string }
              break
            case 'description':
              data = { ...row.data, description: value as string }
              break
            case 'programs':
              data = { ...row.data, programs: value as string[] }
              break
            case 'reminderInterval':
              data = {
                ...row.data,
                reminderInterval: value as number,
                ...(value === undefined || Number(value) === 0
                  ? { reminderIntervalUnit: undefined }
                  : {}),
              }
              break
            case 'reminderIntervalUnit':
              data = { ...row.data, reminderIntervalUnit: value as CodedRef }
              break
            case 'startDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, startDate: value as string },
              }
              break
            case 'endDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, endDate: value as string },
              }
              break
            default:
              return row
          }
          return { ...row, data }
        }
        return row
      })
      setAlertsData({ rows } as AlertsDataType)
    },
    [alertsData, isDirty, setIsDirty]
  )

  const onCollapseRow = useCallback(
    (rowIndex: number) => {
      alertsData.rows[rowIndex].isCollapsed = !alertsData.rows[rowIndex].isCollapsed
      setAlertsData({ ...alertsData })
    },
    [alertsData]
  )

  const sustainCollapsedState = useCallback(
    (alerts: AlertDTO[]) => {
      return alertsData.rows.map((alert: AlertsDataRowType, index: number) => {
        return {
          data: alerts[index],
          isCollapsed: alert.isCollapsed,
        }
      })
    },
    [alertsData.rows]
  )

  const onSave = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      alertsData.rows?.forEach((row: AlertsDataRowType, i: number) => {
        if (!row.data.code) {
          isValid = false
          errors.push({
            field: `alert-code-${i}`,
            message: t('alerts.validation.alert-code-required'),
          })
        }
        if (!row.data.name) {
          isValid = false
          errors.push({
            field: `alert-name-${i}`,
            message: t('alerts.validation.alert-name-required'),
          })
        }
        if (row.data.reminderInterval && !row.data.reminderIntervalUnit) {
          isValid = false
          errors.push({
            field: `alert-reminder-interval-unit-${i}`,
            message: t('alerts.validation.reminder-interval-unit-required'),
          })
        }
        if (!row.data.effective?.startDate) {
          isValid = false
          errors.push({
            field: `alert-startDate-${i}`,
            message: t('alerts.validation.start-date-required'),
          })
        }
      })
      return { errors, isValid }
    }
    const saveAlerts = async (updatedAlerts: AlertDTO[]) => {
      const promises: CancelablePromise<AlertDTO>[] = []
      updatedAlerts.forEach((alert) => {
        if (alert?.id) {
          promises.push(AlertControllerService.updateAlert(alert?.id, alert))
        } else {
          promises.push(AlertControllerService.createAlert(alert))
        }
      })

      try {
        const responses = await Promise.all(promises)
        setAlerts(responses)
        setAlertsData({ rows: sortAlertsData(sustainCollapsedState(responses)) })
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
    }
    const { errors, isValid } = validate()
    if (isValid) {
      const updatedAlerts = alertsData.rows.map((row: AlertsDataRowType) => row.data)
      saveAlerts(updatedAlerts)
      setErrors([])
    } else {
      setErrors(errors)
    }
  }, [
    setIsDirty,
    alertsData.rows,
    t,
    setAlerts,
    sortAlertsData,
    sustainCollapsedState,
    showSnackSuccess,
    handleApiError,
  ])

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      const deleteAlert = async (alertId: string) => {
        await AlertControllerService.deleteAlert(alertId)
          .then(() => {
            alertsData.rows.splice(rowIndex, 1)
            setAlertsData({ rows: alertsData.rows })
            showSnackSuccess(t('api.save-success'))
          })
          .catch((error) => {
            handleApiError(error)
          })
      }

      setDeleteIndex(-1)
      const alertId = alertsData.rows[rowIndex].data?.id
      if (alertId) {
        deleteAlert(alertId)
      } else {
        alertsData.rows.splice(rowIndex, 1)
        setAlertsData({ rows: alertsData.rows })
      }
    },
    [handleApiError, alertsData, showSnackSuccess, t]
  )

  const rowForm = useCallback(
    (
      row: AlertsDataRowType,
      rowIndex: number,
      onChangeRow: (rowIndex: number, field: string, value: string | any) => void
    ) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <MISTextField
              error={!!getError(`alert-code-${rowIndex}`)}
              helperText={getError(`alert-code-${rowIndex}`)}
              id="code"
              label={t('alerts.code')}
              onChange={(event) => onChangeRow(rowIndex, 'code', event.target.value)}
              required
              value={row.data?.code}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              error={!!getError(`alert-name-${rowIndex}`)}
              helperText={getError(`alert-name-${rowIndex}`)}
              id="name"
              label={t('alerts.name')}
              onChange={(event) => onChangeRow(rowIndex, 'name', event.target.value)}
              required
              value={row.data?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <MISTextField
              id="description"
              label={t('alerts.description')}
              onChange={(event) => onChangeRow(rowIndex, 'description', event.target.value)}
              value={row.data?.description}
            />
          </Grid>
          <Grid item xs={12}>
            <MISSelectMultiDropdown
              chipValues={
                programsOptions.filter((x: ProgramTerse) =>
                  row.data.programs?.includes(x.id as string)
                ) as ChipValueType[]
              }
              handleChipDelete={(program) => {
                onChangeRow(
                  rowIndex,
                  'programs',
                  row.data.programs?.filter((id) => id !== program?.id)
                )
              }}
              id="visible-to"
              isChip
              label={t('alerts.visible-to')}
              onChange={(event) => onChangeRow(rowIndex, 'programs', event.target.value)}
              options={getFormattedOptions(programsOptions, true)}
              placeholder={
                row.data.programs?.length === 0 ? t('alerts.labels.visible-to-all-programs') : ''
              }
              value={row.data.programs}
            />
          </Grid>
          <Grid item xs={3}>
            <MISTextField
              id="reminder-interval"
              inputProps={{ min: 0 }}
              label={t('alerts.reminder-interval')}
              onChange={(event) => onChangeRow(rowIndex, 'reminderInterval', event.target.value)}
              type="number"
              value={row.data?.reminderInterval}
            />
          </Grid>
          <Grid item xs={3}>
            <MISSelectDropdown
              disabled={
                row.data?.reminderInterval === undefined || Number(row.data?.reminderInterval) === 0
              }
              error={!!getError(`alert-reminder-interval-unit-${rowIndex}`)}
              helperText={getError(`alert-reminder-interval-unit-${rowIndex}`)}
              id="reminder-interval-unit"
              label={t('alerts.reminder-interval-unit')}
              onChange={(event) =>
                onChangeRow(rowIndex, 'reminderIntervalUnit', event.target.value)
              }
              options={getFormattedOptions(timeUnitOptions)}
              required={row.data?.reminderInterval ? true : false}
              value={timeUnitOptions.find(
                (timeUnit) => timeUnit.code === row.data.reminderIntervalUnit?.code
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <MISDatePicker
              error={!!getError(`alert-startDate-${rowIndex}`)}
              helperText={getError(`alert-startDate-${rowIndex}`)}
              isDefaultToday
              label={t('alerts.start-date')}
              onChange={(value: ChangeEvent<HTMLInputElement>) => {
                onChangeRow(rowIndex, 'startDate', value)
              }}
              required
              value={row.data?.effective?.startDate}
            />
          </Grid>
          <Grid item xs={3}>
            <MISDatePicker
              label={t('alerts.end-date')}
              onChange={(value: ChangeEvent<HTMLInputElement>) => {
                onChangeRow(rowIndex, 'endDate', value)
              }}
              value={row.data?.effective?.endDate}
            />
          </Grid>
        </Grid>
      )
    },
    [getError, programsOptions, timeUnitOptions, t]
  )

  const getProgramLabels = useCallback(
    (programIds) => {
      const labels: string[] = []
      programsOptions.forEach((o) => {
        if (programIds.includes(o.id)) {
          labels.push(o?.name as string)
        }
      })

      return labels.join(', ')
    },
    [programsOptions]
  )

  const rowHeader = useCallback(
    (row: AlertsDataRowType) => {
      return (
        <>
          {row.data?.code && <span>{`${row.data.code}`}</span>}
          {row.data?.name && <span>{` | ${row.data.name}`}</span>}
          {row.data?.programs && row.data?.programs.length > 0 && (
            <span>{` | ${getProgramLabels(row.data?.programs)}`}</span>
          )}
          {row.data?.effective?.startDate && (
            <span>{` | ${isoDateToDisplayFormat(row.data?.effective?.startDate)}`}</span>
          )}
          {row.data?.effective?.endDate && (
            <span>{` to ${isoDateToDisplayFormat(row.data?.effective?.endDate)}`}</span>
          )}
        </>
      )
    },
    [getProgramLabels]
  )

  return (
    <Box className="alerts-container" sx={{ mt: 6 }}>
      <SectionHeader
        addLabel={t('alerts.labels.add-alert')}
        onAdd={onAddRow}
        onSave={onSave}
        saveLabel={t('common.button.save')}
        title={t('alerts.labels.title')}
      />
      <MISAccordionContainer
        emptyDataMessage={t('alerts.empty-message')}
        errors={errors.length === 0 ? [] : [t('common.validation.errors')]}
        onChangeRow={onChangeRow}
        onCollapseRow={onCollapseRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowForm}
        rowHeader={rowHeader}
        rows={alertsData.rows}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </Box>
  )
}

export default Alerts
