import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { domainsAtom, programGroupAtom, programsAtom, purposesAtom, typesAtom } from 'recoil/atoms'
import {
  ProgramControllerService,
  ProgramDomainAssociationControllerService,
  ProgramGroupAssociationControllerService,
  ProgramPurposeAssociationControllerService,
  ProgramTypeAssociationControllerService,
} from 'services/openapi'

export default function Programs() {
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState({})
  const [programGroupAssociations, setProgramGroupAssociations] = useState(new Map())
  const [programGroups, setProgramGroups] = useState([])
  const [typeAssociations, setTypeAssociations] = useState(new Map())
  const [types, setTypes] = useState([])
  const [purposeAssociations, setPurposeAssociations] = useState(new Map())
  const [purposes, setPurposes] = useState([])
  const [domainAssociations, setDomainAssociations] = useState(new Map())
  const [domains, setDomains] = useState([])
  const [openDomainsLinkModal, setOpenDomainsLinkModal] = useState(false)
  const [openTypesLinkModal, setOpenTypesLinkModal] = useState(false)
  const [openProgramGroupsLinkModal, setOpenProgramGroupsLinkModal] = useState(false)
  const [openPurposesLinkModal, setOpenPurposesLinkModal] = useState(false)

  const programGroupsList = useRecoilValue(programGroupAtom)
  const typesList = useRecoilValue(typesAtom)
  const purposesList = useRecoilValue(purposesAtom)
  const domainsList = useRecoilValue(domainsAtom)

  const getEntitiesServiceCall = ProgramControllerService.listPrograms
  const createEntityServiceCall = ProgramControllerService.createProgram
  const updateEntityServiceCall = ProgramControllerService.updateProgram
  const updateAtomCall = useSetRecoilState(programsAtom)

  const associationTablesHeight = '500px;'

  const saveTypeAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      ProgramTypeAssociationControllerService.updateProgramTypeAssociation,
      ProgramControllerService.addTypeToProgram,
      setOpenTypesLinkModal,
      buildTypeAssociationsMap,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const savePurposesAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      ProgramPurposeAssociationControllerService.updateProgramPurposeAssociation,
      ProgramControllerService.addPurposeToProgram,
      setOpenPurposesLinkModal,
      buildPurposeAssociationsMap,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const saveProgramGroupAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      ProgramGroupAssociationControllerService.updateProgramGroupAssociation,
      ProgramControllerService.addProgramGroupToProgram,
      setOpenProgramGroupsLinkModal,
      buildProgramGroupAssociationsMap,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const saveDomainAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      ProgramDomainAssociationControllerService.updateProgramDomainAssociation,
      ProgramControllerService.addDomainToProgram,
      setOpenDomainsLinkModal,
      buildDomainAssociationsMap,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const buildAssociationMap = useCallback(
    (
      existingAssociations,
      otherEntities,
      otherEntityIdFieldName,
      associationsSetter,
      entitiesSetter
    ) => {
      buildAssociationMapUtil(
        existingAssociations,
        otherEntities,
        otherEntityIdFieldName,
        associationsSetter,
        entitiesSetter,
        handleApiError,
        selectedRow
      )
    },
    [handleApiError, selectedRow]
  )

  const buildProgramGroupAssociationsMap = useCallback(() => {
    ProgramControllerService.getProgramGroupAssociationsForProgram(selectedRow.id).then(
      (data) => {
        setProgramGroups(programGroupsList)
        buildAssociationMap(
          data,
          programGroupsList,
          'programGroupId',
          setProgramGroupAssociations,
          setProgramGroups
        )
      },
      (error) => {
        handleApiError(error)
      }
    )
  }, [
    programGroupsList,
    setProgramGroupAssociations,
    setProgramGroups,
    handleApiError,
    buildAssociationMap,
    selectedRow.id,
  ])

  const buildTypeAssociationsMap = useCallback(() => {
    ProgramControllerService.getProgramTypeAssociationsForProgram(selectedRow.id).then(
      (data) => {
        setTypes(typesList)
        buildAssociationMap(data, typesList, 'typeId', setTypeAssociations, setTypes)
      },
      (error) => {
        handleApiError(error)
      }
    )
  }, [
    typesList,
    setTypeAssociations,
    setTypes,
    handleApiError,
    selectedRow.id,
    buildAssociationMap,
  ])

  const buildPurposeAssociationsMap = useCallback(() => {
    ProgramControllerService.getProgramPurposeAssociationsForProgram(selectedRow.id).then(
      (data) => {
        setPurposes(purposesList)
        buildAssociationMap(data, purposesList, 'purposeId', setPurposeAssociations, setPurposes)
      },
      (error) => {
        handleApiError(error)
      }
    )
  }, [
    purposesList,
    handleApiError,
    setPurposeAssociations,
    setPurposes,
    selectedRow.id,
    buildAssociationMap,
  ])

  const buildDomainAssociationsMap = useCallback(() => {
    ProgramControllerService.getProgramDomainAssociationsForProgram(selectedRow.id).then(
      (data) => {
        setDomains(domainsList)
        let otherEntities = []
        domainsList.forEach((domain) => {
          let otherEntity = {
            id: domain.id,
            name: domain.domainName,
          }
          otherEntities.push(otherEntity)
        })

        buildAssociationMap(data, otherEntities, 'domainId', setDomainAssociations, setDomains)
      },
      (error) => {
        handleApiError(error)
      }
    )
  }, [
    domainsList,
    handleApiError,
    setDomainAssociations,
    setDomains,
    selectedRow.id,
    buildAssociationMap,
  ])

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      buildProgramGroupAssociationsMap()
      buildTypeAssociationsMap()
      buildPurposeAssociationsMap()
      buildDomainAssociationsMap()
    }
  }, [
    selectedRow,
    buildProgramGroupAssociationsMap,
    buildTypeAssociationsMap,
    buildPurposeAssociationsMap,
    buildDomainAssociationsMap,
  ])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('programs.title.programs')}</h1>
        {t('programs.title.programs-description')}

        <EntityTemplate
          createEntityServiceCall={createEntityServiceCall}
          entityName={t('programs.title.programs')}
          getEntitiesServiceCall={getEntitiesServiceCall}
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={updateEntityServiceCall}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box>
          <Grid container direction="row">
            <Grid container direction="column" item md={6} spacing={2}>
              <Grid item>
                <Box md={6} sm={6} sx={{ p: 2 }}>
                  <h3>
                    {t('purpose-groups.title.association-header')} {selectedRow.name}
                  </h3>
                  <AssociationTable
                    associations={purposeAssociations}
                    closeLinkModalCallBack={() => setOpenPurposesLinkModal(false)}
                    entity={selectedRow}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('purposes.title.purposes')}
                    openLinkModal={openPurposesLinkModal}
                    openLinkModalCallBack={() => setOpenPurposesLinkModal(true)}
                    otherEntities={purposes}
                    saveCallBack={savePurposesAssociations}
                    showOtherEntityDatesTable
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box md={6} sm={6} sx={{ p: 2 }}>
                  <h3>
                    {t('programs.title.association-header')} {selectedRow.name}
                  </h3>
                  <AssociationTable
                    associations={programGroupAssociations}
                    closeLinkModalCallBack={() => setOpenProgramGroupsLinkModal(false)}
                    entity={selectedRow}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('program-groups.title.program-groups')}
                    openLinkModal={openProgramGroupsLinkModal}
                    openLinkModalCallBack={() => setOpenProgramGroupsLinkModal(true)}
                    otherEntities={programGroups}
                    saveCallBack={saveProgramGroupAssociations}
                    showOtherEntityDatesTable
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container direction="column" item md={6} spacing={2}>
              <Grid item>
                <Box sx={{ p: 2 }}>
                  <h3>
                    {t('type-groups.title.association-header')} {selectedRow.name}
                  </h3>
                  <AssociationTable
                    associations={typeAssociations}
                    closeLinkModalCallBack={() => setOpenTypesLinkModal(false)}
                    entity={selectedRow}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('types.title.types')}
                    openLinkModal={openTypesLinkModal}
                    openLinkModalCallBack={() => setOpenTypesLinkModal(true)}
                    otherEntities={types}
                    saveCallBack={saveTypeAssociations}
                    showOtherEntityDatesTable
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ p: 2 }}>
                  <h3>
                    {t('domains.title.association-header')} {selectedRow.name}
                  </h3>
                  <AssociationTable
                    associations={domainAssociations}
                    closeLinkModalCallBack={() => setOpenDomainsLinkModal(false)}
                    entity={selectedRow}
                    fixedHeightInPixels={associationTablesHeight}
                    headerName={t('domains.title.domains')}
                    openLinkModal={openDomainsLinkModal}
                    openLinkModalCallBack={() => setOpenDomainsLinkModal(true)}
                    otherEntities={domains}
                    saveCallBack={saveDomainAssociations}
                    showOtherEntityDatesTable={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
