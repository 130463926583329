import { define, oneOf, string } from '@react-form-builder/core'
import MISButton from 'common/components/MISButton'

const MyIcon = () => {
  return (
    <span
      style={{ backgroundColor: 'rgba(150,130,20,0.5)', borderRadius: 8, height: 16, width: 16 }}
    />
  )
}
export const MISButtonForm = define(MISButton, 'MatButton')
  // component name displayed in the component panel in the designer
  .name('MISButton')
  .icon(MyIcon)
  // define the component properties that we want to edit in the designer
  .props({
    // button text
    children: string.named('Caption').default('Button'),
    // button color
    color: oneOf('inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'),
  })
