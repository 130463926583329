import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import MISButton from 'common/components/MISButton'
import useUnsavedChangesPrompt from 'common/hooks/useUnsavedChangesPrompt'

type UnsavedChangesWarningProps = {
  isDirty: boolean
}

const UnsavedChangesWarningPrompt = ({ isDirty }: UnsavedChangesWarningProps) => {
  const { t } = useTranslation('common')

  const [showPrompt, confirmNavigation, cancelNavigation] = useUnsavedChangesPrompt(isDirty)

  return (
    <Dialog onClose={cancelNavigation} open={showPrompt}>
      <DialogTitle>
        <Typography variant="h3"> {t('unsaved-changes-warning.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2"> {t('unsaved-changes-warning.warning-message')}</Typography>
      </DialogContent>
      <DialogActions>
        <MISButton onClick={confirmNavigation} size="small" variant="outlined">
          {t('unsaved-changes-warning.button-continue')}
        </MISButton>
        <MISButton onClick={cancelNavigation} size="small">
          {t('unsaved-changes-warning.button-stay')}
        </MISButton>
      </DialogActions>
    </Dialog>
  )
}

export default UnsavedChangesWarningPrompt
