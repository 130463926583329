import { Chip, ChipProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

const MISChip = (props: ChipProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <Chip {...props} size={props.size || 'small'} />
    </ThemeProvider>
  )
}

export default MISChip
