import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useRecoilValue } from 'recoil'
import MISTextField from 'common/components/form/MISTextField'
import { useSnack } from 'common/components/snackbar/useSnack'
import { dateNowIsoString } from 'common/utils/DateUtils'
import Contacts from 'modules/shared/Contacts'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { getValueSetType } from 'modules/shared/utils'
import { personnelDetailsState } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientContact,
  CodedRef,
  PersonnelContactControllerService,
  PersonnelContactDTO,
} from 'services/openapi'
import {
  MIS_CONTACT_EMAIL_TYPES,
  MIS_CONTACT_METHODS,
  MIS_CONTACT_PHONE_TYPES,
  MIS_CONTACT_SOCIAL_MEDIA_TYPES,
} from 'services/terminologyConstants'

const ProviderContactsSection = () => {
  const { showSnackError, showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')
  const personnelDetails = useRecoilValue(personnelDetailsState)
  const contactMethods = useRecoilValue(terminologySelector(MIS_CONTACT_METHODS))
  const contactPhoneTypes = useRecoilValue(terminologySelector(MIS_CONTACT_PHONE_TYPES))
  const contactEmailTypes = useRecoilValue(terminologySelector(MIS_CONTACT_EMAIL_TYPES))
  const contactSocialMediaTypes = useRecoilValue(
    terminologySelector(MIS_CONTACT_SOCIAL_MEDIA_TYPES)
  )
  const [providerContactsData, setProviderContactsData] = useState<PersonnelContactDTO[]>([])
  const [providerContacts, setProviderContacts] = useState<any[]>([])
  const [saveTriggered, setSaveTriggered] = useState(false)

  useEffect(() => {
    const getContacts = async (personnelId: string) => {
      const response = await PersonnelContactControllerService.getPersonnelContacts(personnelId)
      setProviderContactsData(response?.content || [])
    }

    if (personnelDetails?.id) getContacts(personnelDetails.id)
  }, [personnelDetails?.id])

  const getContactMethodTypes = useCallback(
    (contactMethod: CodedRef | undefined) => {
      switch (contactMethod?.code) {
        case 'PHONE':
          return contactPhoneTypes
        case 'EMAIL':
          return contactEmailTypes
        case 'SOCIAL_MEDIA_WEBSITE':
          return contactSocialMediaTypes
      }
      return []
    },
    [contactEmailTypes, contactPhoneTypes, contactSocialMediaTypes]
  )

  const handleCreateContacts = useCallback(() => {
    if (!personnelDetails?.id) {
      return
    }
    const parsedContacts: any[] = []
    providerContactsData.forEach((contact: PersonnelContactDTO) => {
      parsedContacts.push({
        contactMethod: contact.contactMethod
          ? getValueSetType(contactMethods, contact.contactMethod)
          : undefined,
        contactMethodType: contact.contactMethodType
          ? getValueSetType(getContactMethodTypes(contact.contactMethod), contact.contactMethodType)
          : undefined,
        endDate: contact.effective?.endDate ? contact.effective?.endDate : '',
        id: contact.id,
        isCollapsed: true,
        note: contact.note || '',
        preferredFlag: contact.preferredFlag || false,
        startDate: contact.effective?.startDate ? contact.effective?.startDate : '',
        textMessageOkay:
          contact.contactMethod?.code === 'PHONE' ? contact.textMessageOkay || false : undefined,
        value: contact.value || '',
      })
    })

    const sortedContacts = [...parsedContacts].sort((a, b) => b.preferredFlag - a.preferredFlag)

    setProviderContacts(sortedContacts)
  }, [personnelDetails?.id, providerContactsData, contactMethods, getContactMethodTypes])

  useEffect(() => {
    handleCreateContacts()
  }, [providerContactsData, handleCreateContacts])

  const onAddRow = () => {
    const updatedContacts = [
      ...providerContacts,
      {
        contactMethod: undefined,
        contactMethodType: undefined,
        endDate: '',
        isCollapsed: false,
        note: '',
        preferredFlag: providerContacts?.length > 0 ? false : true,
        startDate: dateNowIsoString(),
        textMessageOkay: false,
        value: '',
      },
    ]
    if (updatedContacts) {
      setProviderContacts([...updatedContacts])
    }
  }

  const onSaveContacts = useCallback(() => {
    const saveClientContacts = async (personnelId: string) => {
      const request: PersonnelContactDTO[] = providerContacts.map((contact: ClientContact) => {
        return {
          contactMethod: contact.contactMethod,
          contactMethodType: contact.contactMethodType,
          effective: {
            endDate: contact.endDate,
            startDate: contact.startDate,
          },
          id: contact.id,
          note: contact.note,
          preferredFlag: contact.preferredFlag,
          textMessageOkay: contact.textMessageOkay,
          value: contact.value,
        }
      })

      const response = await PersonnelContactControllerService.savePersonnelContacts(
        personnelId,
        request
      )

      if (response) {
        setProviderContactsData(response)
        showSnackSuccess(t('client-contacts.messages.contact-save-success'))
      } else {
        showSnackError(t('client-contacts.messages.contact-save-error'))
      }
    }
    if (personnelDetails?.id) {
      setSaveTriggered(false)
      saveClientContacts(personnelDetails?.id)
    }
  }, [personnelDetails?.id, providerContacts, showSnackSuccess, showSnackError, t])

  const onDeleteContact = useCallback(
    (contactId) => {
      const deleteContacts = async () => {
        if (personnelDetails?.id) {
          try {
            await PersonnelContactControllerService.deletePersonnelContact(
              personnelDetails?.id,
              contactId
            )
            const updatedContacts = providerContacts.filter((contact: any) => {
              return contact.id !== contactId
            })
            setProviderContacts([...updatedContacts])
            showSnackSuccess(t('client-contacts.messages.contact-save-success'))
          } catch (error) {
            showSnackError(t('client-contacts.messages.contact-save-error'))
          }
        }
      }
      setSaveTriggered(false)
      deleteContacts()
    },
    [personnelDetails?.id, providerContacts, showSnackSuccess, t, showSnackError]
  )

  return (
    <>
      <SectionHeader
        addLabel={t('client-contacts.labels.add-contact')}
        onAdd={onAddRow}
        onSave={() => setSaveTriggered(true)}
        saveLabel={t('common.button.save')}
        title={t('client-contacts.labels.title')}
      />
      <Box className="client-profile-section-container">
        <Grid item sx={{ pt: 2 }} xs={12}>
          <MISTextField
            inputProps={{ maxLength: 255 }}
            label={t('client-contacts.best-time-to-contact')}
            onChange={() => null}
            value=""
          />
        </Grid>
        <Contacts
          contacts={providerContacts}
          deleteContact={(index: number) => onDeleteContact(providerContacts[index]?.id)}
          onSave={onSaveContacts}
          saveTriggered={saveTriggered}
          setContacts={setProviderContacts}
        />
      </Box>
    </>
  )
}

export default ProviderContactsSection
