import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  userId?: string
}

const initialState: UserState = {}

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUserId: (state, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload
    },
  },
})

export const { setUserId } = userSlice.actions

export default userSlice.reducer
