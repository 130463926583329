import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import AddAssociationDialog from './AddAssociationDialog'
import StickyHeadTable from '../TableList'

function TabPanel(props) {
  const { children, index, value, ...other } = props

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  }
}

function AssociationRow(props) {
  const { existingAssociations, onSaveClickedCallback } = props
  const [open, setOpen] = React.useState(false)
  const [associations, setAssociations] = React.useState(existingAssociations)
  const [showValidationErrors, setShowValidationErrors] = React.useState(false)

  const { t } = useTranslation('common')
  let otherEntity = associations[0].otherEntity

  const addNewAssociationRow = (otherEntity) => {
    let newAssociation = { endDate: null, otherEntity: otherEntity, startDate: null }
    let associationsCopy = [...associations]
    associationsCopy.push(newAssociation)
    setAssociations(associationsCopy)
  }
  const deleteNewAssociationRow = (index) => {
    let associationsCopy = [...associations]
    associationsCopy.splice(index, 1)
    setAssociations(associationsCopy)
  }

  const handleStartDateChange = (value, index) => {
    let updatedAssociation = { ...associations[index], isDirty: true, startDate: value }
    let associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleEndDateChange = (value, index) => {
    let updatedAssociation = { ...associations[index], endDate: value, isDirty: true }
    let associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleCancelClicked = () => {
    setShowValidationErrors(false)
    setAssociations(existingAssociations)
  }

  const getEndDateHelperText = (startDate, endDate) => {
    if (endDate && !endDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'End date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const getStartDateHelperText = (startDate, endDate) => {
    if (!startDate) {
      return t('common.helper-text.required-field', { fieldName: 'Start date' })
    } else if (startDate && !startDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'Start date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const isStartDateValid = (startDate, endDate) => {
    if (!startDate || !startDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const isEndDateValid = (startDate, endDate) => {
    if (endDate && !endDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const validateAssociations = () => {
    let isValid = true
    associations.forEach((association) => {
      if (association.isDirty) {
        if (
          !isStartDateValid(association.startDate, association.endDate) ||
          !isEndDateValid(association.startDate, association.endDate)
        ) {
          isValid = false
        }
      }
    })
    return isValid
  }
  const handleSaveClicked = () => {
    setShowValidationErrors(true)
    if (!validateAssociations()) {
      return
    } else {
      setShowValidationErrors(false)
    }
    let associationsToSave = []
    associations.forEach((association) => {
      if (association.isDirty) {
        let associationToSave = {
          endDate: association.endDate ? association.endDate.toISO() : null,
          id: association.id,
          otherEntityId: association.otherEntity.id,
          startDate: association.startDate ? association.startDate.toISO() : null,
        }
        associationsToSave.push(associationToSave)
      }
    })
    if (associationsToSave.length > 0) {
      onSaveClickedCallback(associationsToSave)
    }
  }

  useEffect(() => {
    setAssociations(existingAssociations)
  }, [existingAssociations])

  return (
    <React.Fragment>
      <TableRow
        hover
        key={otherEntity.id}
        onClick={() => setOpen(!open)}
        selected={open}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell>
          {otherEntity.name}
          {otherEntity.description ? ', ' + otherEntity.description : ''}
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={'collapse-' + otherEntity.id}>
        <TableCell
          colSpan={6}
          style={{
            background: '#f2f2f2',
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse elevation={0} in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table component={Paper} elevation={0} size="small">
                <TableHead>
                  <TableRow key="otherEntityHeader">
                    <TableCell>{t('types.table-header.start-date')}</TableCell>
                    <TableCell>{t('types.table-header.end-date')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="otherEntityInfo">
                    <TableCell component="th" scope="row">
                      {isoDateToDisplayFormat(otherEntity.startDate)}
                    </TableCell>
                    <TableCell>
                      {otherEntity.endDate ? isoDateToDisplayFormat(otherEntity.endDate) : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Table component={Paper} elevation={0} size="small">
                <TableHead>
                  <TableRow key="associationHeader">
                    <TableCell>{t('types.table-header.link-start-date')}</TableCell>
                    <TableCell>{t('types.table-header.link-end-date')}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => addNewAssociationRow(otherEntity)} sx={{ mt: 2 }}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {associations.map((association, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            format={IDHE_DATE_DISPLAY_FORMAT}
                            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                            label={t('types.table-header.start-date')}
                            mask="____-__-__"
                            onChange={(value) => handleStartDateChange(value, index)}
                            renderInput={(params) => (
                              <MISTextField
                                {...params}
                                error={
                                  showValidationErrors &&
                                  association.isDirty &&
                                  !isStartDateValid(association.startDate, association.endDate)
                                }
                                fullWidth
                                helperText={
                                  showValidationErrors &&
                                  association.isDirty &&
                                  getStartDateHelperText(association.startDate, association.endDate)
                                }
                              />
                            )}
                            value={association.startDate}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            format={IDHE_DATE_DISPLAY_FORMAT}
                            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                            label={t('types.table-header.end-date')}
                            mask="____-__-__"
                            onChange={(value) => handleEndDateChange(value, index)}
                            renderInput={(params) => (
                              <MISTextField
                                {...params}
                                error={
                                  showValidationErrors &&
                                  association.isDirty &&
                                  !isEndDateValid(association.startDate, association.endDate)
                                }
                                fullWidth
                                helperText={
                                  showValidationErrors &&
                                  association.isDirty &&
                                  getEndDateHelperText(association.startDate, association.endDate)
                                }
                                size="small"
                              />
                            )}
                            value={association.endDate}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          disabled={association.id ? true : false}
                          onClick={() => deleteNewAssociationRow(index)}
                          sx={{ mt: 2 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item />
              <Grid item sx={{ p: 2 }}>
                <MISButton onClick={handleCancelClicked} sx={{ marginRight: '10px' }}>
                  {' '}
                  {t('common.button.cancel')}
                </MISButton>
                <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function AssociationTabTable(props) {
  const {
    associations,
    closeLinkModalCallBack,
    entity,
    fixedHeightInPixels,
    headerName,
    openLinkModal,
    openLinkModalCallBack,
    otherEntities,
    saveCallBack,
  } = props

  const { t } = useTranslation('common')
  const [order, setOrder] = React.useState('asc')

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const getSortCompartor = () => {
    if (order === 'asc') {
      return (a, b) =>
        a[0].otherEntity.name.toLowerCase() > b[0].otherEntity.name.toLowerCase() ? 1 : -1
    } else {
      return (a, b) =>
        a[0].otherEntity.name.toLowerCase() < b[0].otherEntity.name.toLowerCase() ? 1 : -1
    }
  }

  const changeSortOrder = () => {
    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  const handleLinkClicked = () => {
    openLinkModalCallBack()
  }

  const closeLinkModal = () => {
    closeLinkModalCallBack()
  }

  const saveNewAssociations = (associations) => {
    saveCallBack(associations)
  }

  //TODO: when users are set up
  const headCells = [
    {
      id: 'name',
      label: 'User Name',
    },
    {
      id: 'userId',
      label: 'User Id',
    },
  ]

  const tableBodies = [
    { id: 'name', width: 70 },
    { id: 'userId', width: 30 },
  ]

  const data = [
    { name: 'Ravish', userId: '12334' },
    { name: 'Gus', userId: '23456' },
  ]

  return (
    <Box
      component={Paper}
      sx={{
        height: fixedHeightInPixels ? fixedHeightInPixels : 'auto',
        overflowY: fixedHeightInPixels ? 'scroll' : 'none',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 2 }}>
        <Tabs aria-label="basic tabs example" onChange={handleTabChange} value={tabValue}>
          <Tab label="Roles" {...a11yProps(0)} />
          <Tab label="Users" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel index={0} value={tabValue}>
        {openLinkModal && (
          <AddAssociationDialog
            entityName={entity.name}
            handleCloseCallback={closeLinkModal}
            onSaveClickedCallback={saveNewAssociations}
            openDialog={openLinkModal}
            otherEntities={otherEntities}
          />
        )}

        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item />
          <Grid item sx={{ p: 2 }}>
            <MISButton onClick={handleLinkClicked}>{t('common.button.link')}</MISButton>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow key="associationTableHead">
                <TableCell sx={{ fontWeight: 'bold' }}>
                  <TableSortLabel active direction={order} onClick={changeSortOrder}>
                    {headerName}
                  </TableSortLabel>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            {associations && associations.size > 0 && (
              <TableBody>
                {[...associations.values()].sort(getSortCompartor()).map((associations, index) => {
                  return (
                    <AssociationRow
                      existingAssociations={associations}
                      key={index}
                      onSaveClickedCallback={saveNewAssociations}
                    />
                  )
                })}
              </TableBody>
            )}
          </Table>
          {(!associations || associations.size < 1) &&
            "There are no links. Click the 'Link' buttton to add new links"}
        </TableContainer>
      </TabPanel>
      <TabPanel index={1} value={tabValue}>
        <StickyHeadTable
          data={data}
          isMultiSelectable={false}
          isPaginated={false}
          tableBodies={tableBodies}
          tableHeaders={headCells}
        />
      </TabPanel>
    </Box>
  )
}
