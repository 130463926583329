/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientBirthDetailDTO } from '../models/ClientBirthDetailDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientBirthDetailControllerService {
  /**
   * Returns birth detail for client
   * Returns a birth detail for client
   * @param clientId
   * @returns ClientBirthDetailDTO OK
   * @throws ApiError
   */
  public static getBirthDetail(clientId: string): CancelablePromise<ClientBirthDetailDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/birth-details',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Update client birth details
   * update client birth details
   * @param clientId
   * @param requestBody
   * @returns ClientBirthDetailDTO OK
   * @throws ApiError
   */
  public static updateBirthDetail(
    clientId: string,
    requestBody: ClientBirthDetailDTO
  ): CancelablePromise<ClientBirthDetailDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/birth-details',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
