/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Preference } from '../models/Preference'
import type { UnPagedPreference } from '../models/UnPagedPreference'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class UserPreferenceControllerService {
  /**
   * @param userId
   * @param id
   * @returns Preference OK
   * @throws ApiError
   */
  public static getUserPreferenceById(userId: string, id: string): CancelablePromise<Preference> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences/{id}',
      path: {
        userId: userId,
        id: id,
      },
    })
  }

  /**
   * @param userId
   * @param id
   * @param requestBody
   * @returns Preference OK
   * @throws ApiError
   */
  public static updateUserPreference(
    userId: string,
    id: string,
    requestBody: Preference
  ): CancelablePromise<Preference> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences/{id}',
      path: {
        userId: userId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns Preference OK
   * @throws ApiError
   */
  public static deleteDefaultPreferences(id: string): CancelablePromise<Preference> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param userId
   * @returns UnPagedPreference OK
   * @throws ApiError
   */
  public static getUserPreferences(userId: string): CancelablePromise<UnPagedPreference> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences',
      path: {
        userId: userId,
      },
    })
  }

  /**
   * @param userId
   * @param requestBody
   * @returns Preference OK
   * @throws ApiError
   */
  public static createUserPreference(
    userId: string,
    requestBody: Preference
  ): CancelablePromise<Preference> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param userId
   * @param sourceUserId
   * @returns UnPagedPreference OK
   * @throws ApiError
   */
  public static copyUserPreferences(
    userId: string,
    sourceUserId?: string
  ): CancelablePromise<UnPagedPreference> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/users/{userId}/preferences/action/copy-defaults',
      path: {
        userId: userId,
      },
      query: {
        sourceUserId: sourceUserId,
      },
    })
  }
}
