import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { templateGroupAtom, templatesAtom } from 'recoil/atoms'
import {
  EncounterServiceTemplateGroupControllerService,
  EncounterServiceTemplateGroupDTO,
} from 'services/openapi'

export default function EncounterServiceTemplateGroups() {
  const { handleApiError } = useErrorHandler()
  const templates = useRecoilValue(templatesAtom)
  const updateAtomCall = useSetRecoilState(templateGroupAtom)
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState<EncounterServiceTemplateGroupDTO | null>(null)
  const [associations, setAssociations] = useState([])
  const [otherEntities, setOtherEntities] = useState<any[]>([])
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [refetchTrigger, setRefetchTrigger] = useState(0)

  const handleOpenLinkModal = useCallback(() => setOpenLinkModal(true), [])

  const handleCloseLinkModal = useCallback(() => setOpenLinkModal(false), [])

  const handleCreateGroup = useCallback((data: EncounterServiceTemplateGroupDTO) => {
    const { description, effective, name } = data
    return EncounterServiceTemplateGroupControllerService.createEncounterServiceTemplateGroup({
      description,
      effective,
      name,
    })
  }, [])

  const handleUpdateGroup = useCallback((id: string, data: any) => {
    const { description, endDate, isTerminated, name, startDate } = data
    return EncounterServiceTemplateGroupControllerService.updateEncounterServiceTemplateGroup(id, {
      description,
      effective: {
        endDate,
        isTerminated,
        startDate,
      },
      id,
      name,
    })
  }, [])

  const setRefetchTriggerPlusOne = useCallback(
    () => setRefetchTrigger(refetchTrigger + 1),
    [refetchTrigger]
  )

  const updateAssociationCallback = useCallback((id: string, association: any) => {
    return EncounterServiceTemplateGroupControllerService.updateTemplateGroupAssociation(
      id,
      association
    )
  }, [])

  const addAssociationCallback = useCallback((id: string, association: any) => {
    return EncounterServiceTemplateGroupControllerService.addEncounterServiceTemplateAssociation(
      id,
      {
        effective: {
          endDate: association.endDate,
          startDate: association.startDate,
        },
        encounterServiceTemplateId: association.otherEntityId,
      }
    )
  }, [])

  const saveTemplateGroupAssociations = useCallback(
    (associations: any) => {
      saveAssociations(
        associations,
        updateAssociationCallback,
        addAssociationCallback,
        setOpenLinkModal,
        setRefetchTriggerPlusOne,
        handleApiError,
        () => showSnackSuccess(t('api.save-success')),
        selectedRow
      )
    },
    [
      addAssociationCallback,
      handleApiError,
      selectedRow,
      setRefetchTriggerPlusOne,
      showSnackSuccess,
      t,
      updateAssociationCallback,
    ]
  )

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      EncounterServiceTemplateGroupControllerService.getTemplateAssociationsForTemplateGroup(
        selectedRow.id
      ).then(
        (data) => {
          setOtherEntities(templates)
          buildAssociationMapUtil(
            data.map((each) => {
              return {
                ...each,
                endDate: each.effective?.endDate,
                startDate: each.effective?.startDate,
              }
            }),
            templates,
            'encounterServiceTemplateId',
            setAssociations,
            setOtherEntities
          )
        },
        (error) => {
          handleApiError(error)
        }
      )
    }
  }, [handleApiError, refetchTrigger, selectedRow, templates])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('encounter-template-groups.title.template-groups')}</h1>
        {t('encounter-template-groups.title.template-groups-description')}
        <EntityTemplate
          createEntityServiceCall={handleCreateGroup}
          entityName={t('encounter-template-groups.title.template-groups')}
          getEntitiesServiceCall={
            EncounterServiceTemplateGroupControllerService.listEncounterServiceTemplateGroups
          }
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={handleUpdateGroup}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box sx={{ p: 2 }}>
          <h3>
            {t('encounter-template-groups.title.association-header')} {selectedRow.name}
          </h3>
          <AssociationTable
            associations={associations}
            closeLinkModalCallBack={handleCloseLinkModal}
            entity={selectedRow}
            fixedHeightInPixels={0}
            headerName={t('encounter-template-groups.title.template-groups')}
            openLinkModal={openLinkModal}
            openLinkModalCallBack={handleOpenLinkModal}
            otherEntities={otherEntities}
            saveCallBack={saveTemplateGroupAssociations}
            showOtherEntityDatesTable={false}
          />
        </Box>
      )}
    </>
  )
}
