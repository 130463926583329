import { ReactNode } from 'react'
import DEFAULT_IMG_SRC from 'assets/images/client-search-artwork.jpg'
import './MISImageContentContainer.scss'

type MISImageContentContainerProps = {
  children: ReactNode
  imgSrc?: string
  hideImage?: boolean
}

const MISImageContentContainer = ({
  children,
  hideImage = false,
  imgSrc = DEFAULT_IMG_SRC,
}: MISImageContentContainerProps) => {
  return (
    <div className="image-content-container">
      {!hideImage && (
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      )}
      <div className="main">{children}</div>
    </div>
  )
}

export default MISImageContentContainer
