import { CustomStaffMemberProps } from 'modules/shared/StaffAssociation/StaffAssociation'
import {
  AlertDTO,
  ClientAlertDTO,
  PageEncounterServiceTemplateDTO,
  PersonnelJobFunctionFteDTO,
  TeamDTO,
} from 'services/openapi'

type criteriaProps = {
  key: string
  order: 'asc' | 'desc'
  type: 'string' | 'boolean' | 'date' | 'number'
}

const DEFAULT_ORDER = 'asc'
const DEFAULT_TYPE = 'string'

type TeamsDataRowType = { data: TeamDTO; isCollapsed?: boolean | undefined }
type FundersDataRowType = { data: PersonnelJobFunctionFteDTO }
type AlertsDataRowType = { data: AlertDTO; isCollapsed?: boolean }
type ClientAlertsDataRowType = { data: ClientAlertDTO; isCollapsed?: boolean; code?: string }

type SortArrayType =
  | FundersDataRowType
  | TeamsDataRowType
  | AlertsDataRowType
  | ClientAlertsDataRowType
  | any

const getValueByKeypath = (keypath: string, target: any) => {
  if (keypath.split('.').length > 1) {
    return keypath
      .split('.')
      .reduce((previous, current) => (previous ? previous[current] : ''), target)
  } else {
    return target[keypath]
  }
}

export const multiCriteriaSort = (
  array: SortArrayType[],
  criteriaArray: criteriaProps[],
  inactiveFieldKeyPath = ''
) => {
  const sortedArray = array.sort((a: SortArrayType, b: SortArrayType) => {
    for (let i = 0; i < criteriaArray.length; i++) {
      const criterion = criteriaArray[i]
      const { key, order = DEFAULT_ORDER, type = DEFAULT_TYPE } = criterion
      const aValue = getValueByKeypath(key, a)
      const bValue = getValueByKeypath(key, b)
      const ascending = order === 'asc'
      switch (type) {
        case 'string':
          if (('' + aValue).localeCompare(bValue) === 0) {
            continue
          }
          return ascending
            ? ('' + aValue).localeCompare(bValue)
            : ('' + bValue).localeCompare(aValue)
        case 'number':
        case 'boolean':
          if (aValue === bValue) {
            continue
          }
          return ascending ? aValue - bValue : bValue - aValue
        case 'date':
          if (+new Date(aValue) === +new Date(bValue)) {
            continue
          }
          return ascending
            ? +new Date(aValue) - +new Date(bValue)
            : +new Date(bValue) - +new Date(aValue)
      }
    }
    return 0
  })
  if (inactiveFieldKeyPath) {
    return moveDeactivated(sortedArray, inactiveFieldKeyPath)
  }
  return sortedArray
}

export const moveDeactivated = (array: SortArrayType[], fieldKeyPath = '') => {
  const activeItems: SortArrayType[] = [],
    inactiveItems: SortArrayType[] = []
  array.forEach((a: SortArrayType) => {
    if (getValueByKeypath(fieldKeyPath, a)) {
      inactiveItems.push(a)
    } else {
      activeItems.push(a)
    }
  })

  inactiveItems.sort((a: SortArrayType, b: SortArrayType) => {
    const aValue = getValueByKeypath(fieldKeyPath, a)
    const bValue = getValueByKeypath(fieldKeyPath, b)
    return +new Date(bValue) - +new Date(aValue) // Assumption deactivated always DESC
  })

  return [...activeItems, ...inactiveItems]
}

export const arrayMove = (
  arr: (PageEncounterServiceTemplateDTO | CustomStaffMemberProps)[],
  fromIndex: number,
  toIndex: number
) => {
  if (
    fromIndex === toIndex ||
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  ) {
    return arr
  }

  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export const findValueByKeyFromJSONString = (jsonString: string, targetKey: string): any => {
  try {
    const jsonObject = JSON.parse(jsonString)

    const findValue = (obj: any, key: string): any => {
      if (typeof obj === 'object') {
        if (key in obj) {
          return obj[key]
        }
        for (const k in obj) {
          if (obj[k] && typeof obj[k] === 'object') {
            const result = findValue(obj[k], key)
            if (result !== undefined) {
              return result
            }
          }
        }
      }
    }

    return findValue(jsonObject, targetKey)
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return undefined
  }
}
