import './ClientInfo.scss'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined'
import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getCurrentTenant } from 'core/helpers/security/currentTenant'
import { getClientFullName } from 'modules/shared/clientUtils'
import { formatPhoneNumber } from 'modules/shared/Contacts'
import { terminologySelector } from 'recoil/terminology'
import {
  AddressClientDTO,
  CancelablePromise,
  ClientAddressControllerService,
  ClientContact,
  ClientContactControllerService,
  ClientControllerService,
  ClientDTO,
  ClientEmergencyContact,
  ClientEmergencyContactControllerService,
  ClientNonClinicalNote,
  ClientNonClinicalNoteControllerService,
  ClientRelationshipControllerService,
  ClientRelationshipDTO,
  LocationDTO,
  PrivacyDirectiveControllerService,
  UserControllerService,
  UserDTO,
} from 'services/openapi'
import {
  ADDRESS_COUNTRIES,
  ADDRESS_PROVINCES,
  ADDRESS_STREET_TYPES,
  MIS_PERSON_RELATIONSHIP_TYPE,
} from 'services/terminologyConstants'
import { setClientPrivacyDirectives } from 'store/reducers/client'
import { selectClientId } from 'store/selectors/client'
import InfoCardContainer from './InfoCardContainer'
import InfoCardHeader from './InfoCardHeader'
import InfoCardLoader from './InfoCardLoader'

const ClientInfo = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const clientId = useSelector(selectClientId)
  const { handleApiError } = useErrorHandler()
  const streetTypes = useRecoilValue(terminologySelector(ADDRESS_STREET_TYPES))
  const provinces = useRecoilValue(terminologySelector(ADDRESS_PROVINCES))
  const countries = useRecoilValue(terminologySelector(ADDRESS_COUNTRIES))
  const relationships = useRecoilValue(terminologySelector(MIS_PERSON_RELATIONSHIP_TYPE))

  const [contact, setContact] = useState<ClientContact | undefined | null>(null)
  const [emergencyContact, setEmergencyContact] = useState<
    ClientEmergencyContact | undefined | null
  >(null)
  const [emergencyContactMethod, setEmergencyContactMethod] = useState<
    ClientContact | undefined | null
  >(null)
  const [location, setLocation] = useState<LocationDTO | undefined | null>(null)
  const [community, setCommunity] = useState<string | undefined | null>(null)
  const [provider] = useState<string | undefined | null>('Barbara Hunt, Location')
  const [families, setFamilies] = useState<
    { client: ClientDTO; clientRelationship: ClientRelationshipDTO }[] | undefined | null
  >(null)
  const [householdAddressId, setHouseholdAddressId] = useState<string | undefined>(undefined)
  const [household, setHousehold] = useState<AddressClientDTO[] | undefined | null>(null)
  const [note, setNote] = useState<ClientNonClinicalNote | undefined | null>(null)
  const [noteUser, setNoteUser] = useState<UserDTO | undefined | null>(null)

  const getAddressString = useCallback(
    (location: LocationDTO) => {
      let retVal = ''
      const streetType =
        streetTypes.find((value) => value.code === location.address?.streetType?.code)?.name || ''
      const province =
        provinces.find((value) => value.code === location.address?.province?.code)?.name || ''
      const country =
        countries.find((value) => value.code === location.address?.country?.code)?.name || ''
      if (location?.address?.unitNumber) retVal += `${location.address.unitNumber} - `
      if (location?.address?.streetNumber) retVal += `${location.address.streetNumber} `
      if (location?.address?.streetName) retVal += `${location.address.streetName} `
      if (streetType) retVal += `${streetType}, `
      if (location?.address?.city) retVal += `${location.address.city}, `
      if (province) retVal += `${province}, `
      if (location?.address?.postalCode) retVal += `${location.address.postalCode}, `
      if (country) retVal += `${country}`
      return retVal
    },
    [countries, provinces, streetTypes]
  )

  const getRelationshipForHouseholdMember = useCallback(
    (clientId: string | undefined) => {
      if (!clientId) return ''
      const family = families?.find((each) => each.client.id === clientId)
      return (
        relationships.find(
          (value) => value.code === family?.clientRelationship?.relationshipType?.code
        )?.name || ''
      )
    },
    [families, relationships]
  )

  const getContacts = useCallback(async (clientId: string) => {
    const response = await ClientContactControllerService.getClientContacts(clientId)
    const preferred = response?.content?.find((each) => each.preferredFlag)
    setContact(preferred || response?.content?.[0] || undefined)
  }, [])

  const getClientAddresses = useCallback(async (clientId: string) => {
    const response = await ClientAddressControllerService.getClientAddresses(clientId)
    const preferred = response?.content?.find((each) => each.preferredFlag)
    const location = preferred?.location || response?.content?.[0]?.location || undefined
    setLocation(location)
    setCommunity(preferred?.location?.community)
    setHouseholdAddressId(location?.address?.id)
  }, [])

  const getClientEmergencyContact = useCallback(async (clientId: string) => {
    const response = await ClientEmergencyContactControllerService.getClientEmergencyContacts(
      clientId
    )
    const preferred = response?.content?.find((each) => each.isPreferredEmergency)
    const contact = preferred || response?.content?.[0] || undefined
    setEmergencyContact(preferred || response?.content?.[0] || undefined)
    setEmergencyContactMethod(contact?.contactMethods?.find((each) => each.preferredFlag))
  }, [])

  const getClientRelationships = useCallback(async (clientId: string) => {
    const clientRelationships = await ClientRelationshipControllerService.getClientRelationships(
      clientId,
      undefined,
      undefined,
      undefined,
      0,
      100
    )
    const promises: CancelablePromise<ClientDTO>[] = []
    clientRelationships.content?.forEach((relationship) => {
      if (relationship.relatedClientId)
        promises.push(
          ClientControllerService.getClient(relationship.relatedClientId, undefined, true)
        )
    })
    const clientDetails = await Promise.all(promises)
    const families = clientRelationships.content
      ? clientRelationships.content.map((clientRelationship, index) => {
          return { client: clientDetails[index], clientRelationship }
        })
      : []
    setFamilies(families)
  }, [])

  const getHouseholdMember = useCallback(async (clientId: string, householdAddressId: string) => {
    const response = await ClientAddressControllerService.getAddressClients(
      clientId,
      householdAddressId
    )
    setHousehold(response.filter((each) => each.client?.id !== clientId))
  }, [])

  const getClientNonClinicalNotes = useCallback(async (clientId: string) => {
    const response = await ClientNonClinicalNoteControllerService.getNonClinicalNotes(clientId)
    const preferred = response?.content?.find((each) => each.showOnClientDash)
    const note = preferred || undefined
    setNote(note)
    if (note) {
      const user = await UserControllerService.findUserBy('', note.createdUser)
      setNoteUser(user)
    }
  }, [])

  useEffect(() => {
    if (clientId) getContacts(clientId)
  }, [clientId, getContacts])

  useEffect(() => {
    if (clientId) getClientAddresses(clientId)
  }, [clientId, getClientAddresses])

  useEffect(() => {
    if (clientId) getClientEmergencyContact(clientId)
  }, [clientId, getClientEmergencyContact])

  useEffect(() => {
    if (clientId) getClientRelationships(clientId)
  }, [clientId, getClientRelationships])

  useEffect(() => {
    if (clientId && householdAddressId) getHouseholdMember(clientId, householdAddressId)
  }, [clientId, getHouseholdMember, householdAddressId])

  useEffect(() => {
    if (clientId) getClientNonClinicalNotes(clientId)
  }, [clientId, getClientNonClinicalNotes])

  useEffect(() => {
    const getClientDirective = async (id: string) => {
      try {
        const directives = await PrivacyDirectiveControllerService.getDirectives(
          id,
          true,
          undefined,
          undefined,
          ['auditInfo']
        )
        if (!directives?.content?.length) {
          dispatch(setClientPrivacyDirectives(undefined))
          return
        }
        const directive = directives.content[0]
        dispatch(setClientPrivacyDirectives(directive))
      } catch (error) {
        handleApiError(error)
      }
    }

    if (clientId) {
      getClientDirective(clientId)
    }
  }, [clientId, dispatch, handleApiError])

  return (
    <Grid
      alignItems="stretch"
      container
      spacing={2}
      sx={{ backgroundColor: '#ebe4d2', borderRadius: '6px', ml: 2, mr: 2, pb: 2, pr: 2 }}
    >
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<ContactPhoneOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.contact-info')}
        />
        {contact === null || location === null ? (
          <InfoCardLoader />
        ) : (
          <Stack spacing={1} sx={{ pt: 1 }}>
            {contact && (
              <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                {contact.contactMethod?.code === 'PHONE'
                  ? formatPhoneNumber(contact?.value as string)
                  : contact?.value}
              </Typography>
            )}
            {location && (
              <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                {getAddressString(location)}
              </Typography>
            )}
          </Stack>
        )}
      </InfoCardContainer>
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<ContactEmergencyOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.emergency-contact')}
        />
        {emergencyContact === null ? (
          <InfoCardLoader />
        ) : (
          <Box sx={{ pt: 1 }}>
            {emergencyContact && (
              <>
                <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                  {`${emergencyContact.contactName}${emergencyContact.relationship && ','} `}
                  {relationships.find((value) => value.code === emergencyContact.relationship?.code)
                    ?.name || ''}
                </Typography>
                {emergencyContactMethod && (
                  <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                    {emergencyContactMethod.contactMethod?.code === 'PHONE'
                      ? formatPhoneNumber(emergencyContactMethod?.value as string)
                      : emergencyContactMethod?.value}
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
      </InfoCardContainer>
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<FamilyRestroomOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.family')}
        />
        {families === null ? (
          <InfoCardLoader />
        ) : (
          <Box sx={{ pt: 1 }}>
            {families &&
              families.map((each, index) => (
                <Typography
                  key={index}
                  sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}
                >
                  <Link
                    href={`/${getCurrentTenant().tenantId}/clients/client-record/${each.client.id}`}
                    onClick={(event) => {
                      event.preventDefault()
                      navigate(`/clients/client-record/${each.client.id}`)
                    }}
                    underline="hover"
                    variant="inherit"
                  >
                    {getClientFullName(each.client)}
                  </Link>
                  {`${each.clientRelationship.relationshipType && ','} `}
                  {relationships.find(
                    (value) => value.code === each.clientRelationship.relationshipType?.code
                  )?.name || ''}
                </Typography>
              ))}
          </Box>
        )}
      </InfoCardContainer>
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<HomeOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.household')}
        />
        <Box sx={{ pt: 1 }}>
          {household &&
            household.map((each, index) => {
              return (
                <Typography
                  key={index}
                  sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}
                >
                  <Link
                    href={`/${getCurrentTenant().tenantId}/clients/client-record/${
                      each.client?.id
                    }`}
                    onClick={(event) => {
                      event.preventDefault()
                      navigate(`/clients/client-record/${each.client?.id}`)
                    }}
                    underline="hover"
                    variant="inherit"
                  >
                    {`${each.client?.name?.firstName} ${each.client?.name?.lastName}`}
                  </Link>
                  {`${
                    getRelationshipForHouseholdMember(each.client?.id) ? ', ' : ''
                  }${getRelationshipForHouseholdMember(each.client?.id)}`}
                </Typography>
              )
            })}
        </Box>
      </InfoCardContainer>
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<GroupWorkOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.community')}
        />
        {community === null ? (
          <InfoCardLoader />
        ) : (
          <Box sx={{ pt: 1 }}>
            {community && (
              <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                {community}
              </Typography>
            )}
          </Box>
        )}
      </InfoCardContainer>
      <InfoCardContainer xs={6}>
        <InfoCardHeader
          icon={<MedicalInformationOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.key-service-provider')}
        />
        {provider === null ? (
          <InfoCardLoader />
        ) : (
          <Box sx={{ pt: 1 }}>
            {provider && (
              <Typography sx={{ color: '#333', fontSize: '12px', lineHeight: '16px' }}>
                {provider}
              </Typography>
            )}
          </Box>
        )}
      </InfoCardContainer>
      <InfoCardContainer xs={12}>
        <InfoCardHeader
          icon={<DescriptionOutlinedIcon fontSize="small" sx={{ color: '#999' }} />}
          label={t('client-dashboard.non-clinical-notes')}
        />
        {note === null ? (
          <InfoCardLoader />
        ) : (
          <Box>
            {note && (
              <>
                <ReactQuill
                  className="non-clinical-note"
                  modules={{ toolbar: null }}
                  readOnly
                  theme="snow"
                  value={note.noteText}
                />
                <Typography sx={{ color: '#999', fontSize: '10px', lineHeight: '12px' }}>
                  {`${t('client-non-clinical-notes.created-by')} ${noteUser?.firstName} ${
                    noteUser?.lastName
                  }`}
                  <br />
                  {note?.changedDate && isoDateToDisplayFormatWithTime(note?.changedDate)}
                </Typography>
              </>
            )}
          </Box>
        )}
      </InfoCardContainer>
    </Grid>
  )
}

export default ClientInfo
