export const themeJson = {
  cssVariables: {
    '--sjs-base-unit': '8px',
    '--sjs-border-default': 'rgba(0, 0, 0, 0.15)',
    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
    '--sjs-border-light': 'rgba(0, 0, 0, 0.15)',
    '--sjs-corner-radius': '4px',
    '--sjs-font-questiontitle-color': 'rgba(51, 51, 51, 0.91)',
    '--sjs-font-questiontitle-family': 'Open Sans',
    '--sjs-font-questiontitle-size': '14px',
    '--sjs-font-questiontitle-weight': '400',
    '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
    '--sjs-general-backcolor-dim': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
    '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-primary-backcolor': '#cc5500',
    '--sjs-primary-backcolor-dark': 'rgba(188, 78, 0, 1)',
    '--sjs-primary-backcolor-light': 'rgba(204, 85, 0, 0.1)',
    '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-shadow-inner': '0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.05)',
    '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-small':
      '0px 2px 4px 0px rgba(0, 0, 0, 0.1),0px 8px 16px 0px rgba(0, 0, 0, 0.1),0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
  },
  isPanelless: false,
}
