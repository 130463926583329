import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, Divider, IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'
import Loader from 'common/components/Loader'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { StateChip } from 'modules/shared/State'
import { getUserInfoFromUsernames } from 'modules/shared/utils'
import { encounterServicesNotesHistoryState } from 'recoil/encounters'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef, EncounterNoteDTO, UserInfo } from 'services/openapi'
import { MIS_ENCOUNTER_NOTE_STATE } from 'services/terminologyConstants'

type EncounterNoteHistoryDialogProps = {
  encounterServiceId: string
  note: EncounterNoteDTO
  onClose: () => void
  openDialog: boolean
}

const EncounterNoteHistoryDialog = ({
  encounterServiceId,
  note,
  onClose,
  openDialog,
}: EncounterNoteHistoryDialogProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const notesHistory = useRecoilValue(encounterServicesNotesHistoryState)
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_NOTE_STATE))

  const [userInfo, setUserInfo] = useState<{ [key: string]: UserInfo } | null>(null)

  useEffect(() => {
    const getUserInfo = async () => {
      const usernames: string[] = []
      notesHistory[encounterServiceId].forEach((note) => {
        if (note.userId && usernames.indexOf(note.userId) === -1) usernames.push(note.userId)
      })
      const userInfo = new Map<string, UserInfo>()
      try {
        await getUserInfoFromUsernames(usernames, userInfo)
        const nextUserInfo: { [key: string]: UserInfo } = {}
        userInfo.forEach((info) => {
          nextUserInfo[info.username as string] = info
        })
        setUserInfo(nextUserInfo)
      } catch (error) {
        setUserInfo({})
        handleApiError(error)
      }
    }
    if (userInfo === null && notesHistory[encounterServiceId].length > 0) getUserInfo()
  }, [encounterServiceId, handleApiError, note, notesHistory, userInfo])

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={openDialog}>
      <DialogTitle
        sx={{
          background: GLOBAL.WHITE,
          fontSize: GLOBAL.FONT_SIZE_H3,
          fontWeight: '700 !important',
          p: GLOBAL.PADDING_XL,
          pb: GLOBAL.PADDING_XS,
          pt: GLOBAL.PADDING_XXL,
        }}
      >
        {t('encounter-service-notes.labels.note-history')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 14,
            top: 15,
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          background: GLOBAL.WHITE,
          pb: GLOBAL.PADDING_XL,
          pl: GLOBAL.PADDING_XL,
          pr: GLOBAL.PADDING_XL,
        }}
      >
        {userInfo ? (
          <>
            {notesHistory[encounterServiceId].length > 0 &&
              notesHistory[encounterServiceId]
                .filter((noteHistory) => noteHistory.id === note.id)
                .map((noteHistory, index) => (
                  <div key={noteHistory.revisedAt}>
                    <div style={{ marginTop: GLOBAL.MARGIN_SM }}>
                      <span>{`${t('encounter-service-notes.labels.note-created-by')}: ${
                        userInfo[noteHistory.userId as string]?.firstName
                      } ${userInfo[noteHistory.userId as string]?.lastName}`}</span>
                      <span>{` | ${isoDateToDisplayFormatWithTime(noteHistory.revisedAt)}`}</span>
                      <span>
                        {' | '}
                        <StateChip
                          comment={noteHistory.stateComment}
                          defaultCursor
                          label={
                            stateOptions?.find(
                              (o: CodedRef) => o.code === (noteHistory.state as CodedRef)?.code
                            )?.name as string
                          }
                        />
                      </span>
                    </div>
                    <Box sx={{ textDecoration: noteHistory.isLatest ? 'none' : 'line-through' }}>
                      {noteHistory.content && parse(noteHistory.content)}
                    </Box>
                    {index !==
                      notesHistory[encounterServiceId].filter(
                        (noteHistory) => noteHistory.id === note.id
                      ).length -
                        1 && (
                      <Divider sx={{ mb: GLOBAL.MARGIN_MD, ml: 0, mr: 0, mt: GLOBAL.MARGIN_MD }} />
                    )}
                  </div>
                ))}
          </>
        ) : (
          <Loader />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default EncounterNoteHistoryDialog
