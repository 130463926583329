import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AddCircleOutline } from '@mui/icons-material'
import { Divider } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import Loader from 'common/components/Loader'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import { ErrorType } from 'modules/shared/utils'
import { encountersState } from 'recoil/encounters'
import { isDirtyState } from 'recoil/isDirty'
import { encounterIdState, encounterServiceIdState } from 'recoil/lastupdated'
import { EncounterControllerService, EncounterDTO, EncounterServiceDTO } from 'services/openapi'
import AddEncounterDialog from './AddEncounterDialog'
import EncountersFilters from './EncounterFilters'
import EncountersContentPanel from './EncountersContentPanel'
import EncountersHeader from './EncountersHeader'
import EncountersListPanel from './EncountersListPanel'

import './Encounters.scss'

export interface EncounterEntityRefType {
  save: () => void
}

export type EncounterServiceRowDataType = EncounterServiceDTO & { isCollapsed?: boolean }

export interface EncounterServiceRowType {
  rows: EncounterServiceRowDataType[]
  errors: ErrorType[]
}

const Encounters = () => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const { clientDetails } = useClientDetails(clientId)
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const setIsDirty = useSetRecoilState(isDirtyState)
  const [lastUpdatedEncounterId, setLastUpdatedEncounterId] = useRecoilState(encounterIdState)
  const lastUpdatedEncounterServiceId = useRecoilValue(encounterServiceIdState)
  const [encounters, setEncounters] = useRecoilState(encountersState)
  const [encounter, setEncounter] = useState<EncounterDTO | undefined>(undefined)
  const [loadingEncounters, setLoadingEncounters] = useState(true)
  const [openAddEditForm, setOpenAddEditForm] = useState(false)
  const [primaryService, setPrimaryService] = useState<EncounterServiceDTO | undefined>(undefined)
  const [services, setServices] = useState<EncounterServiceRowType>({
    errors: [],
    rows: [],
  })

  const encounterEntityRefType = useRef<EncounterEntityRefType>()

  const validate = useCallback(() => {
    let isValid = true
    const errors: ErrorType[] = []
    let primaryServiceExists: boolean | undefined = false
    services.rows.forEach((row, index: number) => {
      primaryServiceExists = primaryServiceExists || row?.isPrimaryService
      if (!row.programId) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.programId`,
          message: t('encounter-service.validation.program-required'),
        })
      }
      if (!row.purposeId) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.purposeId`,
          message: t('encounter-service.validation.purpose-required'),
        })
      }
      if (!row.typeId) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.typeId`,
          message: t('encounter-service.validation.type-required'),
        })
      }
      if (!row.locationOfService) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.location`,
          message: t('encounter-service.validation.location-required'),
        })
      }
      if (!row.synopsis) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.synopsis`,
          message: t('encounter-service.validation.synopsis-required'),
        })
      }
      if (row.esdrtCategoryId && row.esdrtServiceId && !row.duration) {
        isValid = false
        errors.push({
          field: `encounter-service.${index}.duration`,
          message: t('encounter-service.validation.duration-required'),
        })
      }
    })

    return {
      errors: errors,
      isValid,
      validatedRows: services.rows,
    }
  }, [services.rows, t])

  const saveEncounter = useCallback(async () => {
    const saveMe = {
      ...encounter,
      encounterServices: services.rows,
    }
    if (!encounter?.id) return
    try {
      const response: EncounterDTO = await EncounterControllerService.updateEncounter(
        encounter.id,
        saveMe
      )
      setEncounters(
        encounters?.map((el: EncounterDTO) =>
          el.id === response.id ? Object.assign({}, response) : el
        )
      )

      if (response?.id) {
        setLastUpdatedEncounterId(response?.id)
        const defaultPrimaryService =
          response?.encounterServices?.find(
            (service: EncounterServiceDTO) => service.isPrimaryService
          ) || undefined
        setPrimaryService(defaultPrimaryService)
      }

      showSnackSuccess(t('api.save-success'))
    } catch (error) {
      handleApiError(error)
    }
  }, [
    encounter,
    encounters,
    handleApiError,
    services.rows,
    setEncounters,
    setLastUpdatedEncounterId,
    showSnackSuccess,
    t,
  ])

  const handleEncounterSave = useCallback(async () => {
    setIsDirty(false)

    const { errors, isValid, validatedRows } = validate()
    setServices({ ...services, errors: errors, rows: validatedRows })
    if (isValid) {
      encounterEntityRefType.current?.save()
      saveEncounter()
    }
  }, [saveEncounter, services, setIsDirty, validate])

  useEffect(() => {
    const fetchEncounters = async (id: string) => {
      try {
        const response = await EncounterControllerService.encountersgetPagedList(
          id,
          'CLIENT_FILES',
          '',
          '',
          false,
          false,
          'ALL'
        )
        setEncounters(response?.content)
        if (response?.content?.length && response?.content?.length > 0) {
          const activeId = lastUpdatedEncounterId || response.content[0].id
          setEncounter(response.content.find((obj) => obj.id === activeId))
        }
      } catch (error) {
        handleApiError(error)
      }
      setLoadingEncounters(false)
    }
    if (clientDetails?.id) {
      setLoadingEncounters(true)
      fetchEncounters(clientDetails.id)
    } else setLoadingEncounters(false)
  }, [clientDetails, handleApiError, lastUpdatedEncounterId, setEncounters])

  const handleSaveCallBack = useCallback(
    (encounter: EncounterDTO) => {
      const foundIndex = encounters?.findIndex((e: EncounterDTO) => e.id === encounter.id)
      if (foundIndex !== -1) {
        setEncounters(
          encounters?.map((el: EncounterDTO) =>
            el.id === encounter.id ? Object.assign({}, encounter) : el
          )
        )
      } else {
        setEncounters([...(encounters || []), encounter])
        setEncounter(encounter)
      }
      setOpenAddEditForm(false)
    },
    [encounters, setEncounters]
  )

  useEffect(() => {
    if (encounter) {
      const defaultPrimaryService = encounter?.encounterServices?.find(
        (service: EncounterServiceDTO) => service.isPrimaryService
      )
      setServices({
        errors: [],
        rows: encounter?.encounterServices
          ? encounter?.encounterServices
              .slice()
              .sort(
                (a: EncounterServiceDTO, b: EncounterServiceDTO) =>
                  Number(b.isPrimaryService) - Number(a.isPrimaryService)
              )
              .map((service: EncounterServiceDTO) => {
                if (service.id === lastUpdatedEncounterServiceId) {
                  return { ...service, isCollapsed: false }
                }
                return { ...service, isCollapsed: true }
              })
          : [],
      })
      setPrimaryService(defaultPrimaryService)
    }
  }, [encounter, lastUpdatedEncounterServiceId])

  useEffect(() => {
    if (services && services.rows.length > 0) {
      const primaryServiceFound = services.rows.find(
        (service: EncounterServiceDTO) => service.isPrimaryService
      )
      if (!primaryServiceFound) {
        services.rows[0].isPrimaryService = true
        setServices({ ...services })
      }
    }
  }, [services])

  return (
    <MISBaseContainer>
      <ClientRecordHeader />
      {!loadingEncounters ? (
        <div className="encounters">
          <div className="encounters-list-view">
            <MISButton
              color="primary"
              onClick={() => setOpenAddEditForm(true)}
              size="large"
              startIcon={<AddCircleOutline />}
              sx={{ mb: 1, mt: 1 }}
              variant="text"
            >
              {`${t('common.button.add')} Encounter`}
            </MISButton>
            <Divider />
            <EncountersFilters />
            <EncountersListPanel encounters={encounters} onClick={setEncounter} />
          </div>
          {clientDetails && encounter && (
            <div className="encounters-content-view">
              <EncountersHeader onSave={handleEncounterSave} primaryService={primaryService} />
              <EncountersContentPanel
                client={clientDetails}
                encounter={encounter}
                ref={encounterEntityRefType}
                services={services}
                setEncounter={setEncounter}
                setServices={setServices}
              />
            </div>
          )}
        </div>
      ) : (
        <Loader fullScreen />
      )}
      {clientDetails && clientDetails.id && openAddEditForm && (
        <AddEncounterDialog
          clientId={clientDetails.id}
          entityName="Encounter"
          onCloseCallback={() => setOpenAddEditForm(false)}
          onSaveCallback={handleSaveCallBack}
          openDialog={openAddEditForm}
        />
      )}
    </MISBaseContainer>
  )
}

export default Encounters
