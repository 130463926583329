/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientIdentifier } from '../models/ClientIdentifier'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientIdentifierControllerService {
  /**
   * @param clientId
   * @param activeFilter
   * @returns ClientIdentifier OK
   * @throws ApiError
   */
  public static listClientIdentifiers(
    clientId: string,
    activeFilter?: string
  ): CancelablePromise<Array<ClientIdentifier>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/identifiers',
      path: {
        clientId: clientId,
      },
      query: {
        activeFilter: activeFilter,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientIdentifier OK
   * @throws ApiError
   */
  public static saveClientIdentifiers(
    clientId: string,
    requestBody: Array<ClientIdentifier>
  ): CancelablePromise<Array<ClientIdentifier>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/identifiers',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param identifierId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteIdentifier(clientId: string, identifierId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/identifiers/{identifierId}',
      path: {
        clientId: clientId,
        identifierId: identifierId,
      },
    })
  }
}
