import { MenuItem } from '@mui/material'

type NotificationsProps = {
  messages: string[]
}

const NotificationsContainer = ({ messages }: NotificationsProps) => (
  <>
    {messages.map((message) => (
      <MenuItem className="text" divider key={message}>
        <span className="label">{message}</span>
      </MenuItem>
    ))}
  </>
)

export default NotificationsContainer
