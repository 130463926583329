import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISRadio from 'common/components/form/MISRadio'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { isDateBeforeToday, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { ErrorType, getFormattedOptions, isNumeric } from 'modules/shared/utils'
import { govAgenciesWithDomainsAtom } from 'recoil/atoms'
import { isDirtyState } from 'recoil/isDirty'
import { personnelIdSelector, personnelJobFunctionsSelector } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedRef,
  GovernanceAgencyControllerService,
  GovernanceAgencyDTO,
  GovernanceAgencyWithDomainsDTO,
  PersonnelJobFunctionControllerService,
  PersonnelJobFunctionDTO,
} from 'services/openapi'
import {
  MIS_PROVIDER_FNIH_SPECIALTY_TYPES,
  MIS_PROVIDER_ROLE_TYPES,
} from 'services/terminologyConstants'
import '../ProviderStaffDetails.scss'
import FTEFunders from './FTEFunders'

type JobFunctionsDataRowType = {
  data: PersonnelJobFunctionDTO
  isCollapsed: boolean
}
type JobFunctionsDataType = { rows: JobFunctionsDataRowType[] }

const ENTITY = 'Job Function'

const JobFunction = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const personnelId = useRecoilValue(personnelIdSelector)
  const [jobFunctions, setJobFunctions] = useRecoilState(personnelJobFunctionsSelector)
  const jobFunctionOptions = useRecoilValue(terminologySelector(MIS_PROVIDER_ROLE_TYPES))
  const specialtyOptions = useRecoilValue(terminologySelector(MIS_PROVIDER_FNIH_SPECIALTY_TYPES))
  const govAgenciesOptions = useRecoilValue(govAgenciesWithDomainsAtom)
  const locationOfServiceOptions = useRecoilValue(govAgenciesWithDomainsAtom).filter((ga) =>
    ga.governanceAgencyType?.some((x) => x.code === 'LocationOfService')
  )
  const [respectiveDepartments, setRespectiveDepartments] = useState<
    { orgId: string; departments: GovernanceAgencyDTO[]; isTouched: boolean }[]
  >([])
  const [primaryIndex, setPrimaryIndex] = useState(-1)
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [errors, setErrors] = useState<ErrorType[]>([])

  const [jobFunctionsData, setJobFunctionsData] = useState<JobFunctionsDataType>({
    rows: jobFunctions
      ? [...jobFunctions]
          .sort((a, b) => (a?.isPrimary ? -1 : b?.isPrimary ? 1 : 0))
          .map((opt) => {
            return {
              data: opt,
              isCollapsed: true,
            }
          })
      : [],
  })

  const getError = useCallback(
    (field: string) => {
      const errorObj = errors.find((error) => error.field === field)
      return errorObj ? errorObj.message : ''
    },
    [errors]
  )

  const onAddRow = useCallback(
    () =>
      setJobFunctionsData({
        rows: [
          ...jobFunctionsData.rows,
          {
            data: {
              departmentId: '',
              effective: undefined,
              fnihSpecialty: undefined,
              id: undefined,
              isActive: true,
              isInternal: undefined,
              isPrimary: jobFunctionsData.rows.length === 0 ? true : false,
              isStaff: undefined,
              jobFunction: undefined,
              jobTitle: undefined,
              locationOfServiceId: '',
              organizationId: '',
            },
            isCollapsed: false,
          },
        ],
      }),
    [jobFunctionsData]
  )

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, value: string | CodedRef | boolean) => {
      if (!isDirty) setIsDirty(true)

      const rows = jobFunctionsData.rows?.map((row, index) => {
        let data
        if (field === 'isPrimary') {
          data = { ...row.data, isPrimary: rowIndex === index }
          return { ...row, data }
        } else {
          if (index === rowIndex) {
            switch (field) {
              case 'jobFunction':
                data = {
                  ...row.data,
                  jobFunction: jobFunctionOptions?.find(
                    (o: CodedRef) => o.code === (value as CodedRef)?.code
                  ),
                }
                break
              case 'jobTitle':
                data = { ...row.data, jobTitle: value as string }
                break
              case 'isInternal':
                data = {
                  ...row.data,
                  isInternal: value === t('provider-staff.add-edit.internal'),
                  isStaff:
                    value === t('provider-staff.add-edit.internal') ? true : row.data.isStaff,
                }
                break
              case 'isStaff':
                data = { ...row.data, isStaff: value === 'Yes' }
                break
              case 'isActive':
                data = {
                  ...row.data,
                  isActive: value === t('provider-staff.add-edit.status-active'),
                }
                break
              case 'organizationId':
                data = {
                  ...row.data,
                  organizationId: value,
                }
                break
              case 'departmentId':
                data = {
                  ...row.data,
                  departmentId: value,
                }
                break
              case 'locationOfServiceId':
                data = {
                  ...row.data,
                  locationOfServiceId: value,
                }
                break
              case 'fnihSpecialty':
                data = {
                  ...row.data,
                  fnihSpecialty: specialtyOptions?.find(
                    (o: CodedRef) => o.code === (value as CodedRef)?.code
                  ),
                }
                break
              case 'jobStartDate':
                data = {
                  ...row.data,
                  effective: { ...row.data.effective, startDate: value as string },
                }
                break
              case 'jobEndDate':
                data = {
                  ...row.data,
                  effective: { ...row.data.effective, endDate: value as string },
                  isActive: isDateBeforeToday(value) ? false : row.data?.isActive,
                }
                break
              case 'fullTimeEquivalencies':
                data = {
                  ...row.data,
                  fullTimeEquivalencies: value,
                }
                break
              default:
                return row
            }
            return { ...row, data }
          }
        }
        return row
      })
      setJobFunctionsData({ rows } as JobFunctionsDataType)
    },
    [jobFunctionOptions, jobFunctionsData, specialtyOptions, isDirty, setIsDirty, t]
  )

  const onCollapseRow = useCallback(
    (rowIndex: number) => {
      jobFunctionsData.rows[rowIndex].isCollapsed = !jobFunctionsData.rows[rowIndex].isCollapsed
      setJobFunctionsData({ ...jobFunctionsData })
    },
    [jobFunctionsData]
  )

  const sustainCollapsedState = useCallback(
    (jobFunctions) => {
      return jobFunctionsData.rows.map((jf, index) => {
        return {
          data: jobFunctions[index],
          isCollapsed: jf.isCollapsed,
        }
      })
    },
    [jobFunctionsData.rows]
  )

  const onJobFunctionsSave = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      jobFunctionsData.rows?.forEach((row, i) => {
        if (!row.data.jobFunction) {
          isValid = false
          errors.push({
            field: `jobFunction-${i}`,
            message: t('provider-staff.validation.job-function-required'),
          })
        }
        if (row.data.isInternal === undefined) {
          isValid = false
          errors.push({
            field: `isInternal-${i}`,
            message: t('provider-staff.validation.is-internal-required'),
          })
        }
        if (row.data.isStaff === undefined) {
          isValid = false
          errors.push({
            field: `isStaff-${i}`,
            message: t('provider-staff.validation.is-staff-required'),
          })
        }
        if (row.data.isActive === undefined) {
          isValid = false
          errors.push({
            field: `isActive-${i}`,
            message: t('provider-staff.validation.is-active-required'),
          })
        }
        if (!row.data.effective?.startDate) {
          isValid = false
          errors.push({
            field: `startDate-${i}`,
            message: t('provider-staff.validation.start-date-required'),
          })
        }

        if (row.data.isInternal && row.data.isStaff) {
          if (!row.data.organizationId) {
            isValid = false
            errors.push({
              field: `organization-${i}`,
              message: t('provider-staff.validation.organization-required'),
            })
          }
        }

        if (row.data.fullTimeEquivalencies) {
          row.data.fullTimeEquivalencies.forEach((fte, index) => {
            if (!fte.fullTimeEquivalency && !fte.hourlyRate) {
              isValid = false
              errors.push({
                field: `fullTimeEquivalencies-${i}-fullTimeEquivalency-${index}`,
                message: t('provider-staff.validation.fte-required'),
              })
              errors.push({
                field: `fullTimeEquivalencies-${i}-hourlyRate-${index}`,
                message: t('provider-staff.validation.hourly-rate-required'),
              })
            }

            if (fte.fullTimeEquivalency || fte.hourlyRate) {
              if (!fte.funder) {
                isValid = false
                errors.push({
                  field: `fullTimeEquivalencies-${i}-funder-${index}`,
                  message: t('provider-staff.validation.funder-required'),
                })
              }
            }
            if (fte.fullTimeEquivalency) {
              if (!isNumeric(fte.fullTimeEquivalency)) {
                isValid = false
                errors.push({
                  field: `fullTimeEquivalencies-${i}-fullTimeEquivalency-${index}`,
                  message: t('provider-staff.validation.numeric-value'),
                })
              } else if (
                parseFloat(fte.fullTimeEquivalency.toString()) < 0 ||
                parseFloat(fte.fullTimeEquivalency.toString()) > 9.99
              ) {
                isValid = false
                errors.push({
                  field: `fullTimeEquivalencies-${i}-fullTimeEquivalency-${index}`,
                  message: t('provider-staff.validation.out-of-range-value', {
                    max: 9.99,
                    min: 0,
                  }),
                })
              }
            }

            if (fte.hourlyRate) {
              if (!isNumeric(fte.hourlyRate)) {
                isValid = false
                errors.push({
                  field: `fullTimeEquivalencies-${i}-hourlyRate-${index}`,
                  message: t('provider-staff.validation.numeric-value'),
                })
              } else if (
                parseFloat(fte.hourlyRate.toString()) < 0 ||
                parseFloat(fte.hourlyRate.toString()) > 999.99
              ) {
                isValid = false
                errors.push({
                  field: `fullTimeEquivalencies-${i}-hourlyRate-${index}`,
                  message: t('provider-staff.validation.out-of-range-value', {
                    max: 999.99,
                    min: 0,
                  }),
                })
              }
            }

            if (!fte.effective?.startDate) {
              isValid = false
              errors.push({
                field: `fullTimeEquivalencies-${i}-startDate-${index}`,
                message: t('provider-staff.validation.start-date-required'),
              })
            }
          })
        }
      })
      return { errors, isValid }
    }
    const saveJobFunctions = async (
      personnelId: string,
      updatedJobFunctions: PersonnelJobFunctionDTO[]
    ) => {
      await PersonnelJobFunctionControllerService.saveJobFunctions(personnelId, updatedJobFunctions)
        .then((response) => {
          response.sort((a, b) => (a?.isPrimary ? -1 : b?.isPrimary ? 1 : 0))
          setJobFunctions(response)

          // Update local data copy
          setJobFunctionsData({ rows: sustainCollapsedState(response) })
          showSnackSuccess(t('api.save-success'))
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
    const { errors, isValid } = validate()
    if (personnelId && isValid) {
      const updatedJobFunctions = jobFunctionsData.rows.map((row) => row.data)
      saveJobFunctions(personnelId, updatedJobFunctions)
      setErrors([])
    } else {
      setErrors(errors)
    }
  }, [
    handleApiError,
    jobFunctionsData.rows,
    sustainCollapsedState,
    personnelId,
    setJobFunctions,
    showSnackSuccess,
    setIsDirty,
    t,
  ])

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      const deleteJobFunction = async (personnelId: string, jobFunctionId: string) => {
        await PersonnelJobFunctionControllerService.deletePersonnelJobFunction(
          personnelId,
          jobFunctionId
        )
          .then(() => {
            setJobFunctions(jobFunctions?.filter((job) => job.id !== jobFunctionId))
            // Update local data copy
            jobFunctionsData.rows.splice(rowIndex, 1)
            setJobFunctionsData(jobFunctionsData)

            showSnackSuccess(t('api.save-success'))
          })
          .catch((error) => {
            handleApiError(error)
          })
      }
      setDeleteIndex(-1)
      const jobFunctionId = jobFunctionsData.rows[rowIndex].data?.id
      if (personnelId && jobFunctionId) {
        deleteJobFunction(personnelId, jobFunctionId)
      } else {
        jobFunctionsData.rows.splice(rowIndex, 1)
        setJobFunctionsData({ rows: jobFunctionsData.rows })
      }
    },
    [
      handleApiError,
      jobFunctions,
      jobFunctionsData,
      personnelId,
      setJobFunctions,
      showSnackSuccess,
      t,
    ]
  )

  const getConditionalOrgs = useCallback(
    (isInternal: boolean | undefined) => {
      return isInternal
        ? govAgenciesOptions.filter((ga) =>
            ga.governanceAgencyType?.some((x) => x.code === 'Organization')
          )
        : govAgenciesOptions.filter(
            (ga) =>
              !ga.governanceAgencyType?.some(
                (x) => x.code === 'ReportingCommunity' || x.code === 'LocationOfService'
              )
          )
    },
    [govAgenciesOptions]
  )
  const getRespectiveDepartments = useCallback(
    (orgId: string | undefined) => {
      if (orgId) {
        return respectiveDepartments.find((obj) => obj.orgId === orgId)?.departments || []
      }
      return []
    },
    [respectiveDepartments]
  )

  const fetchDepartments = useCallback(
    async (agencyId: string, rowIndex: number) => {
      if (!agencyId) return
      if (respectiveDepartments.find((obj) => obj.orgId === agencyId)?.isTouched) {
        return
      }

      try {
        const response = await GovernanceAgencyControllerService.getAgencyDepartments(agencyId)
        respectiveDepartments.push({
          departments: response,
          isTouched: true,
          orgId: agencyId,
        })
        setRespectiveDepartments([...respectiveDepartments])
      } catch (error) {
        handleApiError(error)
      }
    },
    [respectiveDepartments, handleApiError]
  )

  useEffect(() => {
    if (jobFunctionsData?.rows?.length > 0) {
      jobFunctionsData?.rows.forEach((jobFunction, index) => {
        if (jobFunction?.data?.organizationId) {
          fetchDepartments(jobFunction?.data?.organizationId, index)
        }
      })
    }
  }, [jobFunctionsData, fetchDepartments])

  const rowForm = useCallback(
    (
      row: JobFunctionsDataRowType,
      index: number,
      onChangeRow: (index: number, field: string, value: string | PersonnelJobFunctionDTO[]) => void
    ) => {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <MISSelectDropdown
                error={!!getError(`jobFunction-${index}`)}
                helperText={getError(`jobFunction-${index}`)}
                id="job-function"
                label={t('provider-staff.add-edit.job-function')}
                onChange={(event) =>
                  onChangeRow(index, 'jobFunction', event.target.value as string)
                }
                options={jobFunctionOptions.map((opt) => ({
                  label: opt.name as string,
                  value: opt,
                }))}
                required
                value={jobFunctionOptions?.find((opt) => opt.code === row.data?.jobFunction?.code)}
              />
            </Grid>
            <Grid item xs={3}>
              <MISTextField
                id="job-title"
                label={t('provider-staff.add-edit.job-title')}
                onChange={(event) => onChangeRow(index, 'jobTitle', event.target.value)}
                value={row.data?.jobTitle}
              />
            </Grid>
            <Grid item xs={2}>
              <MISRadioGroup
                error={!!getError(`isInternal-${index}`)}
                helperText={getError(`isInternal-${index}`)}
                id={`is-internal-${index}`}
                label={t('provider-staff.add-edit.internal-external')}
                name={`is-internal-${index}`}
                onChange={(event) => onChangeRow(index, 'isInternal', event.target.value)}
                options={[
                  {
                    label: t('provider-staff.add-edit.internal'),
                    value: t('provider-staff.add-edit.internal'),
                  },
                  {
                    label: t('provider-staff.add-edit.external'),
                    value: t('provider-staff.add-edit.external'),
                  },
                ]}
                required
                value={
                  row.data?.isInternal === undefined
                    ? undefined
                    : row.data?.isInternal
                    ? t('provider-staff.add-edit.internal')
                    : t('provider-staff.add-edit.external')
                }
              />
            </Grid>
            <Grid item xs={2}>
              <MISRadioGroup
                error={!!getError(`isStaff-${index}`)}
                helperText={getError(`isStaff-${index}`)}
                id={`is-staff-${index}`}
                label={t('provider-staff.add-edit.staff')}
                name={`is-staff-${index}`}
                onChange={(event) => onChangeRow(index, 'isStaff', event.target.value)}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                required
                value={
                  row.data?.isStaff === undefined ? undefined : row.data?.isStaff ? 'Yes' : 'No'
                }
              />
            </Grid>
            <Grid item xs={2}>
              <MISRadioGroup
                error={!!getError(`isActive-${index}`)}
                helperText={getError(`isActive-${index}`)}
                id={`is-active-${index}`}
                label={t('provider-staff.add-edit.status')}
                name={`is-active-${index}`}
                onChange={(event) => onChangeRow(index, 'isActive', event.target.value)}
                options={[
                  {
                    label: t('provider-staff.add-edit.status-active'),
                    value: t('provider-staff.add-edit.status-active'),
                  },
                  {
                    label: t('provider-staff.add-edit.status-inactive'),
                    value: t('provider-staff.add-edit.status-inactive'),
                  },
                ]}
                required
                value={
                  row.data?.isActive === undefined
                    ? undefined
                    : row.data?.isActive
                    ? t('provider-staff.add-edit.status-active')
                    : t('provider-staff.add-edit.status-inactive')
                }
              />
            </Grid>
            <Grid item xs={4}>
              <MISSelectDropdown
                error={!!getError(`organization-${index}`)}
                helperText={getError(`organization-${index}`)}
                label="Organization"
                onChange={(event) => {
                  const org = event.target.value as GovernanceAgencyDTO
                  onChangeRow(index, 'organizationId', (org?.id || '') as string)
                }}
                options={getFormattedOptions(getConditionalOrgs(row.data.isInternal))}
                required={row.data.isStaff && row.data.isInternal}
                value={getConditionalOrgs(row.data.isInternal).find(
                  (ga: GovernanceAgencyDTO) => ga.id === row.data?.organizationId
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <MISSelectDropdown
                disabled={
                  !getRespectiveDepartments(row.data?.organizationId) ||
                  getRespectiveDepartments(row.data?.organizationId)?.length === 0
                }
                label="Department"
                onChange={(event) => {
                  const org = event.target.value as GovernanceAgencyDTO
                  onChangeRow(index, 'departmentId', (org?.id || '') as string)
                }}
                options={getFormattedOptions(getRespectiveDepartments(row.data?.organizationId))}
                value={getRespectiveDepartments(row.data?.organizationId)?.find(
                  (o: GovernanceAgencyWithDomainsDTO) => {
                    return o.id === row.data?.departmentId
                  }
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <MISSelectDropdown
                label="Location Of Service"
                onChange={(event) => {
                  const org = event.target.value as GovernanceAgencyDTO
                  onChangeRow(index, 'locationOfServiceId', (org?.id || '') as string)
                }}
                options={getFormattedOptions(locationOfServiceOptions)}
                value={locationOfServiceOptions.find(
                  (ga: GovernanceAgencyDTO) => ga.id === row.data?.locationOfServiceId
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <MISSelectDropdown
                id="fnih-specialty"
                label={t('provider-staff.job-function.fnih-specialty')}
                onChange={(event) =>
                  onChangeRow(index, 'fnihSpecialty', event.target.value as string)
                }
                options={specialtyOptions.map((opt) => ({
                  label: opt.name as string,
                  value: opt,
                }))}
                value={specialtyOptions?.find((opt) => opt.code === row.data?.fnihSpecialty?.code)}
              />
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                error={!!getError(`startDate-${index}`)}
                helperText={getError(`startDate-${index}`)}
                isDefaultToday
                label={t('provider-staff.add-edit.start-date')}
                onChange={(value: unknown) => onChangeRow(index, 'jobStartDate', value as string)}
                readOnly={false}
                required
                value={row.data?.effective?.startDate}
              />
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                error={undefined}
                helperText={undefined}
                label={t('provider-staff.job-function.end-date')}
                onChange={(value: unknown) => onChangeRow(index, 'jobEndDate', value as string)}
                readOnly={false}
                required={false}
                value={row.data?.effective?.endDate}
              />
            </Grid>
          </Grid>
          <FTEFunders
            fullTimeEquivalencies={row.data?.fullTimeEquivalencies}
            getError={getError}
            jobFunctionId={row.data?.id || ''}
            jobFunctionIndex={index}
            personnelId={personnelId || ''}
            setFullTimeEquivalencies={(value: PersonnelJobFunctionDTO[]) =>
              onChangeRow(index, 'fullTimeEquivalencies', value)
            }
          />
        </>
      )
    },
    [
      personnelId,
      getError,
      jobFunctionOptions,
      getRespectiveDepartments,
      locationOfServiceOptions,
      specialtyOptions,
      getConditionalOrgs,
      t,
    ]
  )

  const rowHeader = useCallback(
    (
      row: JobFunctionsDataRowType,
      rowIndex: number,
      onChangeRow: (rowIndex: number, field: string) => void
    ) => {
      const jobFunction = jobFunctionOptions?.find(
        (o: CodedRef) => o.code === row.data?.jobFunction?.code
      )
      return (
        <>
          <MISRadio
            checked={row.data?.isPrimary}
            onChange={() => setPrimaryIndex(rowIndex)}
            onClick={(event) => event.stopPropagation()}
            sx={{ pointerEvents: 'auto' }}
            value={rowIndex}
          />
          {jobFunction?.name && <span>{jobFunction.name}</span>}
          {row.data?.jobTitle && <span>{` | ${row.data?.jobTitle}`}</span>}
          <span>{` | ${
            row.data?.isActive
              ? t('provider-staff.add-edit.status-active')
              : t('provider-staff.add-edit.status-inactive')
          }`}</span>
          <span>{` | ${
            row.data?.isInternal
              ? t('provider-staff.add-edit.internal')
              : t('provider-staff.add-edit.external')
          }`}</span>
          {row.data?.effective?.startDate && (
            <span>{` | ${isoDateToDisplayFormat(row.data?.effective?.startDate)}`}</span>
          )}
          {row.data?.effective?.endDate && (
            <span>{` to ${isoDateToDisplayFormat(row.data?.effective?.endDate)}`}</span>
          )}
          {row.data?.isPrimary && (
            <span>
              {' | '}
              <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>
                {t('client-person.language.primary')}
              </span>
            </span>
          )}
        </>
      )
    },
    [jobFunctionOptions, t]
  )

  return (
    <>
      <SectionHeader
        addLabel={t('provider-staff.job-function.add-job-function')}
        onAdd={onAddRow}
        onSave={onJobFunctionsSave}
        saveLabel={t('common.button.save')}
        title={t('provider-staff.job-function.header.title')}
      />
      <Box className="provider-staff-section-container">
        <MISAccordionContainer
          errors={errors.length === 0 ? [] : [t('common.validation.errors')]}
          onChangeRow={onChangeRow}
          onCollapseRow={onCollapseRow}
          onRemoveRow={setDeleteIndex}
          rowForm={rowForm}
          rowHeader={rowHeader}
          rows={jobFunctionsData.rows}
        />
      </Box>
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setPrimaryIndex(-1)}
        onSave={() => {
          setPrimaryIndex(-1)
          onChangeRow(primaryIndex, 'isPrimary', true)
        }}
        open={primaryIndex > -1}
        type={MODALS.PREFERRED_WARNING}
      />
    </>
  )
}

export default JobFunction
