/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImmunizationScheduleDTO } from '../models/ImmunizationScheduleDTO'
import type { PageImmunizationScheduleDTO } from '../models/PageImmunizationScheduleDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ImmunizationScheduleControllerService {
  /**
   * Search Immunization schedules by name and agentCode
   * @param name
   * @param agentCode
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageImmunizationScheduleDTO OK
   * @throws ApiError
   */
  public static searchAllImmunizationSchedules(
    name?: string,
    agentCode?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageImmunizationScheduleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/immunizations/schedules',
      query: {
        name: name,
        agentCode: agentCode,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ImmunizationScheduleDTO OK
   * @throws ApiError
   */
  public static putImmunizationSchedules(
    requestBody: Array<ImmunizationScheduleDTO>
  ): CancelablePromise<Array<ImmunizationScheduleDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/immunizations/schedules',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param scheduleId
   * @returns ImmunizationScheduleDTO OK
   * @throws ApiError
   */
  public static getImmunizationScheduleById(
    scheduleId: string
  ): CancelablePromise<ImmunizationScheduleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/immunizations/schedules/{scheduleId}',
      path: {
        scheduleId: scheduleId,
      },
    })
  }
}
