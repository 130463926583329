import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISRadio from 'common/components/form/MISRadio'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import GLOBAL from 'common/styles/global.scss'
import {
  isDateAfterToday,
  isDateBeforeOrEqualDate,
  isDateBeforeOrEqualToday,
  isDateBeforeToday,
  isoDateToDisplayFormat,
  isOverlappingDatesInDateArray,
} from 'common/utils/DateUtils'
import { camelCaseToTitleCase } from 'common/utils/StringUtils'
import { ClientNameDataType } from 'modules/client/ClientDetails/Types'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import { PERSON_NAME_USE_VOCAB_NAME } from 'services/terminologyConstants'
import { ErrorType, getRowTitle } from './utils'

const ENTITY = 'Name'

const Names = ({
  deleteCallback,
  lastNameRequired = false,
  names,
  saveTriggered,
  setNames,
  setSaveTriggered,
  setShouldSave,
}: any) => {
  const { t } = useTranslation('common')
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const nameTypes = useRecoilValue(terminologySelector(PERSON_NAME_USE_VOCAB_NAME))
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    index: -1,
    open: false,
  })
  const [preferredWarningDialog, setPreferredWarningDialog] = useState({ data: {}, open: false })

  useEffect(() => {
    const validateLegalOverlappingDates = (legalNames: ClientNameDataType[]) => {
      const legalNameDates = legalNames.map(
        (legalName: { data: { startDate?: string; endDate?: string } }) => {
          return { endDate: legalName.data.endDate, startDate: legalName.data.startDate }
        }
      )
      return isOverlappingDatesInDateArray(legalNameDates)
    }

    const validate = () => {
      let isValid = true

      const validatedRows = names.rows.map((row: any) => {
        const errors: ErrorType[] = []
        if (!row.data?.firstName) {
          isValid = false
          errors.push({
            field: 'firstName',
            message: t('client-demographics.names.validation.first-name-required'),
          })
        }
        if (lastNameRequired && !row.data?.lastName) {
          isValid = false
          errors.push({
            field: 'lastName',
            message: t('client-demographics.names.validation.last-name-required'),
          })
        }
        if (!row.data?.type) {
          isValid = false
          errors.push({
            field: 'type',
            message: t('client-demographics.names.validation.type-required'),
          })
        }

        if (!row.data?.startDate) {
          isValid = false
          errors.push({
            field: 'startDate',
            message: t('client-demographics.names.validation.start-date-required'),
          })
        } else if (row.data?.startDate) {
          const datePickerError = row.errors.filter((error: any) => {
            return error.field === 'startDate'
          })
          if (datePickerError.length > 0) {
            isValid = false
          }
        }
        if (row.data?.endDate) {
          const datePickerError = row.errors.filter((error: any) => {
            return error.field === 'endDate'
          })
          if (datePickerError.length > 0) {
            isValid = false
          }
        }
        return {
          ...row,
          errors: errors,
        }
      })

      if (!isValid) {
        return {
          errors: ['Validation errors, expand the rows to view'],
          isValid: false,
          validatedRows,
        }
      }

      const clientNameErrors = []
      // Preferred Name Validation
      const preferredNames = names.rows.filter((row: ClientNameDataType) => {
        return row.data?.preferredName === true
      })
      if (preferredNames.length > 1) {
        clientNameErrors.push(t('client-demographics.names.validation.only-one-preffered-name'))
      } else if (preferredNames.length === 0) {
        clientNameErrors.push(t('client-demographics.names.validation.atleast-one-preffered-name'))
      } else if (
        preferredNames[0].data.startDate &&
        isDateAfterToday(preferredNames[0].data.startDate)
      ) {
        clientNameErrors.push(t('client-demographics.names.validation.effective-start-date-today'))
      } else if (
        preferredNames[0].data.endDate &&
        isDateBeforeToday(preferredNames[0].data.endDate)
      ) {
        clientNameErrors.push(
          t('client-demographics.names.validation.effective-end-date-after-today')
        )
      } else if (
        preferredNames[0].data.startDate &&
        preferredNames[0].data.endDate &&
        isDateBeforeOrEqualToday(preferredNames[0].data.endDate) &&
        isDateBeforeOrEqualDate(preferredNames[0].data.endDate, preferredNames[0].data.startDate)
      ) {
        clientNameErrors.push(t('client-demographics.names.validation.effective-end-date-same'))
      }

      // Legal name validation
      const legalNames: ClientNameDataType[] = names.rows.filter((row: ClientNameDataType) => {
        return row.data?.type?.code === 'L'
      })
      if (legalNames.length > 1) {
        if (validateLegalOverlappingDates(legalNames)) {
          clientNameErrors.push(
            t('client-demographics.names.validation.legal-names-overlapping-dates')
          )
        }
      } else if (
        (names.rows.length === 1 && names.rows[0]?.data?.type?.code !== 'L') ||
        legalNames.length === 0
      ) {
        clientNameErrors.push(t('client-demographics.names.validation.atleast-one-legal-name'))
      }

      if (clientNameErrors.length) {
        return { errors: clientNameErrors, isValid: false, validatedRows }
      }

      return { errors: clientNameErrors, isValid, validatedRows }
    }

    if (saveTriggered) {
      setIsDirty(false)

      const { errors, isValid, validatedRows }: any = validate()
      if (!isValid) {
        setNames({ ...names, errors: errors, rows: validatedRows })
        setSaveTriggered(false)
      } else {
        setShouldSave(true)
        setSaveTriggered(false)
      }
    }
  }, [
    lastNameRequired,
    names,
    setNames,
    saveTriggered,
    setSaveTriggered,
    setShouldSave,
    setIsDirty,
    t,
  ])

  const rowHeader = (row: any, rowIndex: number, onChangeRow: any) => {
    const rowTitle = getRowTitle([
      `${row.data?.prefix} ${row.data?.firstName} ${row.data?.middleName} ${row.data?.lastName} ${row.data?.suffix}`,
      row.data?.type?.name,
      `${isoDateToDisplayFormat(row.data?.startDate)} ${
        row.data?.endDate ? 'to ' + isoDateToDisplayFormat(row.data?.endDate) : ''
      }`,
    ])

    return (
      <>
        <MISRadio
          checked={row.data?.preferredName}
          name="names"
          onChange={() => {
            onChangeRow(rowIndex, 'preferredName', !row.data?.preferredName)
          }}
          onClick={(event) => {
            event.stopPropagation()
          }}
          sx={{
            pointerEvents: 'auto',
          }}
        />
        {rowTitle && <span>{rowTitle}</span>}
        {row.data?.preferredName && (
          <>
            {` | `}
            <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{`${t(
              'client-contacts.preferred'
            )}`}</span>
          </>
        )}
      </>
    )
  }

  const rowDataForm = (row: ClientNameDataType, rowIndex: number, onChangeRow: any) => {
    const hasError = (field: string) => {
      const rowError = row.errors?.filter((error: any) => {
        return error.field === field
      })
      if (rowError && rowError[0]) {
        return true
      } else {
        return false
      }
    }

    const getError = (field: string) => {
      const rowError = row.errors?.filter((error: any) => {
        return error.field === field
      })
      if (rowError && rowError[0]) {
        return rowError[0].message
      } else {
        return ''
      }
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {nameTypes && (
            <MISSelectDropdown
              error={hasError('type')}
              helperText={getError('type')}
              label={t('client-demographics.names.type')}
              onChange={(event: any) => onChangeRow(rowIndex, 'type', event.target.value)}
              options={nameTypes?.map((option: any) => {
                return {
                  label: option.name,
                  value: option,
                }
              })}
              required
              value={row?.data?.type}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <MISTextField
            error={hasError('firstName')}
            helperText={getError('firstName')}
            label={t('client-demographics.names.first-name')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'firstName', event.target.value)
            }
            required
            value={row?.data?.firstName}
          />
        </Grid>
        <Grid item xs={3}>
          <MISTextField
            label={t('client-demographics.names.middle-name')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'middleName', event.target.value)
            }
            value={row?.data?.middleName}
          />
        </Grid>
        <Grid item xs={3}>
          <MISTextField
            error={hasError('lastName')}
            helperText={getError('lastName')}
            label={t('client-demographics.names.last-name')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'lastName', event.target.value)
            }
            required={lastNameRequired}
            value={row?.data?.lastName}
          />
        </Grid>
        <Grid item xs={1.5}>
          <MISTextField
            label={t('client-demographics.names.prefix')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'prefix', event.target.value)
            }
            value={row?.data?.prefix}
          />
        </Grid>
        <Grid item xs={1.5}>
          <MISTextField
            label={t('client-demographics.names.suffix')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'suffix', event.target.value)
            }
            value={row?.data?.suffix}
          />
        </Grid>
        <Grid item xs={3}>
          <MISDatePicker
            error={hasError('startDate')}
            helperText={getError('startDate')}
            label={t('client-demographics.names.effective-start-date')}
            onChange={(value: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'startDate', value)
            }
            readOnly={false}
            required
            value={row.data?.startDate}
          />
        </Grid>
        <Grid item xs={3}>
          <MISDatePicker
            error={hasError('startDate')}
            helperText={getError('startDate')}
            label={t('client-demographics.names.effective-end-date')}
            onChange={(value: ChangeEvent<HTMLInputElement>) =>
              onChangeRow(rowIndex, 'endDate', value)
            }
            readOnly={false}
            required={false}
            value={row.data?.endDate}
          />
        </Grid>
      </Grid>
    )
  }

  const onChangeRow = (rowIndex: number, field: string, value: any) => {
    if (!isDirty) setIsDirty(true)

    const updatedRows = names.rows.map((row: any, index: number) => {
      // For preferred flag
      if (field === 'preferredName' && rowIndex === index) {
        setPreferredWarningDialog({
          data: { field: field, rowIndex: rowIndex, value: value },
          open: true,
        })
        return { ...row }
      }

      if (rowIndex === index) {
        // Only for DatePicker if the Date object is invalid
        if (value?.invalid) {
          const rowWithErrors = {
            ...row,
            errors: [
              {
                field,
                message: `Effective ${camelCaseToTitleCase(field)} is not a valid date'`,
              },
            ],
          }
          return rowWithErrors
        }

        const attr = Object.keys(row.data).filter((r) => r === field)
        const newRow = { ...row }
        const fieldName: string = attr[0]
        Object.assign(newRow.data, { [fieldName]: value })
        if (value) {
          newRow.errors = newRow.errors.filter((error: any) => {
            return error.field !== field
          })
        }
        return newRow
      }
      return row
    })
    setNames({ ...names, rows: updatedRows })
  }

  const onCollapseRow = (rowIndex: number) => {
    const collapsedRowsUpdate = names.rows.map((row: any, index: number) => {
      if (rowIndex === index) {
        return { ...row, isCollapsed: !row.isCollapsed }
      }
      return row
    })
    setNames({ ...names, rows: collapsedRowsUpdate })
  }

  const onPreferredWarningClose = useCallback(() => {
    setPreferredWarningDialog({ data: {}, open: false })
  }, [])

  const onPreferredWarningSave = useCallback(
    ({ field, rowIndex, value }: any) => {
      setPreferredWarningDialog({ ...preferredWarningDialog, open: false })
      const updatedRows = names.rows.map((row: any, index: number) => {
        const newRow = { ...row }
        if (field === 'preferredName') {
          Object.assign(newRow.data, { preferredName: false })
        }
        if (rowIndex === index) {
          const attr = Object.keys(row.data).filter((r) => r === field)
          const fieldName: string = attr[0]
          Object.assign(newRow.data, { [fieldName]: value })
          if (value) {
            newRow.errors = newRow.errors.filter((error: any) => {
              return error.field !== field
            })
          }
        }
        return newRow
      })
      setNames({ ...names, rows: updatedRows })
    },
    [names, setNames, preferredWarningDialog]
  )

  const onDeleteWarningClose = useCallback(() => {
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog])

  const onDeleteWarningSave = useCallback(() => {
    const onRemoveRow = (rowIndex: number) => {
      const rowItemToDelete = names.rows[rowIndex]
      if (rowItemToDelete?.data?.id) {
        deleteCallback(rowItemToDelete?.data?.id)
      } else {
        names.rows.splice(rowIndex, 1)
        setNames({ ...names })
      }
    }

    onRemoveRow(deleteConfirmationDialog.index)
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [names, setNames, deleteCallback, deleteConfirmationDialog])

  return (
    <>
      {names && (
        <MISAccordionContainer
          emptyDataMessage={t('client-demographics.names.empty-message')}
          errors={names.errors}
          onChangeRow={onChangeRow}
          onCollapseRow={onCollapseRow}
          onRemoveRow={(index: number) => {
            setDeleteConfirmationDialog({ index: index, open: true })
          }}
          rowForm={rowDataForm}
          rowHeader={rowHeader}
          rows={names.rows}
        />
      )}
      <WarningDialog
        entity={ENTITY}
        onCancel={onDeleteWarningClose}
        onSave={onDeleteWarningSave}
        open={deleteConfirmationDialog.open}
        type={MODALS.DELETE_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => onPreferredWarningClose()}
        onSave={() => onPreferredWarningSave(preferredWarningDialog.data)}
        open={preferredWarningDialog.open}
        type={MODALS.PREFERRED_WARNING}
      />
    </>
  )
}

export default Names
