import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { TextFieldProps } from '@mui/material'
import MISTextField from './MISTextField'

const MAX_DURATION_IN_MINUTES = 5999

export const toHHMM = (value: number): string => {
  const padToTwoDigits = (num: number): string => {
    return num.toString().padStart(2, '0')
  }
  const hours = Math.floor(value / 60)
  const minutes = value % 60
  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`
}

export const getMinutes = (value: string): number => {
  if (value.indexOf(':') > -1) {
    const [str1, str2] = value.split(':')
    const val1 = Number(str1)
    const val2 = str2.length === 1 ? Number(str2) * 10 : Number(str2)
    return Math.min(val1 * 60 + val2, MAX_DURATION_IN_MINUTES)
  } else if (value.indexOf('.') > -1) {
    const [str1, str2] = value.split('.')
    const val1 = Number(str1)
    const val2 = str2.length === 1 ? Number(str2) * 10 : Number(str2)
    return Math.min(val1 * 60 + Math.floor((val2 / 100) * 60), MAX_DURATION_IN_MINUTES)
  }
  return Number(value)
}

type DurationFieldCustomProps = {
  onChange: (value: number | undefined) => void
}

const MISDurationField = (props: DurationFieldCustomProps & TextFieldProps) => {
  const { onChange, value } = props
  const [currValue, setCurrValue] = useState<number | undefined>(Number(value))
  const [displayValue, setDisplayValue] = useState(value ? toHHMM(Number(value)) : '')

  const textfieldInFocusRef = useRef<boolean>(false)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== '') {
        if (isNaN(Number(event.target.value))) {
          const str = event.target.value
          if (str.startsWith('.')) setDisplayValue('0.')
          else if (str.endsWith('.')) setDisplayValue(str)
          else if (str.indexOf(':') > -1 && str.indexOf(':') < 3) {
            const timeString = str.substring(0, Math.min(str.indexOf(':') + 3, str.length))
            const timeInMinutes = getMinutes(timeString)
            setCurrValue(timeInMinutes)
            setDisplayValue(
              str.indexOf(':') === str.length - 3 ? toHHMM(timeInMinutes) : timeString
            )
            onChange(timeInMinutes)
          } else {
            setDisplayValue(str.replace(/[^\d]/g, ''))
          }
        } else {
          if (event.target.value.indexOf('.') > -1) {
            const [str1, str2] = event.target.value.split('.')
            if (str2.length === 0) {
              setDisplayValue(event.target.value)
            } else if (str2.length === 1) {
              const decimalInMinutes = getMinutes(event.target.value)
              setCurrValue(Math.min(decimalInMinutes, MAX_DURATION_IN_MINUTES))
              setDisplayValue(
                decimalInMinutes === MAX_DURATION_IN_MINUTES ? '99:59' : event.target.value
              )
              onChange(Math.min(decimalInMinutes, MAX_DURATION_IN_MINUTES))
            } else {
              const decimalInMinutes = getMinutes(`${str1}.${str2.substring(0, 2)}`)
              setCurrValue(Math.min(decimalInMinutes, MAX_DURATION_IN_MINUTES))
              setDisplayValue(toHHMM(Math.min(decimalInMinutes, MAX_DURATION_IN_MINUTES)))
              onChange(Math.min(decimalInMinutes, MAX_DURATION_IN_MINUTES))
            }
          } else if (Number(event.target.value) === 0) {
            setCurrValue(undefined)
            setDisplayValue('0')
            onChange(undefined)
          } else if (Number(event.target.value) > 5999) {
            setCurrValue(5999)
            setDisplayValue(toHHMM(5999))
            onChange(5999)
          } else {
            setCurrValue(Number(event.target.value))
            setDisplayValue(event.target.value)
            onChange(Number(event.target.value))
          }
        }
      } else {
        setCurrValue(undefined)
        setDisplayValue('')
        onChange(undefined)
      }
    },
    [onChange]
  )

  const handleOnBlur = useCallback(() => {
    textfieldInFocusRef.current = false
    if (currValue) setDisplayValue(toHHMM(currValue))
  }, [currValue])

  useEffect(() => {
    if (!textfieldInFocusRef.current && currValue && currValue >= MAX_DURATION_IN_MINUTES)
      setDisplayValue(toHHMM(MAX_DURATION_IN_MINUTES))
  }, [currValue])

  useEffect(() => {
    if (value) {
      setCurrValue(Number(value))
      if (textfieldInFocusRef.current === false && toHHMM(Number(value)) !== displayValue)
        setDisplayValue(toHHMM(Number(value)))
      else if (textfieldInFocusRef.current && value && Number(value) <= 9)
        setDisplayValue(currValue ? `${currValue}` : '')
    } else setDisplayValue('')
  }, [currValue, displayValue, value])

  return (
    <MISTextField
      {...props}
      inputProps={{ maxLength: 5 }}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onFocus={() => (textfieldInFocusRef.current = true)}
      value={displayValue}
    />
  )
}

export default MISDurationField
