import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISTextField from 'common/components/form/MISTextField'
import MISLink from 'common/components/MISLink'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { tagsAtom } from 'recoil/atoms'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  DocumentControllerService,
  DocumentDTO,
  PageDocumentDTO,
} from 'services/openapi'
import { MIS_DOCUMENT_TITLE } from 'services/terminologyConstants'
import { MODALS } from './constants'
import AddEditDocumentDialog from './Dialogs/AddEditDocumentDialog'
import WarningDialog from './Dialogs/WarningDialog'
import DocumentSectionHeader from './DocumentSectionHeader/DocumentSectionHeader'

export type DocumentProps = {
  entityId: string
  entityType: string
  breadcrumb: string
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void
  order: Order
  orderBy: any
}

const ENTITY = 'Documents'

const headCells = [
  {
    id: 'description',
    label: 'Document Description',
    width: '25%',
  },
  {
    id: 'fileName',
    label: 'File Name',
    width: '10%',
  },
  {
    id: 'ontologyName',
    label: 'Ontology Name',
    width: '35%',
  },
  {
    id: 'addedOn',
    label: 'Added On',
    width: '10%',
  },
  {
    id: 'addedBy',
    label: 'Added By',
    width: '10%',
  },
  {
    id: 'source',
    label: 'Source',
    width: '10%',
  },
]

const moreCells = [
  {
    id: 'download',
    label: 'Download',
  },
  {
    id: 'edit',
    label: 'Edit Properties',
  },
  {
    id: 'delete',
    label: 'Delete',
  },
]

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onRequestSort, order, orderBy } = props

  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((column) => (
          <TableCell
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const Documents = ({ breadcrumb, entityId, entityType }: DocumentProps) => {
  const { t } = useTranslation('common')
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    id: '',
    open: false,
  })
  const navigate = useNavigate()
  const { handleApiError } = useErrorHandler()

  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rowCount, setRowCount] = useState(0)
  const [rowData, setRowData] = useState<DocumentDTO[]>([])
  const [documentListResponse, setDocumentListResponse] = useState<PageDocumentDTO>({})
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [addEditDocumentDialog, setAddEditDocumentDialog] = useState({
    data: {},
    edit: false,
    open: false,
  })
  const { showSnackSuccess } = useSnack()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const titleOptions: CodedConceptDto[] = useRecoilValue(terminologySelector(MIS_DOCUMENT_TITLE))
  const [tags, setTagsValues] = useRecoilState(tagsAtom)

  useEffect(() => {
    const getTagsValues = async () => {
      try {
        const response = await DocumentControllerService.getAllTags()
        if (response) {
          setTagsValues(response.content)
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    getTagsValues()
  }, [handleApiError, setTagsValues])

  const getDocAssociationLabel = useCallback((entity?: string) => {
    switch (entity) {
      case 'CLIENT_FILE':
        return 'Client'
    }
    return 'First Source'
  }, [])

  const getDocAssociationNav = useCallback((entity?: string, entityId?: string) => {
    switch (entity) {
      case 'CLIENT_FILE':
        return '/clients/client-record/' + entityId
    }
    return ''
  }, [])

  const handleSearchDocuments = useCallback(() => {
    DocumentControllerService.getDocumentList(entityId, searchText, [], page, rowsPerPage, [
      `${orderBy},${order}`,
    ])
      .then((response) => {
        setDocumentListResponse(response)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [entityId, searchText, page, rowsPerPage, orderBy, order, handleApiError])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    handleSearchDocuments()
  }, [handleSearchDocuments])

  useEffect(() => {
    if (searchText.length >= 3) {
      handleSearchDocuments()
    }
  }, [handleSearchDocuments, searchText.length])

  const onDeleteWarningClose = useCallback(() => {
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog])

  const onDeleteWarningSave = useCallback(() => {
    const onRemoveDoc = (id: string) => {
      DocumentControllerService.deleteDocument(id)
        .then((response) => {
          showSnackSuccess(t('documents.successful-delete-message'))
          handleSearchDocuments()
        })
        .catch((error) => {
          handleApiError(error)
        })
    }

    onRemoveDoc(deleteConfirmationDialog.id)
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog, showSnackSuccess, t, handleSearchDocuments, handleApiError])

  const navigateToEntity = useCallback(
    (nav: string) => {
      navigate(nav)
    },
    [navigate]
  )

  const getSourceList = useCallback(
    (document: DocumentDTO) => {
      return document.associatedEntities
        ?.filter((x) => x.entityType !== entityType)
        .map((entity) => {
          return {
            key: entity.entityId,
            label: getDocAssociationLabel(entity?.entityType),
            nav: getDocAssociationNav(entity.entityType, entity.entityId),
          }
        })
    },
    [entityType, getDocAssociationLabel, getDocAssociationNav]
  )

  const handleOpenDocumentUpload = useCallback(() => {
    setAddEditDocumentDialog({ data: {}, edit: false, open: true })
  }, [])

  const handleSelect = useCallback(
    (option: string, row: DocumentDTO) => {
      if (row && row.id) {
        switch (option) {
          case 'download': {
            DocumentControllerService.getDocumentById(row.id)
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]))
                const a = document.createElement('a')
                a.href = url
                a.download = row.fileName ? row.fileName : 'idhe_downloadFile' // Specify the filename for the downloaded file
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()

                window.URL.revokeObjectURL(url)
              })
              .catch((error) => {
                handleApiError(error)
              })
            break
          }
          case 'edit': {
            setAddEditDocumentDialog({ data: { document: row }, edit: true, open: true })
            break
          }
          case 'delete': {
            setDeleteConfirmationDialog({ id: row.id, open: true })
            break
          }
        }
      }
      setAnchorEl(null)
    },
    [handleApiError]
  )

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback((event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const handleMoreClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>, row: DocumentDTO) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleRequestSort = useCallback(
    (_: React.MouseEvent<unknown>, property: string | number | symbol) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property.toString())
    },
    [order, orderBy]
  )

  const handleClose = useCallback(
    (update: boolean) => {
      setAddEditDocumentDialog({ data: {}, edit: false, open: false })
      if (update) {
        handleSearchDocuments()
      }
    },
    [handleSearchDocuments]
  )

  const handlePopoverClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setSelectedIndex(-1)
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    if (documentListResponse) {
      setRowData(documentListResponse?.content || [])
      setRowCount(documentListResponse?.totalElements || 0)
    }
  }, [documentListResponse])

  return (
    <>
      <DocumentSectionHeader onAdd={handleOpenDocumentUpload} />
      <div className="governance-agency-list">
        <Box className="container special">
          <Box className="field">
            <Typography component="div" gutterBottom variant="h6">
              {t('documents.search-text-field')}
            </Typography>
            <Divider />
            <Tooltip arrow placement="top" title={t('documents.search-text-tooltip')}>
              <MISTextField
                id="search-text-tooltip"
                label={t('documents.search-text-tooltip')}
                onChange={handleSearchTextChange}
                placeholder={t('documents.search-text-placeholder')}
                value={searchText}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box className="container">
          <Box className="field" />

          <TableContainer className="table-container" component={Paper}>
            <Table aria-label="simple table" className="table">
              <EnhancedTableHead
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              {rowData && rowData.length > 0 && (
                <TableBody>
                  {rowData.map((row: DocumentDTO, index: number) => {
                    if (row) {
                      return (
                        <TableRow className="row" hover key={row.id}>
                          <TableCell className="cell special" scope="row">
                            {row.description}
                          </TableCell>
                          <TableCell className="cell">{row.fileName}</TableCell>
                          <TableCell className="cell">
                            {row.ontologyTitle
                              ? titleOptions.find((x) => x.code === row?.ontologyTitle?.code)?.name
                              : ''}
                          </TableCell>
                          <TableCell className="cell">{row.authoredDatetime}</TableCell>
                          <TableCell className="cell">{row.author}</TableCell>
                          <TableCell className="cell">
                            {getSourceList(row)?.map((source) => {
                              return (
                                <div key={source.key}>
                                  <MISLink
                                    aria-current="page"
                                    color="primary"
                                    key="link-source"
                                    onClick={(event) => {
                                      event.preventDefault()
                                      navigateToEntity(source.nav)
                                    }}
                                    underline="hover"
                                    variant="inherit"
                                  >
                                    {source.label}
                                  </MISLink>
                                </div>
                              )
                            })}
                          </TableCell>
                          <TableCell className="cell">
                            <IconButton
                              aria-controls={anchorEl ? 'document-more' + entityId : undefined}
                              aria-expanded={anchorEl ? 'true' : undefined}
                              aria-haspopup="true"
                              aria-label="more"
                              id={'document-more' + row.id}
                              key={'document-more' + row.id}
                              onClick={(event) => {
                                setSelectedIndex(index)
                                handleMoreClick(event, row)
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Popover
                              anchorEl={anchorEl}
                              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                              id={'document-more-' + row.id}
                              key={'document-more-' + row.id}
                              onClick={(event) => {
                                event.stopPropagation()
                              }}
                              onClose={handlePopoverClose}
                              open={Boolean(anchorEl) && selectedIndex === index}
                            >
                              <Box>
                                {moreCells.map((option) => (
                                  <MenuItem
                                    divider
                                    key={option.id + row.id}
                                    onClick={() => {
                                      handleSelect(option.id, row)
                                    }}
                                    sx={{ height: 54, width: 300 }}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Box>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      )
                    } else {
                      return null
                    }
                  })}
                </TableBody>
              )}
            </Table>
            <TablePagination
              component="div"
              count={rowCount}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          </TableContainer>
        </Box>
        <AddEditDocumentDialog
          editData={addEditDocumentDialog.edit ? addEditDocumentDialog.data : undefined}
          entityId={entityId}
          entityType={entityType}
          onCloseCallback={handleClose}
          openDialog={addEditDocumentDialog.open}
          tags={tags}
        />
        <WarningDialog
          entity={ENTITY}
          onCancel={onDeleteWarningClose}
          onSave={onDeleteWarningSave}
          open={deleteConfirmationDialog.open}
          type={MODALS.DELETE_WARNING}
        />
      </div>
    </>
  )
}

export default Documents
