import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { purposesAtom, purposesGroupAtom } from 'recoil/atoms'
import {
  PurposeControllerService,
  PurposeGroupAssociationControllerService,
} from 'services/openapi'

export default function Purposes() {
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState({})
  const [associations, setAssociations] = useState(new Map())
  const [otherEntities, setOtherEntities] = useState([])
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [refetchTrigger, setRefetchTrigger] = useState(0)

  const purposeGroups = useRecoilValue(purposesGroupAtom)

  const getEntitiesServiceCall = PurposeControllerService.listPurposes
  const createEntityServiceCall = PurposeControllerService.createPurpose
  const updateEntityServiceCall = PurposeControllerService.updatePurpose
  const updateAtomCall = useSetRecoilState(purposesAtom)

  const savePurposeAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      PurposeGroupAssociationControllerService.updatePurposeGroupAssociation,
      PurposeControllerService.addPurposeGroupToPurpose,
      setOpenLinkModal,
      setRefetchTriggerPlusOne,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const setRefetchTriggerPlusOne = () => {
    setRefetchTrigger(refetchTrigger + 1)
  }

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      PurposeControllerService.getPurposeGroupAssociationsForPurpose(selectedRow.id).then(
        (data) => {
          setOtherEntities(purposeGroups)
          buildAssociationMapUtil(
            data,
            purposeGroups,
            'purposeGroupId',
            setAssociations,
            setOtherEntities,
            handleApiError,
            selectedRow
          )
        },
        (error) => {
          handleApiError(error)
        }
      )
    }
  }, [selectedRow, refetchTrigger, handleApiError, purposeGroups])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('purposes.title.purposes')}</h1>
        {t('purposes.title.purposes-description')}

        <EntityTemplate
          createEntityServiceCall={createEntityServiceCall}
          entityName={t('purposes.title.purposes')}
          getEntitiesServiceCall={getEntitiesServiceCall}
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={updateEntityServiceCall}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box sx={{ p: 2 }}>
          <h3>
            {t('purposes.title.association-header')} {selectedRow.name}
          </h3>
          <AssociationTable
            associations={associations}
            closeLinkModalCallBack={() => setOpenLinkModal(false)}
            entity={selectedRow}
            headerName={t('purpose-groups.title.purpose-groups')}
            openLinkModal={openLinkModal}
            openLinkModalCallBack={() => setOpenLinkModal(true)}
            otherEntities={otherEntities}
            saveCallBack={savePurposeAssociations}
          />
        </Box>
      )}
    </>
  )
}
