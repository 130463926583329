import { Stack, Typography } from '@mui/material'

type StatusAlertCardProps = {
  count: number
  label: string
  icon: JSX.Element
}

const StatusAlertCard = ({ count, icon, label }: StatusAlertCardProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: '#bb8033',
        borderBottomRightRadius: '6px',
        borderTopLeftRadius: '6px',
        minHeight: 12,
        p: 1,
        width: '100%',
      }}
    >
      <Stack flexGrow={1} sx={{ ml: 1, mt: 1.5 }}>
        <Typography sx={{ color: '#fff', fontSize: '28px', lineHeight: '34px' }}>
          {count}
        </Typography>
        <Typography sx={{ color: '#fff', fontSize: '14px', lineHeight: '17px' }}>
          {label}
        </Typography>
      </Stack>
      {icon}
    </Stack>
  )
}

export default StatusAlertCard
