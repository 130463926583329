import { atom } from 'recoil'
import { CodedRef } from 'services/openapi'

export enum RunningNoteEntityTypes {
  Encounter = 'Encounters',
}

export interface NoteSchema {
  author: string | undefined
  content: string | undefined
  lastUpdated: string | undefined
  state: CodedRef | undefined
  stateComment?: string
}

export interface RunningNoteSchema {
  id?: string
  encounterId?: string
  encounterServiceId?: string
  checked: boolean
  date: string | undefined
  entity: RunningNoteEntityTypes.Encounter
  notes: NoteSchema[]
  primaryProvider: string
  state: CodedRef | undefined
  name: string | undefined
  synopsis: string | undefined
}

export interface RunningNoteType {
  entity: RunningNoteEntityTypes
  runningNote: RunningNoteSchema
}

export const runningNotesState = atom<RunningNoteType[] | null>({
  default: null,
  key: 'runningNotesState',
})
