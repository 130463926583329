/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressClientDTO } from '../models/AddressClientDTO'
import type { ClientLocationDTO } from '../models/ClientLocationDTO'
import type { PageAddressDTO } from '../models/PageAddressDTO'
import type { PageClientLocationDTO } from '../models/PageClientLocationDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientAddressControllerService {
  /**
   * @param clientId
   * @returns PageClientLocationDTO OK
   * @throws ApiError
   */
  public static getClientAddresses(clientId: string): CancelablePromise<PageClientLocationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/addresses',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientLocationDTO OK
   * @throws ApiError
   */
  public static putClientAddresses(
    clientId: string,
    requestBody: Array<ClientLocationDTO>
  ): CancelablePromise<Array<ClientLocationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/addresses',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param addressId
   * @returns AddressClientDTO OK
   * @throws ApiError
   */
  public static getAddressClients(
    clientId: string,
    addressId: string
  ): CancelablePromise<Array<AddressClientDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/addresses/{addressId}/address-clients',
      path: {
        clientId: clientId,
        addressId: addressId,
      },
    })
  }

  /**
   * @param addressText
   * @returns PageAddressDTO OK
   * @throws ApiError
   */
  public static getAllAddresses(addressText: string): CancelablePromise<PageAddressDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/addresses',
      query: {
        addressText: addressText,
      },
    })
  }

  /**
   * @param clientId
   * @param addressId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteClientAddress(clientId: string, addressId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/addresses/{addressId}',
      path: {
        clientId: clientId,
        addressId: addressId,
      },
    })
  }
}
