import { Component, createRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Fab, Stack, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import * as markerjs2 from 'markerjs2'
import BodyChart from 'assets/images/body-chart.png'
import { ITemplate } from '../../blots/TemplateBlot'

export type BodyTemplateState = {
  dataUrl?: string
  markerState?: markerjs2.MarkerAreaState
}

type BodyTemplateProps = { data?: BodyTemplateState }

export default class BodyTemplate
  extends Component<BodyTemplateProps, BodyTemplateState>
  implements ITemplate
{
  type = 'BodyTemplate'
  state: BodyTemplateState = {}
  imgRef = createRef<HTMLImageElement>()
  markerArea: markerjs2.MarkerArea | null = null

  constructor(props: BodyTemplateProps) {
    super(props)
    this.state = { ...props.data }
    this.imgRef = createRef()
  }

  componentDidMount(): void {
    if (this.imgRef.current) {
      // create a marker.js MarkerArea
      this.markerArea = new markerjs2.MarkerArea(this.imgRef.current)
      this.markerArea.settings.displayMode = 'popup'
      this.markerArea.settings.popupMargin = 100
      this.markerArea.uiStyleSettings.zIndex = '1500'
      // attach an event handler to assign annotated image back to our image element
      this.markerArea.addEventListener('render', (event) => {
        if (this.imgRef.current) {
          const existingMarkerState = this.state.markerState?.markers || []
          const newMarkerState = existingMarkerState.concat(event.state.markers)
          this.setState({
            dataUrl: event.dataUrl,
            markerState: { ...event.state, markers: newMarkerState },
          })
        }
      })
    }
  }

  getData = () => {
    return { dataUrl: this.state.dataUrl, markerState: this.state.markerState }
  }

  render() {
    return (
      <Stack direction="row" spacing={1}>
        <Box sx={{ cursor: 'default', height: 550, width: 612 }}>
          <img
            alt="body-chart"
            crossOrigin="anonymous"
            onClick={() => this.markerArea?.show()}
            ref={this.imgRef}
            src={this.state.dataUrl || BodyChart}
          />
        </Box>
        <Tooltip title="Clear annotation">
          <Fab
            onClick={() => this.setState({ dataUrl: undefined, markerState: undefined })}
            size="small"
          >
            <CloseIcon />
          </Fab>
        </Tooltip>
      </Stack>
    )
  }
}
