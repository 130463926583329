import { FC } from 'react'
import { Button as MUIButton, ButtonProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

interface ButtonCustomProps {
  label?: string
}

const MISButton: FC<ButtonProps & ButtonCustomProps> = (props) => {
  return (
    <ThemeProvider theme={misTheme}>
      <MUIButton {...props}>{props.children || props.label}</MUIButton>
    </ThemeProvider>
  )
}

MISButton.defaultProps = {
  label: 'Button',
  variant: 'contained',
}

export default MISButton
