import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MISConfirmationDialog from 'common/components/MISConfirmationDialog'
import { MODALS } from '../constants'

const WarningDialog = ({
  bodyText,
  entity = '',
  header,
  onCancel,
  onSave,
  open = false,
  primaryButtonText,
  secondaryButtonText,
  type,
}: any) => {
  const { t } = useTranslation('common')

  const getPreferredLabel = useCallback(
    (entity) => {
      let preferredLabel = t('common.preferred')
      if (entity) {
        if (entity === 'Reporting Organization') {
          preferredLabel = t('common.default')
        }
        if (entity === 'Language' || entity === 'Staff') {
          preferredLabel = t('common.primary')
        }
      }
      return preferredLabel
    },
    [t]
  )

  if (!open) return null

  let messages = {
    body: '',
    header: '',
    primaryButton: '',
    secondaryButton: '',
  }

  switch (type) {
    case MODALS.PREFERRED_WARNING:
      messages = {
        body: t('common.warning-messages.preferred-confirmation-dialog.body-text', {
          entity,
          preferred: getPreferredLabel(entity),
        }),
        header: t('common.warning-messages.preferred-confirmation-dialog.header'),
        primaryButton: t('common.warning-messages.preferred-confirmation-dialog.primary-button'),
        secondaryButton: t(
          'common.warning-messages.preferred-confirmation-dialog.secondary-button'
        ),
      }
      break
    case MODALS.PREFERRED_EXPIRED_WARNING:
      messages = {
        body: t('common.warning-messages.preferred-expired-confirmation-dialog.body-text', {
          entity,
          preferred: getPreferredLabel(entity),
        }),
        header: '',
        primaryButton: t(
          'common.warning-messages.preferred-expired-confirmation-dialog.primary-button'
        ),
        secondaryButton: '',
      }
      break
    case MODALS.DELETE_WARNING:
      messages = {
        body: t('common.warning-messages.delete-confirmation-dialog.body-text', { entity }),
        header: t('common.warning-messages.delete-confirmation-dialog.header'),
        primaryButton: t('common.warning-messages.delete-confirmation-dialog.primary-button'),
        secondaryButton: t('common.warning-messages.delete-confirmation-dialog.secondary-button'),
      }
      break
    case MODALS.DELETE_CHARTING_SERVICES:
      messages = {
        body: t('common.warning-messages.delete-charting-service-dialog.body-text', { entity }),
        header: t('common.warning-messages.delete-charting-service-dialog.header'),
        primaryButton: t('common.warning-messages.delete-charting-service-dialog.primary-button'),
        secondaryButton: t(
          'common.warning-messages.delete-charting-service-dialog.secondary-button'
        ),
      }
      break
    case MODALS.UPDATE_WARNING:
      messages = {
        body: t('common.warning-messages.update-confirmation-dialog.body-text', { entity }),
        header: t('common.warning-messages.update-confirmation-dialog.header'),
        primaryButton: t('common.warning-messages.update-confirmation-dialog.primary-button'),
        secondaryButton: t('common.warning-messages.update-confirmation-dialog.secondary-button'),
      }
      break
    case MODALS.UPDATE_ADDRESS:
      messages = {
        body: t('common.warning-messages.update-address-dialog.body-text'),
        header: t('common.warning-messages.update-address-dialog.header'),
        primaryButton: t('common.warning-messages.update-address-dialog.primary-button'),
        secondaryButton: t('common.warning-messages.update-address-dialog.secondary-button'),
      }
      break
    case MODALS.LOGOUT_WARNING:
      messages = {
        body: t('common.warning-messages.logout-dialog.body-text'),
        header: t('common.warning-messages.logout-dialog.header'),
        primaryButton: t('common.warning-messages.logout-dialog.primary-button'),
        secondaryButton: t('common.warning-messages.logout-dialog.secondary-button'),
      }
      break
  }

  return (
    <>
      {open && (
        <MISConfirmationDialog
          bodyText={bodyText ? bodyText : messages.body}
          headerText={header ? header : messages.header}
          onCancel={onCancel}
          onSave={onSave}
          open={open}
          primaryButtonText={primaryButtonText ? primaryButtonText : messages.primaryButton}
          secondaryButtonText={secondaryButtonText ? secondaryButtonText : messages.secondaryButton}
        />
      )}
    </>
  )
}

export default WarningDialog
