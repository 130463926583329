/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientNonClinicalNote } from '../models/ClientNonClinicalNote'
import type { PageClientNonClinicalNote } from '../models/PageClientNonClinicalNote'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientNonClinicalNoteControllerService {
  /**
   * @param clientId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageClientNonClinicalNote OK
   * @throws ApiError
   */
  public static getNonClinicalNotes(
    clientId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageClientNonClinicalNote> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/nonClinicalNotes',
      path: {
        clientId: clientId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns PageClientNonClinicalNote OK
   * @throws ApiError
   */
  public static addNonClinincalNote(
    clientId: string,
    requestBody: Array<ClientNonClinicalNote>
  ): CancelablePromise<PageClientNonClinicalNote> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/nonClinicalNotes',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param noteId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteNonClinincalNote(clientId: string, noteId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/nonClinicalNotes/{noteId}',
      path: {
        clientId: clientId,
        noteId: noteId,
      },
    })
  }
}
