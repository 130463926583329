/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageListEsdrtCategoriesDTO } from '../models/PageListEsdrtCategoriesDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EsdrtControllerService {
  /**
   * @param eSdrtCategoryCode
   * @param effectiveAt
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageListEsdrtCategoriesDTO OK
   * @throws ApiError
   */
  public static listEsdrtCategories(
    eSdrtCategoryCode?: string,
    effectiveAt?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageListEsdrtCategoriesDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/esdrt/categories',
      query: {
        eSDRTCategoryCode: eSdrtCategoryCode,
        effectiveAt: effectiveAt,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
