import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Stack } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISChip from 'common/components/form/MISChip'
import MISDateTimePicker from 'common/components/form/MISDateTimePicker'
import MISDrawer from 'common/components/form/MISDrawer'
import MISDurationField from 'common/components/form/MISDurationField'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import CustomMultiValueAutocomplete from 'modules/client/ClientDetails/ClientPrivacyDirectives/CustomMultiValueAutocomplete'
import { getPersonnelFullName } from 'modules/shared/clientUtils'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { getFormattedOptions } from 'modules/shared/utils'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  FormSchemaControllerService,
  FormSchemaLightDTO,
  PersonnelDTO,
} from 'services/openapi'
import { MIS_ENCOUNTER_SERVICE_STATE } from 'services/terminologyConstants'
import { TEMPLATE_TYPE } from './template-management/TemplateEditor'

type ChartingEntriesFiltersProps = {
  applyFilters: boolean
  filters: FilterProps[]
  providers: PersonnelDTO[]
  setApplyFilters: Dispatch<SetStateAction<boolean>>
  setFilters: Dispatch<SetStateAction<FilterProps[]>>
}

type DisplayFiltersProps = {
  label?: string
  filterIndex: number
  childFilterIndex?: number
  key?: string
}

type TemplateOptions = {
  label: string
  value: any
}

const ChartingEntriesFilters = ({
  applyFilters,
  filters = [],
  providers,
  setApplyFilters,
  setFilters,
}: ChartingEntriesFiltersProps) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
  const [displayFilters, setDisplayFilters] = useState<DisplayFiltersProps[]>([])
  const hideTimerRef = useRef<NodeJS.Timeout | null>(null)
  const [templates, setTemplates] = useState<TemplateOptions[]>([])

  const updateFilter = useCallback(
    (key: string, value: any) => {
      setApplyFilters(false)
      const filterObj = {
        key: key,
        value: value.value ? value.value : value,
      }
      const foundIndex = filters?.findIndex((filter) => filter.key === key)
      if (foundIndex !== -1) {
        filters[foundIndex] = filterObj
      } else {
        filters.push(filterObj)
      }
      setFilters([...filters])
    },
    [setApplyFilters, filters, setFilters]
  )

  const getAllTemplateListUtil = useCallback(
    async (templates: FormSchemaLightDTO[], searchText: string) => {
      const { getTemplateDisplayName } = await import('./historical/utils')
      const { TEMPLATES } = await import('./modules/TemplatesToolbar/Templates')

      const formatOption = (option) => ({
        label: getTemplateDisplayName(option?.name) || option?.name,
        value: option,
      })

      const globals = Object.values(TEMPLATES)
        .filter((template) =>
          template.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        )
        .map(formatOption)
      const result = [
        ...globals,
        ...templates
          .filter((x) => x.templateType !== TEMPLATE_TYPE.AGGREGATE_TEMPLATE)
          .map((template: FormSchemaLightDTO) => ({
            label: template.name,
            value: template,
          })),
      ]
      setTemplates(result)
    },
    []
  )

  const handleTemplateSearch = useCallback(
    async (searchText) => {
      if (hideTimerRef.current !== null) {
        clearTimeout(hideTimerRef.current)
        hideTimerRef.current = null
      }
      hideTimerRef.current = setTimeout(async () => {
        if (searchText.length >= 3) {
          try {
            const res = await FormSchemaControllerService.searchFormSchema(
              searchText,
              undefined,
              true,
              undefined,
              false,
              true,
              ['PUBLISHED'],
              ['PUBLIC'],
              'OR',
              undefined
            )
            if (res?.content) {
              getAllTemplateListUtil(res.content, searchText)
            }
          } catch (error) {
            console.error('Error searching templates:', error)
          }
        }
      }, 1500)
    },
    [getAllTemplateListUtil]
  )

  const renderFormComponent = () => {
    return (
      <Stack
        spacing={2}
        sx={{
          '& .MuiFormControl-root': {
            width: '100%',
          },
          m: 4,
          width: 400,
        }}
      >
        <MISSelectMultiDropdown
          label={t('charting.filter.status')}
          onChange={(event) => updateFilter('status', event.target.value || null)}
          options={getFormattedOptions(stateOptions)}
          placeholder={t('charting.filter.all-status')}
          value={filters.find(({ key }) => key === 'status')?.value || []}
        />
        <MISDurationField
          label={t('charting.filter.duration-from')}
          onChange={(value) => updateFilter('durationFrom', (value as number) || null)}
          value={filters.find(({ key }) => key === 'durationFrom')?.value || ''}
        />
        <MISDurationField
          label={t('charting.filter.duration-to')}
          onChange={(value) => updateFilter('durationTo', (value as number) || null)}
          value={filters.find(({ key }) => key === 'durationTo')?.value || ''}
        />
        <MISSelectMultiDropdown
          label={t('charting.filter.primary-provider')}
          onChange={(event) => updateFilter('primaryProvider', event.target.value || null)}
          options={providers.map((provider: PersonnelDTO) => {
            return {
              label: evaluateLabelUtil(provider.names),
              value: provider,
            }
          })}
          placeholder={t('charting.filter.all-primary-provider')}
          value={filters.find(({ key }) => key === 'primaryProvider')?.value || []}
        />
        <CustomMultiValueAutocomplete
          label={t('charting.filter.templateName')}
          onChange={(event: SyntheticEvent, newValue: string[]) => {
            updateFilter('templateName', newValue)
          }}
          onInputChange={(newValue: string) => {
            handleTemplateSearch(newValue)
          }}
          options={templates || []}
          value={filters.find(({ key }) => key === 'templateName')?.value || []}
        />
        <MISTextField
          label={t('charting.filter.search-text')}
          onChange={(event) => updateFilter('searchText', event.target.value || null)}
          placeholder={t('charting.filter.all-search-text')}
          value={filters.find((filter) => filter?.key === 'searchText')?.value}
        />
        <MISDateTimePicker
          label={t('charting.filter.all-service-date-from')}
          onChange={(value: unknown) =>
            updateFilter('serviceDateTimeFrom', value ? (value as string) : undefined)
          }
          value={filters.find(({ key }) => key === 'serviceDateTimeFrom')?.value || null}
        />
        <MISDateTimePicker
          label={t('charting.filter.all-service-date-to')}
          onChange={(value: unknown) =>
            updateFilter('serviceDateTimeTo', value ? (value as string) : undefined)
          }
          value={filters.find(({ key }) => key === 'serviceDateTimeTo')?.value || null}
        />
        <MISButton
          color="primary"
          onClick={handleSearch}
          size="large"
          sx={{
            alignItems: 'center',
            bottom: 16,
            position: 'absolute',
            width: '90%',
          }}
        >
          {t('charting.filter.apply')}
        </MISButton>
      </Stack>
    )
  }

  const handleSearch = useCallback(() => {
    setApplyFilters(true)
    if (isOpen) {
      setIsOpen(false)
    }
  }, [isOpen, setApplyFilters])

  useEffect(() => {
    const displayFilters: DisplayFiltersProps[] = []
    filters.forEach(({ key, value }, index) => {
      switch (key) {
        case 'searchText':
        case 'durationFrom':
        case 'durationTo':
          if (value !== null) {
            displayFilters.push({
              filterIndex: index,
              label: value,
            })
          }
          break
        case 'primaryProvider':
          value.forEach((source: PersonnelDTO, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: getPersonnelFullName(source),
            })
          })
          break
        case 'templateName':
          value.forEach((source, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: source.value ? source.value.name : source.name,
            })
          })
          break
        case 'status':
          value.forEach((status: CodedConceptDto, idx: number) => {
            displayFilters.push({
              childFilterIndex: idx,
              filterIndex: index,
              label: status.name,
            })
          })
          break
        case 'serviceDateTimeFrom':
        case 'serviceDateTimeTo':
          if (value !== undefined) {
            displayFilters.push({
              filterIndex: index,
              label: isoDateToDisplayFormat(value),
            })
          }
          break
      }
    })
    setDisplayFilters([...displayFilters])
  }, [filters])

  return (
    <>
      <Box
        sx={{
          '& .MuiButton-root': {
            ml: 3,
          },
        }}
      >
        <MISButton
          onClick={() => setIsOpen(true)}
          size="large"
          startIcon={<TuneIcon />}
          sx={{ color: 'black', px: 0.5 }}
          variant="text"
        >
          {t('charting.filter.filter')}
        </MISButton>
        {applyFilters &&
          displayFilters.map(
            ({ childFilterIndex, filterIndex, key, label }: DisplayFiltersProps) => {
              return (
                <MISChip
                  key={key}
                  label={label}
                  onDelete={() => {
                    if (childFilterIndex !== undefined) {
                      filters[filterIndex]?.value.splice(childFilterIndex, 1)
                    } else {
                      filters.splice(filterIndex, 1)
                    }
                    setFilters([...filters])
                    setApplyFilters(true)
                  }}
                  sx={{ mx: 0.5 }}
                  variant="outlined"
                />
              )
            }
          )}
      </Box>

      <MISDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderFormComponent()}
      </MISDrawer>
    </>
  )
}

export default ChartingEntriesFilters
