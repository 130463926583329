import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import MISButton from 'common/components/MISButton'
import global from 'common/styles/global.scss'

export type MISMarkdownRendererProps = {
  content?: string
  filePath?: string
}

const MISMarkdownRenderer = ({ content, filePath }: MISMarkdownRendererProps) => {
  const { t } = useTranslation('common')

  const [isOpen, setIsOpen] = useState(false)
  const [markdownContent, setMarkdownContent] = useState('')

  const handleClose = useCallback((_: SyntheticEvent, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') setIsOpen(false)
  }, [])

  useEffect(() => {
    const fetchContent = async () => {
      if (content) {
        setMarkdownContent(content)
      } else if (filePath) {
        const markdownFile = await import(`../../../translations/en/markdown/${filePath}`)
        if (markdownFile) {
          fetch(markdownFile.default)
            .then((response) => response.text())
            .then((data) => setMarkdownContent(data))
            .catch((error) => console.error('Error fetching MDX file:', error))
        }
      }
    }

    fetchContent()
  }, [filePath, content])

  return (
    <>
      <InfoOutlinedIcon
        onClick={() => setIsOpen(true)}
        sx={{ color: `${global.BUTTON_PRIMARY_BG_COLOR}`, cursor: 'pointer', mr: 1 }}
      />
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle sx={{ textAlign: 'right' }}>
          <HighlightOffIcon onClick={() => setIsOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ marginTop: `-${global.MARGIN_SM}`, whiteSpace: 'pre-line' }}
            variant="body2"
          >
            <div className="markdown-renderer">
              <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <MISButton color="primary" onClick={() => setIsOpen(false)}>
            {t('common.button.close')}
          </MISButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MISMarkdownRenderer
