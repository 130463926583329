import { forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TreeItem, TreeItemContentProps, TreeItemProps, TreeView, useTreeItem } from '@mui/lab'
import { Box, Popover, Typography } from '@mui/material'
import clsx from 'clsx'
import { CodedConceptDto } from 'services/openapi'
import MISTextField from './MISTextField'

export type SelectTreeViewDataType = {
  id: string
  code: string
  name: string
  parentId?: string
  children?: SelectTreeViewDataType[]
}

export const getExpandedIds = (data: SelectTreeViewDataType[], result: string[] = []): string[] => {
  data?.forEach((o) => {
    result.push(o.id)
    if (o.children?.length) getExpandedIds(o.children, result)
  })
  return result
}

export const getFilteredData = (
  data: SelectTreeViewDataType[] | undefined,
  leaf: string
): SelectTreeViewDataType[] => {
  const result: SelectTreeViewDataType[] = []
  data?.forEach((o) => {
    if (o.name.toLocaleLowerCase().indexOf(leaf.toLocaleLowerCase()) > -1) {
      result.push(o)
      return
    }
    const children = getFilteredData(o?.children, leaf)
    if (children.length) {
      result.push(Object.assign({}, o, { children }))
    }
  })
  return result
}

const CustomContent = forwardRef((props: TreeItemContentProps, ref) => {
  const { classes, className, displayIcon, expansionIcon, icon: iconProp, label, nodeId } = props

  const {
    disabled,
    expanded,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
    selected,
  } = useTreeItem(nodeId)

  const icon = useMemo(
    () => iconProp || expansionIcon || displayIcon,
    [displayIcon, expansionIcon, iconProp]
  )

  const handleMouseDown = useCallback((event) => preventSelection(event), [preventSelection])

  const handleExpansionClick = useCallback((event) => handleExpansion(event), [handleExpansion])

  const handleSelectionClick = useCallback((event) => handleSelection(event), [handleSelection])

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as Ref<HTMLDivElement>}
      style={{ padding: '4px' }}
    >
      <div className={classes.iconContainer} onClick={handleExpansionClick}>
        {icon}
      </div>
      <Typography
        className={classes.label}
        component="div"
        onClick={handleSelectionClick}
        sx={{ fontSize: '14px !important' }}
      >
        {label}
      </Typography>
    </div>
  )
})
CustomContent.displayName = 'CustomContent'

const CustomTreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
  <TreeItem ContentComponent={CustomContent} ref={ref} {...props} />
))
CustomTreeItem.displayName = 'CustomTreeItem'

type MISSelectTreeViewProps = {
  data: SelectTreeViewDataType[]
  label: string
  onSelect: (value: string) => void
  error?: boolean
  helperText?: string
  id?: string
  placeholder?: string
  value?: CodedConceptDto
}

const MISSelectTreeView = (props: MISSelectTreeViewProps) => {
  const { data, error, helperText, id, label, onSelect, placeholder, value } = props

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [currentId, setCurrentId] = useState(value?.id || '')
  const [currentValue, setCurrentValue] = useState(value?.name || '')
  const [expandIds, setExpandIds] = useState<string[]>([])
  const [filteredData, setFilteredData] = useState<SelectTreeViewDataType[]>(data)
  const [selectedValue, setSelectedValue] = useState(value?.name || '')
  const ref = useRef<HTMLDivElement>(null)

  const handleChange = useCallback(
    (event) => {
      setCurrentValue(event.target.value)
      if (currentId) setCurrentId('')
      if (event.target.value && event.target.value !== value) {
        setFilteredData(getFilteredData(data, event.target.value))
        if (!anchorEl) setAnchorEl(event.currentTarget)
      } else {
        setFilteredData(data)
      }
    },
    [anchorEl, currentId, data, value]
  )

  const handleClick = useCallback(
    (event) => {
      if (currentValue) setFilteredData(getFilteredData(data, currentValue))
      else setFilteredData(data)
      setAnchorEl(event.currentTarget)
    },
    [currentValue, data]
  )

  const handleClose = useCallback(() => {
    if (!currentId) {
      if (!currentValue) setFilteredData(data)
      setCurrentValue(selectedValue)
      onSelect(selectedValue)
    }
    setAnchorEl(null)
  }, [currentId, currentValue, data, onSelect, selectedValue])

  const handleSelect = useCallback(
    (e, id) => {
      setCurrentId(id)
      setCurrentValue(e.target.innerText)
      setSelectedValue(e.target.innerText)
      onSelect(e.target.innerText)
      setAnchorEl(null)
    },
    [onSelect]
  )

  const handleToggle = useCallback((_, nodeIds: string[]) => setExpandIds(nodeIds), [])

  const renderTree = useCallback(
    (nodes: SelectTreeViewDataType) => (
      <CustomTreeItem key={nodes.id} label={nodes.name} nodeId={nodes.id}>
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </CustomTreeItem>
    ),
    []
  )

  const open = useMemo(() => Boolean(anchorEl), [anchorEl])
  const popoverId = useMemo(() => (open ? 'simple-popover' : undefined), [open])

  useEffect(() => setExpandIds(getExpandedIds(filteredData)), [filteredData])
  useEffect(() => {
    setCurrentId(value?.id || '')
    setCurrentValue(value?.name || '')
    setSelectedValue(value?.name || '')
  }, [value])

  return (
    <Box ref={ref}>
      <MISTextField
        error={error}
        helperText={helperText}
        id={id}
        label={label}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        required
        value={currentValue}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        disableAutoFocus
        disableEnforceFocus
        id={popoverId}
        onClose={handleClose}
        open={open}
      >
        <TreeView
          aria-label="icon expansion"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={expandIds}
          defaultSelected={currentId}
          expanded={expandIds}
          onNodeSelect={handleSelect}
          onNodeToggle={handleToggle}
          selected={currentId}
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            paddingBottom: '10px',
            width: (ref.current as HTMLDivElement)?.clientWidth,
          }}
        >
          {filteredData.map((item) => renderTree(item))}
        </TreeView>
      </Popover>
    </Box>
  )
}

export default MISSelectTreeView
