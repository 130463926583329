/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportingOrganizationDTO } from '../models/ReportingOrganizationDTO'
import type { UnPagedReportingOrganizationDTO } from '../models/UnPagedReportingOrganizationDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ReportingOrganizationControllerService {
  /**
   * @param clientId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns UnPagedReportingOrganizationDTO OK
   * @throws ApiError
   */
  public static getReportingOrganizations(
    clientId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<UnPagedReportingOrganizationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/reporting-organizations',
      path: {
        clientId: clientId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns UnPagedReportingOrganizationDTO OK
   * @throws ApiError
   */
  public static updateReportingOrganizations(
    clientId: string,
    requestBody: Array<ReportingOrganizationDTO>
  ): CancelablePromise<UnPagedReportingOrganizationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/reporting-organizations',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
