import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSetRecoilState } from 'recoil'
import MISTabbedContentContainer from 'common/components/contentpane/MISTabbedContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { immunizationToAvoidState } from 'recoil/immunzations'
import { ImmunizationToAvoidControllerService } from 'services/openapi'
import ImmunizationsConsentContentPanel from './ImmunizationConsent/ImmunizationsConsentContentPanel'
import ImmunizationsToAvoid from './ImmunizationToAvoid/ImmunzationToAvoid'
import ClientRecordHeader from '../ClientDetails/ClientRecordHeader'

const ClientImmunizations = () => {
  const { clientId } = useParams()
  const { t } = useTranslation('common')
  const setImmunizationsToAvoid = useSetRecoilState(immunizationToAvoidState)
  const { handleApiError } = useErrorHandler()

  useEffect(() => {
    const getAvoids = async (id: string) => {
      try {
        const response = await ImmunizationToAvoidControllerService.searchAllImmunizationToAvoid(id)
        if (response?.content) {
          setImmunizationsToAvoid(response.content)
        }
      } catch (err) {
        handleApiError(err)
      }
    }
    if (clientId) getAvoids(clientId)
  }, [clientId, handleApiError, setImmunizationsToAvoid])

  const tabConfig = useCallback(
    () => [
      {
        active: false,
        component: <p>{t('navigation.immunizations-menu.dashboard')}</p>,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.dashboard',
        label: t('navigation.immunizations-menu.dashboard'),
        visibile: true,
      },
      {
        active: false,
        component: <p>{t('navigation.immunizations-menu.history')}</p>,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.history',
        label: t('navigation.immunizations-menu.history'),
        visibile: true,
      },
      {
        active: false,
        component: <p>{t('navigation.immunizations-menu.schedules')}</p>,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.schedules',
        label: t('navigation.immunizations-menu.schedules'),
        visibile: true,
      },
      {
        active: false,
        component: <ImmunizationsToAvoid />,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.immunizations-to-avoid',
        label: t('navigation.immunizations-menu.immunizations-to-avoid'),
        visibile: true,
      },
      {
        active: false,
        component: <ImmunizationsConsentContentPanel />,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.immunization-consents',
        label: t('navigation.immunizations-menu.immunization-consents'),
        visibile: true,
      },
      {
        active: false,
        component: <p>{t('navigation.immunizations-menu.family-reminders')}</p>,
        dirty: false,
        disabled: false,
        id: 'navigation.immunizations-menu.family-reminders',
        label: t('navigation.immunizations-menu.family-reminders'),
        visibile: true,
      },
    ],
    [t]
  )

  return (
    <MISBaseContainer>
      <ClientRecordHeader />
      <MISTabbedContentContainer tabConfig={tabConfig()} />
    </MISBaseContainer>
  )
}

export default ClientImmunizations
