import { Box, SvgIcon } from '@mui/material'

const MISMedPrioIcon = () => {
  return (
    <Box sx={{ paddingLeft: '8px' }}>
      <SvgIcon viewBox="0 -2 18 16">
        <svg height="16" version="1.1" width="16">
          <g id="icon_x2F_16px_x2F_medium-priority-">
            <g>
              <path
                d="M3,4h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3C2.4,6,2,5.6,2,5S2.4,4,3,4z M3,10h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3
              c-0.6,0-1-0.4-1-1S2.4,10,3,10z"
                fill="#FFAB00"
                fillRule="nonzero"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      </SvgIcon>
    </Box>
  )
}

export default MISMedPrioIcon
