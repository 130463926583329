import { TextField, TextFieldProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

const MISTextField = (props: TextFieldProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <TextField {...props} color={props.color || 'primary'} size={props.size || 'small'} />
    </ThemeProvider>
  )
}

export default MISTextField
