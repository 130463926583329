import { atom } from 'recoil'
import { ClientDTO } from 'services/openapi'

export type PrivacyOverrideDetails = {
  checkFlag: boolean
  error?: Error
}

export const userIdState = atom<string>({
  default: '',
  key: 'userIdState',
})

export const userRecentClientsPreferenceIdState = atom<string | undefined>({
  default: undefined,
  key: 'userRecentClientsPreferenceIdState',
})

export const userPinnedClientsPreferenceIdState = atom<string | undefined>({
  default: undefined,
  key: 'userPinnedClientsPreferenceIdState',
})

export const pinnedClientsState = atom<ClientDTO[] | null>({
  default: null,
  key: 'pinnedClientsState',
})

export const recentClientsState = atom<ClientDTO[] | null>({
  default: null,
  key: 'recentClientsState',
})

export const checkPrivacyClientProfileState = atom<PrivacyOverrideDetails | undefined>({
  default: undefined,
  key: 'checkPrivacyClientProfileState',
})
