import { SyntheticEvent, useEffect, useState } from 'react'
import { Autocomplete, FormControl, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'
import misTheme from 'core/styles/theme'
import MISChip from './MISChip'
import MISTextField from './MISTextField'

type MultiValueAutocompleteCustomProps = {
  label: string | React.ReactNode
  required?: boolean
  maxLength?: number
  error?: boolean
  helperText?: string
  defaultValue?: string[]
  options: string[]
  value: string[]
  onChange: (event: SyntheticEvent, newValue: string[]) => void
  allowFreeText?: boolean
}

const MISMultiValueAutocomplete = ({
  allowFreeText = true,
  defaultValue = [],
  error,
  helperText,
  label,
  onChange,
  options,
  required = false,
  value = [],
}: MultiValueAutocompleteCustomProps) => {
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<string[]>([])

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
    )
  }, [inputValue, options])

  const renderOption = (props, option) => {
    const matches = option.toLowerCase().indexOf(inputValue.toLowerCase())
    const beforeMatch = option.substring(0, matches)
    const matchText = option.substring(matches, matches + inputValue.length)
    const afterMatch = option.substring(matches + inputValue.length)

    return (
      <li {...props} key={uuidv4()}>
        <Typography sx={{ padding: '7px' }}>
          {beforeMatch}
          <strong>{matchText}</strong>
          {afterMatch}
        </Typography>
      </li>
    )
  }

  return (
    <ThemeProvider theme={misTheme}>
      <FormControl size="small">
        <Autocomplete
          defaultValue={defaultValue}
          filterSelectedOptions
          freeSolo={allowFreeText}
          id="tags-filled"
          multiple
          onChange={(event, newValue) => {
            onChange(event, newValue)
          }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          options={filteredOptions}
          renderInput={(params) => (
            <MISTextField
              {...params}
              error={error}
              helperText={helperText}
              label={label}
              required={required}
            />
          )}
          renderOption={renderOption}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <MISChip {...getTagProps({ index })} key={index} label={option} variant="outlined" />
            ))
          }
          value={value}
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default MISMultiValueAutocomplete
