import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRecoilState } from 'recoil'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  HEALTH_CONCERN_CODE_SYSTEM_OID,
  healthConcernsClientIdState,
  healthConcernsState,
  HealthConcernsType,
  healthConcernTerminology,
} from 'recoil/healthConcerns'
import {
  CancelablePromise,
  CodedConceptControllerService,
  CodedConceptDto,
  HealthConcernControllerService,
  UnPagedCodedConceptDto,
  UnPagedHealthConcernStateDTO,
} from 'services/openapi'
import { selectClientDetails } from 'store/selectors/client'

const useHealthConcerns = (clientId: string | undefined) => {
  const { handleApiError } = useErrorHandler()
  const clientDetails = useSelector(selectClientDetails)
  const [healthConcerns, setHealthConcerns] = useRecoilState(healthConcernsState)
  const [healthConcernsClientId, setHealthConcernClientIdState] = useRecoilState(
    healthConcernsClientIdState
  )
  const [healthConcernTerms, setHealthConcernTerms] = useRecoilState(healthConcernTerminology)

  useEffect(() => {
    const getHealthConcerns = async (clientId: string) => {
      try {
        const healthConcerns: HealthConcernsType = {}
        const healthConcernTermsNew: CodedConceptDto[] = []
        const resp = await HealthConcernControllerService.getHealthConcerns(clientId)
        const promisesTerms: CancelablePromise<UnPagedCodedConceptDto>[] = []
        const promisesState: CancelablePromise<UnPagedHealthConcernStateDTO>[] = []
        if (resp.content) {
          resp.content.forEach((healthConcern) => {
            if (healthConcern.id) {
              if (
                healthConcernTerms.find(
                  (term) => term.code === healthConcern.healthConcern?.code
                ) === undefined
              )
                promisesTerms.push(
                  CodedConceptControllerService.getFilteredConceptCodes(
                    HEALTH_CONCERN_CODE_SYSTEM_OID,
                    healthConcern.healthConcern?.code as string
                  )
                )
              promisesState.push(
                HealthConcernControllerService.getStateHistory(clientId, healthConcern.id)
              )
            }
          })
          const respTerms: UnPagedCodedConceptDto[] = await Promise.all(promisesTerms)
          respTerms.forEach((resp) => {
            if (
              resp.content &&
              resp.content.length > 0 &&
              healthConcernTermsNew.find((term) => term.code === resp.content?.[0].code) ===
                undefined
            )
              healthConcernTermsNew.push(resp.content?.[0])
          })
          const respStates: UnPagedHealthConcernStateDTO[] = await Promise.all(promisesState)
          resp.content.forEach((healthConcern, index) => {
            if (healthConcern.id)
              healthConcerns[healthConcern.id] = {
                healthConcern,
                states: respStates[index].content || [],
              }
          })
        }
        setHealthConcernTerms([...healthConcernTerms, ...healthConcernTermsNew])
        setHealthConcerns(healthConcerns)
      } catch (error) {
        handleApiError(error)
      }
    }

    if (clientId && clientId !== healthConcernsClientId) {
      setHealthConcerns(null)
      setHealthConcernClientIdState(clientId)
      getHealthConcerns(clientId)
    }
  }, [
    clientDetails?.id,
    clientId,
    handleApiError,
    healthConcernTerms,
    healthConcerns,
    healthConcernsClientId,
    setHealthConcernClientIdState,
    setHealthConcernTerms,
    setHealthConcerns,
  ])

  return { healthConcerns, setHealthConcerns }
}

export default useHealthConcerns
