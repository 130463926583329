import { Box, Skeleton } from '@mui/material'

const InfoCardLoader = () => {
  return (
    <Box sx={{ height: '72px', mt: 1, width: '100%' }}>
      {[1, 2, 3].map((_, index) => (
        <Skeleton animation="wave" height="20px" key={index} />
      ))}
    </Box>
  )
}
export default InfoCardLoader
