import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISPinRemoveIcon from 'common/components/icons/MISPinRemoveIcon'
import Loader from 'common/components/Loader'
import MISButton from 'common/components/MISButton'
import MISLink from 'common/components/MISLink'
import MISTabs from 'common/components/tabs/MISTabs'
import MISTabTitle from 'common/components/tabs/MISTabTitle'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getCurrentTenant } from 'core/helpers/security/currentTenant'
import { getClientAgeString, getClientFullName } from 'modules/shared/clientUtils'
import {
  pinnedClientsState,
  recentClientsState,
  userIdState,
  userPinnedClientsPreferenceIdState,
} from 'recoil/recentClients'
import { Preference, UserPreferenceControllerService } from 'services/openapi'

import './PinnedMenuItem.scss'

type PinnedMenuItemProps = {
  onClose: () => void
}

const PinnedMenuItem = ({ onClose }: PinnedMenuItemProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const navigate = useNavigate()
  const [pinnedClients, setPinnedClients] = useRecoilState(pinnedClientsState)
  const userId = useRecoilValue(userIdState)
  const userPinnedClientsPreferenceId = useRecoilValue(userPinnedClientsPreferenceIdState)
  const recentClients = useRecoilValue(recentClientsState)

  const handleItemClick = useCallback(
    (event, clientId: string) => {
      event.preventDefault()
      navigate(`/clients/client-record/${clientId}`)
      onClose()
    },
    [navigate, onClose]
  )

  const handleRemovePinnedClient = useCallback(
    async (clientId: string, preferenceId: string) => {
      const nextPinnedClients = pinnedClients?.filter((item) => item.id !== clientId) || []
      setPinnedClients(nextPinnedClients)
      try {
        if (userId) {
          const preference: Preference = {
            id: preferenceId,
            name: 'pinned-clients',
            type: Preference.type.USER,
            value:
              nextPinnedClients.length > 0
                ? nextPinnedClients.map((client) => client.id).join('|')
                : '',
          }
          await UserPreferenceControllerService.updateUserPreference(
            userId,
            preferenceId,
            preference
          )
        }
      } catch (err) {
        handleApiError(err)
      }
    },
    [handleApiError, pinnedClients, setPinnedClients, userId]
  )

  const tabs = useMemo(
    () => [
      {
        content: (
          <Box sx={{ pt: 0.5 }}>
            {pinnedClients ? (
              <>
                {pinnedClients.map((client) => (
                  <Box key={client.id}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span className="client-text">
                        <MISLink
                          color="primary"
                          href={`/${getCurrentTenant().tenantId}/clients/client-record/${
                            client.id as string
                          }`}
                          key={client.id}
                          onClick={(event) => handleItemClick(event, client.id as string)}
                          underline="hover"
                          variant="inherit"
                        >
                          {getClientFullName(client)}
                          {client.birthdate && `, ${getClientAgeString(client.birthdate)}`}
                        </MISLink>
                      </span>
                      <Box
                        className="icon"
                        onClick={() =>
                          handleRemovePinnedClient(
                            client.id as string,
                            userPinnedClientsPreferenceId as string
                          )
                        }
                      >
                        <MISPinRemoveIcon />
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                ))}
              </>
            ) : (
              <Box sx={{ pt: 2 }}>
                <Loader />
              </Box>
            )}
          </Box>
        ),
        id: 'pinned-clients',
        title: <MISTabTitle title={t('navigation.top-nav-menu.pinned.pinned-clients')} />,
      },
      {
        content: (
          <Box sx={{ pt: 0.5 }}>
            {recentClients ? (
              <>
                {recentClients.map((client) => (
                  <Box key={client.id}>
                    <span className="client-text">
                      <MISLink
                        color="primary"
                        href={`/${getCurrentTenant().tenantId}/clients/client-record/${
                          client.id as string
                        }`}
                        key={client.id}
                        onClick={(event) => handleItemClick(event, client.id as string)}
                        underline="hover"
                        variant="inherit"
                      >
                        {getClientFullName(client)}
                      </MISLink>
                    </span>
                    <Divider />
                  </Box>
                ))}
              </>
            ) : (
              <Box sx={{ pt: 2 }}>
                <Loader />
              </Box>
            )}
          </Box>
        ),
        id: 'recent-clients',
        title: <MISTabTitle title={t('navigation.top-nav-menu.pinned.recent-clients')} />,
      },
    ],
    [
      handleItemClick,
      handleRemovePinnedClient,
      pinnedClients,
      recentClients,
      t,
      userPinnedClientsPreferenceId,
    ]
  )

  return (
    <Box sx={{ maxHeight: 540, minWidth: 360, mt: -2, pb: 2, pl: 2, pr: 6 }}>
      <MISTabs mountAllTabs tabs={tabs} />
      <Box sx={{ textAlign: 'end' }}>
        <MISButton onClick={onClose} sx={{ mr: -2, mt: 1 }}>
          {t('common.button.close')}
        </MISButton>
      </Box>
    </Box>
  )
}

export default PinnedMenuItem
