import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilState } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { multiCriteriaSort } from 'common/utils/utils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import StaffAssociation from 'modules/shared/StaffAssociation/StaffAssociation'
import { ErrorType } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import { PersonnelDTO, TeamControllerService, TeamDTO, TeamMembershipDTO } from 'services/openapi'
import './Teams.scss'

type TeamsDataRowType = { data: TeamDTO; isCollapsed?: boolean }
type TeamsDataType = { rows: TeamsDataRowType[] }

const ENTITY = 'Team'

const Teams = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [teams, setTeams] = useState<TeamDTO[]>([])
  const [teamsData, setTeamsData] = useState<TeamsDataType>({ rows: [] })
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [deleteIndex, setDeleteIndex] = useState(-1)

  useEffect(() => {
    const getTeams = async () => {
      try {
        const response = await TeamControllerService.getTeams()
        if (response?.content) {
          setTeams(response.content)
        }
      } catch (err) {
        handleApiError(err)
      }
    }
    getTeams()
  }, [handleApiError])

  const sortCriteria = useMemo(
    () => [
      {
        key: 'data.teamName',
        order: 'asc' as const,
        type: 'string' as const,
      },
    ],
    []
  )

  const sortTeamsData = useCallback(
    (arrData: TeamsDataRowType[]) => {
      // Last parameter is keypath of field that moves deactivated at the bottom
      return multiCriteriaSort(arrData, sortCriteria, 'data.effective.endDate')
    },
    [sortCriteria]
  )

  const handleCreateTeamsData = useCallback(() => {
    const parsedData = teams
      ? [...teams].map((obj: TeamDTO) => {
          return {
            data: {
              ...obj,
              teamMembers: obj?.teamMembers?.map((member) => {
                return {
                  ...member,
                  providerStaffId: member?.providerStaff?.id,
                }
              }),
            },
            isCollapsed: true,
          }
        })
      : []

    setTeamsData({
      rows: sortTeamsData(parsedData),
    })
  }, [teams, setTeamsData, sortTeamsData])

  useEffect(() => {
    handleCreateTeamsData()
  }, [teams, handleCreateTeamsData])

  const getError = useCallback(
    (field: string) => {
      const errorObj = errors.find((error) => error.field === field)
      return errorObj ? errorObj.message : ''
    },
    [errors]
  )

  const onAddRow = useCallback(
    () =>
      setTeamsData({
        rows: [
          ...teamsData.rows,
          {
            data: {
              effective: undefined,
              id: undefined,
              teamDescription: undefined,
              teamMembers: [],
              teamName: undefined,
            },
            isCollapsed: false,
          },
        ],
      }),
    [teamsData]
  )

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, value: string | any) => {
      if (!isDirty) setIsDirty(true)

      const rows = teamsData.rows?.map((row: TeamsDataRowType, index: number) => {
        let data: TeamDTO
        if (index === rowIndex) {
          switch (field) {
            case 'teamName':
              data = { ...row.data, teamName: value as string }
              break
            case 'teamDescription':
              data = { ...row.data, teamDescription: value as string }
              break
            case 'startDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, startDate: value as string },
              }
              break
            case 'endDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, endDate: value as string },
              }
              break
            case 'teamMembers':
              data = {
                ...row.data,
                teamMembers: value.map((member: TeamMembershipDTO) => {
                  return {
                    ...member,
                    providerStaffId: member?.providerStaff?.id,
                  }
                }) as TeamMembershipDTO[],
              }
              break
            default:
              return row
          }
          return { ...row, data }
        }
        return row
      })
      setTeamsData({ rows } as TeamsDataType)
    },
    [teamsData, isDirty, setIsDirty]
  )

  const onCollapseRow = useCallback(
    (rowIndex: number) => {
      teamsData.rows[rowIndex].isCollapsed = !teamsData.rows[rowIndex].isCollapsed
      setTeamsData({ ...teamsData })
    },
    [teamsData]
  )

  const sustainCollapsedState = useCallback(
    (teams: TeamDTO[]) => {
      return teamsData.rows.map((team: TeamsDataRowType, index: number) => {
        return {
          data: teams[index],
          isCollapsed: team.isCollapsed,
        }
      })
    },
    [teamsData.rows]
  )

  const onSave = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      teamsData.rows?.forEach((row: TeamsDataRowType, i: number) => {
        if (!row.data.teamName) {
          isValid = false
          errors.push({
            field: `team-name-${i}`,
            message: t('teams.validation.team-name-required'),
          })
        }
        if (!row.data.effective?.startDate) {
          isValid = false
          errors.push({
            field: `team-startDate-${i}`,
            message: t('teams.validation.start-date-required'),
          })
        }
      })
      return { errors, isValid }
    }
    const saveTeams = async (updatedTeams: TeamDTO[]) => {
      await TeamControllerService.saveTeams(updatedTeams)
        .then((response) => {
          setTeamsData({ rows: sortTeamsData(sustainCollapsedState(response)) })
          showSnackSuccess(t('api.save-success'))
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
    const { errors, isValid } = validate()
    if (isValid) {
      const updatedTeams = teamsData.rows.map((row: TeamsDataRowType) => {
        return {
          ...row.data,
          teamMembers: row.data.teamMembers?.map((member) => {
            return {
              ...member,
              providerStaffId: member.providerStaff?.id,
            }
          }),
        }
      })
      saveTeams(updatedTeams)
      setErrors([])
    } else {
      setErrors(errors)
    }
  }, [
    handleApiError,
    teamsData.rows,
    sustainCollapsedState,
    setTeamsData,
    sortTeamsData,
    showSnackSuccess,
    setIsDirty,
    t,
  ])

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      const deleteTeam = async (teamId: string) => {
        await TeamControllerService.deleteTeam(teamId)
          .then(() => {
            teamsData.rows.splice(rowIndex, 1)
            setTeamsData({ rows: teamsData.rows })
            showSnackSuccess(t('api.save-success'))
          })
          .catch((error) => {
            handleApiError(error)
          })
      }

      setDeleteIndex(-1)
      const teamId = teamsData.rows[rowIndex].data?.id
      if (teamId) {
        deleteTeam(teamId)
      } else {
        teamsData.rows.splice(rowIndex, 1)
        setTeamsData({ rows: teamsData.rows })
      }
    },
    [handleApiError, teamsData, showSnackSuccess, t]
  )

  const rowForm = useCallback(
    (
      row: TeamsDataRowType,
      rowIndex: number,
      onChangeRow: (rowIndex: number, field: string, value: string | any) => void
    ) => {
      const onAddTeamMember = (teamRowIndex: number, provider: PersonnelDTO) => {
        teamsData.rows[teamRowIndex]?.data?.teamMembers?.push({
          providerStaff: provider,
        })
        onChangeRow(teamRowIndex, 'teamMembers', teamsData.rows[teamRowIndex]?.data?.teamMembers)
      }

      const onRemoveTeamMember = (
        associationId: string,
        teamRowIndex: number,
        teamMemberRowIndex: number
      ) => {
        const deleteTeamMember = async (teamId: string, memberId: string) => {
          await TeamControllerService.deleteTeamMember(teamId, memberId)
            .then(() => {
              teamsData.rows[teamRowIndex]?.data?.teamMembers?.splice(teamMemberRowIndex, 1)
              onChangeRow(
                teamRowIndex,
                'teamMembers',
                teamsData.rows[teamRowIndex]?.data?.teamMembers
              )
              showSnackSuccess(t('api.save-success'))
            })
            .catch((error) => {
              handleApiError(error)
            })
        }

        const teamId = teamsData.rows[teamRowIndex].data?.id
        if (teamId && associationId) {
          deleteTeamMember(teamId, associationId)
        } else {
          teamsData.rows[teamRowIndex]?.data?.teamMembers?.splice(teamMemberRowIndex, 1)
          setTeamsData({ rows: teamsData.rows })
        }
      }

      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISTextField
              error={!!getError(`team-name-${rowIndex}`)}
              helperText={getError(`team-name-${rowIndex}`)}
              id="team-name"
              label={t('teams.team-name')}
              onChange={(event) => onChangeRow(rowIndex, 'teamName', event.target.value)}
              required
              value={row.data?.teamName}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              id="team-description"
              label={t('teams.team-description')}
              onChange={(event) => onChangeRow(rowIndex, 'teamDescription', event.target.value)}
              value={row.data?.teamDescription}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              error={!!getError(`team-startDate-${rowIndex}`)}
              helperText={getError(`team-startDate-${rowIndex}`)}
              isDefaultToday
              label={t('teams.start-date')}
              onChange={(value: ChangeEvent<HTMLInputElement>) => {
                onChangeRow(rowIndex, 'startDate', value)
              }}
              required
              value={row.data?.effective?.startDate}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              label={t('teams.end-date')}
              onChange={(value: ChangeEvent<HTMLInputElement>) => {
                onChangeRow(rowIndex, 'endDate', value)
              }}
              value={row.data?.effective?.endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <StaffAssociation
              displaySectionHeader={false}
              members={
                row.data?.teamMembers
                  ? row.data?.teamMembers?.map((member) => {
                      return {
                        associationId: member.id,
                        personnel: member.providerStaff,
                      }
                    })
                  : []
              }
              onAdd={(member: PersonnelDTO) => onAddTeamMember(rowIndex, member)}
              onRemove={(associationId: string, memberIndex: number) =>
                onRemoveTeamMember(associationId, rowIndex, memberIndex)
              }
            />
          </Grid>
        </Grid>
      )
    },
    [teamsData, showSnackSuccess, handleApiError, getError, t]
  )

  const rowHeader = useCallback((row: TeamsDataRowType) => {
    return (
      <>
        {row.data?.teamName && <span>{`${row.data.teamName}`}</span>}
        {row.data?.teamDescription && <span>{` | ${row.data.teamDescription}`}</span>}
        {row.data?.effective?.startDate && (
          <span>{` | ${isoDateToDisplayFormat(row.data?.effective?.startDate)}`}</span>
        )}
        {row.data?.effective?.endDate && (
          <span>{` to ${isoDateToDisplayFormat(row.data?.effective?.endDate)}`}</span>
        )}
      </>
    )
  }, [])

  return (
    <Box className="teams-container" sx={{ mt: 6 }}>
      <SectionHeader
        addLabel={t('teams.labels.add-team')}
        onAdd={onAddRow}
        onSave={onSave}
        saveLabel={t('common.button.save')}
        title={t('teams.labels.title')}
      />
      <MISAccordionContainer
        emptyDataMessage={t('teams.empty-message')}
        errors={errors.length === 0 ? [] : [t('common.validation.errors')]}
        onChangeRow={onChangeRow}
        onCollapseRow={onCollapseRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowForm}
        rowHeader={rowHeader}
        rows={teamsData.rows}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </Box>
  )
}

export default Teams
