import global from 'common/styles/global.scss'
import CardTemplate from 'common/templates/CardTemplate'
import './Content.scss'

export const Content = ({
  content,
  heading = '',
  isCollapsible = false,
  isDivider = true,
  onCollapseLabel = '',
}) => {
  return (
    <div className={isDivider ? 'content' : 'content nodivider'} role="presentation">
      <CardTemplate
        className="template"
        headStyle={{
          background: '#FFFFFF',
          borderRadius: `${global.MARGIN_SM}`,
          margin: '1rem',
          padding: '1rem',
        }}
        isCollapsible={isCollapsible}
        isDivider={isDivider}
        onCollapseLabel={onCollapseLabel}
        title={heading}
      >
        {content}
      </CardTemplate>
    </div>
  )
}
