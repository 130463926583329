import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { Client } from '@stomp/stompjs'
import { NotificationControllerService, UserControllerService } from 'services/openapi'

export interface NotificationContextProps {
  notifications: string[]
}

export const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
})

export const NotificationProvider = (props: any) => {
  const [isConnected, setIsConnected] = useState(false)
  const [notifications, setNotifications] = useState<string[]>([])
  const stompClientRef = useRef<Client | null>(null)

  const client = useMemo(() => {
    return new Client({
      brokerURL: process.env.REACT_APP_NOTIFICATION_WEBSOCKET_URL,
      debug: function (str) {
        // You can customize the debug behavior here
        // or leave it empty to disable logging
      },
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      reconnectDelay: 5000,
    })
  }, [])

  stompClientRef.current = client

  useEffect(() => {
    if (
      props.user?.profile?.sub &&
      !isConnected &&
      process.env.REACT_APP_NOTIFICATION_ENABLED === 'true'
    ) {
      UserControllerService.findUserBy(props.user.profile.sub).then((result) => {
        if (result) {
          const userId = String(result.id)

          NotificationControllerService.getNotificationsForUser(userId).then((result) => {
            const notificationList: string[] = (result.content ?? []).map(
              (obj: any) => obj.notification?.message || ''
            )
            setNotifications(notificationList)

            const headers = { selector: `userId = '${userId}'` }

            // Connect to the server
            client.activate()

            // Connection success callback
            client.onConnect = () => {
              console.log('Connected to WebSocket and STOMP server')
              setIsConnected(true)
              client.subscribe(
                process.env.REACT_APP_NOTIFICATION_TOPIC_NAME || 'notificationTopic',
                (message) => {
                  const receivedMessage = message.body
                  // Call the provided callback function with the received message
                  setNotifications((prevNotifications) => [...prevNotifications, receivedMessage])
                },
                headers
              )
            }

            client.onStompError = function (frame) {
              // Will be invoked in case of error encountered at Broker
              // Bad login/passcode typically will cause an error
              // Complaint brokers will set `message` header with a brief message. Body may contain details.
              // Compliant brokers will terminate the connection after any error
              console.log('Broker reported error: ' + frame.headers['message'])
              console.log('Additional details: ' + frame.body)
            }

            // Cleanup on unmount
            return () => {
              // Disconnect the client
              client.deactivate()
              setIsConnected(false)
              console.log('Disconnected from WebSocket and STOMP server')
            }
          })
        }
      })
    }
  }, [props.user?.profile?.sub, client, isConnected])

  return (
    <NotificationContext.Provider
      value={{
        notifications,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}
