import { SvgIcon } from '@mui/material'

const MISToDoIcon = () => {
  return (
    <SvgIcon sx={{ padding: '2px' }} viewBox="0 0 20 20">
      <svg height="20px" version="1.1" width="29px">
        <g transform="matrix(1 0 0 1 -30 -202 )">
          <path
            d="M 11.831885526315789 12.254423076923079  L 4.33237105263158 19.852076923076922  L 0 15.485192307692305  L 1.0507921052631586 14.426038461538461  L 4.294213157894737 17.69526923076923  L 10.781055263157898 11.156807692307693  L 11.831885526315789 12.254423076923079  Z M 11.831885526315789 1.130192307692304  L 4.33237105263158 8.72780769230769  L 0 4.360961538461537  L 1.0507921052631586 3.301769230769229  L 4.294213157894737 6.570999999999998  L 10.781055263157898 0.03253846153845949  L 11.831885526315789 1.130192307692304  Z M 27.50302763157895 17  L 15.292501315789472 17  L 15.292501315789472 15.562115384615385  L 27.50302763157895 15.562115384615385  L 27.50302763157895 17  Z M 27.50302763157895 6  L 15.292501315789472 6  L 15.292501315789472 4.437846153846152  L 27.50302763157895 4.437846153846152  L 27.50302763157895 6  Z "
            fill="#666666"
            fillRule="nonzero"
            stroke="none"
            transform="matrix(1 0 0 1 30 202 )"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default MISToDoIcon
