/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormDataDTO } from '../models/FormDataDTO'
import type { PageFormDataDTO } from '../models/PageFormDataDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FormDataControllerService {
  /**
   * Update an encounter form
   * The properties of the encounter form are specified in the JSON request body.
   * @param formDataId
   * @param requestBody
   * @returns FormDataDTO OK
   * @throws ApiError
   */
  public static formupdateFormData(
    formDataId: string,
    requestBody: FormDataDTO
  ): CancelablePromise<FormDataDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-form-service/v1/form-data/{formDataId}',
      path: {
        formDataId: formDataId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create an encounter form
   * The properties of the encounter form are specified in the JSON request body.
   * @param requestBody
   * @returns FormDataDTO OK
   * @throws ApiError
   */
  public static formcreateFormData(requestBody: FormDataDTO): CancelablePromise<FormDataDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-form-service/v1/form-data',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Search for an encounter form
   * @param entityIds
   * @param formDataIds
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageFormDataDTO OK
   * @throws ApiError
   */
  public static formsearchFormData(
    entityIds?: Array<string>,
    formDataIds?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageFormDataDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-form-service/v1/form-data/search',
      query: {
        entityIds: entityIds,
        formDataIds: formDataIds,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
