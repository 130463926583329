/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataDomainAndEntityPermissionsDTO = {
  dataDomainName?: string
  dataEntities?: Array<string>
  primaryAccess?: DataDomainAndEntityPermissionsDTO.primaryAccess
  secondaryAccess?: DataDomainAndEntityPermissionsDTO.secondaryAccess
}

export namespace DataDomainAndEntityPermissionsDTO {
  export enum primaryAccess {
    READ = 'READ',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
  }

  export enum secondaryAccess {
    READ = 'READ',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
  }
}
