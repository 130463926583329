import { useEffect } from 'react'
import { AuthContextProps } from 'react-oidc-context'
import { useDispatch } from 'react-redux'
import { User } from 'oidc-client-ts'
import { SetterOrUpdater, useSetRecoilState } from 'recoil'
import {
  alertsAtom,
  domainsAtom,
  esdrtCategoriesAtom,
  functionalAreasAtom,
  govAgenciesWithDomainsAtom,
  idheServicesAtom,
  programGroupAtom,
  programsAtom,
  purposesAtom,
  purposesGroupAtom,
  templateGroupAtom,
  templatesAtom,
  typeGroupAtom,
  typesAtom,
  userPreferencesAtom,
} from 'recoil/atoms'
import {
  pinnedClientsState,
  recentClientsState,
  userIdState,
  userPinnedClientsPreferenceIdState,
  userRecentClientsPreferenceIdState,
} from 'recoil/recentClients'
import { terminologyState, terminologyWithRelationsState } from 'recoil/terminology'
import { setApiToken } from 'services/ApiService'
import {
  AgencyDomainControllerService,
  AlertControllerService,
  AlertDTO,
  BusinessLineControllerService,
  BusinessLineTerse,
  CancelablePromise,
  ClientControllerService,
  ClientDTO,
  DataDomainControllerService,
  EncounterServiceTemplateControllerService,
  EncounterServiceTemplateDTO,
  EsdrtControllerService,
  FunctionalAreaControllerService,
  GovernanceAgencyControllerService,
  GovernanceAgencyWithDomainsDTO,
  IdheServiceControllerService,
  IdheServiceDTO,
  ListEsdrtCategoriesDTO,
  ListFunctionalAreasDTO,
  Preference,
  ProgramControllerService,
  ProgramTerse,
  PurposeControllerService,
  PurposeGroupControllerService,
  PurposeGroupTerse,
  PurposeTerse,
  TypeControllerService,
  TypeGroupControllerService,
  TypeGroupTerse,
  TypeTerse,
  UserControllerService,
  UserPreferenceControllerService,
  ValueSetControllerService,
} from 'services/openapi'
import { getVocabularyAtoms, getVocabularyWithRelationsAtoms } from 'services/terminologyConstants'
import { store } from 'store'
import { setAppInitialized } from 'store/reducers/app'
import { setBusinessLinesTerse } from 'store/reducers/businessLine'
import { setBusinessLines, setDataDomains } from 'store/reducers/permission'
import { setPrograms } from 'store/reducers/program'
import { setTerminology } from 'store/reducers/terminology'
import { setUserId } from 'store/reducers/user'
import { DomainType, ValueSetType } from '../Types'

const pageable = { page: 0, size: 20000, sort: ['name', 'asc'] }

function populateValueSets(
  setThisValueSet: SetterOrUpdater<ValueSetType[]>,
  vocabList: Array<string>,
  includeRelated: boolean
) {
  let stateList: ValueSetType[] = []
  return ValueSetControllerService.getValueSets(vocabList, includeRelated).then((data) => {
    vocabList.forEach((element) => {
      if (data[element]) {
        const additionalVocab: ValueSetType = { setName: element, value: data[element] }
        stateList = [...stateList, additionalVocab]
      }
    })
    setThisValueSet([...stateList])
    if (!includeRelated) store.dispatch(setTerminology([...stateList]))
  })
}

function populateDomains(setDomains: SetterOrUpdater<DomainType[]>) {
  return AgencyDomainControllerService.listDomains().then((data) => setDomains(data))
}

function populateFunctionalGroups(setFunctionalAreas: SetterOrUpdater<ListFunctionalAreasDTO[]>) {
  return FunctionalAreaControllerService.getFunctionalAreas().then((data) =>
    setFunctionalAreas(data.content || [])
  )
}

function populateIdheServices(setIdheService: SetterOrUpdater<IdheServiceDTO[]>) {
  return IdheServiceControllerService.getAllServices(
    pageable.page,
    pageable.size,
    pageable.sort
  ).then((data) => setIdheService(data.content || []))
}

function populatePrograms(setProgram: SetterOrUpdater<ProgramTerse[]>) {
  return ProgramControllerService.listPrograms(pageable.page, pageable.size, pageable.sort).then(
    (data) => {
      setProgram(data.content || [])
      store.dispatch(setPrograms(data.content))
    }
  )
}

function populatePurposes(setPurposes: SetterOrUpdater<PurposeTerse[]>) {
  return PurposeControllerService.listPurposes(pageable.page, pageable.size, pageable.sort).then(
    (data) => setPurposes(data.content || [])
  )
}

function populateTypes(setTypes: SetterOrUpdater<TypeTerse[]>) {
  return TypeControllerService.listTypes(pageable.page, pageable.size, pageable.sort).then((data) =>
    setTypes(data.content || [])
  )
}

function populateTemplates(setTypes: SetterOrUpdater<EncounterServiceTemplateDTO[]>) {
  return EncounterServiceTemplateControllerService.organizationgetPagedList().then((data) =>
    setTypes(data.content || [])
  )
}

function populateProgramGroups(setProgramGroup: SetterOrUpdater<BusinessLineTerse[]>) {
  return BusinessLineControllerService.listProgramGroups(
    pageable.page,
    pageable.size,
    pageable.sort
  ).then((data) => {
    setProgramGroup(data.content || [])
    store.dispatch(setBusinessLinesTerse(data.content))
  })
}

function populateDataDomains() {
  return DataDomainControllerService.listDataDomains().then((data) => {
    store.dispatch(setDataDomains(data.content))
  })
}

function populatePurposeGroups(setPurposeGroup: SetterOrUpdater<PurposeGroupTerse[]>) {
  return PurposeGroupControllerService.listPurposeGroups(
    pageable.page,
    pageable.size,
    pageable.sort
  ).then((data) => setPurposeGroup(data.content || []))
}

function populateTypeGroups(setTypeGroup: SetterOrUpdater<TypeGroupTerse[]>) {
  return TypeGroupControllerService.listTypeGroups(
    pageable.page,
    pageable.size,
    pageable.sort
  ).then((data) => setTypeGroup(data.content || []))
}

function populateEsdrtCategoriesAndServices(
  setCategories: SetterOrUpdater<ListEsdrtCategoriesDTO[]>
) {
  return EsdrtControllerService.listEsdrtCategories().then((data) =>
    setCategories(data.content || [])
  )
}

function populateBusinessLines() {
  return BusinessLineControllerService.listAllBusinessLinesWithPrograms().then((data) =>
    store.dispatch(setBusinessLines(data.content || []))
  )
}

export const populateUserPreferences = (
  setUserPreferences: SetterOrUpdater<Map<string, Preference>>,
  setUserIdRecoil: SetterOrUpdater<string>,
  setUserRecentClientsPreferenceId: SetterOrUpdater<string | undefined>,
  setUserPinnedClientsPreferenceId: SetterOrUpdater<string | undefined>,
  setRecentClients: SetterOrUpdater<ClientDTO[] | null>,
  setPinnedClients: SetterOrUpdater<ClientDTO[] | null>,
  user: User
) => {
  UserControllerService.findUserBy(user.profile.sub).then((result) => {
    if (result) {
      const userId = String(result.id)
      setUserIdRecoil(userId)
      store.dispatch(setUserId(userId))
      return UserPreferenceControllerService.getUserPreferences(userId).then((data) => {
        if (data.content) {
          const preferencesMap = new Map<string, Preference>()
          data.content?.forEach((preference) =>
            preferencesMap.set(String(preference.name), preference)
          )
          if (preferencesMap.has('recent-clients')) {
            const preference = preferencesMap.get('recent-clients')
            setUserRecentClientsPreferenceId(preference?.id)
            const recentClientIds = preference?.value ? preference.value.split('|') : []
            const promises: CancelablePromise<ClientDTO>[] = []
            recentClientIds.forEach((id) => promises.push(ClientControllerService.getClient(id)))
            Promise.all(promises)
              .then((resp) => setRecentClients(resp))
              .catch(() => setRecentClients([]))
          } else setRecentClients([])
          if (preferencesMap.has('pinned-clients')) {
            const preference = preferencesMap.get('pinned-clients')
            setUserPinnedClientsPreferenceId(preference?.id)
            const pinnedClientIds = preference?.value ? preference.value.split('|') : []
            const promises: CancelablePromise<ClientDTO>[] = []
            pinnedClientIds.forEach((id) => promises.push(ClientControllerService.getClient(id)))
            Promise.all(promises).then(
              (resp) => setPinnedClients(resp),
              () => setPinnedClients([])
            )
          } else setPinnedClients([])
          setUserPreferences(preferencesMap)
        }
      })
    }
  })
}

const populateGovernanceAgencies = (
  setGovAgenciesWithDomains: SetterOrUpdater<GovernanceAgencyWithDomainsDTO[]>
) => {
  return GovernanceAgencyControllerService.searchAgencyListForSpecifiedType(
    '',
    '',
    '',
    true,
    undefined,
    pageable.size
  ).then((result) => setGovAgenciesWithDomains(result.content || []))
}

const populateAlerts = (setAlerts: SetterOrUpdater<AlertDTO[]>) => {
  return AlertControllerService.getAlerts().then((result) => setAlerts(result.content || []))
}

const useStartupService = (auth: AuthContextProps) => {
  const dispatch = useDispatch()

  const setTerminology = useSetRecoilState(terminologyState)
  const setTerminologyWithRelations = useSetRecoilState(terminologyWithRelationsState)
  const setDomains = useSetRecoilState(domainsAtom)
  const setFunctionalAreas = useSetRecoilState(functionalAreasAtom)
  const setIdheServices = useSetRecoilState(idheServicesAtom)
  const setProgram = useSetRecoilState(programsAtom)
  const setPurposes = useSetRecoilState(purposesAtom)
  const setTypes = useSetRecoilState(typesAtom)
  const setTemplates = useSetRecoilState(templatesAtom)
  const setProgramGroup = useSetRecoilState(programGroupAtom)
  const setPurposeGroup = useSetRecoilState(purposesGroupAtom)
  const setTypeGroup = useSetRecoilState(typeGroupAtom)
  const setTemplateGroups = useSetRecoilState(templateGroupAtom)
  const setEsdrtCategories = useSetRecoilState(esdrtCategoriesAtom)
  const setUserPreferences = useSetRecoilState(userPreferencesAtom)
  const setGovAgenciesWithDomains = useSetRecoilState(govAgenciesWithDomainsAtom)
  const setAlerts = useSetRecoilState(alertsAtom)
  const setUserIdRecoil = useSetRecoilState(userIdState)
  const setUserRecentClientsPreferenceId = useSetRecoilState(userRecentClientsPreferenceIdState)
  const setUserPinnedClientsPreferenceId = useSetRecoilState(userPinnedClientsPreferenceIdState)
  const setRecentClients = useSetRecoilState(recentClientsState)
  const setPinnedClients = useSetRecoilState(pinnedClientsState)

  useEffect(() => {
    const initializeApp = async () => {
      setApiToken(auth)
      if (auth.user)
        populateUserPreferences(
          setUserPreferences,
          setUserIdRecoil,
          setUserRecentClientsPreferenceId,
          setUserPinnedClientsPreferenceId,
          setRecentClients,
          setPinnedClients,
          auth.user
        )
      populateDomains(setDomains)
      populateFunctionalGroups(setFunctionalAreas)
      populateIdheServices(setIdheServices)
      populatePrograms(setProgram)
      populatePurposes(setPurposes)
      populateTypes(setTypes)
      populateTemplates(setTemplates)
      populateProgramGroups(setProgramGroup)
      populatePurposeGroups(setPurposeGroup)
      populateTypeGroups(setTypeGroup)
      populateEsdrtCategoriesAndServices(setEsdrtCategories)
      populateAlerts(setAlerts)
      populateBusinessLines()
      populateDataDomains()

      const promises: Promise<void>[] = []
      promises.push(populateValueSets(setTerminology, getVocabularyAtoms(), false))
      promises.push(
        populateValueSets(setTerminologyWithRelations, getVocabularyWithRelationsAtoms(), true)
      )
      promises.push(populateGovernanceAgencies(setGovAgenciesWithDomains))
      await Promise.all(promises)
      dispatch(setAppInitialized(true))
    }
    if (auth.isAuthenticated) initializeApp()
  }, [
    auth,
    dispatch,
    setDomains,
    setEsdrtCategories,
    setFunctionalAreas,
    setGovAgenciesWithDomains,
    setIdheServices,
    setProgram,
    setProgramGroup,
    setPurposeGroup,
    setPurposes,
    setTemplateGroups,
    setTemplates,
    setTerminology,
    setTerminologyWithRelations,
    setTypeGroup,
    setTypes,
    setUserPreferences,
    setAlerts,
    setPinnedClients,
    setRecentClients,
    setUserIdRecoil,
    setUserRecentClientsPreferenceId,
    setUserPinnedClientsPreferenceId,
  ])
}

export default useStartupService
