import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Paper, Typography } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import { dateNowIsoString } from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { selectUserId } from 'store/selectors/user'
import { SOAPTemplateState } from './SOAPTemplate'

type SOAPTemplateComponentProps = SOAPTemplateState & {
  onChange?: (key: string, value: string) => void
}

const SOAPTemplateComponent = ({
  assessment,
  createdBy,
  createdOn,
  objective,
  onChange,
  plan,
  subjective,
}: SOAPTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const userId = useSelector(selectUserId)
  const providers = useProviders()

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const handleChange = useCallback(
    (key: string, value: string) => onChange?.(key, value),
    [onChange]
  )

  useEffect(() => {
    if (loggedInProvider && createdBy === undefined && loggedInProvider.id)
      handleChange('createdBy', loggedInProvider.id)
    if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
  }, [createdBy, createdOn, handleChange, loggedInProvider])

  return (
    <Grid className="soap-template" container spacing={2}>
      <Grid item xs={6}>
        <Paper className="quadrant">
          <Typography variant="h4">{t('charting.templates.soap.subjective')}</Typography>
          <MISTextField
            label=""
            minRows={5}
            multiline
            onChange={(e) => handleChange('subjective', e.target.value)}
            placeholder={t('charting.templates.soap.subjective-placeholder')}
            value={subjective}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="quadrant">
          <Typography variant="h4">{t('charting.templates.soap.objective')}</Typography>
          <MISTextField
            label=""
            minRows={5}
            multiline
            onChange={(e) => handleChange('objective', e.target.value)}
            placeholder={t('charting.templates.soap.objective-placeholder')}
            value={objective}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="quadrant">
          <Typography variant="h4">{t('charting.templates.soap.assessment')}</Typography>
          <MISTextField
            label=""
            minRows={5}
            multiline
            onChange={(e) => handleChange('assessment', e.target.value)}
            placeholder={t('charting.templates.soap.assessment-placeholder')}
            value={assessment}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="quadrant">
          <Typography variant="h4">{t('charting.templates.soap.plan')}</Typography>
          <MISTextField
            label=""
            minRows={5}
            multiline
            onChange={(e) => handleChange('plan', e.target.value)}
            placeholder={t('charting.templates.soap.plan-placeholder')}
            value={plan}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SOAPTemplateComponent
