/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageRoleView } from '../models/PageRoleView'
import type { RoleDTO } from '../models/RoleDTO'
import type { RolePermissionDTO } from '../models/RolePermissionDTO'
import type { RoleView } from '../models/RoleView'
import type { StatusResponse } from '../models/StatusResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class RoleControllerService {
  /**
   * Get a Role by ID
   * @param roleId
   * @param _with
   * @returns RoleDTO OK
   * @throws ApiError
   */
  public static getById3(roleId: string, _with?: Array<'permissions'>): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/roles/{roleId}',
      path: {
        roleId: roleId,
      },
      query: {
        with: _with,
      },
    })
  }

  /**
   * @param roleId
   * @param requestBody
   * @returns RoleDTO OK
   * @throws ApiError
   */
  public static updateRoles(roleId: string, requestBody: RoleDTO): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/roles/{roleId}',
      path: {
        roleId: roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get Permissions for specified Role
   * @param roleId
   * @returns RolePermissionDTO OK
   * @throws ApiError
   */
  public static getRolePermissions(roleId: string): CancelablePromise<Array<RolePermissionDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/roles/{roleId}/permissions',
      path: {
        roleId: roleId,
      },
    })
  }

  /**
   * Add Permissions to a role
   * @param roleId
   * @param requestBody
   * @returns RolePermissionDTO OK
   * @throws ApiError
   */
  public static addRolePermissions(
    roleId: string,
    requestBody: Array<RolePermissionDTO>
  ): CancelablePromise<Array<RolePermissionDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/roles/{roleId}/permissions',
      path: {
        roleId: roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get a paged list of roles.  Currently no search params.
   * @param name
   * @param description
   * @param roleType
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageRoleView OK
   * @throws ApiError
   */
  public static searchRolesPaged(
    name?: string,
    description?: string,
    roleType?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageRoleView> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/roles',
      query: {
        name: name,
        description: description,
        roleType: roleType,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create a role by adding a record to the database.
   * @param requestBody
   * @returns RoleDTO OK
   * @throws ApiError
   */
  public static createRole(requestBody: RoleDTO): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/roles',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param pattern
   * @returns StatusResponse OK
   * @throws ApiError
   */
  public static loadRoles(pattern?: string): CancelablePromise<StatusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/roles/loader',
      query: {
        pattern: pattern,
      },
    })
  }

  /**
   * @param rolename
   * @param pattern
   * @returns StatusResponse OK
   * @throws ApiError
   */
  public static loadRolePermissions(
    rolename: string,
    pattern?: string
  ): CancelablePromise<StatusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/role-permissions/loader',
      query: {
        rolename: rolename,
        pattern: pattern,
      },
    })
  }

  /**
   * Find a single Role by specified parameter, currently 'name' is supported
   * @param name
   * @returns RoleView OK
   * @throws ApiError
   */
  public static findRoleBy(name?: string): CancelablePromise<RoleView> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/roles/find-by',
      query: {
        name: name,
      },
    })
  }

  /**
   * Find role permissions identified by specified parameter, currently 'name' is supported
   * @param rolename
   * @returns RolePermissionDTO OK
   * @throws ApiError
   */
  public static findPermissionsBy(rolename: string): CancelablePromise<Array<RolePermissionDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/role-permissions/find-by',
      query: {
        rolename: rolename,
      },
    })
  }
}
