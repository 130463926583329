import React, { createContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { useSnack } from 'common/components/snackbar/useSnack'
import { apiErrorState } from 'recoil/apiError'

export const ErrorHandlerContext = createContext()

export const ErrorHandlerProvider = ({ children }) => {
  const { showSnackError } = useSnack()
  const { t } = useTranslation('common')
  const setApiError = useSetRecoilState(apiErrorState)

  const [errors, setErrors] = useState({}) // TODO This should be removed

  const handleApiError = useCallback(
    (apiError, showSnack = true) => {
      const errorBody = apiError.body ? apiError.body : apiError
      // Optionally show the "top-level" error in a snack.
      if (showSnack) {
        let message = 'Unhandled Error, please try refreshing'
        if (errorBody && errorBody.message) {
          message = errorBody.message
          // TODO: We should avoid embedding HTML within strings.
          // This should really be done by passing the entire error into the Snack,
          // and a parser should retrieve the information and display it accordingly.
          if (errorBody.validationResults) {
            message += '<ul>'
            errorBody.validationResults.forEach((validationError) => {
              message += `<li>${validationError.message}</li>`
            })
            message += '</ul>'
          }

          if (errorBody.messageKey && errorBody.messageArgs) {
            message = t(errorBody.messageKey, errorBody.messageArgs)
          }
        }
        showSnackError(message, 6000)
      }

      // Take the validation results and put them into the errors map.
      if (errorBody.validationResults) {
        const newErrors = {}
        errorBody.validationResults.forEach((validationResult) => {
          let message = validationResult.message
          if (validationResult.messageKey) {
            message = t(validationResult.messageKey, validationResult.messageArgs)
          }
          newErrors[validationResult.fieldName] = message
        })
        setApiError(newErrors)
      }
    },
    [setApiError, showSnackError, t]
  )

  const buildError = useCallback((fieldName, messageKey, messageArgs) => {
    return {
      fieldName: fieldName,
      messageArgs: messageArgs,
      messageKey: messageKey,
    }
  }, [])

  const setErrorList = useCallback(
    (errorList) => {
      const newErrors = []
      errorList.forEach((error) => {
        newErrors[error.fieldName] = t(error.messageKey, error.messageArgs)
      })
      setErrors(newErrors)
    },
    [t]
  )

  const clearErrors = useCallback(() => setErrors({}), [])

  const addError = useCallback(
    (key, messageKey, messageArgs) => {
      const newErrors = { ...errors }
      newErrors[key] = t(messageKey, messageArgs)
      setErrors(newErrors)
    },
    [errors, t]
  )

  const getError = useCallback(
    (key) => {
      return errors[key] ? errors[key] : ''
    },
    [errors]
  )

  const hasError = useCallback(
    (key) => {
      return getError(key) ? true : false
    },
    [getError]
  )

  const isError = useCallback(() => {
    return Object.keys(errors).length > 0
  }, [errors])

  return (
    <ErrorHandlerContext.Provider
      value={{
        addError,
        buildError,
        clearErrors,
        getError,
        handleApiError,
        hasError,
        isError,
        setErrorList,
      }}
    >
      {children}
    </ErrorHandlerContext.Provider>
  )
}
