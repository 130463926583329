import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Box, List, ListItem } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISLink from 'common/components/MISLink'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getCurrentTenant } from 'core/helpers/security/currentTenant'
import { getClientFullName } from 'modules/shared/clientUtils'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateValueType, StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientDTO,
  CodedConceptDto,
  CodedRef,
  ImmunizationConsentControllerService,
  ImmunizationConsentDTO,
  ImmunizationConsentStateDTO,
  ImmunizationScheduleControllerService,
} from 'services/openapi'
import {
  MIS_BC_IMMUNIZATION_AGENT,
  MIS_CONSENT_OPTIONS,
  MIS_PERSON_RELATIONSHIP_ATTRIBUTE,
  MIS_PERSON_RELATIONSHIP_TYPE,
  MIS_VOID_REASON_TYPE,
} from 'services/terminologyConstants'
import { selectClientRelationships } from 'store/selectors/client'

type ConsentWithState = {
  state?: ImmunizationConsentStateDTO
  consent?: ImmunizationConsentDTO
}

type ConsentRecordsRow = {
  scheduleId: string
  scheduleName?: string
  consentsWithState: ConsentWithState[]
  consentsData?: string[]
  byWhom: string
  relationClient?: ClientDTO
  groupId?: string
}

const ImmunizationConsentStates = [
  {
    code: 'ACTIVE',
    codeSystemOid: '1.1.123.567.1',
    name: 'Active',
  },
  {
    code: 'INACTIVE',
    codeSystemOid: '1.1.123.567.2',
    name: 'Inactive',
  },
  {
    code: 'VOID',
    codeSystemOid: '1.1.123.567.3',
    name: 'Void',
  },
]

const ImmunizationConsentRecorded = forwardRef((_, ref) => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()

  const { handleApiError } = useErrorHandler()
  const navigate = useNavigate()
  const clientRelationships = useSelector(selectClientRelationships)

  const [displayConsents, setDisplayConsents] = useState<ConsentRecordsRow[]>([])
  const relationshipAttributeTypes = useRecoilValue(
    terminologySelector(MIS_PERSON_RELATIONSHIP_ATTRIBUTE)
  )
  const relationshipTypes = useRecoilValue(terminologySelector(MIS_PERSON_RELATIONSHIP_TYPE))
  const agentOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_BC_IMMUNIZATION_AGENT)
  )
  const consentOptions: CodedConceptDto[] = useRecoilValue(terminologySelector(MIS_CONSENT_OPTIONS))
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  const consentStateValues: StateValueType[] = useMemo(
    () =>
      ImmunizationConsentStates.map((opt) => {
        switch (opt.code) {
          case 'VOID':
            return {
              reasonCodeRequired: true,
              reasonCodeTerminology: voidReasonOptions,
              stateValue: opt,
            }
          default:
            return { stateValue: opt }
        }
      }),
    [voidReasonOptions]
  )

  const populateConsents = useCallback(async () => {
    if (clientId) {
      const response = await ImmunizationConsentControllerService.searchAllImmunizationConsent(
        clientId
      )
      if (response?.content) {
        // Group all the agents together
        const grouped = response.content.reduce(
          (acc: ConsentRecordsRow[], curr: ImmunizationConsentDTO) => {
            const existingSchedule = acc.find(
              (a) => a.scheduleId === curr.immunizationScheduleId && a.groupId === curr.groupId
            )

            if (existingSchedule) {
              existingSchedule.consentsWithState = existingSchedule.consentsWithState || []
              existingSchedule.consentsWithState.push({ consent: curr })
            } else {
              acc.push({
                byWhom: '',
                consentsWithState: [{ consent: curr }],
                groupId: curr.groupId || '',
                scheduleId: curr.immunizationScheduleId || '',
              })
            }
            return acc
          },
          []
        )

        // Process each group
        const processGroup = async (group: ConsentRecordsRow) => {
          const schedule = await ImmunizationScheduleControllerService.getImmunizationScheduleById(
            group.scheduleId
          )
          if (schedule) {
            group.scheduleName = schedule.name
          }

          const statesPromises = group?.consentsWithState.map(({ consent }) =>
            consent?.id
              ? ImmunizationConsentControllerService.getStatesByImmunizationConsentId(
                  clientId,
                  consent.id
                )
              : null
          )

          const states = await Promise.all(statesPromises)

          group.consentsWithState = group.consentsWithState.map((entry, index) => {
            const state = states[0]
            if (state && entry.consent) {
              const agentName =
                agentOptions.find((x) => x.code === entry.consent?.agent?.code)?.name || ''
              const consentName =
                consentOptions.find((x) => x.code === entry.consent?.consent?.code)?.name || ''
              const consentDate = entry.consent?.consentDate
                ? isoDateToDisplayFormat(entry.consent?.consentDate)
                : ''
              entry.state = state?.content?.at(index)
              if (!group.consentsData) group.consentsData = []
              group.consentsData[index] = `${agentName} | ${consentName} | ${consentDate}`
            }
            return entry
          })
          return group
        }

        // Run processing for each group in parallel
        const processedGroups = await Promise.all(grouped.map(processGroup))

        // Set the display consents
        setDisplayConsents(processedGroups)
      }
    }
  }, [clientId, agentOptions, consentOptions])

  const handleChangeConsentState = useCallback(
    async (scheduleId: string, state: CodedRef, reason?: CodedRef, comment?: string) => {
      if (!clientId) return

      try {
        const consentUpdates: ImmunizationConsentStateDTO[] = []

        for (const consent of displayConsents.find((x) => x.scheduleId === scheduleId)
          ?.consentsWithState || []) {
          const serviceUpdate =
            await ImmunizationConsentControllerService.createImmunizationConsentState(
              clientId,
              consent.consent?.id as string,
              { comment, reason, state }
            )
          consentUpdates.push(serviceUpdate)
        }

        const updatedConsent = displayConsents
          .find((x) => x.scheduleId === scheduleId)
          ?.consentsWithState?.map((entry, index) => {
            const state = consentUpdates[index]
            if (state && entry) {
              entry.state = state
            }
            return entry
          })

        const indexToUpdate = displayConsents.findIndex((x) => x.scheduleId === scheduleId)
        const updatedRow = {
          ...displayConsents[indexToUpdate],
          consentsWithState: updatedConsent || [],
        }
        const updatedConsentRows = [...displayConsents]
        updatedConsentRows[indexToUpdate] = updatedRow
        setDisplayConsents(updatedConsentRows)
      } catch (error) {
        handleApiError(error)
      }
    },
    [clientId, displayConsents, handleApiError]
  )

  const handleGetNextServiceStateOptions = useCallback(
    async (scheduleId: string, groupId: string) => {
      const nextStateOptions: StateWithInstructionsType[] = []
      const consentId = displayConsents.find(
        (x) => x.scheduleId === scheduleId && x.groupId === groupId
      )?.consentsWithState[0].consent?.id
      if (clientId && consentId) {
        const response =
          await ImmunizationConsentControllerService.getNextStatesByImmunizationConsentId(
            clientId,
            consentId
          )
        response.content?.forEach((item) => {
          if (item.state) nextStateOptions.push(item)
        })
        return nextStateOptions
      }
      return []
    },
    [clientId, displayConsents]
  )

  useEffect(() => {
    populateConsents()
  }, [populateConsents])

  useEffect(() => {
    if (displayConsents?.length > 0 && !displayConsents[0].byWhom) {
      const newConsents = displayConsents.map((consent) => {
        if (consent.consentsWithState[0].consent?.consentingClientId) {
          const relationship = clientRelationships?.relationships?.find(
            (rel) => rel.client?.id === consent.consentsWithState[0].consent?.consentingClientId
          )
          const relationshipType = relationshipTypes.find(
            (x) => x.code === relationship?.clientRelationship?.relationshipType?.code
          )?.name

          if (relationship) {
            consent.relationClient = relationship.client
            consent.byWhom =
              'By Whom: ' +
              getClientFullName(relationship.client) +
              (relationshipType ? ` | (${relationshipType})` : '')
          }
        } else {
          consent.relationClient = undefined
          consent.byWhom = 'By Whom: Self'
        }
        return consent
      })
      setDisplayConsents(newConsents)
    }
  }, [clientRelationships, displayConsents, relationshipAttributeTypes, relationshipTypes])

  useImperativeHandle(ref, () => ({
    updateList() {
      populateConsents()
    },
  }))

  return (
    <Box>
      {displayConsents?.length > 0 &&
        displayConsents.map((consent, rowIndex: number) => {
          return (
            <List key={rowIndex} sx={{ '& hr': { mx: 0.5 }, boxShadow: 'none' }}>
              <ListItem
                key={rowIndex + 'header'}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  flexDirection: 'row',
                  fontWeight: 'bold',
                  padding: `${GLOBAL.MARGIN_XS} ${GLOBAL.MARGIN_XS}`,
                }}
              >
                {consent.scheduleName}
                {consent.consentsWithState[0]?.state?.state &&
                  ImmunizationConsentStates?.find(
                    (o) => o.code === consent.consentsWithState[0]?.state?.state?.code
                  ) && (
                    <Box sx={{ ml: `${GLOBAL.MARGIN_SM}` }}>
                      <StateChip
                        comment={consent.consentsWithState[0]?.state?.comment}
                        label={
                          ImmunizationConsentStates.find(
                            (o) => o.code === consent.consentsWithState[0]?.state?.state?.code
                          )?.name || ''
                        }
                        reason={
                          consent.consentsWithState[0]?.state?.reason
                            ? voidReasonOptions.find(
                                (x) => x.code === consent.consentsWithState[0]?.state?.reason?.code
                              )?.name
                            : undefined
                        }
                      />
                    </Box>
                  )}
                <StateSelector
                  entityId={consent.scheduleId as string}
                  onGetNextStates={(entityId) =>
                    handleGetNextServiceStateOptions(entityId, consent.groupId as string)
                  }
                  onSelect={handleChangeConsentState}
                  stateValues={consentStateValues}
                  warningText={t('immunizations.void-warning-text')}
                />
              </ListItem>
              {consent.consentsData?.map((consentData, index) => {
                return (
                  <ListItem
                    key={rowIndex + 'index' + index + 'data'}
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      flexDirection: 'row',
                      padding: `${GLOBAL.MARGIN_XS} ${GLOBAL.MARGIN_XS}`,
                    }}
                  >
                    {consentData}
                  </ListItem>
                )
              })}
              <ListItem
                key={rowIndex + 'bywhom'}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  flexDirection: 'row',
                  padding: `${GLOBAL.MARGIN_XS} ${GLOBAL.MARGIN_XS}`,
                }}
              >
                <span>
                  {consent.byWhom}
                  {consent.relationClient && (
                    <>
                      {' | '}
                      <MISLink
                        color="primary"
                        href={`/${getCurrentTenant().tenantId}/clients/client-record/${
                          consent.relationClient.id
                        }`}
                        key={consent.relationClient.id}
                        onClick={(event) => {
                          event.preventDefault()
                          navigate(`/clients/client-record/${consent.relationClient?.id}`)
                        }}
                        underline="hover"
                        variant="inherit"
                      >
                        {consent.relationClient.fileNumber}
                      </MISLink>
                    </>
                  )}
                </span>
              </ListItem>
            </List>
          )
        })}
    </Box>
  )
})
ImmunizationConsentRecorded.displayName = 'ImmunizationConsentRecorded'

export default ImmunizationConsentRecorded
