import { memo } from 'react'
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import MISDraggableListItem from './MISDraggableListItem'

export type DraggableListProps = {
  items: any[]
  onDragEnd: OnDragEndResponder
  handleDelete: (id: string) => void
  handleOnClick: (id: string) => void
}

/*
https://github.com/atlassian/react-beautiful-dnd/issues/2396
There is an issue regarding this, where the drag drop doesnt work in strict mode
this means that it will run in prod, but not on dev envs as long as we have strict mode enabled
Solution: We disable strict mode in index.js when we are testing it locally and then make sure to uncomment it when we push PRs
*/

const MISDraggableList = memo(
  ({ handleDelete, handleOnClick, items, onDragEnd }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <MISDraggableListItem
                  handleDelete={handleDelete}
                  handleOnClick={handleOnClick}
                  index={index}
                  item={item}
                  key={item.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
)
MISDraggableList.displayName = 'MISDraggableList'

export default MISDraggableList
