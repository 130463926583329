/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramPurposeAssociation } from '../models/ProgramPurposeAssociation'
import type { ProgramPurposeAssociationTerse } from '../models/ProgramPurposeAssociationTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ProgramPurposeAssociationControllerService {
  /**
   * @param id
   * @param requestBody
   * @returns ProgramPurposeAssociationTerse OK
   * @throws ApiError
   */
  public static updateProgramPurposeAssociation(
    id: string,
    requestBody: ProgramPurposeAssociation
  ): CancelablePromise<ProgramPurposeAssociationTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/program-purpose-associations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
