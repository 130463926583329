import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, CheckboxProps, SelectChangeEvent, Stack, TextFieldProps } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { CodedConceptDto } from 'services/openapi'
import { getVocabularyAtoms } from 'services/terminologyConstants'
import { selectTerminology } from 'store/selectors/terminology'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBDropdownProps = {
  data?: CheckboxProps & TextFieldProps & { terminologySet?: string }
  onChange: (e: SelectChangeEvent<string>) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  onTerminologySelect: (e: SelectChangeEvent<string>) => void
  preview: boolean
}

const FBDropdown = ({ data, onChange, onDelete, onSave, preview }: FBDropdownProps) => {
  const { t } = useTranslation('common')
  const terminologySets = useSelector(selectTerminology)

  const [configData, setConfigData] = useState<
    CheckboxProps & TextFieldProps & { terminologySet?: string }
  >(data || {})
  const [configMode, setConfigMode] = useState(false)
  const [terminologyOpts, setTerminologyOpts] = useState<CodedConceptDto[]>([])

  useEffect(
    () =>
      setTerminologyOpts(
        terminologySets.find((set) => set.setName === data?.terminologySet)?.value || []
      ),
    [data?.terminologySet, terminologySets]
  )

  return (
    <>
      {preview ? (
        <MISSelectDropdown
          helperText={data?.helperText as string}
          id={data?.id}
          label={data?.label as string}
          onChange={(e) => onChange(e)}
          options={terminologyOpts.map((each) => ({ label: each.name as string, value: each.id }))}
          value={data?.value as string}
        />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <MISSelectDropdown
              helperText={data?.helperText as string}
              id={data?.id}
              label={data?.label as string}
              onChange={(e) => onChange(e)}
              options={terminologyOpts.map((each) => ({
                label: each.name as string,
                value: each.id,
              }))}
              value={data?.value as string}
            />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.dropdown.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.name}
                label={t('charting.form-builder.name')}
                onChange={(e) =>
                  setConfigData(
                    configData ? { ...configData, name: e.target.value } : { name: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.helperText}
                label={t('charting.form-builder.helperText')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, helperText: e.target.value }
                      : { helperText: e.target.value }
                  )
                }
              />
              <MISSelectDropdown
                label={t('charting.form-builder.dropdown.terminology')}
                onChange={(e) => setConfigData({ ...configData, terminologySet: e.target.value })}
                options={getVocabularyAtoms().map((each) => ({
                  label: each as string,
                  value: each,
                }))}
                value={configData?.terminologySet}
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBDropdown
