/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientAlertDTO } from '../models/ClientAlertDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientAlertControllerService {
  /**
   * Returns alerts for client
   * Returns all the alerts for client
   * @param clientId
   * @returns ClientAlertDTO OK
   * @throws ApiError
   */
  public static getClientAlerts(clientId: string): CancelablePromise<Array<ClientAlertDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Update/insert alerts for client
   * Returns all the alerts for client
   * @param clientId
   * @param requestBody
   * @returns ClientAlertDTO OK
   * @throws ApiError
   */
  public static upsertClientAlerts(
    clientId: string,
    requestBody: Array<ClientAlertDTO>
  ): CancelablePromise<Array<ClientAlertDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Create an alert for client
   * Returns newly created alert for client
   * @param clientId
   * @param requestBody
   * @returns ClientAlertDTO OK
   * @throws ApiError
   */
  public static createClientAlert(
    clientId: string,
    requestBody: ClientAlertDTO
  ): CancelablePromise<ClientAlertDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Return client alert
   * Return single client alert
   * @param clientId
   * @param alertId
   * @returns ClientAlertDTO OK
   * @throws ApiError
   */
  public static getClientAlert(
    clientId: string,
    alertId: string
  ): CancelablePromise<ClientAlertDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts/{alertId}',
      path: {
        clientId: clientId,
        alertId: alertId,
      },
    })
  }

  /**
   * Update client alert
   * update client alert
   * @param clientId
   * @param alertId
   * @param requestBody
   * @returns ClientAlertDTO OK
   * @throws ApiError
   */
  public static updateClientAlert(
    clientId: string,
    alertId: string,
    requestBody: ClientAlertDTO
  ): CancelablePromise<ClientAlertDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts/{alertId}',
      path: {
        clientId: clientId,
        alertId: alertId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Deletes the client alert
   * Soft deletes the client alert by setting deleted flag to true
   * @param clientId
   * @param alertId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteClientAlert(clientId: string, alertId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/alerts/{alertId}',
      path: {
        clientId: clientId,
        alertId: alertId,
      },
    })
  }
}
