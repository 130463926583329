/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncounterStaffDTO } from '../models/EncounterStaffDTO'
import type { PageStaffUserDTO } from '../models/PageStaffUserDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterStaffControllerService {
  /**
   * Get paged list of Staff associated with Encounter Service
   * @param encounterId
   * @param encounterServiceId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageStaffUserDTO OK
   * @throws ApiError
   */
  public static getPagedList2(
    encounterId: string,
    encounterServiceId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageStaffUserDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/staff',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Update attributes to staff associations
   * @param encounterId
   * @param encounterServiceId
   * @param requestBody
   * @returns EncounterStaffDTO OK
   * @throws ApiError
   */
  public static updateStaffAttributes(
    encounterId: string,
    encounterServiceId: string,
    requestBody: EncounterStaffDTO
  ): CancelablePromise<EncounterStaffDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/staff',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Add staff to an encounter service
   * @param encounterId
   * @param encounterServiceId
   * @param requestBody
   * @returns EncounterStaffDTO OK
   * @throws ApiError
   */
  public static addStaffToEncounterService(
    encounterId: string,
    encounterServiceId: string,
    requestBody: EncounterStaffDTO
  ): CancelablePromise<EncounterStaffDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/staff',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete an Encounter service Staff
   * Delete encounter service staff by staffId.
   * @param encounterId
   * @param encounterServiceId
   * @param staffId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEncounterServiceStaff(
    encounterId: string,
    encounterServiceId: string,
    staffId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/staff/{staffId}',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        staffId: staffId,
      },
    })
  }
}
