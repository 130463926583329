import { atom, selector, selectorFamily } from 'recoil'
import { ValueSetType } from 'core/Types'
import { MEMBER_STATUS_VOCAB_NAME, VALUESET } from 'services/terminologyConstants'

export const terminologyState = atom<ValueSetType[]>({
  default: [],
  key: 'terminologyState',
})

export const terminologyWithRelationsState = atom<ValueSetType[]>({
  default: [],
  key: 'terminologyWithRelationsState',
})

export const terminologyDefaultStatusSelector = selector({
  get: ({ get }) => {
    const valueSets = get(terminologyState)
    const statusOptions =
      get(terminologyState).find((x) => x.setName === MEMBER_STATUS_VOCAB_NAME)?.value || []
    if (!!valueSets && valueSets.length > 0) {
      const code = VALUESET.MEMBER_STATUS.ACTIVE.code
      const codeSystemOid = VALUESET.MEMBER_STATUS.ACTIVE.codeSystemOid
      // set default status
      return (
        statusOptions.find((x) => x.code === code && x.codeSystemOid === codeSystemOid)?.id || ''
      )
    }
    return ''
  },
  key: 'terminologyDefaultStatusSelector',
})

export const terminologySelector = selectorFamily({
  get:
    (setName: string) =>
    ({ get }) => {
      const valueSet = get(terminologyState).find((x) => x.setName === setName)
      return valueSet ? valueSet.value : []
    },
  key: 'terminologSelector',
})

export const terminologyWithRelationsSelector = selectorFamily({
  get:
    (setName: string) =>
    ({ get }) => {
      const valueSet = get(terminologyWithRelationsState).find((x) => x.setName === setName)
      return valueSet ? valueSet.value : []
    },
  key: 'terminologyWithRelationsSelector',
})
