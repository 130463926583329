import { useCallback, useEffect, useMemo, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISChip from 'common/components/form/MISChip'
import { getNameFromCodedConcept } from 'common/utils/CodedConceptUtils'
import { personnelDetailsState } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import { PersonnelJobFunctionDTO } from 'services/openapi'
import {
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
  MIS_PROVIDER_ROLE_TYPES,
} from 'services/terminologyConstants'
import './ProviderStaffHeader.scss'

const ProviderStaffHeader = () => {
  const personnelData = useRecoilValue(personnelDetailsState)
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const jobFunctionOptions = useRecoilValue(terminologySelector(MIS_PROVIDER_ROLE_TYPES))
  const [primaryJobFunction, setPrimaryJobFunction] = useState<PersonnelJobFunctionDTO | null>(null)
  const [headerChips, setHeaderChips] = useState<string[]>([])
  const headerChipsSortOrder = useMemo(() => ['Inactive', 'Internal', 'External', 'Staff'], [])

  const getTitle = useCallback(() => {
    if (!personnelData?.names) return ''
    const preferredName =
      personnelData?.names.find((name) => name.preferredName === true) || personnelData?.names[0]
    const { firstName, lastName, middleName, prefix, suffix } = preferredName
    const fullNameWithoutSuffix = [prefix, firstName, middleName, lastName]
      .filter(Boolean)
      .join(' ')
      .trim()
    return suffix ? `${fullNameWithoutSuffix}, ${suffix}` : fullNameWithoutSuffix
  }, [personnelData?.names])

  useEffect(() => {
    let primaryJF
    if (personnelData?.jobFunctions) {
      headerChips.splice(0, headerChips.length) // reset

      personnelData?.jobFunctions.forEach((jf: PersonnelJobFunctionDTO) => {
        if (jf.isPrimary) {
          primaryJF = jf
        }
        if (jf.isInternal && !headerChips.includes('Internal')) {
          headerChips.push('Internal')
        }
        if (!jf.isInternal && !headerChips.includes('External')) {
          headerChips.push('External')
        }
        if (jf.isStaff && !headerChips.includes('Staff')) {
          headerChips.push('Staff')
        }
        if (!jf.isActive && !headerChips.includes('Inactive')) {
          headerChips.push('Inactive')
        }
      })

      if (!primaryJF) {
        primaryJF = personnelData?.jobFunctions[0]
      }
      setPrimaryJobFunction(primaryJF)
      setHeaderChips(headerChips)
    }
  }, [personnelData?.jobFunctions, headerChips])

  return (
    <Box className="provider-staff-record-header">
      <Box className="panel left">
        <Avatar
          alt="Avatar Image"
          className="avatar"
          src="https://wallpapercave.com/dwp2x/wp9061276.jpg"
        >
          <PersonIcon className="icon" />
        </Avatar>
      </Box>
      <Box className="panel right">
        <Stack spacing={0} sx={{}}>
          <Typography className="title">{getTitle()}</Typography>
          {headerChipsSortOrder
            .filter((val) => headerChips.includes(val))
            .map((val, index) => {
              return (
                <MISChip
                  key={index}
                  label={val}
                  sx={{ mr: 1 }}
                  variant={val !== 'Inactive' ? 'outlined' : 'filled'}
                />
              )
            })}
        </Stack>

        <Box className="key-value-container">
          <Box className="key-value-column">
            <Typography className="key-title">
              <strong>Gender (Public): </strong>
              {personnelData?.gender &&
                genderOptions?.find((option) => option.code === personnelData?.gender?.code)?.name}
            </Typography>
            <Typography className="key-title bottom">
              <strong>Pronouns: </strong>
              {personnelData?.pronoun &&
                pronounsOptions?.find((option) => option.code === personnelData?.pronoun?.code)
                  ?.name}
            </Typography>
          </Box>
          <Box className="key-value-column">
            <Typography className="key-title">
              <strong>Primary Job Function: </strong>
              {primaryJobFunction &&
                getNameFromCodedConcept(jobFunctionOptions, primaryJobFunction.jobFunction)}
            </Typography>
            <Typography className="key-title bottom">
              <strong>User ID: </strong>
              {/* TODO: Get USER ID */}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProviderStaffHeader
