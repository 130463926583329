import { AddCircleOutline, Delete, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'

const MISAccordionContainer = ({
  addLabel,
  collapsible = true,
  disableDelete,
  emptyDataMessage = '',
  errors = [],
  onAddRow,
  onChangeRow,
  onCollapseRow,
  onRemoveRow,
  rowForm,
  rowHeader,
  rows = [],
}: any) => {
  return (
    <Box>
      {rows.length !== 0 &&
        errors &&
        errors.map((error: string, index: number) => (
          <Alert
            className="error"
            key={index}
            severity="error"
            style={{ marginBottom: GLOBAL.MARGIN_XS }}
          >
            {error}
          </Alert>
        ))}
      {rows.length === 0 && (
        <Typography style={{ textAlign: 'center' }}>
          <em>{emptyDataMessage}</em>
        </Typography>
      )}
      {rows.map((row: any, rowIndex: number) => (
        <Box key={rowIndex}>
          {rowIndex === 0 && errors.length === 0 && <Divider sx={{ mt: GLOBAL.MARGIN_XS }} />}
          <Accordion
            disableGutters
            expanded={collapsible ? !row.isCollapsed : true}
            sx={{ '& hr': { mx: 0.5 }, boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    display: collapsible ? 'block' : 'none',
                    margin: GLOBAL.MARGIN_XS,
                    pointerEvents: 'auto',
                  }}
                />
              }
              onClick={() => onCollapseRow(rowIndex)}
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                flexDirection: 'row-reverse',
                padding: `0 ${GLOBAL.MARGIN_XS}`,
              }}
            >
              <Typography sx={{ alignSelf: 'center', width: '100%' }} variant="body2">
                {rowHeader(row, rowIndex, onChangeRow)}
              </Typography>
              <AccordionActions sx={{ flex: 'auto' }}>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    disabled={disableDelete !== undefined ? disableDelete(rowIndex) : false}
                    onClick={(event) => {
                      event.stopPropagation()
                      onRemoveRow(rowIndex)
                    }}
                    size="small"
                    sx={{ pointerEvents: 'auto' }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </AccordionActions>
            </AccordionSummary>
            <AccordionDetails>{rowForm(row, rowIndex, onChangeRow)}</AccordionDetails>
          </Accordion>
        </Box>
      ))}
      {addLabel && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MISButton
              color="primary"
              onClick={onAddRow}
              size="large"
              startIcon={<AddCircleOutline />}
              variant="text"
            >
              {addLabel}
            </MISButton>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default MISAccordionContainer
