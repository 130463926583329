/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodeSystemSearchResultDto } from '../models/CodeSystemSearchResultDto'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class CodeSystemControllerService {
  /**
   * @param codeSystemOid
   * @param searchText
   * @param includeRelatedConcepts
   * @returns CodeSystemSearchResultDto OK
   * @throws ApiError
   */
  public static searchCodeSystem(
    codeSystemOid: string,
    searchText: string,
    includeRelatedConcepts: boolean = false
  ): CancelablePromise<CodeSystemSearchResultDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-terminology-service/v1/code-systems/{codeSystemOid}/coded-concepts/search',
      path: {
        codeSystemOid: codeSystemOid,
      },
      query: {
        searchText: searchText,
        includeRelatedConcepts: includeRelatedConcepts,
      },
    })
  }
}
