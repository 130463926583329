/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptableUseAgreement } from '../models/AcceptableUseAgreement'
import type { PageAcceptableUseAgreementSummaryDTO } from '../models/PageAcceptableUseAgreementSummaryDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AcceptableUseAgreementControllerService {
  /**
   * @param id
   * @returns AcceptableUseAgreement OK
   * @throws ApiError
   */
  public static getAcceptableUseAgreementById(
    id: string
  ): CancelablePromise<AcceptableUseAgreement> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns AcceptableUseAgreement OK
   * @throws ApiError
   */
  public static updateAcceptableUseAgreement(
    id: string,
    requestBody: AcceptableUseAgreement
  ): CancelablePromise<AcceptableUseAgreement> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageAcceptableUseAgreementSummaryDTO OK
   * @throws ApiError
   */
  public static listAcceptableUseAgreements(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageAcceptableUseAgreementSummaryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns AcceptableUseAgreement OK
   * @throws ApiError
   */
  public static createAcceptableUseAgreement(
    requestBody: AcceptableUseAgreement
  ): CancelablePromise<AcceptableUseAgreement> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param agreementId
   * @param hasAccepted
   * @returns any OK
   * @throws ApiError
   */
  public static confirmAcceptableUseAgreement(
    agreementId: string,
    hasAccepted: boolean
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses/confirm',
      query: {
        agreementId: agreementId,
        hasAccepted: hasAccepted,
      },
    })
  }

  /**
   * @returns AcceptableUseAgreement OK
   * @throws ApiError
   */
  public static getUnConfirmedAcceptableUseAgreement(): CancelablePromise<AcceptableUseAgreement> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/acceptable-uses/pending',
    })
  }
}
