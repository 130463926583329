import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BusinessLineWithPrograms, DataDomain } from 'services/openapi'

// reducer for permission related stuff
export interface PermissionState {
  businessLines?: BusinessLineWithPrograms[]
  dataDomains?: DataDomain[]
}

const initialState: PermissionState = {}

export const permissionSlice = createSlice({
  initialState,
  name: 'permission',
  reducers: {
    setBusinessLines: (state, action: PayloadAction<BusinessLineWithPrograms[] | undefined>) => {
      state.businessLines = action.payload
    },
    setDataDomains: (state, action: PayloadAction<DataDomain[] | undefined>) => {
      state.dataDomains = action.payload
    },
  },
})

export const { setBusinessLines, setDataDomains } = permissionSlice.actions

export default permissionSlice.reducer
