import React, { ChangeEventHandler, FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {
  FormControl,
  FormControlLabel,
  Grid,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { DateTime } from 'luxon'
import global from 'common/styles/global.scss'
import { formatDateToApi } from 'common/utils/DateUtils'
import misTheme from 'core/styles/theme'
import { getClientAgeString } from 'modules/shared/clientUtils'
import MISCheckbox from './MISCheckbox'
import MISTextField from './MISTextField'

interface DateFieldCustomProps {
  estimated?: boolean
  displayEstimated?: boolean
  estimatedLabel?: string
  displayAge?: boolean
  ageUntil?: string
  inputFormat?: string
  placeholder?: string
  tooltipArrow?: boolean
  tooltipText?: string
  tooltipPlacement?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  error?: boolean
  helperText?: ReactNode
  size?: string
  label?: string
  isDefaultToday?: boolean
  disableFuture?: boolean
  onChangeEstimated?: ChangeEventHandler
  shouldDisableDate?: (date: any) => boolean
}

const MISDatePicker: FC<DateFieldCustomProps & TextFieldProps> = (props: any) => {
  if (props.isDefaultToday && (!props.value || props.value === '')) {
    const todaysDate = new Date()
    todaysDate.setHours(0, 0, 0, 0)
    const todaysDateString = DateTime.fromJSDate(todaysDate).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
    props = { ...props, value: todaysDateString }
    props.onChange?.(todaysDateString)
  }

  const [isInvalid, setIsInvalid] = useState(props.error)
  const [dateHelperText, setDateHelperText] = useState(props?.helperText || '')

  useEffect(() => {
    setIsInvalid(props.error)
    setDateHelperText(props.helperText)
  }, [props.error, props.helperText])

  const isInvalidDate = useCallback(
    (dateUpdate: DateTime | null) => {
      if (props.error && props.helperText) {
        setIsInvalid(props.error)
        setDateHelperText(props.helperText)
        return true
      } else if (props.required && !dateUpdate) {
        setDateHelperText(props.label + ' is required')
        return true
      } else if (dateUpdate && !dateUpdate.isValid) {
        setDateHelperText(`${props.label} must be a valid date`)
        return true
      }
      setDateHelperText('')
      return false
    },
    [props.label, props.required, props.helperText, props.error]
  )

  const formatDateValue = useCallback((date: any) => {
    if (date) {
      return date.indexOf('T') !== -1 ? date : `${date}T00:00`
    }
    return ''
  }, [])

  const renderDateComponent = useCallback(
    (props: any) => {
      const handleDateChange = (event: any) => {
        const isInvalid = isInvalidDate(event)
        setIsInvalid(isInvalid)

        const zonedDateTime = event ? formatDateToApi(event) : null
        props.onChange(zonedDateTime)
      }

      const renderText = (params: any) => {
        return (
          <div>
            {props.tooltipText ? (
              <Tooltip
                arrow={props.tooltipArrow}
                placement={props.tooltipPlacement}
                title={props.tooltipText}
              >
                <MISTextField
                  {...params}
                  error={isInvalid}
                  helperText={dateHelperText || ''}
                  required={props.required}
                />
              </Tooltip>
            ) : (
              <MISTextField
                {...params}
                error={isInvalid}
                helperText={dateHelperText || ''}
                required={props.required}
              />
            )}
          </div>
        )
      }

      return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            {...props}
            disableFuture={props.disableFuture}
            format={props.inputFormat}
            inputFormat={props.inputFormat}
            mask="____-__-__"
            onChange={(e) => handleDateChange(e)}
            renderInput={(params) => renderText(params)}
            value={formatDateValue(props.value)}
          />
        </LocalizationProvider>
      )
    },
    [dateHelperText, isInvalid, isInvalidDate, formatDateValue]
  )

  const isDisplayAge = useCallback(() => {
    return props.displayAge && props.value
  }, [props.displayAge, props.value])

  const getCustomDatePicker = useCallback(
    (props: any) => {
      return (
        <FormControl>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={props.displayEstimated ? 8 : 12}>
              {renderDateComponent(props)}
            </Grid>
            {(props.displayEstimated || isDisplayAge()) && (
              <Grid item sx={{ marginTop: !isDisplayAge() && global.MARGIN_SM }} xs={4}>
                {isDisplayAge() && (
                  <Typography sx={{ marginBottom: `-${global.MARGIN_XS}` }} variant="body1">
                    {getClientAgeString(props.value, props.ageUntil || '')}
                  </Typography>
                )}
                {props.displayEstimated && (
                  <FormControlLabel
                    control={
                      <MISCheckbox
                        checked={props.estimated}
                        disabled={props.disabled}
                        onChange={props.onChangeEstimated}
                        readOnly={props.readOnly}
                      />
                    }
                    label={props.estimatedLabel}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </FormControl>
      )
    },
    [isDisplayAge, renderDateComponent]
  )

  return <ThemeProvider theme={misTheme}>{getCustomDatePicker(props)}</ThemeProvider>
}

MISDatePicker.defaultProps = {
  disabled: false,
  disableFuture: false,
  displayAge: false,
  displayEstimated: false,
  error: false,
  estimated: false,
  estimatedLabel: 'Estimated',
  helperText: '',
  inputFormat: 'yyyy-MMM-dd',
  isDefaultToday: false,
  label: 'Date field',
  onChangeEstimated: () => null,
  placeholder: '',
  readOnly: false,
  required: false,
  size: 'small',
  tooltipArrow: true,
  tooltipPlacement: 'top',
  tooltipText: '',
}

export default MISDatePicker
