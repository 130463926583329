/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientLanguage } from '../models/ClientLanguage'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientLanguageControllerService {
  /**
   * @param clientId
   * @returns ClientLanguage OK
   * @throws ApiError
   */
  public static listClientLanguages(clientId: string): CancelablePromise<Array<ClientLanguage>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/languages',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientLanguage OK
   * @throws ApiError
   */
  public static addLanguagesToClient(
    clientId: string,
    requestBody: Array<ClientLanguage>
  ): CancelablePromise<Array<ClientLanguage>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/languages',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
