import { ReactNode } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import { Grid, Stack, Typography } from '@mui/material'
import MISButton from 'common/components/MISButton'
import './SectionHeader.scss'

interface SectionHeaderAddLabel {
  label: string
  icon: ReactNode
}

interface SectionHeaderSaveLabel {
  label: string
  color: 'primary' | 'secondary'
}

type SectionHeaderProps = {
  addLabel?: string | string[] | SectionHeaderAddLabel[]
  isSubsection?: boolean
  onAdd?: () => void | { (): void }[]
  onAdds?: (() => void)[]
  onSave?: () => void
  onSaves?: (() => void)[]
  saveLabel?: string | string[] | SectionHeaderSaveLabel[]
  subtitle?: string
  title?: string
}

const SectionHeader = ({
  addLabel,
  isSubsection,
  onAdd,
  onAdds,
  onSave,
  onSaves,
  saveLabel,
  subtitle,
  title,
}: SectionHeaderProps) => {
  return (
    <div className={isSubsection ? '' : 'section-header'} data-testid="section-header">
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={6}>
          <Typography component="span" variant={isSubsection ? 'h2' : 'h1'}>
            {title}
          </Typography>
          {subtitle && (
            <>
              <br />
              <Typography component="span" variant="body2">
                {subtitle}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item textAlign="right" xs={6}>
          <Stack direction="row" sx={{ display: 'block' }}>
            {addLabel && typeof addLabel === 'string' && (
              <MISButton
                color="primary"
                onClick={onAdd}
                size="large"
                startIcon={<AddCircleOutline />}
                variant="text"
              >
                {addLabel}
              </MISButton>
            )}
            {Array.isArray(addLabel) &&
              Array.isArray(onAdds) &&
              addLabel.length === onAdds.length &&
              addLabel.map((label, index) => {
                return (
                  <MISButton
                    color="primary"
                    key={index}
                    onClick={() => onAdds[index]()}
                    size="large"
                    startIcon={
                      typeof label === 'string' ? (
                        <AddCircleOutline />
                      ) : (
                        (label as SectionHeaderAddLabel).icon
                      )
                    }
                    variant="text"
                  >
                    {typeof label === 'string' ? label : (label as SectionHeaderAddLabel).label}
                  </MISButton>
                )
              })}
            {saveLabel && typeof saveLabel === 'string' && (
              <MISButton color="primary" onClick={onSave} variant="contained">
                {saveLabel}
              </MISButton>
            )}
            {Array.isArray(saveLabel) &&
              Array.isArray(onSaves) &&
              saveLabel.length === onSaves.length &&
              saveLabel.map((label, index) => {
                return (
                  <MISButton
                    color={
                      typeof label === 'string'
                        ? 'primary'
                        : (label as SectionHeaderSaveLabel).color
                    }
                    key={index}
                    onClick={() => onSaves[index]()}
                    size="large"
                    sx={{ ml: 2 }}
                    variant="contained"
                  >
                    {typeof label === 'string' ? label : (label as SectionHeaderSaveLabel).label}
                  </MISButton>
                )
              })}
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default SectionHeader
