/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityAssociationCreationDTO } from '../models/EntityAssociationCreationDTO'
import type { PagePurposeGroupTerse } from '../models/PagePurposeGroupTerse'
import type { PurposeGroup } from '../models/PurposeGroup'
import type { PurposeGroupAssociation } from '../models/PurposeGroupAssociation'
import type { PurposeGroupAssociationTerse } from '../models/PurposeGroupAssociationTerse'
import type { PurposeGroupTerse } from '../models/PurposeGroupTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PurposeGroupControllerService {
  /**
   * @param id
   * @returns PurposeGroupTerse OK
   * @throws ApiError
   */
  public static getPurposeGroupById(id: string): CancelablePromise<PurposeGroupTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns PurposeGroupTerse OK
   * @throws ApiError
   */
  public static updatePurposeGroup(
    id: string,
    requestBody: PurposeGroup
  ): CancelablePromise<PurposeGroupTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePurposeGroupTerse OK
   * @throws ApiError
   */
  public static listPurposeGroups(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePurposeGroupTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns PurposeGroupTerse OK
   * @throws ApiError
   */
  public static createPurposeGroup(
    requestBody: PurposeGroup
  ): CancelablePromise<PurposeGroupTerse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns PurposeGroupAssociationTerse OK
   * @throws ApiError
   */
  public static getPurposeGroupAssociationsForPurposeGroup(
    id: string
  ): CancelablePromise<Array<PurposeGroupAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups/{id}/purposes',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns PurposeGroupAssociation OK
   * @throws ApiError
   */
  public static addPurposeToPurposeGroup(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<PurposeGroupAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups/{id}/purposes',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param name
   * @param activeOnly
   * @returns PurposeGroupTerse OK
   * @throws ApiError
   */
  public static searchPurposeGroups(
    name: string,
    activeOnly: boolean = false
  ): CancelablePromise<Array<PurposeGroupTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purpose-groups/search',
      query: {
        activeOnly: activeOnly,
        name: name,
      },
    })
  }
}
