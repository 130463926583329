/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientContact } from '../models/ClientContact'
import type { PageClientContact } from '../models/PageClientContact'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientContactControllerService {
  /**
   * @param clientId
   * @returns PageClientContact OK
   * @throws ApiError
   */
  public static getClientContacts(clientId: string): CancelablePromise<PageClientContact> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/contacts',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientContact OK
   * @throws ApiError
   */
  public static putClientContacts(
    clientId: string,
    requestBody: Array<ClientContact>
  ): CancelablePromise<Array<ClientContact>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/contacts',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
