import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from '@mui/icons-material'
import { Divider, Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import debounce from 'lodash/debounce'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISRadio from 'common/components/form/MISRadio'
import GLOBAL from 'common/styles/global.scss'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import {
  PersonnelControllerService,
  PersonnelDTO,
  PersonnelJobFunctionDTO,
  PersonnelNameDTO,
} from 'services/openapi'
import {
  displayChipsUtil,
  evaluateChipsUtil,
  evaluateLabelUtil,
  getFilteredListUtil,
  getSortedListUtil,
} from './StaffAssociationUtils'

export type CustomStaffMemberProps = {
  associationId: string | undefined
  personnel: PersonnelDTO | undefined
  isPrimary?: boolean | undefined
}

type StaffAssociationProps = {
  title?: string
  members: CustomStaffMemberProps[]
  onAdd?: (personnel: PersonnelDTO) => void
  onUpdate?: ((personnelId: string) => void) | undefined
  onRemove?: (associationId: string, index: number) => void
  allowSearch?: boolean
  allowUpdate?: boolean
  displayPrimaryIndicator?: boolean
  displaySectionHeader?: boolean
  fullWidth?: boolean
}

const ENTITY = 'Staff'

const StaffAssociation = ({
  allowSearch = true,
  allowUpdate = false,
  displayPrimaryIndicator = false,
  displaySectionHeader = true,
  fullWidth = false,
  members,
  onAdd,
  onRemove,
  onUpdate,
  title,
}: StaffAssociationProps) => {
  const { t } = useTranslation('common')
  const [providers, setProviders] = useState<PersonnelDTO[]>([])
  const [membersData, setMembersData] = useState<CustomStaffMemberProps[]>([])
  const [searchStr, setSearchStr] = useState<string>('')
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [preferredWarningDialog, setPreferredWarningDialog] = useState({ data: {}, open: false })

  const evaluateLabel = useCallback((names: PersonnelNameDTO[] | undefined) => {
    return evaluateLabelUtil(names)
  }, [])

  const evaluateChips = useCallback((jobFunctions: PersonnelJobFunctionDTO[] | undefined) => {
    return evaluateChipsUtil(jobFunctions)
  }, [])

  const displayChips = useCallback((chips: string[]) => {
    return displayChipsUtil(chips)
  }, [])

  const handleSearch = debounce(
    useCallback(async (searchText) => {
      if (searchText) {
        setProviders([])
        const response = await PersonnelControllerService.searchPersonnel(
          searchText,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          1000000
        )
        if (response.content) {
          setProviders(response.content)
        }
      }
    }, []),
    1500
  )

  const handleCreateRows = useCallback(() => {
    setMembersData(members || [])
  }, [members, setMembersData])

  useEffect(() => {
    handleCreateRows()
  }, [handleCreateRows])

  const onPreferredWarningClose = useCallback(() => {
    setPreferredWarningDialog({ data: {}, open: false })
  }, [])

  const onPreferredWarningSave = useCallback(
    ({ personnelId }: { rowIndex?: number; personnelId?: string }) => {
      setPreferredWarningDialog({ ...preferredWarningDialog, open: false })
      if (personnelId && onUpdate) {
        onUpdate(personnelId)
      }
    },
    [preferredWarningDialog, onUpdate]
  )

  const getSortedList = useCallback((members: CustomStaffMemberProps[]) => {
    return getSortedListUtil(members)
  }, [])

  const getFilteredList = useCallback(
    (providers) => {
      return getFilteredListUtil(providers, membersData)
    },
    [membersData]
  )

  return (
    <>
      {displaySectionHeader && <SectionHeader isSubsection title="Add Provider/Staff" />}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {allowSearch && (
          <Grid item xs={fullWidth ? 12 : 6}>
            <MISAutocomplete
              inputValue={searchStr}
              label={title || 'Search Providers/Staff'}
              onChange={(provider) => {
                if (provider && onAdd) {
                  onAdd(provider)
                }
                setSearchStr('')
              }}
              onInputChange={(value) => {
                handleSearch(value)
                setSearchStr(value)
              }}
              options={getFilteredList(providers)}
              renderOption={(props, provider) => {
                const label = evaluateLabel(provider?.names)
                const chips = evaluateChips(provider?.jobFunctions)
                return (
                  <Stack
                    {...props}
                    direction="row"
                    key={label}
                    sx={{
                      '&.MuiAutocomplete-option': {
                        justifyContent: 'space-between',
                      },
                      cursor: 'pointer',
                      p: 1,
                    }}
                  >
                    <span>{label}</span>
                    <span>{displayChips(chips)}</span>
                  </Stack>
                )
              }}
              value=""
            />
          </Grid>
        )}
        <Divider sx={{ mb: GLOBAL.MARGIN_S, ml: 0, mr: 0 }} />
        <Grid item xs={12}>
          {membersData && (
            <List sx={{ '& hr': { mx: 0.5, my: 2 }, boxShadow: 'none', p: 0 }}>
              {getSortedList(membersData)?.map(
                ({ isPrimary, personnel }: CustomStaffMemberProps, index: number) => {
                  const label = evaluateLabel(personnel?.names)
                  const chips = evaluateChips(personnel?.jobFunctions)
                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        onRemove && (
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation()
                                setDeleteIndex(index)
                              }}
                              size="small"
                              sx={{ pointerEvents: 'auto' }}
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        )
                      }
                      sx={{
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        flexDirection: 'row-reverse',
                        p: 0,
                      }}
                    >
                      <Typography sx={{ alignSelf: 'center', m: 1, width: '100%' }} variant="body2">
                        {allowUpdate && (
                          <MISRadio
                            checked={isPrimary}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              event.stopPropagation()
                              setPreferredWarningDialog({
                                data: {
                                  personnelId: event.target.value,
                                  rowIndex: index,
                                },
                                open: true,
                              })
                            }}
                            value={personnel?.id}
                          />
                        )}
                        <span>{label}</span>
                        {displayPrimaryIndicator && isPrimary && (
                          <span>
                            {' | '}
                            <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>
                              {t('common.primary')}
                            </span>
                          </span>
                        )}
                        {chips.length > 0 && <span> |{displayChips(chips)}</span>}
                      </Typography>
                    </ListItem>
                  )
                }
              )}
            </List>
          )}
        </Grid>
      </Grid>
      <WarningDialog
        entity={ENTITY}
        onCancel={() => onPreferredWarningClose()}
        onSave={() => onPreferredWarningSave(preferredWarningDialog.data)}
        open={preferredWarningDialog.open}
        type={MODALS.PREFERRED_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => {
          setDeleteIndex(-1)
          if (onRemove) {
            onRemove(membersData[deleteIndex]?.associationId || '', deleteIndex)
          }
        }}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </>
  )
}

export default StaffAssociation
