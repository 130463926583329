/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationDTO } from '../models/LocationDTO'
import type { PageLocationDTO } from '../models/PageLocationDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class LocationControllerService {
  /**
   * @param locationId
   * @returns LocationDTO OK
   * @throws ApiError
   */
  public static getHousehold(locationId: string): CancelablePromise<LocationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/location/{locationId}',
      path: {
        locationId: locationId,
      },
    })
  }

  /**
   * @param locationId
   * @param requestBody
   * @returns LocationDTO OK
   * @throws ApiError
   */
  public static updateHousehold(
    locationId: string,
    requestBody: LocationDTO
  ): CancelablePromise<LocationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/location/{locationId}',
      path: {
        locationId: locationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param currentReportsFlag
   * @param concernStatus
   * @param searchText
   * @param addressText
   * @param unitNumber
   * @param unitType
   * @param streetNumber
   * @param streetName
   * @param streetType
   * @param city
   * @param province
   * @param postalCode
   * @param country
   * @param heatingSource
   * @param waterSource
   * @param occupancy
   * @param internetAccess
   * @param healthStatusFlag
   * @param moldStatusFlag
   * @param rodentStatusFlag
   * @param septicStatusFlag
   * @param structuralStatusFlag
   * @param waterStatusFlag
   * @returns PageLocationDTO OK
   * @throws ApiError
   */
  public static findHouseholds(
    currentReportsFlag: boolean,
    concernStatus: string,
    searchText?: string,
    addressText?: string,
    unitNumber?: string,
    unitType?: string,
    streetNumber?: string,
    streetName?: string,
    streetType?: string,
    city?: string,
    province?: string,
    postalCode?: string,
    country?: string,
    heatingSource?: string,
    waterSource?: string,
    occupancy?: string,
    internetAccess?: string,
    healthStatusFlag?: string,
    moldStatusFlag?: string,
    rodentStatusFlag?: string,
    septicStatusFlag?: string,
    structuralStatusFlag?: string,
    waterStatusFlag?: string
  ): CancelablePromise<PageLocationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/location',
      query: {
        searchText: searchText,
        addressText: addressText,
        unitNumber: unitNumber,
        unitType: unitType,
        streetNumber: streetNumber,
        streetName: streetName,
        streetType: streetType,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
        heatingSource: heatingSource,
        waterSource: waterSource,
        occupancy: occupancy,
        internetAccess: internetAccess,
        currentReportsFlag: currentReportsFlag,
        concernStatus: concernStatus,
        healthStatusFlag: healthStatusFlag,
        moldStatusFlag: moldStatusFlag,
        rodentStatusFlag: rodentStatusFlag,
        septicStatusFlag: septicStatusFlag,
        structuralStatusFlag: structuralStatusFlag,
        waterStatusFlag: waterStatusFlag,
      },
    })
  }
}
