import { useEffect } from 'react'
import { AuthContextProps } from 'react-oidc-context'
interface SignInProps {
  auth: AuthContextProps
}

const SignIn = ({ auth }: SignInProps) => {
  useEffect(() => {
    auth.signinPopup({ login_hint: 'test' })
  })

  return (
    <div
      style={{
        backgroundRepeat: 'round',
        flexGrow: 1,
        minWidth: '90vw',
      }}
    />
  )
}

export { SignIn }
