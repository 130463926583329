import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.snow.css'
import './Charting.scss'
import 'rc-dock/dist/rc-dock.css'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UnprivilegedEditor } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { DeltaStatic, Sources } from 'quill'
import DockLayout, { LayoutData } from 'rc-dock'
import { useRecoilState, useSetRecoilState } from 'recoil'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import global from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import useChartingEntries from 'modules/shared/hooks/useChartingEntries'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import { ErrorType } from 'modules/shared/utils'
import {
  chartingEditorProgressState,
  chartingFieldsProgressState,
  isDisplayInProgressState,
} from 'recoil/charting'
import { chartingEntryIdState } from 'recoil/lastupdated'
import {
  ChartingEntryControllerService,
  ChartingEntryDTO,
  ClientControllerService,
  ClientDTO,
  EncounterServiceTemplateDTO,
  ProgramTerse,
} from 'services/openapi'
import { setValidationActive } from 'store/reducers/charting'
import { setClientDetails } from 'store/reducers/client'
import { selectClientDetails } from 'store/selectors/client'
import ChartingEntries from './components/ChartingEntries'
import ChartingFields from './components/ChartingFields'
import ChartingServiceFields from './components/ChartingServiceFields'
import Editor, { EMentionTypes, NavigationDetail } from './components/editor/Editor'
import HistoricalPanel from './components/historical/HistoricalPanel'
import { getMetadataForTemplate } from './components/historical/utils'

export interface TValue {
  id: number | string
  disabled?: boolean
  value: string
  type?: string
}

const Charting = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackError } = useSnack()
  const clientDetails = useSelector(selectClientDetails)
  const [lastUpdatedChartingId, setLastUpdatedChartingId] = useRecoilState(chartingEntryIdState)
  useClientDetails(clientId)
  const [chartingEditorProgress, setChartingEditorProgress] = useRecoilState(
    chartingEditorProgressState
  )
  const [chartingFieldsProgress, setChartingFieldsProgress] = useRecoilState(
    chartingFieldsProgressState
  )
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)
  const setIsDisplayInProgress = useSetRecoilState(isDisplayInProgressState)

  const [menuItem, setMenuItem] = useState<string | undefined>(undefined)
  const [chartingData, setChartingData] = useState<ChartingEntryDTO>({
    duration: undefined,
    id: undefined,
    primaryProvider: undefined,
    serviceDateTime: undefined,
    status: undefined,
    statusComment: undefined,
    statusReason: undefined,
  })
  const [programs, setPrograms] = useState<TValue[]>([])
  const [services, setServices] = useState<TValue[]>([])
  const [applyFilters, setApplyFilters] = useState(false)
  const [filters, setFilters] = useState<FilterProps[]>([])
  const [isDisplayRevHistory, setIsDisplayRevHistory] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    entity: '',
    index: -1,
  })
  const { chartingEntries, providers, setChartingEntries, sortData } = useChartingEntries(
    clientId || '',
    filters,
    applyFilters
  )
  const [errors, setErrors] = useState<ErrorType[]>([])

  const chartDataRef = useRef<{ delta: DeltaStatic }>()
  const chartSelectedIdRef = useRef<string | undefined>()
  const editorRef = useRef<{
    getClientMentions: () => string[] | undefined
    setClientMentions: (clientMentions: string[] | undefined) => void
    getClientIdContext: () => string | undefined
    setClientIdContext: (clientId: string | undefined) => void
    getContents: () => DeltaStatic | undefined
    setContents: (contents: string | DeltaStatic) => void
    setServiceDateTime: (setServiceDateTime: string | undefined) => void
    setNavigationDetails: (navigationDetail: NavigationDetail | undefined) => void
    getNavigationDetails: () => NavigationDetail | undefined
  }>()
  const dockLayoutRef = useRef<DockLayout | null>(null)

  useEffect(() => {
    editorRef.current?.setClientIdContext(clientId)
  }, [clientId])

  const resetCharting = useCallback(() => {
    chartSelectedIdRef.current = undefined
    editorRef.current?.setContents('')
    setPrograms([])
    setServices([])
    setChartingData({
      duration: undefined,
      id: undefined,
      primaryProvider: undefined,
      serviceDateTime: undefined,
      status: undefined,
      statusComment: undefined,
      statusReason: undefined,
    })
    dispatch(setValidationActive(false))
  }, [dispatch])

  useEffect(() => {
    setMenuItem(seletedMenuItem)
    if (seletedMenuItem !== 'navigation.left-nav-menu.client.charting.in-progress') {
      editorRef.current?.setClientMentions(undefined)
    }
    const navigationDetails = editorRef.current?.getNavigationDetails()
    if (navigationDetails) {
      const updatedNavigationdetail = { ...navigationDetails, activeMenuItem: seletedMenuItem }
      editorRef.current?.setNavigationDetails(updatedNavigationdetail)
    } else {
      editorRef.current?.setNavigationDetails({ activeMenuItem: seletedMenuItem, fromMenuItem: '' })
    }
    if (
      menuItem !== seletedMenuItem &&
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
        (seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas' &&
          navigationDetails?.fromMenuItem ===
            'navigation.left-nav-menu.client.charting.in-progress'))
    ) {
      //this is required because recoil needs a tick in order to save and then publish the changes onto the editorref in this case, please dont remove this
      setTimeout(() => {
        setMenuItem(seletedMenuItem)
        if (chartingEditorProgress) {
          const { chartingEntryData } = chartingEditorProgress
          const json = chartingEntryData
          editorRef.current?.setContents(json?.delta || '')
          setPrograms(json?.programs || [])
          setServices(json?.services || [])
          setChartingData({
            duration: chartingFieldsProgress?.duration,
            id: chartingFieldsProgress?.id,
            primaryProvider: chartingFieldsProgress?.primaryProvider,
            serviceDateTime: chartingFieldsProgress?.serviceDateTime,
            status: chartingFieldsProgress?.status,
            statusComment: chartingFieldsProgress?.statusComment,
            statusReason: chartingFieldsProgress?.statusReason,
          })
        } else {
          resetCharting()
        }
      }, 0)
    } else if (menuItem !== seletedMenuItem) {
      setMenuItem(seletedMenuItem)
      resetCharting()
    }
  }, [
    chartingEditorProgress,
    chartingFieldsProgress?.duration,
    chartingFieldsProgress?.id,
    chartingFieldsProgress?.primaryProvider,
    chartingFieldsProgress?.serviceDateTime,
    chartingFieldsProgress?.status,
    chartingFieldsProgress?.statusComment,
    chartingFieldsProgress?.statusReason,
    menuItem,
    resetCharting,
    seletedMenuItem,
  ])

  const setChartingDataHandler = useCallback(
    (data: ChartingEntryDTO) => {
      setChartingData(data)
      const chartingData = {
        duration: data?.duration || chartingFieldsProgress?.duration,
        id: data?.id || chartingFieldsProgress?.id,
        primaryProvider: data?.primaryProvider || chartingFieldsProgress?.primaryProvider,
        serviceDateTime: data?.serviceDateTime || chartingFieldsProgress?.serviceDateTime,
        status: data?.status || chartingFieldsProgress?.status,
        statusComment: data?.statusComment || chartingFieldsProgress?.statusComment,
        statusReason: data?.statusReason || chartingFieldsProgress?.statusReason,
      }
      setChartingFieldsProgress(chartingData)
    },
    [chartingFieldsProgress, setChartingFieldsProgress]
  )
  const storeEditorData = useCallback(() => {
    let programs: TValue[] = []
    let services: TValue[] = []
    const content = editorRef.current?.getContents()
    if (content) {
      const mentions = content
        .filter((deltaEntry) => (deltaEntry.insert ? deltaEntry.insert.mention != null : false))
        .map((deltaEntry) => deltaEntry.insert.mention)
      programs = mentions
        .filter((mention) => mention.type === EMentionTypes.Program)
        .filter(
          (entry, index, array) => array.findIndex((val) => val.value === entry.value) === index
        )
      services = mentions
        .filter((dataEntry) => dataEntry.type === EMentionTypes.Service)
        .filter(
          (entry, index, array) => array.findIndex((val) => val.value === entry.value) === index
        )
    }
    const updatedChartingData = { ...chartDataRef.current, programs, services }
    const chartingEntryData = {
      ...JSON.parse(JSON.stringify(updatedChartingData)),
    }
    const chartEntryDTO = {
      chartingEntryData,
      status: {
        code: 'COMPLETE',
        codeSystemOid: '2.16.840.1.113883.3.1019.1.8',
      },
    }
    setChartingEditorProgress(chartEntryDTO)
  }, [setChartingEditorProgress])

  const handleChange = useCallback(
    async (_value: string, _delta: DeltaStatic, _source: Sources, editor: UnprivilegedEditor) => {
      const content = editor.getContents()
      const mentions = content
        .filter((deltaEntry) => (deltaEntry.insert ? deltaEntry.insert.mention != null : false))
        .map((deltaEntry) => deltaEntry.insert.mention)
      const programs = mentions
        .filter((mention) => mention.type === EMentionTypes.Program)
        .filter(
          // filter duplicates
          (entry, index, array) => array.findIndex((val) => val.value === entry.value) === index
        )
      setPrograms(programs)
      const services = mentions
        .filter((dataEntry) => dataEntry.type === EMentionTypes.Service)
        .filter(
          // filter duplicates
          (entry, index, array) => array.findIndex((val) => val.value === entry.value) === index
        )
      setServices(services)
      const navigationDetails = editorRef.current?.getNavigationDetails()
      const activeItemMenu = navigationDetails?.activeMenuItem
      chartDataRef.current = { delta: content }
      if (activeItemMenu !== 'navigation.left-nav-menu.client.charting') {
        storeEditorData()
      }
      // if client is deleted on in progress and it was navigated via blank canvas
      // then navigated to blank canvas
      if (activeItemMenu === 'navigation.left-nav-menu.client.charting.in-progress') {
        if (navigationDetails?.fromMenuItem === 'navigation.left-nav-menu.charting.blank-canvas') {
          const clients = mentions.filter((dataEntry) => dataEntry.type === EMentionTypes.Client)
          if (!clients || clients.length === 0) {
            setSeletedMenuItem('navigation.left-nav-menu.charting.blank-canvas')
            navigate(`/charting`)
            const updatedNavigationDetails = {
              ...navigationDetails,
              fromMenuItem: 'navigation.left-nav-menu.client.charting.in-progress',
            }
            editorRef.current?.setNavigationDetails(updatedNavigationDetails)
          } else {
            const clientIdInContext = editorRef.current?.getClientIdContext()
            const entryClientIdMentions = mentions
              .filter((dataEntry) => dataEntry.type === EMentionTypes.Client)
              .filter(
                // filter duplicates
                (entry, index, array) =>
                  array.findIndex((val) => val.value === entry.value) === index
              )
              .map((c) => c.id)
            const exists = entryClientIdMentions.indexOf(clientIdInContext) !== -1
            if (!exists) {
              const clientIdMentions = editorRef.current?.getClientMentions()
              const newClientId = clientIdMentions?.shift()
              if (newClientId) {
                const resp = await ClientControllerService.getClient(newClientId)
                setIsDisplayInProgress(true)
                dispatch(setClientDetails(resp))
                setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
                navigate(`/clients/client-record/${newClientId}/in-progress`)
              }
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSeletedMenuItem, storeEditorData]
  )

  const handleDeleteService = useCallback((index: number) => {
    setDeleteConfirmationDialog({
      entity: 'service',
      index: index,
    })
  }, [])

  const handleDeleteProgram = useCallback((index: number) => {
    setDeleteConfirmationDialog({
      entity: 'program',
      index: index,
    })
  }, [])

  const onDeleteWarningClose = useCallback(() => {
    setDeleteConfirmationDialog({
      entity: '',
      index: -1,
    })
  }, [])

  const deleteService = useCallback(
    (index: number) => {
      const content = editorRef.current?.getContents()
      if (content) {
        const updatedContent = content.filter((deltaEntry) =>
          deltaEntry.insert.mention
            ? !(
                deltaEntry.insert.mention.type === EMentionTypes.Service &&
                deltaEntry.insert.mention.value === services[index].value
              )
            : true
        )
        editorRef.current?.setContents(JSON.parse(JSON.stringify(updatedContent)))
      }
    },
    [services]
  )

  const deleteProgram = useCallback(
    (index: number) => {
      const content = editorRef.current?.getContents()
      if (content) {
        const updatedContent = content.filter((deltaEntry) =>
          deltaEntry.insert.mention
            ? !(
                deltaEntry.insert.mention.type === EMentionTypes.Program &&
                deltaEntry.insert.mention.value === programs[index].value
              )
            : true
        )
        editorRef.current?.setContents(JSON.parse(JSON.stringify(updatedContent)))
      }
    },
    [programs]
  )

  const onDeleteWarningSave = useCallback(() => {
    deleteConfirmationDialog.entity === 'service'
      ? deleteService(deleteConfirmationDialog.index)
      : deleteProgram(deleteConfirmationDialog.index)

    setDeleteConfirmationDialog({
      entity: '',
      index: -1,
    })
  }, [
    deleteConfirmationDialog.entity,
    deleteConfirmationDialog.index,
    deleteService,
    deleteProgram,
  ])

  const handleSelectChart = useCallback(
    (chartingEntryDTO: ChartingEntryDTO, duplicate?: boolean, isChartRevHistory?: boolean) => {
      setErrors([])
      dispatch(setValidationActive(false))
      const { chartingEntryData, id } = chartingEntryDTO
      const json = chartingEntryData
      if (duplicate) chartSelectedIdRef.current = undefined
      else chartSelectedIdRef.current = id
      editorRef.current?.setContents(json?.delta || '')
      setPrograms(json?.programs || [])
      setServices(json?.services || [])
      setChartingData(chartingEntryDTO)
      if (isChartRevHistory) {
        setIsDisplayRevHistory(true)
      } else {
        setIsDisplayRevHistory(false)
      }
    },
    [dispatch]
  )

  const handleSelectChartRevHistory = useCallback(
    (data: ChartingEntryDTO, isLatestVersion: boolean) => {
      let chartingDTO = data
      if (isLatestVersion && chartingEntries) {
        chartingDTO = chartingEntries.find((dto) => dto.id === data.id) || data
      }
      handleSelectChart(chartingDTO, false, !isLatestVersion)
      setChartingDataHandler(chartingDTO)
      setIsDisplayRevHistory(!isLatestVersion)
    },
    [chartingEntries, handleSelectChart, setChartingDataHandler, setIsDisplayRevHistory]
  )

  // need to make is async by settimeout otherwise it executes before recoil is updated,
  // works with async wait also. Also tried giving window.location.pathname
  //as dependency, but it doesn't work with it.
  useEffect(() => {
    setTimeout(() => {
      if (seletedMenuItem === 'navigation.left-nav-menu.client.charting') {
        if (chartingEntries && chartingEntries?.length > 0) {
          const selectedId = lastUpdatedChartingId || chartingEntries[0]?.id
          const selectedChartingEntry = chartingEntries?.find(
            (chartingEntry) => chartingEntry.id === selectedId
          )
          handleSelectChart(selectedChartingEntry || chartingEntries[0])
        } else {
          resetCharting()
        }
      }
    }, 500)
  }, [chartingEntries, handleSelectChart, lastUpdatedChartingId, resetCharting, seletedMenuItem])

  const handleSelectMention = useCallback(
    async (item: ClientDTO | ProgramTerse | EncounterServiceTemplateDTO, type: EMentionTypes) => {
      switch (type) {
        case EMentionTypes.Client:
          try {
            const resp = await ClientControllerService.getClient(item.id || '')
            const navigationDetails = editorRef.current?.getNavigationDetails()
            const activeItemMenu = navigationDetails?.activeMenuItem
            const fromMenuItem = navigationDetails?.fromMenuItem
            if (activeItemMenu !== 'navigation.left-nav-menu.client.charting') {
              storeEditorData()
            }
            if (activeItemMenu === 'navigation.left-nav-menu.charting.blank-canvas') {
              setIsDisplayInProgress(true)
              dispatch(setClientDetails(resp))
              setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
              navigate(`/clients/client-record/${item.id}/in-progress`)
              const updatedNavigationDetails = navigationDetails
                ? {
                    ...navigationDetails,
                    fromMenuItem: 'navigation.left-nav-menu.charting.blank-canvas',
                  }
                : {
                    activeMenuItem: '',
                    fromMenuItem: 'navigation.left-nav-menu.charting.blank-canvas',
                  }
              editorRef.current?.setNavigationDetails(updatedNavigationDetails)
            }
            if (
              activeItemMenu === 'navigation.left-nav-menu.client.charting.in-progress' &&
              fromMenuItem === 'navigation.left-nav-menu.charting.blank-canvas'
            ) {
              const clientIdMentionList = editorRef.current?.getClientMentions() || []
              if (item.id) {
                const exists = clientIdMentionList.indexOf(item.id) !== -1
                if (!exists) {
                  const updatedClientIdList = [...clientIdMentionList, item.id]
                  editorRef.current?.setClientMentions(updatedClientIdList)
                }
              }
            }
          } catch (error) {
            handleApiError(error)
          }
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleApiError, setSeletedMenuItem, storeEditorData]
  )

  const isDisplaySaveButton = useCallback(() => {
    if (seletedMenuItem === 'navigation.left-nav-menu.client.charting' && isDisplayRevHistory) {
      return false
    }
    return (
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' &&
        chartSelectedIdRef.current) ||
      seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress'
    )
  }, [isDisplayRevHistory, seletedMenuItem])

  const isDisplayCancelButton = useCallback(() => {
    return seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress'
  }, [seletedMenuItem])

  const handleCancel = useCallback(() => {
    setIsDisplayInProgress(false)
    setChartingEditorProgress(undefined)
    setChartingFieldsProgress(undefined)
    editorRef.current?.setClientMentions(undefined)
    setSeletedMenuItem('navigation.left-nav-menu.client.charting')
    navigate(`/clients/client-record/${clientId}/charting`)
  }, [
    clientId,
    navigate,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
  ])

  const handleSubmit = useCallback(async () => {
    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      if (!chartingData?.serviceDateTime) {
        isValid = false
        errors.push({
          field: `charting-service-date-time`,
          message: t('charting.validation.charting-service-date-time-required'),
        })
      }
      if (!chartingData?.primaryProvider) {
        isValid = false
        errors.push({
          field: `charting-primary-provider`,
          message: t('charting.validation.charting-primary-provider-required'),
        })
      }
      if (chartingData.status?.code === 'COMPLETE' || chartingData.status?.code === 'REVISED')
        chartDataRef.current?.delta.forEach((eachDelta) => {
          const templateBlot = eachDelta.insert?.['template-blot']
          if (templateBlot?.templateName) {
            if (templateBlot.templateName === 'AllergyTemplate') {
              if (
                (!templateBlot.templateData?.allergenOther &&
                  !templateBlot.templateData?.allergen) ||
                (templateBlot.templateData?.allergenOther &&
                  !templateBlot.templateData?.allergenSpecified)
              ) {
                isValid = false
                errors.push({ field: 'Allergen', message: 'Allergen' })
              }
              if (!templateBlot.templateData?.type) {
                isValid = false
                errors.push({ field: 'Type', message: 'Type' })
              }
            } else {
              const metadata = getMetadataForTemplate(templateBlot.templateName)
              if (metadata) {
                const keys = Object.keys(metadata)
                const requiredFields = keys.filter((key) => metadata[key].required)
                requiredFields.forEach((requiredField) => {
                  if (!templateBlot.templateData?.[requiredField]) {
                    isValid = false
                    errors.push({
                      field: metadata[requiredField].label,
                      message: metadata[requiredField].label,
                    })
                  }
                })
              }
            }
          }
        })
      return { errors, isValid }
    }

    const saveChartingEntries = async () => {
      if (clientId) {
        // filter out form builder template layout from charting data
        if (chartDataRef.current) {
          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        if (seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress') {
          let response
          try {
            response = await ChartingEntryControllerService.createChartingEntry(clientId, {
              chartingEntryData: { ...chartDataRef.current, programs, services },
              duration: chartingData?.duration,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: chartingData?.status,
            })
          } catch (err) {
            handleApiError(err)
          }
          setLastUpdatedChartingId(response.id as string)
          await setChartingEntries(sortData([response, ...(chartingEntries || [])]))
          setChartingEditorProgress(undefined)
          setChartingFieldsProgress(undefined)
          editorRef.current?.setClientMentions(undefined)
          setIsDisplayInProgress(false)
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          navigate(`/clients/client-record/${clientId}/charting`)
          const navigationDetails = editorRef.current?.getNavigationDetails()
          if (navigationDetails) {
            const updatedNavigationdetail = { ...navigationDetails, fromMenuItem: '' }
            editorRef.current?.setNavigationDetails(updatedNavigationdetail)
          }
        } else if (chartSelectedIdRef.current) {
          let response
          try {
            response = await ChartingEntryControllerService.updateChartingEntry(
              clientId,
              chartSelectedIdRef.current,
              {
                chartingEntryData: {
                  ...chartDataRef.current,
                  programs,
                  services,
                },
                duration: chartingData?.duration,
                primaryProvider: chartingData?.primaryProvider,
                serviceDateTime: chartingData?.serviceDateTime,
                status: chartingData?.status,
                statusComment: chartingData.statusComment,
                statusReason: chartingData.statusReason,
              }
            )
          } catch (err) {
            handleApiError(err)
          }
          setLastUpdatedChartingId(response.id as string)
          await setChartingEntries(
            sortData(
              chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) || []
            )
          )
        }
      }
    }

    const { errors, isValid } = validate()
    if (isValid) {
      saveChartingEntries()
      setErrors([])
      dispatch(setValidationActive(false))
    } else {
      setErrors(errors)
      dispatch(setValidationActive(true))
      showSnackError(t('charting.templates.mandatory-fields-missing'))
    }
  }, [
    chartingData?.serviceDateTime,
    chartingData?.primaryProvider,
    chartingData.status,
    chartingData?.duration,
    chartingData.statusComment,
    chartingData.statusReason,
    t,
    dispatch,
    clientId,
    seletedMenuItem,
    setLastUpdatedChartingId,
    setChartingEntries,
    sortData,
    chartingEntries,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
    navigate,
    programs,
    services,
    handleApiError,
    showSnackError,
  ])

  const handleStateChange = useCallback(
    async (data: ChartingEntryDTO) => {
      const validate = () => {
        let isValid = true
        const errors: ErrorType[] = []
        if (!chartingData?.serviceDateTime) {
          isValid = false
          errors.push({
            field: `charting-service-date-time`,
            message: t('charting.validation.charting-service-date-time-required'),
          })
        }
        if (!chartingData?.primaryProvider) {
          isValid = false
          errors.push({
            field: `charting-primary-provider`,
            message: t('charting.validation.charting-primary-provider-required'),
          })
        }
        if (data.status?.code === 'COMPLETE' || data.status?.code === 'REVISED')
          chartDataRef.current?.delta.forEach((eachDelta) => {
            const templateBlot = eachDelta.insert?.['template-blot']

            if (templateBlot?.templateName) {
              if (templateBlot.templateName === 'AllergyTemplate') {
                if (
                  (!templateBlot.templateData?.allergenOther &&
                    !templateBlot.templateData?.allergen) ||
                  (templateBlot.templateData?.allergenOther &&
                    !templateBlot.templateData?.allergenSpecified)
                ) {
                  isValid = false
                  errors.push({ field: 'Allergen', message: 'Allergen' })
                }
                if (!templateBlot.templateData?.type) {
                  isValid = false
                  errors.push({ field: 'Type', message: 'Type' })
                }
              } else {
                const metadata = getMetadataForTemplate(templateBlot.templateName)
                if (metadata) {
                  const keys = Object.keys(metadata)
                  const requiredFields = keys.filter((key) => metadata[key].required)
                  requiredFields.forEach((requiredField) => {
                    if (!templateBlot.templateData?.[requiredField]) {
                      isValid = false
                      errors.push({
                        field: metadata[requiredField].label,
                        message: metadata[requiredField].label,
                      })
                    }
                  })
                }
              }
            }
          })
        return { errors, isValid }
      }
      const { errors, isValid } = validate()
      if (isValid) {
        setChartingData(data)
        setErrors([])
        dispatch(setValidationActive(false))
      } else {
        setErrors(errors)
        dispatch(setValidationActive(true))
        showSnackError(t('charting.templates.mandatory-fields-missing'))
        return
      }
      if (clientId && chartSelectedIdRef.current) {
        // filter out form builder template layout from charting data
        if (chartDataRef.current) {
          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        let response
        try {
          response = await ChartingEntryControllerService.updateChartingEntry(
            clientId,
            chartSelectedIdRef.current,
            {
              chartingEntryData: {
                ...chartDataRef.current,
                programs,
                services,
              },
              duration: chartingData?.duration,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: data?.status,
              statusComment: data.statusComment,
              statusReason: data.statusReason,
            }
          )
        } catch (err) {
          handleApiError(err)
        }
        setLastUpdatedChartingId(response.id as string)
        await setChartingEntries(
          sortData(
            chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) || []
          )
        )
      }
    },
    [
      clientId,
      chartingData?.serviceDateTime,
      chartingData?.primaryProvider,
      chartingData?.duration,
      t,
      dispatch,
      showSnackError,
      setLastUpdatedChartingId,
      setChartingEntries,
      sortData,
      chartingEntries,
      programs,
      services,
      handleApiError,
    ]
  )

  const editor = useMemo(
    () => (
      <Editor onChange={handleChange} onMentionResultSelect={handleSelectMention} ref={editorRef} />
    ),
    [handleChange, handleSelectMention]
  )

  const DockPanelTabs = {
    ChartingDocument: {
      cached: true,
      content: (
        <div className="charting-view" style={{ overflow: 'auto' }}>
          <div className="charting-content-view">
            <Box
              sx={{
                backgroundColor: global.WHITE,
                borderRadius: '8px',
                pl: 3,
                pr: 3,
                pt: 3,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isDisplaySaveButton() && (
                  <Box display="flex">
                    <MISButton color="primary" onClick={handleSubmit} variant="contained">
                      {t('common.button.save')}
                    </MISButton>
                    {isDisplayCancelButton() && (
                      <MISButton
                        color="secondary"
                        onClick={handleCancel}
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                      >
                        {t('common.button.cancel')}
                      </MISButton>
                    )}
                  </Box>
                )}
              </div>

              <ChartingFields
                chartingData={chartingData}
                errors={errors}
                onChangeChartingData={setChartingDataHandler}
                onChangeState={handleStateChange}
                onSelectChartRevHistory={handleSelectChartRevHistory}
                providers={providers || []}
              />
              <ChartingServiceFields
                onDeleteProgram={handleDeleteProgram}
                onDeleteService={handleDeleteService}
                programs={programs}
                services={services}
              />
            </Box>
            <Box sx={{ pb: 3, pl: 3, pr: 3 }}>{editor}</Box>
          </div>
        </div>
      ),
      id: 'ChartingDocument',
      title: 'Charting Document',
    },
    ChartingEntries: {
      cached: true,
      content: (
        <div className="charting-view">
          {seletedMenuItem === 'navigation.left-nav-menu.client.charting' && clientDetails && (
            <div className="charting-list-view">
              <ChartingEntries
                applyFilters={applyFilters}
                chartingEntries={chartingEntries || []}
                filters={filters}
                onSelect={handleSelectChart}
                providers={providers || []}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
              />
            </div>
          )}
        </div>
      ),
      id: 'ChartingEntries',
      title: 'Charting Entries',
    },
  }

  useEffect(
    () => editorRef.current?.setServiceDateTime(chartingData.serviceDateTime),
    [chartingData.serviceDateTime]
  )

  useEffect(() => {
    if (dockLayoutRef !== undefined && dockLayoutRef.current !== undefined) {
      dockLayoutRef.current?.updateTab('ChartingDocument', DockPanelTabs.ChartingDocument)
    }
  }, [DockPanelTabs.ChartingDocument])

  const historicalPanel = HistoricalPanel({ dockLayoutRef })

  const dockLayout: LayoutData = {
    dockbox: {
      children: [
        historicalPanel.panel,
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 50, widthFlex: 1 },
              tabs: [DockPanelTabs.ChartingEntries],
            },
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  const dockLayoutClientNoContext: LayoutData = {
    dockbox: {
      children: [
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  useEffect(() => {
    // When the user changes, all components need to be updated to use the newest information.
    if (dockLayoutRef !== undefined && dockLayoutRef.current !== undefined) {
      dockLayoutRef.current?.updateTab('ChartingEntries', DockPanelTabs.ChartingEntries)
    }
  }, [DockPanelTabs.ChartingEntries])

  return (
    <>
      <MISBaseContainer>
        {seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
          <ClientRecordHeader />
        )}
        {clientId && seletedMenuItem !== 'navigation.left-nav-menu.client.charting.in-progress' && (
          <DockLayout
            defaultLayout={dockLayout}
            groups={historicalPanel.groups}
            ref={dockLayoutRef}
            style={{ minHeight: '100vh' }}
          />
        )}
        {(!clientId ||
          seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress') && (
          <DockLayout
            defaultLayout={dockLayoutClientNoContext}
            groups={historicalPanel.groups}
            ref={dockLayoutRef}
            style={{ minHeight: '100vh' }}
          />
        )}
      </MISBaseContainer>
      <WarningDialog
        entity={deleteConfirmationDialog.entity}
        onCancel={onDeleteWarningClose}
        onSave={onDeleteWarningSave}
        open={deleteConfirmationDialog.index >= 0}
        type={MODALS.DELETE_CHARTING_SERVICES}
      />
    </>
  )
}

export default Charting
