import { useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { DateTime } from 'luxon'
import MISTextField from 'common/components/form/MISTextField'
import { IDHE_DATE_DISPLAY_FORMAT } from 'common/utils/DateUtils'

const IDHEDatePicker = ({ date, error, helperText, label, onDateChange, readOnly, required }) => {
  const [isInvalid, setIsInvalid] = useState(error)
  const [dateHelperText, setDateHelperText] = useState(helperText ? helperText : '')

  useEffect(() => {
    setIsInvalid(error)
    setDateHelperText(helperText)
  }, [error, helperText])

  const isInvalidDate = (dateUpdate) => {
    if (error && helperText) {
      setIsInvalid(error)
      setDateHelperText(helperText)
      return true
    } else if (required && !dateUpdate) {
      setDateHelperText(label + ' is a required field')
      return true
    } else if (dateUpdate && !dateUpdate.isValid) {
      setDateHelperText(`${label} must be a valid date`)
      return true
    }
    setDateHelperText('')
    return false
  }

  const handleDateChange = (value) => {
    const isInvalid = isInvalidDate(value)
    setIsInvalid(isInvalid)
    if (!isInvalid) {
      const zonedDateTime = value
        ? DateTime.fromJSDate(new Date(value)).toFormat(`yyyy-MM-dd'T'HH:mm:ssZZ`)
        : null
      onDateChange(zonedDateTime)
    } else {
      // If Date is invalid it will propagate to the parent component as a JS date
      // The parent component can use the 'invalid' property of the JS date to perform validations aside from the ones available here
      onDateChange(value)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        format={IDHE_DATE_DISPLAY_FORMAT}
        inputFormat={IDHE_DATE_DISPLAY_FORMAT}
        label={label}
        mask="____-__-__"
        onChange={(value) => handleDateChange(value)}
        readOnly={readOnly}
        renderInput={(params) => (
          <MISTextField
            {...params}
            error={isInvalid}
            helperText={dateHelperText ? dateHelperText : error}
            readOnly={readOnly}
            required={required}
          />
        )}
        value={date ? date : ''}
      />
    </LocalizationProvider>
  )
}

export default IDHEDatePicker
