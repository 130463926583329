import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISRadio from 'common/components/form/MISRadio'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectTreeView from 'common/components/form/MISSelectTreeView'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import GLOBAL from 'common/styles/global.scss'
import {
  getLanguageDataForTreeView,
  LanguageDataRowType,
  LanguageDataType,
  validateLanguages,
} from 'modules/shared/Languages/utils'
import { ErrorType, getError, getFormattedOptions } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientLanguage,
  CodedConceptTerseDto,
  CodedRef,
  PersonnelLanguageDTO,
} from 'services/openapi'
import {
  MIS_LANGUAGE_VOCAB_NAME,
  MIS_PERSON_LANGUAGE_FLUENCY_VOCAB_NAME,
} from 'services/terminologyConstants'
import { MODALS } from '../constants'
import WarningDialog from '../Dialogs/WarningDialog'

const ENTITY = 'Language'

type LanguagesProps = {
  languages: ClientLanguage[] | PersonnelLanguageDTO[]
  onDelete: (languageId: string) => void
  onSave: (languages: ClientLanguage[] | PersonnelLanguageDTO[]) => void
  save: boolean
  toggleSave: (save: boolean) => void
}

const Languages = ({ languages, onDelete, onSave, save, toggleSave }: LanguagesProps) => {
  const { t } = useTranslation('common')
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const names = useRecoilValue(terminologySelector(MIS_LANGUAGE_VOCAB_NAME))
  const fluencyOptions = useRecoilValue(terminologySelector(MIS_PERSON_LANGUAGE_FLUENCY_VOCAB_NAME))

  const [currData, setCurrData] = useState<LanguageDataRowType>({ rows: [] })
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [primaryIndex, setPrimaryIndex] = useState(-1)

  const onAddRow = useCallback(
    () =>
      setCurrData({
        rows: [
          ...currData.rows,
          {
            data: {
              isPrimary: currData.rows.length === 0 ? true : false,
              language: undefined,
              languageFluency: undefined,
              teachingInterest: false,
            },
            isCollapsed: false,
          },
        ],
      }),
    [currData]
  )

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, value?: string | CodedRef) => {
      if (!isDirty) setIsDirty(true)

      const rows = currData.rows?.map((row, index) => {
        if (field === 'isPrimary') {
          row.data.isPrimary = rowIndex === index
        } else {
          if (index === rowIndex) {
            let data
            switch (field) {
              case 'language':
                data = {
                  ...row.data,
                  language: names?.find((o: CodedConceptTerseDto) => o.name === value),
                }
                break
              case 'languageFluency':
                data = {
                  ...row.data,
                  languageFluency: fluencyOptions?.find(
                    (o: CodedRef) => o.code === (value as CodedRef)?.code
                  ),
                }
                break
              case 'teachingInterest':
                data = { ...row.data, teachingInterest: !row.data.teachingInterest }
                break
              default:
                return row
            }
            return { ...row, data }
          }
        }
        return row
      })
      setCurrData({ rows })
    },
    [currData, fluencyOptions, names, isDirty, setIsDirty]
  )

  const onCollapseRow = useCallback(
    (rowIndex: number) => {
      currData.rows[rowIndex].isCollapsed = !currData.rows[rowIndex].isCollapsed
      setCurrData({ ...currData })
    },
    [currData]
  )

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      setDeleteIndex(-1)
      const languageId = currData.rows[rowIndex].data?.id
      if (languageId) onDelete(languageId)
      else {
        currData.rows.splice(rowIndex, 1)
        setCurrData({ rows: currData.rows })
      }
    },
    [currData.rows, onDelete]
  )

  const rowDataForm = useCallback(
    (
      row: LanguageDataType,
      rowIndex: number,
      onChangeRow: (rowIndex: number, field: string, value?: string) => void
    ) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <MISSelectTreeView
              data={names ? getLanguageDataForTreeView(names) : []}
              error={!!getError(errors, `language-${rowIndex}`)}
              helperText={getError(errors, `language-${rowIndex}`)}
              id="language"
              label={t('client-person.language.language')}
              onSelect={(value: string) => onChangeRow(rowIndex, 'language', value)}
              value={names?.find((o) => o.code === row.data.language?.code)}
            />
          </Grid>
          <Grid item xs={3}>
            <MISSelectDropdown
              label={t('client-person.language.fluency')}
              onChange={(event) =>
                onChangeRow(rowIndex, 'languageFluency', event.target.value as string)
              }
              options={getFormattedOptions(fluencyOptions)}
              value={fluencyOptions?.find((o) => o.code === row.data.languageFluency?.code)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <MISCheckbox
                  checked={row.data.teachingInterest}
                  onChange={() => onChangeRow(rowIndex, 'teachingInterest')}
                />
              }
              label={t('client-person.language.interested-in-teaching-others')}
              sx={{ height: '100%' }}
            />
          </Grid>
        </Grid>
      )
    },
    [errors, fluencyOptions, names, t]
  )

  const rowHeader = useCallback(
    (row: LanguageDataType, rowIndex: number) => {
      const name = names?.find((o: CodedRef) => o.code === row.data.language?.code)
      const fluencyOption = fluencyOptions?.find(
        (o: CodedRef) => o.code === row.data.languageFluency?.code
      )
      return (
        <>
          <MISRadio
            checked={row.data.isPrimary}
            name="names"
            onChange={() => setPrimaryIndex(rowIndex)}
            onClick={(event) => event.stopPropagation()}
            sx={{ pointerEvents: 'auto' }}
            value={rowIndex}
          />
          {name?.name && <span>{name.name}</span>}
          {fluencyOption?.name && <span>{` | ${fluencyOption?.name}`}</span>}
          {row.data.teachingInterest && (
            <span>{` | ${t('client-person.language.interested-in-teaching')}`}</span>
          )}
          {row.data.isPrimary && (
            <span>
              {' | '}
              <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>
                {t('client-person.language.primary')}
              </span>
            </span>
          )}
        </>
      )
    },
    [fluencyOptions, names, t]
  )

  useEffect(
    () =>
      setCurrData({
        rows: languages
          ? [...languages]
              .sort((a, b) => (a?.isPrimary ? -1 : b?.isPrimary ? 1 : 0))
              .map((obj) => {
                return {
                  data: {
                    id: obj.id,
                    isPrimary: obj.isPrimary,
                    language: obj.language,
                    languageFluency: obj.languageFluency,
                    teachingInterest: obj.teachingInterest,
                  },
                  isCollapsed: true,
                }
              })
          : [],
      }),
    [languages]
  )

  useEffect(() => {
    if (save) {
      setIsDirty(false)

      const errors = validateLanguages(
        currData,
        t('client-person.validation.language.required'),
        t('client-person.validation.language.unique')
      )
      if (errors.length === 0) {
        setErrors([])
        onSave(currData.rows.map((row) => row.data))
      } else {
        setErrors(errors)
        toggleSave(false)
      }
    }
  }, [currData, onSave, save, toggleSave, setIsDirty, t])

  return (
    <>
      <MISAccordionContainer
        addLabel={t('client-person.language.labels.add-language')}
        onAddRow={onAddRow}
        onChangeRow={onChangeRow}
        onCollapseRow={onCollapseRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowDataForm}
        rowHeader={rowHeader}
        rows={currData.rows}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setPrimaryIndex(-1)}
        onSave={() => {
          setPrimaryIndex(-1)
          onChangeRow(primaryIndex, 'isPrimary')
        }}
        open={primaryIndex > -1}
        type={MODALS.PREFERRED_WARNING}
      />
    </>
  )
}

export default Languages
