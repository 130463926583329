import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import MISChip from 'common/components/form/MISChip'
import MISTextField from 'common/components/form/MISTextField'
import Loader from 'common/components/Loader'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { FormSchemaControllerService, PageFormSchemaLightDTO } from 'services/openapi'
import { setCurrentTemplate } from 'store/reducers/charting'
import { TEMPLATE_TYPE } from './TemplateEditor'

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, onPageChange, page, rowsPerPage } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const TemplateTables = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()

  const [searchStr, setSearchStr] = useState('')
  const [templatesResp, setTemplatesResp] = useState<PageFormSchemaLightDTO>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback((event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const handleEditTemplate = useCallback(
    async (template) => {
      const formSchemaDTO = await FormSchemaControllerService.getFormSchema(template.id)
      dispatch(setCurrentTemplate(formSchemaDTO))
      navigate('/admin/template-editor')
    },
    [dispatch, navigate]
  )

  const handleSearchStrChange = useCallback((e) => {
    setPage(0)
    setSearchStr(e.target.value)
  }, [])

  useEffect(() => {
    FormSchemaControllerService.searchFormSchema(
      searchStr === '' ? '%' : `%${searchStr}%`,
      undefined,
      true,
      undefined,
      false,
      true,
      undefined,
      undefined,
      undefined,
      page,
      rowsPerPage
    )
      .then((resp) => setTemplatesResp(resp))
      .catch((error) => handleApiError(error))
  }, [handleApiError, page, rowsPerPage, searchStr])

  if (!templatesResp) return <Loader />

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Stack direction="row-reverse" sx={{ mt: 2, width: '100%' }}>
        <MISTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label={t('common.button.search')}
          onChange={handleSearchStrChange}
          sx={{ pr: 2 }}
          value={searchStr}
        />
      </Stack>
      <Table aria-label="simple table" sx={{ minWidth: 650, width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t('charting.template-management.name')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t('charting.template-editor.description')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t('charting.template-management.private-public')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t('charting.template-management.type')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>{t('charting.template-editor.tags')}</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {t('charting.template-management.state')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {templatesResp.content &&
            templatesResp.content.map((template, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ minWidth: '120px' }}>{template.name}</TableCell>
                <TableCell sx={{ minWidth: '120px' }}>{template.description}</TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {template.designation && template.designation.length > 0
                    ? template.designation.charAt(0).toUpperCase() +
                      template.designation.slice(1).toLowerCase()
                    : ''}
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {template.templateType === TEMPLATE_TYPE.USER_DEFINED_BASE_TEMPLATE
                    ? t('charting.template-management.base')
                    : t('charting.template-management.aggregate')}
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {template.tags?.split(' ').map((tag, index) => (
                    <MISChip key={index} label={tag} sx={{ mr: 0.5 }} />
                  ))}
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {template.state && template.state.length > 0
                    ? template.state.charAt(0).toUpperCase() + template.state.slice(1).toLowerCase()
                    : ''}
                </TableCell>
                <TableCell sx={{ minWidth: '60px' }}>
                  <IconButton onClick={() => handleEditTemplate(template)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              colSpan={12}
              count={templatesResp.totalElements || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              slotProps={{
                select: { inputProps: { 'aria-label': 'rows per page' }, native: true },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default TemplateTables
