import { FC } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link as MUILink, LinkProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

interface LinkCustomProps {
  color?: string
  href?: string
  label?: string
  showArrow?: boolean
  underline?: string
  variant?: string
}

const MISLink: FC<LinkCustomProps & LinkProps> = (props) => {
  const { children, color, href, label, onClick, showArrow, variant } = props

  return (
    <ThemeProvider theme={misTheme}>
      <MUILink color={color} href={href} onClick={onClick} variant={variant}>
        {children || label} {showArrow && <ArrowForwardIcon fontSize="small" />}
      </MUILink>
    </ThemeProvider>
  )
}

MISLink.defaultProps = {
  color: 'primary',
  href: '',
  label: 'Link',
  onClick: () => null,
  showArrow: false,
  underline: 'hover',
  variant: 'body2',
}

export default MISLink
