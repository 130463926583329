import { useParams } from 'react-router-dom'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import ClientRecordHeaderDetails from './ClientRecordHeaderDetails'

const ClientRecordHeader = () => {
  const { clientId } = useParams()
  const { clientDetails } = useClientDetails(clientId)

  return <>{clientDetails && <ClientRecordHeaderDetails client={clientDetails} />}</>
}

export default ClientRecordHeader
