import { Stack, Typography } from '@mui/material'

const InfoCardHeader = ({ icon, label }: { icon?: JSX.Element; label: string }) => {
  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Typography sx={{ color: '#333', fontSize: '14px', lineHeight: '20px' }}>{label}</Typography>
    </Stack>
  )
}
export default InfoCardHeader
