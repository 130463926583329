import { useCallback } from 'react'
import { AlertColor } from '@mui/material'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import { snackbarState } from 'recoil/snackbar'

const ERROR_DURATION = 6000
const INFO_DURATION = 4000
const SUCCESS_DURATION = 3000
const WARNING_DURATION = 4000

export interface SnackContextType {
  showSnack: (message: string, severity?: AlertColor, autoHideDuration?: number) => void
  hideSnack: () => void
}

export const useSnack = () => {
  const resetSnackbarState = useResetRecoilState(snackbarState)
  const setSnackbarState = useSetRecoilState(snackbarState)

  const hideSnack = useCallback(() => resetSnackbarState(), [resetSnackbarState])

  const showSnack = useCallback(
    (message: string, severity?: AlertColor, autoHideDuration?: number) => {
      setSnackbarState({
        autoHideDuration,
        message,
        onClose: hideSnack,
        severity,
        visible: true,
      })
    },
    [hideSnack, setSnackbarState]
  )

  const showSnackError = useCallback(
    (message: string, autoHideDuration = ERROR_DURATION) => {
      showSnack(message, 'error', autoHideDuration)
    },
    [showSnack]
  )

  const showSnackInfo = useCallback(
    (message: string, autoHideDuration = INFO_DURATION) => {
      showSnack(message, 'info', autoHideDuration)
    },
    [showSnack]
  )

  const showSnackSuccess = useCallback(
    (message: string, autoHideDuration = SUCCESS_DURATION) => {
      showSnack(message, 'success', autoHideDuration)
    },
    [showSnack]
  )

  const showSnackWarning = useCallback(
    (message: string, autoHideDuration = WARNING_DURATION) => {
      showSnack(message, 'warning', autoHideDuration)
    },
    [showSnack]
  )

  return {
    hideSnack,
    showSnackError,
    showSnackInfo,
    showSnackSuccess,
    showSnackWarning,
  }
}
