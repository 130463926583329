import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import MISCheckbox from 'common/components/form/MISCheckbox'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { StateChip } from 'modules/shared/State'
import { encounterIdState, encounterServiceIdState } from 'recoil/lastupdated'
import { RunningNoteSchema } from 'recoil/runningNotes'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef } from 'services/openapi'
import {
  MIS_ENCOUNTER_NOTE_STATE,
  MIS_ENCOUNTER_SERVICE_STATE,
} from 'services/terminologyConstants'
import './RunningNotes.scss'

const RunningNote = ({
  entity,
  handleSingleSelect,
  primaryProvider,
  runningNote,
}: {
  entity: string
  handleSingleSelect: (selected: boolean, noteId: string) => void
  primaryProvider: string
  runningNote: RunningNoteSchema
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { clientId } = useParams()
  const parentStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
  const noteStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_NOTE_STATE))
  const setLastUpdatedEncounterId = useSetRecoilState(encounterIdState)
  const setLastUpdatedEncounterServiceId = useSetRecoilState(encounterServiceIdState)
  return (
    <Box sx={{ mx: -1, my: 1 }}>
      <Typography sx={{ my: 1 }}>
        <MISCheckbox
          checked={runningNote?.checked}
          onChange={(e) =>
            handleSingleSelect(e.target.checked as boolean, runningNote?.id as string)
          }
          sx={{ display: 'inline', m: 0, p: 0 }}
        />
        <span style={{ color: GLOBAL.LINK_PRIMARY_COLOR, display: 'inline' }}>
          {runningNote?.synopsis}
        </span>
        {runningNote?.date && <span>{` | ${runningNote?.date}`}</span>}
        {primaryProvider && (
          <span>{` | ${t('running-notes.primary-provider')}: ${primaryProvider}`}</span>
        )}
        {runningNote?.state && (
          <span>
            {' | '}
            <StateChip
              label={
                parentStateOptions?.find(
                  (o: CodedRef) => o.code === (runningNote.state as CodedRef)?.code
                )?.name as string
              }
            />
          </span>
        )}
        <span
          onClick={() => {
            if (runningNote.encounterId && runningNote.encounterServiceId) {
              setLastUpdatedEncounterId(runningNote.encounterId)
              setLastUpdatedEncounterServiceId(runningNote.encounterServiceId)
            }
            setTimeout(() => {
              navigate(`/clients/client-record/${clientId}/encounters`)
            }, 0)
          }}
          style={{
            color: GLOBAL.LINK_PRIMARY_COLOR,
            cursor: 'pointer',
            float: 'right',
            marginRight: GLOBAL.MARGIN_XS,
          }}
        >
          {entity}
        </span>
      </Typography>
      <Box className="running-note-box">
        {runningNote.notes?.map((note) => {
          return (
            <>
              <Typography>
                {note?.author && <span>{`${t('running-notes.author')}: ${note?.author}`}</span>}
                {note?.author && (
                  <span>{` | ${t('running-notes.last-updated')}: ${isoDateToDisplayFormatWithTime(
                    note?.lastUpdated || ''
                  )}`}</span>
                )}
                {note?.state && (
                  <span>
                    {' | '}
                    <StateChip
                      comment={note.stateComment}
                      defaultCursor={!note.stateComment}
                      label={
                        noteStateOptions?.find(
                          (o: CodedRef) => o.code === (note.state as CodedRef)?.code
                        )?.name as string
                      }
                    />
                  </span>
                )}
              </Typography>
              {note.content && <div dangerouslySetInnerHTML={{ __html: note.content }} />}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

export default RunningNote
