import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  initialized: boolean
}

const initialState: AppState = {
  initialized: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setAppInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload
    },
  },
})

export const { setAppInitialized } = appSlice.actions

export default appSlice.reducer
