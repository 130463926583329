import { ReactNode } from 'react'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { Paper, Stack, Typography } from '@mui/material'

type StatusCardContainerProps = {
  children: ReactNode
  icon: JSX.Element
  label: string
}

const StatusCardContainer = ({ children, icon, label }: StatusCardContainerProps) => {
  return (
    <Paper sx={{ backgroundColor: '#fff', minHeight: '220px', p: 2, width: '19%' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 1 }}>
        <Stack direction="row">
          {icon}
          <Typography sx={{ color: '#333', fontSize: '14px', fontWeight: 700, lineHeight: '16px' }}>
            {label}
          </Typography>
        </Stack>
        <ExpandCircleDownOutlinedIcon
          fontSize="small"
          sx={{ color: '#cc5500', mt: -0.25, transform: 'rotate(270deg)' }}
        />
      </Stack>
      {children}
    </Paper>
  )
}

export default StatusCardContainer
