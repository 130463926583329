/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthConcernDTO } from '../models/HealthConcernDTO'
import type { HealthConcernStateDTO } from '../models/HealthConcernStateDTO'
import type { PageHealthConcernDTO } from '../models/PageHealthConcernDTO'
import type { UnPagedHealthConcernStateDTO } from '../models/UnPagedHealthConcernStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class HealthConcernControllerService {
  /**
   * Retrieve a health concern record for a client
   * @param clientId
   * @param healthConcernId
   * @returns HealthConcernDTO OK
   * @throws ApiError
   */
  public static getHealthConcern(
    clientId: string,
    healthConcernId: string
  ): CancelablePromise<HealthConcernDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}',
      path: {
        clientId: clientId,
        healthConcernId: healthConcernId,
      },
    })
  }

  /**
   * Update a health concern record for a client
   * @param clientId
   * @param healthConcernId
   * @param requestBody
   * @returns HealthConcernDTO OK
   * @throws ApiError
   */
  public static putHealthConcern(
    clientId: string,
    healthConcernId: string,
    requestBody: HealthConcernDTO
  ): CancelablePromise<HealthConcernDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}',
      path: {
        clientId: clientId,
        healthConcernId: healthConcernId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete a health concern for a client
   * @param clientId
   * @param healthConcernId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteHealthConcern(
    clientId: string,
    healthConcernId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}',
      path: {
        clientId: clientId,
        healthConcernId: healthConcernId,
      },
    })
  }

  /**
   * Retrieve a list of health concern records for a client
   * @param clientId
   * @returns PageHealthConcernDTO OK
   * @throws ApiError
   */
  public static getHealthConcerns(clientId: string): CancelablePromise<PageHealthConcernDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Create a health concern record for a client
   * @param clientId
   * @param requestBody
   * @returns HealthConcernDTO OK
   * @throws ApiError
   */
  public static postHealthConcern(
    clientId: string,
    requestBody: HealthConcernDTO
  ): CancelablePromise<HealthConcernDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Adds a new record of a state transition for a health concern record
   * @param clientId
   * @param healthConcernId
   * @param requestBody
   * @returns HealthConcernStateDTO OK
   * @throws ApiError
   */
  public static postHealthConcernState(
    clientId: string,
    healthConcernId: string,
    requestBody: HealthConcernStateDTO
  ): CancelablePromise<HealthConcernStateDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}/states',
      path: {
        clientId: clientId,
        healthConcernId: healthConcernId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Retrieves the history of states for a health concern record
   * @param clientId
   * @param healthConcernId
   * @returns UnPagedHealthConcernStateDTO OK
   * @throws ApiError
   */
  public static getStateHistory(
    clientId: string,
    healthConcernId: string
  ): CancelablePromise<UnPagedHealthConcernStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}/states-history',
      path: {
        clientId: clientId,
        healthConcernId: healthConcernId,
      },
    })
  }

  /**
   * Retrieves the list of states into which the health concern record can be placed
   * @param healthConcernId
   * @returns UnPagedHealthConcernStateDTO OK
   * @throws ApiError
   */
  public static getNextStates(
    healthConcernId: string
  ): CancelablePromise<UnPagedHealthConcernStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/health-concerns/{healthConcernId}/next-states',
      path: {
        healthConcernId: healthConcernId,
      },
    })
  }
}
