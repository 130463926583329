import { Draggable } from 'react-beautiful-dnd'
import { Delete } from '@mui/icons-material'
import { IconButton, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material'

export type DraggableListItemProps = {
  item: any
  index: number
  handleDelete: (id: string) => void
  handleOnClick: (id: string) => void
}

const MISDraggableListItem = ({
  handleDelete,
  handleOnClick,
  index,
  item,
}: DraggableListItemProps) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided: any, snapshot: any) => (
        <ListItemButton
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : ''}
          onClick={() => handleOnClick(index.toString())}
        >
          <ListItemText primary={item.formName} />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="delete"
              edge="end"
              onClick={(event) => {
                event.stopPropagation()
                handleDelete(item.id)
              }}
            >
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      )}
    </Draggable>
  )
}

export default MISDraggableListItem
