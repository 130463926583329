import { Component } from 'react'
import SOAPTemplateComponent from './SOAPTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

import './SOAPTemplate.scss'

export type SOAPTemplateState = {
  createdBy: string
  createdOn: string
  assessment: string
  objective: string
  plan: string
  subjective: string
}

type SOAPTemplateProps = { data?: SOAPTemplateState }

class SOAPTemplate extends Component<SOAPTemplateProps, SOAPTemplateState> implements ITemplate {
  type = 'SOAPTemplate'

  constructor(props: SOAPTemplateProps) {
    super(props)
    this.state = { ...this.state, ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    assessment: { label: 'Assessment', type: ETemplateMetadataType.string },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    objective: { label: 'Objective', type: ETemplateMetadataType.string },
    plan: { label: 'Plan', type: ETemplateMetadataType.string },
    subjective: { label: 'Subjective', type: ETemplateMetadataType.string },
  })

  static getTemplateDisplayName = (): string => 'SOAP Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({ ...this.state })

  handleChange = (key: string, value: string) => this.setState({ ...this.state, [key]: value })

  render() {
    return <SOAPTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default SOAPTemplate
