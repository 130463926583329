import { useCallback } from 'react'
import { LocalizationProvider, TimePicker, TimePickerProps } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider } from '@mui/material/styles'
import { DateTime } from 'luxon'
import { IDHE_TIME_SEC_DISPLAY_FORMAT } from 'common/utils/DateUtils'
import misTheme from 'core/styles/theme'
import MISTextField from './MISTextField'

export type MISTimePickerProps = Omit<TimePickerProps, 'renderInput'>

const MISTimePicker = (props: MISTimePickerProps) => {
  const handleChange = useCallback(
    (date: Date) => {
      if (date) props.onChange(DateTime.fromJSDate(date).toISO())
      else props.onChange(null)
    },
    [props]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={misTheme}>
        <TimePicker
          {...props}
          ampm={false}
          inputFormat={IDHE_TIME_SEC_DISPLAY_FORMAT}
          mask="__:__:__"
          onChange={(date: unknown) => handleChange(date as Date)}
          openTo="hours"
          renderInput={(props) => <MISTextField {...props} />}
          views={['hours', 'minutes', 'seconds']}
        />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default MISTimePicker
