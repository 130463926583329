import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.snow.css'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { setCurrentTemplate } from 'store/reducers/charting'
import TemplateTable from './TemplateTable'

const TemplateManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const handleAdd = useCallback(() => {
    dispatch(setCurrentTemplate(undefined))
    navigate('/admin/template-editor')
  }, [dispatch, navigate])

  return (
    <Box sx={{ background: '#fff', mt: 6 }}>
      <SectionHeader
        addLabel={t('charting.canvas.new-template')}
        onAdd={handleAdd}
        title={t('navigation.left-nav-menu.admin.template-management')}
      />
      <MISBaseContainer>
        <TemplateTable />
      </MISBaseContainer>
    </Box>
  )
}

export default TemplateManagement
