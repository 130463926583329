import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import MISCheckbox from 'common/components/form/MISCheckbox'
import { localDateFormatToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  AcceptableUseAgreementControllerService,
  ConfidentialityAgreementControllerService,
} from 'services/openapi'

const hasCheckbox = false

/**
 * Build a row for the table.  The columns in the row will be
 * accessed by property name when building the table, and also
 * for sorting.
 */
function createRow(name, createBy, startDate, endDate, id) {
  const row = {
    createBy,
    endDate,
    id,
    name,
    startDate,
  }
  return row
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const columns = [
  {
    disablePadding: true,
    id: 'name',
    label: 'Name',
    maxWidth: 400,
  },
  {
    id: 'createBy',
    label: 'Created By',
  },
  {
    id: 'startDate',
    label: 'Start Date',
  },
  {
    id: 'endDate',
    label: 'End Date',
  },
]

function EnhancedTableHead(props) {
  const { onRequestSort, order, orderBy } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {hasCheckbox && <TableCell padding="checkbox" />}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
            style={{ maxWidth: column.maxWidth }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { tableTitle } = props

  return (
    <Toolbar>
      <Typography component="div" id="tableTitle" sx={{ flex: '1 1 100%' }} variant="h4">
        {tableTitle}
      </Typography>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
}

const AgreementList = ({ onRowSelected, title, trigger, type }) => {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [rowData, setRowData] = React.useState([])
  const [agreementListResponse, setAgreementListResponse] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const { handleApiError } = useErrorHandler()

  useEffect(() => {
    // Default max page size as per spring-boot is 2000. This can be changed
    // in the spring config using the spring.data.web.pageable.max-page-size
    // property, but should be enough for our purposes here.
    let getOperation
    if (type === 'acceptable-uses') {
      getOperation = AcceptableUseAgreementControllerService.listAcceptableUseAgreements(0, 2000)
    } else if (type === 'confidentiality-agreements') {
      getOperation = ConfidentialityAgreementControllerService.listConfidentialityAgreements(
        0,
        2000
      )
    }
    if (getOperation) {
      setLoading(true)
      getOperation
        .then((response) => {
          setAgreementListResponse(response)
        })
        .catch((error) => {
          handleApiError(error)
          setRowData([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [type, handleApiError, trigger])

  useEffect(() => {
    if (agreementListResponse) {
      const rows = agreementListResponse.content.map((item) => {
        return createRow(
          item.name,
          item.createdUser,
          localDateFormatToDisplayFormat(item.startDate),
          item.endDate ? localDateFormatToDisplayFormat(item.endDate) : '',
          item.id
        )
      })
      setRowData(rows)
    } else {
      setRowData([])
    }
  }, [agreementListResponse])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowData.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name, id) => {
    const selectOneOnly = true
    const selectedArray = selectOneOnly ? [] : selected
    const selectedIndex = selectedArray.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedArray, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedArray.slice(1))
    } else if (selectedIndex === selectedArray.length - 1) {
      newSelected = newSelected.concat(selectedArray.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedArray.slice(0, selectedIndex),
        selectedArray.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    onRowSelected(id, name)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0

  /////////////////////////////////////////////////////////////////////

  return (
    <Paper elevation={6} sx={{ p: 5 }}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <EnhancedTableToolbar numSelected={selected.length} tableTitle={title} />
          {loading && <p>loading table data...</p>}

          <TableContainer>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                order={order}
                orderBy={orderBy}
                rowCount={rowData.length}
              />

              {!loading && rowData && rowData.length > 0 && (
                <TableBody>
                  {/* .slice().sort is not supported in IE11 */}
                  {rowData &&
                    rowData
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        if (row) {
                          const isItemSelected = isSelected(row.name)
                          const labelId = `enhanced-table-checkbox-${index}`

                          return (
                            <TableRow
                              aria-checked={isItemSelected}
                              hover
                              key={row.name}
                              onClick={(event) => handleClick(event, row.name, row.id)}
                              role="checkbox"
                              selected={isItemSelected}
                              tabIndex={-1}
                            >
                              {hasCheckbox && (
                                <TableCell padding="checkbox">
                                  <MISCheckbox
                                    checked={isItemSelected}
                                    color="primary"
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>
                              )}
                              <TableCell
                                component="th"
                                id={labelId}
                                padding="none"
                                scope="row"
                                style={{ maxWidth: 300 }}
                              >
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{row.createBy}</TableCell>
                              <TableCell align="left">{row.startDate}</TableCell>
                              <TableCell align="left">{row.endDate}</TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AgreementList
