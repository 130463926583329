import MISChip from 'common/components/form/MISChip'
import { arrayMove } from 'common/utils/utils'
import { PersonnelDTO, PersonnelJobFunctionDTO, PersonnelNameDTO } from 'services/openapi'
import { CustomStaffMemberProps } from './StaffAssociation'

export const evaluateLabelUtil = (names: PersonnelNameDTO[] | undefined) => {
  const primaryName = names?.find((name) => name.preferredName) || names?.[0]
  const fullName = [primaryName?.firstName, primaryName?.middleName, primaryName?.lastName]
    .filter(Boolean)
    .join(' ')
    .trim()

  return fullName || ''
}

export const evaluateChipsUtil = (jobFunctions: PersonnelJobFunctionDTO[] | undefined) => {
  const chips: string[] = []
  jobFunctions?.forEach((jf: PersonnelJobFunctionDTO) => {
    if (jf.isInternal && !chips.includes('Internal')) {
      chips.push('Internal')
    }
    if (!jf.isInternal && !chips.includes('External')) {
      chips.push('External')
    }
    if (jf.isStaff && !chips.includes('Staff')) {
      chips.push('Staff')
    }
    if (!jf.isActive && !chips.includes('Inactive')) {
      chips.push('Inactive')
    }
  })
  return chips
}

export const displayChipsUtil = (chips: string[]) => {
  const chipsSortOrder = ['Inactive', 'Internal', 'External', 'Staff']
  return (
    <>
      {chipsSortOrder
        .filter((val: string) => chips.includes(val))
        .map((val: string, index: number) => {
          return (
            <MISChip
              key={index}
              label={val}
              sx={{ ml: 1 }}
              variant={val !== 'Inactive' ? 'outlined' : 'filled'}
            />
          )
        })}
    </>
  )
}

export const getSortedListUtil = (members: CustomStaffMemberProps[]) => {
  members.sort((a, b) => {
    const primaryNameA =
      a?.personnel?.names?.find((name) => name.preferredName) ||
      (a?.personnel?.names?.[0] as PersonnelNameDTO)
    const primaryNameB =
      b?.personnel?.names?.find((name) => name.preferredName) ||
      (b?.personnel?.names?.[0] as PersonnelNameDTO)
    return (primaryNameA?.lastName || '').localeCompare(primaryNameB?.lastName || '')
  })

  const primaryIndex = members.findIndex((a: CustomStaffMemberProps) => a?.isPrimary)
  return arrayMove(members, primaryIndex, 0) as CustomStaffMemberProps[]
}

export const getFilteredListUtil = (
  providers: PersonnelDTO[],
  membersData: CustomStaffMemberProps[]
) => {
  return providers
    .filter(
      (provider: PersonnelDTO) =>
        membersData &&
        !membersData.some(({ personnel }: CustomStaffMemberProps) => personnel?.id === provider?.id)
    )
    .filter((provider: PersonnelDTO) => {
      return provider.jobFunctions?.some((item: PersonnelJobFunctionDTO) => item.isActive)
    })
    .map((provider: PersonnelDTO) => {
      return {
        ...provider,
        label: evaluateLabelUtil(provider?.names),
      }
    })
}

export const getFilteredListUtilWithoutMember = (providers: PersonnelDTO[]) => {
  return providers
    .filter((provider: PersonnelDTO) => {
      return provider.jobFunctions?.some((item: PersonnelJobFunctionDTO) => item.isActive)
    })
    .map((provider: PersonnelDTO) => {
      return {
        ...provider,
        label: evaluateLabelUtil(provider?.names),
      }
    })
}
