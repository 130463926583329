import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import common_en from './translations/en/common.json'

const myI18n = i18n.createInstance()

myI18n
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    interpolation: { escapeValue: false },
    lng: 'en', // Your default language
    resources: {
      en: { common: common_en },
    },
  })

export default myI18n
