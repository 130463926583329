import { ReactElement, useCallback } from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useRecoilState } from 'recoil'
import global from 'common/styles/global.scss'
import { MISNavigationState } from './MISNavigationState'

type MISNavigationItemProps = {
  label: string
  icon?: ReactElement
  name: string
  route: string
  tooltip?: string
  onSelect?: () => void
}

const MISNavigationItem = ({
  icon,
  label,
  name,
  onSelect,
  route,
  tooltip,
}: MISNavigationItemProps) => {
  const [selected, setSelected] = useRecoilState(MISNavigationState)

  const handleSelected = useCallback(() => {
    if (onSelect) {
      onSelect()
    }
    setSelected(name)
  }, [name, onSelect, setSelected])

  return (
    <ListItem
      component={Link}
      onClick={handleSelected}
      sx={{
        ...(selected === name && {
          backgroundColor: global.INNER_LEFT_SIDEBAR_BACKGROUND,
        }),
        color: global.BLACK,
        flexDirection: icon ? 'column' : 'row',
      }}
      to={route}
    >
      {icon && <ListItemIcon sx={{ minWidth: 'auto' }}>{icon}</ListItemIcon>}
      <ListItemText
        primary={label}
        primaryTypographyProps={{ fontSize: global.FONT_BODY_REGULAR }}
        sx={{
          ...(selected === name && {
            backgroundColor: global.INNER_LEFT_SIDEBAR_BACKGROUND,
          }),
        }}
      />
    </ListItem>
  )
}

export default MISNavigationItem
