import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BusinessLineTerse } from 'services/openapi'

export interface BusinessLineState {
  businessLinesTerse?: BusinessLineTerse[]
}

const initialState: BusinessLineState = {}

export const businessLineSlice = createSlice({
  initialState,
  name: 'businessLine',
  reducers: {
    setBusinessLinesTerse: (state, action: PayloadAction<BusinessLineTerse[] | undefined>) => {
      state.businessLinesTerse = action.payload
    },
  },
})

export const { setBusinessLinesTerse } = businessLineSlice.actions

export default businessLineSlice.reducer
