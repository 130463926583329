import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import DockLayout, { PanelData, TabData } from 'rc-dock'
import { HistoricalItem } from './HistoricalItem'
import './HistoricalPanel.css'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'

interface HistoricalPanelProps {
  dockLayoutRef: MutableRefObject<DockLayout | null>
}

const HistoricalPanel = ({ dockLayoutRef }: HistoricalPanelProps) => {
  const HistoricalPanel: PanelData = {
    id: 'historicalPanel',
    panelLock: {
      minHeight: 50,
      minWidth: 200,
      panelExtra: (panelData) => (
        <Button onClick={() => addHistorical('')}>
          <AddIcon />
          Add Tab
        </Button>
      ),
    },
    size: 20,
    tabs: [],
  }
  const [, setTabCount] = useState<number>(0)

  const groups = {
    'card custom': {
      floatable: true,
      maximizable: false,
    },
  }

  const updateTabTitle = useCallback(
    (tabId, title) => {
      const historicalTab = dockLayoutRef?.current?.find(tabId) as TabData
      dockLayoutRef?.current?.updateTab(tabId, { ...historicalTab, title: title })
    },
    [dockLayoutRef]
  )

  const addHistorical = useCallback(
    (templateId?: string, templateName?: string) => {
      return new Promise<void>((resolve) => {
        setTabCount((prevCount) => {
          const sheetNumber = prevCount + 1
          const newHistoricalId = `Historical_${sheetNumber}`
          const newHistorical = {
            cached: true,
            closable: true,
            content: (
              <HistoricalItem
                onSelectTemplate={(value) => updateTabTitle(newHistoricalId, value)}
                preTemplateId={templateId ? templateId : undefined}
              />
            ),
            group: 'card custom',
            id: newHistoricalId,
            title: templateName ? templateName : `Historical ${sheetNumber}`,
          }
          const historicalPanel = dockLayoutRef.current?.find('historicalPanel') as PanelData
          dockLayoutRef.current?.dockMove(newHistorical, historicalPanel, 'middle')
          return sheetNumber
        })
        requestAnimationFrame(() => {
          resolve() // Resolve the promise after the dockMove is completed
        })
      })
    },
    [dockLayoutRef, updateTabTitle]
  )

  const addStaticHistorical = useCallback(async () => {
    const staticTemplates = Object.values(TEMPLATES).filter((each) => each.historical === 'table')

    staticTemplates.forEach(async (template) => {
      await addHistorical(template.id, template.name)
    })
  }, [addHistorical])

  useEffect(() => {
    addStaticHistorical()
  }, [addStaticHistorical])

  return { groups: groups, panel: HistoricalPanel }
}

export default HistoricalPanel
