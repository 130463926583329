import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import createReactClass from 'create-react-class'
import i18next from 'i18next'
import $ from 'jquery'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import myI18n from 'translations'
import App from './core/App'
import { store } from './store'
import common_en from './translations/en/common.json'

declare global {
  interface Window {
    $: typeof $
    jQuery: typeof $
  }
}

window.$ = $
window.jQuery = $
window.createReactClass = createReactClass
window.React = React

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <I18nextProvider i18n={myI18n}>
    <Provider store={store}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Provider>
  </I18nextProvider>
)
