import { Chrono } from 'react-chrono'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material/styles'
import GLOBAL from 'common/styles/global.scss'
import misTheme from 'core/styles/theme'
import './Timeline.scss'

export type TimelineItem = {
  title?: string
  cardTitle: string
  cardSubtitle: string
  content?: React.ReactNode
}

export type TimelineData = {
  title?: string
  cardTitle?: string
  cardSubtitle?: string
  index: number
}

export type TimelineProps = {
  activeItemIndex?: number
  items: TimelineItem[]
  mode?: 'VERTICAL' | 'HORIZONTAL'
  cardHeight?: number
  hideControls?: boolean
  itemWidth?: number
  showAllCardsHorizontal?: boolean
  onItemSelected?: (data: TimelineData) => void
}

const Timeline = ({
  activeItemIndex = -1,
  cardHeight = 200,
  hideControls = false,
  items,
  itemWidth,
  mode = 'VERTICAL',
  onItemSelected,
  showAllCardsHorizontal = false,
}: TimelineProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <Paper
        elevation={3}
        style={{
          border: 0,
          boxShadow: 'none',
          display: 'grid',
        }}
      >
        <Chrono
          activeItemIndex={activeItemIndex}
          allowDynamicUpdate
          cardHeight={cardHeight}
          disableClickOnCircle
          hideControls={hideControls}
          itemWidth={itemWidth}
          items={items}
          mode={mode}
          onItemSelected={onItemSelected}
          showAllCardsHorizontal={showAllCardsHorizontal}
          theme={{
            cardBgColor: 'white',
            cardTitleColor: GLOBAL.BUTTON_PRIMARY_BG_COLOR,
            primary: GLOBAL.BUTTON_PRIMARY_BG_COLOR,
            secondary: GLOBAL.BUTTON_PRIMARY_BG_COLOR,
            titleColor: 'white',
            titleColorActive: 'red',
          }}
        >
          {items &&
            items.map((item) => {
              return item.content
            })}
        </Chrono>
      </Paper>
    </ThemeProvider>
  )
}

export default Timeline
