import { AlertColor } from '@mui/material'
import { atom } from 'recoil'

export interface SnackbarStateType {
  message: string
  autoHideDuration?: number | null
  onClose?: () => void
  severity?: AlertColor
  visible?: boolean
}

export const snackbarState = atom<SnackbarStateType>({
  default: {
    autoHideDuration: undefined,
    message: '',
    onClose: undefined,
    severity: undefined,
    visible: undefined,
  },
  key: 'snackbarState',
})
