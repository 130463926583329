import { atom } from 'recoil'
import {
  EncounterDTO,
  EncounterNoteDTO,
  EncounterNoteHistoryDTO,
  PersonnelDTO,
  StaffUserDTO,
  TemplateFormAssociationEntity,
} from 'services/openapi'

export interface EncounterServicesFormsAssociationsType {
  [key: string]: TemplateFormAssociationEntity[]
}

export interface EncounterServicesNotesType {
  [key: string]: EncounterNoteDTO[]
}

export interface EncounterServicesNoteHistoryType {
  [key: string]: EncounterNoteHistoryDTO[]
}

export interface EncounterServicesStaffType {
  [key: string]: StaffUserDTO[]
}

export interface EncounterServicesStaffPersonnelType {
  [key: string]: PersonnelDTO[]
}

export const encountersState = atom<EncounterDTO[] | undefined>({
  default: undefined,
  key: 'encountersState',
})

export const encounterServicesFormsAssociationsState = atom<EncounterServicesFormsAssociationsType>(
  {
    default: {},
    key: 'encounterServicesFormsAssociationsState',
  }
)

export const encounterServicesNotesState = atom<EncounterServicesNotesType>({
  default: {},
  key: 'encountersServicesNotesState',
})

export const encounterServicesNotesHistoryState = atom<EncounterServicesNoteHistoryType>({
  default: {},
  key: 'encounterServicesNotesHistoryState',
})

export const encounterServicesStaffState = atom<EncounterServicesStaffType>({
  default: {},
  key: 'encounterServicesStaffState',
})

export const encounterServicesStaffPersonnelState = atom<EncounterServicesStaffPersonnelType>({
  default: {},
  key: 'encounterServicesStaffPersonnelState',
})
