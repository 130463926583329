import { Grid, Stack, Typography } from '@mui/material'
import MISUploadIcon from 'common/components/icons/MISUploadIcon'
import MISButton from 'common/components/MISButton'
import './DocumentSectionHeader.scss'

type DocumentSectionHeaderProps = {
  onAdd?: () => void | { (): void }[]
}

const DocumentSectionHeader = ({ onAdd }: DocumentSectionHeaderProps) => {
  //add dialog for this here
  return (
    <div className="section-header" data-testid="section-header">
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={6}>
          <Typography component="span" variant="h1">
            Documents
          </Typography>
        </Grid>
        <Grid item textAlign="right" xs={6}>
          <Stack direction="row" sx={{ display: 'block' }}>
            <MISButton
              color="primary"
              onClick={onAdd}
              size="large"
              startIcon={<MISUploadIcon />}
              variant="text"
            >
              Upload a Document
            </MISButton>
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default DocumentSectionHeader
