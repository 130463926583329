import { Autocomplete, FormControl } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'
import MISTextField from './MISTextField'

type MISAutocompleteProps = {
  clearOnBlur?: boolean
  defaultValue?: any
  disabled?: boolean
  error?: boolean
  filterOptions?: any
  helperText?: string
  inputValue?: string
  label: string
  loading?: boolean
  onChange: (newValue: any) => void
  onFocus?: () => void
  onInputChange?: (newValue: string) => void
  options: any
  renderOption?: (props: any, option: any) => any
  required?: boolean
  value?: any
  placeholder?: string
}

const MISAutocomplete = (props: MISAutocompleteProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <FormControl>
        <Autocomplete
          {...props}
          onChange={(_, value) => props.onChange(value)}
          onInputChange={(_, value) => props.onInputChange?.(value)}
          renderInput={(params) => (
            <MISTextField
              {...params}
              error={props.error}
              helperText={props.helperText}
              label={props.label}
              placeholder={props.placeholder}
              required={props.required}
            />
          )}
          renderOption={props.renderOption}
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default MISAutocomplete
