import AccessibilityIcon from '@mui/icons-material/Accessibility'
import ArticleIcon from '@mui/icons-material/Article'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined'
import AllergyTemplate from '../../templates/allergy-template/AllergyTemplate'
import BodyTemplate from '../../templates/body-template/BodyTemplate'
import FollowUpTemplate from '../../templates/follow-up-template/FollowUpTemplate'
import FormBuilderTemplate from '../../templates/form-builder-template/FormBuilderTemplate'
import HeightWeightTemplate from '../../templates/height-weight-template/HeightWeightTemplate'
import ImageMarkerTemplate from '../../templates/image-marker-template/ImageMarkerTemplate'
import ImageTemplate from '../../templates/image-template/ImageTemplate'
import NoAccessTemplate from '../../templates/no-access-template/NoAccessTemplate'
import SOAPTemplate from '../../templates/soap-template/SOAPTemplate'
import VitalsTemplate from '../../templates/vitals-template/VitalsTemplate'

export const NO_ACCESS_TEMPLATE = {
  historical: 'timeline',
  id: 'NoAccessTemplate',
  key: 'charting.templates.image.label',
  name: 'NoAccessTemplate',
  template: NoAccessTemplate,
}

export const TEMPLATES = {
  AllergyTemplate: {
    historical: 'table',
    icon: <MedicalInformationIcon />,
    id: 'AllergyTemplate',
    key: 'charting.templates.allergy.label',
    name: 'AllergyTemplate',
    template: AllergyTemplate,
  },
  BodyTemplate: {
    historical: 'timeline',
    icon: <AccessibilityIcon />,
    id: 'BodyTemplate',
    key: 'charting.templates.body.label',
    name: 'BodyTemplate',
    template: BodyTemplate,
  },
  FollowUpTemplate: {
    historical: 'table',
    icon: <AssignmentIcon />,
    id: 'FollowUpTemplate',
    key: 'charting.templates.follow-up.label',
    name: 'FollowUpTemplate',
    template: FollowUpTemplate,
  },
  FormBuilderTemplate: {
    historical: 'timeline',
    icon: <ArticleIcon />,
    id: 'FormBuilderTemplate',
    key: 'charting.templates.form-builder.label',
    name: 'FormBuilderTemplate',
    template: FormBuilderTemplate,
  },
  HeightWeightTemplate: {
    historical: 'table',
    icon: <FitnessCenterIcon />,
    id: 'HeightWeightTemplate',
    key: 'charting.templates.height-weight.label',
    name: 'HeightWeightTemplate',
    template: HeightWeightTemplate,
  },
  ImageMarkerTemplate: {
    historical: 'timeline',
    icon: <ImageOutlinedIcon />,
    id: 'ImageMarkerTemplate',
    key: 'charting.templates.image-marker.label',
    name: 'ImageMarkerTemplate',
    template: ImageMarkerTemplate,
  },
  ImageTemplate: {
    historical: 'timeline',
    icon: <ImageOutlinedIcon />,
    id: 'ImageTemplate',
    key: 'charting.templates.image.label',
    name: 'ImageTemplate',
    template: ImageTemplate,
  },
  SOAPTemplate: {
    historical: 'table',
    icon: <SubjectOutlinedIcon />,
    id: 'SOAPTemplate',
    key: 'charting.templates.soap.label',
    name: 'SOAPTemplate',
    template: SOAPTemplate,
  },
  VitalsTemplate: {
    historical: 'table',
    icon: <MonitorHeartOutlinedIcon />,
    id: 'VitalsTemplate',
    key: 'charting.templates.vitals.label',
    name: 'VitalsTemplate',
    template: VitalsTemplate,
  },
}

export default TEMPLATES
