/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgencyIdentifier } from '../models/AgencyIdentifier'
import type { AgencyIdentifierDTO } from '../models/AgencyIdentifierDTO'
import type { PageAgencyIdentifierDTO } from '../models/PageAgencyIdentifierDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class IdentifierControllerService {
  /**
   * Returns a paged list of Identifiers for Agency
   * List is returned as Paged collection
   * @param agencyId
   * @returns PageAgencyIdentifierDTO OK
   * @throws ApiError
   */
  public static listAgencyIdentifiers(
    agencyId: string
  ): CancelablePromise<PageAgencyIdentifierDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/identifiers',
      path: {
        agencyId: agencyId,
      },
    })
  }

  /**
   * Updates the list of Agency Idenfitiers
   * updates the list and then returns as UnPaged collection
   * @param agencyId
   * @param requestBody
   * @returns AgencyIdentifierDTO OK
   * @throws ApiError
   */
  public static updateAgencyIdentifier(
    agencyId: string,
    requestBody: Array<AgencyIdentifier>
  ): CancelablePromise<Array<AgencyIdentifierDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/identifiers',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param agencyId
   * @param identifierId
   * @returns AgencyIdentifier OK
   * @throws ApiError
   */
  public static getAgencyIdentifierById(
    agencyId: string,
    identifierId: string
  ): CancelablePromise<AgencyIdentifier> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/identifiers/{identifierId}',
      path: {
        agencyId: agencyId,
        identifierId: identifierId,
      },
    })
  }

  /**
   * @param agencyId
   * @param identifierId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteAgencyIdentifier(
    agencyId: string,
    identifierId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/identifiers/{identifierId}',
      path: {
        agencyId: agencyId,
        identifierId: identifierId,
      },
    })
  }
}
