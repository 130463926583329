import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISChip from 'common/components/form/MISChip'
import { toHHMM } from 'common/components/form/MISDurationField'
import MISButton from 'common/components/MISButton'
import { getNameFromId } from 'common/utils/CodedConceptUtils'
import { programsAtom, purposesAtom, typesAtom } from 'recoil/atoms'
import { encountersState } from 'recoil/encounters'
import { terminologySelector } from 'recoil/terminology'
import { CodedConceptDto, EncounterDTO, EncounterServiceDTO } from 'services/openapi'
import { GovernanceAgencyControllerService } from 'services/openapi/services/GovernanceAgencyControllerService'
import { MIS_ENCOUNTER_STATE } from 'services/terminologyConstants'

import './EncountersHeader.scss'

const getTotalDurationForEncounter = (encounter: EncounterDTO | undefined): string => {
  let retVal = 0
  if (encounter && encounter.encounterServices) {
    encounter.encounterServices.forEach((service) => (retVal += service.duration || 0))
  }
  return toHHMM(retVal)
}

type KeyValueType = {
  key: string
  value: string
}

type EncountersHeaderProps = {
  onSave: () => void
  primaryService: EncounterServiceDTO | undefined
  saveLabel?: string
}

const EncountersHeader = ({
  onSave,
  primaryService,
  saveLabel = 'Save',
}: EncountersHeaderProps) => {
  const { t } = useTranslation('common')
  const encounters = useRecoilValue(encountersState)
  const purposeOptions = useRecoilValue(purposesAtom)
  const typeOptions = useRecoilValue(typesAtom)
  const programOptions = useRecoilValue(programsAtom)
  const encounterStateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_STATE))

  const [encounter, setEncounter] = useState<EncounterDTO | undefined>(undefined)
  const [duration, setDuration] = useState('')
  const [location, setLocation] = useState<CodedConceptDto>({})

  useEffect(
    () =>
      setEncounter(encounters?.find((encounter) => encounter.id === primaryService?.encounterId)),
    [encounters, primaryService?.encounterId]
  )

  useEffect(() => setDuration(getTotalDurationForEncounter(encounter)), [encounter, primaryService])

  useEffect(() => {
    const losPrimaryService = primaryService?.locationOfService || ''
    const getLocation = async () => {
      const location = await GovernanceAgencyControllerService.getAgencyById(losPrimaryService)
      if (location) {
        setLocation(location)
      }
    }
    if (losPrimaryService) getLocation()
  }, [primaryService])

  const values: KeyValueType[] = useMemo(
    () => [
      {
        key: 'Date/Time',
        value: [primaryService?.encounterServiceDate, primaryService?.encounterServiceTime]
          .join(' ')
          .toString(),
      },
      {
        key: 'Program',
        value: getNameFromId(programOptions, primaryService?.programId) || '',
      },
      {
        key: 'Purpose',
        value: getNameFromId(purposeOptions, primaryService?.purposeId) || '',
      },
      {
        key: 'Type',
        value: getNameFromId(typeOptions, primaryService?.typeId) || '',
      },
      {
        key: 'Duration',
        value: duration || '',
      },
      {
        key: 'Location',
        value: location?.name || '',
      },
    ],
    [
      duration,
      location?.name,
      primaryService?.encounterServiceDate,
      primaryService?.encounterServiceTime,
      primaryService?.programId,
      primaryService?.purposeId,
      primaryService?.typeId,
      programOptions,
      purposeOptions,
      typeOptions,
    ]
  )

  return (
    <Box className="encounter-header">
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={10}>
          <Stack spacing={0}>
            <Typography className="title">{primaryService?.synopsis || ''}</Typography>
            <MISChip
              color="primary"
              label={
                encounterStateOptions.find(
                  (opt) => encounter?.associations?.state?.code === opt.code
                )?.name
              }
            />
          </Stack>
        </Grid>
        <Grid item textAlign="right" xs={2}>
          <MISButton color="primary" onClick={onSave} variant="contained">
            {saveLabel || t('common.button.save')}
          </MISButton>
        </Grid>
      </Grid>

      <Box className="value-container">
        {values.map(({ key, value }: KeyValueType) => {
          return (
            <Box className="key-value-pair" key={key} sx={{ mr: 4 }}>
              <Typography variant="h4">{key}</Typography>
              <Typography>{value}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default EncountersHeader
