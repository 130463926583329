import React, { useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import './CardTemplate.scss'

const CardTemplate = (props) => {
  const [isExpand, setExpand] = useState(true)

  const updateExpandState = () => {
    setExpand(!isExpand)
  }

  return (
    <div className="card-template">
      <Box sx={props.headStyle}>
        <Box sx={props.bodyStyle}>
          <Typography component="div" gutterBottom variant="h1">
            {props.title}
            {!props.title && !isExpand && props.onCollapseLabel}
          </Typography>
          {isExpand && props.title && <Divider className="divider" />}
          {isExpand && props.children}
        </Box>
      </Box>
      {props.isCollapsible && (
        <IconButton
          aria-label="expand button"
          className="expand"
          color="primary"
          component="label"
          onClick={updateExpandState}
        >
          {isExpand && <ExpandLessIcon className="icon" />}
          {!isExpand && <ExpandMoreIcon className="icon" />}
        </IconButton>
      )}
    </div>
  )
}

export default CardTemplate
