import { ElementType, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { FormControlLabel, Grid, InputBaseComponentProps, Typography } from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { dateNowIsoString } from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { MIS_LENGTH_UNITS, MIS_WEIGHT_UNITS } from 'services/terminologyConstants'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import { HeightWeightTemplateState, THeightWeightTemplateValue } from './HeightWeightTemplate'

type HeightWeightTemplateComponentProps = HeightWeightTemplateState & {
  onChange?: (key: string, value: THeightWeightTemplateValue) => void
}

const HeightWeightTemplateComponent = ({
  bmi,
  createdBy,
  createdOn,
  height,
  heightUnit,
  onChange,
  underTwo,
  weight,
  weightUnit,
}: HeightWeightTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const userId = useSelector(selectUserId)
  const providers = useProviders()

  const lengthUnits = useMemo(
    () =>
      terminology
        .find((term) => term.setName === MIS_LENGTH_UNITS)
        ?.value.filter((each) => each.name === 'cm' || each.name === 'in') || [],
    [terminology]
  )

  const defaultLengthUnit = useMemo(
    () => lengthUnits.find((each) => each.name === 'cm'),
    [lengthUnits]
  )

  const weightUnits = useMemo(
    () =>
      terminology
        .find((term) => term.setName === MIS_WEIGHT_UNITS)
        ?.value.filter(
          (each) => each.name === 'kg' || each.name === 'grams' || each.name === 'lb'
        ) || [],
    [terminology]
  )

  const defaultWeightUnit = useMemo(
    () => weightUnits.find((each) => each.name === 'kg'),
    [weightUnits]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const handleChange = useCallback(
    (key: string, value: THeightWeightTemplateValue) => onChange?.(key, value),
    [onChange]
  )

  useEffect(() => {
    if (height && heightUnit && weight && weightUnit && !underTwo) {
      const convertedHeight = (heightUnit.name === 'cm' ? height : height * 2.54) / 100
      let convertedWeight = weight
      if (weightUnit.name === 'grams') convertedWeight /= 1000
      if (weightUnit.name === 'lb') convertedWeight *= 0.454
      const calculatedBmi = (convertedWeight / (convertedHeight * convertedHeight)).toFixed(1)
      handleChange('bmi', Number.parseFloat(calculatedBmi))
    } else handleChange('bmi', undefined)
  }, [height, heightUnit, weight, weightUnit, underTwo, bmi, handleChange])

  useEffect(() => {
    if (heightUnit === undefined && defaultLengthUnit) handleChange('heightUnit', defaultLengthUnit)
    if (weightUnit === undefined && defaultWeightUnit) handleChange('weightUnit', defaultWeightUnit)
    if (createdBy === undefined && loggedInProvider?.id)
      handleChange('createdBy', loggedInProvider.id)
    if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
  }, [
    createdBy,
    createdOn,
    defaultLengthUnit,
    defaultWeightUnit,
    handleChange,
    heightUnit,
    loggedInProvider,
    weightUnit,
  ])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Typography component="h1">{t('charting.templates.height-weight.label')}</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <FormControlLabel
            checked={!!underTwo}
            control={<MISCheckbox />}
            label={t('charting.templates.height-weight.fields.under-two')}
            onChange={(_, checked) => handleChange('underTwo', checked)}
          />
        </Grid>
        <Grid item xs={3}>
          <MISTextField
            InputLabelProps={{ shrink: height !== undefined }}
            InputProps={{
              inputComponent: NumericFormat as ElementType<InputBaseComponentProps>,
              inputProps: {
                allowLeadingZeros: false,
                allowNegative: false,
                decimalScale: 2,
              },
            }}
            label={
              underTwo
                ? t('charting.templates.height-weight.fields.length')
                : t('charting.templates.height-weight.fields.height')
            }
            onChange={(e) => {
              handleChange(
                underTwo ? 'length' : 'height',
                Number.parseFloat(e.target.value) > 999.99
                  ? 999.99
                  : Number.parseFloat(e.target.value)
              )
            }}
            value={height}
          />
        </Grid>
        <Grid item xs={2}>
          <MISSelectDropdown
            label={t('charting.templates.height-weight.fields.unit')}
            noClearIcon
            onChange={(e) => handleChange(underTwo ? 'lengthUnit' : 'heightUnit', e.target.value)}
            options={lengthUnits.map((each) => ({ label: each.name as string, value: each }))}
            value={lengthUnits?.find((each) => each.id === heightUnit?.id)}
          />
        </Grid>
        <Grid item xs={3}>
          <MISTextField
            InputLabelProps={{ shrink: weight !== undefined }}
            InputProps={{
              inputComponent: NumericFormat as ElementType<InputBaseComponentProps>,
              inputProps: {
                allowLeadingZeros: false,
                allowNegative: false,
                decimalScale: 2,
                thousandSeparator: true,
              },
            }}
            label={t('charting.templates.height-weight.fields.weight')}
            onChange={(e) => {
              handleChange(
                'weight',
                Number.parseFloat(e.target.value.replace(',', '')) > 99999.99
                  ? 9999.99
                  : Number.parseFloat(e.target.value.replace(',', ''))
              )
            }}
            value={weight}
          />
        </Grid>
        <Grid item xs={2}>
          <MISSelectDropdown
            label={t('charting.templates.height-weight.fields.unit')}
            noClearIcon
            onChange={(e) => handleChange('weightUnit', e.target.value)}
            options={weightUnits.map((each) => ({ label: each.name as string, value: each }))}
            value={weightUnits?.find((each) => each.id === weightUnit?.id)}
          />
        </Grid>
        <Grid item xs={2}>
          <MISTextField
            InputLabelProps={{ shrink: !!(bmi && !underTwo) }}
            InputProps={{ readOnly: true }}
            disabled={underTwo}
            label={t('charting.templates.height-weight.fields.bmi')}
            value={bmi && !underTwo ? bmi : ''}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default HeightWeightTemplateComponent
