import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
  ltrCssLoader,
  rsErrorMessage,
  RsLocalizationWrapper,
  rsTooltip,
  rSuiteComponents,
  rtlCssLoader,
} from '@react-form-builder/components-rsuite'
import { BiDi, IFormViewer } from '@react-form-builder/core'
import { BuilderView, FormBuilder, IFormBuilder, IFormStorage } from '@react-form-builder/designer'
import { MISButtonForm } from './form-components/MISButtonForm'

interface DesignerProps {
  apiData: string
  formName: string
}

// Here you can pass the metadata of your components
const componentsMetadata = rSuiteComponents.map((definer) => definer.build())
componentsMetadata.push(MISButtonForm.build())

const formStorage: IFormStorage | undefined = undefined

const builderView = new BuilderView(componentsMetadata)
  // You can pass multiple components to display error messages by calling the withErrorMeta function multiple times
  .withErrorMeta(rsErrorMessage.build())
  // You can pass multiple components to display tooltip messages by calling the withTooltipMeta function multiple times
  .withTooltipMeta(rsTooltip.build())
  // Pass an array of template names to the withTemplates function to display them in the designer
  .withTemplates([])
  // The following parameters are required for correct CSS loading in LTR and RTL modes
  .withViewerWrapper(RsLocalizationWrapper)
  .withCssLoader(BiDi.LTR, ltrCssLoader)
  .withCssLoader(BiDi.RTL, rtlCssLoader)

// Example form, in JSON format
const emptyForm = `
{
  "version": "1",
  "tooltipType": "RsTooltip",
  "errorType": "RsErrorMessage",
  "form": {
    "key": "Screen",
    "type": "Screen",
    "props": {},
    "children": [
      
    ]
  },
  "localization": {},
  "languages": [
  ],
  "defaultLanguage": "en-US"
}
`

const Designer = forwardRef(({ apiData, formName }: DesignerProps, ref) => {
  const viewRef = useRef<IFormViewer>(null)
  const builderRef = useRef<IFormBuilder>(null)
  const [key, setKey] = useState(0)

  useImperativeHandle(ref, () => ({
    getBuilder: () => builderRef.current?.formAsString,
  }))

  const updateFormData = useCallback(() => {
    if (apiData) return apiData
    else return emptyForm
  }, [apiData])

  useEffect(() => {
    if (apiData) {
      setKey((prev) => prev + 1)
    }
  }, [apiData])

  return (
    <FormBuilder
      actions={{
        logEventArgs: (e) => console.log(e),
      }}
      builderRef={builderRef}
      formName="Exmaple"
      formStorage={formStorage}
      getForm={updateFormData}
      initialData={{}}
      key={key}
      onFormDataChange={({ data, errors }) => {
        console.log('onFormDataChange', { data, errors })
      }}
      view={builderView}
      viewerRef={viewRef}
    />
  )
})
Designer.displayName = 'Designer'
export default Designer
