/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramDomainAssociation } from '../models/ProgramDomainAssociation'
import type { ProgramDomainAssociationTerse } from '../models/ProgramDomainAssociationTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ProgramDomainAssociationControllerService {
  /**
   * @param id
   * @param requestBody
   * @returns ProgramDomainAssociationTerse OK
   * @throws ApiError
   */
  public static updateProgramDomainAssociation(
    id: string,
    requestBody: ProgramDomainAssociation
  ): CancelablePromise<ProgramDomainAssociationTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/program-domain-associations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
