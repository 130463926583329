import { ElementType, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Grid, InputBaseComponentProps } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import { dateNowIsoString } from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { selectUserId } from 'store/selectors/user'
import { VitalsTemplateState } from './VitalsTemplate'

type VitalsTemplateComponentProps = VitalsTemplateState & {
  onChange?: (key: string, value: number | string) => void
}

const VitalsTemplateComponent = ({
  createdBy,
  createdOn,
  diastolic,
  onChange,
  systolic,
}: VitalsTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const userId = useSelector(selectUserId)
  const providers = useProviders()

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const handleChange = useCallback(
    (key: string, value: number | string) => onChange?.(key, value),
    [onChange]
  )

  useEffect(() => {
    if (loggedInProvider && createdBy === undefined && loggedInProvider.id)
      handleChange('createdBy', loggedInProvider.id)
    if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
  }, [createdBy, createdOn, handleChange, loggedInProvider])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <MISTextField
          InputProps={{
            inputComponent: PatternFormat as ElementType<InputBaseComponentProps>,
            inputProps: { format: '###' },
          }}
          label={t('charting.templates.vitals.systolic')}
          onChange={(e) => handleChange('systolic', Number.parseInt(e.target.value))}
          placeholder="120"
          value={systolic}
        />
      </Grid>
      <Grid item xs={2}>
        <MISTextField
          InputProps={{
            inputComponent: PatternFormat as ElementType<InputBaseComponentProps>,
            inputProps: { format: '###' },
          }}
          label={t('charting.templates.vitals.diastolic')}
          onChange={(e) => handleChange('diastolic', Number.parseInt(e.target.value))}
          placeholder="80"
          value={diastolic}
        />
      </Grid>
    </Grid>
  )
}

export default VitalsTemplateComponent
