/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityAssociationCreationDTO } from '../models/EntityAssociationCreationDTO'
import type { PagePurposeTerse } from '../models/PagePurposeTerse'
import type { Purpose } from '../models/Purpose'
import type { PurposeGroupAssociation } from '../models/PurposeGroupAssociation'
import type { PurposeGroupAssociationTerse } from '../models/PurposeGroupAssociationTerse'
import type { PurposeTerse } from '../models/PurposeTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PurposeControllerService {
  /**
   * @param id
   * @returns PurposeTerse OK
   * @throws ApiError
   */
  public static getPurposeById(id: string): CancelablePromise<PurposeTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purposes/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns PurposeTerse OK
   * @throws ApiError
   */
  public static updatePurpose(id: string, requestBody: Purpose): CancelablePromise<PurposeTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/purposes/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePurposeTerse OK
   * @throws ApiError
   */
  public static listPurposes(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePurposeTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purposes',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns PurposeTerse OK
   * @throws ApiError
   */
  public static createPurpose(requestBody: Purpose): CancelablePromise<PurposeTerse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/purposes',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns PurposeGroupAssociationTerse OK
   * @throws ApiError
   */
  public static getPurposeGroupAssociationsForPurpose(
    id: string
  ): CancelablePromise<Array<PurposeGroupAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purposes/{id}/groups',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns PurposeGroupAssociation OK
   * @throws ApiError
   */
  public static addPurposeGroupToPurpose(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<PurposeGroupAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/purposes/{id}/groups',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param activeOnly
   * @param name
   * @param programId
   * @param typeId
   * @returns PurposeTerse OK
   * @throws ApiError
   */
  public static searchPurposes(
    activeOnly: boolean = false,
    name?: string,
    programId?: string,
    typeId?: string
  ): CancelablePromise<Array<PurposeTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/purposes/search',
      query: {
        activeOnly: activeOnly,
        name: name,
        programId: programId,
        typeId: typeId,
      },
    })
  }
}
