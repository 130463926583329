import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import MISButton from 'common/components/MISButton'

/*
Modal which appears when the user has been idle for the amount of time
specified in the system. Modal warns the user about their inactivity and
can be used to continue staying signed into the system.
*/
const TimeoutModal = ({ handleStayLoggedIn, showModal, time }: any) => {
  return (
    <div>
      <Modal
        aria-describedby="modal-modal-description"
        aria-labelledby="modal-modal-title"
        open={showModal}
      >
        <Box className="timeout-modal-container">
          <h5 className="modal-title">Session Timeout</h5>
          <p className="separator" />
          <p>
            Your session is about to expire in {time} seconds due to inactivity. You will be
            redirected to the sign in page and any changes which have not been saved will be lost.
          </p>
          <Box display="flex" justifyContent="flex-end" marginTop="2rem">
            <MISButton onClick={handleStayLoggedIn}>Stay Logged In</MISButton>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default TimeoutModal
