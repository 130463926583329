import { useCallback, useRef } from 'react'
import ReactQuill from 'react-quill'
import { CSSProperties } from 'styled-components'
import 'react-quill/dist/quill.snow.css'

interface MISReactQuillProps {
  className?: string
  style?: CSSProperties
  key?: number
  placeholder?: string
  noteText?: string
  onContentChange: (...args: any[]) => any
  MAX_NOTE_TEXT_LENGTH: number
}

const MISReactQuill = (props: MISReactQuillProps) => {
  const quillRef = useRef<ReactQuill>(null)

  const getQuillPlainText = useCallback(() => {
    if (quillRef && quillRef.current) {
      const quillPlainText = quillRef.current.getEditor().getText()
      // quill add a blank line even after text is deleted, so need to remove it
      return quillPlainText.replace('\n', '')
    }
  }, [])

  const isValidNoteTextLength = useCallback(() => {
    const noteContent = getQuillPlainText()
    if (noteContent && noteContent.length > props.MAX_NOTE_TEXT_LENGTH) {
      quillRef.current?.getEditor().deleteText(props.MAX_NOTE_TEXT_LENGTH, noteContent.length)
      return false
    }
    return true
  }, [getQuillPlainText, props.MAX_NOTE_TEXT_LENGTH])

  const validateAndChangeContent = useCallback(
    (content: string) => {
      if (isValidNoteTextLength()) {
        props.onContentChange(content)
      }
    },
    [isValidNoteTextLength, props]
  )

  return (
    <ReactQuill
      className={props.className}
      key={props.key}
      onChange={validateAndChangeContent}
      placeholder={props.placeholder}
      ref={quillRef}
      style={props.style}
      theme="snow"
      value={props.noteText || ''}
    />
  )
}

export default MISReactQuill
