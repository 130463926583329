import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
  ltrCssLoader,
  RsLocalizationWrapper,
  rSuiteComponents,
  rtlCssLoader,
} from '@react-form-builder/components-rsuite'
import { BiDi, createView, FormViewer, IFormViewer } from '@react-form-builder/core'
import { MISButtonForm } from './form-components/MISButtonForm'

interface ViewerProps {
  apiData: string
}

// Here you can pass the metadata of your components
const componentsMetadata = rSuiteComponents.map((definer) => definer.build().model)
componentsMetadata.push(MISButtonForm.build().model)

const view = createView(componentsMetadata)
  // The following parameters are required for correct CSS loading in LTR and RTL modes
  .withViewerWrapper(RsLocalizationWrapper)
  .withCssLoader(BiDi.LTR, ltrCssLoader)
  .withCssLoader(BiDi.RTL, rtlCssLoader)
// Example form, in JSON format
const emptyForm = `
{
  "version": "1",
  "tooltipType": "RsTooltip",
  "errorType": "RsErrorMessage",
  "form": {
    "key": "Screen",
    "type": "Screen",
    "props": {},
    "children": [
      
    ]
  },
  "localization": {},
  "languages": [
  ],
  "defaultLanguage": "en-US"
}
`

const Viewer = forwardRef(({ apiData }: ViewerProps, ref) => {
  const viewRef = useRef<IFormViewer>(null)
  const [key, setKey] = useState(0)

  useImperativeHandle(ref, () => ({
    getViewerData: () => viewRef.current?.formData.data,
  }))

  const updateFormData = useCallback(() => {
    if (apiData) return apiData
    else return emptyForm
  }, [apiData])

  useEffect(() => {
    if (apiData) {
      setKey((prev) => prev + 1)
    }
  }, [apiData])

  useEffect(() => {
    if (viewRef.current) {
      // if you want to work with the internal FormViewer component in an imperative style
      console.log('Viewer', viewRef.current)
    }
  }, [])

  return (
    <FormViewer
      formName="Exmaple"
      getForm={updateFormData}
      initialData={{}}
      key={key}
      onFormDataChange={({ data, errors }) => {
        console.log('onFormDataChange', { data, errors })
      }}
      view={view}
      viewerRef={viewRef}
    />
  )
})
Viewer.displayName = 'Viewer'
export default Viewer
