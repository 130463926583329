import { SvgIcon } from '@mui/material'

const MISDashboardIcon = () => {
  return (
    <SvgIcon sx={{ padding: '2px' }} viewBox="0 0 20 20">
      <svg height="20px" version="1.1" width="20px">
        <g transform="matrix(1 0 0 1 -34 -142 )">
          <path
            d="M 20 6.25  L 11.875 6.25  L 11.875 0  L 20 0  L 20 6.25  Z M 8.125 10  L 0 10  L 0 0  L 8.125 0  L 8.125 10  Z M 20 20  L 11.875 20  L 11.875 10  L 20 10  L 20 20  Z M 8.125 20  L 0 20  L 0 13.75  L 8.125 13.75  L 8.125 20  Z M 1.25 1.25  L 1.25 8.75  L 6.875 8.75  L 6.875 1.25  L 1.25 1.25  Z M 13.125 11.25  L 13.125 18.75  L 18.75 18.75  L 18.75 11.25  L 13.125 11.25  Z M 13.125 1.25  L 13.125 5  L 18.75 5  L 18.75 1.25  L 13.125 1.25  Z M 1.25 15  L 1.25 18.75  L 6.875 18.75  L 6.875 15  L 1.25 15  Z "
            fill="#666666"
            fillRule="nonzero"
            stroke="none"
            transform="matrix(1 0 0 1 34 142 )"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default MISDashboardIcon
