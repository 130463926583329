import { useEffect, useRef, useState } from 'react'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'
import background from 'assets/images/logo-bg.png'
import { Colours } from '../styles/Colours'

function DomainSelection({ onBreadcrumb }) {
  const [healthHover, setHealthHover] = useState(false)
  const [cfsHover, setCfsHover] = useState(false)
  const [housingHover, setHousingHover] = useState(false)
  const [educationHover, setEducationHover] = useState(false)
  const [socialServicesHover, setSocialServicesHover] = useState(false)
  const timer = useRef()

  // Clear the timer when the user is active to avoid automatically signing them out for being idle
  useEffect(() => {
    const currentTimer = timer.current

    return () => {
      clearTimeout(currentTimer)
    }
  }, [onBreadcrumb])

  return (
    <div>
      <div>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
          <Fab
            aria-label="save"
            sx={{
              '&:hover': {
                backgroundColor: Colours.white,
              },
              backgroundColor: Colours.white,
              backgroundImage: `url(${background})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '25rem 25rem',
              height: '30rem',
              m: 5,
              width: '30rem',
            }}
          />
          <div>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ m: 4, marginLeft: -5, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  onMouseEnter={() => setHealthHover(true)}
                  onMouseLeave={() => setHealthHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.neutral_200,
                    },
                    backgroundColor: healthHover ? Colours.neutral_200 : Colours.white,
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <HealthAndSafetyOutlinedIcon sx={{ color: Colours.primary, fontSize: 35 }} />
                </Fab>
                <Fab
                  color="primary"
                  onMouseEnter={() => setHealthHover(true)}
                  onMouseLeave={() => setHealthHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.primary,
                    },
                    background: healthHover ? Colours.primary : Colours.secondary,
                    fontSize: 'large',
                    marginLeft: '-2rem',
                    paddingLeft: '3rem',
                    textTransform: 'capitalize',
                    zIndex: '0',
                  }}
                  variant="extended"
                >
                  Health
                </Fab>
              </Box>
            </Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ m: 1, marginBottom: 2, marginLeft: 5, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  onMouseEnter={() => setCfsHover(true)}
                  onMouseLeave={() => setCfsHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.neutral_200,
                    },
                    backgroundColor: cfsHover ? Colours.neutral_200 : Colours.white,
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <FamilyRestroomOutlinedIcon sx={{ color: Colours.primary, fontSize: 35 }} />
                </Fab>
                <Fab
                  color="primary"
                  onMouseEnter={() => setCfsHover(true)}
                  onMouseLeave={() => setCfsHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.primary,
                    },
                    background: cfsHover ? Colours.primary : Colours.secondary,
                    fontSize: 'large',
                    marginLeft: '-2rem',
                    paddingLeft: '3rem',
                    textTransform: 'capitalize',
                    zIndex: '0',
                  }}
                  variant="extended"
                >
                  CFS
                </Fab>
              </Box>
            </Box>

            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ m: 5, marginLeft: 9, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  onMouseEnter={() => setHousingHover(true)}
                  onMouseLeave={() => setHousingHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.neutral_200,
                    },
                    backgroundColor: housingHover ? Colours.neutral_200 : Colours.white,
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <HomeOutlinedIcon sx={{ color: Colours.primary, fontSize: 35 }} />
                </Fab>
                <Fab
                  color="primary"
                  onMouseEnter={() => setHousingHover(true)}
                  onMouseLeave={() => setHousingHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.primary,
                    },
                    background: housingHover ? Colours.primary : Colours.secondary,
                    fontSize: 'large',
                    marginLeft: '-2rem',
                    paddingLeft: '3rem',
                    textTransform: 'capitalize',
                    zIndex: '0',
                  }}
                  variant="extended"
                >
                  Housing
                </Fab>
              </Box>
            </Box>

            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ m: 3, marginLeft: 5, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  onMouseEnter={() => setEducationHover(true)}
                  onMouseLeave={() => setEducationHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.neutral_200,
                    },
                    backgroundColor: educationHover ? Colours.neutral_200 : Colours.white,
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <SchoolOutlinedIcon sx={{ color: Colours.primary, fontSize: 35 }} />
                </Fab>
                <Fab
                  color="primary"
                  onMouseEnter={() => setEducationHover(true)}
                  onMouseLeave={() => setEducationHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.primary,
                    },
                    background: educationHover ? Colours.primary : Colours.secondary,
                    fontSize: 'large',
                    marginLeft: '-2rem',
                    paddingLeft: '3rem',
                    textTransform: 'capitalize',
                    zIndex: '0',
                  }}
                  variant="extended"
                >
                  Education
                </Fab>
              </Box>
            </Box>

            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ m: 1, marginLeft: -5, position: 'relative' }}>
                <Fab
                  aria-label="save"
                  onMouseEnter={() => setSocialServicesHover(true)}
                  onMouseLeave={() => setSocialServicesHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.neutral_200,
                    },
                    backgroundColor: socialServicesHover ? Colours.neutral_200 : Colours.white,
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <VolunteerActivismOutlinedIcon sx={{ color: Colours.primary, fontSize: 35 }} />
                </Fab>
                <Fab
                  color="primary"
                  onMouseEnter={() => setSocialServicesHover(true)}
                  onMouseLeave={() => setSocialServicesHover(false)}
                  sx={{
                    '&:hover': {
                      backgroundColor: Colours.primary,
                    },
                    background: socialServicesHover ? Colours.primary : Colours.secondary,
                    fontSize: 'large',
                    marginLeft: '-2rem',
                    paddingLeft: '3rem',
                    textTransform: 'capitalize',
                    zIndex: '0',
                  }}
                  variant="extended"
                >
                  Social Services
                </Fab>
              </Box>
            </Box>
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default DomainSelection
