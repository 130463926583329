import { atom } from 'recoil'
import { CodedConceptDto, HealthConcernDTO, HealthConcernStateDTO } from 'services/openapi'

export const HEALTH_CONCERN_CODE_SYSTEM_OID = '2.16.840.1.113883.6.96'

export interface HealthConcernType {
  healthConcern: HealthConcernDTO
  states: HealthConcernStateDTO[]
}

export interface HealthConcernsType {
  [key: string]: HealthConcernType
}

export const healthConcernsClientIdState = atom<string | null>({
  default: null,
  key: 'healthConcernsClientIdState',
})

export const healthConcernsState = atom<HealthConcernsType | null>({
  default: null,
  key: 'healthConcernsState',
})

// health concern terminology requires dynamic build up during runtime
// the terminology data from db is too large to fetch
export const healthConcernTerminology = atom<CodedConceptDto[]>({
  default: [],
  key: 'healthConcernTerminology',
})
