/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImmunizationShotDTO } from '../models/ImmunizationShotDTO'
import type { PageImmunizationShotDTO } from '../models/PageImmunizationShotDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ImmunizationShotControllerService {
  /**
   * Search Immunization shots by name, agentCode, identifierType and identifierValue
   * @param name
   * @param agentCode
   * @param identifierType
   * @param identifierValue
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageImmunizationShotDTO OK
   * @throws ApiError
   */
  public static searchAllImmunizationShots(
    name?: string,
    agentCode?: string,
    identifierType?: string,
    identifierValue?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageImmunizationShotDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/immunizations/shots',
      query: {
        name: name,
        agentCode: agentCode,
        identifierType: identifierType,
        identifierValue: identifierValue,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ImmunizationShotDTO OK
   * @throws ApiError
   */
  public static putImmunizationShots(
    requestBody: Array<ImmunizationShotDTO>
  ): CancelablePromise<Array<ImmunizationShotDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/immunizations/shots',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param shotId
   * @returns ImmunizationShotDTO OK
   * @throws ApiError
   */
  public static getImmunizationShotById(shotId: string): CancelablePromise<ImmunizationShotDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/immunizations/shots/{shotId}',
      path: {
        shotId: shotId,
      },
    })
  }
}
