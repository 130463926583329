import { Button, Stack, Typography } from '@mui/material'

type TemplatesToolbarHeaderProps = {
  icon?: JSX.Element
  label: string
  onSelect: (key: string) => void
  itemContent?: string
  itemKey: string
  disabled?: boolean
}

const TemplatesToolbarItem = ({
  disabled,
  icon,
  itemContent,
  itemKey,
  label,
  onSelect,
}: TemplatesToolbarHeaderProps) => {
  return (
    <Button
      disabled={disabled}
      fullWidth
      style={{ color: '#333', justifyContent: 'flex-start' }}
      sx={{ textAlign: 'left', textTransform: 'none' }}
    >
      <Stack
        direction="row"
        onClick={() => (itemContent ? onSelect(itemContent) : onSelect(itemKey))}
        spacing={1}
        width="100%"
      >
        {icon}
        <Typography sx={{ color: '#333', fontSize: '10px', lineHeight: '24px' }}>
          {label}
        </Typography>
      </Stack>
    </Button>
  )
}

export default TemplatesToolbarItem
