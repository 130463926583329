import { Component } from 'react'
import { dateNowIsoString } from 'common/utils/DateUtils'
import { CodedConceptDto } from 'services/openapi'
import AllergyTemplateComponent from './AllergyTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type TAllergyTemplateValue = boolean | string | CodedConceptDto | undefined

export type AllergyTemplateState = {
  allergen?: CodedConceptDto
  allergenOther?: boolean
  allergenSpecified?: string
  type?: CodedConceptDto
  severity?: CodedConceptDto
  lifeStage?: CodedConceptDto
  reaction?: string[]
  note: string
  reportedBy?: CodedConceptDto
  lastUpdated?: string
  createdBy?: string
  createdOn?: string
}

export type AllergyTemplateProps = { data: AllergyTemplateState }

class AllergyTemplate
  extends Component<AllergyTemplateProps, AllergyTemplateState>
  implements ITemplate
{
  type = 'AllergyTemplate'
  state: AllergyTemplateState

  constructor(props: AllergyTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    allergen: { label: 'Allergen', type: ETemplateMetadataType.CodedConceptDto },
    allergenOther: { label: 'Other (allergen)', type: ETemplateMetadataType.boolean },
    allergenSpecified: {
      label: 'Allergen (please specify)',
      type: ETemplateMetadataType.string,
    },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    lastUpdated: { label: 'Last updated on', type: ETemplateMetadataType.Date },
    lifeStage: { label: 'Life stage', type: ETemplateMetadataType.CodedConceptDto },
    note: { label: 'Note', type: ETemplateMetadataType.string },
    reaction: { label: 'Reaction', type: ETemplateMetadataType.string },
    reportedBy: { label: 'Reported by', type: ETemplateMetadataType.CodedConceptDto },
    severity: { label: 'severity', type: ETemplateMetadataType.CodedConceptDto },
    type: { label: 'type', type: ETemplateMetadataType.CodedConceptDto },
  })

  static getTemplateDisplayName = (): string => 'Allergy Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    allergen: this.state.allergen,
    allergenOther: this.state.allergenOther,
    allergenSpecified: this.state.allergenSpecified,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    lastUpdated: this.state.lastUpdated,
    lifeStage: this.state.lifeStage,
    note: this.state.note,
    reaction: this.state.reaction,
    reportedBy: this.state.reportedBy,
    severity: this.state.severity,
    type: this.state.type,
  })

  handleChange = (key: string, value: TAllergyTemplateValue) => {
    if (key === 'allergenOther') {
      if (value)
        this.setState({
          ...this.state,
          allergen: undefined,
          allergenOther: true,
          lastUpdated: dateNowIsoString(),
        })
      else
        this.setState({
          ...this.state,
          allergenOther: false,
          allergenSpecified: undefined,
          lastUpdated: dateNowIsoString(),
        })
    } else this.setState({ ...this.state, [key]: value, lastUpdated: dateNowIsoString() })
  }

  render() {
    return <AllergyTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default AllergyTemplate
