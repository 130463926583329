import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import { useRecoilValue } from 'recoil'
import GLOBAL from 'common/styles/global.scss'
import StaffAssociation, {
  CustomStaffMemberProps,
} from 'modules/shared/StaffAssociation/StaffAssociation'
import {
  encounterServicesStaffPersonnelState,
  encounterServicesStaffState,
} from 'recoil/encounters'
import { EncounterDTO } from 'services/openapi'

type EncounterStaffSectionProps = {
  encounter: EncounterDTO
}

const EncounterStaffSection = ({ encounter }: EncounterStaffSectionProps) => {
  const { t } = useTranslation('common')
  const staff = useRecoilValue(encounterServicesStaffState)
  const staffPersonnel = useRecoilValue(encounterServicesStaffPersonnelState)

  const serviceHeader = useCallback(
    (synopsis: string, serviceName: string, isPrimary: boolean) => (
      <>
        <span>
          <b>{synopsis}</b>
        </span>
        <span>{` | ${serviceName}`}</span>
        {isPrimary && (
          <span>
            {' | '}
            <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{t('common.primary')}</span>
          </span>
        )}
      </>
    ),
    [t]
  )

  const getStaffAssociationMembers = useCallback(
    (serviceId: string) => {
      const members: CustomStaffMemberProps[] = []
      staff[serviceId]?.forEach((staff) => {
        const personnel = staffPersonnel[serviceId].find(
          (personnel) => personnel.id === staff.providerId
        )
        if (personnel) {
          members.push({
            associationId: staff.associationId,
            isPrimary: staff.attributes?.isPrimaryProvider,
            personnel,
          })
        }
      })
      return members
    },
    [staff, staffPersonnel]
  )

  return (
    <>
      {encounter?.id &&
        encounter?.encounterServices &&
        [...encounter.encounterServices]
          .sort((a, b) => (a?.isPrimaryService ? -1 : b?.isPrimaryService ? 1 : 0))
          .map((service) => (
            <div key={service.id}>
              <Divider sx={{ mb: GLOBAL.MARGIN_MD, ml: 0, mr: 0, mt: GLOBAL.MARGIN_MD }} />
              {serviceHeader(
                service.synopsis as string,
                service.name as string,
                service.isPrimaryService as boolean
              )}
              {staffPersonnel[service.id as string] &&
                staffPersonnel[service.id as string].length > 0 && (
                  <Box sx={{ mt: GLOBAL.MARGIN_MD }}>
                    <StaffAssociation
                      allowSearch={false}
                      allowUpdate={false}
                      displayPrimaryIndicator
                      displaySectionHeader={false}
                      members={getStaffAssociationMembers(service.id as string)}
                    />
                  </Box>
                )}
            </div>
          ))}
    </>
  )
}

export default EncounterStaffSection
