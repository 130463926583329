import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { idheServicesAtom } from 'recoil/atoms'
import { IdheServiceControllerService } from 'services/openapi'

type ServiceDataType = {
  name?: string
  description?: string
  startDate?: string
  endDate?: string
  isTerminated?: boolean
}

export default function ChartingServices() {
  const { t } = useTranslation('common')
  const [, setSelectedRow] = useState({})
  const updateAtomCall = useSetRecoilState(idheServicesAtom)

  const handleCreateService = useCallback((data: ServiceDataType) => {
    const { description, endDate, isTerminated, name, startDate } = data
    return IdheServiceControllerService.createService({
      description,
      effective: {
        endDate,
        isTerminated,
        startDate,
      },
      name,
    })
  }, [])

  const handleUpdateService = useCallback((id: string, data: ServiceDataType) => {
    const { description, endDate, isTerminated, name, startDate } = data
    return IdheServiceControllerService.updateService(id, {
      description,
      effective: {
        endDate,
        isTerminated,
        startDate,
      },
      id,
      name,
    })
  }, [])

  return (
    <Box sx={{ p: 2 }}>
      <h1>{t('charting-services.title.charting-services')}</h1>
      {t('charting-services.title.charting-service-description')}

      <EntityTemplate
        createEntityServiceCall={handleCreateService}
        entityName={t('charting-services.title.charting-services')}
        getEntitiesServiceCall={IdheServiceControllerService.getAllServices}
        onRowSelectCallback={setSelectedRow}
        updateAtomCall={updateAtomCall}
        updateEntityServiceCall={handleUpdateService}
      />
    </Box>
  )
}
