import { useCallback, useState } from 'react'
import { Autocomplete, Box, Grid } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import GLOBAL from 'common/styles/global.scss'

export default function DropdownSearch({
  error = false,
  helperText = '',
  isDisabled = false,
  label,
  onSearch,
  onSelect,
  options,
  required = false,
  value,
}) {
  const DEBOUNCE_TIME = 1000

  const [inputValue, setInputValue] = useState('')
  const [showLoadingProgress, setShowLoadingProgress] = useState(false)

  const handleSearch = useCallback(
    (searchText) => {
      let hideTimer = null
      if (searchText) {
        setShowLoadingProgress(true)
      }
      if (hideTimer !== null) {
        clearTimeout(hideTimer)
      }
      hideTimer = setTimeout(() => {
        if (searchText) {
          onSearch(searchText)
          setShowLoadingProgress(false)
        }
      }, DEBOUNCE_TIME)
      setInputValue(searchText)
    },
    [onSearch]
  )

  const createHighlightedContent = (splitText, searchText) => {
    return (
      <div>
        {splitText.map((txt, index) => {
          if (txt === searchText) {
            return (
              <span key={index} style={{ fontWeight: 'bold' }}>
                {txt}
              </span>
            )
          } else {
            return (
              <span key={index} style={{ color: 'gray' }}>
                {txt}
              </span>
            )
          }
        })}
      </div>
    )
  }

  const setTextHightlight = (text, searchText) => {
    if (!searchText) {
      return <span>{text}</span>
    } else {
      const regex = new RegExp(searchText, 'gi')
      const splitMatch = text.replace(regex, '|$&|')
      const splitText = splitMatch.split('|')
      if (splitText && splitText.length > 0) {
        return createHighlightedContent(splitText, searchText)
      } else {
        return <span>{splitText[0]}</span>
      }
    }
  }

  const handleRenderInput = (params) => {
    return (
      <MISTextField
        style={{ width: '100%' }}
        {...params}
        error={error}
        fullWidth
        helperText={helperText}
        label={label}
        required={required}
      />
    )
  }

  const handleRenderOption = (props, option) => {
    return (
      <li {...props} key={option.key}>
        <Grid alignItems="center" container>
          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            <Box component="span">{setTextHightlight(option.title, inputValue)}</Box>
          </Grid>
        </Grid>
      </li>
    )
  }

  return (
    <Autocomplete
      autoComplete
      disabled={isDisabled}
      filterOptions={(option) => option}
      filterSelectedOptions
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
      includeInputInList
      loading={showLoadingProgress}
      loadingText="Searching..."
      noOptionsText="No results found"
      onChange={(event, value) => onSelect(value)}
      onInputChange={(event, searchText) => handleSearch(searchText)}
      options={options}
      renderInput={(params) => handleRenderInput(params)}
      renderOption={(props, option) => handleRenderOption(props, option)}
      style={{ width: 'inherit' }}
      sx={{
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          padding: GLOBAL.MARGIN_XS,
        },
      }}
      value={value}
    />
  )
}
