import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { getCurrentTenant } from 'core/helpers/security/currentTenant'
import { isDirtyState } from 'recoil/isDirty'
import useNavigationBlocker from './useNavigationBlocker'

/*
This hook checks if user is navigating to different page, and calls
the useNavigationBlocker hook. This hook is invoked by useNavigationBlocker
to which we pass handleBlockedNavigation as blocker function, which decides
when the modal should be displayed
*/
const useUnsavedChangesPrompt = (isDirty: boolean) => {
  const navigate = useNavigate()
  const setIsDirty = useSetRecoilState(isDirtyState)
  const location = useLocation()
  const [displayPrompt, setDisplayPrompt] = useState(false)
  const [nextLocation, setNextLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const cancelNavigation = useCallback(() => {
    setDisplayPrompt(false)
    setNextLocation(null)
  }, [])

  // this function is passed as a blocker function to useNavigationBlocker
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // routerApp already adds basenmame in BrowserRouter as /currentTenant/,
      // so need to remove it, otherwise  /currentTenant/ will be added twice
      const nextPathWithTenant = nextLocation.location.pathname
      const currentTenant = getCurrentTenant().tenantId
      const regex = new RegExp('^/' + currentTenant, 'g')
      const nextPath = nextPathWithTenant.replace(regex, '')

      //only display prompt if next location is different then the current location
      if (!confirmedNavigation && nextPath !== location.pathname) {
        setDisplayPrompt(true)
        setNextLocation(nextPath)
        return false
      }
      return true
    },
    [confirmedNavigation, location]
  )

  const confirmNavigation = useCallback(() => {
    setIsDirty(false)
    setDisplayPrompt(false)
    setConfirmedNavigation(true)
  }, [setIsDirty])

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      navigate(nextLocation)
      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, navigate, nextLocation])

  useNavigationBlocker(handleBlockedNavigation, isDirty)

  return [displayPrompt, confirmNavigation, cancelNavigation] as const
}

export default useUnsavedChangesPrompt
