import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { isEqual } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import IDHEDatePicker from 'common/components/fields/DatePicker'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import GLOBAL from 'common/styles/global.scss'
import { getNameFromCodedConcept } from 'common/utils/CodedConceptUtils'
import {
  isDate1BeforeDate2,
  isDateAfterToday,
  isDateBeforeOrEqualToday,
  isoDateToDisplayFormat,
} from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { ErrorType, getError } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import { personnelCredentialsState } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  CodedRef,
  PersonnelCredentialControllerService,
  PersonnelCredentialDTO,
} from 'services/openapi'
import { MIS_PROVIDER_QUALIFIED_ROLE_TYPE } from 'services/terminologyConstants'
import { MODALS } from '../../../../shared/constants'
import WarningDialog from '../../../../shared/Dialogs/WarningDialog'

type CredentialsDataRowType = {
  data: PersonnelCredentialDTO
  isCollapsed: boolean
}

type CredentialsDataType = {
  rows: CredentialsDataRowType[]
}

const sortCredentialsDataRow = (
  creds: CredentialsDataRowType[],
  qualifiedRole: CodedConceptDto[]
): CredentialsDataRowType[] => {
  const active = creds.filter(
    (each) =>
      each.data.effective === undefined ||
      each.data.effective.endDate === undefined ||
      isDateAfterToday(each.data.effective.endDate)
  )

  const activeWithStartDate = active.filter((each) => each.data.effective?.startDate)
  if (activeWithStartDate.length > 1)
    activeWithStartDate.sort((a, b) => {
      if (a.data.effective?.startDate && b.data.effective?.startDate) {
        return isDate1BeforeDate2(a.data.effective.startDate, b.data.effective.startDate) ? 1 : -1
      }
      return 0
    })

  const activeWithoutStartDate = active.filter(
    (each) => each.data.effective === undefined || each.data.effective.startDate === undefined
  )
  if (activeWithoutStartDate.length > 1)
    activeWithoutStartDate.sort((a, b) =>
      a.data.credentialType && b.data.credentialType
        ? getNameFromCodedConcept(qualifiedRole, a.data.credentialType).localeCompare(
            getNameFromCodedConcept(qualifiedRole, b.data.credentialType)
          )
        : 0
    )

  const expired = creds.filter(
    (each) => each.data.effective?.endDate && isDateBeforeOrEqualToday(each.data.effective.endDate)
  )
  if (expired.length > 1)
    expired.sort((a, b) => {
      if (a.data.effective?.endDate && b.data.effective?.endDate) {
        return isDate1BeforeDate2(a.data.effective.endDate, b.data.effective.endDate) ? 1 : -1
      }
      return 0
    })

  return [...activeWithStartDate, ...activeWithoutStartDate, ...expired]
}

const Credentials = forwardRef((_, ref) => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [personnelCredentials, setPersonnelCredentials] = useRecoilState(personnelCredentialsState)
  const qualifiedRole = useRecoilValue(terminologySelector(MIS_PROVIDER_QUALIFIED_ROLE_TYPE))

  const [currData, setCurrData] = useState<CredentialsDataType>({
    rows: personnelCredentials.credentials
      ? sortCredentialsDataRow(
          personnelCredentials.credentials.map((each) => {
            return {
              data: each,
              isCollapsed: true,
            }
          }),
          qualifiedRole
        )
      : [],
  })
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [errors, setErrors] = useState<ErrorType[]>([])

  const handleAddRow = useCallback(() => {
    setCurrData({
      rows: [
        ...currData.rows,
        {
          data: {
            credentialType: undefined,
            effective: undefined,
            issuingOrg: undefined,
            note: undefined,
            renewalReminder: undefined,
          },
          isCollapsed: false,
        },
      ],
    })
  }, [currData])

  const handleChangeRow = useCallback(
    (index: number, field: string, value: number | string) => {
      if (!isDirty) setIsDirty(true)

      const rows = currData.rows.map((row, i) => {
        let data
        if (index === i) {
          switch (field) {
            case 'credentialType':
              data = {
                ...row.data,
                credentialType: qualifiedRole?.find((o: CodedRef) => o.code === (value as string)),
              }
              break
            case 'issuingOrg':
              data = { ...row.data, issuingOrg: value as string }
              break
            case 'renewalReminder':
              data = { ...row.data, renewalReminder: value as number }
              break
            case 'note':
              data = { ...row.data, note: value as string }
              break
            case 'startDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, startDate: value as string },
              }
              break
            case 'endDate':
              data = {
                ...row.data,
                effective: { ...row.data.effective, endDate: value as string },
              }
              break
            default:
              return row
          }
          return { ...row, data }
        }
        return row
      })
      setCurrData({ rows })
    },
    [currData.rows, qualifiedRole, isDirty, setIsDirty]
  )

  const handleCollapseRow = useCallback(
    (index: number) => {
      currData.rows[index].isCollapsed = !currData.rows[index].isCollapsed
      setCurrData({ ...currData })
    },
    [currData]
  )

  const handleRemoveRow = useCallback(
    (index: number) => {
      const deleteRow = async (personnelId: string, id: string) => {
        try {
          await PersonnelCredentialControllerService.deletePersonnelCredential(personnelId, id)
          setPersonnelCredentials({
            credentials: personnelCredentials.credentials.filter((cred) => cred.id !== id),
            id: personnelId,
          })
          currData.rows.splice(index, 1)
          setCurrData(currData)
          showSnackSuccess(t('api.save-success'))
        } catch (error) {
          handleApiError(error)
        }
      }
      setDeleteIndex(-1)
      const id = currData.rows[index].data.id
      if (personnelCredentials.id && id) deleteRow(personnelCredentials.id, id)
      else {
        currData.rows.splice(index, 1)
        setCurrData({ rows: currData.rows })
      }
    },
    [
      currData,
      handleApiError,
      personnelCredentials.credentials,
      personnelCredentials.id,
      setPersonnelCredentials,
      showSnackSuccess,
      t,
    ]
  )

  const handleSaveRows = useCallback(() => {
    setIsDirty(false)

    const validate = () => {
      const errors: ErrorType[] = []
      currData.rows?.forEach((row, index) => {
        if (!row.data.credentialType) {
          errors.push({
            field: `credential-${index}`,
            message: t('provider-staff.credentials-training.credentials.credential-required'),
          })
        }
      })
      setErrors(errors)
      return errors
    }
    const save = async (personnelId: string) => {
      const newData = currData.rows.map((row) => row.data)
      if (isEqual(newData, personnelCredentials.credentials)) return
      try {
        const credentials = await PersonnelCredentialControllerService.savePersonnelCredential(
          personnelId,
          currData.rows.map((row) => row.data)
        )
        setPersonnelCredentials({
          credentials,
          id: personnelId,
        })
        setCurrData({
          rows: sortCredentialsDataRow(
            currData.rows.map((row, index) => {
              return {
                data: credentials[index],
                isCollapsed: row.isCollapsed,
              }
            }),
            qualifiedRole
          ),
        })
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
    }
    const errors = validate()
    if (personnelCredentials.id && errors.length === 0) save(personnelCredentials.id)
  }, [
    currData.rows,
    handleApiError,
    personnelCredentials.credentials,
    personnelCredentials.id,
    qualifiedRole,
    setPersonnelCredentials,
    showSnackSuccess,
    setIsDirty,
    t,
  ])

  useImperativeHandle(
    ref,
    () => ({
      add() {
        handleAddRow()
      },
      save() {
        handleSaveRows()
      },
    }),
    [handleAddRow, handleSaveRows]
  )

  const rowHeader = useCallback(
    (row: CredentialsDataRowType) => {
      const cred = qualifiedRole?.find((o: CodedRef) => o.code === row.data.credentialType?.code)
      return (
        <>
          {cred?.name && <span>{cred.name}</span>}
          {row.data.effective?.startDate && (
            <span>{` | ${isoDateToDisplayFormat(row.data.effective.startDate)}`}</span>
          )}
          {row.data.effective?.endDate && (
            <span>{` to ${isoDateToDisplayFormat(row.data.effective.endDate)}`}</span>
          )}
          {row.data.effective?.endDate && isDateBeforeOrEqualToday(row.data.effective.endDate) && (
            <span>
              {` | `}
              <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>
                {t('provider-staff.credentials-training.credentials.expired')}
              </span>
            </span>
          )}
        </>
      )
    },
    [qualifiedRole, t]
  )

  const rowForm = useCallback(
    (row: CredentialsDataRowType, index: number) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <MISSelectDropdown
              error={!!getError(errors, `credential-${index}`)}
              helperText={getError(errors, `credential-${index}`)}
              id={`credential-${index}`}
              label={t('provider-staff.credentials-training.credentials.credential')}
              onChange={(event) =>
                handleChangeRow(index, 'credentialType', event.target.value as string)
              }
              options={qualifiedRole.map((opt) => ({
                label: opt.name as string,
                value: opt.code,
              }))}
              required
              value={qualifiedRole.find((opt) => opt.code === row.data.credentialType?.code)?.code}
            />
          </Grid>
          <Grid item xs={5}>
            <MISTextField
              id={`issue-organization-${index}`}
              label={t('provider-staff.credentials-training.credentials.issue-organization')}
              onChange={(event) => handleChangeRow(index, 'issuingOrg', event.target.value)}
              value={row.data.issuingOrg}
            />
          </Grid>
          <Grid item xs={2}>
            <MISTextField
              error={!!getError(errors, `renewal-reminder-${index}`)}
              helperText={getError(errors, `renewal-reminder-${index}`)}
              id={`renewal-reminder-${index}`}
              inputProps={{ min: 0 }}
              label={t('provider-staff.credentials-training.credentials.renewal-reminder')}
              onChange={(event) => handleChangeRow(index, 'renewalReminder', event.target.value)}
              type="number"
              value={row.data.renewalReminder}
            />
          </Grid>
          <Grid item xs={5}>
            <MISTextField
              id={`note-${index}`}
              label={t('provider-staff.credentials-training.note')}
              onChange={(event) => handleChangeRow(index, 'note', event.target.value)}
              value={row.data.note}
            />
          </Grid>
          <Grid item xs={2}>
            <IDHEDatePicker
              date={row.data.effective?.startDate}
              error={undefined}
              helperText={undefined}
              label={t('provider-staff.credentials-training.start-date')}
              onDateChange={(value: string) => handleChangeRow(index, 'startDate', value)}
              readOnly={false}
              required={false}
            />
          </Grid>
          <Grid item xs={2}>
            <IDHEDatePicker
              date={row.data.effective?.endDate}
              error={undefined}
              helperText={undefined}
              label={t('provider-staff.credentials-training.end-date')}
              onDateChange={(value: string) => handleChangeRow(index, 'endDate', value)}
              readOnly={false}
              required={false}
            />
          </Grid>
        </Grid>
      )
    },
    [errors, handleChangeRow, qualifiedRole, t]
  )

  return (
    <Box sx={{ pt: GLOBAL.PADDING_MD }}>
      <SectionHeader
        isSubsection
        title={t('provider-staff.credentials-training.credentials.title')}
      />
      <MISAccordionContainer
        emptyDataMessage={t('provider-staff.credentials-training.credentials.empty-data')}
        errors={errors.length === 0 ? [] : [t('common.validation.errors')]}
        onChangeRow={handleChangeRow}
        onCollapseRow={handleCollapseRow}
        onRemoveRow={setDeleteIndex}
        rowForm={rowForm}
        rowHeader={rowHeader}
        rows={currData.rows}
      />
      <WarningDialog
        entity="Credentials"
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => handleRemoveRow(deleteIndex)}
        open={deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
    </Box>
  )
})
Credentials.displayName = 'Credentials'

export default Credentials
