import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import MISChip from 'common/components/form/MISChip'
import { TValue } from '../Charting'

export default function ChartingServiceFields({
  onDeleteProgram,
  onDeleteService,
  programs,
  services,
}: {
  services?: TValue[]
  programs?: TValue[]
  onDeleteProgram: (index: number) => void
  onDeleteService: (index: number) => void
}): JSX.Element {
  const { t } = useTranslation('common')
  return (
    <Box sx={{ marginBottom: 1, width: '100%' }}>
      <Grid columnSpacing={2} container rowSpacing={1}>
        <Grid item xs={6}>
          <Typography component="span" sx={{ display: 'inline' }} variant="body2">
            {t('charting.canvas.services')}
          </Typography>
          {(services == null || services.length === 0) && (
            <Alert severity="warning" style={{ padding: 0 }} variant="outlined">
              {t('charting.canvas.service-warning')}
            </Alert>
          )}
          {services && services.length > 0 && (
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                m: 0,
                p: 0.5,
              }}
            >
              {services.map((service, index) => (
                <MISChip
                  key={index}
                  label={service.value}
                  onDelete={() => {
                    onDeleteService(index)
                  }}
                  sx={{ mx: 0.5 }}
                  variant="outlined"
                />
              ))}
            </Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography component="span" sx={{ display: 'inline' }} variant="body2">
            {t('charting.canvas.programs')}
          </Typography>
          {(programs == null || programs.length === 0) && (
            <Alert severity="warning" style={{ padding: 0 }} variant="outlined">
              {t('charting.canvas.program-warning')}
            </Alert>
          )}
          {programs && programs.length > 0 && (
            <Paper
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                m: 0,
                p: 0.5,
              }}
            >
              {programs.map((program, index) => (
                <MISChip
                  key={index}
                  label={program.value}
                  onDelete={() => {
                    onDeleteProgram(index)
                  }}
                  sx={{ mx: 0.5 }}
                  variant="outlined"
                />
              ))}
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
