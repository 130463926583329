import { Box, SvgIcon } from '@mui/material'

const MISLowPrioIcon = () => {
  return (
    <Box sx={{ paddingBottom: '8px', paddingLeft: '8px' }}>
      <SvgIcon sx={{ transform: 'rotate(270deg)' }} viewBox="0 0 16 16">
        <svg version="1.1">
          <g transform="matrix(1 0 0 1 -1057 -9 )">
            <path
              d="M 7.266666666666667 1.8549999999999989  L 2.3666666666666667 7  L 7.266666666666667 12.145000000000001  C 7.433333333333334 12.320000000000002  7.51388888888889 12.524166666666666  7.508333333333334 12.757500000000002  C 7.50277777777778 12.990833333333335  7.416666666666668 13.195000000000002  7.25 13.370000000000001  C 7.083333333333335 13.545  6.88888888888889 13.632500000000002  6.666666666666667 13.632500000000002  C 6.4444444444444455 13.632500000000002  6.25 13.545  6.083333333333333 13.370000000000001  L 0.9500000000000001 7.997499999999999  C 0.8166666666666668 7.857499999999999  0.7166666666666667 7.699999999999999  0.65 7.5249999999999995  C 0.5833333333333334 7.349999999999999  0.55 7.174999999999999  0.55 7  C 0.55 6.824999999999999  0.5833333333333334 6.649999999999999  0.65 6.4750000000000005  C 0.7166666666666667 6.3  0.8166666666666668 6.142499999999999  0.9500000000000001 6.0024999999999995  L 6.083333333333333 0.6124999999999999  C 6.25 0.4375  6.447222222222223 0.35291666666666405  6.675000000000001 0.3587499999999995  C 6.902777777777779 0.36458333333333  7.1000000000000005 0.45499999999999907  7.266666666666667 0.629999999999999  C 7.433333333333334 0.8049999999999989  7.516666666666667 1.0091666666666639  7.516666666666667 1.242499999999999  C 7.516666666666667 1.4758333333333316  7.433333333333334 1.6799999999999964  7.266666666666667 1.8549999999999989  Z "
              fill="#60bf60"
              fillRule="nonzero"
              stroke="none"
              transform="matrix(1 0 0 1 1057 9 )"
            />
          </g>
        </svg>
      </SvgIcon>
    </Box>
  )
}

export default MISLowPrioIcon
