import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import UnsavedChangesWarningPrompt from 'common/components/modals/UnsavedChangesWarningPrompt'
import misTheme from 'core/styles/theme'
import { isDirtyState } from 'recoil/isDirty'
import './AppNavigation.scss'

const AppNavigation = () => {
  const { pathname } = useLocation()
  const isDirty = useRecoilValue(isDirtyState)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Box className="app-nav">
      <ThemeProvider theme={misTheme}>
        <Box>
          <CssBaseline />
          <Outlet />
          <UnsavedChangesWarningPrompt isDirty={isDirty} />
        </Box>
      </ThemeProvider>
    </Box>
  )
}

export default AppNavigation
