import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISTextField from 'common/components/form/MISTextField'
import MISTimePicker from 'common/components/form/MISTimePicker'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import MISTable from 'common/components/table/MISTable'
import MISTabs from 'common/components/tabs/MISTabs'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { ErrorType, getError, hasError } from 'modules/shared/utils'
import { programsAtom } from 'recoil/atoms'
import { encountersState } from 'recoil/encounters'
import { encounterIdState } from 'recoil/lastupdated'
import {
  EncounterControllerService,
  EncounterDTO,
  EncounterServiceControllerService,
  EncounterServiceDTO,
  EncounterServiceTemplateControllerService,
  EncounterServiceTemplateDTO,
  GovernanceAgencyControllerService,
} from 'services/openapi'

type LocationOptionType = {
  label: string | undefined
  id: string | undefined
}

type AddEnounterDialogProps = {
  clientId: string
  encounterId?: string
  encounterDate?: string
  entityName: string
  openDialog: boolean
  onCloseCallback: () => void
  onSaveCallback: (encounter: EncounterDTO) => void
}

const AddEncounterDialog = ({
  clientId,
  encounterDate,
  encounterId,
  entityName,
  onCloseCallback,
  onSaveCallback,
  openDialog,
}: AddEnounterDialogProps) => {
  const { t } = useTranslation('common')
  const { showSnackError, showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const setLastUpdatedEncounterId = useSetRecoilState(encounterIdState)
  const programs = useRecoilValue(programsAtom)
  const encounters = useRecoilValue(encountersState)

  const [dateValue, setDateValue] = useState(encounterDate || '')
  const [timeValue, setTimeValue] = useState('')
  const [zoneId, setZoneId] = useState('')
  const [searchText, setSearchText] = useState('')
  const [locationOptions, setLocationOptions] = useState<LocationOptionType[]>([])
  const [selectedLocation, setSelectedLocation] = useState<LocationOptionType | null>(null)

  const [order] = useState('asc')
  const [orderBy] = useState('name')
  const [encounterTemplates, setEncounterTemplates] = useState<EncounterServiceTemplateDTO[]>([])
  const [encounterTemplateSearchResults, setEncounterTemplateSearchResults] = useState<
    EncounterServiceTemplateDTO[]
  >([])
  const [searchEncounterTemplateName, setSearchEncounterTemplateName] = useState('')
  const [selectedEncounterTemplates, setSelectedEncounterTemplates] = useState<
    EncounterServiceTemplateDTO[]
  >([])
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [newServicesToAdd, setNewServicesToAdd] = useState<EncounterServiceDTO[]>([])

  const handleClose = useCallback(
    (_, reason: string) => {
      if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
        onCloseCallback()
      }
    },
    [onCloseCallback]
  )

  const validate = useCallback(() => {
    let isValid = true
    const errors: ErrorType[] = []

    if (!dateValue) {
      isValid = false
      errors.push({
        field: 'date',
        message: t('encounters.create.validation.date.required'),
      })
    }
    if (!selectedLocation) {
      isValid = false
      errors.push({
        field: 'location',
        message: t('encounters.create.validation.location.required'),
      })
    }

    if (!isValid) {
      return {
        errors,
        isValid: false,
      }
    }

    // At least one template selected
    if (selectedEncounterTemplates.length === 0) {
      errors.push({
        field: 'template',
        message: t('encounters.create.validation.at-least-one-template'),
      })
    }

    return {
      errors,
      isValid: errors.length === 0,
    }
  }, [dateValue, selectedEncounterTemplates.length, selectedLocation, t])

  const onEncounterSelectChange = useCallback(
    (selected: EncounterServiceTemplateDTO[]) => {
      if (!selected) {
        setSelectedEncounterTemplates([])
        return
      }

      const selectedFiltered = encounterTemplateSearchResults.filter((item: any) => {
        return selected.includes(item.id)
      })
      setSelectedEncounterTemplates(selectedFiltered)
    },
    [encounterTemplateSearchResults]
  )

  const getEncounterServiceFromTemplate = useCallback(
    (template: EncounterServiceTemplateDTO, encounterId: string) => {
      return {
        ...template,
        encounterId,
        encounterServiceTemplateCode: template.code,
        encounterServiceTemplateId: template.id,
        esdrtCategoryId: template.associations?.eSDRTCategory?.eSDRTCategoryId,
        esdrtServiceId: template.associations?.eSDRTService?.eSDRTServiceId,
        isAutomated: false,
        isPrimaryService: false,
        programId: template.associations?.program?.programId,
        purposeId: template.associations?.purpose?.purposeId,
        typeId: template.associations?.type?.typeId,
      }
    },
    []
  )

  const handleSaveClicked = useCallback(async () => {
    const { errors, isValid } = validate()

    if (isValid) {
      if (entityName === 'Encounter Service') {
        if (encounterId) {
          const selectedEncounter: EncounterDTO | undefined = encounters?.find(
            (e: EncounterDTO) => e.id === encounterId
          )
          let primarySerivceFound = false
          if (selectedEncounter) {
            const primaryService: EncounterServiceDTO | undefined =
              selectedEncounter?.encounterServices?.find(
                (en: EncounterServiceDTO) => en.isPrimaryService
              )
            if (primaryService) {
              primarySerivceFound = true
            }
          }
          try {
            let promiseChain: Promise<void> = Promise.resolve()
            selectedEncounterTemplates.forEach((template) => {
              const service = getEncounterServiceFromTemplate(template, encounterId)
              const updatedService: any = {
                ...service,
                encounterServiceDate: dateValue.substring(0, 10), // TODO: generalize date format
                encounterServiceTime: timeValue.substring(0, 8), // TODO: generalize time format
                encounterZoneId: zoneId,
                isPrimaryService: !primarySerivceFound,
                locationOfService: selectedLocation?.id,
              }

              promiseChain = promiseChain
                .then(() =>
                  EncounterServiceControllerService.createEncounterService(
                    encounterId,
                    updatedService
                  )
                )
                .then((newService) => {
                  newServicesToAdd.push(newService)
                  setNewServicesToAdd([...newServicesToAdd])
                  if (selectedEncounterTemplates.length === newServicesToAdd.length) {
                    const encounter = {
                      ...selectedEncounter,
                      encounterServices: [
                        ...(selectedEncounter?.encounterServices || []),
                        ...newServicesToAdd,
                      ],
                    }
                    if (encounter.id) {
                      setLastUpdatedEncounterId(encounter.id)
                      onSaveCallback(encounter)
                      showSnackSuccess(t('api.save-success'))
                    }
                  }
                })
                .catch((error) => {
                  handleApiError(error)
                })
            })
          } catch (error) {
            handleApiError(error)
          }
        }
      }
      if (entityName === 'Encounter') {
        const encounterServices = selectedEncounterTemplates.map((template) => {
          const service = getEncounterServiceFromTemplate(template, '')
          return {
            ...service,
            encounterServiceDate: dateValue.substring(0, 10), // TODO: generalize date format
            encounterServiceTime: timeValue.substring(0, 8), // TODO: generalize time format
            encounterZoneId: zoneId,
            locationOfService: selectedLocation?.id,
          }
        })
        // Default one service as primary
        const primaryServiceFound = encounterServices.find((service) => service.isPrimaryService)
        if (!primaryServiceFound && encounterServices.length > 0) {
          encounterServices[0].isPrimaryService = true
        }

        const parentEncounterAssociation = {
          parentEntityId: clientId,
          parentEntityType: {
            code: 'CLIENT_FILES',
            codeSystemOid: '2.16.840.1.113883.3.1019.1.8',
          },
        }
        const associations = {
          locationOfService: selectedLocation?.id,
          parentEncounterAssociation: parentEncounterAssociation,
        }
        const encounterBody: EncounterDTO = {
          associations: associations,
          encounterDateTime: dateValue,
          encounterServices: encounterServices as EncounterServiceDTO[],
        }
        EncounterControllerService.createEncounter(encounterBody)
          .then((result) => {
            if (result?.id) {
              setLastUpdatedEncounterId(result?.id)
              onSaveCallback(result)
            }
            showSnackSuccess(t('api.save-success'))
          })
          .catch(() => {
            showSnackError(t('api.error.save-error'))
          })
      }
    } else {
      setErrors(errors)
    }
  }, [
    clientId,
    dateValue,
    encounterId,
    encounters,
    entityName,
    getEncounterServiceFromTemplate,
    handleApiError,
    newServicesToAdd,
    onSaveCallback,
    selectedEncounterTemplates,
    selectedLocation?.id,
    setLastUpdatedEncounterId,
    showSnackError,
    showSnackSuccess,
    t,
    timeValue,
    validate,
    zoneId,
  ])

  // Encounter search
  const handleSearchEncounterTemplates = useCallback(() => {
    setSearchText(searchEncounterTemplateName)
  }, [searchEncounterTemplateName])

  const handleSearchEncounterServiceTemplates = useCallback(() => {
    EncounterServiceTemplateControllerService.organizationgetPagedList(searchText)
      .then((result) => {
        setEncounterTemplateSearchResults(result.content || [])
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [handleApiError, searchText])

  const handleSearchTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchEncounterTemplateName(event.target.value)
  }, [])

  useEffect(() => {
    const handleSearchLocation = () => {
      GovernanceAgencyControllerService.searchAgencyListForSpecifiedType('LocationOfService').then(
        (result) => {
          if (result.content) {
            const governanceAgencyOption = result.content.map((agency) => {
              return { id: agency.id, label: agency.name }
            })
            setLocationOptions(governanceAgencyOption)
          }
        }
      )
    }
    handleSearchLocation()
  }, [])

  const encounterTableHeaders = useMemo(
    () => [
      {
        disablePadding: true,
        id: 'name',
        label: 'Template',
        numeric: false,
      },
      {
        disablePadding: false,
        id: 'program',
        label: 'Program',
        numeric: false,
      },
      {
        disablePadding: false,
        id: 'form',
        label: 'Template',
        numeric: false,
      },
      {
        disablePadding: false,
        id: 'documents',
        label: 'Document',
        numeric: false,
      },
    ],
    []
  )

  const encounterTabs = useMemo(
    () => [
      {
        content: (
          <MISTable
            headers={encounterTableHeaders}
            onSelect={onEncounterSelectChange}
            rows={encounterTemplates}
            title=""
          />
        ),
        id: 'ALL',
        title: 'All',
      },
      {
        content: <Box sx={{ m: 2, width: 250 }}>Coming soon...</Box>,
        id: 'TEMPLATE_GROUPS',
        title: 'Template Groups',
      },
      {
        content: <Box sx={{ m: 2, width: 250 }}>Coming soon...</Box>,
        id: 'PROGRAMS',
        title: 'Programs',
      },
    ],
    [encounterTableHeaders, encounterTemplates, onEncounterSelectChange]
  )

  useEffect(() => {
    if (encounterTemplateSearchResults) {
      const data = encounterTemplateSearchResults.map((item) => {
        const programName = programs?.find(
          (x) => item.associations?.program?.programId === x.id
        )?.name
        const documentsMock = 'documents 123'
        const formMock = 'forms 123'

        return {
          documents: documentsMock,
          form: formMock,
          id: item.id,
          name: item.name,
          program: programName,
        }
      })
      setEncounterTemplates(data || [])
    } else {
      setEncounterTemplates([])
    }
  }, [encounterTemplateSearchResults, encounterTableHeaders, onEncounterSelectChange, programs])

  useEffect(() => {
    handleSearchEncounterServiceTemplates()
  }, [orderBy, order, searchText, handleSearchEncounterServiceTemplates])

  const handleTimeChange = useCallback((time: any) => {
    if (time) {
      setZoneId(time.get('zone').name)
      setTimeValue(time.toISOTime())
    }
  }, [])

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={openDialog}>
      <DialogTitle>{`${t('common.button.add')} ${entityName}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <MISDatePicker
              error={hasError(errors, 'date')}
              helperText={getError(errors, 'date')}
              isDefaultToday
              label="Date"
              onChange={(value: any) => setDateValue(value)}
              readOnly={false}
              required
              value={dateValue}
            />
          </Grid>
          <Grid item xs={2}>
            <MISTimePicker
              label="Time"
              onChange={(value) => handleTimeChange(value)}
              readOnly={false}
              value={timeValue}
            />
          </Grid>
          <Grid item xs={3}>
            <MISAutocomplete
              error={hasError(errors, 'location')}
              helperText={getError(errors, 'location')}
              label="Location"
              onChange={(value) => setSelectedLocation(value)}
              options={locationOptions}
              required
              value={selectedLocation}
            />
          </Grid>
          <Grid item xs={3.5}>
            <MISTextField
              id="search-text"
              label="Search Service Template"
              onChange={handleSearchTextChange}
              placeholder="Search by Service Template Name"
              value={searchEncounterTemplateName}
            />
          </Grid>
          <Grid item xs={1.5}>
            <MISButton onClick={handleSearchEncounterTemplates} sx={{ marginTop: '32px' }}>
              {t('common.button.search')}
            </MISButton>
          </Grid>

          <Grid item xs={12}>
            {errors?.find((err) => err.field === 'template') && (
              <Alert className="error" severity="error" style={{ marginBottom: GLOBAL.MARGIN_XS }}>
                {t('encounters.create.validation.at-least-one-template')}
              </Alert>
            )}
          </Grid>
          {encounterTemplates?.length > 0 && <MISTabs tabs={encounterTabs} />}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISButton onClick={(event) => handleClose(event, '')}>
          {t('common.button.cancel')}
        </MISButton>
        <MISButton onClick={handleSaveClicked}>{t('common.button.add')}</MISButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddEncounterDialog
