import { lazy, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import MISTabbedContentContainer from 'common/components/contentpane/MISTabbedContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { useSnack } from 'common/components/snackbar/useSnack'
import { findValueByKeyFromJSONString } from 'common/utils/utils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  govAgencyDepartmentState,
  govAgencyState,
  govAssociatedDomainsState,
  govAssociatedGovAgenciesState,
  govDeparmentsState,
} from 'recoil/govAgency'
import { terminologySelector } from 'recoil/terminology'
import {
  AddressControllerService,
  AddressResponseDTO,
  AgencyDomainControllerService,
  AgencyIdentifierDTO,
  CodedConceptDto,
  CodedRef,
  DomainAssociationDTO,
  FormDataControllerService,
  GovernanceAgencyControllerService,
  GovernanceAgencyDTO,
  IdentifierControllerService,
  OrganizationAssociationDTO,
} from 'services/openapi'
import './GovAgencyDetails.scss'
import { GOV_AGENCY_IDENTIFIERS } from 'services/terminologyConstants'
import GovAgencyRecordHeader from './GovAgencyRecordHeader'

const ContactsSection = lazy(() => import('./Contacts/ContactsSection'))
const NamedContactsSection = lazy(() => import('./NamedContact/NamedContactSection'))
const AddressesSection = lazy(() => import('modules/shared/Addresses/Addresses'))
const IdentiferSection = lazy(() => import('modules/shared/Identifiers'))
const ProfileSection = lazy(() => import('./Profile/ProfileSection'))

const identifierMap: Record<string, string> = {
  FirstNationBand: 'FirstNationBandNum',
  SchoolEducationFacility: 'SchoolDistrictNumber',
  TribalCouncilNationGroup: 'TribalCouncilNum',
}

type GovAgencyDetailsProps = {
  isDepartment?: boolean
}

const GovAgencyDetails = ({ isDepartment }: GovAgencyDetailsProps) => {
  const { showSnackError, showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const { agencyId, departmentId } = useParams()
  const [agencyDetails, setAgencyDetails] = useRecoilState(govAgencyState)
  const [departmentDetails, setDepartmentDetails] = useRecoilState(govAgencyDepartmentState)
  const [internalExternal, setInternalExternal] = useState<boolean | null>(null)

  // Profile
  const setAssociatedDomains = useSetRecoilState(govAssociatedDomainsState)
  const setAssociatedGovAgencies = useSetRecoilState(govAssociatedGovAgenciesState)
  const setDepartments = useSetRecoilState(govDeparmentsState)

  // Names
  const [namesForDepartment] = useState([agencyDetails?.name, departmentDetails?.name])

  // Addresses
  const [agencyAddresses, setAgencyAddresses] = useState<AddressResponseDTO[]>([])
  const [departmentAddress, setDepartmentAddress] = useState<AddressResponseDTO[]>([])

  // Identifiers
  const [agencyIdentifiers, setAgencyIdentifiers] = useState<AgencyIdentifierDTO[]>([])
  const identifierTypes: CodedConceptDto[] = useRecoilValue(
    terminologySelector(GOV_AGENCY_IDENTIFIERS)
  )
  const [filteredIdentifierTypes, setFilteredIdentifierTypes] = useState<CodedConceptDto[]>([])

  const { t } = useTranslation('common')

  const getAgencyDetails = useCallback(
    async (agencyId: string | undefined, isDepartment: boolean, associationsFetch: boolean) => {
      if (!agencyId) return
      try {
        const response = await GovernanceAgencyControllerService.getAgencyById(agencyId)
        if (response) {
          if (isDepartment) {
            setDepartmentDetails(response)
          } else {
            setAgencyDetails(response)
          }

          if (associationsFetch) {
            const domainResponse = await AgencyDomainControllerService.listAgencyDomains(
              agencyId,
              true
            )
            if (domainResponse?.content) {
              setAssociatedDomains(domainResponse.content as DomainAssociationDTO[])
            }

            const govAgenciesResponse =
              await GovernanceAgencyControllerService.getAgencyRelationships(agencyId)
            if (govAgenciesResponse?.content) {
              setAssociatedGovAgencies(govAgenciesResponse.content as OrganizationAssociationDTO[])
            }
          }

          if (!isDepartment) {
            const departmentsResponse =
              await GovernanceAgencyControllerService.getAgencyDepartments(agencyId)
            if (departmentsResponse) {
              setDepartments(departmentsResponse as GovernanceAgencyDTO[])
            }
          }
        }
      } catch (error) {
        handleApiError(error)
      }
    },
    [
      handleApiError,
      setAgencyDetails,
      setAssociatedDomains,
      setAssociatedGovAgencies,
      setDepartmentDetails,
      setDepartments,
    ]
  )

  useEffect(() => {
    if (!agencyId) setAgencyDetails(null)
    if (!departmentId) setDepartmentDetails(null)
    if (agencyId && departmentId) {
      if (departmentId !== departmentDetails?.id) {
        getAgencyDetails(departmentId, true, true)
        getAgencyDetails(agencyId, false, false)
      }
    } else if (agencyId) {
      if (agencyId !== agencyDetails?.id) {
        getAgencyDetails(agencyId, false, true)
      }
    }
  }, [
    isDepartment,
    agencyId,
    departmentId,
    agencyDetails,
    departmentDetails,
    getAgencyDetails,
    setAgencyDetails,
    setDepartmentDetails,
    handleApiError,
  ])

  useEffect(() => {
    const fetchFormSchema = async (agencyId: string) => {
      try {
        const { content } = await FormDataControllerService.formsearchFormData([agencyId])
        if (content?.length && content?.length > 0) {
          content.forEach((o) => {
            if (o.name === 'GA-Orgn-Attributes') {
              const internalExternalFlag = findValueByKeyFromJSONString(
                o?.data as string,
                'GA_Internal_External_Flag'
              )
              setInternalExternal(
                internalExternalFlag === 'true'
                  ? true
                  : internalExternalFlag === 'false'
                  ? false
                  : null
              )
            }
          })
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    if (departmentId && agencyId) {
      fetchFormSchema(agencyId)
    }
  }, [agencyId, departmentId, handleApiError])

  const getAgencyAddresses = useCallback(async () => {
    if (agencyId) {
      try {
        const downloadedAgencyAddresses = await AddressControllerService.getAddressesByAgencyId(
          agencyId
        )
        setAgencyAddresses(
          downloadedAgencyAddresses.content ? downloadedAgencyAddresses.content : []
        )
      } catch (error) {
        handleApiError(error)
      }
    }

    if (departmentId) {
      try {
        const downloadedDepartmentAddresses = await AddressControllerService.getAddressesByAgencyId(
          departmentId
        )
        setDepartmentAddress(
          downloadedDepartmentAddresses.content ? downloadedDepartmentAddresses.content : []
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  }, [agencyId, departmentId, handleApiError])

  const getAgencyIdentifiers = useCallback(async () => {
    if (agencyId) {
      try {
        const downloadedAgencyIdentifiers = await IdentifierControllerService.listAgencyIdentifiers(
          agencyId
        )
        setAgencyIdentifiers(
          downloadedAgencyIdentifiers.content ? downloadedAgencyIdentifiers.content : []
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  }, [agencyId, handleApiError])

  const getFilteredAgencyIdentifiers = useCallback(() => {
    if (agencyDetails && agencyDetails.governanceAgencyType) {
      const tempTypes: CodedConceptDto[] = []
      agencyDetails.governanceAgencyType.forEach((type: CodedRef) => {
        if (type.code && type.code in identifierMap) {
          const match = identifierMap[type.code]
          const typeMatch = identifierTypes.find((x) => x.code === match)
          if (typeMatch) tempTypes.push(typeMatch)
        }
        setFilteredIdentifierTypes(tempTypes)
      })
    }
  }, [identifierTypes, agencyDetails])

  useEffect(() => {
    getAgencyAddresses()
    getAgencyIdentifiers()
  }, [getAgencyAddresses, getAgencyIdentifiers])

  useEffect(() => {
    getFilteredAgencyIdentifiers()
  }, [getFilteredAgencyIdentifiers, identifierTypes, agencyDetails])

  const handleSearchAddresses = useCallback(async (value) => {
    const organzationAll = await AddressControllerService.organizationgetAllAddresses(value)
    return organzationAll?.content ?? null
  }, [])

  const handleAgencyAddressUpdate = useCallback(
    (agencyAddressUpdate) => {
      if (agencyId) {
        const addresserId = departmentId ? departmentId : agencyId
        AddressControllerService.upsertAgencyAddresses(addresserId, agencyAddressUpdate)
          .then((result) => {
            showSnackSuccess(t('api.save-success'))
            getAgencyAddresses()
          })
          .catch((error) => {
            showSnackError(t('api.error.save-error'))
            handleApiError(error)
          })
      }
    },
    [
      agencyId,
      departmentId,
      getAgencyAddresses,
      showSnackSuccess,
      showSnackError,
      handleApiError,
      t,
    ]
  )

  const deleteAgencyAddress = useCallback(
    (addressId: string) => {
      const deleteAddress = async () => {
        try {
          const addresserId = departmentId ? departmentId : agencyId
          if (addressId && addresserId) {
            await AddressControllerService.deleteAgencyAddress(addresserId, addressId)
            getAgencyAddresses()
            showSnackSuccess(t('client-addresses.messages.delete-address-success'))
          }
        } catch (error) {
          handleApiError(error)
        }
      }
      deleteAddress()
    },
    [departmentId, agencyId, getAgencyAddresses, showSnackSuccess, t, handleApiError]
  )

  const handleAgencyIdentifierUpdate = useCallback(
    (agencyIdentifierUpdate) => {
      if (agencyId) {
        IdentifierControllerService.updateAgencyIdentifier(agencyId, agencyIdentifierUpdate)
          .then((result) => {
            showSnackSuccess(t('api.save-success'))
            setAgencyIdentifiers(result)
          })
          .catch((error) => {
            showSnackError(t('api.error.save-error'))
            handleApiError(error)
          })
      }
    },
    [handleApiError, showSnackError, showSnackSuccess, t, agencyId]
  )

  const handleAgencyIdentifierDelete = useCallback(
    (identifierId) => {
      if (agencyId) {
        IdentifierControllerService.deleteAgencyIdentifier(agencyId, identifierId)
          .then((result) => {
            showSnackSuccess(t('api.save-success'))
            setAgencyIdentifiers(agencyIdentifiers.filter((x) => x.id !== identifierId))
          })
          .catch((error) => {
            showSnackError(t('api.error.save-error'))
            handleApiError(error)
          })
      }
    },
    [agencyId, showSnackSuccess, t, agencyIdentifiers, showSnackError, handleApiError]
  )

  const tabConfig = () => [
    {
      active: false,
      component: (
        <ProfileSection isDepartment={isDepartment} setInternalExternal={setInternalExternal} />
      ),
      dirty: false,
      disabled: false,
      id: 'profile',
      label: t('gov-agency-details.profile-label'),
      visibile: true,
    },
    {
      active: false,
      component: agencyId && <ContactsSection isDepartment={isDepartment} />,
      dirty: false,
      disabled: false,
      id: 'contacts',
      label: t('gov-agency-details.contacts-label'),
      visibile: true,
    },
    {
      active: false,
      component: agencyId && <NamedContactsSection isDepartment={isDepartment} />,
      dirty: false,
      disabled: false,
      id: 'namedcontacts',
      label: t('gov-agency-details.named-contacts-label'),
      visibile: true,
    },
    {
      active: false,
      component: agencyId && (
        <AddressesSection
          addresses={departmentId ? departmentAddress : agencyAddresses}
          handleAddressUpdate={handleAgencyAddressUpdate}
          handleSearchAddresses={handleSearchAddresses}
          id={agencyId}
          isFromClient={false}
          namesForDepartment={departmentId ? namesForDepartment : undefined}
          onDeleteAddress={deleteAgencyAddress}
          parentAddresses={departmentId ? agencyAddresses : undefined}
          title={t('client-addresses.labels.title')}
        />
      ),
      dirty: false,
      disabled: false,
      id: 'addresses',
      label: t('client-addresses.labels.title'),
      visibile: true,
    },
    {
      active: false,
      component: agencyId && (
        <IdentiferSection
          deleteIdentifier={handleAgencyIdentifierDelete}
          identifiers={agencyIdentifiers}
          personIdentifierTypes={filteredIdentifierTypes}
          updateIdentifiers={handleAgencyIdentifierUpdate}
        />
      ),
      dirty: false,
      disabled: false,
      id: 'identifiers',
      label: t('client-identifiers.labels.title'),
      visibile: filteredIdentifierTypes?.length > 0,
    },
  ]

  return (
    <MISBaseContainer>
      <div className="gov-agency-record-details">
        <GovAgencyRecordHeader internalExternal={internalExternal} isDepartment={isDepartment} />
        <MISTabbedContentContainer tabConfig={tabConfig()} />
      </div>
    </MISBaseContainer>
  )
}

export default GovAgencyDetails
