import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import DropdownSearch from 'common/components/fields/DropdownSearch'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISRadio from 'common/components/form/MISRadio'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import MISButton from 'common/components/MISButton'
import MISListContainer from 'common/components/MISListContainer'
import GLOBAL from 'common/styles/global.scss'
import { dateNowIsoString, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { camelCaseToTitleCase } from 'common/utils/StringUtils'
import { ValueUtils } from 'common/utils/ValueUtils'
import { ErrorType } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  AddressClientDTO,
  ClientLocationDTO,
  CodedConceptDto,
  LocationDTO,
  PersonnelAddressDTO,
} from 'services/openapi'
import {
  ADDRESS_AREA_TYPES,
  ADDRESS_COUNTRIES,
  ADDRESS_PROVINCES,
  ADDRESS_STREET_TYPES,
  ADDRESS_TYPES,
  ADDRESS_UNIT_TYPES,
} from 'services/terminologyConstants'
import { MODALS } from '../constants'
import WarningDialog from '../Dialogs/WarningDialog'
import SectionHeader from '../SectionHeader/SectionHeader'
import './Addresses.scss'

const ENTITY = 'Address'

type OptionsType = {
  location: LocationDTO
  title: string
  key: string
}
type AddressesProps = {
  id: string
  title: string
  addresses: ClientLocationDTO[] | PersonnelAddressDTO[]
  handleSearchAddresses: (searchTerm: string) => any
  handleAddressUpdate: (updatedData: any) => void
  handleSearchHouseholdAddresses?: (addressId: string) => Promise<AddressClientDTO[] | undefined>
  isFromClient: boolean
  onDeleteAddress?: (addressId: string) => void
  parentAddresses?: ClientLocationDTO[] | PersonnelAddressDTO[]
  namesForDepartment?: any
  isFromProvider?: boolean
}

const Addresses = ({
  addresses,
  handleAddressUpdate,
  handleSearchAddresses,
  handleSearchHouseholdAddresses,
  id,
  isFromClient,
  isFromProvider,
  namesForDepartment,
  onDeleteAddress,
  parentAddresses,
  title,
}: AddressesProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const areaTypes: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_AREA_TYPES))
  const addressTypes: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_TYPES))
  const streetTypes: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_STREET_TYPES))
  const unitTypes: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_UNIT_TYPES))
  const provinces: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_PROVINCES))
  const countries: CodedConceptDto[] = useRecoilValue(terminologySelector(ADDRESS_COUNTRIES))

  const [addressData, setAddressData] = useState<any>()
  const [parentAddressData, setParentAddressData] = useState<any>()
  const [deleteIndex, setDeleteIndex] = useState(-1)
  const [preferredWarningDialog, setPreferredWarningDialog] = useState({ data: {}, open: false })
  const [addressWarningDialog, setAddressWarningDialog] = useState({ index: -1, open: false })
  const [options, setOptions] = useState<OptionsType[]>([])
  const [autofillChangeIndexes, setAutofillChangeIndexes] = useState<number[]>([])

  const getValueSetAreaType = useCallback(
    (areaType) => {
      return areaTypes.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === areaType?.code
      })[0]
    },
    [areaTypes]
  )

  const getValueSetAddressType = useCallback(
    (addressType) => {
      return addressTypes.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === addressType?.code
      })[0]
    },
    [addressTypes]
  )

  const getValueSetUnitType = useCallback(
    (unitType) => {
      return unitTypes.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === unitType?.code
      })[0]
    },
    [unitTypes]
  )

  const getValueSetStreetType = useCallback(
    (streetType) => {
      return streetTypes.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === streetType?.code
      })[0]
    },
    [streetTypes]
  )

  const getValueSetProvince = useCallback(
    (province) => {
      return provinces.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === province?.code
      })[0]
    },
    [provinces]
  )

  const getValueSetCountry = useCallback(
    (country) => {
      return countries.filter((valueSet: CodedConceptDto) => {
        return valueSet.code === country?.code
      })[0]
    },
    [countries]
  )

  const parseRowData = useCallback(
    (d) => {
      const getValueFromLocation = (field: any) => {
        return isFromClient ? _.get(d.location, field) : _.get(d, field)
      }

      return {
        ...d,
        addressId: isFromClient ? _.get(d.location.address, 'id') : d.address.id || null,
        addressType: d.addressType ? getValueSetAddressType(d.addressType) : '',
        areaType: getValueSetAreaType(getValueFromLocation('areaType')),
        buildingDescriptor: ValueUtils.isNull(getValueFromLocation('buildingDescriptor'), ''),
        careOf: ValueUtils.isNull(d.careOf, ''),
        city: getValueFromLocation('address.city'),
        community: ValueUtils.isNull(getValueFromLocation('community'), ''),
        country: getValueSetCountry(getValueFromLocation('address.country')),
        endDate: ValueUtils.isNull(d.effective?.endDate, ''),
        id: d.id,
        inServiceArea: ValueUtils.isNull(getValueFromLocation('inServiceArea'), false),
        locationId: isFromClient ? d.location.id : d.addressEntityId,
        notes: ValueUtils.isNull(d.notes, ''),
        onReserve: ValueUtils.isNull(getValueFromLocation('onReserve'), false),
        postalCode: getValueFromLocation('address.postalCode'),
        preferred: isFromClient ? undefined : ValueUtils.isNull(d.preferred, false),
        preferredFlag: isFromClient ? ValueUtils.isNull(d.preferredFlag, false) : undefined,
        province: getValueSetProvince(getValueFromLocation('address.province')),
        startDate: ValueUtils.isNull(d.effective?.startDate, ''),
        streetName: getValueFromLocation('address.streetName'),
        streetNumber: getValueFromLocation('address.streetNumber'),
        streetType: getValueSetStreetType(getValueFromLocation('address.streetType')),
        unitNumber: ValueUtils.isNull(getValueFromLocation('address.unitNumber'), ''),
        unitType: getValueSetUnitType(getValueFromLocation('address.unitType')),
      }
    },
    [
      getValueSetAddressType,
      getValueSetAreaType,
      getValueSetCountry,
      getValueSetProvince,
      getValueSetStreetType,
      getValueSetUnitType,
      isFromClient,
    ]
  )

  const createHeaderTitle = useCallback(
    (data /* Type: ClientAddressDTO || LocationDTO */, isActualHeader) => {
      let title = ''
      const addressData = isFromClient ? data?.location : data
      const streetType = addressData?.address?.streetType
        ? getValueSetStreetType(addressData.address.streetType).name
        : ''
      const province = addressData?.address?.province
        ? getValueSetProvince(addressData.address.province).name
        : ''
      const country = addressData?.address?.country
        ? getValueSetCountry(addressData.address.country).name
        : ''
      const addressType = data?.addressType ? getValueSetAddressType(data?.addressType).name : ''
      const onReserve = addressData?.onReserve?.name ? addressData.onReserve?.name : ''

      if (addressData?.address?.unitNumber) {
        title += `${addressData.address.unitNumber} - `
      }
      if (addressData?.address?.streetNumber) {
        title += `${addressData.address.streetNumber} `
      }
      if (addressData?.address?.streetName) {
        title += `${addressData.address.streetName} `
      }
      if (streetType) {
        title += `${streetType}, `
      }
      if (addressData?.address?.city) {
        title += `${addressData.address.city}, `
      }
      if (province) {
        title += `${province}, `
      }
      if (addressData?.address?.postalCode) {
        title += `${addressData.address.postalCode}, `
      }
      if (country) {
        title += `${country}`
      }
      if (addressType && isActualHeader) {
        title += ` | ${addressType}`
      }
      if (onReserve) {
        title += ` | ${onReserve}`
      }
      if (addressData?.inServiceArea) {
        title += ` | ${addressData.inServiceArea}`
      }
      if (data?.startDate) {
        title += ` | ${isoDateToDisplayFormat(data.startDate)}`
      }
      if (data?.endDate) {
        title += ` to ${isoDateToDisplayFormat(data.endDate)}`
      }
      return title
    },
    [
      getValueSetAddressType,
      getValueSetCountry,
      getValueSetProvince,
      getValueSetStreetType,
      isFromClient,
    ]
  )

  const handleSearchAddy = useCallback(
    async (value) => {
      const content = await handleSearchAddresses(value)
      if (content) {
        const addressSearchResult: any = content?.map((location: any) => {
          return {
            key: location.id,
            location,
            title: isFromClient
              ? createHeaderTitle({ location }, false)
              : createHeaderTitle(location, false),
          }
        })
        setOptions(addressSearchResult)
      }
    },
    [isFromClient, createHeaderTitle, handleSearchAddresses]
  )

  const handleCreateRows = useCallback(() => {
    if (!addresses) {
      return
    }
    const parsedRowsFromData: any = []
    addresses.forEach((d: any) => {
      parsedRowsFromData.push({
        data: parseRowData(d),
        errors: [],
        householdMembers: {
          activeHouseholds: [],
          showHouseholdMembers: false,
          showTerminatedMembers: false,
          terminatedHouseholds: [],
        },
        isCollapsed: true,
        isParent: false,
      })
    })

    const parsedRowsFromDataSorted = [...parsedRowsFromData].sort((a, b) => {
      if (isFromClient) return b.data.preferredFlag - a.data.preferredFlag
      else return b.data.preferred - a.data.preferred
    })

    if (parentAddresses) {
      const parsedRowsFromParentData: any = []
      parentAddresses.forEach((d: any) => {
        parsedRowsFromParentData.push({
          data: parseRowData(d),
          errors: [],
          isCollapsed: true,
          isParent: true,
        })
      })
      setParentAddressData({
        rows: parsedRowsFromParentData,
      })
    }

    setAddressData({
      errors: [],
      rows: parsedRowsFromDataSorted,
    })
  }, [addresses, parentAddresses, parseRowData, isFromClient])

  useEffect(() => {
    handleCreateRows()
  }, [id, addresses, handleCreateRows])

  const onAddRow = useCallback(() => {
    const rowsUpdate = [
      ...addressData.rows,
      {
        data: {
          addressType: '',
          areaType: '',
          buildingDescriptor: '',
          careOf: '',
          city: '',
          community: '',
          country: '',
          endDate: '',
          inServiceArea: false,
          notes: '',
          onReserve: false,
          postalCode: '',
          preferred: isFromClient ? undefined : addressData?.rows.length > 0 ? false : true,
          preferredFlag: isFromClient ? (addressData?.rows.length > 0 ? false : true) : undefined,
          province: '',
          startDate: dateNowIsoString(),
          streetName: '',
          streetNumber: '',
          streetType: '',
          unitNumber: '',
          unitType: '',
        },
        errors: [],
        householdMembers: {
          activeHouseholds: [],
          showHouseholdMembers: false,
          showTerminatedMembers: false,
          terminatedHouseholds: [],
        },
        isCollapsed: false,
      },
    ]
    setAddressData({ ...addressData, rows: rowsUpdate })
  }, [addressData, isFromClient])

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      if (onDeleteAddress && deleteIndex === -1) {
        setDeleteIndex(rowIndex)
        return
      }
      setDeleteIndex(-1)
      const addressIdToDelete = addressData.rows[rowIndex]?.data?.id
      if (addressIdToDelete) {
        onDeleteAddress?.(addressIdToDelete)
      } else {
        addressData.rows.splice(rowIndex, 1)
        setAddressData({ ...addressData })
      }

      setAutofillChangeIndexes(autofillChangeIndexes.filter((index) => index !== rowIndex))
    },
    [addressData, autofillChangeIndexes, deleteIndex, onDeleteAddress]
  )

  const validate = useCallback(() => {
    let isValid = true
    const validatedRows = addressData.rows.map((row: any) => {
      const errors: ErrorType[] = []

      if (!row.data.addressType) {
        isValid = false
        errors.push({
          field: 'addressType',
          message: t('client-addresses.validation.address-type'),
        })
      }

      if (!row.data.city) {
        isValid = false
        errors.push({
          field: 'city',
          message: t('client-addresses.validation.city'),
        })
      }

      if (!row.data.province) {
        isValid = false
        errors.push({
          field: 'province',
          message: t('client-addresses.validation.province'),
        })
      }

      if (!row.data.country) {
        isValid = false
        errors.push({
          field: 'country',
          message: t('client-addresses.validation.country'),
        })
      }

      if (!row.data.startDate) {
        isValid = false
        errors.push({
          field: 'startDate',
          message: t('client-addresses.validation.startDate'),
        })
      } else if (row.data.startDate) {
        const datePickerError = row.errors.filter((error: ErrorType) => {
          return error.field === 'startDate'
        })
        if (datePickerError.length > 0) {
          isValid = false
        }
      }

      if (row.data.endDate) {
        const datePickerError = row.errors.filter((error: ErrorType) => {
          return error.field === 'endDate'
        })
        if (datePickerError.length > 0) {
          isValid = false
        }
      }

      if (row.data.startDate && row.data.endDate && row.data.startDate > row.data.endDate) {
        errors.push({
          field: 'endDate',
          message: 'Invalid End Date',
        })
        isValid = false
      }

      return {
        ...row,
        errors: errors,
      }
    })

    if (!isValid) {
      return {
        errors: ['Validation errors, expand the rows to view'],
        isValid: false,
        validatedRows,
      }
    }

    return { isValid, validatedRows }
  }, [addressData?.rows, t])

  const setPreferredField = useCallback(
    (row: any, field: any, value: any, index: number) => {
      const hasPreferredFlag = addressData.rows.some((element: any) =>
        isFromClient ? element.data.preferredFlag : element.data.preferred
      )

      const matchingIndex = addressData.rows.findIndex(
        (element: any, i: any) => element.data.id === row.data.id
      )

      if (matchingIndex === index && hasPreferredFlag && value) {
        // openup dialog here
        setPreferredWarningDialog({ data: { field: field, row: row, value: value }, open: true })
        return { ...row }
      }

      const attr: any = Object.keys(row.data).filter((r) => r === field)
      const newRow: any = { ...row }
      newRow.data[attr] = value
      if (value) {
        newRow.errors = newRow.errors.filter((error: ErrorType) => {
          return error.field !== field
        })
      }
      return newRow
    },
    [addressData?.rows, isFromClient]
  )

  const onPreferredWarningClose = useCallback(() => {
    setPreferredWarningDialog({ data: {}, open: false })
  }, [])

  const onAddressWarningClose = useCallback(() => {
    setAddressWarningDialog({ index: -1, open: false })
  }, [])

  const onPreferredWarningSave = useCallback(
    (data: any) => {
      setPreferredWarningDialog({ ...preferredWarningDialog, open: false })
      addressData.rows.forEach((element: any) =>
        isFromClient ? (element.data.preferredFlag = false) : (element.data.preferred = false)
      )
      const attr: any = Object.keys(data.row.data).filter((r) => r === data.field)
      const newRow: any = { ...data.row }
      newRow.data[attr] = data.value
      if (data.value) {
        newRow.errors = newRow.errors.filter((error: ErrorType) => {
          return error.field !== data.field
        })
      }
      return newRow
    },
    [addressData?.rows, isFromClient, preferredWarningDialog]
  )

  const onAddressWarningSave = useCallback(() => {
    const changeIndex = addressWarningDialog.index
    const updatedRows = addressData.rows.map((row, index) => {
      if (index === changeIndex) {
        return {
          ...row,
          data: {
            ...row.data,
            addressEntityId: null,
          },
        }
      }
      return row
    })

    setAddressData({
      ...addressData,
      rows: updatedRows,
    })
    setAutofillChangeIndexes(autofillChangeIndexes.filter((index) => index !== changeIndex))
    setAddressWarningDialog({ index: -1, open: false })
  }, [addressData, addressWarningDialog.index, autofillChangeIndexes])

  const modifyField = useCallback((row: any, field: string, value: any) => {
    // Only for DatePicker if the Date object is invalid
    if (value?.invalid) {
      const rowWithErrors = {
        ...row,
        errors: [
          {
            field,
            message: `Effective ${camelCaseToTitleCase(field)} is not a valid date'`,
          },
        ],
      }
      return rowWithErrors
    }
    const attr: any = Object.keys(row.data).filter((r) => r === field)
    const newRow: any = { ...row }
    newRow.data[attr] = value
    if (value) {
      newRow.errors = newRow.errors.filter((error: ErrorType) => {
        return error.field !== field
      })
    }
    return newRow
  }, [])

  const modifyProperty = useCallback((row: any, property: string, value: any) => {
    const attr: any = Object.keys(row).filter((r) => r === property)
    const newRow: any = { ...row }
    newRow[attr] = value
    return newRow
  }, [])

  const onChangeRow = useCallback(
    (rowIndex: number, field: string, property: any, value: any) => {
      if (!isDirty) setIsDirty(true)

      const updatedRows = addressData.rows.map((row: any, index: number) => {
        if (rowIndex === index) {
          if (field) {
            if (field === 'preferredFlag' || field === 'preferred') {
              return setPreferredField(row, field, value, index)
            } else {
              if (autofillChangeIndexes.includes(index)) {
                setAddressWarningDialog({ index: index, open: true })
              }
              return modifyField(row, field, value)
            }
          } else if (property) {
            return modifyProperty(row, property, value)
          } else {
            //We reach this block when we select an address from the dropdown
            //Adding in new functionality where, if a change is made to any of the values from here
            //we confirm that we want to update the existing address for all clients and then update the address
            const isFromClient = !!value.location

            if (!autofillChangeIndexes.includes(index)) {
              setAutofillChangeIndexes([...autofillChangeIndexes, index])
            }
            const getValueFromLocation = (field: any) => {
              return isFromClient ? _.get(value.location, field) : _.get(value, field)
            }
            const newData = {
              ...row.data,
              addressEntityId: value.addressEntityId
                ? value.addressEntityId
                : value.location.addressEntityId
                ? value.location.addressEntityId
                : null,
              addressId: ValueUtils.isNull(getValueFromLocation('address.id'), null),
              addressType: getValueFromLocation('addressType')
                ? getValueSetAddressType(getValueFromLocation('addressType'))
                : '',
              areaType: getValueFromLocation('address.areaType')
                ? getValueSetAreaType(getValueFromLocation('address.areaType'))
                : '',
              buildingDescriptor: ValueUtils.isNull(getValueFromLocation('buildingDescriptor'), ''),
              city: getValueFromLocation('address.city'),
              community: ValueUtils.isNull(getValueFromLocation('community'), ''),
              country: getValueFromLocation('address.country')
                ? getValueSetCountry(getValueFromLocation('address.country'))
                : '',
              id: ValueUtils.isNull(value.id, null),
              inServiceArea: ValueUtils.isNull(getValueFromLocation('inServiceArea'), false),
              locationId: isFromClient ? value.location.id : null,
              onReserve: ValueUtils.isNull(getValueFromLocation('onReserve'), false),
              postalCode: getValueFromLocation('address.postalCode'),
              province: getValueFromLocation('address.province')
                ? getValueSetProvince(getValueFromLocation('address.province'))
                : '',
              streetName: getValueFromLocation('address.streetName'),
              streetNumber: getValueFromLocation('address.streetNumber'),
              streetType: getValueFromLocation('address.streetType')
                ? getValueSetStreetType(getValueFromLocation('address.streetType'))
                : '',
              unitNumber: ValueUtils.isNull(getValueFromLocation('address.unitNumber'), ''),
              unitType: getValueFromLocation('address.unitType')
                ? getValueSetUnitType(getValueFromLocation('address.unitType'))
                : '',
            }
            const newRow = { ...row, data: newData }
            return newRow
          }
        }
        return row
      })
      setAddressData({ ...addressData, rows: updatedRows })
    },
    [
      isDirty,
      setIsDirty,
      addressData,
      setPreferredField,
      modifyField,
      modifyProperty,
      autofillChangeIndexes,
      getValueSetAddressType,
      getValueSetAreaType,
      getValueSetCountry,
      getValueSetProvince,
      getValueSetStreetType,
      getValueSetUnitType,
    ]
  )

  const onCollapseRow = useCallback(
    (rowIndex: number) => {
      const collapsedRowsUpdate = addressData.rows.map((row: any, index: number) => {
        if (rowIndex === index) {
          return { ...row, isCollapsed: !row.isCollapsed }
        }
        return row
      })
      setAddressData({ ...addressData, rows: collapsedRowsUpdate })
    },
    [addressData]
  )

  const onSave = useCallback(() => {
    setIsDirty(false)

    const { errors, isValid, validatedRows } = validate()
    if (!isValid) {
      setAddressData({ ...addressData, errors: errors, rows: validatedRows })
      return
    }
    const addressUpdate = addressData.rows.map((row: any) => {
      const address = {
        city: row.data.city,
        country: row.data.country,
        id: ValueUtils.isNull(row.data.addressId, null),
        postalCode: row.data.postalCode,
        province: row.data.province,
        streetName: row.data.streetName,
        streetNumber: row.data.streetNumber,
        streetType: ValueUtils.isNull(row.data.streetType, null),
        unitNumber: row.data.unitNumber,
        unitType: ValueUtils.isNull(row.data.unitType, null),
      }

      const updateData = {
        addressEntityId: isFromClient ? null : row.data.addressEntityId,
        addressType: row.data.addressType,
        areaType: ValueUtils.isNull(row.data.areaType, null),
        buildingDescriptor: row.data.buildingDescriptor,
        careOf: row.data.careOf,
        community: row.data.community,
        effective: {
          endDate: row.data.endDate,
          startDate: row.data.startDate,
        },
        id: ValueUtils.isNull(row.data.id, null),
        notes: row.data.notes,
        preferred: isFromClient ? undefined : row.data.preferred,
        preferredFlag: isFromClient ? row.data.preferredFlag : undefined,
      }

      const location = {
        address: address,
        areaType: ValueUtils.isNull(row.data.areaType, null),
        buildingDescriptor: row.data.buildingDescriptor,
        community: row.data.community,
        inServiceArea: row.data.inServiceArea,
        onReserve: row.data.onReserve,
      }
      if (isFromClient) {
        location['id'] = ValueUtils.isNull(row.data.locationId, null)
        return { ...updateData, location: { ...location } }
      } else {
        return { ...location, ...updateData }
      }
    })
    handleAddressUpdate(addressUpdate)
    setAutofillChangeIndexes([])
  }, [addressData, handleAddressUpdate, isFromClient, validate, setIsDirty])

  const rowHeader = useCallback(
    (row: any, rowIndex: number, onChangeRow: any) => {
      return (
        <>
          {!row.isParent && (
            <MISRadio
              checked={isFromClient ? row?.data?.preferredFlag : row?.data?.preferred}
              name="addresses"
              onChange={() => {
                onChangeRow(
                  rowIndex,
                  isFromClient ? 'preferredFlag' : 'preferred',
                  null,
                  isFromClient ? !row.data.preferredFlag : !row.data.preferred
                )
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
              sx={{
                pointerEvents: 'auto',
              }}
              value={rowIndex}
            />
          )}
          <span>{createHeaderTitle(row.data, true)}</span>
          <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{`${
            parentAddresses ? (row?.isParent ? ' | Parent ' : ' | Department ') : ''
          }`}</span>
          <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{`${
            (isFromClient ? row?.data?.preferredFlag : row?.data?.preferred)
              ? ' | ' + t('client-demographics.names.preferred')
              : ''
          }`}</span>
        </>
      )
    },
    [createHeaderTitle, isFromClient, parentAddresses, t]
  )

  const rowDataForm = useCallback(
    (row: any, rowIndex: number, onChangeRow: any) => {
      const hasError = (field: any) => {
        const rowError = row.errors.filter((error: ErrorType) => {
          return error.field === field
        })
        if (rowError[0]) {
          return true
        } else {
          return false
        }
      }

      const getFieldValidationError = (field: any) => {
        const rowError = row.errors.filter((error: ErrorType) => {
          return error.field === field
        })
        if (rowError[0]) {
          return rowError[0].message
        } else {
          return ''
        }
      }

      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropdownSearch
                label={t('client-addresses.address-search')}
                onSearch={handleSearchAddy}
                onSelect={(address: any) => onChangeRow(rowIndex, null, null, address)}
                options={options}
                value={null}
              />
            </Grid>
            <Grid item xs={3}>
              {addressTypes && (
                <MISSelectDropdown
                  error={hasError('addressType')}
                  helperText={getFieldValidationError('addressType')}
                  label={t('client-addresses.address-type')}
                  onChange={(event) =>
                    onChangeRow(rowIndex, 'addressType', null, event.target.value)
                  }
                  options={addressTypes?.map((option: any) => {
                    return {
                      label: option.name,
                      value: option,
                    }
                  })}
                  required
                  value={row?.data?.addressType}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <MISSelectDropdown
                error={hasError('unitType')}
                helperText={getFieldValidationError('unitType')}
                label={t('client-addresses.unit-type')}
                onChange={(event) => onChangeRow(rowIndex, 'unitType', null, event.target.value)}
                options={unitTypes?.map((option: any) => {
                  return {
                    label: option.name,
                    value: option,
                  }
                })}
                value={row?.data?.unitType}
              />
            </Grid>
            <Grid item xs={1}>
              <MISTextField
                error={hasError('unitNumber')}
                helperText={getFieldValidationError('unitNumber')}
                label={t('client-addresses.unit-num')}
                onChange={(event) => onChangeRow(rowIndex, 'unitNumber', null, event.target.value)}
                value={row?.data?.unitNumber}
              />
            </Grid>
            <Grid item xs={1}>
              <MISTextField
                error={hasError('streetNumber')}
                helperText={getFieldValidationError('streetNumber')}
                label={t('client-addresses.street-num')}
                onChange={(event) =>
                  onChangeRow(rowIndex, 'streetNumber', null, event.target.value)
                }
                value={row?.data?.streetNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <MISTextField
                error={hasError('streetName')}
                helperText={getFieldValidationError('streetName')}
                label={t('client-addresses.street-name')}
                onChange={(event) => onChangeRow(rowIndex, 'streetName', null, event.target.value)}
                value={row?.data?.streetName}
              />
            </Grid>
            <Grid item xs={3}>
              <MISSelectDropdown
                error={hasError('streetType')}
                helperText={getFieldValidationError('streetType')}
                label={t('client-addresses.street-type')}
                onChange={(event) => onChangeRow(rowIndex, 'streetType', null, event.target.value)}
                options={streetTypes?.map((option: any) => {
                  return {
                    label: option.name,
                    value: option,
                  }
                })}
                value={row?.data?.streetType}
              />
            </Grid>
            <Grid item xs={4}>
              <MISTextField
                error={hasError('buildingDescriptor')}
                helperText={getFieldValidationError('buildingDescriptor')}
                label={t('client-addresses.building-name')}
                onChange={(event) =>
                  onChangeRow(rowIndex, 'buildingDescriptor', null, event.target.value)
                }
                value={row?.data?.buildingDescriptor}
              />
            </Grid>
            {/* -------------------------------------------------------------------- */}
            <Grid item xs={3}>
              <MISTextField
                error={hasError('community')}
                helperText={getFieldValidationError('community')}
                label={t('client-addresses.community')}
                onChange={(event) => onChangeRow(rowIndex, 'community', null, event.target.value)}
                value={row?.data?.community}
              />
            </Grid>
            <Grid item xs={3}>
              <MISTextField
                error={hasError('city')}
                helperText={getFieldValidationError('city')}
                label={t('client-addresses.city')}
                onChange={(event) => onChangeRow(rowIndex, 'city', null, event.target.value)}
                required
                value={row?.data?.city}
              />
            </Grid>
            <Grid item xs={2}>
              <MISSelectDropdown
                error={hasError('province')}
                helperText={getFieldValidationError('province')}
                label={t('client-addresses.province')}
                onChange={(event) => onChangeRow(rowIndex, 'province', null, event.target.value)}
                options={provinces.map((option: CodedConceptDto) => ({
                  label: option.name as string,
                  value: option,
                }))}
                required
                value={row?.data?.province}
              />
            </Grid>
            <Grid item xs={2}>
              <MISTextField
                error={hasError('postalCode')}
                helperText={getFieldValidationError('postalCode')}
                label={t('client-addresses.postal-code')}
                onChange={(event) => onChangeRow(rowIndex, 'postalCode', null, event.target.value)}
                value={row?.data?.postalCode}
              />
            </Grid>
            <Grid item xs={2}>
              <MISSelectDropdown
                error={hasError('country')}
                helperText={getFieldValidationError('country')}
                label={t('client-addresses.country')}
                onChange={(event) => onChangeRow(rowIndex, 'country', null, event.target.value)}
                options={countries.map((option: CodedConceptDto) => ({
                  label: option.name as string,
                  value: option,
                }))}
                required
                value={row?.data?.country}
              />
            </Grid>
            {/* -------------------------------------------------------------------- */}
            <Grid item xs={3}>
              <MISTextField
                label="c/o"
                onChange={(event) => onChangeRow(rowIndex, 'careOf', null, event.target.value)}
                value={row?.data?.careOf}
              />
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                error={hasError('startDate')}
                helperText={getFieldValidationError('startDate')}
                label={t('client-addresses.start-date')}
                onChange={(value) => onChangeRow(rowIndex, 'startDate', null, value)}
                required
                value={row?.data?.startDate}
              />
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                error={hasError('endDate')}
                helperText={getFieldValidationError('endDate')}
                label={t('client-addresses.end-date')}
                onChange={(value) => onChangeRow(rowIndex, 'endDate', null, value)}
                value={row?.data?.endDate}
              />
            </Grid>
            <Grid item xs={2}>
              <MISSelectDropdown
                error={hasError('areaType')}
                helperText={getFieldValidationError('areaType')}
                label={t('client-addresses.area-type')}
                onChange={(event) => onChangeRow(rowIndex, 'areaType', null, event.target.value)}
                options={areaTypes?.map((option: CodedConceptDto) => ({
                  label: option.name as string,
                  value: option,
                }))}
                value={row?.data?.areaType}
              />
            </Grid>
            <Grid item xs={1.5}>
              <MISRadioGroup
                defaultValue={(row.data.onReserve ? 'Y' : 'N') || 'Y'}
                label={t('client-addresses.on-reserve')}
                onChange={(event, value) => {
                  onChangeRow(rowIndex, 'onReserve', null, value === 'Y')
                }}
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                required
                sx={{
                  '.MuiFormControlLabel-root': {
                    marginRight: GLOBAL.MARGIN_XS,
                    marginTop: GLOBAL.MARGIN_XS,
                  },
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <MISRadioGroup
                defaultValue={(row.data.inServiceArea ? 'Y' : 'N') || 'Y'}
                label={t('client-addresses.service-area')}
                onChange={(event, value) => {
                  onChangeRow(rowIndex, 'inServiceArea', null, value === 'Y')
                }}
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                required
                sx={{
                  '.MuiFormControlLabel-root': {
                    marginRight: GLOBAL.MARGIN_XS,
                    marginTop: GLOBAL.MARGIN_XS,
                  },
                }}
              />
            </Grid>
            {/* -------------------------------------------------------------------- */}
            <Grid item xs={6}>
              <MISTextField
                inputProps={{ maxLength: 255 }}
                label={t('client-addresses.address-notes')}
                onChange={(event) => onChangeRow(rowIndex, 'notes', null, event.target.value)}
                value={row?.data?.notes}
              />
            </Grid>
          </Grid>
          {isFromClient && handleSearchHouseholdAddresses && (
            <Grid
              container
              spacing={2}
              style={{ paddingBottom: 15, paddingRight: 10, paddingTop: 15 }}
            >
              <Grid item style={{ paddingTop: 15 }} xs={12}>
                <MISButton
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={async () => {
                    const householdAddresses = await handleSearchHouseholdAddresses(
                      row.data.addressId
                    )
                    if (householdAddresses) {
                      const activeHouseholds = householdAddresses
                        .filter((addr: any) => {
                          return !addr.clientAddress.isTerminated
                        })
                        .map((addr: any) => {
                          return {
                            addressType: addr.clientAddress?.addressType
                              ? getValueSetAddressType(addr.clientAddress?.addressType).name
                              : '',
                            clientNumber: addr.client?.fileNumber,
                            clientRecord: addr.client?.id,
                            firstName: addr.client.name.firstName,
                            lastName: addr.client.name.lastName,
                            startDate: addr.clientAddress?.effective?.startDate
                              ? isoDateToDisplayFormat(addr.clientAddress?.effective?.startDate)
                              : '',
                          }
                        })
                      const terminatedHouseholds = householdAddresses
                        .filter((addr: any) => {
                          return addr.clientAddress.isTerminated
                        })
                        .map((addr: any) => {
                          return {
                            addressType: addr.clientAddress?.addressType
                              ? getValueSetAddressType(addr.clientAddress?.addressType).name
                              : '',
                            clientNumber: addr.client?.fileNumber,
                            clientRecord: addr.client?.id,
                            endDate: addr.clientAddress?.effective?.endDate
                              ? isoDateToDisplayFormat(addr.clientAddress?.effective?.endDate)
                              : '',
                            firstName: addr.client.name.firstName,
                            lastName: addr.client.name.lastName,
                            startDate: addr.clientAddress?.effective?.startDate
                              ? isoDateToDisplayFormat(addr.clientAddress?.effective?.startDate)
                              : '',
                          }
                        })
                      const householdMembers = {
                        activeHouseholds,
                        showHouseholdMembers: !row.householdMembers.showHouseholdMembers,
                        showTerminatedMembers: row.householdMembers.showTerminatedMembers,
                        terminatedHouseholds,
                      }
                      onChangeRow(rowIndex, null, 'householdMembers', householdMembers)
                    }
                  }}
                  size="small"
                  variant="outlined"
                >
                  {!row.householdMembers.showHouseholdMembers && `Show Household Members`}
                  {row.householdMembers.showHouseholdMembers && `Hide Household Members`}
                </MISButton>
                {row.householdMembers.showHouseholdMembers && (
                  <Paper style={{ marginTop: 15 }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow style={{ backgroundColor: '#E5E5E5' }}>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Client Number</TableCell>
                            <TableCell>Address Type</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>Client Record</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.householdMembers.activeHouseholds.map(
                            (household: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {household.firstName}
                                </TableCell>
                                <TableCell>{household.lastName}</TableCell>
                                <TableCell>{household.clientNumber}</TableCell>
                                <TableCell>{household.addressType}</TableCell>
                                <TableCell>{household.startDate}</TableCell>
                                <TableCell>
                                  <MISButton
                                    onClick={() =>
                                      navigate('/clients/client-record/' + household.clientRecord)
                                    }
                                    variant="text"
                                  >
                                    View
                                  </MISButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}
              </Grid>
              {row.householdMembers.showHouseholdMembers && (
                <Grid item xs={12}>
                  <MISButton
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={() => {
                      const householdMembers = {
                        activeHouseholds: row.householdMembers.activeHouseholds,
                        showHouseholdMembers: row.householdMembers.showHouseholdMembers,
                        showTerminatedMembers: !row.householdMembers.showTerminatedMembers,
                        terminatedHouseholds: row.householdMembers.terminatedHouseholds,
                      }
                      onChangeRow(rowIndex, null, 'householdMembers', householdMembers)
                    }}
                    size="small"
                    variant="outlined"
                  >
                    {!row.householdMembers.showTerminatedMembers && `Show Terminiated Members`}
                    {row.householdMembers.showTerminatedMembers && `Hide Terminiated Members`}
                  </MISButton>
                  {row.householdMembers.showTerminatedMembers && (
                    <Paper style={{ marginTop: 15 }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow style={{ backgroundColor: '#E5E5E5' }}>
                              <TableCell>First Name</TableCell>
                              <TableCell>Last Name</TableCell>
                              <TableCell>Client Number</TableCell>
                              <TableCell>Address Type</TableCell>
                              <TableCell>Start Date</TableCell>
                              <TableCell>End Date</TableCell>
                              <TableCell>Client Record</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.householdMembers.terminatedHouseholds.map(
                              (household: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {household.firstName}
                                  </TableCell>
                                  <TableCell>{household.lastName}</TableCell>
                                  <TableCell>{household.clientNumber}</TableCell>
                                  <TableCell>{household.addressType}</TableCell>
                                  <TableCell>{household.startDate}</TableCell>
                                  <TableCell>{household.endDate}</TableCell>
                                  <MISButton
                                    onClick={() =>
                                      navigate('/clients/client-record/' + household.clientRecord)
                                    }
                                    sx={{ p: 0, textAlign: 'left' }}
                                    variant="text"
                                  >
                                    View
                                  </MISButton>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </>
      )
    },
    [
      addressTypes,
      areaTypes,
      countries,
      getValueSetAddressType,
      handleSearchAddy,
      handleSearchHouseholdAddresses,
      isFromClient,
      navigate,
      options,
      provinces,
      streetTypes,
      t,
      unitTypes,
    ]
  )
  return (
    <>
      <SectionHeader
        addLabel="Add Address"
        onAdd={onAddRow}
        onSave={onSave}
        saveLabel={t('common.button.save')}
        title={title}
      />
      <Box className="addresses-section-container">
        {parentAddressData?.rows?.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6">
              {' '}
              {namesForDepartment[0]}{' '}
            </Typography>
            <MISListContainer
              emptyDataMessage="There are no addresses to be displayed."
              isDisabled
              onChangeRow={onChangeRow}
              onCollapseRow={onCollapseRow}
              onRemoveRow={onRemoveRow}
              rowForm={rowHeader}
              rows={parentAddressData.rows}
            />
            <Typography sx={{ fontWeight: 'bold', mb: 2, mt: 3 }} variant="h6">
              {' '}
              {namesForDepartment[1]}{' '}
            </Typography>
          </>
        )}
        {addressData?.rows && (
          <MISAccordionContainer
            emptyDataMessage="There are no addresses to be displayed."
            errors={addressData.errors}
            onChangeRow={onChangeRow}
            onCollapseRow={onCollapseRow}
            onRemoveRow={onRemoveRow}
            rowForm={rowDataForm}
            rowHeader={rowHeader}
            rows={addressData.rows}
          />
        )}
      </Box>
      <WarningDialog
        entity={ENTITY}
        onCancel={() => setDeleteIndex(-1)}
        onSave={() => onRemoveRow(deleteIndex)}
        open={onDeleteAddress && deleteIndex > -1}
        type={MODALS.DELETE_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => onPreferredWarningClose()}
        onSave={() => onPreferredWarningSave(preferredWarningDialog.data)}
        open={preferredWarningDialog.open}
        type={MODALS.PREFERRED_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => onAddressWarningClose()}
        onSave={() => onAddressWarningSave()}
        open={!isFromProvider && addressWarningDialog.open}
        type={MODALS.UPDATE_ADDRESS}
      />
    </>
  )
}

export default Addresses
