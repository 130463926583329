import { atom } from 'recoil'
import {
  DomainAssociationDTO,
  FormDataDTO,
  GovernanceAgencyDTO,
  OrganizationAssociationDTO,
} from 'services/openapi'

export const govAgencyState = atom<GovernanceAgencyDTO | null>({
  default: null,
  key: 'govAgencyState',
})

export const govAssociatedDomainsState = atom<DomainAssociationDTO[] | null>({
  default: null,
  key: 'govAssociatedDomainsState',
})

export const govAgencyDepartmentState = atom<GovernanceAgencyDTO | null>({
  default: null,
  key: 'govAgencyDepartmentState',
})
export const govAssociatedGovAgenciesState = atom<OrganizationAssociationDTO[] | null>({
  default: null,
  key: 'govAssociatedGovAgenciesState',
})

export const govDeparmentsState = atom<GovernanceAgencyDTO[] | null>({
  default: null,
  key: 'govDeparmentsState',
})

export const govAgencyFormState = atom<FormDataDTO[] | null>({
  default: null,
  key: 'govAgencyFormState',
})
