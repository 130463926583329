import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import AssociationTable from 'common/components/associations/AssociationListTemplate'
import {
  buildAssociationMapUtil,
  saveAssociations,
} from 'common/components/associations/AssociationUtils'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { typeGroupAtom, typesAtom } from 'recoil/atoms'
import { TypeControllerService, TypeGroupAssociationControllerService } from 'services/openapi'

export default function Types() {
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const { t } = useTranslation('common')

  const [selectedRow, setSelectedRow] = useState({})
  const [associations, setAssociations] = useState(new Map())
  const [otherEntities, setOtherEntities] = useState([])
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const typeGroups = useRecoilValue(typeGroupAtom)

  const getEntitiesServiceCall = TypeControllerService.listTypes
  const createEntityServiceCall = TypeControllerService.createType
  const updateEntityServiceCall = TypeControllerService.updateType
  const updateAtomCall = useSetRecoilState(typesAtom)

  const saveTypeAssociations = (associationsToSave) => {
    saveAssociations(
      associationsToSave,
      TypeGroupAssociationControllerService.updateTypeGroupAssociation,
      TypeControllerService.addTypeGroupToType,
      setOpenLinkModal,
      setRefetchNot,
      handleApiError,
      () => showSnackSuccess(t('api.save-success')),
      selectedRow
    )
  }

  const setRefetchNot = () => {
    setRefetch(!refetch)
  }

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      TypeControllerService.getTypeGroupAssociationsForType(selectedRow.id).then(
        (data) => {
          setOtherEntities(typeGroups)
          buildAssociationMapUtil(
            data,
            typeGroups,
            'typeGroupId',
            setAssociations,
            setOtherEntities,
            handleApiError,
            selectedRow
          )
        },
        (error) => {
          handleApiError(error)
        }
      )
    }
  }, [selectedRow, refetch, handleApiError, typeGroups])

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h1>{t('types.title.types')}</h1>
        {t('types.title.types-description')}

        <EntityTemplate
          createEntityServiceCall={createEntityServiceCall}
          entityName={t('types.title.types')}
          getEntitiesServiceCall={getEntitiesServiceCall}
          onRowSelectCallback={setSelectedRow}
          updateAtomCall={updateAtomCall}
          updateEntityServiceCall={updateEntityServiceCall}
        />
      </Box>
      {selectedRow && selectedRow.id && (
        <Box sx={{ p: 2 }}>
          <h3>
            {t('types.title.association-header')} {selectedRow.name}
          </h3>
          <AssociationTable
            associations={associations}
            closeLinkModalCallBack={() => setOpenLinkModal(false)}
            entity={selectedRow}
            headerName={t('type-groups.title.type-groups')}
            openLinkModal={openLinkModal}
            openLinkModalCallBack={() => setOpenLinkModal(true)}
            otherEntities={otherEntities}
            saveCallBack={saveTypeAssociations}
          />
        </Box>
      )}
    </>
  )
}
