import { Checkbox, CheckboxProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

const MISCheckbox = (props: CheckboxProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <Checkbox {...props} size="small" />
    </ThemeProvider>
  )
}

export default MISCheckbox
