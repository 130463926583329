import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

export const selectChartingCurrentTemplate = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.currentTemplate
)

export const selectChartingMyTemplates = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.myTemplates
)

export const selectChartingPreviewState = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.preview
)

export const selectChartingPublicTemplates = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.publicTemplates
)

export const selectChartingValidationActive = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.validationActive
)
