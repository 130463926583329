import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Container } from '@mui/system'
import { useRecoilValue } from 'recoil'
import { Breadcrumb } from 'common/components/breadcrumb/Breadcrumb'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import StickyHeadTable from 'common/components/TableList'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  domainsAtom,
  functionalAreasAtom,
  programsAtom,
  purposesAtom,
  typesAtom,
} from 'recoil/atoms'
import { serviceTemplateState } from 'recoil/lastupdated'
import { terminologyState } from 'recoil/terminology'
import { EncounterServiceTemplateControllerService } from 'services/openapi'
import { DURATION_TYPES, SIGN_REQ_TYPES } from 'services/terminologyConstants'

const headCells = [
  {
    id: 'templateName',
    label: 'Template Name',
  },
  {
    id: 'program',
    label: 'Program',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'purpose',
    label: 'Purpose',
  },
  {
    id: 'startDate',
    label: 'Start Date',
  },
  {
    id: 'endDate',
    label: 'End Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
]

const automationOptions = [
  {
    label: 'None',
    value: false,
  },
  {
    label: 'Automated',
    value: true,
  },
]

const tableBodies = [
  { id: 'name', width: 20 },
  { id: 'program', width: 20 },
  { id: 'type', width: 15 },
  { id: 'purpose', width: 15 },
  { id: 'startDate', width: 10 },
  { id: 'endDate', width: 10 },
  { id: 'status', width: 10 },
]

const pageList = [5, 10, 15]

function SearchEncounterServiceTemplates({ breadcrumb }) {
  const { handleApiError } = useErrorHandler()
  const [searchText, setSearchText] = React.useState('')
  const [automation, setAutomation] = React.useState('')
  const [signatureRequirement, setSignatureRequirement] = React.useState('')
  const [duration, setDuration] = React.useState('')
  const [domains, setDomains] = React.useState([])
  const [functionalAreas, setFunctionalAreas] = React.useState([])
  const [data, setData] = useState([])

  const valueSets = useRecoilValue(terminologyState)
  const domainSets = useRecoilValue(domainsAtom)
  const functionSets = useRecoilValue(functionalAreasAtom)
  const programs = useRecoilValue(programsAtom)
  const purposes = useRecoilValue(purposesAtom)
  const types = useRecoilValue(typesAtom)

  const lastUpdatedServiceTemplate = useRecoilValue(serviceTemplateState)
  const [encounterTemplateSearchResults, setEncounterTemplateSearchResults] = React.useState([])
  const [durationOptions, setDurationOptions] = React.useState([])
  const [signatureRequiredOptions, setSignatureRequiredOptions] = React.useState([])
  const [validSearchInput, setValidSearchInput] = React.useState(false)
  const [showSearchError, setShowSearchError] = React.useState(false)
  const [domainsOptions, setDomainsOptions] = React.useState([])
  const [functionalAreasOptions, setFunctionalAreasOptions] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(null)
  const rowsPerPage = 5
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  /**
   * Build a row for the table.  The columns in the row will be
   * accessed by property name when building the table, and also
   * for sorting.
   */
  function createRow(id, name, program, purpose, type, startDate, endDate, status) {
    const row = {
      endDate,
      id,
      name,
      program,
      purpose,
      startDate,
      status,
      type,
    }
    return row
  }

  const getLastUpatedRow = useCallback(
    (lastUpdatedServiceTemplate) => {
      if (lastUpdatedServiceTemplate) {
        const programName = programs.find(
          (x) => lastUpdatedServiceTemplate.associations.program.programId === x.id
        ).name
        const purposeName = purposes.find(
          (x) => lastUpdatedServiceTemplate.associations.purpose.purposeId === x.id
        ).name
        const typeName = types.find(
          (x) => lastUpdatedServiceTemplate.associations.type.typeId === x.id
        ).name
        return createRow(
          lastUpdatedServiceTemplate.id,
          lastUpdatedServiceTemplate.name,
          programName,
          purposeName,
          typeName,
          isoDateToDisplayFormat(lastUpdatedServiceTemplate.effective.startDate),
          lastUpdatedServiceTemplate.effective.endDate
            ? isoDateToDisplayFormat(lastUpdatedServiceTemplate.effective.endDate)
            : '',
          lastUpdatedServiceTemplate.effective.isTerminated ? 'TERMINATED' : ''
        )
      }
    },
    [programs, purposes, types]
  )

  const handleTableChange = (page, rowsPerPage, orderBy, order) => {
    setPage(page)
    setPageSize(rowsPerPage)
    setOrder(order)
    setOrderBy(orderBy)
  }

  // Check if the user has specified at least one search parameter in
  // addition to the default active status criteria
  const validateSearchInput = useCallback(() => {
    if (searchText || automation || signatureRequirement || duration || functionalAreas) {
      setValidSearchInput(true)
      return true
    } else {
      setValidSearchInput(false)
      return false
    }
  }, [automation, duration, functionalAreas, searchText, signatureRequirement])
  const handleSearchEncounterServiceTemplates = useCallback(() => {
    if (validateSearchInput()) {
      EncounterServiceTemplateControllerService.organizationgetPagedList(
        searchText,
        '',
        '',
        '',
        '',
        '',
        signatureRequirement,
        automation,
        duration,
        domains,
        functionalAreas,
        null,
        '',
        page,
        pageSize,
        `${orderBy},${order}`
      )
        .then((result) => {
          setEncounterTemplateSearchResults(result.content)
        })
        .catch((error) => {
          handleApiError(error)
        })
    } else {
      setShowSearchError(true)
    }
  }, [
    automation,
    duration,
    domains,
    functionalAreas,
    handleApiError,
    order,
    orderBy,
    page,
    pageSize,
    searchText,
    signatureRequirement,
    validateSearchInput,
  ])

  useEffect(() => {
    if (encounterTemplateSearchResults?.length > 0) {
      const data = encounterTemplateSearchResults.map((item) => {
        const programName = programs.find((x) => item.associations.program.programId === x.id).name
        const purposeName = purposes.find((x) => item.associations.purpose.purposeId === x.id).name
        const typeName = types.find((x) => item.associations.type.typeId === x.id).name
        return createRow(
          item.id,
          item.name,
          programName,
          purposeName,
          typeName,
          isoDateToDisplayFormat(item.effective.startDate),
          item.effective.endDate ? isoDateToDisplayFormat(item.effective.endDate) : '',
          item.effective.isTerminated ? 'TERMINATED' : ''
        )
      })
      setData(data)
    } else {
      setData([])
    }
  }, [encounterTemplateSearchResults, programs, purposes, types])

  useEffect(() => {
    if (
      (!signatureRequiredOptions || signatureRequiredOptions.length === 0) &&
      valueSets &&
      valueSets.length > 0
    ) {
      const result = valueSets.find((x) => x.setName === SIGN_REQ_TYPES)
      setSignatureRequiredOptions(result.value)
    }
  }, [signatureRequiredOptions, valueSets])

  useEffect(() => {
    if (!domainsOptions || domainsOptions.length === 0) {
      setDomainsOptions(domainSets)
    }
  }, [domainSets, domainsOptions, setDomainsOptions])

  useEffect(() => {
    if (!functionalAreasOptions || functionalAreasOptions.length === 0) {
      setFunctionalAreasOptions(functionSets)
    }
  }, [functionSets, functionalAreasOptions, setFunctionalAreasOptions])

  useEffect(() => {
    if ((!durationOptions || durationOptions.length === 0) && valueSets && valueSets.length > 0) {
      const result = valueSets.find((x) => x.setName === DURATION_TYPES)
      setDurationOptions(result.value)
    }
  }, [durationOptions, valueSets])

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }

  const handleAutomationChange = (event) => {
    setAutomation(event.target.value)
  }

  const handleSignatureRequirementChange = (event) => {
    setSignatureRequirement(event.target.value)
  }

  const handleDurationChange = (event) => {
    setDuration(event.target.value)
  }

  const handleDomainsChange = (event) => {
    setDomains(event.target.value)
  }

  const handleFunctionalAreasChange = (event) => {
    setFunctionalAreas(event.target.value)
  }

  useEffect(() => {
    handleSearchEncounterServiceTemplates()
  }, [page, rowsPerPage, orderBy, order, handleSearchEncounterServiceTemplates])

  const handleClearSearch = () => {
    setSearchText('')
    setAutomation('')
    setSignatureRequirement('')
    setDuration('')
    setDomains([])
    setFunctionalAreas([])

    // Do not show error if input was previously valid
    if (validSearchInput) {
      setShowSearchError(false)
    }

    // Search input is no longer valid after a clear
    setValidSearchInput(false)
  }

  const handleSelectEncounterTemplateRecord = (row) => {
    navigate('/encounter/service-templates/edit/' + row.id)
  }

  const navigateCreateEncounterTemplate = () => {
    navigate('/encounter/service-templates/create')
  }

  return (
    <div>
      <Breadcrumb breadcrumb={breadcrumb} />

      <Container maxWidth={false} sx={{ maxWidth: '100%' }}>
        <Box
          sx={{
            marginTop: '3rem',
            padding: '0.1rem',
          }}
        >
          <Typography component="div" gutterBottom variant="h4">
            {t('encounter-template-search.title.encounter-template-list')}
          </Typography>

          <Typography component="div" display="block" gutterBottom variant="subtitle1">
            {t('encounter-template-search.title.encounter-template-list-subtitle')}
          </Typography>

          <Typography component="div" gutterBottom mt={3} variant="h5">
            {t('encounter-template-search.title.encounter-template-search-results')}
          </Typography>
          <Typography component="div" display="block" gutterBottom variant="subtitle1">
            {t('encounter-template-search.title.encounter-template-search-subtitle')}
          </Typography>
        </Box>
        <Box
          sx={{
            background: '#FFFFFF',
            borderRadius: '6px',
            padding: '1rem',
          }}
        >
          <Box
            sx={{
              '& .MuiTextField-root': { marginRight: 2, marginTop: 2, width: '100%' },
            }}
          >
            {showSearchError && !validSearchInput && (
              <Alert severity="error" sx={{ marginTop: '1rem' }}>
                {t('encounter-template-search.error.encounter-template-search-parameter')}
              </Alert>
            )}

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Tooltip
                    placement="top"
                    title={t('encounter-template-search.tooltip.search-text')}
                  >
                    <MISTextField
                      id="searchText"
                      label="Search Text"
                      onChange={handleSearchTextChange}
                      value={searchText}
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={4}>
                  <Tooltip
                    placement="top"
                    title={t('encounter-template-search.tooltip.automation')}
                  >
                    <MISTextField
                      id="automation"
                      label="Automation"
                      onChange={handleAutomationChange}
                      select
                      value={automation}
                    >
                      {automationOptions.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MISTextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={3}>
                  <Tooltip
                    placement="top"
                    title={t('encounter-template-search.tooltip.sign-requirement')}
                  >
                    <MISTextField
                      id="signatureRequirement"
                      label="Signature Requirement"
                      onChange={handleSignatureRequirementChange}
                      select
                      value={signatureRequirement}
                    >
                      {signatureRequiredOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.code}
                        </MenuItem>
                      ))}
                    </MISTextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={3}>
                  <Tooltip placement="top" title={t('encounter-template-search.tooltip.duration')}>
                    <MISTextField
                      id="duration"
                      label="Duration"
                      onChange={handleDurationChange}
                      select
                      value={duration}
                    >
                      {durationOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.code}
                        </MenuItem>
                      ))}
                    </MISTextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={3}>
                  <Tooltip placement="top" title={t('encounter-template-search.tooltip.domains')}>
                    <MISTextField
                      SelectProps={{
                        multiple: true,
                        onChange: handleDomainsChange,
                        value: domains,
                      }}
                      id="domains"
                      label="Domains"
                      multiple
                      onChange={handleDomainsChange}
                      select
                      value={domains}
                    >
                      {domainsOptions.map((option) => (
                        <MenuItem key={option.domainName} value={option.id}>
                          {option.domainName}
                        </MenuItem>
                      ))}
                    </MISTextField>
                  </Tooltip>
                </Grid>

                <Grid item xs={3}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t('encounter-template-search.tooltip.functional-areas')}
                  >
                    <MISTextField
                      SelectProps={{
                        multiple: true,
                        onChange: handleFunctionalAreasChange,
                        value: functionalAreas,
                      }}
                      id="functionalAreas"
                      label="Functional Areas"
                      multiple
                      onChange={handleFunctionalAreasChange}
                      select
                      value={functionalAreas}
                    >
                      {functionalAreasOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.displayName}
                        </MenuItem>
                      ))}
                    </MISTextField>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Divider
            sx={{
              marginBottom: '2rem',
              marginLeft: '-2rem',
              marginRight: '-2rem',
              marginTop: '2rem',
            }}
          />

          <Box sx={{ marginTop: '1rem', textAlign: 'start' }}>
            <MISButton
              onClick={() => {
                handleSearchEncounterServiceTemplates(true)
                setShowSearchError(true)
              }}
            >
              {t('common.button.search')}
            </MISButton>
            <MISButton onClick={handleClearSearch} sx={{ marginLeft: '1rem' }}>
              {t('common.button.reset')}
            </MISButton>
          </Box>
        </Box>

        <Box
          sx={{
            '& .MuiTextField-root': { marginRight: 2, marginTop: 2, width: '25ch' },
          }}
        >
          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0)',
              borderRadius: '6px',
              margin: '0.5rem',
              padding: '0.5rem',
              paddingBottom: '0rem',
              paddingTop: '1rem',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography component="div" gutterBottom sx={{ textAlign: 'start' }} variant="h6">
                {t('encounter-template-search.title.encounter-template-search-results')}
              </Typography>
              <MISButton
                onClick={navigateCreateEncounterTemplate}
                sx={{ marginBottom: '1rem', textAlign: 'end' }}
              >
                {t('encounter-template-search.button.add-template')}
              </MISButton>
            </Box>
          </Box>
          <StickyHeadTable
            callSearch={(order, orderBy, page, rowsPerPage) =>
              handleTableChange(page, rowsPerPage, orderBy, order)
            }
            data={data}
            isMultiSelectable={false}
            isPaginated
            lastUpdated={getLastUpatedRow(lastUpdatedServiceTemplate)}
            onRowClick={(row) => handleSelectEncounterTemplateRecord(row)}
            tableBodies={tableBodies}
            tableHeaders={headCells}
            tablePages={pageList}
          />
        </Box>
      </Container>
    </div>
  )
}

export default SearchEncounterServiceTemplates
