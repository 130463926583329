/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDeathDetailDTO } from '../models/ClientDeathDetailDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientDeathDetailControllerService {
  /**
   * Returns death detail for client
   * Returns a death detail for client
   * @param clientId
   * @returns ClientDeathDetailDTO OK
   * @throws ApiError
   */
  public static getDeathDetails(clientId: string): CancelablePromise<ClientDeathDetailDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/death-details',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Update client death details
   * update client death details
   * @param clientId
   * @param requestBody
   * @returns ClientDeathDetailDTO OK
   * @throws ApiError
   */
  public static updateDeathDetails(
    clientId: string,
    requestBody: ClientDeathDetailDTO
  ): CancelablePromise<ClientDeathDetailDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/death-details',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete observation by observationId
   * Soft deletes the observation by setting deleted flag to true
   * @param clientId
   * @param observationId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteDeathDetailObservation(
    clientId: string,
    observationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/clients/{clientId}/death-details/observations/{observationId}',
      path: {
        clientId: clientId,
        observationId: observationId,
      },
    })
  }
}
