import React from 'react'
import Agreements from './Agreements'

const AgreementsAcceptableUse = ({ breadcrumb }) => {
  return (
    <Agreements breadcrumb={breadcrumb} title="Acceptable Use Agreements" type="acceptable-uses" />
  )
}

export default AgreementsAcceptableUse
