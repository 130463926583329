import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Divider, Grid, IconButton, MenuItem, Popover, Typography } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { CodedConceptDto, CodedRef } from 'services/openapi'
import { getFormattedOptions } from '../utils'

export type StateValueType = {
  stateValue: CodedConceptDto
  reasonCodeRequired?: boolean
  reasonCodeTerminology?: CodedConceptDto[]
  commentRequired?: boolean
}

export type StateWithInstructionsType = {
  state?: CodedRef
  instructionalText?: string
}

type StateSelectorProps = {
  ariaLabel?: string
  entityId: string
  id?: string
  onGetNextStates: (entityId: string) => Promise<StateWithInstructionsType[]>
  onSelect: (entityId: string, newState: CodedRef, reason?: CodedRef, comment?: string) => void
  popToLeft?: boolean
  stateValues: StateValueType[]
  warningText?: string
}

const StateSelector = ({
  ariaLabel = 'more',
  entityId,
  id = 'menu-button',
  onGetNextStates,
  onSelect,
  popToLeft = false,
  stateValues,
  warningText = undefined,
}: StateSelectorProps) => {
  const { handleApiError } = useErrorHandler()
  const { t } = useTranslation('common')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [comment, setComment] = useState<string>('')
  const [commentRequiredError, setCommentRequiredError] = useState(false)
  const [needToFetchStates, setNeedToFetchStates] = useState(true)
  const [nextStates, setNextStates] = useState<StateWithInstructionsType[]>([])
  const [reason, setReason] = useState<CodedRef | undefined>(undefined)
  const [reasonRequiredError, setReasonRequiredError] = useState(false)
  const [state, setState] = useState<StateValueType | undefined>(undefined)
  const [instructionalText, setInstructionalText] = useState<string>('')

  const getStateName = useCallback(
    (state) => stateValues?.find((value) => value.stateValue.code === state?.code)?.stateValue.name,
    [stateValues]
  )

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const hasErrors = useCallback((): boolean => {
    let hasError = false
    if (state && state.reasonCodeRequired && reason === undefined) {
      setReasonRequiredError(true)
      hasError = true
    }
    if (state && state.commentRequired && comment === '') {
      setCommentRequiredError(true)
      hasError = true
    }
    return hasError
  }, [comment, reason, state])

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const handleSelectWithComment = useCallback(
    (newState: CodedRef) => {
      if (!hasErrors()) {
        setAnchorEl(null)
        onSelect(entityId, newState, reason, comment)
      }
    },
    [hasErrors, onSelect, entityId, reason, comment]
  )

  useEffect(() => {
    const getStates = async () => {
      try {
        const nextAvailableStates = await onGetNextStates(entityId)
        setNextStates(nextAvailableStates)
        setNeedToFetchStates(false)
      } catch (error) {
        handleApiError(error)
        setAnchorEl(null)
      }
    }
    if (anchorEl) getStates()
    else {
      setComment('')
      setCommentRequiredError(false)
      setNeedToFetchStates(true)
      setNextStates([])
      setReason(undefined)
      setReasonRequiredError(false)
      setState(undefined)
    }
  }, [anchorEl, entityId, handleApiError, onGetNextStates])

  return (
    <>
      <IconButton
        aria-controls={anchorEl ? id : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup="true"
        aria-label={ariaLabel}
        id={id}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        id={id}
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        sx={{ display: `${needToFetchStates ? 'none' : 'block'}` }}
        transformOrigin={{
          horizontal: popToLeft ? 'right' : 'left',
          vertical: 'top',
        }}
      >
        {nextStates.length === 0 && !needToFetchStates ? (
          <Typography sx={{ lineHeight: 3, mx: GLOBAL.MARGIN_SM }}>
            {t('common.state-selector.no-states-available')}
          </Typography>
        ) : (
          <>
            <Box sx={{ display: state ? 'none' : 'block' }}>
              {nextStates.map(({ instructionalText, state }) => (
                <MenuItem
                  divider
                  key={state?.codeSystemOid}
                  onClick={() => {
                    setState(stateValues.find((value) => value.stateValue.code === state?.code))
                    if (instructionalText === undefined && warningText) {
                      setInstructionalText(warningText) // Remove once instructional text is introduced in all the DTOs using states
                    } else {
                      setInstructionalText(instructionalText || '')
                    }
                  }}
                  sx={{ height: 54, minWidth: 300 }}
                >
                  {getStateName(state)}
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                display: state ? 'block' : 'none',
                pb: GLOBAL.PADDING_MD,
                width: 300,
              }}
            >
              <Box sx={{ display: 'inline-flex' }}>
                <IconButton
                  aria-label="back"
                  id="back-button"
                  onClick={() => setState(undefined)}
                  sx={{ width: 48 }}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography sx={{ lineHeight: 3 }}>{getStateName(state)}</Typography>
              </Box>
              <Divider sx={{ mt: GLOBAL.MARGIN_XXS }} />
              <Box sx={{ px: GLOBAL.PADDING_SM }}>
                <Grid container spacing={2}>
                  {state?.reasonCodeRequired && state?.reasonCodeTerminology && (
                    <Grid item xs={12}>
                      <MISSelectDropdown
                        error={reasonRequiredError}
                        helperText={
                          reasonRequiredError
                            ? t('common.state-selector.reason-code-required')
                            : undefined
                        }
                        label={t('common.state-selector.reason-code')}
                        onChange={(event) => setReason(event.target.value as CodedRef)}
                        options={getFormattedOptions(state?.reasonCodeTerminology)}
                        required
                        value={
                          reason
                            ? state.reasonCodeTerminology.find((o) => o.code === reason.code)
                            : undefined
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <MISTextField
                      error={commentRequiredError}
                      helperText={
                        commentRequiredError ? t('common.state-selector.comment-required') : ''
                      }
                      inputProps={{ maxLength: 255 }}
                      label={t('common.state-selector.comment')}
                      maxRows={3}
                      minRows={3}
                      multiline
                      onChange={(event) => setComment(event.target.value)}
                      required={state?.commentRequired}
                      value={comment}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontSize={GLOBAL.FONT_BODY_REGULAR} fontWeight={600}>
                      {instructionalText}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <MISButton color="secondary" onClick={() => setState(undefined)}>
                      {t('common.button.cancel')}
                    </MISButton>
                  </Grid>
                  <Grid item xs={5}>
                    <MISButton
                      color="primary"
                      onClick={() => handleSelectWithComment(state?.stateValue as CodedRef)}
                    >
                      {t('common.button.confirm')}
                    </MISButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Popover>
    </>
  )
}

export default StateSelector
