import { Box, Typography } from '@mui/material'

type StatusCardItemProps = {
  label: string
  subLabel?: string
}

const StatusCardItem = ({ label, subLabel }: StatusCardItemProps) => {
  return (
    <Box sx={{ pb: 1 }}>
      <Typography sx={{ color: '#555', fontSize: '12px', fontWeight: 700, lineHeight: '16px' }}>
        {label}
      </Typography>
      {subLabel && (
        <Typography sx={{ color: '#999', fontSize: '10px', fontWeight: 700, lineHeight: '12px' }}>
          {subLabel}
        </Typography>
      )}
    </Box>
  )
}

export default StatusCardItem
