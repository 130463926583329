import { useCallback, useRef, useState } from 'react'
import { Alert, Box, Grid, Stack } from '@mui/material'
import { useRecoilState } from 'recoil'
import { SurveyCreator } from 'survey-creator-react'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import CardTemplate from 'common/templates/CardTemplate'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { isDirtyState } from 'recoil/isDirty'
import {
  FormSchemaControllerService,
  FormSchemaDTO,
  PageFormSchemaLightDTO,
} from 'services/openapi'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import Designer from './Designer'

interface BuilderComponentHandles {
  getBuilder: () => string
  clearForm: () => void
}

// Example form, in JSON format
const emptyForm = `
  {
    "version": "1",
    "tooltipType": "RsTooltip",
    "errorType": "RsErrorMessage",
    "form": {
      "key": "Screen",
      "type": "Screen",
      "props": {},
      "children": [

      ]
    },
    "localization": {},
    "languages": [
    ],
    "defaultLanguage": "en-US"
  }
  `

const Forms = () => {
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const surveyRef = useRef<SurveyCreator>(
    new SurveyCreator({
      isAutoSave: true,
      showLogicTab: true,
    })
  )
  const designerRef = useRef<BuilderComponentHandles>()

  const [formName, setFormName] = useState('')
  const [formVersion, setFormVersion] = useState('')
  const [formDescription, setFormDescription] = useState('')
  const [formTags, setFormTags] = useState('')
  const [searchError, setSearchError] = useState<string | null>(null)
  const [formSchemaString, setFormSchemaString] = useState(emptyForm)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const formStorage = {
  //   getForm: (formValue: string): Promise<string> => {
  //     // Implementation remains the same
  //     return new Promise((resolve, reject) => {
  //       if (formValue) {
  //         resolve(formValue);
  //       } else {
  //         reject(new Error(`Form ${formName} not found`));
  //       }
  //     });
  //   },
  //   getFormNames: (): Promise<string[]> => {
  //     // Implementation remains the same
  //     return new Promise((resolve) => {
  //         resolve([]);
  //     });
  //   },
  //   removeForm: (formName: string) => {
  //     // Implementation remains the same
  //     return new Promise((resolve, reject) => {
  //       const formValue = localStorage.getItem(formName);
  //       if (formValue) {
  //         resolve(formValue);
  //       } else {
  //         reject(new Error(`Form ${formName} not found`));
  //       }
  //     });
  //   },
  //   saveForm: (formName: string, formValue: string) => {
  //     return new Promise((resolve, reject) => {
  //       const formValueJSON = JSON.stringify(formValue);

  //       try {
  //         console.log(`Saving form: ${formName} with value: ${formValueJSON}`);

  //         handleSaveForm(formName)
  //         resolve(formValue);
  //         // Resolve the promise upon successful save
  //       } catch (error) {
  //         console.error(`Failed to save form ${formName}`, error);
  //         reject(new Error(`Failed to save form ${formName}`));
  //       }
  //     });
  //   },
  // };

  const handleBlur = useCallback(
    (value, setValue) => {
      if (!isDirty) setIsDirty(true)

      setValue(value)
    },
    [isDirty, setIsDirty]
  )

  const mapFormResponseToState = useCallback((formResp: FormSchemaDTO) => {
    setFormName(formResp.name || '')
    setFormVersion(formResp.version || '')
    setFormDescription(formResp.description || '')
    setFormTags(formResp.tags || '')
    setFormSchemaString(formResp.schema || emptyForm)
  }, [])

  const handleNewForm = useCallback(() => {
    mapFormResponseToState({
      description: '',
      name: '',
      schema: undefined,
      tags: '',
      version: '',
    })
    designerRef.current?.clearForm()
  }, [mapFormResponseToState])

  const handleRetrieveForm = useCallback(() => {
    const searchForm = async () => {
      await FormSchemaControllerService.searchFormSchema(
        formName,
        formVersion || undefined
        // formTags || undefined
      )
        .then((response: PageFormSchemaLightDTO) => {
          if (response.content?.length === 1) {
            try {
              mapFormResponseToState(response.content[0])
            } catch (err) {
              setSearchError('Not a valid schema')
              return
            }
          } else if ((response.content?.length as number) > 1) setSearchError('Refine search')
          else setSearchError('No schema found')
        })
        .catch((error) => handleApiError(error))
    }
    if (formName) {
      setSearchError(null)
      surveyRef.current.text = ''
      searchForm()
    } else setSearchError('No criteria provided to retrieve form')
  }, [formName, formVersion, handleApiError, mapFormResponseToState])

  const handleSaveForm = useCallback(() => {
    setIsDirty(false)

    const updateForm = async () => {
      if (designerRef.current) {
        await FormSchemaControllerService.putFormSchema({
          description: formDescription,
          name: formName,
          schema: designerRef.current.getBuilder(),
          tags: formTags.length > 0 ? formTags : undefined,
          version: formVersion.length > 0 ? formVersion : '0.1',
        })
          .then((response: FormSchemaDTO) => mapFormResponseToState(response))
          .catch((error) => handleApiError(error))
      }
    }
    setSearchError(null)
    updateForm()
  }, [
    formDescription,
    formName,
    formTags,
    formVersion,
    handleApiError,
    mapFormResponseToState,
    setIsDirty,
  ])

  return (
    <Box sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', mt: 2 }}>
          <MISButton onClick={handleNewForm}>New Template</MISButton>
          <MISButton onClick={handleSaveForm}>Save Template</MISButton>
          <MISButton onClick={handleRetrieveForm}>Retrieve Template</MISButton>
        </Stack>
      </Grid>
      <CardTemplate
        bodyStyle={{
          '& .MuiTextField-root': { marginRight: 2, marginTop: 2 },
        }}
        headStyle={{
          background: '#FFFFFF',
          borderRadius: '6px',
          padding: '15px',
        }}
        title="Forms"
      >
        <Grid container>
          <Grid item xs={4}>
            <MISTextField
              id="name"
              label="Name"
              onBlur={(e) => handleBlur(e.target.value, setFormName)}
              value={formName}
            />
          </Grid>
          <Grid item xs={2}>
            <MISTextField
              id="version"
              label="Version"
              onBlur={(e) => handleBlur(e.target.value, setFormVersion)}
              value={formVersion}
            />
          </Grid>
          <Grid item xs={6}>
            <MISTextField
              id="description"
              label="Description"
              onBlur={(e) => handleBlur(e.target.value, setFormDescription)}
              value={formDescription}
            />
          </Grid>
          <Grid item xs={12}>
            <MISTextField
              id="tags"
              label="Tags"
              onBlur={(e) => handleBlur(e.target.value, setFormTags)}
              value={formTags}
            />
          </Grid>
          {searchError && (
            <Grid item xs={12}>
              <Alert onClose={() => setSearchError(null)} severity="error">
                {searchError}
              </Alert>
            </Grid>
          )}
        </Grid>
      </CardTemplate>
      <div data-testid="survey-form">
        {/* <SurveyCreatorComponent creator={surveyRef.current} /> */}
        <Designer apiData={formSchemaString} formName={formName} ref={designerRef} />
      </div>
    </Box>
  )
}

export default Forms
