export const EMPTY_FUNCTION = () => {
  return
}
export const EMPTY_OBJECT = {}
export const EMPTY_STRING = ''

export const MODALS = {
  DELETE_CHARTING_SERVICES: 'DELETE_CHARTING_SERVICES',
  DELETE_WARNING: 'DELETE_WARNING',
  LOGOUT_WARNING: 'LOGOUT_WARNING',
  PREFERRED_EXPIRED_WARNING: 'PREFERRED_EXPIRED_WARNING',
  PREFERRED_WARNING: 'PREFERRED_WARNING',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  UPDATE_WARNING: 'UPDATE_WARNING',
}
