import { useTranslation } from 'react-i18next'
import { Box, Tooltip, Typography } from '@mui/material'
import Loader from 'common/components/Loader'
import { FormSchemaDTO } from 'services/openapi'
import TemplatesToolbarHeader from './TemplatesToolbarHeader'
import TemplatesToolbarItem from './TemplatesToolbarItem'
import { TEMPLATE_TYPE } from '../../template-management/TemplateEditor'

type TemplatesToolbarHeaderProps = {
  errors: string[]
  header: string
  onSelect: (template: FormSchemaDTO) => void
  templates?: FormSchemaDTO[]
  templateType?: string
}

const TemplatesToolbarUserTemplates = ({
  errors,
  header,
  onSelect,
  templates,
  templateType,
}: TemplatesToolbarHeaderProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <TemplatesToolbarHeader header={header} />
      {!!(errors.length > 0) && (
        <>
          {errors.map((each, index) => (
            <Typography
              component="p"
              key={index}
              sx={{ color: '#f00', fontSize: '10px', fontWeight: 700, pt: 1 }}
            >
              {each}
            </Typography>
          ))}
        </>
      )}
      {templates && !!(errors.length === 0) && !!(templates.length === 0) && (
        <Typography
          component="span"
          sx={{ color: '#333', fontSize: '10px', fontWeight: 700, pt: 1 }}
        >
          {t('charting.templates.no-templates-available')}
        </Typography>
      )}
      {templates ? (
        templates.map((each) => (
          <Tooltip
            arrow
            disableFocusListener
            disableTouchListener
            key={each.id as string}
            placement="top"
            title={
              <p>
                {`${t('charting.templates.template-properties.name')}${each.name}`}
                <br />
                {`${t('charting.templates.template-properties.version')}${each.version}\n`}
                <br />
                {each.description && (
                  <span>{`${t('charting.templates.template-properties.description')}${
                    each.description
                  }`}</span>
                )}
              </p>
            }
          >
            <Box>
              <TemplatesToolbarItem
                disabled={templateType === TEMPLATE_TYPE.USER_DEFINED_BASE_TEMPLATE}
                itemContent={each.schema as string}
                itemKey={each.id as string}
                label={each.name as string}
                onSelect={() => onSelect(each)}
              />
            </Box>
          </Tooltip>
        ))
      ) : (
        <Box sx={{ p: 0.5 }}>
          <Loader />
        </Box>
      )}
    </>
  )
}

export default TemplatesToolbarUserTemplates
