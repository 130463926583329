import { useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MISButton from 'common/components/MISButton'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { AcceptableUseAgreementControllerService } from 'services/openapi'

function AcceptableUseAcknowledgement({ handleAcceptableUseAcknowledgement }) {
  const [content, setContent] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [acknowledgementId, setAcknowledgementId] = useState('')
  const open = true

  const { handleApiError } = useErrorHandler()

  // Get the content of the acceptable use acknowledgement for the current customer
  const getContent = useCallback(() => {
    setInProgress(true)
    AcceptableUseAgreementControllerService.getUnConfirmedAcceptableUseAgreement()
      .then((result) => {
        // A null result indicates that the acceptable use acknowledgment has already
        // been accepted by the user and therefore it does not need to be shown
        if (!result) {
          handleAcceptableUseAcknowledgement(null, null)
          setContent('No Agreement Found, please try refreshing the page')
        } else {
          setContent(() => result.text)
          setAcknowledgementId(result.id)
        }
      })
      .catch((error) => {
        handleApiError(error)
      })
      .finally(() => {
        setContent((prevState) =>
          prevState ? prevState : 'No Agreement Found, please try freshing the page'
        )
        setInProgress((prevState) => !prevState)
      })
  }, [handleAcceptableUseAcknowledgement, handleApiError])

  useEffect(() => {
    if (!inProgress && !content) {
      getContent()
    }
  }, [inProgress, content, getContent])

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">Acceptable Use Acknowledgement</DialogTitle>
        <DialogContent>
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: content }}
            id="alert-dialog-description"
          />
        </DialogContent>
        <DialogActions>
          <MISButton onClick={() => handleAcceptableUseAcknowledgement(acknowledgementId, false)}>
            Decline
          </MISButton>
          <MISButton
            autoFocus
            onClick={() => handleAcceptableUseAcknowledgement(acknowledgementId, true)}
          >
            Accept
          </MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AcceptableUseAcknowledgement
