import { atom, DefaultValue, selector } from 'recoil'
import {
  PersonnelAddressDTO,
  PersonnelCredentialDTO,
  PersonnelDTO,
  PersonnelJobFunctionDTO,
  PersonnelLanguageDTO,
  PersonnelNameDTO,
  PersonnelTrainingDTO,
} from 'services/openapi'

export const personnelDetailsState = atom<PersonnelDTO | undefined>({
  default: undefined,
  key: 'personnelDetailsState',
})

export const personnelIdSelector = selector<string | undefined>({
  get: ({ get }) => get(personnelDetailsState)?.id,
  key: 'personnelIdSelector',
})

export const personnelAddressesSelector = selector<PersonnelAddressDTO[] | undefined>({
  get: ({ get }) => get(personnelDetailsState)?.addresses,
  key: 'personnelAddressesSelector',
  set: ({ get, set }, newValue) => {
    set(personnelDetailsState, {
      ...get(personnelDetailsState),
      addresses: newValue instanceof DefaultValue ? undefined : newValue,
    })
  },
})

export const personnelJobFunctionsSelector = selector<PersonnelJobFunctionDTO[] | undefined>({
  get: ({ get }) => get(personnelDetailsState)?.jobFunctions,
  key: 'personnelJobFunctionsSelector',
  set: ({ get, set }, newValue) => {
    set(personnelDetailsState, {
      ...get(personnelDetailsState),
      jobFunctions: newValue instanceof DefaultValue ? undefined : newValue,
    })
  },
})

export const personnelLanguagesSelector = selector<PersonnelLanguageDTO[] | undefined>({
  get: ({ get }) => get(personnelDetailsState)?.languages,
  key: 'personnelLanguagesSelector',
  set: ({ get, set }, newValue) => {
    set(personnelDetailsState, {
      ...get(personnelDetailsState),
      languages: newValue instanceof DefaultValue ? undefined : newValue,
    })
  },
})

export const personnelNamesSelector = selector<PersonnelNameDTO[] | undefined>({
  get: ({ get }) => get(personnelDetailsState)?.names,
  key: 'personnelNamesSelector',
  set: ({ set }, newValue) =>
    set(
      personnelDetailsState,
      (currentState) =>
        ({
          ...currentState,
          names: newValue,
        } as PersonnelDTO)
    ),
})

export const expertiseAtom = atom<string[] | undefined>({
  default: [],
  key: 'expertiseAtom',
})

export const personnelCredentialsState = atom<{
  id: string | undefined
  credentials: PersonnelCredentialDTO[]
}>({
  default: {
    credentials: [],
    id: undefined,
  },
  key: 'personnelCredentialsState',
})

export const personnelTrainingState = atom<{
  id: string | undefined
  training: PersonnelTrainingDTO[]
}>({
  default: {
    id: undefined,
    training: [],
  },
  key: 'personnelTrainingState',
})
