import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import {
  personnelCredentialsState,
  personnelIdSelector,
  personnelTrainingState,
} from 'recoil/personnelDetails'
import {
  PersonnelCredentialControllerService,
  PersonnelTrainingControllerService,
} from 'services/openapi'
import Credentials from './Credentials/Credentials'
import Training from './Training/Training'

type CredentialsTrainingRefType = {
  add: () => void
  save: () => void
}

const CredentialsTraining = () => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const personnelId = useRecoilValue(personnelIdSelector)
  const [personnelCredentials, setPersonnelCredentials] = useRecoilState(personnelCredentialsState)
  const [personnelTraining, setPersonnelTraining] = useRecoilState(personnelTrainingState)

  const credentialsRef = useRef<CredentialsTrainingRefType>()
  const trainingRef = useRef<CredentialsTrainingRefType>()

  useEffect(() => {
    const getCredentialsTraining = async (id: string) => {
      try {
        let resp = await PersonnelCredentialControllerService.getPersonnelCredentials(id, 0, 100)
        setPersonnelCredentials({ credentials: resp.content || [], id })
        resp = await PersonnelTrainingControllerService.getPersonnelTrainings(id, 0, 100)
        setPersonnelTraining({ id, training: resp.content || [] })
      } catch (err) {
        handleApiError(err)
      }
    }
    if (personnelId && personnelId !== personnelCredentials.id) getCredentialsTraining(personnelId)
  }, [
    handleApiError,
    personnelCredentials.id,
    personnelId,
    setPersonnelCredentials,
    setPersonnelTraining,
  ])

  return (
    <>
      <SectionHeader
        addLabel={
          [
            t('provider-staff.credentials-training.header.add-credential'),
            t('provider-staff.credentials-training.header.add-training'),
          ] as string[]
        }
        onAdds={[() => credentialsRef.current?.add(), () => trainingRef.current?.add()]}
        onSave={() => {
          credentialsRef.current?.save()
          trainingRef.current?.save()
        }}
        saveLabel={t('common.button.save')}
        title={t('provider-staff.credentials-training.header.title')}
      />
      <Box className="provider-staff-section-container">
        {personnelCredentials.id && personnelId === personnelCredentials.id && (
          <Credentials ref={credentialsRef} />
        )}
        {personnelTraining.id && personnelId === personnelTraining.id && (
          <Training ref={trainingRef} />
        )}
      </Box>
    </>
  )
}

export default CredentialsTraining
