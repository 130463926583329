import { atom } from 'recoil'
import {
  AlertDTO,
  BusinessLineTerse,
  EncounterServiceTemplateDTO,
  EncounterServiceTemplateGroupDTO,
  GovernanceAgencyWithDomainsDTO,
  IdheServiceDTO,
  ListEsdrtCategoriesDTO,
  ListFunctionalAreasDTO,
  Preference,
  ProgramTerse,
  PurposeGroupTerse,
  PurposeTerse,
  TypeGroupTerse,
  TypeTerse,
} from 'services/openapi'
import { DomainType } from '../core/Types'

export const purposesAtom = atom<PurposeTerse[]>({
  default: [],
  key: 'purposesAtom',
})

export const purposesGroupAtom = atom<PurposeGroupTerse[]>({
  default: [],
  key: 'purposesGroupAtom',
})

export const typeGroupAtom = atom<TypeGroupTerse[]>({
  default: [],
  key: 'typeGroupAtom',
})

export const programGroupAtom = atom<BusinessLineTerse[]>({
  default: [],
  key: 'programGroupAtom',
})

export const typesAtom = atom<TypeTerse[]>({
  default: [],
  key: 'typesAtom',
})

export const programsAtom = atom<ProgramTerse[]>({
  default: [],
  key: 'programsAtom',
})

export const domainsAtom = atom<DomainType[]>({
  default: [],
  key: 'domainsAtom',
})

export const functionalAreasAtom = atom<ListFunctionalAreasDTO[]>({
  default: [],
  key: 'functionalAreasAtom',
})

export const templateGroupAtom = atom<EncounterServiceTemplateGroupDTO[]>({
  default: [],
  key: 'templateGroupAtom',
})

export const templatesAtom = atom<EncounterServiceTemplateDTO[]>({
  default: [],
  key: 'templatesAtom',
})

export const esdrtCategoriesAtom = atom<ListEsdrtCategoriesDTO[]>({
  default: [],
  key: 'esdrtCategoriesAtom',
})

export const userPreferencesAtom = atom<Map<string, Preference>>({
  default: new Map<string, Preference>(),
  key: 'userPreferencesAtom',
})

export const govAgenciesWithDomainsAtom = atom<GovernanceAgencyWithDomainsDTO[]>({
  default: [],
  key: 'govAgenciesWithDomainsAtom',
})

export const tagsAtom = atom<string[] | undefined>({
  default: [],
  key: 'tagsAtom',
})

export const alertsAtom = atom<AlertDTO[]>({
  default: [],
  key: 'alertsAtom',
})

export const idheServicesAtom = atom<IdheServiceDTO[]>({
  default: [],
  key: 'idheServicesAtom',
})
