import GLOBAL from 'common/styles/global.scss'

type MISTabTitleProps = {
  title: string
  count?: number
}

const MISTabTitle = ({ count, title }: MISTabTitleProps) => {
  return (
    <span>
      {title}
      {count && count > 0 && (
        <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{` (${count})`}</span>
      )}
    </span>
  )
}

export default MISTabTitle
