/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditInfo } from './AuditInfo'

export type Preference = {
  id?: string
  auditInfo?: AuditInfo
  name?: string
  value?: string
  type?: Preference.type
}

export namespace Preference {
  export enum type {
    USER = 'USER',
    TENANT = 'TENANT',
    USER_DEFAULT = 'USER_DEFAULT',
    TENANT_DEFAULT = 'TENANT_DEFAULT',
  }
}
