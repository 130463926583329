import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Stack } from '@mui/material'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import useProviders from 'modules/shared/hooks/useProviders'
import { ChartingEntryControllerService } from 'services/openapi'
import { selectUserId } from 'store/selectors/user'
import UserToDoEntry from './UserToDoEntry'
import UserToDoFilters from './UserToDoFilters'
import { isDateBeforeOrEqualDate } from '../../../common/utils/DateUtils'

export enum EFilterBy {
  ASSIGNED_TO = 'Assigned to me',
  CREATED_BY = 'Created by me',
}

type TToDoEntry = {
  clientId?: string
  template: TTemplateBlot
}

const UserToDo = () => {
  const providers = useProviders()
  const userId = useSelector(selectUserId)

  const [filterBy, setFilterBy] = useState(EFilterBy.ASSIGNED_TO)
  const [followUp, setFollowUp] = useState<TToDoEntry>()
  const [followUps, setFollowUps] = useState<TToDoEntry[]>([])
  const [followUpTemplates, setFollowUpTemplates] = useState<TTemplateBlot[]>([])

  const provider = useMemo(
    () => providers?.find((provider) => provider.userId === userId),
    [providers, userId]
  )

  const handleSelectEntry = useCallback(
    (id: string) => setFollowUp(followUps?.find((each) => each.template.templateRecordId === id)),
    [followUps]
  )

  useEffect(() => {
    const getFollowUps = async (providerId: string) => {
      const followUpsResp = await ChartingEntryControllerService.getFilteredChartingEntries1(
        'FollowUpTemplate',
        undefined,
        filterBy === EFilterBy.ASSIGNED_TO
          ? [`assignedTo.id|eq|${providerId}`]
          : [`createdBy|eq|${provider?.id}`],
        true
      )
      if (followUpsResp?.length) {
        const followUps: TToDoEntry[] = []
        followUpsResp.forEach((each) => {
          const clientId = each.clientId?.length > 0 ? each.clientId[0] : undefined
          each.chartingTemplateBlot.forEach((template) => {
            followUps.push({ clientId, template })
          })
        })
        const followUpsWithDueDate = followUps.filter((each) => each.template.templateData.dueDate)
        followUpsWithDueDate.sort((a, b) =>
          isDateBeforeOrEqualDate(
            a.template.templateData.dueDate as string,
            b.template.templateData.dueDate as string
          )
            ? -1
            : 1
        )
        const followUpsWithoutDueDate = followUps.filter(
          (each) => !each.template.templateData.dueDate
        )
        const sortedFollowUps = [...followUpsWithDueDate, ...followUpsWithoutDueDate]
        setFollowUp(sortedFollowUps[0])
        setFollowUps(sortedFollowUps)
        setFollowUpTemplates(sortedFollowUps.map((each) => each.template))
      } else {
        setFollowUp(undefined)
        setFollowUps([])
        setFollowUpTemplates([])
      }
    }

    if (provider?.id) getFollowUps(provider.id)
  }, [filterBy, provider])

  return (
    <MISBaseContainer>
      <Stack direction="row" spacing={3}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <UserToDoFilters
            entries={followUpTemplates}
            entryId={followUp?.template.templateRecordId || ''}
            filterBy={filterBy}
            onFilterByChange={(_, value) => setFilterBy(value)}
            onSelect={handleSelectEntry}
          />
        </LocalizationProvider>
        {followUp && <UserToDoEntry clientId={followUp.clientId} entry={followUp.template} />}
      </Stack>
    </MISBaseContainer>
  )
}

export default UserToDo
