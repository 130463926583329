/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccessPermissions = {
  primary?: AccessPermissions.primary
  secondary?: AccessPermissions.secondary
}

export namespace AccessPermissions {
  export enum primary {
    READ = 'READ',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
  }

  export enum secondary {
    READ = 'READ',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
  }
}
