/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImmunizationToAvoidDTO } from '../models/ImmunizationToAvoidDTO'
import type { ImmunizationToAvoidStateDTO } from '../models/ImmunizationToAvoidStateDTO'
import type { PageImmunizationToAvoidDTO } from '../models/PageImmunizationToAvoidDTO'
import type { UnPagedImmunizationToAvoidStateDTO } from '../models/UnPagedImmunizationToAvoidStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ImmunizationToAvoidControllerService {
  /**
   * @param clientId
   * @param immunizationToAvoidId
   * @returns ImmunizationToAvoidDTO OK
   * @throws ApiError
   */
  public static getImmunizationToAvoidById(
    clientId: string,
    immunizationToAvoidId: string
  ): CancelablePromise<ImmunizationToAvoidDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid/{immunizationToAvoidId}',
      path: {
        clientId: clientId,
        immunizationToAvoidId: immunizationToAvoidId,
      },
    })
  }

  /**
   * @param clientId
   * @param immunizationToAvoidId
   * @param requestBody
   * @returns ImmunizationToAvoidDTO OK
   * @throws ApiError
   */
  public static putImmunizationToAvoid(
    clientId: string,
    immunizationToAvoidId: string,
    requestBody: ImmunizationToAvoidDTO
  ): CancelablePromise<ImmunizationToAvoidDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid/{immunizationToAvoidId}',
      path: {
        clientId: clientId,
        immunizationToAvoidId: immunizationToAvoidId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Search Immunization to Avoid by agentCode
   * @param clientId
   * @param agentCode
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageImmunizationToAvoidDTO OK
   * @throws ApiError
   */
  public static searchAllImmunizationToAvoid(
    clientId: string,
    agentCode?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageImmunizationToAvoidDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid',
      path: {
        clientId: clientId,
      },
      query: {
        agentCode: agentCode,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ImmunizationToAvoidDTO OK
   * @throws ApiError
   */
  public static postImmunizationToAvoid(
    clientId: string,
    requestBody: ImmunizationToAvoidDTO
  ): CancelablePromise<ImmunizationToAvoidDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get history of state changes for immunization to avoid
   * @param clientId
   * @param immunizationToAvoidId
   * @returns UnPagedImmunizationToAvoidStateDTO OK
   * @throws ApiError
   */
  public static getStatesByImmunizationToAvoidId(
    clientId: string,
    immunizationToAvoidId: string
  ): CancelablePromise<UnPagedImmunizationToAvoidStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid/{immunizationToAvoidId}/states',
      path: {
        clientId: clientId,
        immunizationToAvoidId: immunizationToAvoidId,
      },
    })
  }

  /**
   * Create a new State for immunizationToAvoidId
   * @param clientId
   * @param immunizationToAvoidId
   * @param requestBody
   * @returns ImmunizationToAvoidStateDTO OK
   * @throws ApiError
   */
  public static createImmunizationToAvoidState(
    clientId: string,
    immunizationToAvoidId: string,
    requestBody: ImmunizationToAvoidStateDTO
  ): CancelablePromise<ImmunizationToAvoidStateDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid/{immunizationToAvoidId}/states',
      path: {
        clientId: clientId,
        immunizationToAvoidId: immunizationToAvoidId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get list of possible next states for immunization to avoid
   * @param clientId
   * @param immunizationToAvoidId
   * @returns UnPagedImmunizationToAvoidStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByImmunizationToAvoidId(
    clientId: string,
    immunizationToAvoidId: string
  ): CancelablePromise<UnPagedImmunizationToAvoidStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/client/{clientId}/immunizations/immunizations-to-avoid/{immunizationToAvoidId}/next-states',
      path: {
        clientId: clientId,
        immunizationToAvoidId: immunizationToAvoidId,
      },
    })
  }
}
