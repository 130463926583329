import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { ClientAlertControllerService } from 'services/openapi'
import { setClientAlerts } from 'store/reducers/client'

const ClientStartup = ({ clientId }: { clientId: string }) => {
  const dispatch = useDispatch()
  const { handleApiError } = useErrorHandler()

  useEffect(() => {
    const getClientAlerts = async (clientId: string) => {
      try {
        const response = await ClientAlertControllerService.getClientAlerts(clientId)
        dispatch(setClientAlerts(response))
      } catch (err) {
        handleApiError(err)
      }
    }
    if (clientId) getClientAlerts(clientId)
  }, [clientId, dispatch, handleApiError])

  return null
}

export default ClientStartup
