import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSnack } from 'common/components/snackbar/useSnack'
import { dateNowIsoString } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import Names from 'modules/shared/Names'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { getValueSetType } from 'modules/shared/utils'
import { personnelDetailsState, personnelNamesSelector } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import { PersonnelNameControllerService, PersonnelNameDTO } from 'services/openapi'
import { PERSON_NAME_USE_VOCAB_NAME } from 'services/terminologyConstants'
import '../ProviderStaffDetails.scss'

const NamesSection = () => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackError, showSnackSuccess } = useSnack()
  const personnelData = useRecoilValue(personnelDetailsState)
  const [personnelNames, setPersonnelNames] = useRecoilState(personnelNamesSelector)
  const nameTypes = useRecoilValue(terminologySelector(PERSON_NAME_USE_VOCAB_NAME))
  const [shouldSave, setShouldSave] = useState(false)
  const [saveTriggered, setSaveTriggered] = useState(false)
  const [providerStaffNamesData, setProviderStaffNamesData] = useState<any>({
    errors: [],
    rows: [],
  })

  useEffect(() => {
    const handleCreateRows = (names: PersonnelNameDTO[]) => {
      const parsedRowsFromData: any[] = []
      names.forEach((d: any) => {
        parsedRowsFromData.push({
          data: {
            endDate: d.effective?.endDate ? d.effective?.endDate : '',
            firstName: d.firstName ? d.firstName : '',
            id: d.id,
            lastName: d.lastName ? d.lastName : '',
            middleName: d.middleName ? d.middleName : '',
            preferredName: d.preferredName ? d.preferredName : false,
            prefix: d.prefix ? d.prefix : '',
            startDate: d.effective?.startDate ? d.effective?.startDate : '',
            suffix: d.suffix ? d.suffix : '',
            type: d.type ? getValueSetType(nameTypes, d.type) : undefined,
          },
          errors: [],
          isCollapsed: true,
        })
      })
      parsedRowsFromData.sort(
        (a, b) =>
          Number(b.data.preferredName) - Number(a.data.preferredName) ||
          (a.data.firstName || '').localeCompare(b.data.firstName || '')
      )

      setProviderStaffNamesData({
        errors: [],
        rows: parsedRowsFromData,
      })
    }
    if (personnelNames && personnelNames.length > 0) {
      handleCreateRows(personnelNames)
    }
  }, [personnelNames, nameTypes])

  const onAddRow = () => {
    const rowsUpdate = [
      ...providerStaffNamesData.rows,
      {
        data: {
          endDate: '',
          firstName: '',
          lastName: '',
          middleName: '',
          preferredName: false,
          prefix: '',
          startDate: dateNowIsoString(),
          suffix: '',
          type: undefined,
        },
        errors: [],
        isCollapsed: false,
      },
    ]
    if (rowsUpdate) {
      setProviderStaffNamesData({ ...providerStaffNamesData, rows: rowsUpdate })
    }
  }

  useEffect(() => {
    const saveProviderStaffNames = () => {
      const updatedProviderStaffNames = providerStaffNamesData.rows.map((row: any) => {
        return {
          ...row.data,
          effective: {
            endDate: row.data.endDate,
            startDate: row.data.startDate,
          },
          type: { code: row.data.type?.code, codeSystemOid: row.data.type?.codeSystemOid },
        }
      })

      PersonnelNameControllerService.savePersonnelNames(
        personnelData?.id || '',
        updatedProviderStaffNames
      )
        .then((response) => {
          showSnackSuccess(t('api.save-success'))
          setPersonnelNames(response)
        })
        .catch((error) => {
          showSnackError(t('api.error.save-error'))
          handleApiError(error)
        })
    }

    if (shouldSave) {
      saveProviderStaffNames()
      setShouldSave(false)
    }
  }, [
    shouldSave,
    personnelData?.id,
    providerStaffNamesData,
    setPersonnelNames,
    showSnackSuccess,
    showSnackError,
    handleApiError,
    t,
  ])

  const deleteProviderStaffName = useCallback(
    (id: string) => {
      PersonnelNameControllerService.deletePersonnelName(personnelData?.id || '', id)
        .then(() => {
          showSnackSuccess(t('api.save-success'))
          setPersonnelNames(personnelNames?.filter((name) => name.id !== id))
        })
        .catch((error) => {
          showSnackError(t('api.error.save-error'))
          handleApiError(error)
        })
    },
    [
      handleApiError,
      personnelData?.id,
      personnelNames,
      setPersonnelNames,
      showSnackError,
      showSnackSuccess,
      t,
    ]
  )

  return (
    <>
      <SectionHeader
        addLabel={t('client-demographics.names.labels.add-name')}
        onAdd={onAddRow}
        onSave={() => setSaveTriggered(true)}
        saveLabel={t('common.button.save')}
        title={t('client-demographics.names.labels.title')}
      />
      <Box className="provider-staff-section-container">
        <Names
          deleteCallback={deleteProviderStaffName}
          lastNameRequired
          names={providerStaffNamesData}
          saveTriggered={saveTriggered}
          setNames={setProviderStaffNamesData}
          setSaveTriggered={setSaveTriggered}
          setShouldSave={setShouldSave}
        />
      </Box>
    </>
  )
}

export default NamesSection
