import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import Addresses from 'modules/shared/Addresses/Addresses'
import { personnelAddressesSelector, personnelIdSelector } from 'recoil/personnelDetails'
import { PersonnelAddressControllerService, PersonnelAddressDTO } from 'services/openapi'

const BusinessAddress = () => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()
  const personnelId = useRecoilValue(personnelIdSelector)
  const [addresses, setAddresses] = useRecoilState(personnelAddressesSelector)

  const handleUpdateAddresses = useCallback(
    (addresses) => {
      const updateAddresses = async (personnelId: string, addresses: PersonnelAddressDTO[]) => {
        try {
          const response = await PersonnelAddressControllerService.saveAddresses(
            personnelId,
            addresses
          )
          setAddresses(response)
          showSnackSuccess(t('api.save-success'))
        } catch (err) {
          handleApiError(err)
        }
      }
      if (personnelId) updateAddresses(personnelId, addresses)
    },
    [handleApiError, personnelId, setAddresses, showSnackSuccess, t]
  )

  const handleDeleteAddress = useCallback(
    (addressId: string) => {
      const deleteAddress = async (personnelId: string, addressId: string) => {
        try {
          if (addressId) {
            await PersonnelAddressControllerService.deleteAddress(personnelId, addressId)
            setAddresses(addresses?.filter((address) => address.id !== addressId))
            showSnackSuccess(t('api.save-success'))
          }
        } catch (err) {
          handleApiError(err)
        }
      }
      if (personnelId) deleteAddress(personnelId, addressId)
    },
    [addresses, handleApiError, personnelId, setAddresses, showSnackSuccess, t]
  )

  const handleSearchAddresses = useCallback(async (value) => {
    const addresses = await PersonnelAddressControllerService.searchAllAddresses(value)
    return addresses?.content ?? null
  }, [])

  return (
    <>
      {personnelId && addresses && (
        <Addresses
          addresses={addresses}
          handleAddressUpdate={handleUpdateAddresses}
          handleSearchAddresses={handleSearchAddresses}
          id={personnelId}
          isFromClient={false}
          isFromProvider
          onDeleteAddress={handleDeleteAddress}
          title={t('provider-staff.business-addresses.header.title')}
        />
      )}
    </>
  )
}

export default BusinessAddress
