import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import HistoryIcon from '@mui/icons-material/History'
import { Box, Popover, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISChip from 'common/components/form/MISChip'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { getTimeFromDate, isoDateToDisplayFormatInUTC } from 'common/utils/DateUtils'
import { findValueByKeyFromJSONString } from 'common/utils/utils'
import Timeline from 'modules/shared/Timeline'
import { terminologySelector } from 'recoil/terminology'
import {
  ChartingEntryAuditDTO,
  ChartingEntryControllerService,
  ChartingEntryDTO,
  CodedRef,
} from 'services/openapi'
import { MIS_ENCOUNTER_SERVICE_STATE } from 'services/terminologyConstants'

interface ChartingRevisionProps {
  chartingData?: ChartingEntryDTO
  onClickChartVersion: (chartingAudit: ChartingEntryAuditDTO, isLatestVersion: boolean) => void
}

type TimelineProps = {
  date?: string
}

type ChangeLogItem = {
  templateRecordId: string
  templateName: string
  changeType: string
}

function Content(userName: string, state: string, changeLog: ChangeLogItem[]) {
  return (
    <Box width="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Box>
          {changeLog &&
            changeLog.length > 0 &&
            changeLog.map((cl) => {
              return (
                <Typography key={cl.templateRecordId} variant="body2">
                  <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                    {cl.changeType.toLowerCase()[0].toUpperCase() +
                      cl.changeType.toLowerCase().slice(1)}
                    :
                  </span>
                  {cl.templateName}
                </Typography>
              )
            })}
          {changeLog && changeLog.length === 0 && (
            <Typography style={{ fontWeight: 'bold' }} variant="body2">
              New Charting Entry
            </Typography>
          )}
          <Typography variant="body2">
            <span>By </span>
            {userName}
          </Typography>
        </Box>
        <Box>
          <MISChip label={state} sx={{ mb: 0.25, mr: 0.25 }} variant="filled" />
        </Box>
      </Stack>
    </Box>
  )
}

function TimelineTitle(props: TimelineProps) {
  if (props.date) {
    return (
      <Stack direction="column" spacing={2} style={{ marginBottom: '10px' }}>
        <Typography
          sx={{
            color: `${GLOBAL.LINK_PRIMARY_COLOR}`,
            fontWeight: 'bold',
            left: '-30px',
            position: 'absolute',
            top: '30px',
          }}
          variant="h3"
        >
          {isoDateToDisplayFormatInUTC(props.date)}
        </Typography>
        <Typography sx={{ left: '3px', position: 'absolute', top: '40px' }} variant="body1">
          {getTimeFromDate(props.date)}
        </Typography>
      </Stack>
    )
  } else {
    return null
  }
}

const ChartingRevisionsPopover = ({ chartingData, onClickChartVersion }: ChartingRevisionProps) => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const [items, setItems] = useState<any>([])
  const [latestVersion, setLatestVersion] = useState<number | undefined>(undefined)
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const handleCardClick = useCallback(
    (data: { index: number }) => {
      if (data.index >= 0) {
        let isLatestVersion = false
        const item = items[data.index]
        const auditDTO = item.auditData
        const chartingEntryDTO = {
          ...auditDTO,
          id: auditDTO.chartingEntryId,
        }
        if (auditDTO.version === latestVersion) {
          isLatestVersion = true
        }
        onClickChartVersion(chartingEntryDTO, isLatestVersion)
        setAnchorEl(null)
      }
    },
    [items, latestVersion, onClickChartVersion]
  )

  useEffect(() => {
    const getChartingEntryRevisions = async (clientId: string, chartingEntryId: string) => {
      const response = await ChartingEntryControllerService.getChartingAuditHistories(
        clientId,
        chartingEntryId
      )

      const latestVersion = response.reduce((a, b) =>
        (a.version || 0) > (b.version || 0) ? a : b
      ).version
      setLatestVersion(latestVersion)
      const revisionList = response.map((audit: ChartingEntryAuditDTO) => {
        const changeLog = findValueByKeyFromJSONString(
          JSON.stringify(audit.chartingEntryData),
          'changeLog'
        )
        const userName =
          audit.auditInfo?.created?.user?.firstName + ' ' + audit.auditInfo?.created?.user?.lastName
        const state = stateOptions?.find(
          (o: CodedRef) => o.code === (audit.status as CodedRef)?.code
        )?.name as string

        return {
          auditData: audit,
          content: Content(userName, state, changeLog),
          title: <TimelineTitle date={audit.auditInfo?.created?.date} />,
        }
      })
      revisionList.sort((a, b) => {
        return (
          new Date(b.auditData.revisionDateTime || '').getTime() -
          new Date(a.auditData.revisionDateTime || '').getTime()
        )
      })
      setItems(revisionList)
    }
    if (anchorEl && clientId && chartingData?.id) {
      setItems([])
      getChartingEntryRevisions(clientId, chartingData.id)
    }
  }, [anchorEl, chartingData, clientId, stateOptions, t])

  return (
    <>
      <MISButton
        color="primary"
        onClick={handleClick}
        size="large"
        startIcon={<HistoryIcon />}
        variant="text"
      >
        {t('charting.charting-revision.button-revision-history')}
      </MISButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        id="menu-button-popover"
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <Box sx={{ height: 400, px: GLOBAL.PADDING_SM, width: 600 }}>
          <Timeline
            cardHeight={100}
            hideControls
            items={items}
            mode="VERTICAL"
            onItemSelected={handleCardClick}
          />
        </Box>
      </Popover>
    </>
  )
}

export default ChartingRevisionsPopover
