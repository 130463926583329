import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import global from 'common/styles/global.scss'
import './MISTabbedContentContainer.scss'

export interface TabConfigType {
  id: string
  label: string
  component: ReactNode
  disabled: boolean
  visibile: boolean
  active: boolean
  dirty: boolean
}

type MISTabbedContentContainerProps = {
  tabConfig: TabConfigType[]
}

const MISTabbedContentContainer = ({ tabConfig }: MISTabbedContentContainerProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [visibleTabs, setVisibleTabs] = useState<TabConfigType[]>([])

  const handleTabClick = useCallback((newIndex: number) => setActiveTabIndex(newIndex), [])

  useEffect(() => {
    setVisibleTabs(tabConfig.filter((tab: TabConfigType) => tab.visibile))
  }, [tabConfig])

  return (
    <div className="tabbed-content-container">
      <Tabs
        orientation="vertical"
        sx={{ backgroundColor: global.INNER_LEFT_SIDEBAR_BACKGROUND }}
        value={0}
      >
        <aside className="tabs-container">
          {visibleTabs.map((tab: TabConfigType, index: number) => {
            return (
              <Tab
                id={`vertical-tab-${index}`}
                key={`vertical-tab-${index}`}
                label={tab.label}
                onClick={() => handleTabClick(index)}
                sx={{
                  alignItems: 'self-start',
                  backgroundColor: index === activeTabIndex ? global.WHITE : '',
                  paddingLeft: global.PADDING_MD,
                }}
              />
            )
          })}
        </aside>
      </Tabs>
      <div className="tabs-content">
        {visibleTabs.map((tab, index) => {
          if (activeTabIndex !== index) return null
          return (
            <Box
              key={index}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%', pb: 3, pt: 3 }}
            >
              {tab.component}
            </Box>
          )
        })}
      </div>
    </div>
  )
}

export default MISTabbedContentContainer
