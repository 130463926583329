import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { Paper, Stack, Typography } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import FBCheckbox from './form-builder-components/FBCheckbox'
import FBDatePicker from './form-builder-components/FBDatePicker'
import FBDateTimePicker from './form-builder-components/FBDateTimePicker'
import FBDropdown from './form-builder-components/FBDropdown'
import FBNumberFormat from './form-builder-components/FBNumberFormat'
import FBTextField from './form-builder-components/FBTextField'
import FBTimePicker from './form-builder-components/FBTimePicker'
import FBTypography from './form-builder-components/FBTypography'
import FBValueUnit from './form-builder-components/FBValueUnit'

export const FORM_BUILDER_COMPONENTS = {
  FBCheckbox: {
    component: FBCheckbox,
    key: 'charting.form-builder.checkbox.title',
    name: 'FBCheckbox',
  },
  FBDatePicker: {
    component: FBDatePicker,
    key: 'charting.form-builder.date-picker.title',
    name: 'FBDatePicker',
  },
  FBDateTimePicker: {
    component: FBDateTimePicker,
    key: 'charting.form-builder.date-time-picker.title',
    name: 'FBDateTimePicker',
  },
  FBDropdown: {
    component: FBDropdown,
    key: 'charting.form-builder.dropdown.title',
    name: 'FBDropdown',
  },
  FBNumberFormat: {
    component: FBNumberFormat,
    key: 'charting.form-builder.number-format.title',
    name: 'FBNumberFormat',
  },
  FBTextField: {
    component: FBTextField,
    key: 'charting.form-builder.text-field.title',
    name: 'FBTextField',
  },
  FBTimePicker: {
    component: FBTimePicker,
    key: 'charting.form-builder.time-picker.title',
    name: 'FBTimePicker',
  },
  FBTypography: {
    component: FBTypography,
    key: 'charting.form-builder.typography.title',
    name: 'FBTypography',
  },
  FBValueUnit: {
    component: FBValueUnit,
    key: 'charting.form-builder.value-unit.title',
    name: 'FBValueUnit',
  },
}

type FormBuilderToolbarProps = {
  onDragStart: (name: string) => void
}

const FormBuilderToolbar = ({ onDragStart }: FormBuilderToolbarProps) => {
  const { t } = useTranslation('common')

  const [searchStr, setSearchStr] = useState('')

  return (
    <Paper sx={{ pb: 2, pl: 2, pr: 2, pt: 2 }}>
      <MISTextField
        InputProps={{ startAdornment: <SearchIcon />, sx: { pl: 1 } }}
        onChange={(e) => setSearchStr(e.target.value)}
        size="small"
        sx={{ pb: 1 }}
        value={searchStr}
      />
      <Typography sx={{ color: '#333', fontSize: '12px', fontWeight: 700 }}>
        {t('charting.form-builder.components')}
      </Typography>
      {Object.values(FORM_BUILDER_COMPONENTS)
        .filter((each) => searchStr === '' || t(each.key).toLowerCase().includes(searchStr))
        .map((each) => (
          <Stack
            component="div"
            direction="row"
            draggable
            key={each.key}
            onDragStart={() => onDragStart(each.name)}
            spacing={1}
            sx={{ cursor: 'grab', p: 0 }}
          >
            <Typography sx={{ color: '#333', fontSize: '10px', lineHeight: '24px' }}>
              {t(each.key)}
            </Typography>
          </Stack>
        ))}
    </Paper>
  )
}

export default FormBuilderToolbar
