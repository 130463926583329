import { FormControlLabel, Radio, RadioProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

interface RadioCustomProps {
  label?: string
  name?: string
}

const MISRadio = (props: RadioProps & RadioCustomProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <FormControlLabel
        control={<Radio {...props} size={props.size || 'small'} />}
        label={props.label}
      />
    </ThemeProvider>
  )
}

export default MISRadio
