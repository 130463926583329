/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientName } from '../models/ClientName'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientNameControllerService {
  /**
   * @param clientId
   * @param activeFilter
   * @returns ClientName OK
   * @throws ApiError
   */
  public static listClientNames(
    clientId: string,
    activeFilter?: string
  ): CancelablePromise<Array<ClientName>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/names',
      path: {
        clientId: clientId,
      },
      query: {
        activeFilter: activeFilter,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientName OK
   * @throws ApiError
   */
  public static addNameToClient(
    clientId: string,
    requestBody: Array<ClientName>
  ): CancelablePromise<Array<ClientName>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/names',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @returns ClientName OK
   * @throws ApiError
   */
  public static getPreferredName(clientId: string): CancelablePromise<ClientName> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/preferredName',
      path: {
        clientId: clientId,
      },
    })
  }
}
