import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TuneIcon from '@mui/icons-material/Tune'
import { DatePicker } from '@mui/lab'
import { Box, Divider, ListItem, Stack, Typography } from '@mui/material'
import MISChip from 'common/components/form/MISChip'
import MISDrawer from 'common/components/form/MISDrawer'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import {
  IDHE_DATE_DISPLAY_FORMAT,
  isDate1BeforeDate2,
  isoDateToDisplayFormat,
} from 'common/utils/DateUtils'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { CodedConceptDto, PersonnelDTO, ProgramTerse } from 'services/openapi'
import { FOLLOW_UP_URGENCY } from 'services/terminologyConstants'
import { selectPrograms } from 'store/selectors/program'
import { selectTerminology } from 'store/selectors/terminology'

type ToDoFiltersProps = {
  entries: TTemplateBlot[]
  entryId: string
  onSelect: (id: string) => void
}

const ToDoFilters = ({ entries, entryId, onSelect }: ToDoFiltersProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const programsAssociated = useSelector(selectPrograms)
  const providers = useProviders()

  const [entriesState, setEntriesState] = useState(entries)
  const [entryIdState, setEntryIdState] = useState(entryId)
  const [filterAssignedTo, setFilterAssignedTo] = useState<PersonnelDTO>()
  const [filterDueDateAfter, setFilterDueDateAfter] = useState<string>()
  const [filterDueDateBefore, setFilterDueDateBefore] = useState<string>()
  const [filterPriority, setFilterPriority] = useState<CodedConceptDto>()
  const [filterPrograms, setFilterPrograms] = useState<ProgramTerse[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const followUpUrgency = useMemo(
    () => terminology.find((term) => term.setName === FOLLOW_UP_URGENCY)?.value || [],
    [terminology]
  )

  useEffect(() => {
    let entriesState = [...entries]
    if (filterAssignedTo)
      entriesState = entriesState.filter(
        (each) => each.templateData.assignedTo?.id === filterAssignedTo?.id
      )
    if (filterDueDateAfter)
      entriesState = entriesState.filter((each) => {
        if (each.templateData.dueDate)
          return isDate1BeforeDate2(filterDueDateAfter, each.templateData.dueDate)
        return false
      })
    if (filterDueDateBefore)
      entriesState = entriesState.filter((each) => {
        if (each.templateData.dueDate)
          return isDate1BeforeDate2(each.templateData.dueDate, filterDueDateBefore)
        return false
      })
    if (filterPriority)
      entriesState = entriesState.filter(
        (each) => each.templateData.priority?.id === filterPriority.id
      )
    if (filterPrograms.length > 0)
      entriesState = entriesState.filter((each) => {
        if (!each.templateData.programs) return false
        for (let i = 0; i < filterPrograms.length; i++) {
          if (!each.templateData.programs.find((each) => each.id === filterPrograms[i].id))
            return false
        }
        return true
      })
    setEntriesState(entriesState)
    if (entries.length > 0) onSelect(entries[0]?.templateRecordId || '')
  }, [
    filterAssignedTo,
    filterDueDateAfter,
    filterDueDateBefore,
    filterPriority,
    filterPrograms,
    entries,
    onSelect,
  ])

  useEffect(() => setEntryIdState(entryId), [entryId])

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #eeeeee',
        borderRadius: '6px',
        maxWidth: 320,
        minHeight: 600,
        minWidth: 320,
      }}
    >
      <MISButton
        onClick={() => setIsOpen(true)}
        size="large"
        startIcon={<TuneIcon />}
        sx={{ color: '#cc5500', justifyContent: 'start', p: 2, width: '100%' }}
        variant="text"
      >
        {t('common.button.filters')}
      </MISButton>
      {filterAssignedTo && (
        <MISChip
          label={`${t('client-to-do.filters.assigned-to')}${evaluateLabelUtil(
            filterAssignedTo?.names
          )}`}
          onDelete={() => setFilterAssignedTo(undefined)}
          sx={{ mb: 0.5, mx: 1 }}
          variant="outlined"
        />
      )}
      {filterDueDateBefore && (
        <MISChip
          label={`${t('client-to-do.filters.due-date-before')}${isoDateToDisplayFormat(
            filterDueDateBefore
          )}`}
          onDelete={() => setFilterDueDateBefore(undefined)}
          sx={{ mb: 0.5, mx: 1 }}
          variant="outlined"
        />
      )}
      {filterDueDateAfter && (
        <MISChip
          label={`${t('client-to-do.filters.due-date-after')}${isoDateToDisplayFormat(
            filterDueDateAfter
          )}`}
          onDelete={() => setFilterDueDateAfter(undefined)}
          sx={{ mb: 0.5, mx: 1 }}
          variant="outlined"
        />
      )}
      {filterPriority && (
        <MISChip
          label={`${t('client-to-do.filters.priority')}${filterPriority?.name}`}
          onDelete={() => setFilterPriority(undefined)}
          sx={{ mb: 0.5, mx: 1 }}
          variant="outlined"
        />
      )}
      {filterPrograms.length > 0 && (
        <MISChip
          label={`${t('client-to-do.filters.programs')}${filterPrograms
            .map((each) => each.name)
            .join(', ')}`}
          onDelete={() => setFilterPrograms([])}
          sx={{ mb: 0.5, mx: 1 }}
          variant="outlined"
        />
      )}
      <Divider />
      {entriesState &&
        Array.isArray(entriesState) &&
        entriesState.map((entry) => (
          <Box key={entry.templateRecordId}>
            <ListItem
              onClick={() => onSelect(entry.templateRecordId || '')}
              sx={{
                backgroundColor:
                  entry.templateRecordId === entryIdState
                    ? GLOBAL.INNER_LEFT_SIDEBAR_BACKGROUND
                    : GLOBAL.WHITE,
                borderBottom: `1px solid ${GLOBAL.BUTTON_DISABLED_BG_COLOR}`,
                cursor: 'pointer',
                px: 2,
                py: 1,
                width: '100%',
              }}
            >
              <Typography variant="body2">
                {entry.templateData.dueDate &&
                  `${t('client-to-do.filters.due-date')}${isoDateToDisplayFormat(
                    entry.templateData.dueDate
                  )}`}
                <Typography
                  sx={{
                    display: '-webkit-box',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '1',
                  }}
                  variant="body2"
                >
                  {entry.templateData.description}
                </Typography>
                {evaluateLabelUtil(entry.templateData.assignedTo?.names)}
              </Typography>
            </ListItem>
          </Box>
        ))}
      <MISDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Stack spacing={2} sx={{ m: 3, width: 320 }}>
          <MISSelectDropdown
            label={t('charting.templates.follow-up.fields.assigned-to')}
            onChange={(e) => setFilterAssignedTo(e.target.value)}
            options={
              providers?.map((provider: PersonnelDTO) => {
                return {
                  label: evaluateLabelUtil(provider?.names),
                  value: provider,
                }
              }) || []
            }
            value={providers?.find((each) => each.id === filterAssignedTo?.id)}
          />
          <DatePicker
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('client-to-do.filters.due-date-before-label')}
            onChange={(value) => setFilterDueDateBefore(value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={filterDueDateBefore || null}
          />
          <DatePicker
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('client-to-do.filters.due-date-after-label')}
            onChange={(value) => setFilterDueDateAfter(value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={filterDueDateAfter || null}
          />
          <MISSelectDropdown
            label={t('charting.templates.follow-up.fields.priority')}
            onChange={(e) => setFilterPriority(e.target.value)}
            options={followUpUrgency.map((each) => ({ label: each.name as string, value: each }))}
            value={filterPriority}
          />
          <MISSelectMultiDropdown
            label={t('charting.templates.follow-up.fields.programs')}
            onChange={(e) => setFilterPrograms(e.target.value || [])}
            options={programsAssociated?.map((program: ProgramTerse) => {
              return {
                label: program.name,
                value: program,
              }
            })}
            value={
              filterPrograms?.map((program) =>
                programsAssociated?.find((each) => program.id === each.id)
              ) || []
            }
          />
          <MISButton color="primary" onClick={() => setIsOpen(false)} size="large">
            {t('common.button.close')}
          </MISButton>
        </Stack>
      </MISDrawer>
    </Box>
  )
}

export default ToDoFilters
