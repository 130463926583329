import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Tooltip from '@mui/material/Tooltip'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Content } from 'common/components/contentpane/Content'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { populateUserPreferences } from 'core/routing/useStartupService'
import { userPreferencesAtom } from 'recoil/atoms'
import { UserControllerService, UserPreferenceControllerService } from 'services/openapi'

const UserPreferences = ({ auth }) => {
  const { handleApiError } = useErrorHandler()
  const { t } = useTranslation('common')
  const recoilUserPreferences = useRecoilValue(userPreferencesAtom)
  const setUserPreferences = useSetRecoilState(userPreferencesAtom)
  const [timezoneOptions, setTimezoneOptions] = useState([])
  const [timezone, setTimezone] = useState(null)
  const [timezoneSearch, setTimezoneSearch] = useState('')
  const [browsersTimezone, setBrowsersTimezone] = useState('')
  const [warnings, setWarnings] = useState(new Set())
  const user = auth?.user

  const getOffset = useCallback((timezone, date) => {
    let offset
    ;['en', 'fr'].some((lang) => {
      let parts = new Intl.DateTimeFormat(lang, {
        minute: 'numeric',
        timeZone: timezone,
        timeZoneName: 'short',
      }).formatToParts(date)
      let tzName = parts.filter((part) => part.type === 'timeZoneName' && part.value)
      if (/^(GMT|UTC)/.test(tzName[0].value)) {
        offset = tzName[0].value.replace(/GMT|UTC/, '') || '+0'
        return true
      }
      return false
    })
    return offset
  }, [])

  const getTimezone = useCallback(
    (timezone, date) => {
      let offset = getOffset(timezone, date)
      let sign = offset[0] === '\x2b' ? '\x2b' : '\x2d'
      let [h, m] = offset.substring(1).split(':')
      return `${timezone} ${sign}${h.padStart(2, '0')}:${m || '00'}`
    },
    [getOffset]
  )

  const getTimezones = useCallback(() => {
    const date = new Date()
    return Intl.supportedValuesOf('timeZone').map((timezone) => {
      return getTimezone(timezone, date)
    })
  }, [getTimezone])

  const validatePreferences = useCallback(() => {
    let warningsSet = new Set()
    if (timezone !== browsersTimezone) {
      warningsSet.add('timezone')
    }
    setWarnings(warningsSet)
  }, [timezone, browsersTimezone])

  const handleSetTimezones = useCallback(() => {
    const browsersTimezoneWithOffset = getTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      new Date()
    )
    setBrowsersTimezone(browsersTimezoneWithOffset)
    setTimezoneOptions(getTimezones())
  }, [getTimezone, getTimezones])

  useEffect(() => {
    handleSetTimezones()
    if (recoilUserPreferences) {
      const userTimezone = recoilUserPreferences.get('timezone')?.value
        ? recoilUserPreferences.get('timezone')?.value
        : null
      setTimezone(userTimezone)
    }
  }, [recoilUserPreferences, handleSetTimezones])

  useEffect(() => {
    validatePreferences()
  }, [validatePreferences, timezone])

  const styles = () => ({
    popper: {
      width: 'fit-content',
    },
  })

  const PopperMy = function (props) {
    return <Popper {...props} placement="bottom-start" style={styles.popper} />
  }

  const updatePreference = async (preferenceId, preference) => {
    const userId = await UserControllerService.findUserBy(user.profile?.sub).then((result) => {
      if (result) {
        return result.id
      }
    })

    await UserPreferenceControllerService.updateUserPreferences(userId, preferenceId, preference)
      .then(() => {
        populateUserPreferences(setUserPreferences, user)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }

  const createPreference = async (preference) => {
    const userId = await UserControllerService.findUserBy(user.profile?.sub).then((result) => {
      if (result) {
        return result.id
      }
    })

    await UserPreferenceControllerService.createUserPreference(userId, preference)
      .then(() => {
        populateUserPreferences(setUserPreferences, user)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }

  const handleSetBrowserTimezone = () => {
    setWarnings((prevWarnings) => {
      return new Set([...prevWarnings].filter((key) => key !== 'timezone'))
    })
    setTimezone(browsersTimezone)
  }

  const handleSavePreferences = async () => {
    const currentTimezone = recoilUserPreferences.get('timezone')
    if (timezone && currentTimezone && timezone !== currentTimezone.value) {
      const timezoneUpdate = { ...recoilUserPreferences.get('timezone'), value: timezone }
      updatePreference(timezoneUpdate.id, timezoneUpdate)
    } else if (timezone && !currentTimezone) {
      createPreference({ name: 'timezone', value: timezone })
    }
  }

  const preferencesActions = () => {
    const currentTimezone = recoilUserPreferences.get('timezone')
    if (!timezone || (currentTimezone && timezone === currentTimezone.value)) {
      return <MISButton disabled>Save</MISButton>
    }
    return <MISButton onClick={handleSavePreferences}>{t('common.button.save')}</MISButton>
  }

  const getUserPreferencesContent = () => {
    return (
      <Paper elevation={6} sx={{ p: 5 }}>
        {recoilUserPreferences && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {warnings.has('timezone') && (
                <Alert className="error" severity="warning">
                  <div>
                    The current timezone set is not the same as your browser&apos;s timezone.&nbsp;
                    <MISButton color="primary" onClick={handleSetBrowserTimezone} size="small">
                      Click here
                    </MISButton>
                    &nbsp;if you wish to set your timezone to your browser&apos;s timezone.
                  </div>
                </Alert>
              )}
              <Tooltip
                arrow
                placement="right"
                style={{ width: '350px' }}
                title={t('user.tooltip.timezone')}
              >
                <Autocomplete
                  PopperComponent={PopperMy}
                  id="timezone"
                  inputValue={timezoneSearch}
                  onChange={(event, value) => {
                    setTimezone(value || null)
                  }}
                  onInputChange={(event, value) => {
                    setTimezoneSearch(value)
                  }}
                  options={timezoneOptions}
                  renderInput={(params) => (
                    <MISTextField
                      style={{ width: '350px' }}
                      {...params}
                      label={t('user.field.timezone')}
                    />
                  )}
                  size="small"
                  value={timezone}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid alignItems="center" container direction="row-reverse" justifyContent="space-between">
          <Grid item>{preferencesActions()}</Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <Content
      content={getUserPreferencesContent()}
      heading="Preferences"
      isCollapsible={false}
      isDivider={false}
    />
  )
}

export default UserPreferences
