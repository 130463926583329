/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageListFunctionalAreasDTO } from '../models/PageListFunctionalAreasDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FunctionalAreaControllerService {
  /**
   * @param _function
   * @param displayName
   * @param functionParentId
   * @param isTopLevel
   * @param includeFullPath
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageListFunctionalAreasDTO OK
   * @throws ApiError
   */
  public static getFunctionalAreas(
    _function?: string,
    displayName?: string,
    functionParentId?: string,
    isTopLevel?: boolean,
    includeFullPath: boolean = false,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageListFunctionalAreasDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/functional-areas',
      query: {
        function: _function,
        displayName: displayName,
        functionParentId: functionParentId,
        isTopLevel: isTopLevel,
        includeFullPath: includeFullPath,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
