import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { Divider, Typography } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'

export interface SortOptionType {
  id: 'asc' | 'desc'
  description: string
}

type EncounterNotesSectionHeaderProps = {
  onAddNote: () => void
  onChangeSortOption: (option: 'asc' | 'desc') => void
  sortOption: 'asc' | 'desc'
}

const EncounterServiceNotesHeader = ({
  onAddNote,
  onChangeSortOption,
  sortOption,
}: EncounterNotesSectionHeaderProps) => {
  const { t } = useTranslation('common')

  const sortOptions = useMemo(() => {
    return [
      { description: t('client-non-clinical-notes.ascending-date'), id: 'asc' },
      { description: t('client-non-clinical-notes.descending-date'), id: 'desc' },
    ]
  }, [t])

  const handleChangeSortOption = useCallback(
    (option: 'asc' | 'desc') => onChangeSortOption(option),
    [onChangeSortOption]
  )

  return (
    <>
      <Divider sx={{ mb: GLOBAL.MARGIN_XL, ml: 0, mr: 0, mt: GLOBAL.MARGIN_XL }} />
      <Typography sx={{ fontWeight: 650, pb: GLOBAL.MARGIN_XS }} variant="h3">
        Notes
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: GLOBAL.MARGIN_LG,
        }}
      >
        <div style={{ display: 'flex' }}>
          <span style={{ marginRight: GLOBAL.MARGIN_XS, marginTop: GLOBAL.MARGIN_XS }}>
            {t('client-non-clinical-notes.sort-by')}
          </span>
          <div style={{ marginTop: `-${GLOBAL.MARGIN_S}` }}>
            <MISSelectDropdown
              id="sortBy"
              label=""
              onChange={(event) => handleChangeSortOption(event.target.value as 'asc' | 'desc')}
              options={sortOptions.map((option) => {
                return {
                  label: option.description,
                  value: option.id,
                }
              })}
              value={sortOption}
            />
          </div>
        </div>
        <MISButton
          color="primary"
          onClick={onAddNote}
          size="large"
          startIcon={<AddCircleOutline />}
          variant="text"
        >
          {`${t('common.button.add')} Note`}
        </MISButton>
      </div>
    </>
  )
}

export default EncounterServiceNotesHeader
