import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  ClientAlertDTO,
  ClientBirthDetailDTO,
  ClientDeathDetailDTO,
  ClientDTO,
  ClientEducationDTO,
  ClientGenderDetailDTO,
  ClientLanguage,
  ClientRelationshipDTO,
  PrivacyDirectiveDTO,
} from 'services/openapi'

export type TClientRelationships = {
  clientId: string | undefined
  relationships: { client: ClientDTO; clientRelationship: ClientRelationshipDTO }[]
}

export interface ClientState {
  clientId?: string
  clientAlerts?: ClientAlertDTO[]
  clientDetails?: ClientDTO
  clientLanguages?: ClientLanguage[]
  clientRelationships?: TClientRelationships
  clientParentRelationships?: ClientRelationshipDTO[]
  clientBirthDetails?: ClientBirthDetailDTO
  clientDeathDetails?: ClientDeathDetailDTO
  clientGenderDetails?: ClientGenderDetailDTO
  clientEducation?: ClientEducationDTO[]
  clientPrivacyDirectives?: PrivacyDirectiveDTO
  genderIdentityTags?: string[]
}

const initialState: ClientState = {}

export const clientSlice = createSlice({
  initialState,
  name: 'client',
  reducers: {
    resetClient: () => initialState,
    setClientAlerts: (state, action: PayloadAction<ClientAlertDTO[] | undefined>) => {
      state.clientAlerts = action.payload
    },
    setClientBirthDetails: (state, action: PayloadAction<ClientBirthDetailDTO | undefined>) => {
      state.clientBirthDetails = action.payload
    },
    setClientDeathDetails: (state, action: PayloadAction<ClientDeathDetailDTO | undefined>) => {
      state.clientDeathDetails = action.payload
    },
    setClientDetails: (state, action: PayloadAction<ClientDTO | undefined>) => {
      state.clientDetails = action.payload
    },
    setClientEducation: (state, action: PayloadAction<ClientEducationDTO[] | undefined>) => {
      state.clientEducation = action.payload
    },
    setClientGenderDetails: (state, action: PayloadAction<ClientGenderDetailDTO | undefined>) => {
      state.clientGenderDetails = action.payload
    },
    setClientLanguages: (state, action: PayloadAction<ClientLanguage[] | undefined>) => {
      state.clientLanguages = action.payload
    },
    setClientParentRelationships: (
      state,
      action: PayloadAction<ClientRelationshipDTO[] | undefined>
    ) => {
      state.clientParentRelationships = action.payload
    },
    setClientPrivacyDirectives: (state, action: PayloadAction<PrivacyDirectiveDTO | undefined>) => {
      state.clientPrivacyDirectives = action.payload
    },
    setClientRelationships: (state, action: PayloadAction<TClientRelationships | undefined>) => {
      state.clientRelationships = action.payload
    },
    setGenderIdentityTags: (state, action: PayloadAction<string[] | undefined>) => {
      state.genderIdentityTags = action.payload
    },
  },
})

export const {
  resetClient,
  setClientAlerts,
  setClientBirthDetails,
  setClientDeathDetails,
  setClientDetails,
  setClientEducation,
  setClientGenderDetails,
  setClientLanguages,
  setClientParentRelationships,
  setClientPrivacyDirectives,
  setClientRelationships,
  setGenderIdentityTags,
} = clientSlice.actions

export default clientSlice.reducer
