/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfidentialityAgreement } from '../models/ConfidentialityAgreement'
import type { PageConfidentialityAgreementSummaryDTO } from '../models/PageConfidentialityAgreementSummaryDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ConfidentialityAgreementControllerService {
  /**
   * @param id
   * @returns ConfidentialityAgreement OK
   * @throws ApiError
   */
  public static getConfidentialityAgreementById(
    id: string
  ): CancelablePromise<ConfidentialityAgreement> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/confidentiality-agreements/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns ConfidentialityAgreement OK
   * @throws ApiError
   */
  public static updateConfidentialityAgreement(
    id: string,
    requestBody: ConfidentialityAgreement
  ): CancelablePromise<ConfidentialityAgreement> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-user-service/v1/agreements/confidentiality-agreements/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageConfidentialityAgreementSummaryDTO OK
   * @throws ApiError
   */
  public static listConfidentialityAgreements(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageConfidentialityAgreementSummaryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/confidentiality-agreements',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ConfidentialityAgreement OK
   * @throws ApiError
   */
  public static createConfidentialityAgreement(
    requestBody: ConfidentialityAgreement
  ): CancelablePromise<ConfidentialityAgreement> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-user-service/v1/agreements/confidentiality-agreements',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns ConfidentialityAgreement OK
   * @throws ApiError
   */
  public static getConfidentialityAgreement(): CancelablePromise<ConfidentialityAgreement> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/agreements/confidentiality-agreements/current',
    })
  }
}
