/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelNameDTO } from '../models/PagePersonnelNameDTO'
import type { PersonnelNameDTO } from '../models/PersonnelNameDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelNameControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelNameDTO OK
   * @throws ApiError
   */
  public static getPersonnelNames(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelNameDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/names',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelNameDTO OK
   * @throws ApiError
   */
  public static savePersonnelNames(
    personnelId: string,
    requestBody: Array<PersonnelNameDTO>
  ): CancelablePromise<Array<PersonnelNameDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/names',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param personnelId
   * @param nameId
   * @returns any OK
   * @throws ApiError
   */
  public static deletePersonnelName(personnelId: string, nameId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/names/{nameId}',
      path: {
        personnelId: personnelId,
        nameId: nameId,
      },
    })
  }
}
