import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import global from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import {
  PersonnelControllerService,
  PrivacyDirectiveOverrideControllerService,
} from 'services/openapi'

const TopBanner = () => {
  const { clientId } = useParams()
  const [localMessage, setLocalMessage] = useState('')

  const getHeader = useCallback(async (id: string) => {
    const overrideDirectives =
      await PrivacyDirectiveOverrideControllerService.getDirectiveOverrides(
        id,
        true,
        undefined,
        undefined,
        ['auditInfo']
      )
    if (overrideDirectives && overrideDirectives.content) {
      const provider =
        overrideDirectives.content.length > 0
          ? overrideDirectives.content[0].overrideUserId
          : undefined

      if (provider) {
        const userList = await PersonnelControllerService.getPersonnelLightList([provider])
        if (userList) {
          const userName = userList[0].firstName + ' ' + userList[0].lastName
          const theTime = isoDateToDisplayFormatWithTime(
            overrideDirectives.content[0].auditInfo?.created?.date
          )
          setLocalMessage(
            `Restricted File access granted to ${userName} until ${theTime}. See Manage File Access Restrictions > View History for details `
          )
        }
      }
    } else {
      setLocalMessage('')
    }
  }, [])

  useEffect(() => {
    if (clientId) {
      getHeader(clientId)
    }
  }, [clientId, getHeader])

  return (
    <>
      {localMessage && localMessage !== '' && (
        <Box
          sx={{
            backgroundColor: global.BUTTON_PRIMARY_BG_COLOR,
            color: 'white',
            left: 0,
            padding: '8px',
            position: 'relative',
            top: 0,
            width: '100%',
          }}
        >
          <Typography variant="body1">{localMessage}</Typography>
        </Box>
      )}
    </>
  )
}

export default TopBanner
