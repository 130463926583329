import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import FollowUpTemplateComponent from 'modules/charting/components/templates/follow-up-template/FollowUpTemplateComponent'

type ToDoEntryProps = {
  entry: TTemplateBlot
}

const ToDoEntry = ({ entry }: ToDoEntryProps) => {
  const [templateData, setTemplateData] = useState(entry?.templateData)

  useEffect(() => setTemplateData(entry.templateData), [entry])

  if (!entry?.templateData) return null

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: '6px',
        p: 2,
        width: '100%',
      }}
    >
      <FollowUpTemplateComponent
        {...templateData}
        onChange={(key, value) => setTemplateData({ ...templateData, [key]: value })}
        readOnly
      />
    </Box>
  )
}

export default ToDoEntry
