import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Drawer } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'

interface MISDrawerProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

const MISDrawer = ({ children, isOpen, onClose }: MISDrawerProps) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => setOpen(isOpen), [isOpen])

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setOpen(open)
      if (!open) {
        onClose()
      }
    },
    [onClose]
  )

  return (
    <ThemeProvider theme={misTheme}>
      <Drawer anchor="right" onClose={toggleDrawer(false)} open={open}>
        {children}
      </Drawer>
    </ThemeProvider>
  )
}

export default MISDrawer
