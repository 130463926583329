import { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  NoteSchema,
  RunningNoteEntityTypes,
  RunningNoteSchema,
  runningNotesState,
  RunningNoteType,
} from 'recoil/runningNotes'
import {
  CancelablePromise,
  CodedConceptDto,
  EncounterControllerService,
  EncounterNoteDTO,
  EncounterServiceDTO,
  EncounterServiceTemplateDTO,
  EncounterStaffControllerService,
  ListFunctionalAreasDTO,
  PageStaffUserDTO,
  Program,
  StaffUserDTO,
} from 'services/openapi'
import useProviders from './useProviders'
import { getPersonnelFullName } from '../clientUtils'

export type FilterProps = { key: string; value: any }

const defaultObject = {
  checked: false,
  date: '',
  encounterServiceId: '',
  entity: RunningNoteEntityTypes.Encounter,
  id: '',
  name: '',
  notes: [],
  primaryProvider: '',
  state: undefined,
  synopsis: '',
}

const useRunningNotes = (clientId: string, filters: FilterProps[], applyFilters = false) => {
  const { handleApiError } = useErrorHandler()
  const [runningNotes, setRunningNotes] = useRecoilState(runningNotesState)
  const providers = useProviders() || []

  const [serviceTemplateIdsInUse, setServiceTemplateIdsInUse] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [author, setAuthor] = useState('')
  const [sources, setSources] = useState<string[]>([])
  const [noteStatuses, setNoteStatuses] = useState<string[]>([])
  const [programs, setPrograms] = useState<string[]>([])
  const [serviceTemplates, setServiceTemplates] = useState<string[]>([])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [associatedStaff, setAssociatedStaff] = useState<
    {
      providerId: string
      encounterServiceId: string
      name: string
    }[]
  >([])

  const hasNoteFilters = useCallback((filters) => {
    const existingFilters = filters.filter((obj: FilterProps) => {
      if (
        obj.key === 'sources' &&
        obj?.value?.length === 1 &&
        obj?.value[0]?.function === 'ENCOUNTERS'
      ) {
        return false
      }
      return !!obj?.value && obj?.value[0]
    })
    return (
      existingFilters.filter(
        ({ key }: FilterProps) => key === 'searchText' || key === 'noteStatuses'
      )?.length > 0
    )
  }, [])

  const getFilterValue = useCallback(
    (filterKey: string) => {
      const filterObj = filters.filter((o) => o.key === filterKey)?.[0]
      if (filterObj) {
        const { key, value } = filterObj
        switch (key) {
          case 'searchText':
            return value
          case 'author':
            return value?.userId
          case 'sources': {
            const functions: string[] = []
            value?.forEach((fa: ListFunctionalAreasDTO) => {
              if (fa?.function) {
                functions.push(fa.function)
              }
            })
            return [...functions]
          }
          case 'noteStatuses': {
            const statusCodes: string[] = []
            value?.forEach((status: CodedConceptDto) => {
              if (status?.code) {
                statusCodes.push(status.code)
              }
            })
            return [...statusCodes]
          }
          case 'programs': {
            const programIds: string[] = []
            value?.forEach((program: Program) => {
              if (program?.id) {
                programIds.push(program?.id)
              }
            })
            return [...programIds]
          }
          case 'serviceTemplates': {
            const templateIds: string[] = []
            value?.forEach((template: EncounterServiceTemplateDTO) => {
              if (template?.id) {
                templateIds.push(template.id)
              }
            })
            return [...templateIds]
          }
          case 'fromDate':
          case 'toDate':
            return value
        }
      }
    },
    [filters]
  )

  useEffect(() => {
    if (applyFilters) {
      filters.forEach(({ key }: FilterProps) => {
        switch (key) {
          case 'searchText':
            setSearchText(getFilterValue(key))
            break
          case 'author':
            setAuthor(getFilterValue(key))
            break
          case 'sources':
            setSources(getFilterValue(key))
            break
          case 'noteStatuses':
            setNoteStatuses(getFilterValue(key))
            break
          case 'programs':
            setPrograms(getFilterValue(key))
            break
          case 'serviceTemplates':
            setServiceTemplates(getFilterValue(key))
            break
          case 'fromDate':
            setFromDate(getFilterValue(key))
            break
          case 'toDate':
            setToDate(getFilterValue(key))
            break
        }
      })
    }
  }, [applyFilters, filters, getFilterValue])

  const transform = useCallback((obj: EncounterServiceDTO) => {
    const runningNote: RunningNoteSchema = { ...defaultObject }
    runningNote.id = obj.id
    runningNote.encounterId = obj.encounterId
    runningNote.encounterServiceId = obj.id
    runningNote.checked = false
    runningNote.name = obj.name
    runningNote.synopsis = obj.synopsis
    runningNote.date = obj.encounterServiceDate
    runningNote.state = obj.state
    runningNote.primaryProvider = ''

    const notes: NoteSchema[] = []
    obj.notes?.forEach((n: EncounterNoteDTO) => {
      notes.push({
        author: `${n.auditInfo?.created?.user?.firstName} ${n.auditInfo?.created?.user?.lastName}`,
        content: n.content,
        lastUpdated: n.auditInfo?.lastUpdated?.date || '',
        state: n.state,
      })
    })
    runningNote.notes = [...notes].sort((a, b) => {
      return (
        new Date(b?.lastUpdated as string).getTime() - new Date(a?.lastUpdated as string).getTime()
      )
    })
    return {
      entity: RunningNoteEntityTypes.Encounter,
      runningNote,
    }
  }, [])

  useEffect(() => {
    const getEncounterNotes = async (clientId: string) => {
      try {
        const response = await EncounterControllerService.encountersgetPagedList(
          clientId,
          'CLIENT_FILES',
          '',
          '',
          false,
          false,
          hasNoteFilters(filters) ? 'MATCHING_ONLY' : 'ALL',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          getFilterValue('programs') || undefined,
          getFilterValue('serviceTemplates') || undefined,
          true,
          getFilterValue('searchText') || undefined,
          getFilterValue('author') || undefined,
          getFilterValue('noteStatuses') || undefined,
          getFilterValue('fromDate') || undefined,
          getFilterValue('toDate') || undefined,
          undefined,
          undefined,
          10000000000
        )
        const promises: CancelablePromise<PageStaffUserDTO>[] = []
        const esIds: string[] = []
        const serviceTemplateIds: string[] = []
        const runningNoteList: RunningNoteType[] = []
        const encounters = response?.content
        encounters?.forEach((e) => {
          e.encounterServices?.forEach((es) => {
            const transformedObj = transform(es)
            runningNoteList.push(transformedObj)
            if (es.encounterServiceTemplateId) {
              serviceTemplateIds.push(es.encounterServiceTemplateId)
            }

            if (es.encounterId && es.id) {
              esIds.push(es.id)
              promises.push(EncounterStaffControllerService.getPagedList2(es.encounterId, es.id))
            }
          })
        })
        const temp: { encounterServiceId: string; name: string; providerId: string }[] = []
        const responses = await Promise.all(promises)
        responses.forEach((response, idx) => {
          const encounterServiceId = esIds[idx]
          if (response?.content) {
            response.content?.forEach((obj: StaffUserDTO) => {
              const isPrimary = obj.attributes?.isPrimaryProvider
              if (isPrimary) {
                const foundIdx = associatedStaff.findIndex(
                  (o) =>
                    o.providerId === obj.providerId && o.encounterServiceId === encounterServiceId
                )
                if (foundIdx === -1) {
                  if (obj.providerId) {
                    temp.push({
                      encounterServiceId: encounterServiceId,
                      name: getPersonnelFullName(
                        providers.find((o) => o.id === obj.providerId) || null
                      ),
                      providerId: obj.providerId,
                    })
                  }
                }
              }
            })
          }
        })
        setAssociatedStaff([...associatedStaff, ...temp])
        if (filters.length === 0) {
          setServiceTemplateIdsInUse([...serviceTemplateIds])
        }
        setRunningNotes(
          [...runningNoteList].sort((a, b) => {
            return (
              new Date(b?.runningNote?.date as string).getTime() -
              new Date(a?.runningNote?.date as string).getTime()
            )
          })
        )
      } catch (error) {
        handleApiError(error)
      }
    }
    if (clientId) {
      if (sources && (sources.length === 0 || sources.some((source) => source === 'ENCOUNTERS'))) {
        getEncounterNotes(clientId)
      } else {
        setRunningNotes([])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    author,
    clientId,
    fromDate,
    noteStatuses,
    programs,
    providers,
    searchText,
    serviceTemplates,
    sources,
    toDate,
  ])

  return { associatedStaff, providers, runningNotes, serviceTemplateIdsInUse, setRunningNotes }
}

export default useRunningNotes
