import { ChangeEvent, ElementType, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { Box, FormControlLabel, InputBaseComponentProps, Stack } from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISTextField from 'common/components/form/MISTextField'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBNumberFormatProps = {
  data?: TFormBuilderComponentData
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBNumberFormat = ({ data, onChange, onDelete, onSave, preview }: FBNumberFormatProps) => {
  const { t } = useTranslation('common')

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(data || {})
  const [configMode, setConfigMode] = useState(false)
  const [formatEntered, setFormatEntered] = useState(!!data?.format)

  return (
    <>
      {preview ? (
        <MISTextField
          {...data}
          InputProps={{
            inputComponent: data?.format
              ? (PatternFormat as ElementType<InputBaseComponentProps>)
              : (NumericFormat as ElementType<InputBaseComponentProps>),
            inputProps: {
              allowLeadingZeros: !!data?.allowLeadingZeros,
              allowNegative: !!data?.allowNegative,
              decimalScale: data?.decimalScale,
              format: data?.format,
              prefix: data?.prefix,
              suffix: data?.suffix,
              thousandSeparator: !!data?.thousandSeparator,
            },
          }}
          onChange={onChange}
        />
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>
            <MISTextField
              {...data}
              InputProps={{
                inputComponent: data?.format
                  ? (PatternFormat as ElementType<InputBaseComponentProps>)
                  : (NumericFormat as ElementType<InputBaseComponentProps>),
                inputProps: {
                  allowLeadingZeros: !!data?.allowLeadingZeros,
                  allowNegative: !!data?.allowNegative,
                  decimalScale: data?.decimalScale,
                  format: data?.format,
                  prefix: data?.prefix,
                  suffix: data?.suffix,
                  thousandSeparator: !!data?.thousandSeparator,
                },
              }}
              onChange={onChange}
            />
          </Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.number-format.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.name}
                label={t('charting.form-builder.name')}
                onChange={(e) =>
                  setConfigData(
                    configData ? { ...configData, name: e.target.value } : { name: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.helperText}
                label={t('charting.form-builder.helperText')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, helperText: e.target.value }
                      : { helperText: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.format}
                label={t('charting.form-builder.number-format.format')}
                onChange={(e) => {
                  if (e.target.value) {
                    setFormatEntered(true)
                    setConfigData(
                      configData
                        ? {
                            ...configData,
                            allowLeadingZeros: undefined,
                            allowNegative: undefined,
                            decimalScale: undefined,
                            format: e.target.value,
                            prefix: undefined,
                            suffix: undefined,
                            thousandSeparator: undefined,
                          }
                        : { format: e.target.value }
                    )
                  } else {
                    setFormatEntered(false)
                    setConfigData(
                      configData
                        ? { ...configData, format: e.target.value }
                        : { format: e.target.value }
                    )
                  }
                }}
              />
              <MISTextField
                defaultValue={data?.decimalScale}
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.decimal-scale')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, decimalScale: Number.parseInt(e.target.value) }
                      : { decimalScale: Number.parseInt(e.target.value) }
                  )
                }
                type="number"
              />
              <MISTextField
                defaultValue={data?.prefix}
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.prefix')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, prefix: e.target.value }
                      : { prefix: e.target.value }
                  )
                }
              />
              <MISTextField
                defaultValue={data?.suffix}
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.suffix')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, suffix: e.target.value }
                      : { suffix: e.target.value }
                  )
                }
              />
              <FormControlLabel
                control={
                  <MISCheckbox
                    defaultChecked={!!data?.thousandSeparator}
                    onChange={(_, checked) =>
                      setConfigData(
                        configData
                          ? { ...configData, thousandSeparator: checked }
                          : { thousandSeparator: checked }
                      )
                    }
                  />
                }
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.thousand-separator')}
              />
              <FormControlLabel
                control={
                  <MISCheckbox
                    defaultChecked={!!data?.allowLeadingZeros}
                    onChange={(_, checked) =>
                      setConfigData(
                        configData
                          ? { ...configData, allowLeadingZeros: checked }
                          : { allowLeadingZeros: checked }
                      )
                    }
                  />
                }
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.allow-leading-zeros')}
              />
              <FormControlLabel
                control={
                  <MISCheckbox
                    defaultChecked={!!data?.allowNegative}
                    onChange={(_, checked) =>
                      setConfigData(
                        configData
                          ? { ...configData, allowNegative: checked }
                          : { allowNegative: checked }
                      )
                    }
                  />
                }
                disabled={formatEntered}
                label={t('charting.form-builder.number-format.allow-negative')}
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBNumberFormat
