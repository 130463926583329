/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePrivacyDirectiveDTO } from '../models/PagePrivacyDirectiveDTO'
import type { PrivacyDirectiveDTO } from '../models/PrivacyDirectiveDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PrivacyDirectiveControllerService {
  /**
   * Returns paged list of privacy directives for client
   *  Returns paged list of privacy directives for client.
   * The items in response will be filted based on the specified query parameters.
   * NOTE: The latestOnly param, which is true by default, implies that only one
   * item is returned, which overrides any paging properties specified as
   * query paramters.
   *
   * @param clientId
   * @param latestOnly
   * @param entityId
   * @param entityDirectivesToInclude
   * @param _with
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePrivacyDirectiveDTO OK
   * @throws ApiError
   */
  public static getDirectives(
    clientId: string,
    latestOnly: boolean = true,
    entityId?: Array<string>,
    entityDirectivesToInclude: string = 'CLIENT',
    _with?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePrivacyDirectiveDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/privacy-directives',
      path: {
        clientId: clientId,
      },
      query: {
        latestOnly: latestOnly,
        entityId: entityId,
        entityDirectivesToInclude: entityDirectivesToInclude,
        with: _with,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create client privacy directive
   * Create a privacy directive associated with specified client
   * @param clientId
   * @param requestBody
   * @returns PrivacyDirectiveDTO OK
   * @throws ApiError
   */
  public static createDirective(
    clientId: string,
    requestBody: PrivacyDirectiveDTO
  ): CancelablePromise<PrivacyDirectiveDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/privacy-directives',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
