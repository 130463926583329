import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { Delete } from '@mui/icons-material'
import HistoryIcon from '@mui/icons-material/History'
import { Divider, IconButton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateValueType, StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef, EncounterNoteControllerService, EncounterNoteDTO } from 'services/openapi'
import { MIS_ENCOUNTER_NOTE_STATE, MIS_VOID_REASON_TYPE } from 'services/terminologyConstants'

export interface SortOptionType {
  id: 'asc' | 'desc'
  description: string
}

type EncounterServiceNoteProps = {
  encounterId: string
  serviceId: string
  index: number
  note: EncounterNoteDTO
  onChangeNote: (note: EncounterNoteDTO, index: number) => void
  onDeleteNote: (index: number) => void
  onViewHistory: (noteId: string | undefined) => void
}

const EncounterServiceNote = ({
  encounterId,
  index,
  note,
  onChangeNote,
  onDeleteNote,
  onViewHistory,
  serviceId,
}: EncounterServiceNoteProps) => {
  const { t } = useTranslation('common')
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_NOTE_STATE))
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  const encounterServiceNoteStateValues: StateValueType[] = useMemo(
    () =>
      stateOptions.map((opt) => {
        switch (opt.code) {
          case 'REVISED':
            return {
              commentRequired: true,
              stateValue: opt,
            }
          case 'VOIDED':
            return {
              reasonCodeRequired: true,
              reasonCodeTerminology: voidReasonOptions,
              stateValue: opt,
            }
          default:
            return { stateValue: opt }
        }
      }),
    [stateOptions, voidReasonOptions]
  )

  const handleChangeContent = useCallback(
    (value: string, index: number) => onChangeNote({ ...note, content: value }, index),
    [note, onChangeNote]
  )

  const handleChangeState = useCallback(
    async (
      _id: string,
      state: CodedRef,
      _reason: CodedRef | undefined,
      stateComment?: string | undefined
    ) => {
      onChangeNote({ ...note, state, stateComment }, index)
    },
    [index, note, onChangeNote]
  )

  const handleGetNextNoteStateOptions = useCallback(
    async (noteId: string) => {
      const nextStateOptions: StateWithInstructionsType[] = []
      const response = await EncounterNoteControllerService.getNextStatesByNoteId(
        encounterId,
        serviceId,
        noteId
      )
      response.content?.forEach((item) => {
        if (item.state) nextStateOptions.push(item)
      })
      return nextStateOptions
    },
    [encounterId, serviceId]
  )

  return (
    <>
      <Divider sx={{ mb: GLOBAL.MARGIN_S, ml: 0, mr: 0 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: GLOBAL.MARGIN_SM,
        }}
      >
        <div>
          {note.auditInfo && (
            <>
              <span>{`${t('encounter-service-notes.labels.note-created-by')}: ${
                note.auditInfo?.lastUpdated?.user?.firstName
              } ${note.auditInfo?.lastUpdated?.user?.lastName}`}</span>
              <span>{` | ${isoDateToDisplayFormatWithTime(
                note.auditInfo?.lastUpdated?.date
              )}`}</span>
              {note.state && (
                <span>
                  {' | '}
                  <StateChip
                    comment={note.stateComment}
                    defaultCursor={!note.stateComment}
                    label={
                      stateOptions?.find((o: CodedRef) => o.code === (note.state as CodedRef)?.code)
                        ?.name as string
                    }
                  />
                </span>
              )}
              <StateSelector
                entityId={note.id as string}
                onGetNextStates={handleGetNextNoteStateOptions}
                onSelect={handleChangeState}
                stateValues={encounterServiceNoteStateValues}
                warningText={t('common.state-selector.warning')}
              />
            </>
          )}
        </div>
        <div>
          <MISButton
            color="primary"
            onClick={() => onViewHistory(note?.id)}
            size="large"
            startIcon={<HistoryIcon />}
            sx={{ px: 2 }}
            variant="text"
          >
            {t('encounter-service-notes.labels.view-history')}
          </MISButton>
          <IconButton
            onClick={() => onDeleteNote(index)}
            size="small"
            sx={{ pointerEvents: 'auto' }}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div style={{ paddingBottom: GLOBAL.MARGIN_MD }}>
        <ReactQuill
          onChange={(value: string) => handleChangeContent(value, index)}
          style={{ height: '100%' }}
          theme="snow"
          value={note.content || ''}
        />
      </div>
    </>
  )
}

export default EncounterServiceNote
