/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertDTO } from '../models/AlertDTO'
import type { PageAlertDTO } from '../models/PageAlertDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AlertControllerService {
  /**
   * returns alert by alertId
   * returns alert by alertId
   * @param alertId
   * @returns AlertDTO OK
   * @throws ApiError
   */
  public static getAlert(alertId: string): CancelablePromise<AlertDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/alerts/{alertId}',
      path: {
        alertId: alertId,
      },
    })
  }

  /**
   * update alert
   * updates alert by alertId
   * @param alertId
   * @param requestBody
   * @returns AlertDTO OK
   * @throws ApiError
   */
  public static updateAlert(alertId: string, requestBody: AlertDTO): CancelablePromise<AlertDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/alerts/{alertId}',
      path: {
        alertId: alertId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete alert by alertId
   * Soft deletes the alert by setting deleted flag to true
   * @param alertId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteAlert(alertId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/alerts/{alertId}',
      path: {
        alertId: alertId,
      },
    })
  }

  /**
   * Returns a paged list of alerts
   * List is returned as Paged collection
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageAlertDTO OK
   * @throws ApiError
   */
  public static getAlerts(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageAlertDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/alerts',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create alert
   * creates alert
   * @param requestBody
   * @returns AlertDTO OK
   * @throws ApiError
   */
  public static createAlert(requestBody: AlertDTO): CancelablePromise<AlertDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/alerts',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
