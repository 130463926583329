/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncounterNoteDTO } from '../models/EncounterNoteDTO'
import type { PageEncounterNoteDTO } from '../models/PageEncounterNoteDTO'
import type { PageEncounterNoteHistoryDTO } from '../models/PageEncounterNoteHistoryDTO'
import type { UnPagedEncounterNoteHistoryDTO } from '../models/UnPagedEncounterNoteHistoryDTO'
import type { UnPagedEncounterNoteStateDTO } from '../models/UnPagedEncounterNoteStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class EncounterNoteControllerService {
  /**
   * Get an EncounterServiceNote by id
   * @param encounterId
   * @param encounterServiceId
   * @param noteId
   * @returns EncounterNoteDTO OK
   * @throws ApiError
   */
  public static encountersgetById2(
    encounterId: string,
    encounterServiceId: string,
    noteId: string
  ): CancelablePromise<EncounterNoteDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/{noteId}',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        noteId: noteId,
      },
    })
  }

  /**
   * Update an EncounterNote
   * The properties of the EncounterNote are specified in the JSON request body.
   * @param encounterId
   * @param encounterServiceId
   * @param noteId
   * @param requestBody
   * @returns EncounterNoteDTO OK
   * @throws ApiError
   */
  public static updateEncounterNote(
    encounterId: string,
    encounterServiceId: string,
    noteId: string,
    requestBody: EncounterNoteDTO
  ): CancelablePromise<EncounterNoteDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/{noteId}',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        noteId: noteId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete an EncounterServiceNote
   * Delete encounter service note by noteId.
   * @param encounterId
   * @param encounterServiceId
   * @param noteId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEncounterNote(
    encounterId: string,
    encounterServiceId: string,
    noteId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/{noteId}',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        noteId: noteId,
      },
    })
  }

  /**
   * Get paged list of EncounterNotes associated with Encounter Service with optional search params
   * @param encounterId
   * @param encounterServiceId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterNoteDTO OK
   * @throws ApiError
   */
  public static getPagedList3(
    encounterId: string,
    encounterServiceId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterNoteDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create an EncounterNote
   * @param encounterId
   * @param encounterServiceId
   * @param requestBody
   * @returns EncounterNoteDTO OK
   * @throws ApiError
   */
  public static createEncounterService1(
    encounterId: string,
    encounterServiceId: string,
    requestBody: EncounterNoteDTO
  ): CancelablePromise<EncounterNoteDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Get paged list of EncounterNotes associated with Encounter with optional search params
   * @param encounterId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterNoteDTO OK
   * @throws ApiError
   */
  public static getPagedListForEncounter(
    encounterId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterNoteDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/notes',
      path: {
        encounterId: encounterId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Get list of possible next states for notes
   * @param encounterId
   * @param encounterServiceId
   * @param noteId
   * @returns UnPagedEncounterNoteStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByNoteId(
    encounterId: string,
    encounterServiceId: string,
    noteId: string
  ): CancelablePromise<UnPagedEncounterNoteStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/{noteId}/next-states',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        noteId: noteId,
      },
    })
  }

  /**
   * Get history of state changes by noteId
   * @param encounterId
   * @param encounterServiceId
   * @param noteId
   * @returns UnPagedEncounterNoteHistoryDTO OK
   * @throws ApiError
   */
  public static getStatesByNoteId(
    encounterId: string,
    encounterServiceId: string,
    noteId: string
  ): CancelablePromise<UnPagedEncounterNoteHistoryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/{noteId}/history',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
        noteId: noteId,
      },
    })
  }

  /**
   * Get paged list of all versions of EncounterNotes associated with Encounter Service id
   * @param encounterId
   * @param encounterServiceId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageEncounterNoteHistoryDTO OK
   * @throws ApiError
   */
  public static getPagedListForEncounterNoteHistory(
    encounterId: string,
    encounterServiceId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageEncounterNoteHistoryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-encounter-service/v1/encounters/{encounterId}/encounter-services/{encounterServiceId}/notes/history',
      path: {
        encounterId: encounterId,
        encounterServiceId: encounterServiceId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
