import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PersonnelDTO } from 'services/openapi'

export interface ProviderState {
  providers?: PersonnelDTO[]
}

const initialState: ProviderState = {}

export const providerSlice = createSlice({
  initialState,
  name: 'provider',
  reducers: {
    setProviders: (state, action: PayloadAction<PersonnelDTO[] | undefined>) => {
      state.providers = action.payload
    },
  },
})

export const { setProviders } = providerSlice.actions

export default providerSlice.reducer
