import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Divider, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import ADD_CLIENT_IMG_SRC from 'assets/images/add-client.jpg'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { dateNowIsoString, isoDateToLocalDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getFormattedOptions } from 'modules/shared/utils'
import { govAgenciesWithDomainsAtom } from 'recoil/atoms'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  CodedRef,
  GovernanceAgencyDTO,
  PersonnelControllerService,
} from 'services/openapi'
import {
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
  MIS_PROVIDER_ROLE_TYPES,
} from 'services/terminologyConstants'

const defaultData: any = {
  birthdate: '',
  estimated: '',
  firstName: '',
  gender: '',
  isStaff: '',
  jobFunction: '',
  jobInternalExternal: '',
  jobOrganizationId: '',
  jobStartDate: '',
  jobStatus: true,
  jobTitle: '',
  lastName: '',
  middleName: '',
  namePrefix: '',
  nameSuffix: '',
  pronouns: '',
}

const AddEditProviderStaff = () => {
  const { t } = useTranslation('common')
  const { showSnackError, showSnackSuccess } = useSnack()
  const { buildError, getError, handleApiError, hasError, setErrorList } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const [data, setData] = useState(defaultData)

  const navigate = useNavigate()
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const jobFunctionOptions = useRecoilValue(terminologySelector(MIS_PROVIDER_ROLE_TYPES))
  const govAgenciesOptions = useRecoilValue(govAgenciesWithDomainsAtom).filter((ga) =>
    ga.governanceAgencyType?.some((x) => x.code === 'Organization')
  )

  const handleChange = useCallback(
    (value: any, field: string) => {
      if (!isDirty) setIsDirty(true)
      const updatedData = {
        ...data,
        [field]: value,
      }
      setData(updatedData)
    },
    [data, setData, isDirty, setIsDirty]
  )

  const getCodedConcept = (selectedValue: CodedConceptDto) => {
    return {
      code: selectedValue?.code,
      codeSystemOid: selectedValue?.codeSystemOid,
      name: selectedValue?.name,
    }
  }

  const handleSaveProviderStaff = () => {
    setIsDirty(false)

    if (validate()) {
      let genderCodedConcept: CodedRef | null = null
      if (data.gender) {
        const selectedGender = genderOptions?.find((x) => x.id === data.gender.id)
        if (selectedGender) {
          genderCodedConcept = getCodedConcept(selectedGender)
        }
      }

      let pronounsCodedConcept: CodedRef | null = null
      if (data.pronouns) {
        const selectedPronouns = pronounsOptions?.find((x) => x.id === data.pronouns.id)
        if (selectedPronouns) {
          pronounsCodedConcept = getCodedConcept(selectedPronouns)
        }
      }

      let jobFunctionCodedConcept: CodedRef | null = null
      if (data.jobFunction) {
        const selectedJobFunction = jobFunctionOptions?.find((x) => x.id === data.jobFunction.id)
        if (selectedJobFunction) {
          jobFunctionCodedConcept = getCodedConcept(selectedJobFunction)
        }
      }

      const saveMe = {
        dateOfBirth: data.birthdate ? isoDateToLocalDisplayFormat(data.birthdate) : '',
        dateOfBirthEstimated: data.estimated,
        gender: genderCodedConcept || undefined,
        jobFunction: {
          effective: {
            startDate: data.jobStartDate || dateNowIsoString(),
          },
          isActive: data.jobStatus || false,
          isInternal: data.jobInternalExternal || false,
          isStaff: data.isStaff || false,
          jobFunction: jobFunctionCodedConcept || undefined,
          jobTitle: data.jobTitle,
          organizationId: data.jobOrganizationId || undefined,
        },
        name: {
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          preferredName: true,
          prefix: data.namePrefix,
          suffix: data.nameSuffix,
        },
        pronoun: pronounsCodedConcept || undefined,
      }

      PersonnelControllerService.createPersonnel(saveMe)
        .then((result) => {
          showSnackSuccess(t('api.save-success'))
          navigate(`/provider-staff-record/${result.id}`)
        })
        .catch((error) => {
          showSnackError(t('api.error.save-error'))
          handleApiError(error)
        })
    }
  }

  const validate = () => {
    const errors: any[] = []
    if (!data.firstName) {
      errors.push(
        buildError('add-provider-staff.first-name', 'api.error.required-field', {
          fieldName: 'First Name',
        })
      )
    }
    if (!data.lastName) {
      errors.push(
        buildError('add-provider-staff.last-name', 'api.error.required-field', {
          fieldName: 'Last Name',
        })
      )
    }
    if (!data.jobFunction) {
      errors.push(
        buildError('add-provider-staff.job-function', 'api.error.required-field', {
          fieldName: 'Primary Job Function',
        })
      )
    }
    if (!data.gender) {
      errors.push(
        buildError('add-provider-staff.gender', 'api.error.required-field', { fieldName: 'Gender' })
      )
    }
    if (data.jobInternalExternal === '') {
      errors.push(
        buildError('add-provider-staff.job-internal-external', 'api.error.required-field', {
          fieldName: 'Internal/External',
        })
      )
    }
    if (data.isStaff === '') {
      errors.push(
        buildError('add-provider-staff.is-staff', 'api.error.required-field', {
          fieldName: 'Staff',
        })
      )
    }

    if (data.isStaff && data.jobInternalExternal && !data.jobOrganizationId) {
      errors.push(
        buildError('add-provider-staff.job-organization', 'api.error.required-field', {
          fieldName: 'Organization',
        })
      )
    }

    if (!data.jobStartDate) {
      errors.push(
        buildError('add-provider-staff.start-date', 'api.error.required-field', {
          fieldName: 'Start Date',
        })
      )
    }
    setErrorList(errors)
    return errors.length === 0
  }

  const getAddProviderStaffContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISTextField
              error={hasError('add-provider-staff.first-name')}
              helperText={getError('add-provider-staff.first-name')}
              id="first-name"
              label={t('provider-staff.add-edit.first-name')}
              onChange={(event) => handleChange(event.target.value, 'firstName')}
              required
              value={data.firstName}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              id="middle-name"
              label={t('provider-staff.add-edit.middle-name')}
              onChange={(event) => handleChange(event.target.value, 'middleName')}
              value={data.middleName}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              error={hasError('add-provider-staff.last-name')}
              helperText={getError('add-provider-staff.last-name')}
              id="last-name"
              label={t('provider-staff.add-edit.last-name')}
              onChange={(event) => handleChange(event.target.value, 'lastName')}
              required
              value={data.lastName}
            />
          </Grid>
          <Grid item xs={1.5}>
            <MISTextField
              id="prefix-name"
              label={t('provider-staff.add-edit.prefix-name')}
              onChange={(event) => handleChange(event.target.value, 'namePrefix')}
              value={data.namePrefix}
            />
          </Grid>
          <Grid item xs={1.5}>
            <MISTextField
              id="suffix-name"
              label={t('provider-staff.add-edit.suffix-name')}
              onChange={(event) => handleChange(event.target.value, 'nameSuffix')}
              value={data.nameSuffix}
            />
          </Grid>
          {genderOptions && (
            <Grid item xs={3}>
              <MISSelectDropdown
                error={hasError('add-provider-staff.gender')}
                helperText={getError('add-provider-staff.gender')}
                id="gender"
                label={t('provider-staff.add-edit.gender')}
                onChange={(event) => handleChange(event.target.value, 'gender')}
                options={getFormattedOptions(genderOptions)}
                required
                value={genderOptions.find((o: any) => o === data.gender)}
              />
            </Grid>
          )}
          {pronounsOptions && (
            <Grid item xs={3}>
              <MISSelectDropdown
                id="pronouns"
                label={t('provider-staff.add-edit.pronouns')}
                onChange={(event) => handleChange(event.target.value, 'pronouns')}
                options={getFormattedOptions(pronounsOptions)}
                value={pronounsOptions.find((o: any) => o === data.pronouns)}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <MISDatePicker
              disableFuture
              displayAge
              displayEstimated
              estimated={data.estimated}
              label={t('provider-staff.add-edit.birthdate')}
              onChange={(value) => handleChange(value, 'birthdate')}
              onChangeEstimated={(event: any) => handleChange(event.target.checked, 'estimated')}
              value={data.birthdate}
            />
          </Grid>
          {jobFunctionOptions && (
            <Grid item xs={3}>
              <MISSelectDropdown
                error={hasError('add-provider-staff.job-function')}
                helperText={getError('add-provider-staff.job-function')}
                id="job-function"
                label={t('provider-staff.add-edit.job-function')}
                onChange={(event) => handleChange(event.target.value, 'jobFunction')}
                options={getFormattedOptions(jobFunctionOptions)}
                required
                value={jobFunctionOptions.find((o: any) => o === data.jobFunction)}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <MISTextField
              id="job-title"
              label={t('provider-staff.add-edit.job-title')}
              onChange={(event) => handleChange(event.target.value, 'jobTitle')}
              value={data.jobTitle}
            />
          </Grid>
          {govAgenciesOptions?.length > 0 && (
            <Grid item xs={4}>
              <MISSelectDropdown
                error={hasError('add-provider-staff.job-organization')}
                helperText={getError('add-provider-staff.job-organization')}
                id="job-organization"
                label={t('provider-staff.add-edit.organization')}
                onChange={(event) => {
                  const org = event.target.value as GovernanceAgencyDTO
                  if (org) {
                    handleChange(org?.id, 'jobOrganizationId')
                  }
                }}
                options={getFormattedOptions(govAgenciesOptions)}
                required={data.isStaff && data.jobInternalExternal}
                value={govAgenciesOptions.find(
                  (ga: GovernanceAgencyDTO) => ga.id === data.jobOrganizationId
                )}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <MISDatePicker
              error={hasError('add-provider-staff.start-date')}
              helperText={getError('add-provider-staff.start-date')}
              id="start-date"
              isDefaultToday
              label={t('provider-staff.add-edit.start-date')}
              onChange={(value) => handleChange(value, 'jobStartDate')}
              readOnly={false}
              required
              value={data.jobStartDate}
            />
          </Grid>
          <Grid item xs={3}>
            <MISRadioGroup
              error={hasError('add-provider-staff.is-staff')}
              helperText={getError('add-provider-staff.is-staff')}
              id="is-staff"
              label={t('provider-staff.add-edit.staff')}
              onChange={(event) => handleChange(event.target.value === 'Yes', 'isStaff')}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              required
              value={data.isStaff ? 'Yes' : data.isStaff === false ? 'No' : ''}
            />
          </Grid>
          <Grid item xs={3}>
            <MISRadioGroup
              defaultValue="ACTIVE"
              id="status"
              label={t('provider-staff.add-edit.status')}
              onChange={(event) => handleChange(event.target.value === 'ACTIVE', 'jobStatus')}
              options={[
                { label: t('provider-staff.add-edit.status-active'), value: 'ACTIVE' },
                { label: t('provider-staff.add-edit.status-inactive'), value: 'INACTIVE' },
              ]}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <MISRadioGroup
              defaultValue=""
              error={hasError('add-provider-staff.job-internal-external')}
              helperText={getError('add-provider-staff.job-internal-external')}
              id="internal-external"
              label={t('provider-staff.add-edit.internal-external')}
              onChange={(event) =>
                setData({
                  ...data,
                  ...(event.target.value === 'INTERNAL' && {
                    isStaff: true,
                  }),
                  jobInternalExternal: event.target.value === 'INTERNAL',
                })
              }
              options={[
                { label: t('provider-staff.add-edit.internal'), value: 'INTERNAL' },
                { label: t('provider-staff.add-edit.external'), value: 'EXTERNAL' },
              ]}
              required
            />
          </Grid>
        </Grid>
        <Divider className="divider" />
        <Box className="actions" sx={{ textAlign: 'end' }}>
          <MISButton
            onClick={handleSaveProviderStaff}
            sx={{ marginLeft: '1rem' }}
            variant="contained"
          >
            {t('provider-staff.add-edit.add-provider-staff-text')}
          </MISButton>
        </Box>
      </>
    )
  }

  return (
    <MISBaseContainer>
      <div className="add-edit-provider-staff">
        <MISImageContentContainer imgSrc={ADD_CLIENT_IMG_SRC}>
          <Content
            content={getAddProviderStaffContent()}
            heading={t('provider-staff.add-edit.add-provider-staff-text')}
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
      </div>
    </MISBaseContainer>
  )
}

export default AddEditProviderStaff
