import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { DateTime } from 'luxon'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import AddAssociationDialog from './AddAssociationDialog'

type AssociationTableProps = {
  headerName: string
  openLinkModalCallBack: () => void
  closeLinkModalCallBack: () => void
  associations: any[] | Map<any, any>
  otherEntities: any[]
  saveCallBack: (associations: any[]) => void
  openLinkModal: boolean
  entity: any
  fixedHeightInPixels: number
  showOtherEntityDatesTable?: boolean
}

type AssociationRowProps = {
  existingAssociations: Association[]
  onSaveClickedCallback: (associations: Association[]) => void
  showOtherEntityDatesTable?: boolean
}

type Association = {
  id: number | null
  otherEntity: AssociationEntity
  startDate: string | null
  endDate: string | null
  isDirty: boolean
}

type AssociationEntity = {
  id: number
  name: string
  description?: string
  startDate?: string | null
  endDate?: string | null
}

function AssociationRow(props: AssociationRowProps): JSX.Element {
  const { existingAssociations, onSaveClickedCallback, showOtherEntityDatesTable } = props
  const [open, setOpen] = useState<boolean>(false)
  const [associations, setAssociations] = useState<Association[]>(existingAssociations)
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)

  const { t } = useTranslation('common')
  const otherEntity = associations?.[0]?.otherEntity ? associations[0].otherEntity : null

  const addNewAssociationRow = (otherEntity: AssociationEntity) => {
    const newAssociation: Association = {
      endDate: null,
      id: null,
      isDirty: true,
      otherEntity: otherEntity,
      startDate: null,
    }
    const associationsCopy = [...associations]
    associationsCopy.push(newAssociation)
    setAssociations(associationsCopy)
  }
  const deleteNewAssociationRow = (index: number) => {
    const associationsCopy = [...associations]
    associationsCopy.splice(index, 1)
    setAssociations(associationsCopy)
  }

  const handleStartDateChange = (value: any, index: number) => {
    const updatedAssociation = { ...associations[index], isDirty: true, startDate: value }
    const associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleEndDateChange = (value: any, index: number) => {
    const updatedAssociation = { ...associations[index], endDate: value, isDirty: true }
    const associationsCopy = [...associations]
    associationsCopy[index] = updatedAssociation
    setAssociations(associationsCopy)
  }

  const handleCancelClicked = () => {
    setShowValidationErrors(false)
    setAssociations(existingAssociations)
  }

  const getEndDateHelperText = (startDate: any, endDate: any) => {
    if (endDate && !endDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'End date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const getStartDateHelperText = (startDate: any, endDate: any) => {
    if (!startDate) {
      return t('common.helper-text.required-field', { fieldName: 'Start date' })
    } else if (startDate && !startDate.isValid) {
      return t('common.helper-text.must-be-valid-date', { fieldName: 'Start date' })
    } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
      return t('common.helper-text.start-date-before-end-date')
    }
    return ''
  }

  const isStartDateValid = (startDate: any, endDate: any) => {
    if (!startDate || !startDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const isEndDateValid = (startDate: any, endDate: any) => {
    if (endDate && !endDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const validateAssociations = (): boolean => {
    let isValid = true
    associations.forEach((association) => {
      if (association.isDirty) {
        if (
          !isStartDateValid(association.startDate, association.endDate) ||
          !isEndDateValid(association.startDate, association.endDate)
        ) {
          isValid = false
        }
      }
    })
    return isValid
  }

  const handleSaveClicked = (): void => {
    setShowValidationErrors(true)
    if (!validateAssociations()) {
      return
    } else {
      setShowValidationErrors(false)
    }
    const associationsToSave: any = []
    associations.forEach((association) => {
      if (association.isDirty) {
        const associationToSave = {
          endDate: association.endDate
            ? DateTime.fromFormat(association.endDate, 'yyyy-MM-dd').toISO()
            : null,
          id: association.id,
          otherEntityId: association.otherEntity.id,
          startDate: association.startDate
            ? DateTime.fromFormat(association.startDate, 'yyyy-MM-dd').toISO()
            : null,
        }
        associationsToSave.push(associationToSave)
      }
    })
    if (associationsToSave.length > 0) {
      onSaveClickedCallback(associationsToSave)
    }
  }

  useEffect(() => {
    setAssociations(existingAssociations)
  }, [existingAssociations])

  return (
    <React.Fragment>
      <TableRow
        hover
        key={otherEntity?.id}
        onClick={() => setOpen(!open)}
        selected={open}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell>
          {otherEntity?.name}
          {otherEntity?.description ? ', ' + otherEntity.description : ''}
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={'collapse-' + otherEntity?.id}>
        <TableCell
          colSpan={6}
          style={{
            background: '#f2f2f2',
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {showOtherEntityDatesTable && (
              <Box sx={{ margin: 1 }}>
                <TableContainer component={Paper} elevation={0}>
                  <Table size="small">
                    <TableHead>
                      <TableRow key="otherEntityHeader">
                        <TableCell>{t('types.table-header.start-date')}</TableCell>
                        <TableCell>{t('types.table-header.end-date')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key="otherEntityInfo">
                        <TableCell component="th" scope="row">
                          {isoDateToDisplayFormat(otherEntity?.startDate)}
                        </TableCell>
                        <TableCell>
                          {otherEntity?.endDate ? isoDateToDisplayFormat(otherEntity.endDate) : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <Box sx={{ margin: 1 }}>
              <Table component={Paper} elevation={0} size="small">
                <TableHead>
                  <TableRow key="associationHeader">
                    <TableCell>{t('types.table-header.link-start-date')}</TableCell>
                    <TableCell>{t('types.table-header.link-end-date')}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => otherEntity && addNewAssociationRow(otherEntity)}
                        sx={{ mt: 2 }}
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {associations &&
                    associations.length > 0 &&
                    associations.map((association, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                              label={t('types.table-header.start-date')}
                              mask="____-__-__"
                              onChange={(value) => handleStartDateChange(value, index)}
                              renderInput={(params) => (
                                <MISTextField
                                  {...params}
                                  error={
                                    showValidationErrors &&
                                    association.isDirty &&
                                    !isStartDateValid(association.startDate, association.endDate)
                                  }
                                  fullWidth
                                  helperText={
                                    showValidationErrors &&
                                    association.isDirty &&
                                    getStartDateHelperText(
                                      association.startDate,
                                      association.endDate
                                    )
                                  }
                                />
                              )}
                              value={association.startDate}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                              label={t('types.table-header.end-date')}
                              mask="____-__-__"
                              onChange={(value) => handleEndDateChange(value, index)}
                              renderInput={(params) => (
                                <MISTextField
                                  {...params}
                                  error={
                                    showValidationErrors &&
                                    association.isDirty &&
                                    !isEndDateValid(association.startDate, association.endDate)
                                  }
                                  fullWidth
                                  helperText={
                                    showValidationErrors &&
                                    association.isDirty &&
                                    getEndDateHelperText(association.startDate, association.endDate)
                                  }
                                  size="small"
                                />
                              )}
                              value={association.endDate}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={association.id ? true : false}
                            onClick={() => deleteNewAssociationRow(index)}
                            sx={{ mt: 2 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item />
              <Grid item sx={{ p: 2 }}>
                <MISButton onClick={handleCancelClicked} sx={{ marginRight: '10px' }}>
                  {' '}
                  {t('common.button.cancel')}
                </MISButton>
                <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function AssociationTable(props: AssociationTableProps) {
  const {
    associations,
    closeLinkModalCallBack,
    entity,
    fixedHeightInPixels,
    headerName,
    openLinkModal,
    openLinkModalCallBack,
    otherEntities,
    saveCallBack,
    showOtherEntityDatesTable,
  } = props

  const { t } = useTranslation('common')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const getSortCompartor = () => {
    if (order === 'asc') {
      return (a: any[], b: any[]) =>
        a?.[0]?.otherEntity?.name?.toLowerCase() > b?.[0]?.otherEntity?.name?.toLowerCase() ? 1 : -1
    } else {
      return (a: any[], b: any[]) =>
        a?.[0]?.otherEntity?.name?.toLowerCase() < b?.[0]?.otherEntity?.name?.toLowerCase() ? 1 : -1
    }
  }

  const changeSortOrder = () => {
    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleLinkClicked = () => {
    openLinkModalCallBack()
  }

  const closeLinkModal = () => {
    closeLinkModalCallBack()
  }

  const saveNewAssociations = (associations: any[]) => {
    saveCallBack(associations)
  }

  return (
    <Box
      component={Paper}
      sx={{
        height: fixedHeightInPixels ? fixedHeightInPixels : 'auto',
        overflowY: fixedHeightInPixels ? 'scroll' : 'none',
      }}
    >
      {openLinkModal && (
        <AddAssociationDialog
          entityName={entity.name}
          handleCloseCallback={closeLinkModal}
          onSaveClickedCallback={saveNewAssociations}
          openDialog={openLinkModal}
          otherEntities={otherEntities}
        />
      )}

      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item />
        <Grid item sx={{ p: 2 }}>
          <MISButton onClick={handleLinkClicked}>{t('common.button.link')}</MISButton>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow key="associationTableHead">
              <TableCell sx={{ fontWeight: 'bold' }}>
                <TableSortLabel active direction={order} onClick={changeSortOrder}>
                  {headerName}
                </TableSortLabel>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {/* TODO: Fix this part, since associations is a map but treated like an array here */}
          {associations && [...associations.values()].length > 0 && (
            <>
              <TableBody>
                {[...associations.values()]
                  .sort(getSortCompartor())
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((associations) => {
                    return (
                      <AssociationRow
                        existingAssociations={associations}
                        key={Math.random()}
                        onSaveClickedCallback={saveNewAssociations}
                        showOtherEntityDatesTable={showOtherEntityDatesTable}
                      />
                    )
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    component="td"
                    count={[...associations.values()].length}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {(!associations || [...associations.values()].length < 1) &&
          "There are no links. Click the 'Link' buttton to add new links"}
      </TableContainer>
    </Box>
  )
}
