/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StatusResponse = {
  status?: StatusResponse.status
  message?: string
  errorCode?: string
  timestamp?: string
  exception?: string
}

export namespace StatusResponse {
  export enum status {
    OK = 'OK',
    FAIL = 'FAIL',
  }
}
