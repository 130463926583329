import { Box, CircularProgress } from '@mui/material'

type LoaderProps = {
  fullScreen?: boolean
  height?: number | string
}

const Loader = ({ fullScreen = false, height = '100%' }: LoaderProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: fullScreen ? '100vh' : height,
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loader
