/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageTeamDTO } from '../models/PageTeamDTO'
import type { TeamDTO } from '../models/TeamDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class TeamControllerService {
  /**
   * Returns a paged list of Team and its members
   * List is returned as Paged collection
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageTeamDTO OK
   * @throws ApiError
   */
  public static getTeams(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageTeamDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/teams',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create or update team and team members
   * updates the list and then returns as UnPaged collection
   * @param requestBody
   * @returns TeamDTO OK
   * @throws ApiError
   */
  public static saveTeams(requestBody: Array<TeamDTO>): CancelablePromise<Array<TeamDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/teams',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete team by teamId
   * Soft deletes the team by setting deleted flag to true
   * @param teamId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTeam(teamId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/teams/{teamId}',
      path: {
        teamId: teamId,
      },
    })
  }

  /**
   * Delete team member by teamMemberId
   * Soft deletes the team member by setting deleted flag to true
   * @param teamId
   * @param memberId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTeamMember(teamId: string, memberId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/teams/{teamId}/members/{memberId}',
      path: {
        teamId: teamId,
        memberId: memberId,
      },
    })
  }
}
