import { useCallback, useEffect, useState } from 'react'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISButton from 'common/components/MISButton'
import { arrayMove } from 'common/utils/utils'

const getProperty = (obj, prop) => {
  var parts = prop.split('.')

  if (Array.isArray(parts)) {
    var last = parts.length > 1 ? parts.pop() : parts
    var l = parts.length,
      i = 1,
      current = parts[0]

    while ((obj = obj[current]) && i < l) {
      current = parts[i]
      i++
    }

    if (typeof obj === 'object') {
      return obj[last]
    }
    return obj
  } else {
    throw Object.assign(new Error('parts is not valid array'))
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function EnhancedTableHead(props) {
  const {
    checkboxed,
    headCells,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {checkboxed && (
          <TableCell padding="checkbox">
            <MISCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? 'right' : 'left'}
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, onPageChange, page, rowsPerPage } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default function StickyHeadTable({
  callSearch,
  data,
  isMultiSelectable,
  isPaginated,
  lastUpdated,
  onRowClick,
  tableBodies,
  tableHeaders,
  tablePages,
}) {
  const [rows, setRows] = useState(data)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name') //same as rows/page
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5) //This assumes that the smallest vale us 5. TODO: pick from lowest value

  const getDisplayRows = useCallback(
    (rows) => {
      if (!(rows?.length > 0)) return rows

      const sortedRows = rows
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

      if (lastUpdated) {
        const lastUpdatedIndex = sortedRows.findIndex((o) => o.id === lastUpdated.id)
        if (lastUpdatedIndex !== -1) {
          return arrayMove(sortedRows, lastUpdatedIndex, 0)
        } else {
          sortedRows.unshift(lastUpdated)
        }
      }

      return sortedRows
    },
    [lastUpdated, order, orderBy, page, rowsPerPage]
  )

  useEffect(() => {
    if (data?.length > 0) {
      setRows(data)
    }
  }, [data])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    if (callSearch) {
      callSearch(order, orderBy, page, rowsPerPage)
    }
  }

  const handleClick = (event, row) => {
    if (!isMultiSelectable) {
      onRowClick(row)
    } else {
      const selectedIndex = selected.indexOf(row.name)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      setSelected(newSelected)
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (callSearch) {
      callSearch(order, orderBy, page, rowsPerPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    if (callSearch) {
      callSearch(order, orderBy, page, rowsPerPage)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  return (
    <Paper sx={{ mb: 2, width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table aria-label="sticky table" stickyHeader>
          <EnhancedTableHead
            checkboxed={isMultiSelectable}
            headCells={tableHeaders}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
          />
          <TableBody>
            {(rowsPerPage > 0 ? getDisplayRows(rows) : rows).map((row, index) => {
              const isItemSelected = isSelected(row.name)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow
                  aria-checked={isItemSelected}
                  hover
                  key={row.name}
                  onClick={(event) => handleClick(event, row)}
                  role="checkbox"
                  selected={isItemSelected}
                  tabIndex={-1}
                >
                  {isMultiSelectable && (
                    <TableCell padding="checkbox">
                      <MISCheckbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                  )}

                  {tableBodies.map((body) => {
                    return typeof body.id === 'string' ? (
                      body.width ? (
                        <TableCell
                          key={body.id + 'key1' + index}
                          style={{ width: body.width + '%' }}
                        >
                          {getProperty(row, body.id)}
                        </TableCell>
                      ) : (
                        <TableCell key={body.id + 'key1' + index}>
                          {getProperty(row, body.id)}
                        </TableCell>
                      )
                    ) : (
                      <TableCell key={body.id + 'key2'}>
                        {body.map((button, index) => (
                          <MISButton
                            key={index}
                            size="small"
                            startIcon={button.icon}
                            to={button.base}
                          />
                        ))}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 49 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isPaginated && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  ActionsComponent={TablePaginationActions}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  count={rows.length}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={tablePages}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  )
}
