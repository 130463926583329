import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import MISButton from 'common/components/MISButton'

type ConfigurationContainerProps = {
  children: ReactNode
  onCancel: () => void
  onSave: () => void
  open: boolean
  title: string
}

const ConfigurationContainer = ({
  children,
  onCancel,
  onSave,
  open,
  title,
}: ConfigurationContainerProps) => {
  const { t } = useTranslation('common')

  return (
    <Dialog onMouseDown={(e) => e.stopPropagation()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width: '500px' }}>{children}</DialogContent>
      <DialogActions>
        <MISButton color="secondary" onClick={onCancel}>
          {t('common.button.cancel')}
        </MISButton>
        <MISButton color="primary" onClick={onSave}>
          {t('common.button.save')}
        </MISButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfigurationContainer
