import { useCallback } from 'react'
import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { ThemeProvider } from '@mui/material/styles'
import { DateTime } from 'luxon'
import { IDHE_DATE_TIME_SEC_DISPLAY_FORMAT } from 'common/utils/DateUtils'
import misTheme from 'core/styles/theme'
import MISTextField from './MISTextField'

export type MISDateTimePickerProps = Omit<DateTimePickerProps, 'renderInput'>

const MISDateTimePicker = (props: MISDateTimePickerProps) => {
  const handleChange = useCallback(
    (date: DateTime) => {
      if (date) props.onChange(date.toISO())
      else props.onChange(null)
    },
    [props]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={misTheme}>
        <DateTimePicker
          {...props}
          ampm={false}
          inputFormat={IDHE_DATE_TIME_SEC_DISPLAY_FORMAT}
          mask="____-___-__ __:__:__"
          onChange={(date: unknown) => handleChange(date as DateTime)}
          renderInput={(props) => <MISTextField {...props} />}
          views={['year', 'day', 'hours', 'minutes', 'seconds']}
        />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default MISDateTimePicker
