import { useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Breadcrumb } from 'common/components/breadcrumb/Breadcrumb'
import AgreementEdit from './AgreementEdit'
import AgreementList from './AgreementList'

const Agreements = ({ breadcrumb, title, type }) => {
  const [agreementId, setAgreementId] = useState(undefined)
  const [trigger, setTrigger] = useState(0)

  const editTitle = 'Edit ' + title
  const listTitle = 'List of ' + title

  /**
   * This is used as a callback when an agreement has been saved.
   * It needs to trigger the list component to rerender.
   * It should not need to know which agreement was updated.
   */
  const handleAgreementUpdates = (id) => {
    setTrigger(trigger + 1)
    setAgreementId(id)
  }

  const handleRowSelected = (id) => {
    setAgreementId(id)
  }

  return (
    <>
      <Breadcrumb breadcrumb={breadcrumb} />
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box justifyContent="center" sx={{ width: '100%' }}>
          <Stack spacing={1}>
            <AgreementList
              agreementId={agreementId}
              onRowSelected={handleRowSelected}
              title={listTitle}
              trigger={trigger}
              type={type}
            />

            <AgreementEdit
              agreementId={agreementId}
              onUpdate={handleAgreementUpdates}
              title={editTitle}
              type={type}
            />
          </Stack>
        </Box>
      </Paper>
    </>
  )
}

export default Agreements
