import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'

type OverridePrivacyDialogProps = {
  open: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  onCancel?: () => void
  onSave?: (reason: string) => void
}

const OverridePrivacyDialog = (props: OverridePrivacyDialogProps) => {
  const {
    onCancel,
    onSave,
    open = false,
    primaryButtonText = 'Access Restricted File',
    secondaryButtonText = 'Cancel',
  } = props

  const { t } = useTranslation('common')
  const [reason, setReason] = useState('')

  const [isOpen, setIsOpen] = useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleCancel = () => {
    setIsOpen(false)
    onCancel && onCancel()
  }

  const handleSave = () => {
    setIsOpen(false)
    onSave && onSave(reason)
  }

  return (
    <Dialog maxWidth="xs" open={isOpen}>
      <DialogTitle>
        <Typography fontSize="large" fontWeight="bold">
          {t('privacy-directives.dialog-title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MISTextField
              id="reason-field"
              label={t('privacy-directives.field-title')}
              maxRows={8}
              minRows={4}
              multiline
              onChange={(e) => setReason(e.target.value)}
              required
              value={reason}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {onSave && (
          <MISButton color="secondary" disabled={reason?.length === 0} onClick={handleSave}>
            {primaryButtonText}
          </MISButton>
        )}
        {onCancel && (
          <MISButton color="primary" onClick={handleCancel}>
            {secondaryButtonText}
          </MISButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default OverridePrivacyDialog
