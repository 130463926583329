/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressResponseDTO } from '../models/AddressResponseDTO'
import type { OrganizationAddressDTO } from '../models/OrganizationAddressDTO'
import type { OrganizationPageAddressDTO } from '../models/OrganizationPageAddressDTO'
import type { PageAddressResponseDTO } from '../models/PageAddressResponseDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AddressControllerService {
  /**
   * Get all addresses for an agency
   * @param agencyId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageAddressResponseDTO OK
   * @throws ApiError
   */
  public static getAddressesByAgencyId(
    agencyId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageAddressResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/addresses',
      path: {
        agencyId: agencyId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create/Update an Address
   * The properties of the address are set in the JSON request body.
   * @param agencyId
   * @param requestBody
   * @returns AddressResponseDTO Successfully updated the specified address
   * @throws ApiError
   */
  public static upsertAgencyAddresses(
    agencyId: string,
    requestBody: Array<OrganizationAddressDTO>
  ): CancelablePromise<Array<AddressResponseDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/addresses',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Failed to create/update the specified address`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }

  /**
   * Get an agency address by id of agencyAddress, NOT the address Entity.
   * @param agencyId
   * @param addressId
   * @returns any OK
   * @throws ApiError
   */
  public static getAddressById(agencyId: string, addressId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/addresses/{addressId}',
      path: {
        agencyId: agencyId,
        addressId: addressId,
      },
    })
  }

  /**
   * Create/Update an Address
   * The properties of the address are set in the JSON request body.
   * @param agencyId
   * @param addressId
   * @returns void
   * @throws ApiError
   */
  public static deleteAgencyAddress(agencyId: string, addressId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/addresses/{addressId}',
      path: {
        agencyId: agencyId,
        addressId: addressId,
      },
      errors: {
        400: `Failed to delete the specified address`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }

  /**
   * Search addresses across all governance agencies. This will support the address auto-complete function on the frontend and do a partial match on the various parts of an address on the concatenated address field:{unit #} - {street #} {street name} {street type} {city} {province} {postal code} {country}
   * @param addressText
   * @returns OrganizationPageAddressDTO OK
   * @throws ApiError
   */
  public static organizationgetAllAddresses(
    addressText: string
  ): CancelablePromise<OrganizationPageAddressDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/addresses',
      query: {
        addressText: addressText,
      },
    })
  }
}
