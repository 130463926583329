/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppSetting } from '../models/AppSetting'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AppSettingControllerService {
  /**
   * @param key
   * @returns AppSetting OK
   * @throws ApiError
   */
  public static getAppSetting(key: string): CancelablePromise<AppSetting> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-user-service/v1/app-settings/{key}',
      path: {
        key: key,
      },
    })
  }
}
