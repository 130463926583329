import RGL from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'

type FormBuilderConfigProps = {
  data: (RGL.ReactGridLayoutProps & { border?: boolean; height?: number }) | undefined
  onChange: (key: string, value: boolean | number | string) => void
}

const FormBuilderConfig = ({ data, onChange }: FormBuilderConfigProps) => {
  const { t } = useTranslation('common')

  return (
    <Paper sx={{ mb: 2, pb: 2, pl: 2, pr: 2, pt: 2 }}>
      <Typography sx={{ color: '#333', fontSize: '12px', fontWeight: 700 }}>
        {t('charting.form-builder.configurations')}
      </Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <MISTextField
          defaultValue={data?.cols || 4}
          inputProps={{ max: 12, min: 1 }}
          label={t('charting.form-builder.grid.cols')}
          onChange={(e) => onChange('cols', Number.parseInt(e.target.value))}
          size="small"
          type="number"
        />
        <MISTextField
          defaultValue={data?.height || 48}
          inputProps={{ max: 96, min: 16 }}
          label={t('charting.form-builder.grid.height')}
          onChange={(e) => onChange('height', Number.parseInt(e.target.value))}
          size="small"
          type="number"
        />
        <FormControlLabel
          control={
            <Switch
              checked={data?.border}
              onChange={(_, checked) => onChange('border', checked)}
              size="small"
            />
          }
          label={t('charting.form-builder.grid.border')}
        />
      </Stack>
    </Paper>
  )
}

export default FormBuilderConfig
