import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup as MUIRadioGroup,
  RadioGroupProps,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import misTheme from 'core/styles/theme'
import MISRadio from './MISRadio'

interface RadioOption {
  label: string
  value: any
}

interface RadioGroupCustomProps {
  defaultValue?: any
  required?: boolean
  label: string
  options: RadioOption[]
  helperText?: string
  error?: boolean
  disabled?: boolean
}

const MISRadioGroup = (props: RadioGroupProps & RadioGroupCustomProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <FormControl disabled={props.disabled}>
        <FormLabel
          className="radio-group"
          htmlFor="mis-radio-group-input"
          id={`mis-radio-group-label-${props.id}`}
          required={props.required}
        >
          {props.label}
        </FormLabel>
        <MUIRadioGroup row {...props} onChange={props.onChange}>
          {props.options?.length > 0
            ? props.options.map((option) => (
                <MISRadio
                  checked={props.value !== undefined ? props.value === option.value : undefined}
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              ))
            : props.children}
        </MUIRadioGroup>
        {props.helperText && (
          <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  )
}

export default MISRadioGroup
