/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramGroupAssociation } from '../models/ProgramGroupAssociation'
import type { ProgramGroupAssociationTerse } from '../models/ProgramGroupAssociationTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ProgramGroupAssociationControllerService {
  /**
   * @param id
   * @param requestBody
   * @returns ProgramGroupAssociationTerse OK
   * @throws ApiError
   */
  public static updateProgramGroupAssociation(
    id: string,
    requestBody: ProgramGroupAssociation
  ): CancelablePromise<ProgramGroupAssociationTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/program-group-associations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
