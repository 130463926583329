import { useContext } from 'react'
import { ErrorHandlerContext } from './ErrorHandlerContext'

export const useErrorHandler = () => {
  const {
    addError,
    buildError,
    clearErrors,
    getError,
    handleApiError,
    hasError,
    isError,
    setErrorList,
  } = useContext(ErrorHandlerContext)

  return {
    addError,
    buildError,
    clearErrors,
    getError,
    handleApiError,
    hasError,
    isError,
    setErrorList,
  }
}
